import { gql } from '@apollo/client';

import { AddressTypeEnum } from './generated/graphql';

import { Option } from '../components/shared/types';

export enum ResidenceTypeEnum {
  Rent = 'rent',
  Own = 'own',
  Family = 'family',
  Buying = 'buying',
}

export const ResidenceTypeLabelMap = {
  [ResidenceTypeEnum.Rent]: 'Renting/Leasing',
  [ResidenceTypeEnum.Own]: 'Owns Home Outright',
  [ResidenceTypeEnum.Family]: 'Lives With Family',
  [ResidenceTypeEnum.Buying]: 'Buying Home',
};

export const residenceTypeOptions: Option[] = Object.values(ResidenceTypeEnum).map((value) => ({
  label: ResidenceTypeLabelMap[value],
  value,
}));

export class Address {
  id?: number;
  address_line: string;
  address_line_2: string;
  zip: string;
  city: string;
  state: string;
  county: string;
  residence_type: ResidenceTypeEnum;
  years_at_home?: number;
  months_at_home?: number;
  monthly_payment?: number;
  moved_states?: boolean;
  address_type?: AddressTypeEnum;

  constructor() {
    this.address_line = '';
    this.address_line_2 = '';
    this.zip = '';
    this.city = '';
    this.state = '';
    this.county = '';
    this.residence_type = ResidenceTypeEnum.Rent;
  }
}

export const addressDetailQuery = gql`
  query addressDetail($address: String, $zipCode: String) {
    addressDetail(address: $address, zipCode: $zipCode) {
      city
      county
      state
    }
  }
`;
