import React, { useMemo, useState } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  List,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai';
import { BiCheck } from 'react-icons/bi';

import { StateAbbreviation } from '../../../gql/generated/graphql';

interface UsStatesSelectProps {
  name: string;
  label: string;
  states: StateAbbreviation[];
}
const UsStatesSelect: React.FC<UsStatesSelectProps> = ({ name, label, states }) => {
  const [order, setOrder] = useState<'DESC' | 'ASC'>('ASC');
  const [search, setSearch] = useState<string>();
  const [field, , { setValue }] = useField<StateAbbreviation[]>(name);

  const collapse = useDisclosure({ defaultIsOpen: false });

  const stateList = useMemo(() => {
    if (!states?.length) {
      return [];
    }
    return states
      .filter((state) => !search || state?.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => (a && b ? (order === 'ASC' ? a.localeCompare(b) : b.localeCompare(a)) : -1));
  }, [states, search, order]);

  return (
    <FormControl>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <Flex direction="column">
        <Flex
          justifyContent="space-between"
          minH={8}
          w="100%"
          border="1px"
          borderRadius={collapse.isOpen ? '2px 2px 0 0' : '2px'}
          px={2}
          py={1}
          onClick={collapse.onToggle}
        >
          <Box>
            {field.value?.length ? (
              field.value.map((state) => (
                <Tag key={state} marginRight={1} marginBlock={1}>
                  <TagLabel>{state}</TagLabel>
                  <TagCloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(field.value.filter((selectedState) => selectedState !== state));
                    }}
                  />
                </Tag>
              ))
            ) : (
              <Text opacity={0.2} fontSize="sm">
                No states selected
              </Text>
            )}
          </Box>
          <ChevronDownIcon marginTop={field.value?.length ? 1 : 0} w={5} h={5} />
        </Flex>
        <Collapse in={collapse.isOpen}>
          <Box
            px={2}
            py={2}
            border="1px"
            borderRadius="0 0 12px 12px"
            bgColor="selectGray"
            opacity={0.95}
            boxShadow={2}
          >
            <Flex gap={2} align="center" marginBottom={1}>
              <Icon
                textColor="white"
                cursor="pointer"
                as={order === 'ASC' ? AiOutlineSortAscending : AiOutlineSortDescending}
                onClick={() => {
                  setOrder(order === 'ASC' ? 'DESC' : 'ASC');
                }}
              />
              <Input
                placeholder="Search"
                bgColor="white"
                size="xs"
                defaultValue={search}
                borderRadius={2}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </Flex>
            <List maxH={48} overflowY="scroll">
              {stateList.map((state) => {
                const isStateSelected = field.value?.some(
                  (selectedState) => selectedState === state,
                );

                return (
                  <Flex
                    _hover={{ bgColor: 'selectBlue', textColor: 'black' }}
                    borderRadius={2}
                    key={state}
                  >
                    {isStateSelected ? (
                      <Flex justifyContent="center" alignItems="center" mx={1}>
                        <BiCheck size="15px" color="white" />
                      </Flex>
                    ) : (
                      <Box w={6} />
                    )}
                    <Box
                      w="100%"
                      key={state}
                      cursor="pointer"
                      textColor="white"
                      fontSize="sm"
                      onClick={() => {
                        if (
                          field.value &&
                          !field.value?.some((selectedState) => selectedState.toString() === state)
                        ) {
                          setValue([...field.value, state]);
                        }
                      }}
                    >
                      {state}
                    </Box>
                  </Flex>
                );
              })}
            </List>
          </Box>
        </Collapse>
      </Flex>
    </FormControl>
  );
};

export default UsStatesSelect;
