import { FC, useEffect, useMemo, useState } from 'react';

import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import { getFullName } from '../../../../gql/customerGql';
import { Deal } from '../../../../gql/dealGql';
import { Pod, useDealUpdateAssignmentsMutation } from '../../../../gql/generated/graphql';

import CustomReactSelect from '../../../shared/CustomReactSelect';

import { logger } from '../../../../libs/Logger';

interface TitlePodOption {
  value?: string;
  label?: string | null;
}

const getOption = (title_pod: Pod): TitlePodOption => ({
  value: title_pod.id?.toString(),
  label: title_pod.name,
});

interface TitleTeamAutocompleteProps {
  deal: Deal;
  titlingPods: Pod[];
}

const TitleTeamAutocomplete: FC<TitleTeamAutocompleteProps> = ({ deal, titlingPods }) => {
  const [selectedOption, setSelectedOption] = useState<TitlePodOption | null>(
    deal?.titling_pod ? getOption(deal.titling_pod) : null,
  );
  const [updateTitlePod, { loading }] = useDealUpdateAssignmentsMutation();

  const titlePodOptions = useMemo(
    () =>
      titlingPods.map((titlingPod: Pod) => ({
        value: titlingPod.id?.toString(),
        label: titlingPod.name,
      })),
    [titlingPods],
  );

  useEffect(() => {
    if (deal?.titling_pod) {
      setSelectedOption(getOption(deal.titling_pod));
    }
  }, [deal?.titling_pod]);

  const handleChange = async (selected: SingleValue<TitlePodOption>) => {
    if (!deal?.id) {
      return;
    }

    try {
      await updateTitlePod({
        variables: {
          id: deal.id,
          titling_pod_id: selected?.value ? parseInt(selected.value, 10) : null,
        },
      });

      setSelectedOption(selected);
      toast.success(
        `Title Team ${selected?.value ? `updated to ${selected.label}` : 'Unassigned'} for Deal ${
          deal.id
        } - ${getFullName(deal.customer)}`,
      );
    } catch (error) {
      logger.error('TitleTeamAutoComplete.tsx', '', null, error);
      toast.error(
        (error as { message: string })?.message ||
          `Failed to update Title Team for Deal ${deal.id} - ${getFullName(deal.customer)}`,
      );
    }
  };

  return (
    <CustomReactSelect
      placeholder=""
      isClearable
      options={titlePodOptions}
      onChange={handleChange}
      value={selectedOption}
      isLoading={loading}
    />
  );
};

export default TitleTeamAutocomplete;
