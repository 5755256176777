// needs to match the state array on API side
// constants/states.ts
export const STATES_TAXED_ON_WARRANTY = [
  'AR',
  'CT',
  'FL',
  'HI',
  'KS',
  'NE',
  'ME',
  'NJ',
  'NY',
  'OH',
  'PA',
  'SD',
  'TN',
  'UT',
  'WI',
  'WY',
];
