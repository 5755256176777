import { useContext, useState } from 'react';

import { WarningIcon } from '@chakra-ui/icons';
import { Button, Checkbox, ModalProps, Spacer, Text } from '@chakra-ui/react';

import { ConsentNameEnumType, useSaveConsentMutation } from '../../../../gql/generated/graphql';

import Modal from '../../../shared/Modal';

import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';

type NoHardConsentModalProps = Omit<ModalProps, 'children'>;

const NoHardConsentModal = ({ ...modalProps }: NoHardConsentModalProps) => {
  const { deal, dispatch } = useContext(DealContext);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [saveConsent, { loading }] = useSaveConsentMutation();

  const submitConsentForHardPull = async () => {
    if (!deal.id || !checkboxChecked) {
      return;
    }

    const consentData = await saveConsent({
      variables: {
        dealId: deal.id,
        type: ConsentNameEnumType.CreditScoreHardPull,
      },
    });

    if (consentData.data?.saveConsent.isNew) {
      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          consents: [
            ...(deal.consents || []),
            {
              id: consentData.data.saveConsent.consent?.id,
              type: consentData.data.saveConsent.consent?.type,
            },
          ],
        },
      });
    }
  };

  return (
    <Modal
      size="2xl"
      {...modalProps}
      title="Consent Required"
      leftButtons={
        <Button onClick={modalProps.onClose} variant="warning" isDisabled={loading}>
          CANCEL
        </Button>
      }
      rightButtons={
        <Button
          onClick={submitConsentForHardPull}
          isLoading={loading}
          isDisabled={!checkboxChecked}
        >
          CONFIRM
        </Button>
      }
    >
      <WarningIcon mr={2} color="yellow.400" />
      <Text as="span">
        We do not have a record of the driver providing consent for a hard credit pull. Consent is
        required to proceed.
      </Text>
      <Spacer my={8} />
      <Checkbox
        fontStyle="italic"
        onChange={(e) => setCheckboxChecked(e.target.checked)}
        alignItems="flex-start"
      >
        By checking this box, I am confirming that I have obtained consent from the driver to submit
        an application for credit.
      </Checkbox>
    </Modal>
  );
};

export default NoHardConsentModal;
