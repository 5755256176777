import { RelationToBuyerEnum } from './generated/graphql';

export class DealContact {
  id?: number;
  deal_id?: number;
  relation_to_buyer?: RelationToBuyerEnum;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;

  constructor(dealId?: number) {
    this.deal_id = dealId;
  }
}
