import React, { MouseEvent } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { usePayoffRequestFailureRequestMutation } from '../../../../gql/generated/graphql';
import { PayoffRequest } from '../../../../gql/payoffRequestGql';

import PayoffFailureForm, { FormValues } from './PayoffFailureForm';

import { PermissionEnum } from '../../../../constants/permissions';
import { Can } from '../../../../libs/Can';

interface Props {
  payoffRequest?: PayoffRequest;
  disabled?: boolean;
}

const RequestPayoffFailure: React.FC<Props> = ({ payoffRequest, disabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [payoffRequestFailureRequest, { loading }] = usePayoffRequestFailureRequestMutation();

  const handleSubmit = async (fail_reason: FormValues) => {
    try {
      if (!payoffRequest?.id) {
        throw new Error();
      }

      const updatedPayoffRequest = {
        id: payoffRequest?.id,
        fail_reason,
      };

      await payoffRequestFailureRequest({
        variables: {
          payoffRequest: updatedPayoffRequest,
        },
      });

      onClose();
      toast.success('Payoff Failure requested successfully');
    } catch (error) {
      toast.error('Could not request Payoff Failure');
    }
  };

  return (
    <Can I={PermissionEnum.RequestPayoffFailure}>
      <Button
        size="sm"
        isDisabled={disabled}
        variant="warning"
        width="75px"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        FAIL
      </Button>

      <PayoffFailureForm
        isOpen={isOpen}
        action="Request"
        loading={loading}
        onSubmit={handleSubmit}
        onClose={onClose}
        payoffRequest={payoffRequest}
      />
    </Can>
  );
};

export default RequestPayoffFailure;
