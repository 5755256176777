import Big from 'big.js';
import { cloneDeep } from 'lodash';

import { Payoff as GeneratedPayoff, TemporaryData } from '../gql/generated/graphql';
import { Payoff } from '../gql/payoffGql';
import { PayoffRequest } from '../gql/payoffRequestGql';

export const getTotalPayoff = (data?: TemporaryData | Payoff | GeneratedPayoff | null) => {
  const { payoff_includes_sales_tax, sales_tax_from_payoff, vehicle_payoff } = data ?? {};

  if (payoff_includes_sales_tax) {
    return new Big(vehicle_payoff ?? 0).plus(sales_tax_from_payoff ?? 0).toNumber();
  }

  return vehicle_payoff ?? 0;
};

export const getPayoffRequestOrigin = (payoffRequest?: PayoffRequest) =>
  payoffRequest?.deal_id ? payoffRequest.deal ?? {} : payoffRequest?.temporary_info?.data ?? {};

export const getDoubleTaxApplied = (
  data?: TemporaryData | Payoff | GeneratedPayoff | null,
  buyerNotLessee?: boolean,
) => {
  if (!buyerNotLessee) {
    return false;
  }

  const {
    payoff_includes_sales_tax,
    user_entered_total_payoff,
    vehicle_payoff,
    sales_tax_from_payoff,
  } = data ?? {};

  const totalPayoff = getTotalPayoff(data);
  const adjustedUserEnteredTotalPayoff =
    user_entered_total_payoff === 0 && (vehicle_payoff ?? 0) > 0
      ? vehicle_payoff
      : user_entered_total_payoff;

  return (
    payoff_includes_sales_tax &&
    adjustedUserEnteredTotalPayoff &&
    sales_tax_from_payoff &&
    totalPayoff ===
      new Big(adjustedUserEnteredTotalPayoff).plus(sales_tax_from_payoff ?? 0).toNumber()
  );
};

export const cleanPayoff = (payoff?: Payoff) => {
  if (!payoff) {
    return payoff;
  }

  const payoffCopy = cloneDeep(payoff);

  delete payoffCopy.doubleTaxApplied;
  delete payoffCopy.previousTotalPayoff;
  delete payoffCopy.totalPayoff;

  return payoffCopy;
};
