import * as Yup from 'yup';

import { RemoveIndex } from '../../utils/types';

export const validationSchema = Yup.object().shape({
  date: Yup.date().nullable().required('Date is required'),
  time: Yup.date().nullable().required('Time is required'),
  type: Yup.string().nullable().required('Type is required'),
  note: Yup.string().nullable().optional(),
});

export type FollowUpFormType = Partial<RemoveIndex<Yup.InferType<typeof validationSchema>>>;
