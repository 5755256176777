import { useLocation } from 'react-router-dom';

import { Deal } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

export interface EstimateParams {
  type?: DealType;
  phoneNumber?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

export interface EstimateLocation {
  pathname: string;
  state: EstimateParams;
}

export const getPreFilledEstimate = (location: ReturnType<typeof useLocation>) => {
  const estimateParams = location.state as EstimateParams;
  const locationSearch = location.search;
  const preFilledEstimate = new Deal();
  if (estimateParams) {
    const { type, phoneNumber, firstName, middleName, lastName } = estimateParams;

    preFilledEstimate.type = type;
    preFilledEstimate.customer.phone_number = phoneNumber ?? '';
    preFilledEstimate.customer.first_name = firstName ?? '';
    preFilledEstimate.customer.middle_name = middleName ?? '';
    preFilledEstimate.customer.last_name = lastName ?? '';
  } else if (locationSearch) {
    const searchParams = new URLSearchParams(location.search);
    const phoneNumber = searchParams.get('phoneNumber');
    preFilledEstimate.customer.phone_number = phoneNumber ?? '';
  }

  return preFilledEstimate;
};
