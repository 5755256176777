import { ChangeEvent, FocusEvent, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { Formik, FormikHelpers } from 'formik';
import { isEqual } from 'lodash';
import * as Yup from 'yup';

import { colorOptions } from '../../gql/carGql';
import { Deal, isDealInOrPastFunded, isUneditable } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import ConfirmEditModal from '../ConfirmEditModal/ConfirmEditModal';
import MmrButton from '../DealInfoAcquisition/MmrButton';
import { carValidationReq } from '../EstimateForm/ValidationSchema';
import VehicleValuesCalculator from '../EstimateForm/VehicleValuesCalculator';
import { AutoExplodeLicensePlate } from '../EstimateForm/autoExplodeLicensePlate';
import { AutoExplodeVin } from '../EstimateForm/autoExplodeVin';
import DatePicker from '../shared/DatePicker';
import Input from '../shared/Input';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';
import Select from '../shared/Select';

import { PermissionEnum } from '../../constants/permissions';
import SELECT_OPTIONS from '../../constants/selectOptions';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext, ModalContext } from '../../libs/contextLib';

interface EditVehicleInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: Deal, setSubmitting: (isSubmitting: boolean) => void) => void;
}

const EditVehicleInfoModal = ({ isOpen, onClose, onSubmit }: EditVehicleInfoModalProps) => {
  const { deal } = useContext(DealContext);
  const abilities = useContext(AbilityContext);
  const { modals, setModal } = useContext(ModalContext);

  const [carDetailsLoading, setCarDetailsLoading] = useState(false);
  const [callVinDecoder, setCallVinDecoder] = useState(false);
  const [callLicensePlateDecoder, setCallLicensePlateDecoder] = useState(false);
  const [isLicensePlateNumberDirty, setLicensePlateNumberDirty] = useState(false);
  const [isLicensePlateStateDirty, setLicensePlateStateDirty] = useState(false);
  const [isVinDirty, setVinDirty] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const canEditFundedOnwards =
    abilities.has(PermissionEnum.EditFundedOnwards) && isDealInOrPastFunded(deal.state);
  const isRefi = deal.type === DealType.Refi;

  const validationSchema = useMemo(
    () => Yup.object().shape({ car: carValidationReq(false, isRefi) }),
    [isRefi],
  );

  return (
    <Formik
      onSubmit={(values: Deal, { setSubmitting }: FormikHelpers<Deal>) => {
        onSubmit(values, setSubmitting);
      }}
      initialValues={deal}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
        initialValues,
        isValid,
        isSubmitting,
        handleBlur,
        handleChange,
      }) => {
        const isDisabled = canEditFundedOnwards ? !isEditing : isUneditable(values.state);

        const customHandleBlur = (
          e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLSelectElement>,
        ) => {
          handleBlur(e);

          if (
            values.car.license_plate_number &&
            values.car.license_plate_state &&
            (e.target.name === 'car.license_plate_number' ||
              e.target.name === 'car.license_plate_state') &&
            (isLicensePlateNumberDirty || isLicensePlateStateDirty)
          ) {
            setCallLicensePlateDecoder(true);
          }

          if (values.car.vin.length === 17 && e.target.name === 'car.vin') {
            setCallVinDecoder(true);
          }
        };

        const customHandleChange = (
          e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
        ) => {
          if (
            e.target.name === 'car.license_plate_number' &&
            e.target.value !== values.car.license_plate_number
          ) {
            setLicensePlateNumberDirty(true);
          }
          if (
            e.target.name === 'car.license_plate_state' &&
            e.target.value !== values.car.license_plate_state
          ) {
            setLicensePlateStateDirty(true);
          }
          if (e.target.name === 'car.vin' && e.target.value !== values.car.vin) {
            setVinDirty(true);
          }

          handleChange(e);
        };

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          setCanSave(!isEqual(initialValues.car, values.car));
        }, [values.car]);

        return (
          <Modal
            title="Edit Vehicle Info"
            variant="noMargin"
            isOpen={isOpen}
            onClose={() => {
              setIsEditing(false);
              onClose();
            }}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            hasForm
            formProps={{
              noValidate: true,
              onSubmit: handleSubmit,
            }}
            leftButtons={
              <Button
                variant="warning"
                onClick={() => {
                  setIsEditing(false);
                  onClose();
                }}
              >
                Cancel
              </Button>
            }
            rightButtons={
              <>
                {deal.type === DealType.Acquisition ? <MmrButton /> : null}
                <Button
                  variant="secondary"
                  hidden={!canEditFundedOnwards || isEditing}
                  onClick={() => {
                    setModal({ ConfirmEdit: true });
                  }}
                >
                  Edit
                </Button>
                <ConfirmEditModal
                  isOpen={modals.ConfirmEdit}
                  onConfirm={() => {
                    setIsEditing(true);
                  }}
                />
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="SAVE"
                  isDisabled={!isValid || !canSave}
                  hidden={canEditFundedOnwards && !isEditing}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  SAVE
                </Button>
              </>
            }
          >
            <Box maxH="70vh">
              <VStack w="full" h="full" pb={2}>
                <HStack w="full">
                  <Input
                    label="License Plate Number"
                    name="car.license_plate_number"
                    onChange={customHandleChange}
                    onBlur={customHandleBlur}
                    isDisabled={isDisabled}
                  />
                  <Select
                    label="State"
                    name="car.license_plate_state"
                    options={SELECT_OPTIONS.US_STATES}
                    onChange={customHandleChange}
                    onBlur={customHandleBlur}
                    isDisabled={isDisabled}
                  />
                </HStack>

                <Input
                  label="VIN"
                  name="car.vin"
                  onChange={customHandleChange}
                  onBlur={customHandleBlur}
                  isDisabled={isDisabled}
                />

                <HStack w="full">
                  <NumberInput
                    label="Year"
                    name="car.year"
                    isDisabled={isDisabled}
                    isWhole
                    valueIsNumericString
                    isLoading={carDetailsLoading}
                  />
                  <Input
                    label="Make"
                    name="car.make"
                    isDisabled={isDisabled}
                    isLoading={carDetailsLoading}
                  />
                </HStack>

                <Input
                  label="Model"
                  name="car.model"
                  isDisabled={isDisabled}
                  isLoading={carDetailsLoading}
                />

                <Input label="Trim" name="car.kbb_trim_name" isDisabled={isDisabled} />

                <HStack w="full">
                  <NumberInput
                    label="Mileage"
                    name="car.mileage"
                    isDisabled={isDisabled}
                    showThousandSeparator
                    isWhole
                  />
                  <Select
                    label="Color"
                    name="car.color"
                    options={colorOptions}
                    isDisabled={isDisabled}
                  />
                </HStack>

                <HStack w="full">
                  <NumberInput label="KBB Lending" name="car.book_value" isMoney isWhole />
                  <NumberInput label="KBB Retail" name="car.retail_book_value" isMoney isWhole />
                </HStack>
                <HStack w="full">
                  <NumberInput
                    label="JD Power Lending"
                    name="car.jdp_adjusted_clean_trade"
                    isMoney
                    isWhole
                  />
                  <NumberInput
                    label="JD Power Retail"
                    name="car.jdp_adjusted_clean_retail"
                    isMoney
                    isWhole
                  />
                </HStack>
                <HStack w="full" display="flex" justifyContent="end">
                  <VehicleValuesCalculator
                    kbbSelectedOptions={deal.car.kbb_selected_options_object?.selected_options}
                  />
                </HStack>

                {deal.type === DealType.Acquisition && deal.financial_info_acquisition?.mmr ? (
                  <NumberInput label="MMR" name="car.mmr" isDisabled={isDisabled} isMoney isWhole />
                ) : null}

                <DatePicker
                  topLabel="Registration Expiration"
                  placeholder="MM YYYY"
                  name="car.registration_expiration"
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  boxStyles={{ width: '100%' }}
                />
              </VStack>
            </Box>

            <AutoExplodeVin
              callVinDecoder={callVinDecoder}
              setCallVinDecoder={setCallVinDecoder}
              isVinDirty={isVinDirty}
              setVinDirty={setVinDirty}
              setCarDetailsLoading={setCarDetailsLoading}
            />
            <AutoExplodeLicensePlate
              setCallVinDecoder={setCallVinDecoder}
              callLicensePlateDecoder={callLicensePlateDecoder}
              setCallLicensePlateDecoder={setCallLicensePlateDecoder}
              isLicensePlateNumberDirty={isLicensePlateNumberDirty}
              isLicensePlateStateDirty={isLicensePlateStateDirty}
              setLicensePlateNumberDirty={setLicensePlateNumberDirty}
              setLicensePlateStateDirty={setLicensePlateStateDirty}
              setCarDetailsLoading={setCarDetailsLoading}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditVehicleInfoModal;
