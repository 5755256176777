import { LazyQueryExecFunction } from '@apollo/client';
import Big from 'big.js';
import { cloneDeep } from 'lodash';

import { Deal, isDeal } from '../gql/dealGql';
import {
  GetFeesQuery,
  GetFeesQueryVariables,
  TtGetFeesSourceType,
  useGetFeesLazyQuery,
} from '../gql/generated/graphql';

import { USBankSlug } from '../constants/lienholders';
import { mapDealToGetTTFeesVariables } from '../utils/deals';
import {
  TemporaryDataWithTotalPayoff,
  mapTemporaryDataToGetTTFeesVariables,
} from '../utils/temporaryData';

import { useLazyLienholder } from './useLazyLienholder';

interface GetVehiclePayoffAndSalesTaxFromPayoffArgs {
  userEnteredTotalPayoff?: number;
  salesTaxFromPayoff?: number;
  salesTaxFromPayoffEnteredManually?: boolean;
  payoffIncludesSalesTax?: boolean;
  buyerNotLessee?: boolean;
  lienholderSlug?: string;
  doubleTax?: boolean;
  getTTFees: LazyQueryExecFunction<GetFeesQuery, GetFeesQueryVariables>;
  getTTFeesVariables: GetFeesQueryVariables;
}

interface Result {
  vehiclePayoff?: number;
  salesTaxFromPayoff?: number;
  doubleTaxApplied?: boolean;
}

const getVehiclePayoffAndSalesTaxFromPayoff = async ({
  userEnteredTotalPayoff = 0,
  salesTaxFromPayoff = 0,
  salesTaxFromPayoffEnteredManually,
  payoffIncludesSalesTax,
  buyerNotLessee,
  lienholderSlug,
  doubleTax,
  getTTFees,
  getTTFeesVariables: variables,
}: GetVehiclePayoffAndSalesTaxFromPayoffArgs): Promise<Result> => {
  if (!payoffIncludesSalesTax) {
    return {
      vehiclePayoff: userEnteredTotalPayoff,
      salesTaxFromPayoff: 0,
      doubleTaxApplied: false,
    };
  }

  const isDoubleTaxApplicable = buyerNotLessee && doubleTax && lienholderSlug !== USBankSlug;

  if (salesTaxFromPayoffEnteredManually) {
    const currentVehiclePayoff = new Big(userEnteredTotalPayoff)
      .minus(salesTaxFromPayoff ?? 0)
      .round(2)
      .toNumber();

    return {
      vehiclePayoff: isDoubleTaxApplicable ? userEnteredTotalPayoff : currentVehiclePayoff,
      salesTaxFromPayoff,
      doubleTaxApplied: isDoubleTaxApplicable,
    };
  }

  const result = await getTTFees({ variables });
  const salesTaxRate = result?.data?.getFees?.salesTaxRate ?? 0;
  const denominator = new Big(salesTaxRate).plus(1);

  const totalPayoffWithoutSalesTax = new Big(userEnteredTotalPayoff)
    .div(denominator)
    .round(2)
    .toNumber();
  const newVehiclePayoff = isDoubleTaxApplicable
    ? userEnteredTotalPayoff
    : totalPayoffWithoutSalesTax;
  const newSalesTaxFromPayoff = new Big(userEnteredTotalPayoff)
    .minus(totalPayoffWithoutSalesTax)
    .round(2)
    .toNumber();

  return {
    vehiclePayoff: newVehiclePayoff,
    salesTaxFromPayoff: newSalesTaxFromPayoff,
    doubleTaxApplied: isDoubleTaxApplicable,
  };
};

const getUpdatedDealPayoffValues = async (
  deal: Deal,
  getTTFees: LazyQueryExecFunction<GetFeesQuery, GetFeesQueryVariables>,
  doubleTax?: boolean,
  userEnteredTotalPayoff?: number,
): Promise<Result> => {
  const newDeal = cloneDeep(deal);

  const getTTFeesVariables = mapDealToGetTTFeesVariables(newDeal, {
    ttEndpoint: 'getFees',
    ttGetFeesSource: TtGetFeesSourceType.DdPayoffTax,
  });
  const payoffValues = await getVehiclePayoffAndSalesTaxFromPayoff({
    userEnteredTotalPayoff,
    salesTaxFromPayoff: newDeal.car.payoff.sales_tax_from_payoff,
    salesTaxFromPayoffEnteredManually: newDeal.car.payoff.sales_tax_from_payoff_entered_manually,
    payoffIncludesSalesTax: newDeal.car.payoff.payoff_includes_sales_tax,
    buyerNotLessee: newDeal.financial_info?.buyer_not_lessee,
    lienholderSlug: newDeal.car.payoff.lienholder_slug,
    doubleTax,
    getTTFees,
    getTTFeesVariables: { ...getTTFeesVariables, financialInfoId: newDeal.financial_info?.id },
  });

  return payoffValues;
};

const getUpdatedTemporaryDataPayoffValues = async (
  temporaryData: TemporaryDataWithTotalPayoff,
  getTTFees: LazyQueryExecFunction<GetFeesQuery, GetFeesQueryVariables>,
  doubleTax?: boolean,
  userEnteredTotalPayoff?: number,
): Promise<Result> => {
  const newTemporaryData = cloneDeep(temporaryData);

  const getTTFeesVariables = mapTemporaryDataToGetTTFeesVariables(newTemporaryData);
  const payoffValues = await getVehiclePayoffAndSalesTaxFromPayoff({
    userEnteredTotalPayoff,
    salesTaxFromPayoff: newTemporaryData.sales_tax_from_payoff ?? 0,
    salesTaxFromPayoffEnteredManually: !!newTemporaryData.sales_tax_from_payoff_entered_manually,
    payoffIncludesSalesTax: !!newTemporaryData.payoff_includes_sales_tax,
    // TODO: buyerNotLessee is not available in COM so double tax is not applicable at this point
    buyerNotLessee: false,
    lienholderSlug: newTemporaryData.lienholder_slug ?? '',
    doubleTax,
    getTTFees,
    getTTFeesVariables,
  });

  return payoffValues;
};

interface Parameters {
  data: Deal | TemporaryDataWithTotalPayoff;
  userEnteredTotalPayoff?: number;
}

export const useGetUpdatedPayoffValues = () => {
  const [getTTFees] = useGetFeesLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const { getLienholder } = useLazyLienholder();

  const getUpdatedPayoffValues = async ({ data, userEnteredTotalPayoff }: Parameters) => {
    const { doubleTax } = await getLienholder(data);

    if (isDeal(data)) {
      return getUpdatedDealPayoffValues(data, getTTFees, doubleTax, userEnteredTotalPayoff);
    }

    return getUpdatedTemporaryDataPayoffValues(data, getTTFees, doubleTax, userEnteredTotalPayoff);
  };

  return { getUpdatedPayoffValues } as {
    getUpdatedPayoffValues: (parameters: Parameters) => Promise<Result>;
  };
};
