import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import { Row, Col, Table } from 'react-bootstrap';
import { Button, IconButton } from '@chakra-ui/react';
import { LinkContainer } from 'react-router-bootstrap';
import { BiArrowBack, BiTrash, BiEdit } from 'react-icons/bi';
import { FaThumbsUp } from 'react-icons/fa';
import Toggle from 'react-toggle';
import LeaseEndContainer from '../../components/Container/Container';
import Loader from '../../components/Loader';
import ROUTES from '../../constants/routes';
import { Review, listReviews, createReview, deleteReview, updateReview } from '../../gql/reviewGql';
import { formatDate, truncateString } from '../../libs/utils';
import UpsertReviewModal from './components/UpsertReviewModal';
import DeleteReviewModal from './components/DeleteReviewModal';

const ReviewsPage: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewToEdit, setReviewToEdit] = useState<Review | null>(null);
  const [createReviewMutation] = useMutation(createReview);
  const [deleteReviewMutation] = useMutation(deleteReview);
  const [updateReviewMutation] = useMutation(updateReview);

  const { data, loading } = useQuery(listReviews, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.reviews) {
      setReviews(data.reviews);
    }
  }, [data]);

  const handleUpdate = async (values: Review) => {
    try {
      await updateReviewMutation({ variables: { review: { ...values } } });
      const index = reviews.findIndex((review) => review.id === values.id);
      setReviews((prev) => [...prev.slice(0, index), values, ...prev.slice(index + 1)]);

      setShowModal(false);
    } catch {
      toast.error('Failed to update review.');
    }
  };

  const handleCreate = async (values: Review) => {
    try {
      const {
        data: {
          createReview: { id },
        },
      } = await createReviewMutation({
        variables: { review: { ...values } },
      });
      setReviews((prev) => [{ ...values, id }, ...prev]);
      setShowModal(false);
    } catch {
      toast.error('Failed to add review.');
    }
  };

  const handleSubmit = async (values: Review) => {
    if (reviewToEdit) {
      await handleUpdate(values);
    } else {
      await handleCreate(values);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteReviewMutation({
        variables: { id: idToDelete },
      });
      setReviews((prev) => prev.filter((review) => review.id !== idToDelete));
      setShowDeleteModal(false);
    } catch {
      toast.error('Failed to delete review.');
    }
  };

  return (
    <>
      <Loader isLoading={loading} />
      <LeaseEndContainer fluid>
        <Row className="align-items-start justify-content-between mb-3">
          <Col xs="auto">
            <LinkContainer to={ROUTES.DASHBOARD}>
              <Button size="lgWithIconLeft" leftIcon={<BiArrowBack />} variant="secondary">
                DASHBOARD
              </Button>
            </LinkContainer>
            <Button
              size="lgWithIconLeft"
              leftIcon={<FaThumbsUp />}
              onClick={() => {
                setReviewToEdit(null);
                setShowModal(true);
              }}
            >
              ADD REVIEW TO COM
            </Button>
          </Col>
        </Row>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Location</th>
              <th>Customer Review</th>
              <th>Date Added</th>
              <th className="text-center">COM Visibility</th>
              <th className="text-center">Edit</th>
              <th className="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            {reviews.map((review) => (
              <tr key={review.id}>
                <td>{review.customer_name}</td>
                <td>{review.location}</td>
                <td>{truncateString(review.review, 100)}</td>
                <td>{formatDate(review.created_at)}</td>
                <td align="center">
                  <Toggle
                    icons={false}
                    name="com_visible"
                    checked={review.com_visible}
                    onChange={() =>
                      handleUpdate({
                        ...review,
                        com_visible: !review.com_visible,
                      })
                    }
                  />
                </td>
                <td align="center">
                  <IconButton
                    icon={<BiEdit />}
                    variant="iconHover"
                    size="xs"
                    aria-label="Edit Review"
                    onClick={() => {
                      setShowModal(true);
                      setReviewToEdit(review);
                    }}
                  />
                </td>
                <td align="center">
                  <IconButton
                    icon={<BiTrash />}
                    variant="iconHover"
                    color="errorsRed"
                    size="xs"
                    aria-label="Delete Review"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setIdToDelete(review.id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </LeaseEndContainer>
      <UpsertReviewModal
        handleOnSubmit={handleSubmit}
        showModal={showModal}
        setShowModal={setShowModal}
        reviewToEdit={reviewToEdit}
      />
      <DeleteReviewModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default ReviewsPage;
