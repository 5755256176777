import { FC, ReactNode } from 'react';
import { VStack, StackProps, LayoutProps } from '@chakra-ui/react';

interface MessageCellProps extends StackProps {
  minW?: LayoutProps['minW'];
  maxW?: LayoutProps['maxW'];
  children: ReactNode;
}

const MessageCell: FC<MessageCellProps> = ({ minW = 'auto', maxW = 'auto', children, ...rest }) => {
  return (
    <VStack minW={minW} maxW={maxW} minH="100%" p="4px" align="start" verticalAlign="top" {...rest}>
      {children}
    </VStack>
  );
};

export default MessageCell;
