import { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Tooltip } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { MdWarning } from 'react-icons/md';
import { toast } from 'react-toastify';

import { OdometerStatus, getFullCarName, getWarrantyInfoQuery } from '../../gql/carGql';
import { Deal, DealStateEnum } from '../../gql/dealGql';
import { Deal as GeneratedDeal, useVehicleInfoUpsertMutation } from '../../gql/generated/graphql';

import EditVehicleInfoModal from '../EditVehicleInfoModal/EditVehicleInfoModal';
import InfoCardV2 from '../InfoCard/InfoCardV2';

import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { ModalContext } from '../../libs/contextLib';
import { formatMileage, formatMoney, getUTCDate } from '../../libs/utils';
import { cleanDealForUpdate } from '../../utils/deals';

const VehicleInfo = () => {
  const { deal, dispatch } = useContext(DealContext);
  const { modals, setModal } = useContext(ModalContext);
  const [upsertVehicleInfo] = useVehicleInfoUpsertMutation();
  const [warrantyInfo, setWarrantyInfo] = useState<{
    full_years: number;
    full_miles: number;
    full_status: string;
    power_train_years: number;
    power_train_miles: number;
    power_train_status: string;
  }>();
  const getWarrantyInfo = useQuery(getWarrantyInfoQuery, {
    variables: {
      make: deal.car.make,
      mileage: deal.car.mileage,
      year: deal.car.year,
    },
    skip: !deal.car.make || !deal.car.mileage || !deal.car.year,
  });

  useEffect(() => {
    if (getWarrantyInfo.data?.getWarrantyInfo) {
      setWarrantyInfo(getWarrantyInfo.data.getWarrantyInfo);
    }
  }, [getWarrantyInfo.data]);

  const onSubmit = async (values: Deal, setSubmitting: (isSubmitting: boolean) => void) => {
    const newDeal = cleanDealForUpdate(values);

    // TODO: temporary conversion. We should remove all classes and use the generated classes
    const generatedNewDeal = newDeal as unknown as GeneratedDeal;
    try {
      const { data } = await upsertVehicleInfo({
        variables: {
          deal: {
            ...generatedNewDeal,
            car: {
              ...generatedNewDeal.car,
              registration_expiration: generatedNewDeal.car?.registration_expiration,
              odometer_status:
                deal.car?.mileage && deal.car.mileage !== values.car.mileage
                  ? OdometerStatus.Changed
                  : deal.car.odometer_status,
            },
          },
        },
      });

      // TODO: temporary conversion. We should remove all classes and use the generated classes
      const updatedDeal = (data?.vehicleInfoUpsert ?? {}) as Deal;
      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          ...deal,
          car: {
            ...deal.car,
            ...updatedDeal.car,
          },
          financial_info_acquisition:
            deal.financial_info_acquisition || updatedDeal.financial_info_acquisition
              ? {
                  ...deal.financial_info_acquisition,
                  ...updatedDeal.financial_info_acquisition,
                }
              : undefined,
        },
      });
      setSubmitting(false);
      toast.success('Successfully edited Vehicle Info');
      setModal({ VehicleInfo: false });
    } catch (e) {
      toast.error('There was an error editing the Vehicle Info');
      logger.error('VehiclInfo.tsx', '', null, e);
      setSubmitting(false);
    }
  };

  const getOdometerStatus = (status?: OdometerStatus) => {
    let tooltip = null;
    let Icon = null;

    switch (status) {
      case OdometerStatus.Verified:
        tooltip = 'Mileage Verified';
        Icon = <IoIosCheckmarkCircle color="#8CC63F" size={24} />;
        break;
      case OdometerStatus.Changed:
        tooltip = 'Mileage has been changed and not verified.';
        Icon = <MdWarning color="#F3C43D" size={24} />;
        break;
      case OdometerStatus.Warning:
        tooltip = 'The mileage entered ends in repeating digits so it has not been verified.';
        Icon = <MdWarning color="#F3C43D" size={24} />;
        break;
      default:
        return null;
    }

    return (
      <Tooltip label={tooltip} placement="top">
        <Box minW="16px">{Icon}</Box>
      </Tooltip>
    );
  };

  return (
    <>
      <InfoCardV2
        name="Vehicle Info"
        values={{
          Vehicle: getFullCarName(deal.car),
          Trim: deal.car.kbb_trim_name,
          'License Plate Number': deal.car.license_plate_number,
          'License Plate State': deal.car.license_plate_state,
          VIN: deal.car.vin,
          Mileage: {
            render: (
              <Box display="flex" alignItems="center">
                <Box mr="4px">{formatMileage(deal.car.mileage)}</Box>
                {getOdometerStatus(deal.car.odometer_status)}
              </Box>
            ),
            value: deal.car.mileage?.toString(),
          },
          Color: deal.car.color,
          'Full Warranty': warrantyInfo
            ? `${warrantyInfo.full_years}/${warrantyInfo.full_miles.toLocaleString('en-US')} - ${
                warrantyInfo.full_status
              }`
            : '',
          'Powertrain Warranty': warrantyInfo
            ? `${warrantyInfo.power_train_years}/${warrantyInfo.power_train_miles.toLocaleString(
                'en-US',
              )} - ${warrantyInfo.power_train_status}`
            : '',

          'KBB Lending': formatMoney(deal.car.book_value),
          'KBB Retail': formatMoney(deal.car.retail_book_value),
          'JD Power Lending': formatMoney(deal.car.jdp_adjusted_clean_trade),
          'JD Power Retail': formatMoney(deal.car.jdp_adjusted_clean_retail),
          'Registration Expiration': deal?.car?.registration_expiration
            ? getUTCDate(deal.car.registration_expiration).toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
              })
            : '',
        }}
        hideEmpty
        showEditable={deal.state !== DealStateEnum.Estimate}
        editAction={() => setModal({ VehicleInfo: true })}
      />

      <EditVehicleInfoModal
        onSubmit={onSubmit}
        isOpen={modals.VehicleInfo}
        onClose={() => setModal({ VehicleInfo: false })}
      />
    </>
  );
};

export default VehicleInfo;
