import { FC, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';

import {
  useLoggedUserQuery,
  useSwitchCallStatusIndividualSubscription,
  useSwitchCallStatusSubscription,
} from '../../gql/generated/graphql';
import { updateUserCallStatus } from '../../gql/userGql';

import button from '../../chakra/components/button';
import ActiveMenuItem from '../Navbar/ActiveMenuItem';

const options = [
  { label: 'ACTIVE', value: 'active' },
  // { label: 'DIRECT', value: 'direct' },
  { label: 'INACTIVE', value: 'inactive' },
];

export const getOptionColor = (option: string | null) => {
  const colors = {
    red: '#BE170E',
    // blue: '#399AEC',
    green: '#8CC243',
  };

  switch (option) {
    case 'inactive':
      return colors.red;
    // case 'direct':
    //   return colors.blue;
    default:
      return colors.green;
  }
};

export const UserStatusSwitcher: FC = () => {
  const [updateStatus] = useMutation(updateUserCallStatus);
  const { data: switchCallStatusSubscriptionData } = useSwitchCallStatusSubscription();
  const { data: loggedUserData, refetch: refetchLoggedUser } = useLoggedUserQuery();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useSwitchCallStatusIndividualSubscription({
    onData: ({ data }) => {
      const newStatus = data?.data?.switchCallStatusIndividual;
      if (newStatus && newStatus !== selectedOption) {
        setSelectedOption(newStatus);
        refetchLoggedUser();
      }
    },
  });

  const handleChange = async (value: string) => {
    setSelectedOption(value);
    await updateStatus({
      variables: {
        status: value,
      },
    });
    refetchLoggedUser();
  };

  useEffect(() => {
    if (switchCallStatusSubscriptionData?.switchCallStatus) {
      setSelectedOption(switchCallStatusSubscriptionData.switchCallStatus);
    }
  }, [switchCallStatusSubscriptionData]);

  useEffect(() => {
    const loggedUser = loggedUserData?.loggedUser;
    if (!loggedUser?.call_status) {
      return;
    }

    // We are removing the direct option for now
    if (loggedUser.call_status === 'direct') {
      handleChange('active');
      return;
    }

    setSelectedOption(loggedUser.call_status);
  }, [loggedUserData]);

  const optionColor = getOptionColor(selectedOption);
  // eslint-disable-next-line no-underscore-dangle
  const activeNavBarButtonVariant = button.variants.navBar._active;
  const styleProps = {
    ...activeNavBarButtonVariant,
    bg: optionColor,
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="navBar"
        size={{ base: 'sm', sm: 'md' }}
        w={{ base: '100px', sm: '140px' }}
        rightIcon={<ChevronDownIcon w="2em" h="2em" />}
        _hover={styleProps}
        _active={styleProps}
        {...styleProps}
      >
        {selectedOption ?? 'Select an option'}
      </MenuButton>
      <MenuList zIndex="dropdown" fontSize={{ base: 'xs', sm: 'md' }}>
        {options.map(({ label, value }) => (
          <ActiveMenuItem
            key={value}
            isActive={selectedOption === value}
            onClick={() => handleChange(value)}
          >
            {label}
          </ActiveMenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
