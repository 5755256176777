import { FC, useContext, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, dealInfoUpsert } from '../../../../gql/dealGql';
import { isGapSelected, isVscSelected } from '../../../../gql/financialInfoGql';

import Modal from '../../../shared/Modal';
import NumberInput from '../../../shared/NumberInput';
import Tooltip from '../../../shared/Tooltip';
import RequiredDocModal from '../RequiredDocModal';

import { ChryslerCapitalSlug, HondaFinancialSlug } from '../../../../constants/lienholders';
import { PermissionEnum } from '../../../../constants/permissions';
import { Can } from '../../../../libs/Can';
import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';
import { AbilityContext } from '../../../../libs/contextLib';
import { isPayoffEstimated } from '../../../DealInfoAcquisition/FinalizeDealButton';
import { docStates } from '../../../EstimateForm/payoffDocStates';
import PayoffReminderModal from '../../../PayoffReminderModal/PayoffReminderModal';

interface CloseDealProps {
  showPaperworkOptions: boolean;
  setShowPaperworkOptions: (showPaperworkOptions: boolean) => void;
}

const CloseDeal: FC<CloseDealProps> = ({ showPaperworkOptions, setShowPaperworkOptions }) => {
  const { deal, dispatch, documentMedia, isRecalculatingPayoff, isPayoffRequested } =
    useContext(DealContext);
  const abilities = useContext(AbilityContext);
  const { unverifiedRequiredMediaList, missingRequiredMediaList } = documentMedia;

  const { values, errors, isSubmitting, setSubmitting } = useFormikContext<Deal>();

  const [showReminderModal, setShowReminderModal] = useState(false);
  const requiredDocModal = useDisclosure();
  const {
    isOpen: isPayoffModalOpen,
    onOpen: onOpenPayoffModal,
    onClose: onClosePayoffModal,
  } = useDisclosure();

  const [upsertDealInfo] = useMutation(dealInfoUpsert);

  const tooltipErrors = useMemo(() => {
    const newTooltipErrors = [];

    if (!values.financial_info.option_type) {
      newTooltipErrors.push('Select a Package');
    }

    if (isPayoffRequested) {
      newTooltipErrors.push('Cannot have an active payoff request');
    }

    if (isVscSelected(values.financial_info.option_type)) {
      if (
        (!values.financial_info.pen_vsc_form_id ||
          !values.financial_info.pen_vsc_rate_id ||
          !values.financial_info.pen_vsc_session_id) &&
        !abilities.has(PermissionEnum.SuperUser)
      ) {
        newTooltipErrors.push('Calculate VSC Rates');
      }
      if (errors.financial_info?.vsc_price) {
        newTooltipErrors.push(errors.financial_info?.vsc_price);
      }
      if (errors.financial_info?.vsc_cost) {
        newTooltipErrors.push(errors.financial_info?.vsc_cost);
      }
    }

    if (isGapSelected(values.financial_info.option_type)) {
      if (
        (!values.financial_info.pen_gap_form_id ||
          !values.financial_info.pen_gap_rate_id ||
          !values.financial_info.pen_gap_session_id) &&
        !abilities.has(PermissionEnum.SuperUser)
      ) {
        newTooltipErrors.push('Calculate GAP Rate');
      }
      if (errors.financial_info?.gap_price) {
        newTooltipErrors.push(errors.financial_info?.gap_price);
      }
      if (errors.financial_info?.gap_cost) {
        newTooltipErrors.push(errors.financial_info?.gap_cost);
      }
    }

    return newTooltipErrors;
  }, [values.financial_info, errors, isPayoffRequested]);

  const isDisabled = tooltipErrors.length > 0;

  const onFinalizePayment = () => {
    upsertDealInfo({
      variables: {
        financialInfo: values.financial_info,
        car: values.car,
      },
    })
      .then(({ data }) => {
        dispatch({ type: DealActionsEnum.UpdateDeal, payload: data.dealInfoUpsert });
        onClosePayoffModal();
        setShowPaperworkOptions(true);
      })
      .catch((e) => {
        logger.error('CloseDealButton.tsx', 'Failed to close deal', null, e);
        toast.error('Failed to close deal');
      })
      .finally(() => setSubmitting(false));
  };

  const onSave = (setSaving: (isSaving: boolean) => void) => {
    upsertDealInfo({
      variables: {
        financialInfo: values.financial_info,
        car: values.car,
      },
    })
      .catch((e) => {
        logger.error('FinalizePaymentButton.tsx', 'Failed to save data', null, e);
        toast.error('Failed to save data');
      })
      .finally(() => setSaving(false));
  };

  const isHidden = showPaperworkOptions ? true : deal.state !== DealStateEnum.Closing;

  const shouldShowReminderModal = () => {
    if (
      values.car.payoff.lienholder_slug === ChryslerCapitalSlug ||
      (values.car.payoff.lienholder_slug === HondaFinancialSlug &&
        docStates.includes(values.customer.address?.state))
    ) {
      return true;
    }

    return false;
  };

  const shouldShowRequiredDocModal = () =>
    !requiredDocModal.isOpen &&
    (unverifiedRequiredMediaList.length > 0 || missingRequiredMediaList.length > 0);

  const handleModalFinalizePayment = () => {
    if (shouldShowReminderModal()) {
      setShowReminderModal(true);
      onClosePayoffModal();
      return;
    }

    onFinalizePayment();
  };

  const handleFinalizePayment = () => {
    if (shouldShowRequiredDocModal()) {
      requiredDocModal.onOpen();
      return;
    }

    requiredDocModal.onClose();

    if (isPayoffEstimated(deal.car.payoff.vehicle_payoff ?? 0)) {
      onOpenPayoffModal();
      return;
    }

    if (shouldShowReminderModal()) {
      setShowReminderModal(true);
      return;
    }

    onFinalizePayment();
  };

  return (
    <Can I={PermissionEnum.FinalizePayment}>
      <Tooltip errors={tooltipErrors}>
        <Button
          isLoading={isSubmitting}
          loadingText="Close Deal"
          isDisabled={isDisabled || isRecalculatingPayoff}
          hidden={isHidden}
          onClick={handleFinalizePayment}
        >
          Close Deal
        </Button>
      </Tooltip>
      <Modal
        isOpen={isPayoffModalOpen}
        title="Vehicle Payoff Confirmation"
        size="lg"
        onClose={onClosePayoffModal}
        rightButtons={
          <Button
            isLoading={isSubmitting}
            loadingText="Close Deal"
            isDisabled={!values.financial_info.option_type}
            hidden={isHidden}
            onClick={handleModalFinalizePayment}
          >
            Close Deal
          </Button>
        }
      >
        <Text mb={4}>
          Please confirm the vehicle payoff is accurate and not an estimate. Is this correct?
        </Text>
        <NumberInput name="car.payoff.vehicle_payoff" label="Payoff" isMoney max={9999999} />
      </Modal>
      <PayoffReminderModal
        show={showReminderModal}
        hide={() => setShowReminderModal(false)}
        saveFn={onSave}
        finalizeFn={onFinalizePayment}
        values={values}
      />
      <RequiredDocModal
        {...requiredDocModal}
        unverifiedRequiredMediaList={unverifiedRequiredMediaList}
        missingRequiredMediaList={missingRequiredMediaList}
        onSuccessButtonLabel="Close Deal"
        onSuccess={handleFinalizePayment}
      />
    </Can>
  );
};

export default CloseDeal;
