import { useContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Deal, bootedDealsQuery } from '../../gql/dealGql';
import {
  getDealNotificationCountByType,
  getDealsNotificationCountByType,
} from '../../gql/notificationsGql';

import { BucketCard, BucketHeader } from '../DealsSwimlane/DealsSwimlane';
import ExportDealsToXlsxButton from '../ExportDealsToXlsxButton/ExportDealsToXlsxButton';
import Loader from '../Loader';
import {
  NewTableNotificationsContainer,
  NewTitleNotificationsContainer,
} from '../NotificationBadge/NotificationsContainer';
import TextNotificationBadgeV2 from '../NotificationBadge/TextNotificationBadgeV2';

import { FiltersContext } from '../../libs/contextLib';
import { handleClickOrCommandClick } from '../../libs/eventHandlers';
import { DealWithCalculatedProps, bootedDealColumns } from '../../pages/BootedDealsPage/constants';
import { getBootedDealState } from '../../pages/BootedDealsPage/utils';

interface BootedDealsProps {
  start_date: Date;
  end_date: Date;
  showOnlyUnreadMessages: boolean;
}

const BootedDeals = ({ start_date, end_date, showOnlyUnreadMessages }: BootedDealsProps) => {
  const {
    filters: {
      global: {
        selectedPodId,
        sources,
        types,
        showAssignedToMe,
        showAssignedToDeletedUsers,
        showActiveTags,
        showMatureFollowUps,
      },
    },
  } = useContext(FiltersContext);

  const history = useHistory();

  const { data, loading } = useQuery<{ bootedDeals: Deal[] }>(bootedDealsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      assigned_to_me_filter: showAssignedToMe,
      assigned_to_deleted_users_filter: showAssignedToDeletedUsers,
      start_date,
      end_date,
      sources,
      types,
      pod_id: selectedPodId,
      unread_messages_filter: showOnlyUnreadMessages,
      showActiveTags,
      showMatureFollowUps,
    },
  });

  const processedDeals = useMemo<DealWithCalculatedProps[]>(() => {
    if (data?.bootedDeals) {
      return data.bootedDeals.map((deal) => ({
        ...deal,
        dealNotificationCountByType: getDealNotificationCountByType(deal),
        lastDealState: getBootedDealState(deal),
      }));
    }
    return [];
  }, [data?.bootedDeals]);

  const notificationCountByType = useMemo(
    () => getDealsNotificationCountByType(processedDeals),
    [processedDeals],
  );

  return (
    <BucketCard>
      <BucketHeader>
        Booted Deals
        <NewTitleNotificationsContainer>
          <TextNotificationBadgeV2 count={notificationCountByType.messages} />
        </NewTitleNotificationsContainer>
        <ExportDealsToXlsxButton
          deals={processedDeals}
          columns={bootedDealColumns}
          dealType="booted"
        />
      </BucketHeader>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th width="20px" />
              {bootedDealColumns.map(({ header }) => (
                <Th key={header}>{header}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {processedDeals.map((deal) => (
              <Tr
                key={deal.id}
                cursor="pointer"
                _hover={{ bgColor: 'hoverGray' }}
                onClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
                onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
              >
                <Td key="Notifications">
                  <NewTableNotificationsContainer style={{ margin: 0 }}>
                    <TextNotificationBadgeV2
                      count={deal.dealNotificationCountByType?.messages}
                      deal={deal}
                    />
                  </NewTableNotificationsContainer>
                </Td>
                {bootedDealColumns.map(({ header, getValue }) => (
                  <Td key={header} whiteSpace="normal" maxWidth="lg">
                    {getValue(deal)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Loader isLoading={loading} />
    </BucketCard>
  );
};

export default BootedDeals;
