import { FC, SyntheticEvent, useContext, useState } from 'react';

import { FetchResult, useMutation } from '@apollo/client';
import { Box, HStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { relationshipToBuyerOptions } from '../../gql/customerGql';
import { Deal, creditAppUpsert } from '../../gql/dealGql';

import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import Input from '../shared/Input';
import Select from '../shared/Select';
import Autosaving from './components/Autosaving';

import { cleanDeal, closeOrCancelButtonTriggedBlur } from './CreditPerson';

import { phoneNumberMask } from '../../constants/masks';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';

interface Props {
  isSecond?: boolean;
}

const AdditionalContact: FC<Props> = ({ isSecond }) => {
  const [autosaving, setAutosaving] = useState<boolean>(false);
  const [saveTime, setSaveTime] = useState<string>('');
  const { deal, dispatch } = useContext(DealContext);
  const { values, handleBlur, dirty, setFieldValue } = useFormikContext<Deal>();
  const name = isSecond ? 'second_contact' : 'contact';

  const [upsertCreditApp] = useMutation(creditAppUpsert);

  const setInsertedIds = (res: FetchResult<{ creditAppUpsert: Deal }>) => {
    const personValues = values?.[name];
    const personRes = res?.data?.creditAppUpsert?.[name];

    if (personValues?.id !== personRes?.id) {
      setFieldValue(`${name}.id`, personRes?.id);
    }
  };

  const handleOnBlur = async (
    e: SyntheticEvent<HTMLInputElement | HTMLSelectElement> | undefined,
  ) => {
    if (autosaving) {
      return;
    }

    if (closeOrCancelButtonTriggedBlur(e)) {
      return;
    }

    handleBlur(e);

    const cleanedValues = cleanDeal({ values, deal });

    if (dirty) {
      setSaveTime(new Date().toLocaleTimeString('en-US'));
      setAutosaving(true);
      try {
        const res = await upsertCreditApp({
          variables: {
            deal: cleanedValues,
          },
        });
        // avoids multiple inserts
        setInsertedIds(res);
        dispatch({ type: DealActionsEnum.UpdateDeal, payload: res.data.creditAppUpsert });
      } catch (error) {
        toast.error(`${error}`);
      } finally {
        setAutosaving(false);
      }
    }
  };

  return (
    <Card variant="rounded">
      <CardHeaderV2 title={`${isSecond ? 'Second ' : ''}Contact`} variant="square">
        <Autosaving autosaving={autosaving} saveTime={saveTime} />
      </CardHeaderV2>
      <Box mx={6}>
        <HStack pt={3} justifyContent="space-between">
          <Select
            label="Relation To Buyer"
            name={`${name}.relation_to_buyer`}
            onBlur={handleOnBlur}
            emptyOption={false}
            options={relationshipToBuyerOptions}
            formControlProps={{
              width: '25%',
            }}
          />
        </HStack>
        <HStack mt={3} alignItems="start">
          <Input label="First Name" name={`${name}.first_name`} onBlur={handleOnBlur} />
          <Input label="Middle Name" name={`${name}.middle_name`} onBlur={handleOnBlur} />
          <Input label="Last Name" name={`${name}.last_name`} onBlur={handleOnBlur} />
        </HStack>
        <HStack mt={3} w="66.66%" alignItems="start">
          <Input
            label="Phone Number"
            name={`${name}.phone_number`}
            onBlur={handleOnBlur}
            mask={phoneNumberMask}
            copyButton
          />
          <Input label="Email" name={`${name}.email`} onBlur={handleOnBlur} copyButton />
        </HStack>
      </Box>
    </Card>
  );
};

export default AdditionalContact;
