import { useState } from 'react';

import { useQuery } from '@apollo/client';

import { lienholdersQuery } from '../gql/prs/lienholderGql';
import { LienholderPRSType } from '../gql/prs/types';

import { Option } from '../components/shared/types';
import { OtherLienholderSlug } from '../constants/lienholders';
import { mapPRSLienholdersToOptions } from '../utils/prs';

interface Props {
  state?: string;
  make?: string;
}

export const useLienholders = ({ state = '', make = '' }: Props = {}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [probableOptions, setProbableOptions] = useState<Option[]>([]);

  const { loading } = useQuery<{ lienholders: LienholderPRSType[] }>(lienholdersQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      // Default state to get initial lienholders. Could be any state.
      state: state || 'UT',
      make,
    },
    onCompleted: ({ lienholders }) => {
      if (!lienholders?.length) {
        setOptions([]);
        setProbableOptions([]);
        return;
      }

      const sortedLienholders = lienholders.slice().sort((a, b) => a.slug.localeCompare(b.slug));
      setOptions(mapPRSLienholdersToOptions(sortedLienholders));

      const probableLienholders = sortedLienholders.filter(
        (lienholder) => lienholder.probableLienholder || lienholder.slug === OtherLienholderSlug,
      );
      setProbableOptions(mapPRSLienholdersToOptions(probableLienholders));
    },
    onError: () => {
      setOptions([]);
      setProbableOptions([]);
    },
  });

  return {
    loading,
    options,
    probableOptions,
  };
};
