import { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

type Variant = 'square' | 'rounded' | 'roundedNoMargin' | 'roundedWithBorder';

const borderProps: BoxProps = { border: '1px solid', borderColor: 'snowyWhite' };
const roundedWhiteProps: BoxProps = { borderRadius: '10px', bgColor: 'white' };

const variants: Record<Variant, BoxProps> = {
  square: { ...borderProps },
  rounded: { ...roundedWhiteProps, pb: 5, mb: 5 },
  roundedNoMargin: { ...roundedWhiteProps, mb: 0, pb: 3 },
  roundedWithBorder: { ...roundedWhiteProps, ...borderProps, pb: 5, mb: 5 },
};

type CardProps = PropsWithChildren<BoxProps> & {
  variant?: Variant;
};

const Card = ({ variant = 'square', children, ...rest }: CardProps) => {
  const variantProps = variants[variant];

  return (
    <Box {...variantProps} {...rest}>
      {children}
    </Box>
  );
};

export default Card;
