import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { DealStateEnum, dealUpdateStateAndSendEmails } from '../../../gql/dealGql';
import {
  DealType,
  DocumentProgressStatus,
  PaperworkType,
  useDealUpdateStateMutation,
} from '../../../gql/generated/graphql';

import { PermissionEnum } from '../../../constants/permissions';
import { DealContext } from '../../../libs/DealContext';
import { logger } from '../../../libs/Logger';
import { AbilityContext } from '../../../libs/contextLib';

interface Props {
  width?: string;
}

const MarkAsSigned = ({ width }: Props) => {
  const { deal, dealRefetch, claimIsRequired } = useContext(DealContext);
  const [updateDealState] = useDealUpdateStateMutation();
  const [updateDealStateAndFirstPaymentDate] = useMutation(dealUpdateStateAndSendEmails);

  const abilities = useContext(AbilityContext);
  const canMarkAsSigned = abilities.has(PermissionEnum.ManageSigningProcess);
  const isDisabled =
    deal.document_progress_status !== DocumentProgressStatus.SentForSignatures ||
    deal.state !== DealStateEnum.SentForSignatures ||
    !canMarkAsSigned ||
    claimIsRequired;
  const isPaperSign = deal.paperwork_type === PaperworkType.Paper;

  const onMarkAsSigned = async () => {
    if (!deal.id) {
      return;
    }
    try {
      if (deal.type === DealType.Buyout || deal.type === DealType.Refi) {
        await updateDealStateAndFirstPaymentDate({
          variables: {
            id: deal.id,
            state: DealStateEnum.Signed,
          },
        });
      } else {
        await updateDealState({
          variables: {
            id: deal.id,
            state: DealStateEnum.Signed,
          },
        });
      }
      toast.success('Moved deal to "Signed"!');
      dealRefetch();
    } catch (e) {
      logger.error('MarkAsSigned.tsx', '', null, e);
      toast.error('Failed to move deal to "Signed". Please refresh the page');
    }
  };

  return (
    <Button
      variant={isPaperSign ? 'primary' : 'secondary'}
      size={isPaperSign ? 'lg' : 'sm'}
      w={width}
      onClick={onMarkAsSigned}
      isDisabled={isDisabled}
    >
      SIGNED
    </Button>
  );
};

export default MarkAsSigned;
