import { ComponentStyleConfig } from '@chakra-ui/react';

const spinner: ComponentStyleConfig = {
  baseStyle: {
    color: 'ceruleanBlue',
  },
  variants: {
    thick: {
      borderWidth: '4px',
    },
    autosuggest: {
      pos: 'absolute',
      top: { base: '8px', sm: '12px' },
      right: { base: '8px', sm: '12px' },
    },
  },
};

export default spinner;
