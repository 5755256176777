import { ReactNode, useContext } from 'react';

import { PermissionEnum } from '../constants/permissions';

import { AbilityContext } from './contextLib';

export interface CanProps {
  I: PermissionEnum | PermissionEnum[];
  children: ReactNode | ((hasPermission: boolean) => ReactNode);
}

export const Can = ({ I: permission, children }: CanProps) => {
  const abilities = useContext(AbilityContext);
  let hasPermission = false;
  if (typeof permission === 'string') {
    hasPermission = abilities.has(permission);
  } else if (Array.isArray(permission) && permission.length) {
    hasPermission = permission.some((p) => abilities.has(p));
  }

  return (
    <>{typeof children === 'function' ? children(hasPermission) : hasPermission && children}</>
  );
};
