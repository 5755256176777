import { FC } from 'react';

import LeaseEndContainer from '../../components/Container/Container';
import ButtonsRow from '../../components/Dashboards/ButtonsRow';

import TeamManager from './TeamManager';

const TeamsPage: FC = () => {
  return (
    <>
      <ButtonsRow />
      <LeaseEndContainer fluid>
        <TeamManager />
      </LeaseEndContainer>
    </>
  );
};

export default TeamsPage;
