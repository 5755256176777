import { getFullName } from '../../gql/customerGql';
import { Deal, DealSourceEnum, DealSourceLabelMap } from '../../gql/dealGql';
import { getDealNotificationCountByType } from '../../gql/notificationsGql';

import { getBootedDealState } from './utils';

import { formatDate } from '../../libs/utils';

export type CalculatedProps = {
  dealNotificationCountByType?: ReturnType<typeof getDealNotificationCountByType>;
  lastDealState?: ReturnType<typeof getBootedDealState>;
};

export type DealWithCalculatedProps = Deal & CalculatedProps;

export const bootedDealColumns = [
  {
    header: 'Date',
    getValue: (deal: DealWithCalculatedProps) => formatDate(deal.created_at, deal.creation_date_tz),
  },
  {
    header: 'Name',
    getValue: (deal: DealWithCalculatedProps) => deal.customer && getFullName(deal.customer),
  },
  { header: 'Year', getValue: (deal: DealWithCalculatedProps) => deal.car.year },
  { header: 'Make', getValue: (deal: DealWithCalculatedProps) => deal.car.make },
  { header: 'Model', getValue: (deal: DealWithCalculatedProps) => deal.car.model },
  { header: 'Reason', getValue: (deal: DealWithCalculatedProps) => deal.boot_reason?.reason },
  { header: 'Last State', getValue: (deal: DealWithCalculatedProps) => deal.lastDealState ?? '' },
  {
    header: 'Source',
    getValue: (deal: DealWithCalculatedProps) =>
      DealSourceLabelMap[deal.source as DealSourceEnum] ?? '',
  },
  {
    header: 'Comment',
    getValue: (deal: DealWithCalculatedProps) => deal.boot_reason?.comment,
  },
];
