import React, { useState } from 'react';
import { BsClock, BsPencilFill, BsExclamationCircle } from 'react-icons/bs';
import { HStack, VStack, Box, Text, Skeleton, IconButton, Icon } from '@chakra-ui/react';
import { Deal } from '../../../gql/dealGql';
import { getDealAgeColor, getDealAge } from '../../../utils/deals';

interface StatusIconsProps {
  loading: boolean;
  deal: Deal;
}

const StatusIcons: React.FC<StatusIconsProps> = ({ loading, deal }) => {
  const [mouseOverStatusButton, setMouseOverStatusButton] = useState<boolean>(false);

  const { state, completion_date_utc, completion_date_tz } = deal;
  const dealAge = getDealAge(completion_date_utc, completion_date_tz);

  return (
    <HStack align="start" justify="start" spacing="40px">
      <HStack w="200px">
        <IconButton
          icon={mouseOverStatusButton ? <BsPencilFill /> : <BsClock />}
          size="lg"
          bg="violetRed"
          color="white"
          aria-label="state"
          rounded="full"
          onMouseEnter={() => setMouseOverStatusButton(true)}
          onMouseLeave={() => setMouseOverStatusButton(false)}
        />
        <VStack>
          <Skeleton isLoaded={!loading} w="max-content">
            <Text fontSize="xs">STATUS</Text>
            <Text casing="capitalize">{mouseOverStatusButton ? 'Edit Status' : state}</Text>
          </Skeleton>
        </VStack>
      </HStack>
      <HStack>
        <Box bg={getDealAgeColor(dealAge)} rounded="full" h="48px" w="48px" position="relative">
          <Icon
            as={BsExclamationCircle}
            fontSize="20px"
            position="absolute"
            top="14px"
            right="14px"
          />
        </Box>
        <VStack>
          <Skeleton isLoaded={!loading} w="max-content">
            <Text fontSize="xs">AGE</Text>
            <Text>{dealAge} Days</Text>
          </Skeleton>
        </VStack>
      </HStack>
    </HStack>
  );
};

export default StatusIcons;
