import { FC, useMemo } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Box, Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { set } from 'date-fns';
import { Formik } from 'formik';
import { BiEdit } from 'react-icons/bi';
import { toast } from 'react-toastify';

import {
  FollowUp,
  GetFollowUpDealsPaginatedQuery,
  useFollowUpUpdateMutation,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import DeleteFollowUp from './DeleteFollowUp';
import FollowUpButton from './FollowUpButton';
import FollowUpForm from './FollowUpForm';
import { FollowUpFormType, validationSchema } from './validationSchema';

import { logger } from '../../libs/Logger';
import { getTimezoneStr } from '../../libs/utils';

interface EditFollowUpProps {
  followUp: FollowUp;
  refetch?: () => Promise<ApolloQueryResult<GetFollowUpDealsPaginatedQuery>>;
  showIconButton?: boolean;
}

const EditFollowUp: FC<EditFollowUpProps> = ({ followUp, refetch, showIconButton = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateFollowUp, { loading }] = useFollowUpUpdateMutation();

  const initialValues: FollowUpFormType = useMemo(
    () => ({
      date: new Date(followUp.date_utc),
      time: new Date(followUp.date_utc),
      type: followUp.type ?? undefined,
      note: followUp.note,
    }),
    [followUp],
  );

  const handleSubmit = async (values: FollowUpFormType) => {
    if (!followUp.id || !followUp.deal_id || !values.date || !values.time) {
      return;
    }

    const hours = values.time.getHours();
    const minutes = values.time.getMinutes();

    try {
      await updateFollowUp({
        variables: {
          id: followUp.id,
          deal_id: followUp.deal_id,
          date_utc: set(values.date, {
            hours,
            minutes,
            seconds: 0,
            milliseconds: 0,
          }),
          tz: getTimezoneStr(),
          type: values.type ?? '',
          note: values.note,
        },
      });
      toast.success('Follow-Up updated');
      await refetch?.();
      onClose();
    } catch (e) {
      const error = e as Error;
      logger.error('EditFollowUp.tsx', 'updateFollowUp', error);
      toast.error(
        `Error updating Follow-Up: ${error.message || error}. Please refresh and try again.`,
      );
    }
  };

  return (
    <>
      {showIconButton ? (
        <Box pos="relative">
          <IconButton
            aria-label="edit follow-up"
            size="xs"
            variant="iconHover"
            onClick={(e) => {
              e.stopPropagation();
              onOpen();
            }}
            icon={<BiEdit />}
          />
        </Box>
      ) : (
        <FollowUpButton hasFollowUp onOpen={onOpen} />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Modal
          title="Edit Follow-Up"
          hasForm
          isOpen={isOpen}
          onClose={onClose}
          leftButtons={<DeleteFollowUp followUp={followUp} />}
          rightButtons={
            <Button type="submit" isLoading={loading} loadingText="Save Changes">
              Save Changes
            </Button>
          }
          scrollBehavior="outside"
        >
          <FollowUpForm />
        </Modal>
      </Formik>
    </>
  );
};

export default EditFollowUp;
