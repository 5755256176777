import { FC, useContext } from 'react';

import { Box, Flex, FlexboxProps, LayoutProps } from '@chakra-ui/react';
import { endOfToday, subDays } from 'date-fns/esm';
import { Col, Row } from 'react-bootstrap';

import CompletedAcquisitionDealsTable from '../CompletedAcquisitionDealsTable/CompletedAcquisitionDealsTable';
import CompletedDealsTable from '../CompletedDealsTable/CompletedDealsTable';
import Inbox from '../Inbox';

import { PermissionEnum } from '../../constants/permissions';
import { AbilityContext } from '../../libs/contextLib';

interface BottomSectionProps {
  minW?: LayoutProps['minW'];
  maxW?: LayoutProps['maxW'];
  flexDir?: FlexboxProps['flexDir'];
}

const BottomSection: FC<BottomSectionProps> = ({
  minW = '620px',
  maxW = '1530px',
  flexDir = 'column',
}) => {
  const ability = useContext(AbilityContext);

  const today = endOfToday();
  const fiveDaysAgo = subDays(today, 5);

  return (
    <Flex gap={2} flexDir={flexDir} minW={minW} maxW={maxW} flexGrow={1}>
      <Box>
        <Inbox />
      </Box>
      <Box gap={4}>
        <Row className="h-30 m-0">
          <Col className="h-100">
            {(ability.has(PermissionEnum.SeeCompletedBuyoutDeals) ||
              ability.has(PermissionEnum.SeeCompletedRefiDeals)) &&
            !ability.has(PermissionEnum.SuperUser) ? (
              <CompletedDealsTable startDate={fiveDaysAgo} endDate={today} />
            ) : (
              ability.has(PermissionEnum.SeeCompletedAcquisitionDeals) && (
                <CompletedAcquisitionDealsTable startDate={fiveDaysAgo} endDate={today} />
              )
            )}
          </Col>
        </Row>
      </Box>
    </Flex>
  );
};

export default BottomSection;
