import { gql } from '@apollo/client/core';
import Big from 'big.js';
import { addDays, formatISO } from 'date-fns';

import { Bank, DealType, Processor } from './generated/graphql';

import { VscPackageTypeEnum } from '../components/DealInfoBuyout/types';
import { getDefaultBankByDealType } from '../utils/financialInfos';

import { BankEnum } from './bankGql';

export enum TermEnum {
  FortyEight = 48,
  Sixty = 60,
  SeventyTwo = 72,
  EightyFour = 84,
}

export const DOC_FEE = 799;
export const TITLE_FEE = 324;
export const BUY_RATE = 2.99;
export const SELL_RATE = 4.99;
export const MONEY_DOWN = 0;
export const BANK_FEES = 0;
export const VSC_TERM = '2 years/24k';
export const VSC_PRICE = 3000;
export const VSC_COST = 1500;
export const GAP_PRICE = 900;
export const GAP_COST = 341;

export const LTV_THRESHOLD = 0.7;

export const DEFAULT_BANKS_BY_DEAL_TYPE: Record<DealType, BankEnum> = {
  [DealType.Buyout]: BankEnum.TD,
  [DealType.Acquisition]: BankEnum.TD,
  [DealType.Refi]: BankEnum.LentegrityAutoFinance,
};

export enum DaysToPaymentEnum {
  Thirty = 30,
  FortyFive = 45,
  Sixty = 60,
  Ninety = 90,
}

// Same function as getFirstPaymentDate in utils/dealHelpers.ts in API but returns an ISO string. Keep in sync.
export const getFirstPaymentDateISO = (
  daysToPayment = DaysToPaymentEnum.FortyFive,
  bank = getDefaultBankByDealType(),
): string => {
  const calculatedDate = addDays(new Date(), daysToPayment);

  if (bank !== BankEnum.PNC) {
    return formatISO(calculatedDate);
  }

  const dayOfMonth = calculatedDate.getDate();
  const month = calculatedDate.getMonth();
  const year = calculatedDate.getFullYear();

  if (dayOfMonth === 29) {
    return formatISO(new Date(year, month, 28));
  }
  if (dayOfMonth === 30 || dayOfMonth === 31) {
    if (month === 11) {
      return formatISO(new Date(year + 1, 0, 1));
    }
    return formatISO(new Date(year, month + 1, 1));
  }
  return formatISO(calculatedDate);
};

export enum ProcessorEnum {
  ATC = 'ATC',
  VITU = 'VITU',
  DLRdmv = 'DLRdmv',
  Plateman = 'Plateman',
  StateDMV = 'State DMV',
  TitleGirl = 'The Title Girl',
  WKLS = 'WKLS',
  Other = 'Other (leave note)',
}

export enum OptionTypeEnum {
  VscPlusGap = 'vscPlusGap',
  VSC = 'vsc',
  Gap = 'gap',
  NoProducts = 'noProducts',
}

export class FinancialInfo {
  id?: number;
  deal_id?: number;
  money_down?: number;
  bank_fees?: number;
  base_tax_amount?: number;
  warranty_tax_amount?: number;
  title?: number;
  total_fee_amount?: number;
  sales_tax_rate?: number;
  doc_fee?: number;
  vsc_price?: number;
  vsc_cost?: number;
  gap_price?: number;
  gap_cost?: number;
  days_to_payment?: number;
  first_payment_date?: string;
  sell_rate?: number;
  term?: number;
  buy_rate?: number;
  bank?: BankEnum;
  vsc_type?: string;
  vsc_term: string;
  profit?: number;
  reserve?: number;
  user_entered_reserve?: number;
  amount_financed?: number;
  payment?: number;
  closer_commission?: number;
  setter_commission?: number;
  option_type?: OptionTypeEnum;
  plate_transfer: boolean;
  pen_vsc_session_id?: string;
  pen_vsc_rate_id?: number;
  pen_vsc_form_id?: number;
  pen_gap_session_id?: string;
  pen_gap_rate_id?: number;
  pen_gap_form_id?: number;
  title_only?: boolean;
  processor?: ProcessorEnum;
  tt_transaction_id?: string;
  buyer_not_lessee?: boolean;
  new_lienholder?: Bank;
  card_payment_amount_limit?: number;

  newBaseTaxAmount?: number;
  totalTaxAmount?: number;
  feesQueryFailed?: boolean;
  maxMarkup?: number;
  tempUserEnteredReserve?: number;

  constructor(deal_id?: number) {
    this.deal_id = deal_id;
    this.bank = getDefaultBankByDealType();
    this.days_to_payment = DaysToPaymentEnum.FortyFive;
    this.first_payment_date = getFirstPaymentDateISO(this.days_to_payment, this.bank);
    this.doc_fee = DOC_FEE;
    this.title = TITLE_FEE;
    this.term = TermEnum.SeventyTwo;
    this.buy_rate = BUY_RATE;
    this.sell_rate = SELL_RATE;
    this.money_down = MONEY_DOWN;
    this.bank_fees = BANK_FEES;
    this.vsc_type = VscPackageTypeEnum.Major;
    this.vsc_term = VSC_TERM;
    this.vsc_price = VSC_PRICE;
    this.vsc_cost = VSC_COST;
    this.gap_price = GAP_PRICE;
    this.gap_cost = GAP_COST;
    this.plate_transfer = false;
    this.total_fee_amount = 0;
  }
}

export const processorUpdate = gql`
  mutation updateProcessor($deal_id: ID!, $fi_id: ID!, $processor: String) {
    updateProcessor(deal_id: $deal_id, fi_id: $fi_id, processor: $processor) {
      id
      processor
    }
  }
`;

export const onTransactionCreated = gql`
  subscription onTransactionCreated($dealId: ID!) {
    onTransactionCreated(dealId: $dealId) {
      deal_id
      tt_transaction_id
    }
  }
`;

export const TITLE_ONLY_TOTAL_FEE_AMOUNT = 0;

export const isVscSelected = (optionType?: string) =>
  [OptionTypeEnum.VscPlusGap, OptionTypeEnum.VSC].includes(optionType as OptionTypeEnum);

export const isGapSelected = (optionType?: string) =>
  [OptionTypeEnum.VscPlusGap, OptionTypeEnum.Gap].includes(optionType as OptionTypeEnum);

export const getTotalTaxes = (financialInfo: Partial<FinancialInfo>) =>
  isVscSelected(financialInfo.option_type)
    ? new Big(financialInfo.base_tax_amount ?? 0)
        .plus(new Big(financialInfo.warranty_tax_amount ?? 0))
        .toNumber()
    : new Big(financialInfo.base_tax_amount ?? 0).toNumber();

export const getTotalFeeAmountConsideringTitleOnly = (financialInfo: FinancialInfo) =>
  financialInfo.title_only
    ? TITLE_ONLY_TOTAL_FEE_AMOUNT
    : new Big(financialInfo.total_fee_amount ?? 0).toNumber();

export const mapTTProcessorToProcessor = (
  ttProcessor?: Processor | null,
): ProcessorEnum | undefined => {
  switch (ttProcessor) {
    case Processor.Atc:
      return ProcessorEnum.ATC;
    case Processor.Vitu:
      return ProcessorEnum.VITU;
    case Processor.DlrDmv:
      return ProcessorEnum.DLRdmv;
    default:
      return undefined;
  }
};

export const mapProcessorToTTProcessor = (processor?: ProcessorEnum): Processor | undefined => {
  switch (processor) {
    case ProcessorEnum.ATC:
      return Processor.Atc;
    case ProcessorEnum.VITU:
      return Processor.Vitu;
    case ProcessorEnum.DLRdmv:
      return Processor.DlrDmv;
    default:
      return undefined;
  }
};
