import { FC, useContext, useState } from 'react';

import { IconButton } from '@chakra-ui/react';
import { Modal } from 'react-bootstrap';
import { BiHistory } from 'react-icons/bi';

import { DealStateLabelMap } from '../../gql/dealGql';

import { DealContext } from '../../libs/DealContext';
import { formatDateWithTime } from '../../libs/utils';

const DealStatesModal: FC = () => {
  const {
    deal: { deal_states, set_date, completion_date_utc },
  } = useContext(DealContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <IconButton
        icon={<BiHistory size={23} />}
        variant="icon"
        color="primary"
        size="xs"
        float="right"
        aria-label="Deal States History"
        onClick={() => setShowModal(true)}
      />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="deal-states-modal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">Deal States History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <text className="mr-4">
            Set Date:
            {set_date ? ` ${formatDateWithTime(set_date?.toString())}` : ' - '}
          </text>
          <text>
            Completion Date:
            {completion_date_utc
              ? ` ${formatDateWithTime(completion_date_utc?.toString())}`
              : ' - '}
          </text>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>State</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {deal_states?.map((deal_state) => (
                <tr key={deal_state.id}>
                  <td>{formatDateWithTime(deal_state?.updated_date_utc?.toString())}</td>
                  <td>{DealStateLabelMap[deal_state.state]}</td>
                  <td>{deal_state.user?.name || 'System'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DealStatesModal;
