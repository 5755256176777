export enum ColorsEnum {
  RED = 'rgb(247 96 110)',
  YELLOW = '#f3c43d',
  GREEN = '#5ce14c',
  ORANGE = '#ffa707',
  BLUE = '#0076bd',
  ELECTRICBLUE = '#005292',
  WHITE = '#fff',
  TOOLTIP_RED = 'rgb(219 29 29)',
  BLACK = '#000000',
}
