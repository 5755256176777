import {
  Box,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioProps,
  useMultiStyleConfig,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Option } from './types';

import colors from '../../chakra/foundations/colors';

interface RadioCardProps extends RadioProps {
  label: string;
}

const RadioCard = ({ label, ...props }: RadioCardProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="full" h="full" m={0} fontSize="sm" fontWeight={700}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        h="full"
        borderWidth={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        _checked={{
          bg: colors.caribbeanGreen[500],
          color: 'white',
          borderColor: colors.caribbeanGreen[500],
        }}
        _invalid={{
          bg: colors.spanishPink,
          borderColor: colors.errorsRed,
        }}
      >
        {label}
      </Box>
    </Box>
  );
};

interface RadioButtonsProps extends FlexProps {
  name: string;
  label: string;
  options: Option[];
}

const RadioButtons = ({ name, label, options, ...rest }: RadioButtonsProps) => {
  const { getFieldMeta, setFieldValue, setFieldTouched } = useFormikContext();
  const { value, touched, error } = getFieldMeta(name);
  const isInvalid = touched && !!error;

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value: value as string | number | undefined,
    onChange: (newValue) => {
      setFieldValue(name, newValue);
      setTimeout(() => setFieldTouched(name, true));
    },
  });
  const rootProps = getRootProps();

  const styles = useMultiStyleConfig('FormControl');

  return (
    <Box {...rest} {...rootProps}>
      <FormControl isInvalid={isInvalid} sx={styles.control}>
        <FormLabel whiteSpace="nowrap" sx={styles.label}>
          {label}
        </FormLabel>
        <Flex
          direction="row"
          borderWidth={1}
          borderColor={isInvalid ? colors.errorsRed : 'black'}
          borderRadius="sm"
          h={8}
          sx={styles.input}
        >
          {options.map((option) => (
            <RadioCard
              key={option.value}
              label={option.label}
              {...getRadioProps({ value: option.value })}
            />
          ))}
        </Flex>
        <FormErrorMessage sx={styles.error}>{error}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};

export default RadioButtons;
