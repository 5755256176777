import { useContext, useMemo, useState } from 'react';

import { Box, Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { File } from '../../gql/contractGql';
import {
  DealType,
  useAddDocumentsToMediaCenterMutation,
  useDeleteAndReplaceFilesMutation,
} from '../../gql/generated/graphql';

import DocumentsTable from '../DocumentsTable/DocumentsTable';
import { parseFilename } from '../MediaCenter/utils';
import Modal from '../shared/Modal';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

interface Props {
  isOpen: boolean;
  files: File[];
  sendContractsLoading: boolean;
  onClose: () => void;
  onOpenFile: (file: File) => void;
  onSendContracts: () => void;
  r1EContractGenerated?: boolean;
}

const DocumentsModal = ({
  isOpen,
  onClose,
  files,
  sendContractsLoading,
  onOpenFile,
  onSendContracts,
  r1EContractGenerated,
}: Props) => {
  const { deal, documentMedia } = useContext(DealContext);
  const [addDocumentsToMediaCenter, { loading: addDocumentsToMediaCenterLoading }] =
    useAddDocumentsToMediaCenterMutation();

  const [replaceAndDelete, { loading }] = useDeleteAndReplaceFilesMutation();

  const [selectedFilesIndexes, setSelectedFilesIndexes] = useState<number[]>([]);

  const adobeSignCondition = deal.type === DealType.Acquisition;

  const selectedExistingFiles = useMemo(() => {
    const generatedFiles = new Set(
      files.filter((_, index) => selectedFilesIndexes.includes(index)).map((file) => file.filename),
    );

    const duplicates = documentMedia.mediaList.filter((media) => {
      if (!media.id) {
        return false;
      }
      const fileName = parseFilename(media.key ?? '').sourceFileName;
      return generatedFiles.has(fileName);
    });

    return duplicates; // these are the files currently in the media center
  }, [files, selectedFilesIndexes, documentMedia]);

  const containsDuplicateFiles = !!selectedExistingFiles.length;

  const handleAddToMediaCenter = async () => {
    if (!deal.id) {
      return;
    }

    const selectedFiles = files
      .filter((_, index) => selectedFilesIndexes.includes(index))
      .map((file) => ({
        key: file.key,
      }));

    await addDocumentsToMediaCenter({
      variables: {
        deal_id: deal.id,
        files: selectedFiles,
      },
    });

    onClose();
  };

  const onReplaceAndAdd = async () => {
    try {
      // Delete duplicate files from media center
      const fileKeys = selectedExistingFiles.map((file) => ({
        key: file.key,
      }));

      const selectedFiles = files
        .filter((_, index) => selectedFilesIndexes.includes(index))
        .map((file) => ({
          key: file.key,
        }));

      if (deal.id) {
        await replaceAndDelete({
          variables: {
            deal_id: deal.id,
            files_to_delete: fileKeys,
            files_to_upload: selectedFiles,
          },
        });
      }
    } catch (error: unknown) {
      logger.error('DocumentsModal.tsx', 'Unable to replace existing files', null, error);
      toast.error('Unable to replace existing files');
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      isCentered
      size="xl"
      title="Generated Documents"
      leftButtons={
        <Button
          variant="warning"
          isDisabled={sendContractsLoading || addDocumentsToMediaCenterLoading}
          onClick={onClose}
        >
          CANCEL
        </Button>
      }
      rightButtons={
        <>
          {adobeSignCondition ? (
            <Button
              variant="secondary"
              isLoading={sendContractsLoading}
              isDisabled={selectedFilesIndexes.length === 0 || addDocumentsToMediaCenterLoading}
              onClick={onSendContracts}
            >
              SEND CONTRACTS
            </Button>
          ) : null}

          <Button
            variant={containsDuplicateFiles ? 'secondary' : 'primary'}
            isLoading={addDocumentsToMediaCenterLoading}
            isDisabled={selectedFilesIndexes.length === 0 || sendContractsLoading}
            onClick={handleAddToMediaCenter}
          >
            {containsDuplicateFiles ? 'ADD NEW' : 'ADD TO MEDIA CENTER'}
          </Button>
          {containsDuplicateFiles ? (
            <Button isLoading={loading} loadingText="REPLACE & ADD" onClick={onReplaceAndAdd}>
              {`REPLACE (${selectedExistingFiles.length}) & ADD`}
            </Button>
          ) : null}
        </>
      }
    >
      {r1EContractGenerated ? (
        <Box mb={5}>Contract successfully generated in Route One.</Box>
      ) : null}
      {files?.length ? (
        <DocumentsTable
          infoText="The following documents have been generated. Each of these documents can be reviewed
        prior to adding them to the Media Center."
          files={files}
          selectedFilesIndexes={selectedFilesIndexes}
          setSelectedFilesIndexes={setSelectedFilesIndexes}
          onOpenFile={onOpenFile}
          showDownloadColumn
          selectAllByDefault
          modal="generate"
          numExistingFiles={selectedExistingFiles.length}
          existingFiles={selectedExistingFiles.map(
            (file) => parseFilename(file.key ?? '').sourceFileName,
          )}
        />
      ) : (
        <Box>No documents have been generated.</Box>
      )}
    </Modal>
  );
};

export default DocumentsModal;
