/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';

import {
  HStack,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';

import { PrequalificationCreditReport } from '../../../../gql/generated/graphql';

import { creditScoreColorMap } from './PrequalChecksRow';
import TradelinesTable from './TradelinesTable';

import { formatMoney } from '../../../../libs/utils';
import {
  PrequalCheckStatusType,
  formatExperianDate,
  getTradelineSummary,
} from '../../../../utils/prequalification';

type CreditReportProps = {
  creditReport?: PrequalificationCreditReport | null;
  creditScore?: number;
  creditStatus?: PrequalCheckStatusType;
};

const CreditReport = ({
  creditReport,
  creditScore,
  creditStatus = 'warning',
}: CreditReportProps) => {
  const revolvingSummary = useMemo(
    () => getTradelineSummary(creditReport?.tradelines?.revolving || []),
    [creditReport?.tradelines?.revolving],
  );

  const installmentSummary = useMemo(
    () => getTradelineSummary(creditReport?.tradelines?.installment || []),
    [creditReport?.tradelines?.installment],
  );

  const totalSummary = useMemo(
    () => ({
      highCredit: revolvingSummary.highCredit + installmentSummary.highCredit,
      creditLimit: revolvingSummary.creditLimit + installmentSummary.creditLimit,
      balance: revolvingSummary.balance + installmentSummary.balance,
      pastDue: revolvingSummary.pastDue + installmentSummary.pastDue,
      monthlyPayment: revolvingSummary.monthlyPayment + installmentSummary.monthlyPayment,
    }),
    [revolvingSummary, installmentSummary],
  );

  const availableCreditPercentage = (
    100 -
    (revolvingSummary.balance / revolvingSummary.creditLimit) * 100
  ).toFixed(0);

  return (
    <>
      <HStack w="full" justifyContent="space-evenly" mb={10}>
        <Text fontWeight="bold" fontSize="3xl">
          {creditReport?.names?.customerName?.firstName}{' '}
          {creditReport?.names?.customerName?.surname}
        </Text>
        <VStack spacing={0} alignItems="flex-start">
          <Text fontWeight="semibold">Credit Score</Text>
          <Text fontSize="2xl" color={creditScoreColorMap[creditStatus]}>
            {creditScore}
          </Text>
        </VStack>
      </HStack>

      <VStack spacing={4}>
        {/* Aliases */}
        <VStack spacing={0} w="70%" mx="auto" alignItems="flex-start">
          <Text decoration="underline" fontSize="xl" fontWeight="bold">
            Aliases
          </Text>
          <VStack spacing={1}>
            {creditReport?.names?.aliases.map((alias) => (
              <Text key={`${alias?.firstName} ${alias?.middleName} ${alias?.surname}`}>
                {alias?.surname ? `${alias.surname}, ` : ''}
                {alias?.firstName ? `${alias.firstName} ` : ''} {alias?.middleName}
              </Text>
            ))}
          </VStack>
        </VStack>

        {/* Address */}
        <VStack spacing={0} w="70%" mx="auto" alignItems="flex-start">
          <HStack w="full" justifyContent="space-between">
            <Text decoration="underline" fontSize="xl" fontWeight="bold">
              Addresses
            </Text>
            <Text decoration="underline" fontSize="xl" fontWeight="bold">
              Date Reported
            </Text>
          </HStack>

          <VStack w="full" spacing={1} alignItems="flex-start">
            {creditReport?.addresses?.map((address) => (
              <HStack
                key={`${address?.streetName}-${address?.firstReportedDate}`}
                w="full"
                justifyContent="space-between"
              >
                <Text>
                  {address?.streetPrefix ? `${address.streetPrefix}` : ''}
                  {address?.streetName
                    ? `${address?.streetPrefix ? ' ' : ''}${address.streetName}`
                    : ''}
                  {address?.streetSuffix
                    ? `${address?.streetName ? ' ' : ''}${address.streetSuffix}`
                    : ''}
                  {address?.city ? `, ${address.city}` : ''}
                  {address?.state ? `, ${address.state}` : ''}
                  {address?.zipCode ? ` ${address.zipCode}` : ''}
                </Text>
                <Text>{formatExperianDate(address?.firstReportedDate)}</Text>
              </HStack>
            ))}
          </VStack>
        </VStack>

        {/* Employment */}
        <VStack spacing={0} w="70%" mx="auto" alignItems="flex-start">
          <HStack w="full" justifyContent="space-between">
            <Text decoration="underline" fontSize="xl" fontWeight="bold">
              Employment
            </Text>
            <Text decoration="underline" fontSize="xl" fontWeight="bold">
              Date Last Reported
            </Text>
          </HStack>
          <VStack w="full" spacing={1} alignItems="flex-start">
            {creditReport?.employments?.map((employment) => (
              <HStack
                key={`${employment?.name}-${employment?.lastUpdatedDate}`}
                w="full"
                justifyContent="space-between"
              >
                <Text>{employment?.name}</Text>
                <Text>{formatExperianDate(employment?.lastUpdatedDate)}</Text>
              </HStack>
            ))}
          </VStack>
        </VStack>

        {/* Score Factors */}
        <VStack spacing={0} w="70%" mx="auto" alignItems="flex-start">
          <HStack alignItems="center">
            <Text decoration="underline" fontSize="xl" fontWeight="bold">
              Score Factors{' '}
            </Text>
            <Text>(ordered by most impactful)</Text>
          </HStack>
          <UnorderedList spacing={1} alignItems="flex-start">
            {creditReport?.scoreFactors?.map((factor) => (
              <ListItem key={factor?.code}>{factor?.description}</ListItem>
            ))}
          </UnorderedList>
        </VStack>

        {/* Messages */}
        <VStack spacing={0} w="70%" mx="auto" alignItems="flex-start">
          <Text decoration="underline" fontSize="xl" fontWeight="bold">
            Extra Messages
          </Text>
          <UnorderedList spacing={1} alignItems="flex-start">
            {creditReport?.informationalMessages?.map((message) => (
              <ListItem key={message}>{message}</ListItem>
            ))}
          </UnorderedList>
        </VStack>

        {/* Summary */}
        <VStack spacing={0} w="70%" mx="auto" alignItems="center">
          <Text decoration="underline" fontSize="xl" fontWeight="bold">
            Summary
          </Text>
          <VStack spacing={1}>
            <TableContainer w="full">
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>TYPE</Th>
                    <Th>HIGH BALANCE</Th>
                    <Th>CRED LIM</Th>
                    <Th>BALANCE</Th>
                    <Th>PAST DUE</Th>
                    <Th>MONTHLY PAY</Th>
                    <Th>AVAILABLE</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td fontWeight="bold">REVOLVING</Td>
                    <Td>{formatMoney(revolvingSummary.highCredit, { noDecimal: true })}</Td>
                    <Td>{formatMoney(revolvingSummary.creditLimit, { noDecimal: true })}</Td>
                    <Td>{formatMoney(revolvingSummary.balance, { noDecimal: true })}</Td>
                    <Td>{formatMoney(revolvingSummary.pastDue, { noDecimal: true })}</Td>
                    <Td>{formatMoney(revolvingSummary.monthlyPayment, { noDecimal: true })}</Td>
                    <Td>{availableCreditPercentage}%</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">INSTALLMENT</Td>
                    <Td>{formatMoney(installmentSummary.highCredit, { noDecimal: true })}</Td>
                    <Td>{formatMoney(installmentSummary.creditLimit, { noDecimal: true })}</Td>
                    <Td>{formatMoney(installmentSummary.balance, { noDecimal: true })}</Td>
                    <Td>{formatMoney(installmentSummary.pastDue, { noDecimal: true })}</Td>
                    <Td>{formatMoney(installmentSummary.monthlyPayment, { noDecimal: true })}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight="bold">TOTAL</Td>
                    <Td>{formatMoney(totalSummary.highCredit, { noDecimal: true })}</Td>
                    <Td>{formatMoney(totalSummary.creditLimit, { noDecimal: true })}</Td>
                    <Td>{formatMoney(totalSummary.balance, { noDecimal: true })}</Td>
                    <Td>{formatMoney(totalSummary.pastDue, { noDecimal: true })}</Td>
                    <Td>{formatMoney(totalSummary.monthlyPayment, { noDecimal: true })}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        </VStack>

        {/* Tradelines */}
        <VStack spacing={8} w="full" alignItems="center">
          {(creditReport?.tradelines?.revolving || []).length > 0 && (
            <TradelinesTable title="Revolving" tradelines={creditReport!.tradelines!.revolving} />
          )}
          {(creditReport?.tradelines?.installment || []).length > 0 && (
            <TradelinesTable
              title="Installment"
              tradelines={creditReport!.tradelines!.installment}
            />
          )}
          {(creditReport?.tradelines?.unknown || []).length > 0 && (
            <TradelinesTable title="Unknown" tradelines={creditReport!.tradelines!.unknown} />
          )}
        </VStack>
      </VStack>
    </>
  );
};

export default CreditReport;
