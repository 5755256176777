import React, { Fragment, useCallback } from 'react';
import { Table, Tr, Tbody, Td } from '@chakra-ui/react';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { DealMedia } from '../../../../gql/mediaGql';

import { ADD, PREVIEW, ShowMediaModalType, VERIFY } from '../MediaModal';
import { useMediaCenterRows } from './hooks/use-media-table-rows';
import MediaTableHead from './components/MediaTableHead';
import CustomerMediaRow from './components/CustomerMediaRow';
import InternalMediaRow from './components/InternalMediaRow';

interface MediaTableProps {
  media: DealMedia[];
  onMediaTypeChange: (value: DealMedia) => void;
  onSelectMedia: (selectedMedia: DealMedia[]) => void;
  selectedFiles: DealMedia[];
  updateModalProps: (showModal: ShowMediaModalType) => void;
  isInternalFile?: boolean;
}

const MediaTable: React.FC<MediaTableProps> = ({
  media: mediaList,
  selectedFiles,
  onSelectMedia,
  updateModalProps,
  isInternalFile,
  onMediaTypeChange,
}) => {
  const { hiddenGroups, media, onSortChange, setHiddenGroups, sorting } =
    useMediaCenterRows(mediaList);

  const onExpandGroup = useCallback(
    (group: string) => {
      setHiddenGroups(
        hiddenGroups.includes(group)
          ? hiddenGroups.filter((g) => g !== group)
          : hiddenGroups.concat(group),
      );
    },
    [hiddenGroups],
  );

  const uploadedMediaFiles = mediaList.filter((item) => item.key);

  return (
    <Table maxHeight="500px" overflow="scroll" size="sm">
      <MediaTableHead
        isInternal={!!isInternalFile}
        onSelectMedia={onSelectMedia}
        onSortChange={onSortChange}
        selectedFiles={selectedFiles}
        sorting={sorting}
        uploadedMediaFiles={uploadedMediaFiles}
      />
      <Tbody>
        {Object.entries(media).map(([group, mediaItems]) => {
          const isHidden = hiddenGroups.includes(group);
          return (
            <Fragment key={group}>
              {/* Grouping Row */}
              <Tr
                borderLeftColor="gray.100"
                borderLeftWidth="1px"
                borderRightColor="gray.100"
                borderRightWidth="1px"
              >
                <Td cursor="pointer" colSpan={6} p={2} onClick={() => onExpandGroup(group)}>
                  {isHidden ? (
                    <MdKeyboardArrowRight size={20} />
                  ) : (
                    <MdKeyboardArrowDown size={20} />
                  )}
                  {group}
                </Td>
              </Tr>
              {/* Media Rows */}
              {!isHidden &&
                mediaItems.map((item, i) => {
                  const { fileExtension, filePath, friendlyName, isUploaded, type } = item;
                  const openUploadedPreview = () => {
                    updateModalProps({
                      filePath,
                      show: true,
                      content: VERIFY,
                      dealMedia: {
                        ...item,
                        fileName: friendlyName,
                        extension: fileExtension,
                        type,
                      },
                      isInternalFile,
                    });
                  };

                  const openUploadModal = () => {
                    if (isUploaded) {
                      return;
                    }

                    updateModalProps({
                      show: true,
                      content: ADD,
                      initialMediaType: item.type,
                    });
                  };

                  const onClickTextColumn = () => {
                    if (isUploaded) {
                      openUploadedPreview();
                      return;
                    }

                    openUploadModal();
                  };

                  const onUploadPreviewClick = () => {
                    if (item.isPdf) {
                      updateModalProps({
                        show: true,
                        content: PREVIEW,
                        filePath,
                        fileUrl: item.signed_url ?? '',
                      });
                    }
                  };

                  return isInternalFile ? (
                    <InternalMediaRow
                      key={item.key ?? i}
                      item={item}
                      onClickTextColumn={onClickTextColumn}
                      onMediaTypeChange={onMediaTypeChange}
                      onSelect={({ target }) => {
                        if (target.checked) {
                          onSelectMedia([...selectedFiles, item]);
                        } else {
                          onSelectMedia(
                            [...selectedFiles].filter(
                              (file) => file.id !== item.id || file.type !== item.type,
                            ),
                          );
                        }
                      }}
                      onUploadPreviewClick={onUploadPreviewClick}
                      openUploadModal={openUploadModal}
                      selectedFiles={selectedFiles}
                      updateModalProps={updateModalProps}
                    />
                  ) : (
                    <CustomerMediaRow
                      key={item.key ?? i}
                      item={item}
                      onClickTextColumn={onClickTextColumn}
                      onMediaTypeChange={onMediaTypeChange}
                      onSelect={({ target }) => {
                        if (target.checked) {
                          onSelectMedia([...selectedFiles, item]);
                        } else {
                          onSelectMedia(
                            [...selectedFiles].filter(
                              (file) => file.id !== item.id || file.type !== item.type,
                            ),
                          );
                        }
                      }}
                      onUploadPreviewClick={onUploadPreviewClick}
                      openUploadModal={openUploadModal}
                      selectedFiles={selectedFiles}
                      updateModalProps={updateModalProps}
                    />
                  );
                })}
            </Fragment>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default MediaTable;
