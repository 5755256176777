import { MouseEvent } from 'react';

import { RouteComponentProps } from 'react-router-dom';

export const handleClickOrCommandClick = (
  e: MouseEvent,
  url: string,
  history?: RouteComponentProps['history'],
  redirectFunction?: () => void,
) => {
  if (e.button === 1 || e.ctrlKey || e.metaKey) {
    e.stopPropagation();
    window.open(url, '_blank');
  } else if (history) {
    history.push(url);
  } else if (redirectFunction) {
    redirectFunction();
  }
};
