import { FC, useContext, useState } from 'react';

import { parseISO, startOfMonth } from 'date-fns';
import { Col, Form, Row } from 'react-bootstrap';

import BootedDeals from '../../components/BootedDeals/BootedDeals';
import LeaseEndContainer from '../../components/Container/Container';
import ButtonsRow from '../../components/Dashboards/ButtonsRow';

import { FiltersContext } from '../../libs/contextLib';
import { formatDateISO } from '../../libs/utils';

const ReviveBoot: FC = () => {
  const {
    filters: {
      global: { showOnlyUnread },
    },
  } = useContext(FiltersContext);

  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(new Date());

  return (
    <>
      <ButtonsRow />
      <LeaseEndContainer fluid>
        <Row className="align-items-start mb-3">
          <Form.Group as={Col} md="2">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              defaultValue={formatDateISO(startDate)}
              onChange={(e) => setStartDate(parseISO(e.target.value))}
            />
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              defaultValue={formatDateISO(endDate)}
              onChange={(e) => setEndDate(parseISO(e.target.value))}
            />
          </Form.Group>
        </Row>
        <BootedDeals
          start_date={startDate}
          end_date={endDate}
          showOnlyUnreadMessages={showOnlyUnread}
        />
      </LeaseEndContainer>
    </>
  );
};

export default ReviveBoot;
