import { ReactElement, useContext, useMemo, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Button, Flex } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { DealDates } from '../../gql/dealDatesGql';
import { Deal, DealStateEnum, isDealInOrPastFunded, isUneditable } from '../../gql/dealGql';
import { FinancialInfo, ProcessorEnum } from '../../gql/financialInfoGql';
import { DealType, PaperworkType, TtJurisdiction } from '../../gql/generated/graphql';

import ConfirmEditModal from '../ConfirmEditModal/ConfirmEditModal';
import GenerateDocumentsButton from '../GenerateDocs/GenerateDocumentsButton';
import PaperworkOptionsButtons from '../PaperworkOptionsButtons/PaperworkOptionsButtons';
import SendToNetsuiteButton from '../SendNetsuite/SendToNetsuiteButton';
import { TitleWorkButtonWithContext } from '../forwardButtons/TitleWorkButton';
import Card from '../shared/Card';
import Tooltip from '../shared/Tooltip';
import AutoStructureButton from './components/buttons/AutoStructureButton';
import CloseDeal from './components/buttons/CloseDealButton';
import CreateBookSheetButton from './components/buttons/CreateBookSheetButton';
import SaveButton from './components/buttons/SaveButton';
import SendToCloser from './components/buttons/SendToCloserButton';
import { SendToProcessorButton } from './components/buttons/SendToProcessorButton';
import SubmitCreditAppButton from './components/buttons/SubmitCreditAppButton';

import { DealInfoBuyoutForm } from './DealInfoBuyoutForm';
import { validationSchema } from './validationSchema';

import { PermissionEnum } from '../../constants/permissions';
import { useBanks } from '../../hooks/useBanks';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { passValuesToSchema } from '../../libs/utils';
import DisabledLienholderModal from '../../pages/DealDetail/DisabledLienholderModal';
import MarkAsSigned from '../../pages/DealDetail/ProgressionButtons/MarkAsSigned';
import MoveToButton from '../../pages/DealDetail/ProgressionButtons/MoveToButton';
import MoveToFundedOrReadyForPickup from '../../pages/DealDetail/ProgressionButtons/MoveToFundedOrReadyForPickup';
import SendToSignaturesButton from '../../pages/DealDetail/ProgressionButtons/SendToSignaturesButton';
import { getDefaultBankByDealType } from '../../utils/financialInfos';

const hiddenStates = [
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Funded,
];

interface DealInfoBuyoutProps {
  refetch: () => Promise<ApolloQueryResult<Deal>>;
  jurisdiction: TtJurisdiction;
  supportedProcessors: ProcessorEnum[];
}

const DealInfoBuyout = ({ refetch, jurisdiction, supportedProcessors }: DealInfoBuyoutProps) => {
  const { deal, claimIsRequired } = useContext(DealContext);

  const { modals } = useContext(ModalContext);
  const abilities = useContext(AbilityContext);

  const [showPaperworkOptions, setShowPaperworkOptions] = useState(false);

  const { banks, refetch: banksRefetch } = useBanks({ onlyActive: true, deal });

  const editable = !(hiddenStates.includes(deal.state) || isUneditable(deal.state));
  const isRefi = deal.type === DealType.Refi;

  const initialValues = useMemo(
    () => ({
      ...deal,
      // `deal_dates` needs to be initialized so that the initial date values are `undefined` instead of `null`.
      // This prevents the form from incorrectly detecting the values as changed when creating the `custom_dates` object.
      deal_dates: deal.deal_dates ?? new DealDates(),
      financial_info: deal.financial_info
        ? {
            ...deal.financial_info,
            bank: deal.financial_info.bank || getDefaultBankByDealType(deal.type),
          }
        : new FinancialInfo(deal.id),
    }),
    [modals],
  );

  const inClosed = deal.state === DealStateEnum.Closed;
  const inStructuring = deal.state === DealStateEnum.Structuring;
  const inClosing = deal.state === DealStateEnum.Closing;

  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmEditModalOpen, setIsConfirmEditModalOpen] = useState<boolean>(false);

  const canEditFundedOnwards =
    abilities.has(PermissionEnum.EditFundedOnwards) && isDealInOrPastFunded(deal.state);

  const forwardButton = useMemo(() => {
    const isPaperSign = deal.paperwork_type === PaperworkType.Paper;

    const buttonsByState: Partial<Record<DealStateEnum, ReactElement | null>> = {
      [DealStateEnum.Closed]: isPaperSign ? <SendToSignaturesButton /> : null,
      [DealStateEnum.SentForSignatures]: isPaperSign ? <MarkAsSigned /> : null,
      [DealStateEnum.Signed]: <MoveToFundedOrReadyForPickup />,
      [DealStateEnum.ReadyForPickup]: <MoveToButton newState={DealStateEnum.AtAuction} />,
      [DealStateEnum.PaidOff]: <MoveToButton newState={DealStateEnum.ReadyForPickup} />,
      [DealStateEnum.AtAuction]: <MoveToButton newState={DealStateEnum.Sold} />,
      [DealStateEnum.Sold]: <MoveToButton newState={DealStateEnum.TitleSent} />,
      [DealStateEnum.TitleSent]: <MoveToButton newState={DealStateEnum.Finalized} />,
      [DealStateEnum.Funded]: <TitleWorkButtonWithContext />,
      [DealStateEnum.SendPayoff]: <TitleWorkButtonWithContext />,
      [DealStateEnum.WaitingForTitle]: <TitleWorkButtonWithContext />,
      [DealStateEnum.TitleReceived]: <TitleWorkButtonWithContext />,
      [DealStateEnum.SentToProcessor]: <TitleWorkButtonWithContext />,
    };

    const dealStateButton = buttonsByState[deal.state];
    if (!dealStateButton) {
      return null;
    }
    if (claimIsRequired) {
      return <Tooltip errors={['Deal must be claimed']}>{dealStateButton}</Tooltip>;
    }
    return dealStateButton;
  }, [deal.paperwork_type, deal.state, claimIsRequired]);

  return (
    <Card variant="rounded">
      <Formik
        validate={(values) => passValuesToSchema(values, validationSchema, deal)}
        onSubmit={() => undefined}
        initialValues={initialValues}
        validateOnMount
        enableReinitialize
      >
        <Form noValidate>
          <DealInfoBuyoutForm
            jurisdiction={jurisdiction}
            banks={banks}
            banksRefetch={banksRefetch}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isEditable={editable || isEditing}
          />
          <Flex pt={4} px={4} justifyContent="end" flexWrap="wrap" gap={2}>
            {/* secondary buttons */}
            {canEditFundedOnwards ? (
              isEditing && <SaveButton customOnClick={() => setIsEditing(false)} />
            ) : (
              <SaveButton />
            )}
            {canEditFundedOnwards && (
              <>
                <Button
                  variant="secondary"
                  hidden={isEditing}
                  onClick={() => {
                    setIsConfirmEditModalOpen(true);
                  }}
                >
                  Edit
                </Button>
                <ConfirmEditModal
                  isOpen={isConfirmEditModalOpen}
                  onConfirm={() => {
                    setIsConfirmEditModalOpen(false);
                    setIsEditing(true);
                  }}
                  onClose={() => {
                    setIsConfirmEditModalOpen(false);
                  }}
                />
              </>
            )}
            {(inStructuring || inClosing) && <SubmitCreditAppButton banks={banks} />}
            <CreateBookSheetButton />

            {/* primary buttons */}
            {inStructuring && !isRefi && abilities.has(PermissionEnum.AutomateCreditApplication) ? (
              <AutoStructureButton />
            ) : null}
            <SendToCloser />
            <CloseDeal
              showPaperworkOptions={showPaperworkOptions}
              setShowPaperworkOptions={setShowPaperworkOptions}
            />
            <PaperworkOptionsButtons
              showPaperworkOptions={showPaperworkOptions}
              setShowPaperworkOptions={setShowPaperworkOptions}
              refetch={refetch}
            />
            {inClosed && deal.paperwork_type === PaperworkType.Paper && <GenerateDocumentsButton />}
            <SendToNetsuiteButton />
            <SendToProcessorButton supportedProcessors={supportedProcessors} />
            {forwardButton}

            <DisabledLienholderModal />
          </Flex>
        </Form>
      </Formik>
    </Card>
  );
};

export default DealInfoBuyout;
