import { FC, useContext, useEffect, useMemo, useState } from 'react';

import {
  AvatarGroup,
  Badge,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Link,
  StackProps,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useInterval,
} from '@chakra-ui/react';
import { MdComment, MdModeComment } from 'react-icons/md';

import { Deal, DealStateLabelMap } from '../../../gql/dealGql';
import { useNotificationSeenMutation } from '../../../gql/generated/graphql';
import { Message, getFromString, getTimeString } from '../../../gql/messageGql';
import { isUserAssignedToDeal } from '../../../gql/userGql';

import MessageCell from './MessageCell';
import UserAvatar from './UserAvatar';

import ROUTES from '../../../constants/routes';
import { useUser } from '../../../hooks/useUser';
import { TextWidgetContext } from '../../../libs/contextLib';
import { getActiveConversation } from '../../../utils/messages';
import MultipleDealsModal from '../../MultipleDealsModal';

interface MessageRowProps extends StackProps {
  deals: Deal[];
  message: Message;
  isSelected: boolean;
  handleSelectMessage: (message: Message) => void;
  refetchInboxQuery: () => void;
}

const MessageRow: FC<MessageRowProps> = ({
  deals,
  message,
  isSelected,
  handleSelectMessage,
  refetchInboxQuery,
  style,
  ...rest
}) => {
  const user = useUser();

  const { setTextDealId, setActiveConversation } = useContext(TextWidgetContext);

  const [notificationSeenMutation] = useNotificationSeenMutation();

  const {
    isOpen: isOpenMultipleDealModal,
    onOpen: onOpenMultipleDealModal,
    onClose: onCloseMultipleDealModal,
  } = useDisclosure();
  const [openTextWidgetFromModal, setOpenTextWidgetFromModal] = useState(false);

  const isRead = useMemo(() => {
    const notificationDealsAssignedToUser =
      message.notifications?.filter((notification) =>
        isUserAssignedToDeal(user, notification.deal),
      ) ?? [];

    if (notificationDealsAssignedToUser.length > 0) {
      return notificationDealsAssignedToUser.every((notification) => notification.seen);
    }

    return message.notifications?.some((notification) => notification.seen) ?? true;
  }, [message.notifications, user]);
  const isUnread = !isRead;

  const [timeString, setTimeString] = useState<string>('');
  useEffect(() => {
    setTimeString(getTimeString(message));
  }, [message]);
  useInterval(() => {
    setTimeString(getTimeString(message));
  }, 60000);

  const fromString = useMemo(() => {
    const fromStringList = deals.map((deal) => <Text>{getFromString(message, deal)}</Text>);
    if (deals.length > 1) {
      return (
        <Tooltip label={fromStringList}>
          <Text>Multiple customers</Text>
        </Tooltip>
      );
    }
    return fromStringList[0];
  }, [deals, message.from]);

  const handleOpenTextWidget = (deal: Deal) => {
    if (deal && message) {
      getActiveConversation(deal, message, setActiveConversation);
    }
    if (deal.id) {
      setTextDealId(deal.id);
    }
    onCloseMultipleDealModal();
  };

  const handleOpenTextWidgetOrModal = () => {
    if (deals.length === 1) {
      handleOpenTextWidget(deals[0]);
    }
    if (deals.length > 1) {
      setOpenTextWidgetFromModal(true);
      onOpenMultipleDealModal();
    }
  };

  const handleNewEstimateSeenNotification = async () => {
    const notificationWithoutDeal = message.notifications?.[0];
    if (!notificationWithoutDeal) {
      return;
    }

    await notificationSeenMutation({
      variables: {
        notificationId: notificationWithoutDeal.id,
        seen: true,
      },
    });

    refetchInboxQuery();
  };

  return (
    <HStack fontSize="sm" pr="5px" style={style} {...rest}>
      <VStack h="100%" w="100%">
        {message.isFirstInGroup ? (
          <HStack w="100%" minH="20px" align="start" verticalAlign="top" p="4px 4px 4px 16px">
            <Text variant="inbox">{message.groupKey}</Text>
          </HStack>
        ) : null}
        <HStack
          h="100%"
          w="100%"
          borderBottom="1px solid #e2e8f0"
          _hover={{ filter: 'brightness(0.9)' }}
          bg={isUnread ? 'white' : 'gray.100'}
        >
          <MessageCell minW="10%" maxW="10%" h="100%">
            <Flex h="100%" w="100%" alignItems="center" justifyContent="center" gap={4}>
              <Checkbox
                m={0}
                isDisabled={isUnread}
                isChecked={isSelected}
                onChange={() => handleSelectMessage(message)}
              />
              <Icon
                cursor="pointer"
                as={isUnread ? MdModeComment : MdComment}
                m={0}
                w={5}
                h={5}
                color={isUnread ? 'green' : 'gray'}
                onClick={handleOpenTextWidgetOrModal}
              />
            </Flex>
          </MessageCell>
          <MessageCell minW="18%" maxW="18%">
            <Text variant="inbox" fontWeight="bold">
              {fromString}
            </Text>
            <Text variant="inbox" fontWeight="light">
              {timeString}
            </Text>
          </MessageCell>
          <MessageCell minW="38%" maxW="38%" my="auto">
            <Text
              variant="inbox"
              whiteSpace="break-spaces"
              lineHeight="20px"
              noOfLines={2}
              my="auto"
            >
              {message.body}
            </Text>
          </MessageCell>
          <MessageCell minW="15%" maxW="15%">
            {deals.length === 0 ? (
              <Link
                my="auto"
                textDecoration="none"
                href={`${ROUTES.NEW_ESTIMATE}?phoneNumber=${message.from}`}
                onAuxClick={async (e) => {
                  const isMiddleClick = e.button === 1;
                  if (!isMiddleClick) {
                    return;
                  }

                  await handleNewEstimateSeenNotification();
                }}
                onClick={async () => {
                  await handleNewEstimateSeenNotification();
                }}
              >
                <Badge bgColor="green" color="white" fontSize="l" p={0.5} cursor="pointer">
                  NEW
                </Badge>
              </Link>
            ) : deals.length === 1 ? (
              <>
                <Text variant="inbox">
                  <Link
                    href={`/deals/${deals[0].id}`}
                    textDecoration="underline"
                    fontWeight="bold"
                    mb={1}
                  >
                    {deals[0].id}
                  </Link>
                </Text>
                <Text variant="inbox">{DealStateLabelMap[deals[0].state]}</Text>
              </>
            ) : deals.length > 1 ? (
              <>
                <Text variant="inbox">
                  <Link
                    textDecoration="underline"
                    fontWeight="bold"
                    mb={1}
                    onClick={handleOpenTextWidgetOrModal}
                    cursor="pointer"
                  >
                    {deals.length} deals
                  </Link>
                </Text>
              </>
            ) : null}
          </MessageCell>
          <MessageCell minW="15%" maxW="15%">
            {deals.length === 1 ? (
              <AvatarGroup my="auto" spacing={-5}>
                <UserAvatar
                  key={`${rest.key}Advisor`}
                  assignedRole="Advisor"
                  user={deals[0].setter}
                />
                <UserAvatar
                  key={`${rest.key}FinancialSpecialist`}
                  assignedRole="Financial Specialist"
                  user={deals[0].closer}
                />
                <UserAvatar
                  key={`${rest.key}FinancialSpecialist2`}
                  assignedRole="Financial Specialist 2"
                  user={deals[0].closer2}
                />
                <UserAvatar
                  key={`${rest.key}AccountingClerk`}
                  assignedRole="Accounting Clerk"
                  user={deals[0].funding_clerk}
                />
                <UserAvatar
                  key={`${rest.key}TitleClerk`}
                  assignedRole="Title Clerk"
                  user={deals[0].title_clerk}
                />
                <UserAvatar
                  key={`${rest.key}TitleClerk2`}
                  assignedRole="Reg Assistant"
                  user={deals[0].title_clerk2}
                />
              </AvatarGroup>
            ) : null}
          </MessageCell>
        </HStack>
      </VStack>

      <MultipleDealsModal
        openTextWidget={openTextWidgetFromModal}
        handleOpenTextWidget={handleOpenTextWidget}
        deals={deals}
        isOpen={isOpenMultipleDealModal}
        onClose={onCloseMultipleDealModal}
      />
    </HStack>
  );
};

export default MessageRow;
