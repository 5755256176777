import React from 'react';

import { useApolloClient } from '@apollo/client';
import { Box, Button } from '@chakra-ui/react';
import { Formik } from 'formik';
import { Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Article } from '../../../gql/articleGql';
import { getArticleImageUploadUrl } from '../../../gql/mediaGql';

import Dropzone from '../../../components/Dropzone';

import { urlValidation } from '../../../libs/yup-validators/url';

interface UpsertArticleModalProps {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  handleSubmit: (a: Article) => void;
  articleToEdit: Article | null;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a title'),
  headline: Yup.string().required('Please enter a headline'),
  url: urlValidation,
});

const convertFileToDataUrl = async (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result);
    });

    reader.readAsDataURL(file);
  });
};

const UpsertArticleModal: React.FC<UpsertArticleModalProps> = ({
  showModal,
  setShowModal,
  handleSubmit,
  articleToEdit,
}) => {
  const client = useApolloClient();

  const uploadImage = async (file: File) => {
    try {
      const { url: putUrl, key } = (
        await client.query({
          query: getArticleImageUploadUrl,
        })
      )?.data?.articleImageUploadUrl;

      await fetch(putUrl, {
        method: 'PUT',
        body: file,
      });

      const url = await convertFileToDataUrl(file);

      return { url, key };
    } catch {
      toast.error('Unable to upload files');
      return null;
    }
  };

  const initialValues = {
    title: '',
    headline: '',
    url: '',
    thumbnail_key: '',
    thumbnail: '',
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      onHide={() => setShowModal(false)}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          {articleToEdit ? 'Edit Featured Article' : 'Add Featured Article'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
          initialValues={articleToEdit || initialValues}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            handleSubmit: formikHandleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            isSubmitting,
            errors,
          }) => (
            <Form onSubmit={formikHandleSubmit}>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  className="text-transform-none"
                  name="title"
                  type="text"
                  value={values.title}
                  onChange={handleChange}
                  isInvalid={touched.title && !!errors.title}
                  onBlur={handleBlur}
                  maxLength={100}
                />
                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="h-100">
                <Form.Label>Headline</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="headline"
                  value={values.headline}
                  onChange={handleChange}
                  isInvalid={touched.headline && !!errors.headline}
                  onBlur={handleBlur}
                  maxLength={300}
                />
                <Form.Control.Feedback type="invalid">{errors.headline}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>URL</Form.Label>
                <Form.Control
                  className="text-transform-none"
                  name="url"
                  type="text"
                  value={values.url}
                  onChange={handleChange}
                  isInvalid={touched.url && !!errors.url}
                  onBlur={handleBlur}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Thumbnail</Form.Label>
                <Box pb={3}>
                  <img src={values.thumbnail} alt="thumbnail" />
                </Box>
                <Dropzone
                  onDrop={async (acceptedFiles) => {
                    const image = await uploadImage(acceptedFiles[0]);
                    if (image) {
                      setFieldValue('thumbnail', image.url);
                      setFieldValue('thumbnail_key', image.key);
                    }
                  }}
                  accept="image/png, image/jpeg"
                  showFiles={false}
                  multiple={false}
                />
              </Form.Group>
              <Row className="justify-content-center my-4">
                <Button
                  isLoading={isSubmitting}
                  loadingText={articleToEdit ? 'EDIT ARTICLE' : 'ADD ARTICLE'}
                  isDisabled={articleToEdit ? false : !isValid}
                  type="submit"
                >
                  {articleToEdit ? 'EDIT ARTICLE' : 'ADD ARTICLE'}
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpsertArticleModal;
