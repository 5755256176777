import { FC, useContext, useMemo, useState } from 'react';

import { VStack, Wrap } from '@chakra-ui/react';

import { DealStateEnum, DealStateLabelMap, TITLING_STATES } from '../../../../gql/dealGql';
import { PodUser, TeamType, usePodsQuery } from '../../../../gql/generated/graphql';

import { Option } from '../../../shared/types';

import ClearFiltersButton from './ClearFiltersButton';
import ExportButton from './ExportButton';
import FilterInput from './FilterInput';
import MoreFilters from './MoreFilters';
import MultiAutocomplete from './MultiAutocomplete';
import Search from './Search';

import { FiltersContext } from '../../../../libs/contextLib';
import { hasKey } from '../../../../utils/objects';
import { FilterOptionsEnum, FiltersActionKind, filterOptions } from '../../utils';

const DEAL_STATE = 'Deal State';

interface Props {
  maxAge: string | undefined;
  titlingPodUsers: PodUser[];
}

const parseTitlingStates = (options: DealStateEnum[] | DealStateEnum | undefined) => {
  if (typeof options === 'string') {
    return [{ value: options, label: DealStateLabelMap[options] }];
  }
  if (Array.isArray(options)) {
    return options.map((value) => ({ value, label: DealStateLabelMap[value] }));
  }
  return undefined;
};

const TableFilters: FC<Props> = ({ maxAge, titlingPodUsers }) => {
  const {
    filters: { titling: titlingFilters },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const getActiveFilters = () => {
    const filterArr: FilterOptionsEnum[] = [];
    filterOptions.map((fo) => {
      if (hasKey(titlingFilters, fo.value)) {
        if (titlingFilters[fo.value]) {
          filterArr.push(fo.label);
        }
      }
      if (fo.toValue) {
        if (hasKey(titlingFilters, fo.toValue)) {
          if (titlingFilters[fo.toValue]) {
            filterArr.push(fo.label);
          }
        }
      }
      return filterArr;
    });
    return filterArr;
  };

  const [selectedFilters, setSelectedFilters] = useState<FilterOptionsEnum[]>(() =>
    getActiveFilters(),
  );

  const [titlingPods, setTitlingPods] = useState<Option[]>([]);

  usePodsQuery({
    variables: { team_type: [TeamType.Titling] },
    onCompleted: (data) => {
      if (data?.pods) {
        const titlingPodsAll = [...data.pods];

        titlingPodsAll.unshift({
          id: undefined,
          name: 'No Team Assigned',
        });

        setTitlingPods(
          titlingPodsAll.map((titlingPod) => ({
            value: titlingPod?.id?.toString() ?? '',
            label: titlingPod?.name ?? '',
          })),
        );
      }
    },
  });

  const userOptions = useMemo(() => {
    const users = [...titlingPodUsers];
    users.unshift({
      user_id: 'blank',
      user: {
        id: 'blank',
        name: 'Not Assigned',
        email: '',
        nickname: '',
        hours: '',
        on_vacation: false,
        auto_assign_deals: false,
      },
    });
    return users.map((titlingUser) => ({
      value: titlingUser.user_id ?? '',
      label: titlingUser.user?.name ?? '',
    }));
  }, [titlingPodUsers]);

  return (
    <VStack p={6} bgColor="babyBlueEyes" borderTopRadius={8} w="100%">
      <Wrap w="100%" overflow="visible" align="center" spacing={4}>
        <Search
          dispatch={dispatchFilters}
          actionKind={FiltersActionKind.SEARCH}
          value={titlingFilters.search}
        />
        <MultiAutocomplete
          placeholder="Team"
          options={titlingPods}
          actionKind={FiltersActionKind.SET_TITLING_PODS}
          dispatch={dispatchFilters}
          value={titlingFilters.titlingPods}
          width={300}
        />
        <MultiAutocomplete
          placeholder="Assigned to"
          options={userOptions}
          actionKind={FiltersActionKind.SET_ASSIGNEES}
          dispatch={dispatchFilters}
          value={titlingFilters.assignees}
          width={300}
        />
        <MultiAutocomplete
          placeholder={DEAL_STATE}
          options={parseTitlingStates(TITLING_STATES) ?? []}
          dispatch={dispatchFilters}
          actionKind={FiltersActionKind.SET_DEAL_STATE}
          value={titlingFilters.dealStates}
        />
        <MoreFilters
          options={filterOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          dispatch={dispatchFilters}
        />
        <ExportButton />
        <ClearFiltersButton />
      </Wrap>
      {filterOptions.length && selectedFilters.length ? (
        <Wrap w="100%" overflow="visible" align="center" spacing={4} pt={4}>
          {filterOptions.map((fo) => {
            if (selectedFilters?.includes(fo.label)) {
              return (
                <FilterInput
                  key={fo.label}
                  option={fo}
                  maxAge={maxAge}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              );
            }
            return null;
          })}
        </Wrap>
      ) : null}
    </VStack>
  );
};

export default TableFilters;
