import { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import { FaCheckCircle, FaCircle, FaClock } from 'react-icons/fa';
import { MdFastForward } from 'react-icons/md';
import { Box, Tooltip } from '@chakra-ui/react';

interface BaseProps {
  position: number;
  step: number;
  lastStep?: number;
}

type ConditionalProps =
  | {
      canSkip: boolean;
      handleSkip: () => Promise<void>;
    }
  | {
      canSkip?: never;
      handleSkip?: never;
    };

type TimelineIconProps = BaseProps & ConditionalProps;

const TimelineIcon: FC<TimelineIconProps> = ({
  position,
  step,
  lastStep = 6,
  canSkip = false,
  handleSkip,
}) => {
  const getIconProps = () => {
    if (step > position || step === lastStep) {
      return { as: FaCheckCircle, color: 'caribbeanGreen.500' };
    }

    if (step === position) {
      return {
        ...(canSkip
          ? { as: MdFastForward, onClick: handleSkip, cursor: 'pointer' }
          : { as: FaClock }),
        color: 'ceruleanBlue',
      };
    }

    return { as: FaCircle, color: 'black' };
  };

  return (
    <Tooltip hidden={!canSkip || step !== position} label="Skip" placement="right">
      <Box zIndex={1}>
        <Icon
          backgroundColor="white"
          borderRadius="50%"
          marginX={step === position ? '-4px' : 0}
          border={step === position ? '5px solid' : '0px'}
          padding={step === position ? '1px' : '0px'}
          fontSize={step === position ? '30px' : '22px'}
          {...getIconProps()}
        />
      </Box>
    </Tooltip>
  );
};

export default TimelineIcon;
