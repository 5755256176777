import gql from 'graphql-tag';

export class Auction {
  id?: number;
  name?: string;
  address_line?: string;
  zip?: string;
  city?: string;
  state?: string;
  phone_number?: string;
  average_transport_cost?: number;

  constructor() {
    this.name = '';
    this.address_line = '';
    this.zip = '';
    this.city = '';
    this.state = '';
    this.phone_number = '';
  }
}

export const getAuctionNameById = (id: number, auctions: Auction[]) =>
  auctions.find((auction) => auction.id === id)?.name;

export const auctionsQuery = gql`
  query auctions {
    auctions {
      id
      name
      address_line
      zip
      state
    }
  }
`;

export const auctionUpsert = gql`
  mutation auctionUpsert($auction: auctionInput) {
    auctionUpsert(auction: $auction) {
      id
    }
  }
`;

export const auctionDistancesQuery = gql`
  query auctionDistances($data: auctionDistancesInput) {
    auctionDistances(data: $data) {
      name
      transport_cost
      id
    }
  }
`;

export const auctionAverageTransportCostQuery = gql`
  query auctionAverageTransportCost($id: ID!, $customerAddress: String!) {
    auctionAverageTransportCost(id: $id, customerAddress: $customerAddress)
  }
`;
