import { useMemo } from 'react';

import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { Card as BootstrapCard } from 'react-bootstrap';
import { Virtuoso } from 'react-virtuoso';

import { Deal } from '../../gql/dealGql';
import { getDealsNotificationCountByType } from '../../gql/notificationsGql';

import NotificationsAvatar from '../NotificationBadge/NotificationAvatar';
import Task from '../Task';
// eslint-disable-next-line import/order
import styled from 'styled-components';

import './scrollbar.css';

export const BucketCard = styled(BootstrapCard)`
  height: 100%;
`;

export const BucketHeader = styled(BootstrapCard.Header)`
  text-align: center;
`;

export const BucketBody = styled(BootstrapCard.Body)`
  padding: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

interface DealsBucketProps {
  title: string;
  dailyCount?: number;
  deals: Deal[];
  assumedId?: string;
}

const DealsSwimlane = ({ title, dailyCount, deals, assumedId }: DealsBucketProps) => {
  const dealsNotificationCountByType = useMemo(
    () => getDealsNotificationCountByType(deals),
    [deals],
  );

  return (
    <VStack
      w="full"
      maxW="285px"
      minW="200px"
      bgColor="brightGray"
      h="900px"
      spacing={0}
      borderRadius={10}
    >
      <Flex
        w="full"
        bg="secondary"
        px={3}
        py={2}
        textColor="white"
        justifyContent="space-between"
        borderTopRadius={10}
      >
        <Text fontWeight={400}>{title}</Text>
        <NotificationsAvatar count={dealsNotificationCountByType.unseenMessages} />
      </Flex>
      <Flex
        w="full"
        justifyContent="space-between"
        bgColor="queenBlue"
        textColor="white"
        fontSize="sm"
        px={2}
        fontWeight="light"
      >
        <Text>Today: {dailyCount}</Text>
        <Text>Total: {deals.length}</Text>
      </Flex>
      <VStack w="full" py="1px" overflowX="hidden" overflowY="auto">
        <Virtuoso
          className="custom-scrollbar"
          style={{
            height: '900px',
            width: 'inherit',
          }}
          data={deals}
          itemContent={(i, deal) => (
            <Box w="full" py={1.5} px={2} key={deal.id ?? i}>
              <Task deal={deal} assumedId={assumedId} />
            </Box>
          )}
        />
      </VStack>
    </VStack>
  );
};

export default DealsSwimlane;
