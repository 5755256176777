export enum KeyMetricsTypeEnum {
  COUNT = 'count',
  MONEY = 'money',
  PERCENTAGE = 'percentage',
}

export const METRICS = {
  TRANSACTIONS: 'Transactions',
  RPT: 'cRPT',
  VSC_PERCENTAGE: 'VSC %',
  GAP_PERCENTAGE: 'GAP %',
  TIME_TO_FLOOR: 'Time to Floor',
  CLOSING_PERCENTAGE: 'Closing Percentage',
  TOTAL_REVENUE: 'Total Revenue',
  SIGNED_PERCENTAGE: 'Signed Percentage',
  DAYS_TO_PICKUP: 'Days to Pickup',
  FEE_REVENUE: 'Fee Revenue',
  REVENUE: 'Revenue',
} as const;

export type MetricType = typeof METRICS[keyof typeof METRICS];

export const METRICS_TO_METRICS_TYPE: {
  [K in
    | KeyMetricsTypeEnum.COUNT
    | KeyMetricsTypeEnum.MONEY
    | KeyMetricsTypeEnum.PERCENTAGE]: MetricType[];
} = {
  [KeyMetricsTypeEnum.COUNT]: [METRICS.TRANSACTIONS, METRICS.DAYS_TO_PICKUP],
  [KeyMetricsTypeEnum.MONEY]: [
    METRICS.TOTAL_REVENUE,
    METRICS.FEE_REVENUE,
    METRICS.REVENUE,
    METRICS.RPT,
  ],
  [KeyMetricsTypeEnum.PERCENTAGE]: [
    METRICS.VSC_PERCENTAGE,
    METRICS.GAP_PERCENTAGE,
    METRICS.SIGNED_PERCENTAGE,
    METRICS.CLOSING_PERCENTAGE,
  ],
};
