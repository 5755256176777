/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getEnv, isProd } from '../config';

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://223fd98764ae4bf182bf8b13af7ad906@o583742.ingest.sentry.io/5737012',
    integrations: [new Integrations.BrowserTracing()],
    environment: getEnv(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isProd ? 0.2 : 1.0,
  });
};

export const handleErrorWithSentry = (
  filename: string,
  message: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any,
) => {
  // Auth errors
  if (error?.message && !(error instanceof Error)) {
    data = error;
    error = new Error(error.message);
    // API errors
  } else if (error?.config?.url) {
    data.url = error.config.url;
  }
  // send error to sentry
  const scope: Sentry.Scope = new Sentry.Scope();
  scope.setExtra('filename', filename);
  scope.setExtra('message', message);
  scope.setExtra('data', data);
  Sentry.captureException(error, scope);
};
