import { useState } from 'react';

import { Box, BoxProps, HStack, Stack, Text } from '@chakra-ui/react';
import { SingleValue } from 'react-select';

import { DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import { Maybe } from '../../gql/generated/graphql';

import CustomReactSelect from '../shared/CustomReactSelect';

export type Suggestion = {
  isDeal: boolean;
  isClickable: boolean;

  id: Maybe<number>;
  state: Maybe<string>;
  customerName: Maybe<string>;
  phoneNumber: Maybe<string>;
  homePhoneNumber: Maybe<string>;
  email: Maybe<string>;
  vin: Maybe<string>;
};

type SuggestProps = BoxProps & {
  label: 'Deals' | 'Web Leads';
  suggestions: Suggestion[];
  setSuggestions: (suggestions: Suggestion[]) => void;
  search: (value: string) => void;
  isLoading: boolean;
  handleChange: (newValue: SingleValue<Suggestion>) => void;
};

const Suggest = ({
  label,
  suggestions,
  setSuggestions,
  search,
  isLoading,
  handleChange,
  ...rest
}: SuggestProps) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleInputChange = (value: string) => {
    setSearchValue(value);
    if (value.length >= 3) {
      search(value);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <Box position="relative" w={200} {...rest}>
      <CustomReactSelect
        placeholder={`Search ${label}`}
        inputValue={searchValue}
        onInputChange={handleInputChange}
        onBlur={() => {
          setSearchValue('');
          setSuggestions([]);
        }}
        isLoading={isLoading}
        options={suggestions}
        noOptionsMessage={() => (
          <Text fontSize="sm">
            {searchValue.length >= 3 ? `No ${label} found` : 'Enter at least 3 characters'}
          </Text>
        )}
        filterOption={() => true} // This shows all options. The filtering is done on the API.
        value={null}
        onChange={handleChange}
        maxMenuHeight={window.innerHeight * 0.8}
        styles={{
          option: (provided) => ({
            ...provided,
            borderBottomWidth: 1,
            borderBottomColor: 'gray.50',
          }),
        }}
        formatOptionLabel={(suggestion: Suggestion) => (
          <Box cursor={suggestion.isClickable ? 'pointer' : 'not-allowed'}>
            <Stack spacing={1} fontSize="2xs">
              <HStack spacing={1} justifyContent="space-between" alignItems="start" fontSize="sm">
                <Text fontWeight="bold">{suggestion.customerName}</Text>
                {suggestion.isDeal ? (
                  <Text fontWeight="thin" whiteSpace="nowrap">
                    ID: {suggestion.id}
                  </Text>
                ) : null}
              </HStack>
              <Stack spacing={0.5}>
                {suggestion.isDeal ? (
                  <Text>Deal State: {DealStateLabelMap[suggestion.state as DealStateEnum]}</Text>
                ) : null}
                <Text>
                  {suggestion.phoneNumber}
                  {suggestion.phoneNumber && suggestion.homePhoneNumber ? ' / ' : ''}
                  {suggestion.homePhoneNumber}
                </Text>
                <Text>{suggestion.email}</Text>
                <Text>{suggestion.vin}</Text>
              </Stack>
            </Stack>
          </Box>
        )}
      />
    </Box>
  );
};

export default Suggest;
