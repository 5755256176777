import { useContext } from 'react';

import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { DealStateEnum, DealStateLabelMap } from '../../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../../gql/generated/graphql';

import { PermissionEnum } from '../../../constants/permissions';
import { Can } from '../../../libs/Can';
import { DealContext } from '../../../libs/DealContext';

interface MoveToButtonProps {
  newState: DealStateEnum;
  width?: string;
  inContractStatusTimeline?: boolean;
}

const MoveToButton = ({ newState, width, inContractStatusTimeline = false }: MoveToButtonProps) => {
  const { deal, dealRefetch, claimIsRequired } = useContext(DealContext);

  const [updateDealState] = useDealUpdateStateMutation();

  const getPermissionName = (dealState: DealStateEnum) => {
    return dealState
      ?.split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Can I={(PermissionEnum.ChangeStateTo + getPermissionName(newState)) as PermissionEnum}>
      <Button
        width={width}
        variant={inContractStatusTimeline ? 'secondary' : 'primary'}
        size={inContractStatusTimeline ? 'sm' : undefined}
        onClick={async () => {
          if (!deal.id) {
            return;
          }
          try {
            await updateDealState({
              variables: {
                id: deal.id,
                state: newState,
              },
            });
            await dealRefetch();
            toast.success(`Moved deal to "${DealStateLabelMap[newState]}"`);
          } catch (e) {
            toast.error(
              `Failed to move deal to "${DealStateLabelMap[newState]}". Please refresh the page`,
            );
          }
        }}
        isDisabled={claimIsRequired}
      >
        {`${inContractStatusTimeline ? '' : 'SEND TO '}${DealStateLabelMap[
          newState
        ].toUpperCase()}`}
      </Button>
    </Can>
  );
};

export default MoveToButton;
