import { Badge } from '@chakra-ui/react';

import colors from '../../chakra/foundations/colors';

interface MoreBadgeProps {
  number: number;
  color?: string;
}

const MoreBadge = ({ number, color = colors.babyBlueEyes }: MoreBadgeProps) => {
  const label = `+ ${number}`;

  return (
    <Badge backgroundColor={color} ml={1}>
      {label}
    </Badge>
  );
};

export default MoreBadge;
