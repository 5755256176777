import { useMemo, useState } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  List,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  TagProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai';
import { BiCheck } from 'react-icons/bi';

export type BaseOption = {
  id: string | number;
  name: string;
  color?: string;
};

type LEMultiSelectProps<TOption extends BaseOption> = {
  name: string;
  label?: string;
  options: TOption[];
  additionalOnSelect?: (options: BaseOption[]) => void;
  loading?: boolean;
  _tagProps?: TagProps;
};

const LEMultiSelect = <TOption extends BaseOption>({
  name,
  label,
  options,
  additionalOnSelect,
  loading,
  _tagProps,
}: LEMultiSelectProps<TOption>) => {
  const [order, setOrder] = useState<'DESC' | 'ASC'>('ASC');
  const [search, setSearch] = useState<string>();
  const [field, , { setValue }] = useField<BaseOption[]>(name);

  const collapse = useDisclosure({ defaultIsOpen: false });

  const optionsList = useMemo(() => {
    if (!options?.length) {
      return [];
    }
    return options
      .filter((option) => !search || option?.name?.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) =>
        a?.name && b?.name
          ? order === 'ASC'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
          : -1,
      );
  }, [options, search, order]);

  return (
    <FormControl>
      {label && <FormLabel fontSize="sm">{label}</FormLabel>}
      <Flex direction="column">
        <Flex
          justifyContent="space-between"
          minH={8}
          w="100%"
          border="1px"
          borderRadius={collapse.isOpen ? '2px 2px 0 0' : '2px'}
          px={2}
          py={1}
          onClick={collapse.onToggle}
        >
          {loading ? (
            <Spinner size="sm" alignSelf="center" />
          ) : (
            <Box>
              {field.value?.length ? (
                field.value.map((option) => (
                  <Tag
                    key={option.id}
                    marginRight={1}
                    marginBlock={1}
                    {...(option.color && { bg: option.color })}
                    {..._tagProps}
                  >
                    <TagLabel>{option.name}</TagLabel>
                    <TagCloseButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setValue(
                          field.value.filter((selectedOption) => selectedOption.id !== option.id),
                        );
                      }}
                    />
                  </Tag>
                ))
              ) : (
                <Text opacity={0.2} fontSize="sm" mb={0}>
                  Nothing is selected
                </Text>
              )}
            </Box>
          )}
          <ChevronDownIcon marginTop={field.value?.length ? 1 : 0} w={5} h={5} />
        </Flex>
        <Collapse in={collapse.isOpen}>
          <Box
            px={2}
            py={2}
            border="1px"
            borderRadius="0 0 12px 12px"
            bgColor="selectGray"
            opacity={0.95}
            boxShadow={2}
          >
            <Flex gap={2} align="center" marginBottom={1}>
              <Icon
                textColor="white"
                cursor="pointer"
                as={order === 'ASC' ? AiOutlineSortAscending : AiOutlineSortDescending}
                onClick={() => {
                  setOrder(order === 'ASC' ? 'DESC' : 'ASC');
                }}
              />
              <Input
                placeholder="Search"
                bgColor="white"
                size="xs"
                defaultValue={search}
                borderRadius={2}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </Flex>
            <List maxH={48} overflowY="scroll">
              {optionsList.map((option) => {
                const isOptionSelected = field.value?.some(
                  (selectedOption) => selectedOption.id === option.id,
                );

                return (
                  <Flex
                    _hover={{ bgColor: 'selectBlue', textColor: 'black' }}
                    borderRadius={2}
                    key={option.id}
                  >
                    {isOptionSelected ? (
                      <Flex justifyContent="center" alignItems="center" mx={1}>
                        <BiCheck size="15px" color="white" />
                      </Flex>
                    ) : (
                      <Box w={6} />
                    )}
                    <Box
                      w="100%"
                      key={option.id}
                      cursor="pointer"
                      textColor="white"
                      fontSize="sm"
                      onClick={() => {
                        if (
                          field.value &&
                          !field.value?.some(
                            (selectedOption) => selectedOption.id.toString() === option.id,
                          )
                        ) {
                          setValue([
                            ...field.value,
                            { id: option.id.toString(), name: option.name, color: option?.color },
                          ]);
                          additionalOnSelect?.([
                            ...field.value,
                            { id: option.id.toString(), name: option.name, color: option?.color },
                          ]);
                        }
                      }}
                    >
                      {option.name}
                    </Box>
                  </Flex>
                );
              })}
            </List>
          </Box>
        </Collapse>
      </Flex>
    </FormControl>
  );
};

export default LEMultiSelect;
