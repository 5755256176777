import { FC, useEffect, useState } from 'react';

import {
  Button,
  Flex,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp, BiTrash } from 'react-icons/bi';
import { MdPersonAdd } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Maybe,
  useSoftDeleteUserMutation,
  useUsersWithRoleQuery,
} from '../../gql/generated/graphql';
import { User } from '../../gql/userGql';

import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import Modal from '../../components/shared/Modal';

import CreateUserModal from './CreateUserModal/CreateUserModal';

import usePagination from '../../hooks/usePagination';
import { formatDate } from '../../libs/utils';

const FollowUpsTable: FC = () => {
  const paginationContext = usePagination();

  const [columnToSort, setColumnToSort] = useState<string>('date');
  const [sortByAscending, setSortByAscending] = useState<boolean>(true);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [sortedUsers, setSortedUsers] = useState<Maybe<User>[] | null>(null);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const {
    isOpen: deleteModalIsOpen,
    onOpen: deleteModalOnOpen,
    onClose: deleteModalOnClose,
  } = useDisclosure();
  const {
    isOpen: createUserModalIsOpen,
    onOpen: createUserModalOnOpen,
    onClose: createUserModalOnClose,
  } = useDisclosure();
  const { data: users, loading } = useUsersWithRoleQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast.error(`Couldn't load Users. Please refresh. Error: ${error.message}`);
    },
  });

  const [softDeleteUser, { loading: softDeleteLoading }] = useSoftDeleteUserMutation();

  const handleDelete = async (id: string) => {
    try {
      await softDeleteUser({
        variables: { id },
      });

      // remove deleted user from the list
      setSortedUsers(sortedUsers?.filter((u) => u?.id !== id) ?? null);
      toast.success('User Deleted');
    } catch (error) {
      toast.error('Failed to delete user.');
    } finally {
      deleteModalOnClose();
    }
  };

  useEffect(() => {
    // sort pods by name
    if (!users?.usersWithRole) {
      return;
    }

    const usrs = [...users.usersWithRole];

    usrs.sort((a, b) => {
      if (columnToSort === 'date') {
        const aDate = new Date(a?.created_at ?? '');
        const bDate = new Date(b?.created_at ?? '');
        return sortByAscending
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      }

      // Default sort by name
      const aName = a?.name ?? '';
      const bName = b?.name ?? '';

      return sortByAscending ? aName.localeCompare(bName) : bName.localeCompare(aName);
    });

    setSortedUsers(usrs as User[]);
  }, [users?.usersWithRole, columnToSort, sortByAscending]);

  const HeaderColumn: FC<{
    columnName: string;
    width: string;
    canSort?: boolean;
  }> = ({ columnName, width, canSort = false }) => (
    <Th
      pl={columnName === 'name' ? 1 : undefined}
      w={width}
      color="white"
      cursor={canSort ? 'pointer' : 'default'}
      onClick={() => {
        if (canSort) {
          // Toggle sort direction if already sorting by name, otherwise default to ascending
          if (columnToSort === columnName) {
            setSortByAscending(!sortByAscending);
          } else {
            setColumnToSort(columnName);
            setSortByAscending(true);
          }
        }
      }}
    >
      {columnName.replace('_', ' ').toUpperCase()}
      {columnToSort === columnName && canSort && (
        <Icon as={sortByAscending ? BiChevronDown : BiChevronUp} marginLeft={1} />
      )}
    </Th>
  );

  return (
    <>
      <Flex
        h={16}
        w="100%"
        bgColor="oxfordBlue"
        borderRadius="10px 10px 0 0"
        alignItems="center"
        justifyContent="flex-end"
        px={8}
      >
        <Button
          variant="secondary"
          leftIcon={<MdPersonAdd size="20px" />}
          onClick={() => createUserModalOnOpen()}
        >
          Add User
        </Button>
      </Flex>
      <Table>
        <Thead cursor="pointer" color="white" bgColor="queenBlue" w="100%" px={6} height="55px">
          <HeaderColumn width="1%" columnName="" />
          <HeaderColumn width="15%" columnName="name" canSort />
          <HeaderColumn width="15%" columnName="date" canSort />
          <HeaderColumn width="15%" columnName="team" />
          <HeaderColumn width="15%" columnName="roles" />
          <HeaderColumn width="15%" columnName="phone number" />
          <HeaderColumn width="15%" columnName="twilio number" />
          <HeaderColumn width="5%" columnName="actions" />
        </Thead>
        <Tbody>
          {sortedUsers?.map((user) => {
            if (!user) {
              return null;
            }

            return (
              <Tr
                height="55px"
                borderBottom="2px"
                borderX="1px"
                borderColor="backgroundGray"
                cursor="pointer"
                _hover={{ bgColor: 'hoverGray' }}
                key={user.id}
              >
                <Td> </Td>
                <Td pl={1} w="15%">
                  {user.name}
                </Td>
                <Td w="15%"> {formatDate(user?.created_at?.toString())}</Td>
                <Td w="15%"> {user?.pods?.map((pod) => pod.name).join(', ')}</Td>
                <Td w="15%"> {user?.role}</Td>
                <Td w="15%"> {user?.phone_number}</Td>
                <Td w="15%"> {user?.twilio_number}</Td>

                <Td w="10%">
                  <Flex justifyContent="center">
                    <IconButton
                      icon={<BiTrash />}
                      variant="iconHover"
                      size="xs"
                      color="errorsRed"
                      aria-label="Delete User"
                      onClick={(e) => {
                        e.stopPropagation();
                        setUserToDelete(user);
                        deleteModalOnOpen();
                      }}
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        filters={{
          currentPage,
          itemsPerPage,
        }}
        parentSetCurrentPage={setCurrentPage}
        parentSetItemsPerPage={setItemsPerPage}
        numItemsShown={sortedUsers?.length || 0}
        paginationContext={paginationContext}
        itemsPerPageOptions={[50, 100]}
        borderTop="1px solid"
        borderColor="lightGray"
      />
      <Modal
        title="Delete User Confirmation"
        isOpen={deleteModalIsOpen}
        onClose={deleteModalOnClose}
        leftButtons={
          <Button
            variant="warning"
            onClick={() => {
              setUserToDelete(null);
              deleteModalOnClose();
            }}
          >
            CANCEL
          </Button>
        }
        rightButtons={
          <Button
            variant="boot"
            onClick={async () => {
              if (!userToDelete) {
                return;
              }
              await handleDelete(userToDelete.id);
            }}
            loadingText="Delete"
            isLoading={softDeleteLoading}
          >
            Delete
          </Button>
        }
      >
        <Text align="center">Are you sure you want to delete {userToDelete?.name}?</Text>
      </Modal>
      <CreateUserModal isOpen={createUserModalIsOpen} onClose={createUserModalOnClose} />
      <Loader isLoading={loading} />
    </>
  );
};

export default FollowUpsTable;
