import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

export const baseStyle = definePartsStyle({
  field: {
    borderColor: 'silverSlateGray',
    _hover: {
      borderColor: 'silverSlateGray',
    },
    _invalid: {
      bgColor: 'spanishPink',
      borderColor: 'errorsRed',
      _hover: {
        borderColor: 'errorsRed',
      },
    },
    _focus: {
      bgColor: 'azureishWhite',
      borderColor: 'cornFlowerBlue',
      _hover: {
        borderColor: 'cornFlowerBlue',
      },
    },
    _disabled: {
      opacity: 1,
      color: 'basicGray',
      bgColor: 'brightGray',
      borderColor: 'brightGray',
      _hover: {
        borderColor: 'brightGray',
      },
    },
  },
});

const input = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'sm',
    variant: undefined,
  },
});

export default input;
