import { ComponentStyleConfig } from '@chakra-ui/react';

const mediaEditForm: ComponentStyleConfig = {
  parts: ['flexContainer', 'inlineSaveButtonContainer', 'formButtons'],
  baseStyle: {
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      ml: 3,
      flex: 1,
    },
    inlineSaveButtonContainer: {
      display: 'none',
    },
    formButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      mt: 5,
    },
  },
  variants: {
    reduced: {
      flexContainer: {
        flexDirection: 'row',
        ml: 0,
      },
      inlineSaveButtonContainer: {
        display: 'inline-block',
      },
      formButtons: {
        display: 'none',
      },
    },
  },
};

export default mediaEditForm;
