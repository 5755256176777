import { stringDoesNotMatchMessage } from '../components/MediaCenter/components/MediaModal/validations';
import { Address } from '../gql/generated/graphql';
import { DriverLicenseMetadata, DriverLicenseMetadataValueFields } from '../gql/mediaGql';
import { removeWhiteSpaces } from './text';

export const isAddressField = (fieldName: DriverLicenseMetadataValueFields): boolean =>
  fieldName === 'address' ||
  fieldName === 'address2' ||
  fieldName === 'city' ||
  fieldName === 'state' ||
  fieldName === 'zip';

export const isSomeAddressFieldNotMatching = (errors: DriverLicenseMetadata | undefined): boolean =>
  errors?.address === stringDoesNotMatchMessage ||
  errors?.address2 === stringDoesNotMatchMessage ||
  errors?.city === stringDoesNotMatchMessage ||
  errors?.state === stringDoesNotMatchMessage ||
  errors?.zip === stringDoesNotMatchMessage;

export const haveAllAddressFieldsBeenFilled = (
  metadata: DriverLicenseMetadata | undefined,
): boolean => {
  const { address, address2, city, state, zip } = metadata ?? {};

  const addressValue = removeWhiteSpaces(address);
  const address2Value = removeWhiteSpaces(address2);
  const cityValue = removeWhiteSpaces(city);
  const stateValue = removeWhiteSpaces(state);
  const zipValue = removeWhiteSpaces(zip);

  return (
    !!addressValue &&
    (!!address2Value || address2Value === '') &&
    !!cityValue &&
    !!stateValue &&
    !!zipValue
  );
};

export const getBothAddressLines = (address: Address) => {
  const { address_line, address_line_2 } = address;

  if (!address_line && !address_line_2) {
    return '';
  }

  if (!address_line) {
    return address_line_2;
  }

  if (!address_line_2) {
    return address_line;
  }

  return `${address_line}, ${address_line_2}`;
};

export const getAddressValue = ({
  address_line,
  address_line_2,
  city,
  state,
  zip,
}: Pick<Address, 'address_line' | 'address_line_2' | 'city' | 'state' | 'zip'> = {}) => {
  const addressValueArr = [];
  if (address_line) {
    addressValueArr.push(address_line);
  }
  if (address_line_2) {
    addressValueArr.push(address_line_2);
  }
  if (city) {
    addressValueArr.push(city);
  }
  if (state) {
    addressValueArr.push(state);
  }
  let addressValue = addressValueArr.join(', ');
  if (zip) {
    addressValue += ` ${zip}`;
  }

  return addressValue;
};
