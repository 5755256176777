import { DealSourceEnum, DealStateEnum } from '../gql/dealGql';

// Commented out permissions are unused in this app
export enum PermissionEnum {
  // BackOffice = 'Role:Back Office',
  // ChangeStateToPaidOff = 'Change State To:Paid Off',
  AutomateCreditApplication = 'Automate:Credit Application',
  BootAcquisitionDeal = 'Boot:Acquisition Deal',
  BootDeal = 'Boot:Deal',
  BuildDeal = 'Build:Deal',
  CalculateFee = 'Calculate:Fee',
  CalculateGap = 'Calculate:Gap',
  CalculateVSC = 'Calculate:VSC',
  ChangeStateTo = 'Change State To:', // This is for dynamic places in which we use Change State to
  ChangeStateToAnyPrevious = 'Change State To:Any Previous',
  ChangeStateToAtAuction = 'Change State To:At Auction',
  ChangeStateToFinalized = 'Change State To:Finalized',
  ChangeStateToReadyForPickup = 'Change State To:Ready For Pickup',
  ChangeStateToSold = 'Change State To:Sold',
  ChangeStateToTitleSent = 'Change State To:Title Sent',
  ClaimClosedDeal = 'Claim:Closed Deal',
  ClaimDeal = 'Claim:Deal',
  ClaimDealAsAccountingClerk = 'Claim:Deal As Accounting Clerk', // Former Funding Clerk
  ClaimDealAsStructuringManager = 'Claim:Deal As Structuring Manager',
  ClaimFinancialSpecialistInSoftClose = 'Claim:Financial Specialist In Soft Close',
  Closer = 'Role:Closer',
  ConfirmPayoffFailure = 'Confirm:Payoff Failure',
  CreateEstimate = 'Create:Estimate',
  CustomerService = 'Role:Customer Service',
  DeskingManager = 'Role:Desking Manager',
  EditDeal = 'Edit:Deal',
  EditDealSource = 'Edit:Deal Source',
  EditFundedOnwards = 'Edit:Funded Onwards',
  EditNotes = 'Can:Edit Notes',
  EditPriceAndCost = 'Edit:Price and Cost',
  EditRegistration = 'Edit:Registration',
  EditSellRate = 'Edit:Sell Rate',
  EditTaxes = 'Edit:Taxes',
  FilterDealOwner = 'Filter:Deal Owner',
  FinalizePayment = 'Finalize:Payment',
  FinancialSpecialist = 'Role:Financial Specialist',
  FundingClerk = 'Role:Funding Clerk',
  FundingManager = 'Role:Funding Manager',
  GenerateBankContract = 'Generate:Bank Contract',
  GenerateDocuments = 'Generate:Documents',
  LeadAdvisorPod = 'Lead:Advisor Pod',
  LogisticsCoordinator = 'Role:Logistics Coordinator',
  ManagePods = 'Manage:Pods',
  ManageSigningProcess = 'Manage:Signing Process',
  OverrideFailedApprovalAcquisitionDeals = 'Override:Failed Approval Acquisition Deals',
  PayoffClerk = 'Role:Payoff Clerk',
  PayoffManager = 'Role:Payoff Manager',
  PinNotes = 'Can:Pin Notes',
  ReassignDeal = 'Reassign:Deal',
  RequestBoot = 'Request:Boot',
  RequestPayoff = 'Request:Payoff',
  RequestPayoffFailure = 'Request:Payoff Failure',
  RequestPrequalification = 'Request:Prequalification',
  RevertState = 'Revert:State',
  SalesAdvisor = 'Role:Sales Advisor',
  SalesTeam = 'Role:Sales Team',
  See = 'See:', // This is for dyanmic places in which we use see
  SeeAIChat = 'See:AI Chat',
  SeeAllPayoffRequests = 'See:All Payoff Requests',
  SeeAllPods = 'See:All Pods',
  SeeAssignedToMeFilter = 'See:Assigned To Me Filter', // only given to Admins & Pod Leads
  SeeAssignedToDeletedUsersFilter = 'See:Assigned To Deleted Users Filter', // only given to Admins & Pod Leads and Outbound Advisor Manager
  // SeeAtAuction = 'See:At Auction',
  // SeeAtDMV = 'See:At DMV',
  SeeCallInDeals = 'See:Call-In Deals',
  // SeeCap = 'See:Cap',
  // SeeCapped = 'See:Capped',
  // SeeClosed = 'See:Closed',
  // SeeClosing = 'See:Closing',
  SeeCommission = 'See:Commission',
  SeeCompletedAcquisitionDeals = 'See:Completed Acquisition Deals',
  SeeCompletedBuyoutDeals = 'See:Completed Buyout Deals',
  SeeCompletedRefiDeals = 'See:Completed Refi Deals',
  SeeCosts = 'See:Costs',
  // SeeFunded = 'See:Funded',
  SeeGenerateContracts = 'See:Generate Contracts',
  SeeKeyMetrics = 'See:Key Metrics',
  // SeeKeyMetricsClosingPercentage = 'See:Key Metrics Closing Percentage',
  // SeeKeyMetricsDaysToPickup = 'See:Key Metrics Days To Pickup',
  // SeeKeyMetricsGAPPercentage = 'See:Key Metrics GAP Percentage',
  // SeeKeyMetricsRPT = 'See:Key Metrics RPT',
  // SeeKeyMetricsSignedPercentage = 'See:Key Metrics Signed Percentage',
  // SeeKeyMetricsSubRevenues = 'See:Key Metrics Sub Revenues',
  // SeeKeyMetricsTotalRevenue = 'See:Key Metrics Total Revenue',
  // SeeKeyMetricsTransactions = 'See:Key Metrics Transactions',
  // SeeKeyMetricsVSCPercentage = 'See:Key Metrics VSC Percentage',
  SeeMetrics = 'See:Metrics',
  SeeMetricsAccounting = 'See:Accounting Metrics',
  SeeMetricsAcquisitions = 'See:Acquisition Metrics',
  SeeMetricsDataEditor = 'See:Metrics:Data Editor',
  SeeMetricsFunding = 'See:Funding Metrics',
  SeeMetricsOverView = 'See:Overview Metrics',
  SeeMetricsSales = 'See:Sales Metrics',
  SeeMetricsTitling = 'See:Titling Metrics',
  // SeeNewEstimates = 'See:New Estimates',
  SeeNotifications = 'See:Notifications',
  SeeOutBoundDeals = 'See:Outbound Deals',
  SeeD2DDeals = 'See:D2D Deals',
  // SeePaidOff = 'See:Paid Off',
  // SeePayoffSent = 'See:Payoff Sent',
  SeePrequalification = 'See:Prequalification',
  SeeProfit = 'See:Profit',
  SeeQueue = 'See:Queue',
  // SeeReadyForPickup = 'See:Ready For Pickup',
  SeeScheduleFollowUpButton = 'See:Schedule Follow Up Button',
  // SeeSendPayoff = 'See:Send Payoff',
  // SeeSentForSignatures = 'See:Sent For Signatures',
  // SeeSentToProcessor = 'See:Sent To Processor',
  // SeeSigned = 'See:Signed',
  // SeeSigning = 'See:Signing',
  SeeSigningOnCom = 'See:Signing On Com',
  // SeeSoftClose = 'See:Soft Close',
  // SeeSold = 'See:Sold',
  // SeeStructuring = 'See:Structuring',
  // SeeTitleReceived = 'See:Title Received',
  // SeeTitleSent = 'See:Title Sent',
  // SeeUsers = 'See:Users',
  // SeeWaitingForTitle = 'See:Waiting For Title',
  SeeWebDeals = 'See:Web Deals',
  SeeWebLeadsSearch = 'See:Web Leads Search',
  SendPurchaseOrder = 'Send:Purchase Order',
  Setter = 'Role:Setter',
  ShippingLabelsCreate = 'Shipping Labels:Create',
  ShippingLabelsView = 'Shipping Labels:View',
  SkipContractValidation = 'Skip:Contract Validation',
  SkipGenerateAndUpload = 'Skip:Generate and Upload',
  SubmitAndClaimEstimate = 'Submit and Claim:Estimate',
  SubmitCreditApplication = 'Submit:Credit Application',
  SuperUser = 'Is:Super User',
  EditDealTypeAcquisition = 'Edit:Deal Type:Acquisition',
  TitleClerk = 'Role:Title Clerk',
  UploadContractsToRouteOne = 'Upload:Contracts To Route One',
  UnwindToSetDeal = 'Unwind to set:Deal',
  MoveToStructuring = 'Move to:Structuring',
  ViewCreditScore = 'View:Credit Score',
  SeeStructuringQueue = 'See:Structuring Queue',
  EditReserve = 'Edit:Reserve',
  ChangeCardPaymentAmountLimit = 'Change:Card Payment Amount Limit',

  // negative permissions
  CannotSeeCompletedPage = 'Cannot See: Completed Page',
  CannotSeeFollowUpsPage = 'Cannot See: Follow-Ups Page',
  CannotSeeMetricsPage = 'Cannot See: Metrics Page',
  CannotSeeBootedPage = 'Cannot See: Booted Page',
}

// Keep in sync with API types/user.ts
export enum RoleEnum {
  // TODO: see if updating these to the Auth0 values could simplify some team/pod logic
  DeskManager = 'desk_manager',
  FundingClerk = 'funding_clerk',
  FinancialSpecialist = 'financial_specialist',
  PodLeader = 'pod_leader', // Users with 'Lead:Advisor Pod' permission. Only used in Pod Management
}

export const readOnlyDealStates = [
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Funded,
];

export const editingPermissions = [
  PermissionEnum.SuperUser,
  PermissionEnum.DeskingManager,
  PermissionEnum.LeadAdvisorPod,
  PermissionEnum.FinancialSpecialist,
  PermissionEnum.FundingClerk,
];

// Keep in sync with API utils/authHelpers.ts
export const dealSourceToPermissionMap = new Map<DealSourceEnum, PermissionEnum>([
  [DealSourceEnum.Web, PermissionEnum.SeeWebDeals],
  [DealSourceEnum.D2D, PermissionEnum.SeeD2DDeals],
  [DealSourceEnum.CallIn, PermissionEnum.SeeCallInDeals],
  [DealSourceEnum.Outbound, PermissionEnum.SeeOutBoundDeals],
]);
