import { EmploymentStatusType } from './generated/graphql';

import { capitalizeFirstLetter } from '../utils/text';

export enum PayFrequency {
  Weekly = 'weekly',
  BiWeekly = 'biweekly',
  Monthly = 'monthly',
  Annually = 'annually',
}

export const payFrequencyOptions = Object.values(PayFrequency).map((frequency: PayFrequency) => ({
  label: capitalizeFirstLetter(frequency),
  value: frequency,
}));

export enum EmploymentStatusDisplay {
  employed = 'Employed',
  not_employed = 'Not Employed',
  retired = 'Retired',
}

export const isEmploymentInfoRequired = (employment: Employment) =>
  employment?.status === EmploymentStatusType.Employed;

export class Employment {
  id?: number;
  status: EmploymentStatusType;
  name?: string;
  job_title?: string;
  phone_number?: string;
  years_at_job?: number;
  months_at_job?: number;
  gross_income?: number;
  pay_frequency?: PayFrequency;

  constructor() {
    this.status = EmploymentStatusType.Employed;
    this.name = '';
    this.job_title = '';
    this.phone_number = '';
    this.pay_frequency = PayFrequency.Annually;
  }
}
