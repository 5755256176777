import { useContext, useMemo } from 'react';

import { Button, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import { DealSourceEnum, DealSourceLabelMap } from '../../gql/dealGql';
import {
  DealType,
  TeamType,
  useDealUpdateAssignmentsMutation,
  usePodsQuery,
} from '../../gql/generated/graphql';
import { User } from '../../gql/userGql';

import Autocomplete from '../shared/Autocomplete';
import Modal, { LEModalProps } from '../shared/Modal';
import UserAutocomplete from '../shared/UserAutocomplete';
import { Option } from '../shared/types';

import { validationSchema } from './validationSchema';

import { PermissionEnum } from '../../constants/permissions';
import SELECT_OPTIONS from '../../constants/selectOptions';
import { useUserAbilityAndVisibleStates } from '../../hooks/useUserAbilityAndVisibleStates';
import { Can } from '../../libs/Can';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { AbilityContext } from '../../libs/contextLib';
import { passValuesToSchema } from '../../libs/utils';

interface FormValues {
  type: DealType;
  pod_id: string;
  titling_pod_id: string;
  setter: User;
  closer: User;
  closer2: User;
  structuring_manager: User;
  funding_clerk: User;
  title_clerk: User;
  title_clerk2: User;
  deal_source: DealSourceEnum;
}

type UserAssignmentProps = Pick<LEModalProps, 'isOpen' | 'onClose'>;

const UserAssignment = ({ isOpen, onClose }: UserAssignmentProps) => {
  const { deal, dispatch } = useContext(DealContext);
  const ability = useContext(AbilityContext);
  const hideSalesPodAssignment =
    ability.has(PermissionEnum.TitleClerk) && !ability.has(PermissionEnum.SuperUser);

  const { loggedUserPods } = useUserAbilityAndVisibleStates();

  const { data: titlingPodsData } = usePodsQuery({
    variables: { team_type: [TeamType.Titling], includeDetails: true },
  });

  const [updateDealAssignments, { loading }] = useDealUpdateAssignmentsMutation();

  const initialValues = useMemo(
    () =>
      ({
        type: deal?.type,
        pod_id:
          loggedUserPods.length === 1
            ? loggedUserPods[0]?.id?.toString()
            : deal?.pod?.id?.toString(),
        titling_pod_id: deal.titling_pod_id,
        setter: deal?.setter,
        closer: deal?.closer,
        closer2: deal?.closer2,
        structuring_manager: deal?.structuring_manager,
        funding_clerk: deal?.funding_clerk,
        title_clerk: deal?.title_clerk,
        title_clerk2: deal?.title_clerk2,
        deal_source: deal?.source,
      } as FormValues),
    [deal, loggedUserPods],
  );

  const handleSubmit = async (values: FormValues) => {
    if (!deal.id) {
      return;
    }

    try {
      const res = await updateDealAssignments({
        variables: {
          id: deal.id,
          type: values?.type,
          pod_id: parseInt(values?.pod_id, 10),
          setter_id: values?.setter?.id,
          closer_id: values?.closer?.id || null,
          closer2_id: values?.closer2?.id || null,
          structuring_manager_id: values?.structuring_manager?.id || null,
          funding_clerk_id: values?.funding_clerk?.id,
          titling_pod_id: parseInt(values?.titling_pod_id, 10) || null,
          title_clerk_id: values?.title_clerk?.id || null,
          title_clerk2_id: values?.title_clerk2?.id || null,
          deal_source: values?.deal_source,
        },
      });

      dispatch({
        ...deal,
        type: DealActionsEnum.UpdateDeal,
        // TODO: Need to use the generated type in our DealContext
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: res.data?.dealUpdateAssignments as any,
      });
      toast.success('Deal Assignment Updated');
    } catch {
      toast.error('Failed to change assignments.');
    } finally {
      onClose();
    }
  };

  const podOptions = useMemo(
    () =>
      loggedUserPods
        .filter((p) => !p?.archived || p?.name === deal.pod?.name)
        .map((pod) => ({ value: pod?.id?.toString(), label: pod?.name } as Option)) || [],
    [loggedUserPods, deal.pod],
  );

  const titlingPodOptions = useMemo(
    () =>
      titlingPodsData?.pods
        ?.filter((p) => !p?.archived || p?.name === deal.titling_pod?.name)
        .map((pod) => ({ value: pod?.id?.toString(), label: pod?.name } as Option)) || [],
    [titlingPodsData, deal.titling_pod],
  );

  const dealSourceOptions = useMemo(
    () =>
      Object.values(DealSourceEnum).map(
        (dealSource) => ({ value: dealSource, label: DealSourceLabelMap[dealSource] } as Option),
      ) || [],
    [DealSourceLabelMap],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values) => passValuesToSchema(values, validationSchema, deal)}
      validateOnMount
      enableReinitialize
    >
      {({ handleChange, values, errors, isValid }) => {
        const selectedTitlingPod = titlingPodsData?.pods?.find(
          (titlingPod) => titlingPod?.id?.toString() === values.titling_pod_id,
        );

        const selectedPod = loggedUserPods.find((pod) => pod?.id?.toString() === values.pod_id);

        const insertProps = (name: keyof FormValues) => ({
          name,
          includeEmptyOption: true,
          user: values[name] as User,
          placeholder: 'Select...',
          setUser: (newUser: User | undefined) =>
            handleChange({ target: { name, value: newUser } }),
          loading,
        });

        return (
          <Modal
            title="Assign Users"
            size="xl"
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            leftButtons={
              <Button variant="warning" isDisabled={loading} onClick={onClose}>
                CANCEL
              </Button>
            }
            rightButtons={
              <Button
                type="submit"
                isDisabled={!isValid}
                isLoading={loading}
                onClick={() => handleSubmit(values)}
              >
                SAVE
              </Button>
            }
          >
            <Form>
              <VStack>
                <Can I={PermissionEnum.ReassignDeal}>
                  <Autocomplete
                    placeholder="Select..."
                    leftAddon="Deal Type"
                    name="type"
                    options={SELECT_OPTIONS.DEAL_TYPES}
                    error={errors?.type}
                  />
                  {!hideSalesPodAssignment && (
                    <>
                      <Autocomplete
                        placeholder="Select..."
                        leftAddon="Sales Pod"
                        name="pod_id"
                        options={podOptions}
                        error={errors?.pod_id}
                        isLoading={loading}
                      />
                      <UserAutocomplete
                        {...insertProps('setter')}
                        label="Advisor"
                        selectedPod={selectedPod}
                        error={errors?.setter?.id}
                      />
                      <UserAutocomplete
                        {...insertProps('closer')}
                        label="Financial Specialist"
                        selectedPod={selectedPod}
                      />
                      <UserAutocomplete
                        {...insertProps('closer2')}
                        label="Financial Specialist 2"
                        selectedPod={selectedPod}
                      />
                      <UserAutocomplete
                        {...insertProps('structuring_manager')}
                        label="Structuring Manager"
                        selectedPod={selectedPod}
                      />
                      <UserAutocomplete
                        {...insertProps('funding_clerk')}
                        label="Accounting Clerk"
                        includeEmptyOption
                        selectedPod={selectedPod}
                        error={errors?.funding_clerk?.id}
                      />
                    </>
                  )}
                  <Autocomplete
                    leftAddon="Titling Team"
                    name="titling_pod_id"
                    options={titlingPodOptions}
                    placeholder="Select..."
                    error={errors?.titling_pod_id}
                    isLoading={loading}
                  />
                  <UserAutocomplete
                    {...insertProps('title_clerk')}
                    filterOnlyActive
                    label="Title Clerk"
                    podType="titling"
                    selectedPod={selectedTitlingPod}
                    error={errors?.title_clerk?.id}
                  />
                  <UserAutocomplete
                    {...insertProps('title_clerk2')}
                    filterOnlyActive
                    label="Reg Assistant"
                    podType="titling"
                    selectedPod={selectedTitlingPod}
                    error={errors?.title_clerk2?.id}
                  />
                  <Can I={PermissionEnum.EditDealSource}>
                    <Autocomplete
                      placeholder="Select..."
                      leftAddon="Deal Source"
                      name="deal_source"
                      options={dealSourceOptions}
                      error={errors?.deal_source}
                      isLoading={loading}
                    />
                  </Can>
                </Can>
              </VStack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default UserAssignment;
