import React, { useState, MouseEvent } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import Modal from '../../../shared/Modal';

import { PayoffRequest } from '../../../../gql/payoffRequestGql';
import {
  useMediaInsertMutation,
  usePayoffRequestUpdateMutation,
} from '../../../../gql/generated/graphql';

import { DealMediaEdit } from '../../../../gql/mediaGql';
import { insertMediaByUrl } from '../../../../utils/media';
import UploadPayoffDocsRadioAndDropzone from './UploadPayoffDocsRadioAndDropzone';
import { getFullName } from '../../../../gql/customerGql';

interface Props {
  disabled?: boolean;
  payoffRequest?: PayoffRequest;
}

const UploadPayoffDocs: React.FC<Props> = ({ disabled, payoffRequest }) => {
  const client = useApolloClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [needsPayoffDocuments, setNeedsPayoffDocuments] = useState(true);
  const [payoffDocumentsUploaded, setPayoffDocumentsUploaded] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<DealMediaEdit[]>([]);
  const [loading, setLoading] = useState(false);

  const [payoffRequestUpdate] = usePayoffRequestUpdateMutation();
  const [mediaInsert] = useMediaInsertMutation();

  const onSubmit = async () => {
    setLoading(true);
    if (payoffRequest?.deal_id && payoffRequest?.id) {
      await insertMediaByUrl(selectedMedia, payoffRequest.deal_id, client, mediaInsert);

      const requestPayoffInput = {
        id: payoffRequest.id,
        deal_id: payoffRequest.deal_id,
        needs_payoff_documents: needsPayoffDocuments,
        payoff_documents_uploaded: payoffDocumentsUploaded,
      };

      try {
        await payoffRequestUpdate({
          variables: {
            payoffRequest: requestPayoffInput,
          },
        });

        onClose();
        toast.success('Payoff documents uploaded');
      } catch (error) {
        toast.error('Failed to upload payoff documents');
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        size="sm"
        width="75px"
        isDisabled={disabled}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        UPLOAD
      </Button>
      <Modal
        title={`Upload
            ${
              payoffRequest?.deal?.customer
                ? ` for ${getFullName(payoffRequest?.deal?.customer)}`
                : ` for ${payoffRequest?.temporary_info?.data?.first_name} ${payoffRequest?.temporary_info?.data?.last_name}`
            }  `}
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        rightButtons={
          <>
            <Button variant="warning" onClick={onClose}>
              CANCEL
            </Button>
            <Button
              isLoading={loading}
              loadingText={needsPayoffDocuments ? 'UPLOAD' : 'CONFIRM'}
              isDisabled={!payoffDocumentsUploaded && needsPayoffDocuments}
              onClick={onSubmit}
            >
              {needsPayoffDocuments ? 'UPLOAD' : 'CONFIRM'}
            </Button>
          </>
        }
      >
        <UploadPayoffDocsRadioAndDropzone
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
          needsPayoffDocuments={needsPayoffDocuments}
          setNeedsPayoffDocuments={setNeedsPayoffDocuments}
          setPayoffDocumentsUploaded={setPayoffDocumentsUploaded}
          dropzoneMarginTop={4}
        />
      </Modal>
    </>
  );
};

export default UploadPayoffDocs;
