import { FC } from 'react';

import { Flex, FlexProps, Spinner, Text } from '@chakra-ui/react';

interface Props extends FlexProps {
  autosaving?: boolean;
  saveTime?: string;
}

const Autosaving: FC<Props> = ({ autosaving, saveTime, ...rest }) => {
  return (
    <Flex flex={1} h={6} mr={2} justifyContent="end" alignItems="baseline" {...rest}>
      {autosaving ? <Spinner variant="thick" /> : <Text>Autosaved at: {saveTime || '-'}</Text>}
    </Flex>
  );
};

export default Autosaving;
