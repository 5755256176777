import React from 'react';
import { Input } from '@chakra-ui/react';
import { FiltersAction, FiltersActionKind } from '../../utils';
import { formatDateISO } from '../../../../libs/utils';

interface Props {
  placeholder: string;
  actionKind:
    | FiltersActionKind.SET_FROM_DATE
    | FiltersActionKind.SET_TO_DATE
    | FiltersActionKind.SET_CLOSED_FROM_DATE
    | FiltersActionKind.SET_CLOSED_TO_DATE
    | FiltersActionKind.SET_TITLE_FROM_DATE
    | FiltersActionKind.SET_TITLE_TO_DATE;
  dispatch: React.Dispatch<FiltersAction>;
  value?: Date;
}

const DateFilter: React.FC<Props> = ({ placeholder, dispatch, actionKind, value }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const payload = e.target.value ? new Date(e.target.value) : undefined;
    dispatch({ type: actionKind, payload });
  };

  return (
    <Input
      type="date"
      placeholder={placeholder}
      h="40px"
      minW="160px"
      maxW="160px"
      borderRadius={3}
      bgColor="azureishWhite"
      onChange={handleInputChange}
      value={value ? formatDateISO(value) : ''}
    />
  );
};

export default DateFilter;
