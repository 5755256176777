import { memo } from 'react';
import { Box, BoxProps, CloseButton, Flex } from '@chakra-ui/react';

import { MetricsPages } from '../../../gql/generated/graphql';
import { PermissionEnum } from '../../../constants/permissions';
import { Can } from '../../../libs/Can';

const ACTIVE_COLOR = '#343A40';

interface SidebarProps extends BoxProps {
  metricsPages: MetricsPages[];
  onClose: () => void;
  onPressItem: (item: MetricsPages) => void;
  itemSelected?: MetricsPages;
}

const LinkContent = memo(
  ({
    link,
    isItemActive,
    onPressItem,
    ...rest
  }: {
    link: MetricsPages;
    isItemActive: boolean;
    onPressItem: (link: MetricsPages) => void;
  }) => {
    return (
      <Flex
        onClick={() => onPressItem(link)}
        align="center"
        p="2"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        backgroundColor={isItemActive ? ACTIVE_COLOR : undefined}
        color={isItemActive ? 'white' : undefined}
        _hover={{
          bg: '#eaebeb',
          color: 'black',
        }}
        {...rest}
      >
        {link.name}
      </Flex>
    );
  },
);

const SidebarContent = ({
  metricsPages,
  onClose,
  onPressItem,
  itemSelected,
  ...rest
}: SidebarProps) => {
  return (
    <Box width="50" pos="absolute" {...rest}>
      <Flex h="10" alignItems="center" mx="8" justifyContent="space-between">
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {[...metricsPages]
        .sort((a: MetricsPages, b: MetricsPages) => {
          if (!a?.sort_order) {
            return -1;
          }
          if (!b?.sort_order) {
            return 1;
          }
          return a.sort_order > b.sort_order ? 1 : -1;
        })
        .map((link: MetricsPages) => {
          const isItemActive = link.id === itemSelected?.id;
          if (link.permission_name) {
            return (
              <Can I={link.permission_name as PermissionEnum}>
                <LinkContent link={link} isItemActive={isItemActive} onPressItem={onPressItem} />
              </Can>
            );
          }
          return <LinkContent link={link} isItemActive={isItemActive} onPressItem={onPressItem} />;
        })}
    </Box>
  );
};

export default SidebarContent;
