import { FC } from 'react';

import { Button, Text } from '@chakra-ui/react';

import Modal from '../../../shared/Modal';

interface Props {
  contractValidated: boolean | undefined;
  handleConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const ConfirmManualValidationModal: FC<Props> = ({
  contractValidated,
  handleConfirm,
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      title={contractValidated ? 'Confirm Manual Contract Generation' : 'Confirm Manual Validation'}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      size="3xl"
      showDivider={false}
      leftButtons={
        <Button variant="warning" onClick={onClose}>
          Cancel
        </Button>
      }
      rightButtons={
        <Button
          onClick={() => {
            handleConfirm();
            onClose();
          }}
        >
          CONFIRM
        </Button>
      }
    >
      <Text fontWeight="bold" mt={4} mb={0}>
        There is no record of a{' '}
        {contractValidated
          ? 'generated contract in the media center'
          : 'successfully validated worksheet'}
        .
      </Text>
      <Text mb={0}>
        By marking this deal as sent you are indicating that you are aware of this and have manually
        verified the presence of a{' '}
        {contractValidated ? 'generated contract' : 'validated worksheet'} in RouteOne. Do not
        confirm this action otherwise.
      </Text>
    </Modal>
  );
};

export default ConfirmManualValidationModal;
