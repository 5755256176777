import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from '@chakra-ui/react';
import styled from 'styled-components';
import { Deal } from '../../gql/dealGql';
import { mmrQuery } from '../../gql/mmr';
import { ColorsEnum } from '../../libs/colorLib';

export const StyledTooltip = styled(Tooltip)<{ placement: string }>`
  .tooltip-inner {
    background-color: ${ColorsEnum.TOOLTIP_RED};
    color: ${ColorsEnum.WHITE};
  }

  .arrow:before {
    ${({ placement }) => `border-${placement}-color: ${ColorsEnum.TOOLTIP_RED}`}
  }
`;

const MmrButton: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<Deal>();

  const { car, condition_report, customer } = values;

  const renderTooltip = (props: React.ComponentProps<typeof StyledTooltip>) => (
    <StyledTooltip {...props} transition={false}>
      Missing:
      {!car.vin ? <div>Vin</div> : ''}
      {!car.mileage ? <div>Mileage</div> : ''}
      {!car.color ? <div>color</div> : ''}
      {!condition_report?.score ? <div>Condition Report</div> : ''}
    </StyledTooltip>
  );

  const disabled = !car.vin || !car.mileage || !car.color || !condition_report?.score;

  const [getMmr, { loading }] = useLazyQuery(mmrQuery, {
    onCompleted: (data) => {
      if (data?.mmr.average) {
        setFieldValue('car.mmr', data.mmr.average);
      } else {
        setFieldValue('car.mmr', (car?.book_value ?? 0) * 0.9);
      }
    },
  });

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={disabled ? renderTooltip : <span />}
    >
      <span>
        <Button
          isLoading={loading}
          loadingText="CALCULATE MMR"
          isDisabled={disabled}
          onClick={() => {
            getMmr({
              variables: {
                vin: car.vin,
                mileage: car.mileage,
                color: car.color,
                grade: condition_report?.score,
                trim: car.kbb_trim_name,
                state: customer.address?.state,
              },
            });
          }}
        >
          CALCULATE MMR
        </Button>
      </span>
    </OverlayTrigger>
  );
};

export default MmrButton;
