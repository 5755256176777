import { ChangeEvent, FocusEvent, FocusEventHandler, useEffect } from 'react';

import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightElement,
  Spinner,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Option } from './types';

type SelectProps = {
  label?: string;
  name: string;
  options: Option[];
  showErrorMessage?: boolean;
  emptyOption?: boolean;
  formControlVariant?: 'inline' | 'reduced' | undefined;
  formControlProps?: FormControlProps;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  isLoading?: boolean;
} & ChakraSelectProps;

const Select = ({
  label,
  name,
  options,
  showErrorMessage = true,
  emptyOption = true,
  formControlVariant,
  formControlProps,
  defaultValue,
  onChange,
  onBlur,
  isLoading,
  ...rest
}: SelectProps) => {
  const { handleBlur, handleChange, getFieldMeta, setFieldTouched, isSubmitting, setFieldValue } =
    useFormikContext();
  const { value, initialValue, error, touched } = getFieldMeta(name);
  const styles = useMultiStyleConfig('FormControl', { variant: formControlVariant });

  // Workaround for dynamic form fields.
  if (isSubmitting && !touched) {
    setFieldTouched(name, true, false);
  }

  useEffect(() => {
    if (defaultValue) {
      setFieldValue(name, defaultValue);
    }
  }, []);

  useEffect(() => {
    if (value === initialValue && !touched) {
      return;
    }

    // Select doesn't trigger onBlur when selecting an option.
    handleBlur({ target: { name }, currentTarget: { name } } as FocusEvent<HTMLSelectElement>);
  }, [value]);

  return (
    <FormControl isInvalid={touched && !!error} sx={styles.control} {...formControlProps}>
      {label && (
        <FormLabel whiteSpace="nowrap" sx={styles.label}>
          {label}
        </FormLabel>
      )}
      <InputGroup sx={styles.input}>
        <ChakraSelect
          name={name}
          onBlur={onBlur ?? handleBlur}
          onChange={onChange ?? handleChange}
          value={(value as string) ?? ''}
          icon={isLoading ? <></> : undefined}
          _hover={{
            // fix input style props not being overwritten with customized theme
            // https://github.com/chakra-ui/chakra-ui/issues/2347
            borderColor: 'currentColor',
          }}
          {...rest}
        >
          {emptyOption && <option value="">Select an Option</option>}
          {options.map(({ value: optionValue, label: optionLabel }) => (
            <option key={optionValue} value={optionValue}>
              {optionLabel}
            </option>
          ))}
        </ChakraSelect>
        {isLoading ? (
          <InputRightElement alignItems="center">
            <Spinner size="sm" />
          </InputRightElement>
        ) : null}
      </InputGroup>
      {showErrorMessage ? <FormErrorMessage sx={styles.error}>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default Select;
