import React, { useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';
import { Customer } from '../../gql/customerGql';
import MaskedSsnButton from './MaskedSsnButton';

interface SsnMaskProps {
  type: 'form' | 'payoff' | 'customerInfo';
}

export const SsnMask: React.FC<SsnMaskProps> = ({ type }) => {
  return (
    <Box mb={type === 'customerInfo' ? 1 : undefined}>
      <MdCircle size={8} />
      <MdCircle size={8} />
      <MdCircle size={8} style={{ marginRight: '5px' }} />
      <MdCircle size={8} />
      <MdCircle size={8} style={{ marginRight: '5px' }} />
      <MdCircle size={8} />
      <MdCircle size={8} />
      <MdCircle size={8} />
      <MdCircle size={8} style={{ marginRight: type === 'payoff' ? '32px' : '14px' }} />
    </Box>
  );
};

interface MaskedSSNProps {
  customer: Customer;
}

const MaskedSsnCustomerInfo: React.FC<MaskedSSNProps> = ({ customer }) => {
  const [ssnHidden, setSsnHidden] = useState(true);
  const ssn = customer?.ssn;
  return ssn ? (
    <HStack maxWidth="100%" spacing="auto">
      {ssnHidden ? <SsnMask type="customerInfo" /> : <Text mr="17px">{ssn}</Text>}
      <MaskedSsnButton type="customerInfo" customerId={customer.id} onClick={setSsnHidden} />
    </HStack>
  ) : null;
};

export default MaskedSsnCustomerInfo;
