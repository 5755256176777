import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import {
  Deal,
  DealStateEnum,
  dealInfoUpsert,
  isDealInOrPastFunded,
  isUneditable,
} from '../../../../gql/dealGql';

import { PermissionEnum } from '../../../../constants/permissions';
import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';
import { AbilityContext } from '../../../../libs/contextLib';

const hiddenStates = [
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Funded,
];

interface SaveButtonProps {
  customOnClick?: () => void;
}

const SaveButton: React.FC<SaveButtonProps> = ({ customOnClick }) => {
  const { deal, dispatch, isRecalculatingPayoff } = useContext(DealContext);
  const { values } = useFormikContext<Deal>();
  const abilities = useContext(AbilityContext);

  const canEditFundedOnwards =
    abilities.has(PermissionEnum.EditFundedOnwards) && isDealInOrPastFunded(deal.state);

  const [isSaving, setSaving] = useState<boolean>(false);
  const [upsertDealInfo] = useMutation(dealInfoUpsert);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSave = (newValues: Deal, setSaving: (isSaving: boolean) => void) => {
    upsertDealInfo({
      variables: {
        financialInfo: newValues.financial_info,
        car: newValues.car,
        customer: newValues.customer,
      },
    })
      .then(({ data }) => {
        dispatch({ type: DealActionsEnum.UpdateDeal, payload: data.dealInfoUpsert });
        toast.success('Deal saved');
      })
      .catch((e: Error) => logger.error('SaveButton.tsx', '', null, e))
      .finally(() => setSaving(false));

    if (customOnClick) {
      customOnClick();
    }
  };

  return (
    <Button
      variant="secondary"
      isLoading={isSaving}
      isDisabled={isRecalculatingPayoff}
      loadingText="SAVE"
      onClick={() => {
        setSaving(true);
        onSave(values, setSaving);
      }}
      hidden={
        (hiddenStates.includes(deal.state) || isUneditable(deal.state)) && !canEditFundedOnwards
      }
    >
      SAVE
    </Button>
  );
};

export default SaveButton;
