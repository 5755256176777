import { Dispatch } from 'react';

import { CustomerContactTypeEnum } from '../gql/customerGql';
import { Deal } from '../gql/dealGql';
import { Deal as GenDeal, Message as GenMessage } from '../gql/generated/graphql';
import { Message } from '../gql/messageGql';

import { ActiveConversation } from '../components/TextWidget/Components/Message';

import { formatPhoneWithParentheses } from '../libs/utils';

export const getActiveConversation = (
  deal: Deal | GenDeal,
  message: Message | GenMessage,
  setActiveConversation: Dispatch<ActiveConversation>,
) => {
  let { from } = message;
  if (from && from.charAt(0) === '+') {
    from = formatPhoneWithParentheses(from);
  }
  switch (from) {
    case deal.customer?.phone_number:
      setActiveConversation({
        role: CustomerContactTypeEnum.Customer,
        phone_number: deal.customer?.phone_number ?? '',
        first_name: deal.customer?.first_name ?? '',
        last_name: deal.customer?.last_name ?? '',
      });
      break;
    case deal.cobuyer?.phone_number:
      setActiveConversation({
        role: CustomerContactTypeEnum.Cobuyer,
        phone_number: deal.cobuyer?.phone_number ?? '',
        first_name: deal.cobuyer?.first_name ?? '',
        last_name: deal.cobuyer?.last_name ?? '',
      });
      break;
    case deal.contact?.phone_number:
      setActiveConversation({
        role: CustomerContactTypeEnum.Contact,
        phone_number: deal.contact?.phone_number ?? '',
        first_name: deal.contact?.first_name ?? '',
        last_name: deal.contact?.last_name ?? '',
      });
      break;
    case deal.second_contact?.phone_number:
      setActiveConversation({
        role: CustomerContactTypeEnum.Second,
        phone_number: deal.second_contact?.phone_number ?? '',
        first_name: deal.second_contact?.first_name ?? '',
        last_name: deal.second_contact?.last_name ?? '',
      });
      break;
    default:
      setActiveConversation({
        role: '',
        phone_number: '',
        first_name: '',
        last_name: '',
      });
      break;
  }
};
