import { gql } from '@apollo/client/core';

import { DealStateEnum } from './dealGql';

export type Dates = {
  [key in DealStateEnum]?: string;
};

export type CustomDates = {
  [key in DealStateEnum]?: string;
};

export class DealDates {
  id: number;
  deal_id: number;
  dates: Dates | null;
  custom_dates: CustomDates | null;

  constructor() {
    this.id = 0;
    this.deal_id = 0;
    this.dates = {};
    this.custom_dates = {};
  }
}

export const customDatesUpdate = gql`
  mutation customDatesUpdate($deal_id: ID!, $date_key: String!, $date: DateTime) {
    customDatesUpdate(deal_id: $deal_id, date_key: $date_key, date: $date) {
      id
      custom_dates
    }
  }
`;
