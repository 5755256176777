import { useContext } from 'react';

import { Button } from '@chakra-ui/react';

import { DealStateEnum } from '../../../../gql/dealGql';

import useGenerateBookSheets from '../../../../hooks/useGenerateBookSheets';
import { DealContext } from '../../../../libs/DealContext';

const CreateBookSheetButton = () => {
  const { deal, isRecalculatingPayoff } = useContext(DealContext);

  const { generateBookSheets, isLoading } = useGenerateBookSheets();

  return deal.state === DealStateEnum.Signed ? (
    <Button
      variant="secondary"
      isLoading={isLoading}
      isDisabled={isRecalculatingPayoff}
      loadingText="CREATE BOOK SHEETS"
      onClick={() =>
        generateBookSheets({ dealId: deal.id, customerState: deal.customer.address.state })
      }
    >
      CREATE BOOK SHEETS
    </Button>
  ) : null;
};

export default CreateBookSheetButton;
