import { PropsWithChildren, useContext } from 'react';

import { Container, ContainerProps } from 'react-bootstrap';

import Loader from '../Loader';

import { FiltersContext } from '../../libs/contextLib';
import { CurrentView } from '../../pages/Dashboard';

type LEContainerProps = PropsWithChildren<
  ContainerProps & {
    isLoading?: boolean;
  }
>;

const LeaseEndContainer = ({ isLoading, children, ...rest }: LEContainerProps) => {
  const {
    filters: {
      global: { currentView },
    },
  } = useContext(FiltersContext);

  return (
    <>
      <Container
        style={{
          ...(currentView === CurrentView.DashboardView && { backgroundColor: '#F7F7F7' }),
          padding: '1rem 3rem',
        }}
        {...rest}
      >
        {children}
      </Container>
      <Loader isLoading={isLoading ?? false} />
    </>
  );
};
export default LeaseEndContainer;
