import { FC, useEffect, useState } from 'react';

import {
  Button,
  Flex,
  HStack,
  Icon,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  useDisclosure,
} from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { MdGroups } from 'react-icons/md';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';

import { PodsQuery, TeamType, usePodsQuery } from '../../gql/generated/graphql';
import { Pod } from '../../gql/podGql';

import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';

import PodModal from './PodModal/PodModal';
import TeamRow from './TeamRow';

import usePagination from '../../hooks/usePagination';

type PodsType = NonNullable<PodsQuery['pods']>;

type PodType = NonNullable<PodsType[number]>;

const sortByName = (a?: PodType | null, b?: PodType | null) => {
  const aName = a?.name ?? '';
  const bName = b?.name ?? '';

  return aName.localeCompare(bName);
};

const TeamsTable: FC = () => {
  const paginationContext = usePagination();

  const [columnToSort, setColumnToSort] = useState<string>('team name');
  const [sortByAscending, setSortByAscending] = useState<boolean>(true);

  const [includeArchived, setIncludeArchived] = useState<boolean>(false);

  const [sortedPods, setSortedPods] = useState<Pod[] | null>([]);

  const [selectedPod, setSelectedPod] = useState<Pod | null>(null);
  const {
    isOpen: isOpenPodModal,
    onClose: onClosePodModal,
    onOpen: onOpenPodModal,
  } = useDisclosure();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);

  const {
    data: podsData,
    refetch: refetchPods,
    loading,
  } = usePodsQuery({
    variables: { includeDetails: true, hideArchived: !includeArchived },
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast.error(`Couldn't load teams. Please refresh. Error: ${error.message}`);
    },
  });

  useEffect(() => {
    // sort pods by name
    if (!podsData?.pods) {
      return;
    }
    const pods = [...podsData?.pods];

    pods.sort((a, b) => {
      if (columnToSort === 'date added') {
        const aDate = new Date(a?.created_at ?? '');
        const bDate = new Date(b?.created_at ?? '');
        if (a?.created_at === b?.created_at) {
          return sortByName(a, b);
        }
        return sortByAscending
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      }

      if (columnToSort === 'team type') {
        const aTeamType = a?.team_type ?? '';
        const bTeamType = b?.team_type ?? '';
        if (aTeamType === bTeamType) {
          return sortByName(a, b);
        }
        return sortByAscending
          ? aTeamType.localeCompare(bTeamType)
          : bTeamType.localeCompare(aTeamType);
      }

      if (columnToSort === 'region') {
        const aRegion = a?.parent_pod?.name ?? '';
        const bRegion = b?.parent_pod?.name ?? '';
        if (!aRegion) {
          return 1;
        }
        if (!bRegion) {
          return -1;
        }
        if (aRegion === bRegion) {
          return sortByName(a, b);
        }
        return sortByAscending ? aRegion.localeCompare(bRegion) : bRegion.localeCompare(aRegion);
      }

      if (columnToSort === 'users') {
        const aLen = a?.pod_users?.length ?? 0;
        const bLen = b?.pod_users?.length ?? 0;
        if (aLen === bLen) {
          return sortByName(a, b);
        }
        return sortByAscending ? aLen - bLen : bLen - aLen;
      }

      const aName = a?.name ?? '';
      const bName = b?.name ?? '';

      // Send archived pods to the bottom
      if (aName.includes('(ARCHIVED)')) {
        return 1;
      }
      if (bName.includes('(ARCHIVED)')) {
        return -1;
      }

      // Default sort by name
      return sortByAscending ? aName.localeCompare(bName) : bName.localeCompare(aName);
    });

    setSortedPods(pods as Pod[]);
    if (pods) {
      paginationContext.setTotalRecords(pods?.length);
    }
  }, [podsData, columnToSort, sortByAscending]);

  useEffect(() => {
    if (isOpenPodModal) {
      return;
    }

    refetchPods();
  }, [isOpenPodModal]);

  const HeaderColumn: FC<{
    columnName: string;
    width: string;
    canSort?: boolean;
  }> = ({ columnName, width, canSort = false }) => (
    <Th
      pl={columnName === 'name' ? 1 : undefined}
      w={width}
      color="white"
      cursor={canSort ? 'pointer' : 'default'}
      onClick={() => {
        if (canSort) {
          if (columnToSort === columnName) {
            setSortByAscending(!sortByAscending);
          } else {
            setColumnToSort(columnName);
            setSortByAscending(true);
          }
        }
      }}
    >
      {columnName.replace('_', ' ').toUpperCase()}
      {columnToSort === columnName && canSort && (
        <Icon as={sortByAscending ? BiChevronDown : BiChevronUp} marginLeft={1} />
      )}
    </Th>
  );

  return (
    <>
      <Flex
        h={16}
        w="100%"
        bgColor="oxfordBlue"
        borderRadius="10px 10px 0 0"
        alignItems="center"
        justifyContent="flex-end"
        px={8}
      >
        <HStack spacing={2} mr={8}>
          <Text color="white">Include Archived Teams</Text>
          <Toggle
            name="includeArchived"
            checked={includeArchived}
            onChange={(e) => setIncludeArchived(e.target.checked)}
            icons={false}
          />
        </HStack>
        <Button
          variant="secondary"
          leftIcon={<MdGroups size="20px" />}
          onClick={() => onOpenPodModal()}
        >
          Add Team
        </Button>
      </Flex>
      <Table>
        <Thead cursor="pointer" color="white" bgColor="queenBlue" w="100%" px={6} height="55px">
          <HeaderColumn width="5%" columnName="" />
          <HeaderColumn width="19%" columnName="team_name" canSort />
          <HeaderColumn width="15%" columnName="date added" canSort />
          <HeaderColumn width="10%" columnName="team type" canSort />
          <HeaderColumn width="15%" columnName="region" canSort />
          <HeaderColumn width="15%" columnName="manager/lead" />
          <HeaderColumn width="15%" columnName="users" canSort />
          <HeaderColumn width="5%" columnName="actions" />
        </Thead>
        <Tbody>
          {sortedPods?.map((pod) => {
            if (!pod) {
              return null;
            }

            return (
              <TeamRow
                pod={pod}
                refetch={refetchPods}
                setSelectedPod={setSelectedPod}
                onOpenPodModal={onOpenPodModal}
                key={pod.id}
              />
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        filters={{
          currentPage,
          itemsPerPage,
        }}
        parentSetCurrentPage={setCurrentPage}
        parentSetItemsPerPage={setItemsPerPage}
        numItemsShown={sortedPods?.length || 0}
        paginationContext={paginationContext}
        itemsPerPageOptions={[50, 100]}
        borderTop="1px solid"
        borderColor="lightGray"
      />
      <PodModal
        isOpen={isOpenPodModal}
        onClose={onClosePodModal}
        selectedPod={selectedPod}
        setSelectedPod={setSelectedPod}
        regionPods={sortedPods?.filter((pod) => pod.team_type === TeamType.Region) || []}
      />
      <Loader isLoading={loading} />
    </>
  );
};

export default TeamsTable;
