import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

interface IPDFViewerProps {
  fileUrl?: string;
}

const PDFViewer: React.FC<IPDFViewerProps> = ({ fileUrl }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [pagesArray, setPagesArray] = useState([1]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    const newPagesArray = [];
    for (let i = 0; i < numPages; i += 1) {
      newPagesArray.push(i + 1);
    }
    setPagesArray(newPagesArray);
  };

  const container = document.getElementById('pdf-container');

  return (
    <div id="pdf-container" className="text-center" style={{ margin: 'auto' }}>
      <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {pagesArray.map((page) => (
          <Page key={page} pageNumber={page} width={container?.offsetWidth} />
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
