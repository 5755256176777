import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Col, FormGroup, Modal, ListGroup, Spinner } from 'react-bootstrap';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import InputMask from 'react-input-mask';
import { useFormikContext } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { formatMoney } from '../../libs/utils';
import { determineApprovalQuery } from '../../gql/conditionReportGql';
import { Deal } from '../../gql/dealGql';
import { DealContext } from '../../libs/DealContext';
import BootActions from '../Task/TaskActions/BootAction';
import { ColorsEnum } from '../../libs/colorLib';
import { AutoExplodeZip } from '../AutoExplodeZip';
import PaperworkOptionsButtons from '../PaperworkOptionsButtons';
import { NumberInput } from './FormComponents';
import RequestApprovalButton from './RequestApprovalButton';
import FinalizeDealButton from './FinalizeDealButton';
import SELECT_OPTIONS from '../../constants/selectOptions';
import Select from '../shared/Select';
import DatePicker from '../shared/DatePicker';

const StyledRow = styled(Form.Row)`
  justify-content: center;
  align-items: center;
`;

const ClosingView: React.FC = () => {
  const { deal } = useContext(DealContext);
  const { values, setFieldValue, touched, errors, handleChange, handleBlur } =
    useFormikContext<Deal>();
  const { car, condition_report, financial_info_acquisition, customer } = values;
  const [showXModal, setShowXModal] = useState<boolean>(false);
  const [zipLoading, setZipLoading] = useState<boolean>(false);
  const [failedCriteria, setFailedCriteria] = useState<string[]>();
  const [showPaperworkOptions, setShowPaperworkOptions] = useState<boolean>(false);
  const [isAddressLineFocused, setAddressLineFocused] = useState<boolean>(false);
  const [approvedAmount, setApprovedAmount] = useState<number | undefined>(undefined);

  const [determineApproval] = useLazyQuery(determineApprovalQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ determineApprovalQuery: { approved, failedCriteria: failReason } }) => {
      setFieldValue('financial_info_acquisition.is_approved', approved);
      setFailedCriteria(failReason);
    },
  });

  useEffect(() => {
    determineApproval({
      variables: {
        data: {
          accidents: condition_report?.accidents,
          tires: condition_report?.tires,
          exterior: condition_report?.exterior,
          interior: condition_report?.interior,
          smoked_in: condition_report?.smoked_in,
          lights_on_dash: condition_report?.lights_on_dash,
          overall_condition: condition_report?.overall_condition,
          year: car.year,
          payoff: car.payoff.vehicle_payoff,
          book_value: car.book_value,
          kbb_trim: car.kbb_trim_name,
          vin: car.vin,
          mileage: car.mileage,
          color: car.color,
          vehicle_type: car.vehicle_type,
          state: customer.address?.state,
          financial_info_acquisition_id: financial_info_acquisition.id,
        },
      },
    });
  }, [condition_report, car]);

  useEffect(() => {
    if (approvedAmount && financial_info_acquisition.cash_to_customer === approvedAmount) {
      setFieldValue('financial_info_acquisition.is_approved', true);
    } else if (deal.financial_info_acquisition.is_approved) {
      if (
        financial_info_acquisition.cash_to_customer &&
        financial_info_acquisition.cash_to_customer >
          (financial_info_acquisition.max_cash_to_customer ?? 0)
      ) {
        setFieldValue('financial_info_acquisition.is_approved', false);
      } else {
        setFieldValue('financial_info_acquisition.is_approved', true);
      }
    }
  }, [financial_info_acquisition.cash_to_customer]);

  useEffect(() => {
    if (
      deal.financial_info_acquisition.overriding_user_id &&
      deal.financial_info_acquisition.is_approved
    ) {
      setApprovedAmount(deal.financial_info_acquisition.cash_to_customer);
    }
  }, []);

  return (
    <>
      <StyledRow>
        {financial_info_acquisition.is_approved ? (
          <BiCheckCircle size="200" className="ml-2 mb-4" color={ColorsEnum.GREEN} />
        ) : (
          <>
            <BiXCircle
              size="200"
              className="ml-2 mb-4"
              color={ColorsEnum.RED}
              onClick={() => setShowXModal(true)}
            />
            <Modal
              show={showXModal}
              backdrop="static"
              onHide={() => setShowXModal(false)}
              keyboard={false}
              dialogClassName="not-approved-modal"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-center w-100">
                  Reasons for not being approved...
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ListGroup>
                  {failedCriteria?.map((fc) => (
                    <ListGroup.Item key={fc} variant="warning">
                      {fc}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Modal.Body>
            </Modal>
          </>
        )}
      </StyledRow>
      <StyledRow>
        <Col md="4">
          <h5>Cash To Customer</h5>
        </Col>
        <FormGroup as={Col} md="4" className="m-0 mb-2 align-items-center">
          <NumberInput isMoney path="financial_info_acquisition.cash_to_customer" />
        </FormGroup>
      </StyledRow>
      <StyledRow>
        <Col md="4">
          <h5>Max Cash To Customer</h5>
        </Col>
        <Col md="4">
          <h5>{formatMoney(financial_info_acquisition.max_cash_to_customer)}</h5>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col md="4">
          <h5>Max Total Cost</h5>
        </Col>
        <Col md="4">
          <h5>{formatMoney(financial_info_acquisition.max_total_cost)}</h5>
        </Col>
      </StyledRow>
      <h6 className="mt-5">Personal Info</h6>
      <Form.Row>
        <Form.Group as={Col} md="6">
          <Form.Control
            type="text"
            name="customer.address.address_line"
            placeholder="Address Line 1"
            value={values.customer.address?.address_line}
            onChange={handleChange}
            onFocus={() => setAddressLineFocused(true)}
            onBlur={(e: React.SyntheticEvent) => {
              handleBlur(e);
              setAddressLineFocused(false);
            }}
            isInvalid={
              touched.customer?.address?.address_line && !!errors.customer?.address?.address_line
            }
          />
          <Form.Control.Feedback type="invalid">
            {errors.customer?.address?.address_line}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Control
            type="text"
            name="customer.address.address_line_2"
            placeholder="Address Line 2"
            value={values.customer.address?.address_line_2}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={
              touched.customer?.address?.address_line_2 &&
              !!errors.customer?.address?.address_line_2
            }
          />
          <Form.Control.Feedback type="invalid">
            {errors.customer?.address?.address_line_2}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="3">
          <Form.Control
            type="text"
            placeholder="Zip Code"
            as={InputMask}
            mask="99999"
            name="customer.address.zip"
            value={values.customer.address?.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.customer?.address?.zip && !!errors.customer?.address?.zip}
          />
          <Form.Control.Feedback type="invalid">
            {errors.customer?.address?.zip}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Control
            type="text"
            placeholder="City"
            name="customer.address.city"
            value={values.customer.address?.city}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.customer?.address?.city && !!errors.customer?.address?.city}
          />
          <Form.Control.Feedback type="invalid">
            {errors.customer?.address?.city}
          </Form.Control.Feedback>
          <Spinner
            animation="border"
            className="spinner"
            variant="primary"
            size="sm"
            hidden={!zipLoading}
          />
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Select
            emptyOption={false}
            placeholder="State"
            id="customer.address.state"
            name="customer.address.state"
            options={SELECT_OPTIONS.US_STATES}
            isRequired
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.customer?.address?.state && !!errors.customer?.address?.state}
          />
          <Spinner
            animation="border"
            className="spinner"
            variant="primary"
            size="sm"
            hidden={!zipLoading}
          />
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Control
            type="text"
            placeholder="County"
            name="customer.address.county"
            value={values.customer.address?.county}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.customer?.address?.county && !!errors.customer?.address?.county}
          />
          <Form.Control.Feedback type="invalid">
            {errors.customer?.address?.county}
          </Form.Control.Feedback>
          <Spinner
            animation="border"
            className="spinner"
            variant="primary"
            size="sm"
            hidden={!zipLoading}
          />
        </Form.Group>
        <AutoExplodeZip
          name="customer"
          setZipLoading={setZipLoading}
          isAddressLineFocused={isAddressLineFocused}
        />
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="6">
          <DatePicker
            name="customer.dob"
            leftLabel="Date of Birth"
            boxStyles={{ w: '100%' }}
            valueFormat="dateUTC"
          />
        </Form.Group>
      </Form.Row>
      <StyledRow>
        {financial_info_acquisition.is_approved ? (
          <>
            <FinalizeDealButton
              showPaperworkOptions={showPaperworkOptions}
              setShowPaperworkOptions={setShowPaperworkOptions}
            />
            <PaperworkOptionsButtons showPaperworkOptions={showPaperworkOptions} />
          </>
        ) : deal.financial_info_acquisition.is_approved ? (
          <RequestApprovalButton />
        ) : (
          <BootActions deal={deal} allowAllToBoot />
        )}
      </StyledRow>
    </>
  );
};

export default ClosingView;
