import { ReactNode } from 'react';

import { Badge, Flex, Td } from '@chakra-ui/react';

import { getFullName } from '../../gql/customerGql';
import { DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import { Deal, StructuringFollowUpSortColumn } from '../../gql/generated/graphql';

import TextNotificationBadgeV2 from '../NotificationBadge/TextNotificationBadgeV2';
import ClaimActions from '../Task/TaskActions/ClaimActions';

import DeleteStructuringFollowUp from './DeleteStructuringFollowUp';
import EditStructuringFollowUp from './EditStructuringFollowUp';
import { StructuringFollowUpDeals } from './StructuringFollowUpTable';

import { PermissionEnum } from '../../constants/permissions';
import {
  canManuallyClaimStructuringManager,
  isStructuringManagerClaimed,
} from '../../utils/permissions';

const TABLE_HEADERS = {
  NOTIFICATIONS: 'Notifications',
  DATE: 'Date',
  CUSTOMER: 'Customer',
  DEAL_STATE: 'Deal State',
  TAGS: 'Tags',
  NOTES: 'Notes',
  ADVISOR: 'Advisor',
  STRUCTURING_MANAGER: 'Structuring Manager',
  ACTIONS: 'Actions',
} as const;

export type TableHeaders = typeof TABLE_HEADERS[keyof typeof TABLE_HEADERS];

type GetComponentArgs = {
  deal: NonNullable<StructuringFollowUpDeals[number]>;
  userId: string;
  permissions: Set<PermissionEnum>;
  unseenMessages?: number;
};

type ColumnProps = {
  getComponent: (params: GetComponentArgs) => ReactNode;
  sortColumn?: StructuringFollowUpSortColumn;
  hideHeader?: boolean;
};

const TABLE_COLUMNS: { [K in TableHeaders]: ColumnProps } = {
  [TABLE_HEADERS.NOTIFICATIONS]: {
    getComponent: ({ deal, unseenMessages }) => (
      <Td px="12px" w="1%">
        <TextNotificationBadgeV2 count={unseenMessages} deal={deal as Deal} size={25} />
      </Td>
    ),
    hideHeader: true,
  },
  [TABLE_HEADERS.DATE]: {
    getComponent: ({ deal }) => (
      <Td w="12%">
        {new Date(deal.structuring_follow_up?.created_at).toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        })}
      </Td>
    ),
    sortColumn: StructuringFollowUpSortColumn.Date,
  },
  [TABLE_HEADERS.CUSTOMER]: {
    getComponent: ({ deal }) => <Td w="15%">{getFullName(deal.customer)}</Td>,
    sortColumn: StructuringFollowUpSortColumn.Customer,
  },
  [TABLE_HEADERS.DEAL_STATE]: {
    getComponent: ({ deal }) => <Td w="10%">{DealStateLabelMap[deal.state as DealStateEnum]}</Td>,
    sortColumn: StructuringFollowUpSortColumn.DealState,
  },
  [TABLE_HEADERS.TAGS]: {
    getComponent: ({ deal }) => (
      <Td w="15%">
        <Flex gap={1} alignItems="center" h="full" flexWrap="wrap">
          {(deal.tags || [])
            .filter((tag) => tag.is_dashboard_visible)
            .map((tag) => (
              <Badge
                key={tag.id}
                rounded="md"
                textTransform="uppercase"
                bg={tag.color}
                color="white"
                fontSize={10}
              >
                {tag.display_name}
              </Badge>
            ))}
        </Flex>
      </Td>
    ),
  },
  [TABLE_HEADERS.NOTES]: {
    getComponent: ({ deal }) => <Td w="20%">{deal.structuring_follow_up?.notes}</Td>,
  },
  [TABLE_HEADERS.ADVISOR]: {
    getComponent: ({ deal }) => <Td w="15%">{deal.setter?.name}</Td>,
    sortColumn: StructuringFollowUpSortColumn.Advisor,
  },
  [TABLE_HEADERS.STRUCTURING_MANAGER]: {
    getComponent: ({ deal }) => <Td w="15%">{deal.structuring_manager?.name}</Td>,
    sortColumn: StructuringFollowUpSortColumn.StructuringManager,
  },
  [TABLE_HEADERS.ACTIONS]: {
    getComponent: ({ deal, userId, permissions }) => (
      <Td w="5%">
        <Flex justifyContent="end">
          {!isStructuringManagerClaimed(deal) &&
          canManuallyClaimStructuringManager(deal, permissions) ? (
            <ClaimActions setRole="structuring_manager_id" deal={deal} assumedId={userId} />
          ) : null}

          <EditStructuringFollowUp
            structuringFollowUp={deal.structuring_follow_up}
            showIconButton
          />
          <DeleteStructuringFollowUp
            structuringFollowUp={deal.structuring_follow_up}
            showIconButton
          />
        </Flex>
      </Td>
    ),
  },
};

// Workaround to avoid error "TypeError: (0 , it(...)[1].getComponent) is not a function"
// For some reason, "Object.entries(TABLE_COLUMNS)" adds an extra key-value pair "[displayName = 'TABLE_COLUMNS']"
export const TABLE_COLUMNS_ENTRIES = Object.values(TABLE_HEADERS).map(
  (header) => [header, TABLE_COLUMNS[header]] as const,
);
