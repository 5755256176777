import { FC } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Button, useDisclosure } from '@chakra-ui/react';
import { FaUndo } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import { PermissionEnum } from '../../constants/permissions';
import { Can } from '../../libs/Can';
import { formatDate } from '../../libs/utils';

interface UnwindDealToSetProps {
  deal: Deal;
  refetch?: () => Promise<ApolloQueryResult<Deal>>;
}

type UnwindToSetDealStateType =
  | DealStateEnum.SoftClose
  | DealStateEnum.Structuring
  | DealStateEnum.Closing;

export const stateIsUnwindableToSet = (
  state: DealStateEnum,
  set_date?: string,
): state is UnwindToSetDealStateType =>
  !!set_date &&
  (state === DealStateEnum.SoftClose ||
    state === DealStateEnum.Structuring ||
    state === DealStateEnum.Closing);

const UnwindDealToSet: FC<UnwindDealToSetProps> = ({ deal, refetch }) => {
  const {
    isOpen: isUnwindModalOpen,
    onOpen: onOpenUnwindModal,
    onClose: onCloseUnwindModal,
  } = useDisclosure();

  const [updateDealState, { loading }] = useDealUpdateStateMutation();

  const onClickUnwindButton = async () => {
    if (!deal.id || !stateIsUnwindableToSet(deal.state, deal.set_date)) {
      return;
    }

    try {
      const previousState = DealStateEnum.SoftClose;
      await updateDealState({
        variables: {
          id: deal.id,
          state: previousState,
          clearSetDate: true,
        },
      });

      toast.success(`Deal moved to ${DealStateLabelMap[previousState]}.`);
      refetch?.();
    } catch (e) {
      toast.error('Failed to unwind deal.');
    } finally {
      onCloseUnwindModal();
    }
  };

  return (
    <Can I={PermissionEnum.UnwindToSetDeal}>
      <Button
        size="lgWithIconLeft"
        variant="boot"
        leftIcon={<FaUndo />}
        onClick={onOpenUnwindModal}
        hidden={!stateIsUnwindableToSet(deal.state, deal.set_date)}
      >
        UNWIND TO SET
      </Button>

      <Modal
        title="Unwind to Set"
        isOpen={isUnwindModalOpen}
        onClose={onCloseUnwindModal}
        size="lg"
        headerSize="md"
        canDismiss={false}
        showDivider={false}
        leftButtons={
          <Button
            size="md"
            variant="warning"
            onClick={() => {
              onCloseUnwindModal();
            }}
            isDisabled={loading}
          >
            Cancel
          </Button>
        }
        rightButtons={
          <Button
            size="md"
            variant="boot"
            onClick={() => {
              onClickUnwindButton();
            }}
            isLoading={loading}
            loadingText="Unwind"
          >
            Unwind
          </Button>
        }
      >
        Are you sure you want to unwind and clear the set date?
        <br />
        <b>Set Date:</b> {formatDate(deal.set_date)}
      </Modal>
    </Can>
  );
};

export default UnwindDealToSet;
