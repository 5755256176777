import React, { useMemo } from 'react';

import { HStack, StackProps } from '@chakra-ui/react';

import { Deal } from '../../../../gql/dealGql';
import { getDealNotificationCountByType } from '../../../../gql/notificationsGql';

import NotificationBadge from './NotificationBadge';

import { ColorsEnum } from '../../../../libs/colorLib';

interface Props extends StackProps {
  deal: Deal;
}

const Notifications: React.FC<Props> = ({ deal, ...rest }) => {
  const dealNotificationCountByType = useMemo(() => getDealNotificationCountByType(deal), [deal]);

  return (
    <HStack {...rest}>
      <NotificationBadge
        notificationCount={dealNotificationCountByType.messages}
        bgColor={ColorsEnum.GREEN}
      />
      <NotificationBadge
        notificationCount={dealNotificationCountByType.notMessages}
        bgColor={ColorsEnum.RED}
      />
    </HStack>
  );
};

export default Notifications;
