import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { dealDetailNetsuite, DealStateEnum } from '../../gql/dealGql';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

const SendToNetsuiteButton = () => {
  const [detailsDealNS] = useMutation(dealDetailNetsuite);
  const { deal } = useContext(DealContext);

  return (
    <>
      <Button
        variant="secondary"
        hidden={deal.state !== DealStateEnum.Funded}
        onClick={() => {
          detailsDealNS({
            variables: {
              id: deal.id,
            },
          })
            .then(() => {
              toast.success('Sent to Netsuite!');
            })
            .catch((e) => {
              logger.error('SendNetsuiteButton.tsx', '', null, e);
              toast.error('Failed to "Sent to Netsuite". Please refresh the page');
            });
        }}
      >
        SEND TO NETSUITE
      </Button>
    </>
  );
};

export default SendToNetsuiteButton;
