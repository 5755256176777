import { ChangeEvent, FC, useEffect } from 'react';

import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import {
  CommissionType,
  RoleIdEnumType,
  StateAbbreviation,
  TeamType,
  UserByRole,
} from '../../../gql/generated/graphql';
import { Pod, TeamTypeDisplay } from '../../../gql/podGql';

import LEMultiSelect from '../../../components/LEMultiSelect/LEMultiSelect';
import Input from '../../../components/shared/Input';
import NumberInput from '../../../components/shared/NumberInput';
import Select from '../../../components/shared/Select';
import Switch from '../../../components/shared/Switch';
import ColorSelect from '../ColorSelect';
import WorkingHoursTable from '../WorkingHoursTable/WorkingHoursTable';
import {
  showCloserAndSpecialFieldsArray,
  showManagerFieldsArray,
  showPodHoursFieldsArray,
  showSetterFieldsArray,
  showSetterLeadsFieldArray,
  showTitlingFieldsArray,
  showVSCFieldsArray,
} from '../utils';

import { PodModalFormikFields } from './PodModal';
import UsStatesSelect from './UsStatesSelect';

import {
  PodModalFormRoles,
  getSelectedUsersByRoleAndTeamRole,
  getSpecificRoleIdByTeamTypeAndRole,
} from '../../../utils/pods';

interface PodModalFormProps {
  regionPods: Pod[];
  getUsersByRoles: LazyQueryExecFunction<
    { usersByRoles: { [key in RoleIdEnumType]: UserByRole[] } },
    OperationVariables
  >;
  usersByRolesObj: { [key in RoleIdEnumType]: UserByRole[] };
}

const PodModalForm: FC<PodModalFormProps> = ({ regionPods, getUsersByRoles, usersByRolesObj }) => {
  const { values, handleChange, setFieldValue } = useFormikContext<PodModalFormikFields>();

  useEffect(() => {
    setFieldValue('pod.leads', [...values.pod.selectedSetterLeads]);
  }, [values.pod.selectedSetterLeads]);

  useEffect(() => {
    setFieldValue('pod.usersWithoutTeamRole', [
      ...values.pod.selectedClosers,
      ...values.pod.selectedSetters,
    ]);
  }, [values.pod.selectedClosers, values.pod.selectedSetters]);

  useEffect(() => {
    setFieldValue('pod.leads', [...values.pod.selectedTitleClerkLeads]);
  }, [values.pod.selectedTitleClerkLeads]);

  useEffect(() => {
    setFieldValue('pod.usersWithoutTeamRole', [...values.pod.selectedTitleClerks]);
  }, [values.pod.selectedTitleClerks]);

  const handleTeamTypeChange = async (
    e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>,
  ) => {
    if (values.pod.team_type === e.target.value) {
      return;
    }

    handleChange(e);

    const { data: usersByRolesData } = await getUsersByRoles({
      variables: {
        teamType: e.target.value,
      },
    });

    if (!usersByRolesData) {
      return;
    }

    const usersByRolesObject = usersByRolesData.usersByRoles || {};

    switch (e.target.value as TeamType) {
      case TeamType.D2d: {
        const selectedClosers = getSelectedUsersByRoleAndTeamRole(
          values.pod.usersWithoutTeamRole || [],
          usersByRolesObject,
          RoleIdEnumType.D2dCloser,
        );
        const selectedSetters =
          values.pod.usersWithoutTeamRole?.filter(
            (user) => !selectedClosers.map((closer) => closer.id).includes(user.id),
          ) || [];
        setFieldValue('pod.selectedClosers', selectedClosers);
        setFieldValue('pod.selectedSetterLeads', values.pod.leads || []);
        setFieldValue('pod.selectedSetters', selectedSetters);
        break;
      }
      case TeamType.Inbound:
      case TeamType.Outbound:
      case TeamType.Titling:
        setFieldValue('pod.selectedSetterLeads', values.pod.leads || []);
        setFieldValue('pod.selectedSetters', values.pod.usersWithoutTeamRole || []);
        break;
      default:
        break;
    }
  };

  return (
    <Flex direction="column" gap={2}>
      <Select
        name="pod.team_type"
        label="Team Type"
        options={[
          { label: 'Select Team Type', value: '' },
          ...Object.values(TeamType).map((type: TeamType) => ({
            label: TeamTypeDisplay[type as TeamType],
            value: type,
          })),
        ]}
        onChange={(e) => {
          handleTeamTypeChange(e);
        }}
        emptyOption={false}
        value={values.pod.team_type}
        isDisabled={!!values.pod.id && !!values.pod.team_type}
      />
      {values.pod.team_type ? (
        <Flex gap={2}>
          <Input label="Name" name="pod.name" placeholder="Name" />
          <ColorSelect name="pod" />
        </Flex>
      ) : null}
      {values.pod?.team_type && showVSCFieldsArray.includes(values.pod.team_type) ? (
        <Flex gap={2}>
          <NumberInput name="pod.vsc_multiplier" label="VSC Multiplier" showThousandSeparator />
          <NumberInput name="pod.vsc_markup" label="VSC Markup" showThousandSeparator isMoney />
        </Flex>
      ) : null}
      {values.pod.team_type && values.pod.team_type === TeamType.D2d ? (
        <Select
          name="pod.parent_pod_id"
          label="Region"
          options={[
            { label: 'Select Region', value: '' },
            ...regionPods?.map((pod) => ({
              label: pod.name,
              value: pod.id?.toString() as string,
            })),
          ]}
          emptyOption={false}
          value={values.pod.parent_pod_id?.toString() || ''}
        />
      ) : null}
      {values.pod?.team_type && showManagerFieldsArray.includes(values.pod.team_type) ? (
        <>
          <LEMultiSelect
            name="pod.managers"
            label="Manager(s)"
            options={usersByRolesObj[RoleIdEnumType.Admin]}
          />
          <Flex>
            <NumberInput
              name="pod.manager_commission_rate"
              label="Manager Commission"
              isMoney={values.pod.managerCommissionIsFlat}
              isPercentage={!values.pod.managerCommissionIsFlat}
              showThousandSeparator
              decimalScale={2}
              isAllowed={(_values?: { floatValue: number | undefined }) =>
                _values?.floatValue
                  ? _values.floatValue <= 9999.99
                  : _values?.floatValue === 0 || _values?.floatValue === undefined
              }
            />
            <Flex fontSize="15px" gap={1} mt={8} ml={2}>
              %
              <Switch
                name="pod.managerCommissionIsFlat"
                defaultChecked={values.pod.managerCommissionIsFlat}
                customHandleChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    'pod.manager_commission_type',
                    e.target.checked ? CommissionType.Flat : CommissionType.Percentage,
                  );
                }}
              />
              $
            </Flex>
          </Flex>
        </>
      ) : null}
      {values.pod?.team_type && showCloserAndSpecialFieldsArray.includes(values.pod.team_type) ? (
        <>
          <LEMultiSelect
            name="pod.selectedClosers"
            label={values.pod.team_type === TeamType.D2d ? 'Self Gen Setters' : 'Closers'}
            options={
              usersByRolesObj[
                getSpecificRoleIdByTeamTypeAndRole(values.pod.team_type, PodModalFormRoles.closer)
              ]
            }
          />
          {values.pod.team_type === TeamType.D2d ? (
            <Flex>
              <NumberInput
                name="pod.special_commission_rate"
                label="Self Gen Setter Commission"
                isMoney={values.pod.specialCommissionIsFlat}
                isPercentage={!values.pod.specialCommissionIsFlat}
                showThousandSeparator
                decimalScale={2}
                isAllowed={(_values?: { floatValue: number | undefined }) =>
                  _values?.floatValue
                    ? _values.floatValue <= 9999.99
                    : _values?.floatValue === 0 || _values?.floatValue === undefined
                }
              />
              <Flex fontSize="15px" gap={1} mt={8} ml={2}>
                %
                <Switch
                  name="pod.specialCommissionIsFlat"
                  defaultChecked={values.pod.specialCommissionIsFlat}
                  customHandleChange={(e) => {
                    handleChange(e);
                    setFieldValue(
                      'pod.special_commission_type',
                      e.target.checked ? CommissionType.Flat : CommissionType.Percentage,
                    );
                  }}
                />
                $
              </Flex>
            </Flex>
          ) : (
            <Flex>
              <NumberInput
                name="pod.closer_commission_rate"
                label="Closer Commission"
                isMoney={values.pod.closerCommissionIsFlat}
                isPercentage={!values.pod.closerCommissionIsFlat}
                showThousandSeparator
                decimalScale={2}
                isAllowed={(_values?: { floatValue: number | undefined }) =>
                  _values?.floatValue
                    ? _values.floatValue <= 9999.99
                    : _values?.floatValue === 0 || _values?.floatValue === undefined
                }
              />
              <Flex fontSize="15px" gap={1} mt={8} ml={2}>
                %
                <Switch
                  name="pod.closerCommissionIsFlat"
                  defaultChecked={values.pod.closerCommissionIsFlat}
                  customHandleChange={(e) => {
                    handleChange(e);
                    setFieldValue(
                      'pod.closer_commission_type',
                      e.target.checked ? CommissionType.Flat : CommissionType.Percentage,
                    );
                  }}
                />
                $
              </Flex>
            </Flex>
          )}
        </>
      ) : null}
      {values.pod?.team_type && showSetterLeadsFieldArray.includes(values.pod.team_type) ? (
        <LEMultiSelect
          name="pod.selectedSetterLeads"
          label={values.pod.team_type === TeamType.D2d ? 'Setter Lead(s)' : 'Team Leads'}
          options={
            usersByRolesObj[
              getSpecificRoleIdByTeamTypeAndRole(values.pod.team_type, PodModalFormRoles.setterLead)
            ]
          }
          additionalOnSelect={
            values.pod?.team_type !== TeamType.D2d
              ? (selectedSetterLeads) => {
                  setFieldValue(
                    'pod.selectedSetters',
                    values.pod.selectedSetters?.filter(
                      (u) =>
                        !selectedSetterLeads?.map((setterLead) => setterLead.id)?.includes(u.id),
                    ) || [],
                  );
                }
              : undefined
          }
        />
      ) : null}
      {values.pod?.team_type && showSetterFieldsArray.includes(values.pod.team_type) ? (
        <>
          <LEMultiSelect
            name="pod.selectedSetters"
            label={
              [TeamType.Inbound, TeamType.Outbound].includes(values.pod?.team_type)
                ? 'Advisors'
                : 'Setters'
            }
            options={
              usersByRolesObj[
                getSpecificRoleIdByTeamTypeAndRole(values.pod.team_type, PodModalFormRoles.setter)
              ]
            }
            additionalOnSelect={
              values.pod?.team_type !== TeamType.D2d
                ? (selectedSetters) => {
                    setFieldValue(
                      'pod.selectedSetterLeads',
                      values.pod.selectedSetterLeads?.filter(
                        (u) => !selectedSetters?.map((setter) => setter.id)?.includes(u.id),
                      ) || [],
                    );
                  }
                : undefined
            }
          />
          <Flex>
            <NumberInput
              name="pod.setter_commission_rate"
              label={
                [TeamType.Inbound, TeamType.Outbound].includes(values.pod?.team_type)
                  ? 'Advisors Commission'
                  : 'Setters Commission'
              }
              isMoney={values.pod.setterCommissionIsFlat}
              isPercentage={!values.pod.setterCommissionIsFlat}
              showThousandSeparator
              decimalScale={2}
              isAllowed={(_values?: { floatValue: number | undefined }) =>
                _values?.floatValue
                  ? _values.floatValue <= 9999.99
                  : _values?.floatValue === 0 || _values?.floatValue === undefined
              }
            />
            <Flex fontSize="15px" gap={1} mt={8} ml={2}>
              %
              <Switch
                name="pod.setterCommissionIsFlat"
                defaultChecked={values.pod.setterCommissionIsFlat}
                customHandleChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    'pod.setter_commission_type',
                    e.target.checked ? CommissionType.Flat : CommissionType.Percentage,
                  );
                }}
              />
              $
            </Flex>
          </Flex>
        </>
      ) : null}
      {values.pod?.team_type && showTitlingFieldsArray.includes(values.pod.team_type) ? (
        <>
          <LEMultiSelect
            name="pod.selectedTitleClerkLeads"
            label="Lead(s)"
            options={usersByRolesObj[RoleIdEnumType.TitleClerk]}
            additionalOnSelect={(selectedTitleClerkLeads) => {
              setFieldValue(
                'pod.selectedTitleClerks',
                values.pod.selectedTitleClerks?.filter(
                  (u) =>
                    !selectedTitleClerkLeads
                      ?.map((titleClerkLead) => titleClerkLead.id)
                      ?.includes(u.id),
                ) || [],
              );
            }}
          />
          <LEMultiSelect
            name="pod.selectedTitleClerks"
            label="Title Clerks"
            options={usersByRolesObj[RoleIdEnumType.TitleClerk]}
            additionalOnSelect={(selectedTitleClerks) => {
              setFieldValue(
                'pod.selectedTitleClerkLeads',
                values.pod.selectedTitleClerkLeads?.filter(
                  (u) => !selectedTitleClerks?.map((titleClerk) => titleClerk.id)?.includes(u.id),
                ) || [],
              );
            }}
          />
          <UsStatesSelect
            name="pod.us_states_object.states"
            label="States"
            states={Object.values(StateAbbreviation)}
          />
        </>
      ) : null}
      {values.pod?.team_type && showPodHoursFieldsArray.includes(values.pod.team_type) ? (
        <WorkingHoursTable />
      ) : null}
    </Flex>
  );
};

export default PodModalForm;
