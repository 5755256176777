import { Deal, DealStateEnum, isUneditable } from '../gql/dealGql';
import { DocumentProgressStatus, PaperworkType } from '../gql/generated/graphql';

import { canBankUseR1 } from './financialInfos';

export const CONTRACT_NOT_FOUND_ERROR = 'Contract not found in R1';

export const r1NonValidatedStatuses = [
  DocumentProgressStatus.Required,
  DocumentProgressStatus.PendingR1,
  DocumentProgressStatus.PendingLender,
  DocumentProgressStatus.Failed,
];

export const r1NonUploadedStatuses = [
  DocumentProgressStatus.Required,
  DocumentProgressStatus.PendingR1,
  DocumentProgressStatus.PendingLender,
  DocumentProgressStatus.Failed,
  DocumentProgressStatus.GenerateDocuments,
];

export const r1SentStatuses = [
  DocumentProgressStatus.SentForSignatures,
  DocumentProgressStatus.Signed,
];

export const isDocumentProgressValidated = (
  documentProgressValidationStatus?: DocumentProgressStatus,
) =>
  documentProgressValidationStatus &&
  !r1NonValidatedStatuses.includes(documentProgressValidationStatus);

export const isDocumentProgressUploaded = (
  documentProgressValidationStatus?: DocumentProgressStatus,
) =>
  documentProgressValidationStatus &&
  !r1NonUploadedStatuses.includes(documentProgressValidationStatus);

export const isDocumentProgressSent = (documentProgressValidationStatus?: DocumentProgressStatus) =>
  documentProgressValidationStatus && r1SentStatuses.includes(documentProgressValidationStatus);

export const getValidationMessage = (deal: Deal) => {
  const {
    document_progress_status,
    r1_contract_validation_warnings_object,
    r1_contract_manually_validated,
  } = deal;

  switch (document_progress_status) {
    case DocumentProgressStatus.Failed:
      return { copy: 'Failed', textColor: 'errorsRed' };
    case DocumentProgressStatus.PendingR1:
      return { copy: 'In Progress (Route One)', textColor: 'ceruleanBlue' };
    case DocumentProgressStatus.PendingLender:
      return { copy: 'In Progress (Lienholder)', textColor: 'ceruleanBlue' };
    case DocumentProgressStatus.GenerateDocuments:
    case DocumentProgressStatus.UploadDocuments:
    case DocumentProgressStatus.ReadyForSignatures:
    case DocumentProgressStatus.SentForSignatures:
    case DocumentProgressStatus.Signed: {
      if (r1_contract_manually_validated) {
        return {
          copy: 'Performed Manually',
          textColor: 'americanOrange',
        };
      }
      if (r1_contract_validation_warnings_object?.warnings?.length) {
        return {
          copy: 'Warning. Review issues below and verify. You may proceed to generate contract.',
          textColor: 'ceruleanBlue',
        };
      }
      return { copy: 'Successful', textColor: 'caribbeanGreen.500' };
    }
    default:
      return { copy: 'Required', textColor: 'ceruleanBlue' };
  }
};

export const getShouldCancelContracts = (deal: Deal, newDealState: DealStateEnum | null) => {
  if (!newDealState) {
    return false;
  }

  const isEsign = deal.paperwork_type === PaperworkType.Esign;

  const currentDealState = deal.state;
  const fromUneditableToEditableOrClosed =
    isUneditable(currentDealState) &&
    (!isUneditable(newDealState) || newDealState === DealStateEnum.Closed);
  const worksheetGenerated = deal.document_progress_status !== DocumentProgressStatus.Required;

  return (
    isEsign &&
    fromUneditableToEditableOrClosed &&
    canBankUseR1(deal.financial_info.bank) &&
    worksheetGenerated
  );
};
