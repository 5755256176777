import ReactSelect, { CSSObjectWithLabel, GroupBase, Props, StylesConfig } from 'react-select';

import { getAutocompleteStyles } from './styles';

// Custom ReactSelect that fixes the menu being cut off by the parent container
// The menu is portaled to document.body
const CustomReactSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> & { hasLeftAddon?: boolean; isInvalid?: boolean },
) => {
  const { styles, hasLeftAddon, isInvalid, ...restProps } = props;

  const defaultStyles = getAutocompleteStyles<Option, IsMulti, Group>({
    hasLeftAddon: !!hasLeftAddon,
    isInvalid: !!isInvalid,
  });

  const mergedStyles = styles
    ? Object.keys(styles).reduce((acc, key) => {
        return {
          ...acc,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [key]: (provided: CSSObjectWithLabel, state: any) => {
            const defaultStyleFn = defaultStyles[key as keyof typeof defaultStyles];
            const defaultStyle = defaultStyleFn ? defaultStyleFn(provided, state) : provided;

            const styleFn = styles[key as keyof typeof styles];
            const style = styleFn ? styleFn(provided, state) : {};

            return {
              ...defaultStyle,
              ...style,
            };
          },
        };
      }, {} as StylesConfig<Option, IsMulti, Group>)
    : defaultStyles;

  return (
    <ReactSelect
      {...restProps}
      styles={{
        ...defaultStyles,
        ...mergedStyles,
        menuPortal: (provided, state) => ({
          ...(defaultStyles?.menuPortal ? defaultStyles.menuPortal(provided, state) : provided),
          ...(styles?.menuPortal ? styles.menuPortal(provided, state) : provided),
          zIndex: 9999,
        }),
        // This allows copy and paste into the field with the right click context menu
        // https://github.com/JedWatson/react-select/issues/4461#issuecomment-1379611370
        input: (provided, state) => ({
          ...(defaultStyles?.input ? defaultStyles.input(provided, state) : provided),
          ...(styles?.input ? styles.input(provided, state) : provided),
          gridTemplateColumns: '0fr',
        }),
        placeholder: (provided, state) => ({
          ...(defaultStyles?.placeholder ? defaultStyles.placeholder(provided, state) : provided),
          ...(styles?.placeholder ? styles.placeholder(provided, state) : provided),
          pointerEvents: 'none',
          userSelect: 'none',
        }),
      }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
    />
  );
};

export default CustomReactSelect;
