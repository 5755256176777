export const phoneNumberMask = '(999) 999-9999';
export const emptyPhoneNumberMask = '(___) ___-____';

export const ssnFullMask = '999-99-9999';
export const ssnLast4Mask = '***-**-9999';
export const ssnLast6Mask = '***-99-9999';
export const emptySsnFullMask = '___-__-____';
export const emptySsnLast4Mask = 'XXX-XX-____';
export const emptySsnLast6Mask = 'XXX-__-____';

export const emptyZipMask = '_____';
