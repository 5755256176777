import { ChangeEvent, FC } from 'react';

import {
  Switch as ChakraSwitch,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  HStack,
  SwitchProps,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

interface Props extends FormControlProps {
  name: string;
  label?: string;
  showErrorMessage?: boolean;
  customHandleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  customHandleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  switchProps?: SwitchProps;
  isNoYes?: boolean;
  noYesFontSize?: Pick<FormLabelProps, 'fontSize'>['fontSize'];
  noLabel?: string;
  yesLabel?: string;
}

const Switch: FC<Props> = ({
  name,
  label,
  showErrorMessage = true,
  customHandleChange,
  customHandleBlur,
  switchProps,
  isNoYes = false,
  noYesFontSize = 'md',
  noLabel = 'No',
  yesLabel = 'Yes',
  ...rest
}) => {
  const {
    getFieldMeta,
    handleChange: formikHandleChange,
    handleBlur: formikHandleBlur,
    setFieldValue,
  } = useFormikContext();

  // fix for Formik not updating the value
  const defaultHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, e.target.checked);
    formikHandleChange(e);
  };

  const handleChange = customHandleChange ?? defaultHandleChange;
  const handleBlur = customHandleBlur ?? formikHandleBlur;
  const { value, touched, error } = getFieldMeta(name);

  const styles = useMultiStyleConfig('Switch', { size: switchProps?.size });
  const checkedStyles = useMultiStyleConfig('Switch', {
    size: switchProps?.size,
    variant: 'checked',
  });

  return (
    <FormControl isInvalid={touched && !!error} {...rest}>
      <HStack alignItems="start">
        {isNoYes ? (
          <FormLabel mb={0} mr={0} opacity={1} fontSize={noYesFontSize}>
            {noLabel}
          </FormLabel>
        ) : null}

        <ChakraSwitch
          name={name}
          isChecked={!!value}
          onChange={handleChange}
          onBlur={handleBlur}
          {...switchProps}
        />

        {label ? (
          <Text
            pb={2}
            mb={0}
            ml={1}
            whiteSpace="normal"
            sx={value === true || switchProps?.isChecked ? checkedStyles.label : styles.label}
          >
            {label}
          </Text>
        ) : null}

        {isNoYes ? (
          <FormLabel
            mb={0}
            opacity={1}
            fontSize={noYesFontSize}
            sx={value === true || switchProps?.isChecked ? checkedStyles.label : styles.label}
          >
            {yesLabel}
          </FormLabel>
        ) : null}
      </HStack>
      {showErrorMessage ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default Switch;
