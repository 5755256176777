import { Button, Divider, Flex, Icon, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdPhoneEnabled } from 'react-icons/md';
import { useCallsContext } from '../../libs/callsContext';

const CallAlreadyAnsweredPanel = () => {
  const [visible, setVisible] = useState(false);
  const { callAlreadyAnswered, setCallAlreadyAnswered } = useCallsContext();

  useEffect(() => {
    if (callAlreadyAnswered) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setCallAlreadyAnswered(false);
      }, 6000);
    }
  }, [callAlreadyAnswered]);

  if (!visible) {
    return null;
  }

  return (
    <Flex
      w="80%"
      h="80px"
      position="fixed"
      bottom={0}
      right={0}
      left={0}
      ml="10%"
      bg="midnightblue"
      alignItems="center"
      borderTopRadius="10px"
      // one less than the CurrentCallPanel
      zIndex={1999}
    >
      <Flex
        rounded="full"
        w="50px"
        h="50px"
        bg="green"
        ml={8}
        justifyContent="center"
        alignItems="center"
      >
        <Icon as={MdPhoneEnabled} color="white" w={6} h={6} bg="transparent" m={0} />
      </Flex>
      <Divider orientation="vertical" mx={8} h="50%" />
      <Text color="white" fontSize={{ base: '24px', lg: '28px' }}>
        Call Already Answered
      </Text>
      <Divider orientation="vertical" mx={8} h="50%" />
      <Button
        h="60%"
        fontSize="16px"
        padding="12px 15px"
        ml="auto"
        mr={8}
        onClick={() => setVisible(false)}
      >
        OK
      </Button>
    </Flex>
  );
};

export default CallAlreadyAnsweredPanel;
