import { Deal, DealSourceEnum, DealStateEnum } from '../gql/dealGql';
import {
  DealType,
  Deal as GeneratedDeal,
  StructuringFollowUpDealFragmentFragment,
} from '../gql/generated/graphql';
import { User } from '../gql/userGql';

import {
  PermissionEnum,
  dealSourceToPermissionMap,
  editingPermissions,
  readOnlyDealStates,
} from '../constants/permissions';

interface IsInputReadOnlyParams {
  abilities: Set<PermissionEnum>;
  dealState: DealStateEnum;
  determineReadOnlyByDealState?: boolean;
  isEditing?: boolean;
}

export const isInputReadOnly = ({
  abilities,
  dealState,
  determineReadOnlyByDealState,
  isEditing,
}: IsInputReadOnlyParams) => {
  const userReadOnlyDealStates = [...readOnlyDealStates];

  if (abilities.has(PermissionEnum.Setter)) {
    userReadOnlyDealStates.push(DealStateEnum.Closing);
  }

  const hasEditingPermission = editingPermissions.some((editingPermission) =>
    abilities.has(editingPermission),
  );
  if (isEditing && hasEditingPermission) {
    const indexOfClosedState = userReadOnlyDealStates.indexOf(DealStateEnum.Closed);
    userReadOnlyDealStates.splice(indexOfClosedState, 1);
  }

  const canEditFundedOnwards = abilities.has(PermissionEnum.EditFundedOnwards);
  if (isEditing && canEditFundedOnwards) {
    const indexOfFundedState = userReadOnlyDealStates.indexOf(DealStateEnum.Funded);
    userReadOnlyDealStates.splice(indexOfFundedState, 1);
  }

  if (
    determineReadOnlyByDealState ||
    abilities.has(PermissionEnum.Closer) ||
    abilities.has(PermissionEnum.FundingClerk)
  ) {
    return userReadOnlyDealStates.includes(dealState);
  }

  return userReadOnlyDealStates.includes(dealState) || !abilities.has(PermissionEnum.BuildDeal);
};

export const getAllowedDealSources = (abilities: Set<PermissionEnum>) => {
  const allSources = Object.values(DealSourceEnum);

  const allowedSources = allSources.filter((source) => {
    const permission = dealSourceToPermissionMap.get(source);

    return permission && abilities.has(permission);
  });

  return allowedSources;
};

export const isAllSources = (sources: DealSourceEnum[]) => {
  const allSources = Object.values(DealSourceEnum);
  return sources.length === allSources.length;
};

export const isAllTypes = (types: DealType[]) => {
  const allTypes = Object.values(DealType);
  return types.length === allTypes.length;
};

export const canManuallyClaimSetter = (
  deal: Deal,
  permissions: Set<PermissionEnum>,
  options: { ignoreSource: boolean } = { ignoreSource: false },
): boolean =>
  (permissions.has(PermissionEnum.Setter) ||
    permissions.has(PermissionEnum.SalesAdvisor) ||
    permissions.has(PermissionEnum.SuperUser)) &&
  (options.ignoreSource || deal.source === DealSourceEnum.Web) &&
  [DealStateEnum.Estimate, DealStateEnum.SoftClose].includes(deal.state);

export const canManuallyClaimStructuringManager = (
  deal: Deal | StructuringFollowUpDealFragmentFragment,
  permissions: Set<PermissionEnum>,
): boolean =>
  (permissions.has(PermissionEnum.ClaimDealAsStructuringManager) ||
    permissions.has(PermissionEnum.SuperUser)) &&
  DealStateEnum.Structuring === deal.state;

export const isSetterUnclaimed = <T extends Pick<GeneratedDeal, 'setter'>>(d: T): boolean =>
  !d.setter?.id;
export const isStructuringManagerClaimed = (d: Deal | StructuringFollowUpDealFragmentFragment) =>
  !!d.structuring_manager_id;

const canClaimFinancialSpecialistInDealState = (
  permissions: Set<PermissionEnum>,
  dealState: DealStateEnum,
) => {
  const canClaimInSoftClose = permissions.has(PermissionEnum.ClaimFinancialSpecialistInSoftClose);
  const unclaimableDealStates = [DealStateEnum.Estimate, DealStateEnum.Floor];

  if (!canClaimInSoftClose) {
    unclaimableDealStates.push(DealStateEnum.SoftClose);
  }

  return !unclaimableDealStates.includes(dealState);
};

export const isAdvisor = (permissions: Set<PermissionEnum>) =>
  permissions.has(PermissionEnum.Setter) || permissions.has(PermissionEnum.SalesAdvisor);

export const isFinancialSpecialist = (permissions: Set<PermissionEnum>) =>
  permissions.has(PermissionEnum.Closer) || permissions.has(PermissionEnum.FinancialSpecialist);

export const isLeadAdvisor = (permissions: Set<PermissionEnum>) =>
  permissions.has(PermissionEnum.LeadAdvisorPod);

export const canManuallyClaimCloser = (
  deal: Deal,
  permissions: Set<PermissionEnum>,
  user: User,
): boolean =>
  !!user.can_claim_as_closer &&
  (permissions.has(PermissionEnum.Closer) ||
    permissions.has(PermissionEnum.SuperUser) ||
    permissions.has(PermissionEnum.FinancialSpecialist)) &&
  canClaimFinancialSpecialistInDealState(permissions, deal.state);

export const isCloserUnclaimed = <T extends Pick<GeneratedDeal, 'closer' | 'closer2'>>(
  d: T,
): boolean => !d.closer?.id && !d.closer2?.id;

export const canManuallyClaimFundingClerk = (
  deal: Deal,
  permissions: Set<PermissionEnum>,
): boolean =>
  (permissions.has(PermissionEnum.SuperUser) ||
    permissions.has(PermissionEnum.ClaimDealAsAccountingClerk)) &&
  deal.state === DealStateEnum.Signed;

export const isFundingClerkUnclaimed = (d: Deal): boolean => !d.funding_clerk?.id;

/**
 * Returns the role if the user can claim the deal as that role.
 * Returns null if the user cannot claim the deal or is already claimed.
 */
export const getClaimRole = (
  deal: Deal,
  abilities: Set<PermissionEnum>,
  user: User,
): keyof Pick<Deal, 'setter_id' | 'closer_id' | 'funding_clerk_id'> | null => {
  if (
    deal.state === DealStateEnum.StructuringInProgress ||
    deal.state === DealStateEnum.Structuring
  ) {
    return null;
  }

  // The `canClaim` functions also check the deal state.
  if (isCloserUnclaimed(deal) && canManuallyClaimCloser(deal, abilities, user)) {
    return 'closer_id';
  }
  if (isSetterUnclaimed(deal) && canManuallyClaimSetter(deal, abilities)) {
    return 'setter_id';
  }
  if (isFundingClerkUnclaimed(deal) && canManuallyClaimFundingClerk(deal, abilities)) {
    return 'funding_clerk_id';
  }

  return null;
};

export const isClaimRequired = (deal: Deal, abilities: Set<PermissionEnum>, user: User) =>
  getClaimRole(deal, abilities, user) !== null;

export const filterOwner = (
  deals: Deal[],
  user: User,
  abilities: Set<PermissionEnum>,
  options: { includeUnclaimedDeals: boolean } = { includeUnclaimedDeals: true },
): Deal[] =>
  deals.filter((deal) => {
    const isOwner =
      deal.setter?.id === user.id || deal.closer?.id === user.id || deal.closer2?.id === user.id;

    if (options.includeUnclaimedDeals) {
      return (
        isOwner ||
        (isCloserUnclaimed(deal) && canManuallyClaimCloser(deal, abilities, user)) ||
        (isSetterUnclaimed(deal) && canManuallyClaimSetter(deal, abilities, { ignoreSource: true }))
      );
    }

    return isOwner;
  });
