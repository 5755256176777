import { gql } from '@apollo/client';

import { JdpVehicleAccessoriesObject } from './generated/graphql';

import { Payoff, PayoffFragment } from './payoffGql';

export enum ColorEnum {
  BLACK = 'Black',
  RED = 'Red',
  BLUE = 'Blue',
  BROWN = 'Brown',
  GRAY = 'Gray',
  GREEN = 'Green',
  ORANGE = 'Orange',
  PURPLE = 'Purple',
  SILVER = 'Silver',
  WHITE = 'White',
  YELLOW = 'Yellow',
}

export const colorOptions = Object.values(ColorEnum).map((value) => ({
  label: value,
  value,
}));

export enum OdometerStatus {
  Verified = 'Verified',
  Warning = 'Warning',
  Changed = 'Changed',
}

export enum VehicleEnum {
  MPV = 'MULTIPURPOSE PASSENGER VEHICLE (MPV)',
  TRUCK = 'TRUCK',
  PASSENGER = 'PASSENGER CAR',
}

export enum FuelType {
  Gasoline = 'Gasoline',
  Electric = 'Electric',
}
export const isElectricVehicle = (fuelType: string | undefined) => fuelType === FuelType.Electric;

export type KbbSelectedOption = {
  id: number;
  name: string;
  type: string;
  lendingOptionPrice: number;
  retailOptionPrice: number;
  removed: boolean;
  categoryGroup: string;
};

export type KbbSelectedOptionObject = {
  selected_options: KbbSelectedOption[];
};

export class Car {
  id?: number;
  color?: ColorEnum;
  mileage?: number;
  book_value?: number;
  retail_book_value?: number;
  jdp_adjusted_clean_trade?: number;
  jdp_adjusted_clean_retail?: number;
  jdp_vehicle_accessories_object?: JdpVehicleAccessoriesObject;
  vehicle_type?: string;
  fuel_type?: string;
  kbb_trim_name?: string;
  mmr?: number;
  vin: string;
  year: string;
  make: string;
  model: string;
  payoff: Payoff;
  license_plate_number?: string;
  license_plate_state?: string;
  registration_expiration?: Date;
  odometer_status?: OdometerStatus;
  kbb_selected_options_object?: KbbSelectedOptionObject;

  constructor() {
    this.vin = '';
    this.year = '';
    this.make = '';
    this.model = '';
    this.payoff = new Payoff();
  }
}

export const getFullCarName = (c: Car) => (c ? `${c?.year} ${c?.make} ${c?.model}` : '');

export enum MileageWarningsEnum {
  LowerThanReported = 'The mileage value of {value} is lower than the last reported number of {expected}. Please verify and confirm mileage before continuing.',
  RoundNumber = 'Mileage is rarely a round number. Are you sure {value} is correct?',
  HighMileage = 'This value seems very high. Is this correct?',
  LowMileage = 'This value seems very low. Is this correct?',
  RepeatingEnd = 'The mileage value of {value} has repeating digits at the end. Is this correct?',
}

export const carDetailVinQuery = gql`
  query carDetailVin($vin: String) {
    carDetailVin(vin: $vin) {
      year
      make
      model
      vehicle_type
      fuel_type
    }
  }
`;

export const carDetailLicensePlateQuery = gql`
  query carDetailLicensePlate($license_plate_state: String!, $license_plate_number: String!) {
    carDetailLicensePlate(
      license_plate_state: $license_plate_state
      license_plate_number: $license_plate_number
    ) {
      vin
      year
      make
      model
      vehicle_type
      fuel_type
    }
  }
`;

export const getWarrantyInfoQuery = gql`
  query getWarrantyInfo($make: String!, $mileage: Int!, $year: String!) {
    getWarrantyInfo(make: $make, mileage: $mileage, year: $year) {
      full_years
      full_miles
      full_status
      power_train_years
      power_train_miles
      power_train_status
    }
  }
`;

export const vehicleInfoUpsert = gql`
  mutation vehicleInfoUpsert($deal: dealInput) {
    vehicleInfoUpsert(deal: $deal) {
      id
      car {
        id
        license_plate_number
        license_plate_state
        vin
        year
        make
        model
        mileage
        color
        book_value
        retail_book_value
        jdp_adjusted_clean_trade
        jdp_adjusted_clean_retail
        vehicle_type
        fuel_type
        kbb_trim_name
        mmr
        registration_expiration
        odometer_status
        payoff {
          ...PayoffFragment
        }
      }
      financial_info_acquisition {
        id
        deal_id
        kbb_lending
        appraised_value
        max_cash_to_customer
        max_total_cost
        cash_to_customer
        is_approved
        auction_fees
        transport_cost
        total_cost
        auction_id
        gross_profit
        sell_price
        overriding_user_id
      }
    }
  }
  ${PayoffFragment}
`;

export const vehicleMileageQuery = gql`
  query vehicleMileage($vin: String!) {
    vehicleMileage(vin: $vin) {
      estimatedAverageMiles
      eventCount
      lastOdometer
      lastOdometerDate
      resultCode
      resultMessage
      rollback
      vin
    }
  }
`;

export const updateCarMutation = gql`
  mutation updateCar($car: carInput!) {
    updateCar(car: $car) {
      id
    }
  }
`;
