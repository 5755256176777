import { Avatar, AvatarProps, Box } from '@chakra-ui/react';

type NotificationAvatarProps = AvatarProps & {
  count?: number;
  messagesOnly?: boolean;
  maxDisplayCount?: number;
};

const NotificationsAvatar = ({
  count,
  messagesOnly = true,
  maxDisplayCount = 99,
  ...rest
}: NotificationAvatarProps) => {
  if (!count) {
    return null;
  }

  const countDisplayValue = count && count > maxDisplayCount ? `${maxDisplayCount}+` : count;

  const avatarProps: AvatarProps = {
    bg: messagesOnly ? 'green' : 'red',
    fontSize: count && count > maxDisplayCount ? '0.7em' : '0.9em',
  };

  return (
    <Avatar icon={<Box />} size="xs" ml={1} {...avatarProps} {...rest}>
      {countDisplayValue}
    </Avatar>
  );
};

export default NotificationsAvatar;
