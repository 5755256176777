import { ChangeEvent, Dispatch, FC, useEffect, useState } from 'react';

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { HiSearch } from 'react-icons/hi';

import useDebounce from '../../../../hooks/useDebounce';
import { FiltersAction, FiltersActionKind } from '../../utils';

interface Props {
  actionKind: FiltersActionKind.SEARCH;
  dispatch: Dispatch<FiltersAction>;
  value?: string;
}

const Search: FC<Props> = ({ dispatch, actionKind, value }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearch = useDebounce((payload: string) => {
    dispatch({ type: actionKind, payload });
  }, 500);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const payload = e.target.value ?? '';
    setSearchValue(payload);
    debouncedSearch(payload);
  };

  useEffect(() => {
    setSearchValue(value ?? '');
  }, [value]);

  return (
    <InputGroup maxW="200px">
      <InputLeftElement pointerEvents="none" h="full">
        <HiSearch color="gray.300" />
      </InputLeftElement>
      <Input
        type="text"
        placeholder="Search"
        value={searchValue}
        h="40px"
        minW="170px"
        maxW="200px"
        borderRadius={3}
        bgColor="azureishWhite"
        onChange={handleInputChange}
      />
    </InputGroup>
  );
};

export default Search;
