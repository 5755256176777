import React, { MouseEvent } from 'react';
import { Box, Button, HStack, IconButton, VStack, Td, Tooltip, Text } from '@chakra-ui/react';
import {
  MdDeleteForever,
  MdDownload,
  MdPreview,
  MdDomainVerification,
  MdInfo,
} from 'react-icons/md';

import { DealMediaEdit } from '../../../../../gql/mediaGql';
import { formatDateAndTime } from '../../../../../utils/dates';
import { downloadFile } from '../../../../../utils/files';
import { DELETE, VERIFY, ADD, ShowMediaModalType } from '../../MediaModal';
import colors from '../../../../../chakra/foundations/colors';

interface MediaRowActionsProps {
  sourceFileName: string;
  filePath: string;
  fileUrl: string;
  updateModalProps: (showModal: ShowMediaModalType) => void;
  dealMedia: DealMediaEdit;
  isInternalFile: boolean;
  isUploaded: boolean;
  canDelete?: boolean;
}

const MediaRowActions: React.FC<MediaRowActionsProps> = ({
  sourceFileName,
  filePath,
  fileUrl,
  updateModalProps,
  dealMedia,
  isInternalFile,
  isUploaded,
  canDelete = true,
}) => {
  const tooltipDate = dealMedia.created_at ? `${formatDateAndTime(dealMedia.created_at)}` : '';
  const verifyFile = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    updateModalProps({
      show: true,
      content: VERIFY,
      filePath,
      dealMedia: { ...dealMedia },
      isInternalFile,
    });
  };

  return (
    <Td paddingX={2} textAlign="center" w="2.5%">
      {isUploaded ? (
        <HStack alignItems="center" w="100%" spacing={0}>
          <IconButton
            icon={
              dealMedia.verified ? (
                <MdDomainVerification color={colors.lightGreen[400]} />
              ) : (
                <MdPreview />
              )
            }
            variant="iconHover"
            size="sm"
            fontSize={25}
            aria-label="Verify"
            onClick={verifyFile}
          />
          <IconButton
            icon={<MdDownload />}
            variant="iconHover"
            size="sm"
            fontSize={25}
            aria-label="Download"
            onClick={(e) => {
              e.stopPropagation();
              downloadFile(fileUrl, sourceFileName);
            }}
          />
          <IconButton
            icon={<MdDeleteForever />}
            variant="iconHover"
            size="sm"
            fontSize={25}
            aria-label="Delete"
            isDisabled={!canDelete}
            onClick={(e) => {
              e.stopPropagation();
              updateModalProps({
                show: true,
                content: DELETE,
                filePath,
                dealMedia: { ...dealMedia, sourceFileName },
              });
            }}
          />
          <Tooltip
            placement="top"
            hasArrow
            width="150px"
            label={
              <VStack spacing="-1">
                <Text>Added</Text>
                <Text>{tooltipDate}</Text>
              </VStack>
            }
            backgroundColor="gray.700"
            borderRadius="5px"
            marginX={2}
            textAlign="center"
          >
            <Box>
              <MdInfo size={25} />
            </Box>
          </Tooltip>
        </HStack>
      ) : (
        <Button
          onClick={() =>
            updateModalProps({
              show: true,
              content: ADD,
              initialMediaType: dealMedia.type,
            })
          }
          size="sm"
        >
          UPLOAD
        </Button>
      )}
    </Td>
  );
};

export default MediaRowActions;
