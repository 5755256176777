import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';

import { Box, BoxProps, Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { ColorEnum } from '../../gql/carGql';
import { Deal } from '../../gql/dealGql';

import CardSubHeaderV2 from '../shared/Card/components/CardSubHeaderV2';
import DatePicker from '../shared/DatePicker';
import { GridFormRow } from '../shared/GridForm';
import Input from '../shared/Input';
import NumberInput from '../shared/NumberInput';
import Select from '../shared/Select';

import VehicleValuesCalculator from './VehicleValuesCalculator';
import { AutoExplodeLicensePlate } from './autoExplodeLicensePlate';
import { AutoExplodeVin } from './autoExplodeVin';

import colors from '../../chakra/foundations/colors';
import { states } from '../../libs/states';

interface Props extends BoxProps {
  handleOnChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => void;
  handleOnBlur: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  carDetailsLoading: boolean;
  setCarDetailsLoading: Dispatch<SetStateAction<boolean>>;
  callVinDecoder: boolean;
  setCallVinDecoder: Dispatch<SetStateAction<boolean>>;
  callLicensePlateDecoder: boolean;
  setCallLicensePlateDecoder: Dispatch<SetStateAction<boolean>>;
  isLicensePlateNumberDirty: boolean;
  isLicensePlateStateDirty: boolean;
  setLicensePlateNumberDirty: Dispatch<SetStateAction<boolean>>;
  setLicensePlateStateDirty: Dispatch<SetStateAction<boolean>>;
  isVinDirty: boolean;
  setVinDirty: Dispatch<SetStateAction<boolean>>;
}

export const VehicleForm: FC<Props> = ({
  handleOnChange,
  handleOnBlur,
  carDetailsLoading,
  setCarDetailsLoading,
  callVinDecoder,
  setCallVinDecoder,
  callLicensePlateDecoder,
  setCallLicensePlateDecoder,
  isLicensePlateNumberDirty,
  isLicensePlateStateDirty,
  setLicensePlateNumberDirty,
  setLicensePlateStateDirty,
  isVinDirty,
  setVinDirty,
  ...rest
}) => {
  const { values } = useFormikContext<Deal>();

  return (
    <Box {...rest}>
      <CardSubHeaderV2 title="Vehicle Information & Values" />
      <SimpleGrid
        templateColumns={{
          base: '1fr',
          sm: '3fr 1fr',
        }}
      >
        <Stack py={5} px={6} spacing={3}>
          <GridFormRow minChildWidth={135}>
            <Input
              label="License Plate Number"
              name="car.license_plate_number"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            <AutoExplodeLicensePlate
              setCarDetailsLoading={setCarDetailsLoading}
              setCallVinDecoder={setCallVinDecoder}
              callLicensePlateDecoder={callLicensePlateDecoder}
              setCallLicensePlateDecoder={setCallLicensePlateDecoder}
              isLicensePlateNumberDirty={isLicensePlateNumberDirty}
              isLicensePlateStateDirty={isLicensePlateStateDirty}
              setLicensePlateNumberDirty={setLicensePlateNumberDirty}
              setLicensePlateStateDirty={setLicensePlateStateDirty}
            />
            <Select
              label="License Plate State"
              name="car.license_plate_state"
              options={Object.keys(states).map((stateKey) => {
                return {
                  value: states[stateKey as keyof typeof states],
                  label: states[stateKey as keyof typeof states],
                };
              })}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            <DatePicker
              topLabel="Registration Expiration"
              name="car.registration_expiration"
              dateFormat="MMMM yyyy"
              showMonthYearPicker
            />
          </GridFormRow>
          <GridFormRow minChildWidth={135}>
            <Input
              label="VIN Number"
              name="car.vin"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            <Box />
            <Box />
            <AutoExplodeVin
              setCarDetailsLoading={setCarDetailsLoading}
              callVinDecoder={callVinDecoder}
              setCallVinDecoder={setCallVinDecoder}
              isVinDirty={isVinDirty}
              setVinDirty={setVinDirty}
            />
          </GridFormRow>
          <GridFormRow minChildWidth={135}>
            <Input
              label="Year"
              name="car.year"
              onChange={handleOnChange}
              isLoading={carDetailsLoading}
            />
            <Input
              label="Make"
              name="car.make"
              onChange={handleOnChange}
              isLoading={carDetailsLoading}
            />
            <Input
              label="Model"
              name="car.model"
              onChange={handleOnChange}
              isLoading={carDetailsLoading}
            />
          </GridFormRow>
          <GridFormRow minChildWidth={135}>
            <NumberInput label="Mileage" name="car.mileage" isWhole showThousandSeparator />
            <Select
              label="Vehicle Color"
              name="car.color"
              options={Object.values(ColorEnum).map((color) => {
                return { value: color, label: color };
              })}
              value={values.car?.color}
              onChange={handleOnChange}
            />
            <Box />
          </GridFormRow>
        </Stack>

        <Stack bgColor={colors.azureishWhite} py={5} px={6}>
          <GridFormRow minChildWidth={135}>
            <NumberInput
              label="KBB Lending"
              name="car.book_value"
              _input={{
                bgColor: colors.white,
                _focus: {
                  bgColor: colors.white,
                },
              }}
              isMoney
              isWhole
              showThousandSeparator
            />
            <NumberInput
              label="KBB Retail"
              name="car.retail_book_value"
              _input={{
                bgColor: colors.white,
                _focus: {
                  bgColor: colors.white,
                },
              }}
              isMoney
              isWhole
              showThousandSeparator
            />
            <NumberInput
              label="JD Power Lending"
              name="car.jdp_adjusted_clean_trade"
              _input={{
                bgColor: colors.white,
                _focus: {
                  bgColor: colors.white,
                },
              }}
              isMoney
              isWhole
              showThousandSeparator
            />
            <NumberInput
              label="JD Power Retail"
              name="car.jdp_adjusted_clean_retail"
              _input={{
                bgColor: colors.white,
                _focus: {
                  bgColor: colors.white,
                },
              }}
              isMoney
              isWhole
              showThousandSeparator
            />
          </GridFormRow>

          <Flex pt={6} justifyContent="end">
            <VehicleValuesCalculator
              kbbSelectedOptions={values.car?.kbb_selected_options_object?.selected_options}
            />
          </Flex>
        </Stack>
      </SimpleGrid>
    </Box>
  );
};
