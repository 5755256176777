import { theme } from '@chakra-ui/react';
import { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';

import colors from '../../chakra/foundations/colors';

export const getAutocompleteStyles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  { hasLeftAddon, isInvalid } = {
    isInvalid: false,
    hasLeftAddon: false,
  },
) => {
  const color = colors.darkLiver;

  const controlProps: CSSObjectWithLabel = isInvalid
    ? {
        backgroundColor: colors.spanishPink,
        borderColor: theme.colors.red[500],
        boxShadow: `0 0 0 1px ${theme.colors.red[500]}`,
      }
    : {
        backgroundColor: colors.white,
        borderColor: colors.darkLiver,
      };

  const indicatorProps: CSSObjectWithLabel = {
    width: '24px',
    padding: '4px',
    color,
    ':hover': {
      color,
    },
  };

  const customStyles: StylesConfig<Option, IsMulti, Group> = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      maxHeight: '32px',
      fontSize: '14px',
      fontWeight: 'normal',
      paddingRight: '2px',
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '32px',
      maxHeight: '32px',
      alignItems: 'start',
      ...controlProps,
      borderRadius: hasLeftAddon ? `0 ${theme.radii.sm} ${theme.radii.sm} 0` : theme.radii.sm,
      ':hover': {
        ...controlProps,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: '32px',
      margin: 0,
      padding: '5px 10px',
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      maxHeight: '30px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      ...indicatorProps,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...indicatorProps,
      marginRight: '6px',
    }),
    option: (provided) => ({
      ...provided,
      padding: '6px 12px',
      fontSize: '14px',
      fontWeight: 'normal',
    }),
    placeholder: (provided) => ({
      ...provided,
      color,
    }),
  };

  return customStyles;
};
