import React, { useContext, useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { File } from '../../../../gql/contractGql';
import { getFullName } from '../../../../gql/customerGql';
import { Deal, dealInfoUpsert } from '../../../../gql/dealGql';
import {
  generatePurchaseOrder as _generatePurchaseOrder,
  sendPurchaseOrder as _sendPurchaseOrder,
} from '../../../../gql/documentsGql';

// eslint-disable-next-line import/order
import styled from 'styled-components';

import { PermissionEnum } from '../../../../constants/permissions';
import { Can } from '../../../../libs/Can';
import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { isNumber } from '../../../../libs/utils';
import ClickMe from '../../../GenerateDocs/ClickMe';
import PDFViewer from '../../../PDFViewer.tsx';

const StyledModal = styled(Modal)`
  & > div {
    max-width: none;
    width: 80vw;
  }
`;

const SendPurchaseOrderButton: React.FC = () => {
  const { dispatch } = useContext(DealContext);
  const { values } = useFormikContext<Deal>();
  const [sendPurchaseOrder, { data, loading, error }] = useLazyQuery(_sendPurchaseOrder);
  const [generatePurchaseOrder, { data: dataPO }] = useLazyQuery(_generatePurchaseOrder);

  const [upsertDealInfo, { loading: upsertLoading }] = useMutation(dealInfoUpsert);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [missingInfo, setMissingInfo] = useState<boolean>(true);
  const [purchaseOrderID, setPurchaseOrderID] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPreview, setShowPreview] = useState<boolean>();
  const [fileUrl, setFileUrl] = useState<string>();

  useEffect(() => {
    if (values?.document_info?.adobe_agreement_id) {
      setPurchaseOrderID(values.document_info.adobe_agreement_id);
    }
  }, [values?.document_info]);

  useEffect(() => {
    setIsDisabled(missingInfo);
  }, [missingInfo]);

  useEffect(() => {
    setMissingInfo(
      !values.financial_info.option_type ||
        !values.financial_info.payment ||
        !isNumber(values.financial_info.money_down) ||
        !values.customer.email,
    );
  }, [values.financial_info]);

  useEffect(() => {
    if (data?.sendPurchaseOrder) {
      toast.success('Sent Purchase Order');
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`, { autoClose: false });
    }
  }, [error]);

  const handleSubmit = () =>
    upsertDealInfo({
      variables: {
        financialInfo: values.financial_info,
        car: values.car,
      },
    }).then((res) => {
      dispatch({ type: DealActionsEnum.UpdateDeal, payload: res.data.dealInfoUpsert });
      generatePurchaseOrder({
        variables: {
          deal_id: values.id,
        },
      });
    });

  return (
    <Can I={PermissionEnum.SendPurchaseOrder}>
      <Button
        variant="primary"
        size="sm"
        isLoading={loading || upsertLoading}
        loadingText="GENERATE PURCHASE ORDER"
        isDisabled={isDisabled}
        onClick={() => {
          setShowModal(true);
          handleSubmit();
        }}
      >
        GENERATE PURCHASE ORDER
      </Button>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        dialogClassName="preview-contracts-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">Preview or Send Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataPO?.generatePurchaseOrder?.files ? (
            dataPO?.generatePurchaseOrder?.files.map((file: File) => (
              <div key={file.key}>
                <ClickMe
                  onClick={() => {
                    setFileUrl(file.url);
                    setShowPreview(true);
                  }}
                >
                  {file.filename}
                </ClickMe>
              </div>
            ))
          ) : (
            <div>Loading...</div>
          )}
          <Button
            isLoading={loading || upsertLoading}
            loadingText="SEND PURCHASE ORDER"
            onClick={() => {
              if (purchaseOrderID) {
                setShowConfirmation(true);
              } else {
                setShowModal(false);
                sendPurchaseOrder({
                  variables: {
                    deal_id: values.id,
                    key: dataPO?.generatePurchaseOrder?.files[0].key,
                  },
                });
              }
            }}
          >
            SEND PURCHASE ORDER
          </Button>
        </Modal.Body>
      </Modal>
      <StyledModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        backdrop="static"
        keyboard={false}
        dialogClassName="po-preview-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">Previewing Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFViewer fileUrl={fileUrl} />
        </Modal.Body>
      </StyledModal>
      <Modal
        show={showConfirmation}
        backdrop="static"
        onHide={() => setShowConfirmation(false)}
        keyboard={false}
        dialogClassName="purchase-order-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            A purchase order has already been sent to {getFullName(values.customer)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            isLoading={loading || upsertLoading}
            loadingText="SEND AGAIN"
            onClick={() => {
              setShowConfirmation(false);
              setShowModal(false);
              sendPurchaseOrder({
                variables: {
                  deal_id: values.id,
                  key: dataPO?.generatePurchaseOrder?.files[0].key,
                },
              });
            }}
          >
            SEND AGAIN
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              setShowConfirmation(false);
              setShowModal(false);
            }}
          >
            CANCEL
          </Button>
        </Modal.Body>
      </Modal>
    </Can>
  );
};

export default SendPurchaseOrderButton;
