import { useContext } from 'react';

import { Button, ButtonProps, createIcon } from '@chakra-ui/react';

import { DealContext } from '../../../../libs/DealContext';

const CreditScoreIcon = createIcon({
  displayName: 'CreditScoreIcon',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512 416">
      <path d="M161 342.026c-8.82-.243-17.143-.162-25.468-.384-15.75-.42-31.938-14.325-31.895-35.618.131-64.987.094-129.974.137-194.961.003-4.51.744-8.946 1.904-13.284 3.39-12.675 15.21-22.129 28.284-23.231 5.83-.492 11.64-.533 17.463-.534 81.817-.015 163.634-.01 245.45-.017 5.331 0 10.712.021 15.858 1.386 15.41 4.087 25.42 16.866 25.512 32.99.178 31.658-.014 63.32.156 94.98.024 4.552-2.032 5.074-5.764 5.061-28.827-.097-57.655-.046-86.482-.045-66.987.002-133.973 0-200.96.004-6.855 0-6.873.012-6.874 6.793-.005 28.827.115 57.656-.107 86.481-.038 4.948 1.569 6.128 6.265 6.087 23.66-.209 47.324.007 70.984-.172 4.506-.034 6.201 1.247 5.975 5.908-.346 7.149-.08 14.327-.095 21.492-.014 6.98-.025 7.004-6.855 7.015-17.663.028-35.325.034-53.488.05m138.5-233.653h-12.996c-47.32 0-94.641.055-141.961-.087-4.708-.014-6.595 1.253-6.313 6.183.37 6.477.084 12.991.09 19.49.007 6.715.013 6.72 6.967 6.72 79.811 0 159.623-.004 239.435-.006 4.665 0 9.337-.155 13.994.048 3.618.157 5.115-1.105 5.008-4.87-.203-7.16-.345-14.344.046-21.488.273-4.976-1.614-6.12-6.297-6.093-32.323.181-64.648.1-97.973.103z" />
      <path d="M399.257 247.257c4.021-4.02 7.93-7.665 11.518-11.6 2.565-2.812 4.408-2.52 6.915.116 5.74 6.037 11.575 12.002 17.685 17.658 3.277 3.033 1.908 4.818-.535 7.243-17.033 16.904-33.985 33.888-50.955 50.855-19.907 19.902-39.91 39.712-59.618 59.81-3.642 3.713-5.477 3.46-8.988-.127-20.745-21.199-41.691-42.204-62.767-63.075-3.418-3.384-3.631-5.386.023-8.669 5.818-5.226 11.278-10.876 16.61-16.607 2.695-2.898 4.473-2.864 7.228-.028 12.887 13.267 26.065 26.252 38.902 39.566 3.33 3.454 5.373 3.483 8.789.038 24.872-25.085 49.938-49.977 75.193-75.18z" />
    </svg>
  ),
});

const PrequalificationButton = ({ ...rest }: ButtonProps) => {
  const { everyoneIsPrequalified } = useContext(DealContext);

  const buttonText = everyoneIsPrequalified ? 'PREQUAL RESULTS' : 'PREQUALIFY';

  return (
    <Button
      variant="secondary"
      size="smWithIconLeft"
      leftIcon={<CreditScoreIcon fontSize={16} />}
      loadingText={buttonText}
      {...rest}
    >
      {buttonText}
    </Button>
  );
};

export default PrequalificationButton;
