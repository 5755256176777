import { FC } from 'react';

import { Switch } from 'react-router-dom';

import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import { PermissionEnum } from './constants/permissions';
import ROUTES from './constants/routes';
import { DealProvider } from './libs/DealContext';
import BootedDealsPage from './pages/BootedDealsPage';
import CompletedDealsPage from './pages/CompletedDealsPage';
import Dashboard from './pages/Dashboard';
import DealDetail from './pages/DealDetail';
import FollowUpsPage from './pages/FollowUpsPage/FollowUpsPage';
import Home from './pages/Home';
import Metrics from './pages/Metrics';
import NewEstimate from './pages/NewEstimate';
import NotFound from './pages/NotFound';
import PressManagerPage from './pages/PressManagerPage';
import ReviewsPage from './pages/ReviewsPage';
import ScheduleFollowUpPage from './pages/ScheduleFollowUpPage/ScheduleFollowUpPage';
import StructuringFollowUpsPage from './pages/StructuringFollowUpsPage/StructuringFollowUpsPage';
import Teams from './pages/TeamsPage';
import TitlingDealDetail from './pages/TitlingDealDetail';
import Transcriptions from './pages/Transcriptions';

const Routes: FC = () => {
  return (
    <Switch>
      <UnauthenticatedRoute exact path={ROUTES.HOME}>
        <Home />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path={ROUTES.DASHBOARD}>
        <Dashboard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={ROUTES.TRANSCRIPTIONS}>
        <Transcriptions />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={ROUTES.NEW_ESTIMATE}>
        <NewEstimate />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={ROUTES.SCHEDULE_FOLLOW_UP}>
        <ScheduleFollowUpPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={ROUTES.DEAL_SHOW}>
        <DealProvider>
          <DealDetail />
        </DealProvider>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path={ROUTES.TITLING_DEAL_SHOW}>
        <TitlingDealDetail />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path={ROUTES.REVIVE_BOOT}
        excludedPermission={PermissionEnum.CannotSeeBootedPage}
      >
        <BootedDealsPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path={ROUTES.COMPLETED_DEALS}
        excludedPermission={PermissionEnum.CannotSeeCompletedPage}
      >
        <CompletedDealsPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path={ROUTES.FOLLOW_UPS}
        excludedPermission={PermissionEnum.CannotSeeFollowUpsPage}
      >
        <FollowUpsPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path={ROUTES.STRUCTURING_FOLLOW_UPS}
        requiredPermission={PermissionEnum.SeeStructuringQueue}
      >
        <StructuringFollowUpsPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path={ROUTES.METRICS}
        excludedPermission={PermissionEnum.CannotSeeMetricsPage}
      >
        <Metrics />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        requiredPermission={PermissionEnum.SuperUser}
        path={ROUTES.COM_REVIEWS}
      >
        <ReviewsPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact requiredPermission={PermissionEnum.ManagePods} path={ROUTES.TEAMS}>
        <Teams />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        requiredPermission={PermissionEnum.SuperUser}
        path={ROUTES.PRESS_PAGE_MANAGER}
      >
        <PressManagerPage />
      </AuthenticatedRoute>
      <NotFound />
    </Switch>
  );
};

export default Routes;
