import React, { useContext, useEffect, useState } from 'react';

import { PropsValue, SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, DealStateLabelMap } from '../../../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../../../gql/generated/graphql';

import CustomReactSelect from '../../../shared/CustomReactSelect';
import { Option } from '../../../shared/types';

import { PermissionEnum } from '../../../../constants/permissions';
import { logger } from '../../../../libs/Logger';
import { AbilityContext } from '../../../../libs/contextLib';

interface Props {
  deal: Deal;
  highlight?: boolean;
}

const DealStateAutoComplete: React.FC<Props> = ({ deal, highlight }) => {
  const [updateDealState] = useDealUpdateStateMutation();

  const [dealStateOptions, setDealStateOptions] = useState<Option[]>([]);
  const [selectedState, setSelectedState] = useState<PropsValue<Option>>(null);

  const ability = useContext(AbilityContext);
  const canChangeStateToAnyPrevious = ability.has(PermissionEnum.ChangeStateToAnyPrevious);

  const getNextState = () => {
    switch (deal.state) {
      case DealStateEnum.Funded:
        return DealStateEnum.SendPayoff;
      case DealStateEnum.SendPayoff:
        return DealStateEnum.WaitingForTitle;
      case DealStateEnum.WaitingForTitle:
        return DealStateEnum.TitleReceived;
      case DealStateEnum.TitleReceived:
        return DealStateEnum.SentToProcessor;
      case DealStateEnum.SentToProcessor:
        return DealStateEnum.Finalized;
      default:
        return null;
    }
  };

  const getTitlingDealStates = () => {
    switch (deal.state) {
      case DealStateEnum.Funded:
        return [
          { label: DealStateLabelMap.funded, value: DealStateEnum.Funded },
          { label: DealStateLabelMap.send_payoff, value: DealStateEnum.SendPayoff },
        ];
      case DealStateEnum.SendPayoff:
        return [
          { label: DealStateLabelMap.funded, value: DealStateEnum.Funded },
          { label: DealStateLabelMap.send_payoff, value: DealStateEnum.SendPayoff },
          { label: DealStateLabelMap.waiting_for_title, value: DealStateEnum.WaitingForTitle },
        ];
      case DealStateEnum.WaitingForTitle:
        return [
          { label: DealStateLabelMap.send_payoff, value: DealStateEnum.SendPayoff },
          { label: DealStateLabelMap.waiting_for_title, value: DealStateEnum.WaitingForTitle },
          { label: DealStateLabelMap.title_received, value: DealStateEnum.TitleReceived },
        ];
      case DealStateEnum.TitleReceived:
        return [
          { label: DealStateLabelMap.waiting_for_title, value: DealStateEnum.WaitingForTitle },
          { label: DealStateLabelMap.title_received, value: DealStateEnum.TitleReceived },
          { label: DealStateLabelMap.sent_to_processor, value: DealStateEnum.SentToProcessor },
        ];
      case DealStateEnum.SentToProcessor:
        return [
          { label: DealStateLabelMap.title_received, value: DealStateEnum.TitleReceived },
          { label: DealStateLabelMap.sent_to_processor, value: DealStateEnum.SentToProcessor },
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    setSelectedState({
      label: highlight
        ? DealStateLabelMap[deal.state].toUpperCase()
        : DealStateLabelMap[deal.state],
      value: deal.state,
    });
  }, []);

  useEffect(() => {
    let dsOptions: Option[] = [];
    if (canChangeStateToAnyPrevious && deal?.deal_dates?.dates) {
      const allDealStates = Object.values(DealStateEnum);

      let states = Object.keys(deal.deal_dates.dates) as DealStateEnum[];

      const nextState = getNextState();
      if (nextState && !states.includes(nextState)) {
        states.push(nextState);
      }

      const excludedStates = [
        DealStateEnum.Floor,
        DealStateEnum.StructuringInProgress,
        DealStateEnum.Booted,
      ];

      states = states
        .filter((state: DealStateEnum) => {
          return state && DealStateLabelMap[state] && !excludedStates.includes(state);
        })
        .sort((a, b) => allDealStates.indexOf(a) - allDealStates.indexOf(b));

      dsOptions = states.map((state) => ({
        label: DealStateLabelMap[state],
        value: state,
      }));
    } else {
      dsOptions = getTitlingDealStates();
    }
    setDealStateOptions(dsOptions);
  }, [deal]);

  const handleChange = async (newState: SingleValue<Option>) => {
    if (!deal.id) {
      return;
    }

    updateDealState({
      variables: {
        id: deal.id,
        state: newState?.value,
      },
    })
      .then(() => {
        setSelectedState(newState);
        toast.success('Deal state updated successfully');
      })
      .catch((error) => {
        logger.error('DealStateAutocomplete.tsx', '', null, error);
        toast.error(error?.message || 'Failed to update deal state. Please refresh the page.');
      });
  };

  return (
    <CustomReactSelect
      placeholder=""
      isClearable={false}
      options={dealStateOptions}
      value={selectedState}
      onChange={handleChange}
    />
  );
};

export default DealStateAutoComplete;
