/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo, useRef, useState } from 'react';

import { useSubscription } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { GoTriangleDown } from 'react-icons/go';
import Select, { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { AutoSizer, Table as VirtualizedTable } from 'react-virtualized';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import { Deal, onDealUpdate } from '../../gql/dealGql';
import {
  InboxFilterByEnum,
  InboxOrderByEnum,
  TeamType,
  useInboxQuery,
  useNotificationSeenMutation,
  useOnNewMessageSubscription,
  useOnNoDealNotificationSubscription,
  usePodsQuery,
} from '../../gql/generated/graphql';
import { Message, getGroupKey } from '../../gql/messageGql';
import { isDealStateRelevantForRole, isUserAssignedToDeal } from '../../gql/userGql';

import Pagination from '../Pagination';
import { GroupedOption, Option } from '../shared/types';
import MessageRow from './components/MessageRow';
import PhoneCallTable from './components/PhoneCallHistory';

import { PermissionEnum } from '../../constants/permissions';
import usePagination from '../../hooks/usePagination';
import { useUser } from '../../hooks/useUser';
import { useUserAbilityAndVisibleStates } from '../../hooks/useUserAbilityAndVisibleStates';
import { logger } from '../../libs/Logger';

const Inbox = () => {
  const user = useUser();

  const { loggedUserAbility, loggedUserPods, loggedUserSources } = useUserAbilityAndVisibleStates();
  const shouldShowMyLanesFilter = useMemo(
    () =>
      loggedUserAbility.has(PermissionEnum.Setter) ||
      loggedUserAbility.has(PermissionEnum.Closer) ||
      loggedUserAbility.has(PermissionEnum.FundingClerk) ||
      loggedUserAbility.has(PermissionEnum.TitleClerk) ||
      loggedUserAbility.has(PermissionEnum.SuperUser),
    [loggedUserAbility],
  );

  const paginationContext = usePagination();
  const [currentPage, setCurrentPage] = useState<number>();
  const [itemsPerPage, setItemsPerPage] = useState<number>();

  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);

  const defaultFilterBy = shouldShowMyLanesFilter
    ? InboxFilterByEnum.MyLanes
    : InboxFilterByEnum.YourMessages;
  const [previousWidth, setPreviousWidth] = useState<number>();
  const [filterBy, setFilterBy] = useState<InboxFilterByEnum>(defaultFilterBy);
  const [selectedFilterByOption] = useState<SingleValue<Option>>();
  const [podIds, setPodIds] = useState<string[] | null>(null);

  const defaultOrderBy = InboxOrderByEnum.DateNewest;
  const [orderBy, setOrderBy] = useState<InboxOrderByEnum>(defaultOrderBy);
  const [selectedOrderByOption] = useState<SingleValue<Option>>();
  const [unreadOnly, setUnreadOnly] = useState<boolean>(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const regularRowHeight = 64;
  const firstInGroupRowHeight = 88;
  const [previousFirstMessage, setPreviousFirstMessage] = useState<Message>();
  const [scrollTop, setScrollTop] = useState<number>();
  const tableRef = useRef<VirtualizedTable>(null);

  const salesPodsOptions = useMemo(
    () =>
      loggedUserPods.map((salesPod) => ({
        value: salesPod?.id?.toString() ?? '',
        label: salesPod?.name ?? '',
        group: InboxFilterByEnum.SalesPod,
      })),
    [loggedUserPods],
  );

  const [titlingPodsOptions, setTitlingPodsOptions] = useState<Option[]>([]);
  usePodsQuery({
    variables: { team_type: [TeamType.Titling] },
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast.error(`Couldn't load teams. Please refresh. Error: ${error.message}`);
    },
    onCompleted: (data) => {
      if (data?.pods) {
        setTitlingPodsOptions(
          data.pods.map((pod) => ({
            value: pod?.id?.toString() ?? '',
            label: pod?.name ?? '',
            group: InboxFilterByEnum.TitlePod,
          })),
        );
      }
    },
  });

  const { allSalesPodIds, allTitlingPodIds, filterByGroupedOptions } = useMemo(() => {
    const salesPodIds: string[] = [];
    const titlingPodIds: string[] = [];
    const options: (Option | GroupedOption)[] = [
      { label: 'All', value: InboxFilterByEnum.All },
      { label: 'All Your Messages', value: InboxFilterByEnum.YourMessages },
      { label: 'My Teams', value: InboxFilterByEnum.MyTeams },
    ];
    if (salesPodsOptions.length > 0) {
      salesPodIds.push(...salesPodsOptions.map((salesPodOption) => salesPodOption.value));
      options.push(
        { label: 'All Sales Pods', value: InboxFilterByEnum.AllSalesPods },
        {
          options: salesPodsOptions,
        },
      );
    }
    if (titlingPodsOptions.length > 0) {
      titlingPodIds.push(...titlingPodsOptions.map((titlingPodOption) => titlingPodOption.value));
      options.push(
        { label: 'All Title Teams', value: InboxFilterByEnum.AllTitlePods },
        {
          options: titlingPodsOptions,
        },
      );
    }
    if (shouldShowMyLanesFilter) {
      const myLanesOption = { label: 'My Lanes', value: InboxFilterByEnum.MyLanes };
      options.splice(2, 0, myLanesOption);
    }

    return {
      allSalesPodIds: salesPodIds,
      allTitlingPodIds: titlingPodIds,
      filterByGroupedOptions: options,
    };
  }, [salesPodsOptions, titlingPodsOptions]);

  const [notificationSeenMutation, { loading: notificationSeenMutationLoading }] =
    useNotificationSeenMutation();
  const { refetch: refetchInboxQuery } = useInboxQuery({
    variables: {
      filterBy,
      orderBy,
      unreadOnly,
      ...(podIds?.length ? { podIds: podIds.map((podId) => parseInt(podId, 10)) } : {}),
      itemsPerPage: itemsPerPage ?? 50,
      page: currentPage ?? 0,
    },
    onCompleted: (data) => {
      if (!data.inbox?.messages) {
        return;
      }

      setPreviousFirstMessage(messages[0]);
      const newMessages = data.inbox.messages as unknown as Message[];
      setMessages(newMessages);
      paginationContext.setTotalRecords(data.inbox.totalRecords ?? 0);
    },
    fetchPolicy: 'cache-and-network',
  });

  const shouldRefetch = (deals: Deal[]) =>
    filterBy === InboxFilterByEnum.All ||
    deals.some((deal) => {
      switch (filterBy) {
        case InboxFilterByEnum.YourMessages:
          return isUserAssignedToDeal(user, deal);
        case InboxFilterByEnum.SalesPod:
          return podIds?.includes(deal?.pod_id ?? '');
        case InboxFilterByEnum.TitlePod:
          return podIds?.includes(deal?.titling_pod_id ?? '');
        case InboxFilterByEnum.MyLanes:
          return isDealStateRelevantForRole(user, deal);
        case InboxFilterByEnum.AllSalesPods:
          return allSalesPodIds.includes(deal?.pod_id ?? '');
        case InboxFilterByEnum.AllTitlePods:
          return allTitlingPodIds.includes(deal?.titling_pod_id ?? '');
        case InboxFilterByEnum.MyTeams:
          return (
            allSalesPodIds.includes(deal?.pod_id ?? '') ||
            allTitlingPodIds.includes(deal?.titling_pod_id ?? '')
          );
        default:
          return true;
      }
    });

  useOnNewMessageSubscription({
    onData: ({ data }) => {
      if (!data.data?.onNewMessage) {
        return;
      }

      const deals = data.data.onNewMessage.deals as Deal[];
      if (shouldRefetch(deals)) {
        refetchInboxQuery();
      }
    },
  });

  useSubscription<{ onDealUpdate: Deal[] }>(onDealUpdate, {
    variables: { sources: loggedUserSources },
    onData: ({ data }) => {
      if (!data?.data?.onDealUpdate) {
        return;
      }

      const updatedDeals = data.data.onDealUpdate;
      const aMessageIncludesUpdatedDeal = messages.some((message) =>
        message.notifications?.some((notification) =>
          updatedDeals.some((updatedDeal) => updatedDeal.id === notification.deal?.id),
        ),
      );

      if (aMessageIncludesUpdatedDeal) {
        refetchInboxQuery();
      }
    },
  });

  useOnNoDealNotificationSubscription({
    onData: ({ data }) => {
      if (!data.data?.onNoDealNotification) {
        return;
      }

      const updatedNotification = data.data.onNoDealNotification;
      const aMessageIncludesUpdatedNotification = messages.some((message) =>
        message.notifications?.some(
          (messageNotification) => updatedNotification.id === messageNotification.id,
        ),
      );

      if (aMessageIncludesUpdatedNotification) {
        refetchInboxQuery();
      }
    },
  });

  const orderByOptions = [
    {
      value: InboxOrderByEnum.DateNewest,
      label: 'Date (Newest)',
    },
    {
      value: InboxOrderByEnum.DateOldest,
      label: 'Date (Oldest)',
    },
    {
      value: InboxOrderByEnum.Deal,
      label: 'Deal',
    },
  ];

  const messagesWithGroup = useMemo(() => {
    const existingGroupKeys = new Map<string, boolean>();

    return messages.map((message) => {
      const groupKey = getGroupKey(orderBy, message);
      const groupKeyAlreadyExists = existingGroupKeys.has(groupKey);

      const isFirstInGroup = !groupKeyAlreadyExists;
      if (!groupKeyAlreadyExists) {
        existingGroupKeys.set(groupKey, true);
      }

      return {
        ...message,
        groupKey,
        isFirstInGroup,
      };
    });
  }, [messages]);

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }

    // Fix wrong height of rows when changing filter or order
    tableRef.current.recomputeRowHeights();
  }, [messagesWithGroup]);

  useEffect(() => {
    const keepScrollTopPosition = () => {
      if (!previousFirstMessage) {
        return;
      }

      const previousFirstMessageIndex = messages.findIndex(
        (message) => message.id === previousFirstMessage.id,
      );
      if (!scrollTop || previousFirstMessageIndex === -1) {
        return;
      }

      setScrollTop(scrollTop + previousFirstMessageIndex * regularRowHeight);
    };

    keepScrollTopPosition();
  }, [previousFirstMessage]);

  const handleFilterByChange = async (
    newSelectedFilterBy: SingleValue<Option | GroupedOption | undefined>,
  ) => {
    setSelectedMessages([]);
    setScrollTop(0);

    const individualPodSelected =
      newSelectedFilterBy?.group === InboxFilterByEnum.SalesPod ||
      newSelectedFilterBy?.group === InboxFilterByEnum.TitlePod;
    const newFilterBy = (
      individualPodSelected ? newSelectedFilterBy?.group : newSelectedFilterBy?.value
    ) as InboxFilterByEnum;
    setFilterBy(newFilterBy);

    if (individualPodSelected) {
      setPodIds(newSelectedFilterBy?.value ? [newSelectedFilterBy?.value] : null);
    } else if (newSelectedFilterBy?.value === InboxFilterByEnum.AllSalesPods) {
      setPodIds(allSalesPodIds);
    } else if (newSelectedFilterBy?.value === InboxFilterByEnum.AllTitlePods) {
      setPodIds(allTitlingPodIds);
    } else {
      setPodIds(null);
    }
  };

  const handleOrderByChange = async (newSelectedOrderBy: SingleValue<Option | undefined>) => {
    setSelectedMessages([]);
    setScrollTop(0);

    setOrderBy(newSelectedOrderBy?.value as InboxOrderByEnum);
  };

  const isMessageSelected = (message: Message) =>
    selectedMessages.some(
      (selectedMessage) => selectedMessage.id && message.id && selectedMessage.id === message.id,
    );

  const handleSelectMessage = (message: Message) => {
    if (isMessageSelected(message)) {
      setSelectedMessages(
        selectedMessages.filter(
          (selectedMessage) =>
            selectedMessage.id && message.id && selectedMessage.id !== message.id,
        ),
      );
    } else {
      setSelectedMessages([...selectedMessages, message]);
    }
  };

  const handleMarkUnread = async () => {
    const notificationSeenPromises = selectedMessages
      .flatMap((message) =>
        message.notifications?.map((notification) => {
          const notificationDeal = notification.deal;

          const isMessageWithoutDeal = !notificationDeal;
          const userIsAssignedToDeal = isUserAssignedToDeal(user, notificationDeal);
          if (isMessageWithoutDeal || userIsAssignedToDeal) {
            return notificationSeenMutation({
              variables: {
                dealId: notificationDeal?.id,
                notificationId: notification.id,
                seen: false,
              },
            });
          }

          return null;
        }),
      )
      .filter((promise) => promise !== null) as ReturnType<typeof notificationSeenMutation>[];

    setSelectedMessages([]);
    if (notificationSeenPromises.length === 0) {
      toast.info('Cannot mark unread because you are not assigned to any of the deals');
      return;
    }

    try {
      await Promise.all(notificationSeenPromises);
      refetchInboxQuery();
    } catch (error) {
      logger.error('Inbox/index.tsx', 'Failed to mark unread', null, error);
      toast.error('Failed to mark unread');
    }
  };

  return (
    <Box mx={1}>
      <Flex direction="column">
        <Flex
          direction="column"
          px={4}
          py={4}
          backgroundColor="oxfordBlue"
          borderRadius="10px 10px 0 0"
        >
          <Heading as="h3" color="white" fontSize="20px" fontWeight="medium">
            Inbox
          </Heading>
        </Flex>
        <Box bgColor="queenBlue" borderRadius="0 0 10px 10px">
          <SimpleReactLightbox>
            <SRLWrapper>
              <Tabs
                index={selectedTabIndex}
                onChange={(index) => setSelectedTabIndex(index)}
                variant="enclosed"
              >
                <TabList pt="5px">
                  <Tab
                    bgColor={selectedTabIndex === 0 ? 'gray.100' : 'gray.400'}
                    color={selectedTabIndex === 0 ? 'black' : 'gray.600'}
                    fontWeight="bold"
                    ml={3}
                    borderBottom={0}
                    mb={0}
                  >
                    Text Messages
                  </Tab>
                  <Tab
                    bgColor={selectedTabIndex === 1 ? 'gray.100' : 'gray.400'}
                    color={selectedTabIndex === 1 ? 'black' : 'gray.600'}
                    fontWeight="bold"
                    ml={1.5}
                    borderBottom={0}
                    mb={0}
                  >
                    Phone Calls
                  </Tab>
                </TabList>
                <TabPanels bgColor="gray.100" borderRadius="0 0 10px 10px">
                  <TabPanel alignItems="center" p={0}>
                    <Flex justifyContent="space-between" px={3} py={2}>
                      <Box>
                        <Button
                          variant="secondary"
                          size="sm"
                          mr={2}
                          isDisabled={selectedMessages.length === 0}
                          isLoading={notificationSeenMutationLoading}
                          loadingText="MARK UNREAD"
                          onClick={handleMarkUnread}
                        >
                          MARK UNREAD
                        </Button>
                      </Box>
                      <Flex alignItems="center" gap={3}>
                        <Checkbox
                          mb={0}
                          isChecked={unreadOnly}
                          onChange={() => setUnreadOnly(!unreadOnly)}
                        >
                          Unread Only
                        </Checkbox>
                        <Box>
                          <Select
                            components={{
                              DropdownIndicator: () => (
                                <Box color="primary" mx={2}>
                                  <GoTriangleDown size={20} />
                                </Box>
                              ),
                            }}
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                minWidth: '170px',
                              }),
                            }}
                            defaultValue={orderByOptions.filter(
                              (option) => option.value === defaultOrderBy,
                            )}
                            value={selectedOrderByOption}
                            onChange={handleOrderByChange}
                            options={orderByOptions}
                          />
                        </Box>
                        <Box>
                          <Select
                            components={{
                              DropdownIndicator: () => (
                                <Box color="primary" mx={2}>
                                  <GoTriangleDown size={20} />
                                </Box>
                              ),
                            }}
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                minWidth: '200px',
                              }),
                            }}
                            defaultValue={
                              filterByGroupedOptions.filter(
                                (option) => option.value === defaultFilterBy,
                              ) as unknown as Option
                            }
                            value={selectedFilterByOption}
                            onChange={handleFilterByChange}
                            options={filterByGroupedOptions}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                    <Box
                      bgColor="white"
                      border="2px"
                      borderColor="lightGray"
                      borderBottomRadius="10px"
                    >
                      {/* @ts-ignore */}
                      <AutoSizer disableHeight>
                        {({ width }) => {
                          // Makes the table responsive. recomputeRowHeights modifies the width too
                          if (width !== previousWidth && tableRef.current) {
                            tableRef.current.recomputeRowHeights();
                          }
                          const widthMinusPadding = width - 4;
                          setPreviousWidth(widthMinusPadding);

                          return (
                            // @ts-ignore
                            <VirtualizedTable
                              ref={tableRef}
                              width={widthMinusPadding}
                              height={500}
                              disableHeader
                              rowHeight={({ index }) =>
                                // eslint-disable-next-line security/detect-object-injection
                                messagesWithGroup[index].isFirstInGroup
                                  ? firstInGroupRowHeight
                                  : regularRowHeight
                              }
                              scrollTop={scrollTop}
                              onScroll={({ scrollTop: currentScrollTop }) =>
                                setScrollTop(currentScrollTop)
                              }
                              headerHeight={0}
                              overscanRowCount={10}
                              rowCount={messagesWithGroup.length}
                              // eslint-disable-next-line security/detect-object-injection
                              rowGetter={({ index }) => messagesWithGroup[index]}
                              rowRenderer={({ key, style, rowData }) => {
                                const message = rowData as Message;
                                const deals = message.notifications?.length
                                  ? (message.notifications
                                      ?.map(({ deal }) => deal)
                                      .filter((deal) => !!deal) as Deal[])
                                  : [];
                                const isSelected = isMessageSelected(message);

                                return (
                                  <MessageRow
                                    key={key}
                                    style={style}
                                    deals={deals}
                                    message={message}
                                    isSelected={isSelected}
                                    handleSelectMessage={handleSelectMessage}
                                    refetchInboxQuery={refetchInboxQuery}
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      </AutoSizer>
                      <Pagination
                        filters={{
                          currentPage: currentPage ?? 0,
                          itemsPerPage: itemsPerPage ?? 50,
                        }}
                        parentSetCurrentPage={setCurrentPage}
                        parentSetItemsPerPage={setItemsPerPage}
                        numItemsShown={messagesWithGroup?.length}
                        paginationContext={paginationContext}
                        itemsPerPageOptions={[50, 100, 250, 500]}
                        borderTop="1px solid"
                        borderColor="lightGray"
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel px={0} pb={0}>
                    <PhoneCallTable />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </SRLWrapper>
          </SimpleReactLightbox>
        </Box>
      </Flex>
    </Box>
  );
};

export default Inbox;
