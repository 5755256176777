import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, dealInfoUpsert } from '../../../../gql/dealGql';

import { PermissionEnum } from '../../../../constants/permissions';
import { Can } from '../../../../libs/Can';
import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';

interface SaveEditButtonProps extends ButtonProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

const SaveEditButton: React.FC<SaveEditButtonProps> = ({ isEditing, setIsEditing, ...rest }) => {
  const { dispatch, isRecalculatingPayoff } = useContext(DealContext);

  const { values } = useFormikContext<Deal>();
  const [isSaving, setSaving] = useState<boolean>(false);
  const [upsertDealInfo] = useMutation(dealInfoUpsert);
  const { dealRefetch } = useContext(DealContext);

  const onSave = (deal: Deal) => {
    upsertDealInfo({
      variables: {
        financialInfo: deal.financial_info,
        car: deal.car,
      },
    })
      .then(({ data }) => {
        dispatch({ type: DealActionsEnum.UpdateDeal, payload: data.dealInfoUpsert });
        toast.success('Deal saved');
      })
      .catch((e) => logger.error('SaveEditButton.tsx', '', null, e))
      .finally(() => {
        setIsEditing(false);
        setSaving(false);
        dealRefetch();
      });
  };

  return (
    <Can I={PermissionEnum.EditDeal}>
      <Button
        variant="secondary"
        isLoading={isSaving}
        isDisabled={isRecalculatingPayoff}
        onClick={() => {
          onSave(values);
        }}
        hidden={values.state !== DealStateEnum.Closed || !isEditing}
        size="sm"
        leftIcon={<FaEdit fontSize="md" />}
        w="70px"
        {...rest}
      >
        SAVE
      </Button>
    </Can>
  );
};

export default SaveEditButton;
