import React, { MouseEventHandler } from 'react';
import { Button, VStack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../shared/Modal';

import { FailPayoffRequestReasonEnum, PayoffRequest } from '../../../../gql/payoffRequestGql';

import Select from '../../../shared/Select';
import TextArea from '../../../shared/TextArea';

import SELECT_OPTIONS from '../../../../constants/selectOptions';
import { passValuesToSchema } from '../../../../libs/utils';
import { getFullName } from '../../../../gql/customerGql';

export interface FormValues {
  reason: FailPayoffRequestReasonEnum | string;
  comment: string;
}

const MAX_COMMENT_LENGTH = 150;
const validationSchema = Yup.object().shape({
  reason: Yup.string()
    .required('Reason is required')
    .oneOf(Object.values(FailPayoffRequestReasonEnum), 'Invalid Reason'),
  comment: Yup.string().max(MAX_COMMENT_LENGTH).required('Comment is required'),
});

interface Props {
  isOpen: boolean;
  action: 'Request' | 'Confirm';
  loading: boolean;
  onSubmit: (fail_reason: FormValues) => Promise<void>;
  onClose: () => void;
  payoffRequest?: PayoffRequest;
}

const PayoffFailureForm: React.FC<Props> = ({
  isOpen,
  action,
  loading,
  onSubmit,
  onClose,
  payoffRequest,
}) => {
  const initialValues = {
    reason: payoffRequest?.fail_reason?.reason || '',
    comment: payoffRequest?.fail_reason?.comment || '',
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={(value) => passValuesToSchema(value, validationSchema)}
      validateOnMount
    >
      {({ isValid, handleChange, handleSubmit }) => (
        <Modal
          title={` ${action} Payoff Failure
              ${
                payoffRequest?.deal?.customer
                  ? ` for ${getFullName(payoffRequest?.deal?.customer)}`
                  : ` for ${payoffRequest?.temporary_info?.data?.first_name} ${payoffRequest?.temporary_info?.data?.last_name}`
              }`}
          isOpen={isOpen}
          onClose={onClose}
          size="xl"
          rightButtons={
            <>
              <Button variant="warning" isDisabled={loading} onClick={onClose}>
                CANCEL
              </Button>
              <Button
                variant="boot"
                type="submit"
                isDisabled={!isValid}
                isLoading={loading}
                loadingText={`${action.toUpperCase()} PAYOFF FAILURE`}
                onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
              >
                {action.toUpperCase()} PAYOFF FAILURE
              </Button>
            </>
          }
        >
          <Form>
            <VStack spacing={4} align="flex-start">
              <Select
                label="Reason"
                placeholder=""
                id="reason"
                name="reason"
                options={SELECT_OPTIONS.FAIL_PAYOFF_REQUEST_REASONS}
                isRequired
                onChange={handleChange}
              />
              <TextArea
                as="textarea"
                label="Comment"
                placeholder="Comment"
                id="comment"
                name="comment"
                maxLength={MAX_COMMENT_LENGTH}
                rows={3}
                isRequired
                onChange={handleChange}
              />
            </VStack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default PayoffFailureForm;
