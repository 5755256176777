import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { baseStyle } from './input';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const input = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'sm',
  },
});

export default input;
