export const getName = (name: string, opts?: { firstInitial?: boolean; lastInitial?: boolean }) => {
  if (!name || !opts) {
    return name;
  }

  const formattedName = [];
  const splitName = name.split(' ');
  if (splitName.length === 1) {
    return splitName[0];
  }

  const firstName = splitName[0];
  if (opts.firstInitial) {
    formattedName.push(firstName.charAt(0));
  } else {
    formattedName.push(firstName);
  }

  const lastName = splitName[splitName.length - 1];
  if (opts.lastInitial) {
    formattedName.push(lastName.charAt(0));
  } else {
    formattedName.push(lastName);
  }

  return formattedName.join(' ');
};
