import React, { MouseEvent, useContext } from 'react';

import { Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';

import {
  PayoffRequestStatus,
  usePayoffRequestFailMutation,
} from '../../../../gql/generated/graphql';
import { PayoffRequest } from '../../../../gql/payoffRequestGql';

import PayoffFailureForm, { FormValues } from './PayoffFailureForm';

import { PermissionEnum } from '../../../../constants/permissions';
import { AbilityContext } from '../../../../libs/contextLib';

interface Props {
  buttonType?: 'icon' | 'button';
  payoffRequest?: PayoffRequest;
}

const ConfirmPayoffFailure: React.FC<Props> = ({ buttonType = 'icon', payoffRequest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const abilities = useContext(AbilityContext);

  const [payoffRequestFail, { loading }] = usePayoffRequestFailMutation();

  const handleSubmit = async (fail_reason: FormValues) => {
    try {
      if (!payoffRequest?.id) {
        throw new Error();
      }

      const updatedPayoffRequest = {
        id: payoffRequest?.id,
        fail_reason,
      };

      await payoffRequestFail({
        variables: {
          payoffRequest: updatedPayoffRequest,
        },
      });

      onClose();
      toast.success('Payoff Request failed successfully');
    } catch (error) {
      toast.error('Could not fail Payoff Request');
    }
  };

  return (
    <>
      {buttonType === 'icon' ? (
        <IconButton
          icon={<BiTrash />}
          variant="iconHover"
          size="sm"
          color="errorsRed"
          aria-label="Delete"
          isLoading={loading}
          isDisabled={!abilities.has(PermissionEnum.ConfirmPayoffFailure)}
          visibility={
            payoffRequest?.status === PayoffRequestStatus.FailureRequested ? 'visible' : 'hidden'
          }
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            onOpen();
          }}
        />
      ) : (
        <Button
          size="sm"
          variant="warning"
          width="75px"
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          FAIL
        </Button>
      )}

      {payoffRequest && (
        <PayoffFailureForm
          isOpen={isOpen}
          action="Confirm"
          loading={loading}
          onSubmit={handleSubmit}
          onClose={onClose}
          payoffRequest={payoffRequest}
        />
      )}
    </>
  );
};

export default ConfirmPayoffFailure;
