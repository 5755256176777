export default Object.freeze({
  HOME: '/',
  DASHBOARD: '/dashboard',
  COM_REVIEWS: '/reviews',
  NEW_ESTIMATE: '/estimates/new',
  DEAL_SHOW: '/deals/:id',
  TITLING_DEAL_SHOW: '/deals/:id/titling',
  REVIVE_BOOT: '/revive-boot',
  COMPLETED_DEALS: '/completed-deals',
  FOLLOW_UPS: '/follow-ups',
  TEAMS: '/teams',
  TRANSCRIPTIONS: '/transcriptions',
  PRESS_PAGE_MANAGER: '/press-page-manager',
  METRICS: '/metrics',
  SCHEDULE_FOLLOW_UP: '/estimates/schedule-follow-up',
  STRUCTURING_FOLLOW_UPS: '/structuring-follow-ups',
});
