import { FC, useContext, useState } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { File } from '../../gql/contractGql';
import {
  DocumentProgressStatus,
  UploadDocumentToRouteOneMutationVariables,
  useRetrieveDealDocsUploadedToR1Query,
  useSetReadyForSignaturesStatusMutation,
  useUploadDocumentToRouteOneMutation,
} from '../../gql/generated/graphql';

import DocumentsTable from '../DocumentsTable/DocumentsTable';
import { parseFilename } from '../MediaCenter/utils';
import PDFViewer from '../PDFViewer.tsx';
import Modal from '../shared/Modal';

import { PermissionEnum } from '../../constants/permissions';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { AbilityContext } from '../../libs/contextLib';
import {
  isDocumentProgressSent,
  isDocumentProgressUploaded,
  isDocumentProgressValidated,
} from '../../utils/routeOne';

interface SignatureUploadModalButtonProps {
  width?: string;
  inMediaCenter?: boolean;
}

const SignatureUploadModalButton: FC<SignatureUploadModalButtonProps> = ({
  width,
  inMediaCenter,
}) => {
  const {
    deal,
    dispatch,
    deal: { r1_jacket_id },
    documentMedia: { signatureFileList },
  } = useContext(DealContext);
  const abilities = useContext(AbilityContext);

  const [selectedFilesIndexes, setSelectedFilesIndexes] = useState<number[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [numDocsInR1, setNumDocsInR1] = useState<number>(0);
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  // isUploading instead of the mutation loading state because is uploading individual files
  const [isUploading, setIsUploading] = useState(false);

  const isDisabled =
    (!isDocumentProgressValidated(deal.document_progress_status) ||
      !isDocumentProgressUploaded(deal.document_progress_status) ||
      isDocumentProgressSent(deal.document_progress_status)) &&
    !abilities.has(PermissionEnum.SuperUser) &&
    !abilities.has(PermissionEnum.FundingManager);
  const isHidden = inMediaCenter;
  const buttonText = inMediaCenter ? 'UPLOAD FOR SIGNATURES' : 'UPLOAD';
  const buttonVariant = inMediaCenter ? 'secondaryDarkBg' : 'secondary';

  const [uploadDocToR1] = useUploadDocumentToRouteOneMutation();
  const [setReadyForSignaturesStatus] = useSetReadyForSignaturesStatusMutation();

  // Check to see how many documents are in R1
  const { loading: retrievingDocsUploadedToR1 } = useRetrieveDealDocsUploadedToR1Query({
    skip: !isOpen,
    variables: { dealJacketID: r1_jacket_id ?? '' },
    fetchPolicy: 'no-cache',
    onCompleted(newData) {
      // don't count the credit app or E-Contract because they are R1 generated
      setNumDocsInR1(
        newData?.retrieveDealDocsUploadedToR1?.filter((doc) => doc?.documentType === 'Other')
          .length ?? 0,
      );
    },
    onError: (error) => {
      logger.error('DocumentsTable.tsx', '', signatureFileList, error);
    },
  });

  const handleUpload = async () => {
    onClose();
    setIsUploading(true);

    const failedFileIndexes: number[] = [];

    const docPromises = signatureFileList
      .filter((_, index) => selectedFilesIndexes.includes(index))
      .map(async (m, selectedFileIndex) => {
        const { friendlyName } = parseFilename(m.key);
        const variables: UploadDocumentToRouteOneMutationVariables = {
          jacketId: deal.r1_jacket_id,
          doc: {
            key: m.key,
            name: friendlyName,
          },
          withCobuyer: !!deal.cobuyer,
        };

        try {
          const result = await uploadDocToR1({
            variables,
          });

          if (result.data?.uploadDocumentToRouteOne) {
            toast.success(`${friendlyName} uploaded successfully`);
          } else {
            failedFileIndexes.push(selectedFilesIndexes[selectedFileIndex]);
            logger.error(
              'SignatureUpload.tsx',
              'uploadDocumentToRouteOne',
              variables,
              'Unknown error',
            );
            toast.error(`${friendlyName} failed to upload: Unknown error. Please try again.`, {
              autoClose: false,
            });
            throw new Error('Unknown error');
          }
        } catch (e) {
          failedFileIndexes.push(selectedFilesIndexes[selectedFileIndex]);
          logger.error('SignatureUpload.tsx', 'uploadDocumentToRouteOne', variables, e);
          const error = e as Error;
          toast.error(`${friendlyName} failed to upload: ${error.message}.  Please try again.`, {
            autoClose: false,
          });
          throw Error(error.message);
        }
      });

    const results = await Promise.allSettled(docPromises);
    const someDocsUploaded = results.some((r) => r.status === 'fulfilled');
    if (someDocsUploaded) {
      const result = await setReadyForSignaturesStatus({
        variables: {
          jacketId: deal.r1_jacket_id,
          documentProgressStatus: deal.document_progress_status,
        },
      });
      if (result.data?.setReadyForSignaturesStatus) {
        dispatch({
          type: DealActionsEnum.UpdateDeal,
          payload: { document_progress_status: DocumentProgressStatus.ReadyForSignatures },
        });
      }
    }

    setSelectedFilesIndexes(failedFileIndexes);
    setIsUploading(false);
  };

  const handleOpenFile = (file: File) => {
    setFileUrl(file.url);
    setFileName(file.filename);
    setShowPreview(true);
  };

  if (isHidden) {
    return null;
  }

  return (
    <>
      <Button
        variant={buttonVariant}
        size="sm"
        w={width}
        onClick={onOpen}
        isDisabled={isDisabled}
        isLoading={isUploading}
        loadingText={buttonText}
      >
        {buttonText}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        title="Upload For Signatures"
        leftButtons={
          <Button variant="warning" onClick={onClose}>
            CANCEL
          </Button>
        }
        rightButtons={
          <Button
            isDisabled={
              (r1_jacket_id && retrievingDocsUploadedToR1) || selectedFilesIndexes.length === 0
            }
            onClick={handleUpload}
          >
            {retrievingDocsUploadedToR1
              ? 'FETCHING'
              : `UPLOAD (${selectedFilesIndexes.length}) DOCUMENTS`}
          </Button>
        }
      >
        <DocumentsTable
          infoText="Select the documents you would like to upload for digital signing."
          files={signatureFileList}
          selectedFilesIndexes={selectedFilesIndexes}
          setSelectedFilesIndexes={setSelectedFilesIndexes}
          onOpenFile={handleOpenFile}
          modal="upload"
          numDocsInR1={numDocsInR1}
        />
      </Modal>

      <Modal
        isOpen={showPreview}
        onClose={() => setShowPreview(false)}
        closeOnEsc={false}
        title={`Previewing: ${fileName}`}
        size="full"
      >
        <PDFViewer fileUrl={fileUrl} />
      </Modal>
    </>
  );
};

export default SignatureUploadModalButton;
