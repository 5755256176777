import { FC, useEffect, useState } from 'react';

import { HStack } from '@chakra-ui/react';
import { isSameDay } from 'date-fns';
import { useFormikContext } from 'formik';

import { FollowUpTypeEnum } from '../../gql/generated/graphql';

import DatePicker from '../shared/DatePicker';
import Select from '../shared/Select';
import TextArea from '../shared/TextArea';

import { FollowUpFormType } from './validationSchema';

interface FollowUpFormProps {
  setTypeAvailable?: boolean;
  needsNamePrefix?: boolean;
}

const FollowUpForm: FC<FollowUpFormProps> = ({
  setTypeAvailable = true,
  needsNamePrefix = false,
}) => {
  const namePrefix = needsNamePrefix ? 'follow_up.' : '';

  const { values: formikValues } = useFormikContext<
    FollowUpFormType | { follow_up: FollowUpFormType }
  >();
  const values = (
    needsNamePrefix && 'follow_up' in formikValues ? formikValues.follow_up : formikValues
  ) as FollowUpFormType;

  const currentDate = new Date();
  const [minTime, setMinTime] = useState<Date | undefined>(undefined);

  useEffect(() => {
    const minHour = currentDate.getHours();
    const minMinute = currentDate.getMinutes();
    const isToday = values.date ? isSameDay(currentDate, values.date) : false;

    if (isToday) {
      setMinTime(new Date(0, 0, 0, minHour, minMinute));
    } else {
      setMinTime(undefined);
    }
  }, [values.date]);

  return (
    <>
      <HStack w="100%" mt={3} alignItems="start">
        <DatePicker
          topLabel="Date"
          name={`${namePrefix}date`}
          minDate={currentDate}
          boxStyles={{ width: '100%' }}
        />
        <DatePicker
          topLabel="Time"
          name={`${namePrefix}time`}
          dateFormat="h:mm aa"
          timeFormat="h:mm aa"
          minTime={minTime}
          maxTime={minTime ? new Date(0, 0, 0, 23, 59) : undefined}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption=""
          boxStyles={{ width: '100%' }}
        />
      </HStack>
      {setTypeAvailable ? (
        <HStack w="100%" mt={3} alignItems="start">
          <Select
            name={`${namePrefix}type`}
            label="Type"
            emptyOption={false}
            options={[
              { value: FollowUpTypeEnum.Call, label: 'Call' },
              { value: FollowUpTypeEnum.Text, label: 'Text' },
              { value: FollowUpTypeEnum.Email, label: 'Email' },
              { value: FollowUpTypeEnum.SendContracts, label: 'Send Contracts' },
            ]}
          />
        </HStack>
      ) : null}
      <HStack w="100%" mt={3} alignItems="start">
        <TextArea
          name={`${namePrefix}note`}
          label="Note"
          minH="6em"
          maxH="12em"
          resize="vertical"
          maxLength={300}
        />
      </HStack>
    </>
  );
};

export default FollowUpForm;
