import { FC } from 'react';

import { Button } from '@chakra-ui/react';

import Modal from '../../../shared/Modal';

interface Props {
  handleOk: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const ContractNotFoundModal: FC<Props> = ({ handleOk, isOpen, onClose }) => {
  return (
    <Modal
      title="Contract Not Found"
      isOpen={isOpen}
      onClose={onClose}
      canDismiss={false}
      showDivider={false}
      rightButtons={<Button onClick={handleOk}>OK</Button>}
    >
      No contract found in Route One. Ensure contract is generated before sending to web or select
      signing on Route One.
    </Modal>
  );
};

export default ContractNotFoundModal;
