import { useContext, useState } from 'react';

import { Box, Flex, Progress } from '@chakra-ui/react';

import { DealStateEnum } from '../../../gql/dealGql';

import SendWithAdobeSign from '../../../components/DealInfoBuyout/components/buttons/SendWithAdobeSign';
import GenerateDocumentsButton from '../../../components/GenerateDocs/GenerateDocumentsButton';
import Card from '../../../components/shared/Card';
import CardHeaderV2 from '../../../components/shared/Card/components/CardHeaderV2';
import MoveToButton from '../ProgressionButtons/MoveToButton';

import { NonButtonStep } from './NonButtonStep';
import TimelineIcon from './TimelineIcon';

import { DealContext } from '../../../libs/DealContext';

const AdobeContractStatusTimeline = () => {
  const { deal, documentMedia } = useContext(DealContext);

  const width = '100px';

  const generatedAllDocs = documentMedia?.internalMediaList?.every((item) => !!item.id);

  const [steps] = useState([1, 2, 3, 4]);

  const getStep = () => {
    if (deal.state === DealStateEnum.Signed) {
      return 4;
    }
    if (deal.state === DealStateEnum.SentForSignatures) {
      return 3;
    }
    if (generatedAllDocs) {
      return 2;
    }

    return 1;
  };

  return (
    <Card variant="rounded" pb={0}>
      <CardHeaderV2 title="Contract Status" variant="rounded" pod={deal.pod} showPodColor />
      <Flex overflowX="auto">
        <Box minW="580px" mb={4} w="full">
          <Flex margin={5} justifyContent="space-between">
            <GenerateDocumentsButton useMiniButton width={width} />
            <SendWithAdobeSign width={width} inContractStatusTimeline />
            <NonButtonStep width={width}>SIGNING</NonButtonStep>
            <MoveToButton newState={DealStateEnum.Signed} width={width} inContractStatusTimeline />
          </Flex>
          <Flex
            marginX="60px"
            marginY="0px"
            justifyContent="space-between"
            alignItems="center"
            height={8}
            textAlign="center"
          >
            {steps.map((step) => (
              <TimelineIcon
                key={step}
                position={step}
                step={getStep()}
                lastStep={steps[steps.length - 1]}
              />
            ))}
          </Flex>
          <Progress
            margin="-18px 60px 25px 65px"
            height="5px"
            colorScheme="caribbeanGreen"
            bgColor="black"
            value={(getStep() - 1) * (100 / 3)} // Each step is 1/3 of the progress bar
          />
        </Box>
      </Flex>
    </Card>
  );
};

export default AdobeContractStatusTimeline;
