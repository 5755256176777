import { useContext, useEffect, useMemo, useState } from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Box, Button, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { Col, Row } from 'react-bootstrap';
import { MdAssignment, MdKeyboardArrowLeft } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, estimateUpsert, isDealInOrPastFunded } from '../../gql/dealGql';
import { DealStates } from '../../gql/dealStatesGql';
import { ProcessorEnum, mapTTProcessorToProcessor } from '../../gql/financialInfoGql';
import {
  DealType,
  PaperworkType,
  StateAbbreviation,
  TtJurisdiction,
  useJurisdictionProcessorQuery,
} from '../../gql/generated/graphql';
import { isUserAssignedTitleTeamLead, isUserAssignedToDeal } from '../../gql/userGql';

import CommunicationHistory from '../../components/CommunicationHistory';
import LeaseEndContainer from '../../components/Container';
import CreditApplication from '../../components/CreditApplication/CreditApplication';
import CustomerInfo from '../../components/CustomerInfo';
import CustomerInfoAcquisition from '../../components/CustomerInfoAcquisition';
import DealInfo from '../../components/DealInfo/DealInfo';
import DealInfoAcquisition from '../../components/DealInfoAcquisition/DealInfoAcquisition';
import DealInfoBuyout from '../../components/DealInfoBuyout/DealInfoBuyout';
import DealIssuesModal from '../../components/DealIssuesModal';
import EstimateForm from '../../components/EstimateForm';
import MediaCenter from '../../components/MediaCenter';
import PaymentEstimator from '../../components/PaymentEstimator/PaymentEstimator';
import PayoffInfo from '../../components/PayoffInfo/PayoffInfo';
import RequestBoot from '../../components/RequestBoot';
import TagInfo from '../../components/TagInfo/TagInfo';
import BootAction from '../../components/Task/TaskActions/BootAction';
import ReviveBootAction from '../../components/Task/TaskActions/ReviveBootAction';
import UnwindDeal from '../../components/UnwindDeal/UnwindDeal';
import UnwindDealToSet, {
  stateIsUnwindableToSet,
} from '../../components/UnwindDeal/UnwindDealToSet';
import VehicleInfo from '../../components/VehicleInfo';
import Card from '../../components/shared/Card';
import CardHeaderV2 from '../../components/shared/Card/components/CardHeaderV2';

import { BootBox, ChangeDealStateSelect } from './ChangeDealStateSelect';
import ChangeDealTypeButton from './ChangeDealTypeButton';
import AdobeContractStatusTimeline from './ContractStatusTimeline/AdobeContractStatusTimeline';
import ContractStatusTimeline from './ContractStatusTimeline/ContractStatusTimeline';
import DealStatesModal from './DealStatesModal';
import BackButton from './ProgressionButtons/BackButton';
import ClaimButton from './ProgressionButtons/ClaimButton';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import useGenerateBookSheets from '../../hooks/useGenerateBookSheets';
import { useLienholder } from '../../hooks/useLienholder';
import usePageTitle from '../../hooks/usePageTitle';
import { useUser } from '../../hooks/useUser';
import { Can } from '../../libs/Can';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { AbilityContext } from '../../libs/contextLib';
import Notes from '../../providers/Notes';
import { canSetProcessor, cleanDealForEstimateUpsert } from '../../utils/deals';
import { canBankUseR1 } from '../../utils/financialInfos';

const CARFAX_URL = 'https://www.carfaxbig.com/report/vhr?vin=';

const determineCenterBoxByPreviousState = (
  previousState: string | undefined,
  deal: Deal,
  onSaveEstimate: (estimate: Deal, setSubmitting: (isSubmitting: boolean) => void) => void,
  dealRefetch: () => Promise<ApolloQueryResult<Deal>>,
  jurisdiction: TtJurisdiction,
  supportedProcessors: ProcessorEnum[],
) => {
  if (previousState === DealStateEnum.Estimate) {
    return deal.customer?.first_name ? (
      <Card variant="roundedWithBorder">
        <CardHeaderV2 title="Edit Estimate" showPodColor pod={deal.pod}>
          <DealStatesModal />
        </CardHeaderV2>
        <Box>
          <EstimateForm estimate={deal} onSave={onSaveEstimate} />
        </Box>
      </Card>
    ) : null;
  }

  if (previousState === DealStateEnum.SoftClose) {
    return (
      <>
        <PaymentEstimator />
        <CreditApplication />
      </>
    );
  }

  return (
    <DealInfoBuyout
      jurisdiction={jurisdiction}
      refetch={dealRefetch}
      supportedProcessors={supportedProcessors}
    />
  );
};

const DealDetail = () => {
  const user = useUser();

  const { deal, dispatch, dealRefetch, isLoading, jurisdiction } = useContext(DealContext);
  const abilities = useContext(AbilityContext);

  usePageTitle({ deal });

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const urlIdParamChanged = deal.id && deal.id.toString() !== id;
    const forceReload = () => history.go(0);

    if (urlIdParamChanged) {
      forceReload();
    }
  }, [deal.id, id, history]);

  const [upsertEstimate] = useMutation<{ estimateUpsert: Deal }>(estimateUpsert);
  const { generateBookSheets } = useGenerateBookSheets();

  const { data } = useJurisdictionProcessorQuery({
    variables: {
      state: deal?.customer?.address?.state as StateAbbreviation,
    },
    skip: !deal?.customer?.address?.state || !canSetProcessor(deal?.state),
    fetchPolicy: 'network-only',
  });

  const supportedProcessors = useMemo(() => {
    if (data?.jurisdiction?.supportedProcessors?.length) {
      return data.jurisdiction.supportedProcessors
        .map(mapTTProcessorToProcessor)
        .filter(Boolean) as ProcessorEnum[];
    }
    return [];
  }, [data?.jurisdiction?.supportedProcessors]);

  const { doubleTax, directPayState } = useLienholder({ data: deal });
  const [showIssuesIfPresent, setShowIssuesIfPresent] = useState(true);

  const { canEditNotes, canPinNotes, canEditDealTypeAcquisition } = useMemo(
    () => ({
      canEditNotes: abilities.has(PermissionEnum.EditNotes),
      canPinNotes: abilities.has(PermissionEnum.PinNotes),
      canEditDealTypeAcquisition: abilities.has(PermissionEnum.EditDealTypeAcquisition),
    }),
    [abilities],
  );
  const { isAssignedToDeal, isAssignedTitleTeamLead } = useMemo(
    () => ({
      isAssignedToDeal: isUserAssignedToDeal(user, deal),
      isAssignedTitleTeamLead: isUserAssignedTitleTeamLead(user, deal),
    }),
    [user, deal],
  );

  const canManagePins = useMemo(
    () => canPinNotes || isAssignedToDeal || isAssignedTitleTeamLead,
    [canPinNotes, isAssignedToDeal, isAssignedTitleTeamLead],
  );

  const canEditNote = useMemo(
    () => canEditNotes || isAssignedTitleTeamLead,
    [canEditNotes, isAssignedTitleTeamLead],
  );

  const onSaveEstimate = async (estimate: Deal, setSubmitting: (isSubmitting: boolean) => void) => {
    const updatedDeal = cleanDealForEstimateUpsert(estimate);

    try {
      const res = await upsertEstimate({
        variables: {
          deal: {
            ...updatedDeal,
            car: {
              ...updatedDeal.car,
              registration_expiration: updatedDeal.car?.registration_expiration || null,
            },
            referral_source: updatedDeal.referral_source?.source_name
              ? updatedDeal.referral_source
              : null,
          },
        },
      });

      if (!res.data?.estimateUpsert) {
        throw new Error('No data returned');
      }

      const { estimateUpsert: dealFromAPI } = res.data;
      const { id: dealId, state } = dealFromAPI;

      if (state === DealStateEnum.SoftClose || state === DealStateEnum.Floor) {
        await generateBookSheets({
          dealId,
          customerState: deal.customer.address?.state,
        });
      }

      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: dealFromAPI as Partial<Deal>,
      });

      if (state === DealStateEnum.Floor) {
        history.push(ROUTES.DASHBOARD);
      }
    } catch (e) {
      const error = e as Error;
      logger.error('DealDetail.tsx', 'onSaveEstimate', null, error);
      toast.error(
        `Error saving Estimate: ${error.message || error}. Please refresh and try again.`,
      );
    } finally {
      setSubmitting(false);
    }
  };

  const renderCenterBox = () => {
    switch (deal.state) {
      case DealStateEnum.Estimate: {
        return deal.customer?.first_name ? (
          <>
            <Card variant="roundedWithBorder" mb={3}>
              <CardHeaderV2 title="Edit Estimate" showPodColor pod={deal.pod}>
                <DealStatesModal />
              </CardHeaderV2>
              <Box>
                <EstimateForm estimate={deal} onSave={onSaveEstimate} />
              </Box>
            </Card>
          </>
        ) : null;
      }
      case DealStateEnum.SoftClose: {
        return (
          <>
            <PaymentEstimator />
            <CreditApplication />
          </>
        );
      }
      case DealStateEnum.Booted: {
        const dealStates = deal.deal_states;
        dealStates.sort((a: DealStates, b: DealStates) => {
          const aDate = a?.updated_date_utc || '';
          const bDate = b?.updated_date_utc || '';
          if (new Date(aDate) > new Date(bDate)) {
            return -1;
          }
          if (new Date(aDate) < new Date(bDate)) {
            return 1;
          }
          return 0;
        });
        const previousDealState = dealStates.find(
          (ds) => [DealStateEnum.Booted].indexOf(ds.state) === -1,
        );
        return determineCenterBoxByPreviousState(
          previousDealState?.state,
          deal,
          onSaveEstimate,
          dealRefetch,
          jurisdiction,
          supportedProcessors,
        );
      }
      default: {
        return (
          <DealInfoBuyout
            jurisdiction={jurisdiction}
            refetch={dealRefetch}
            supportedProcessors={supportedProcessors}
          />
        );
      }
    }
  };

  const renderContractStatusTimeline = () => {
    const shouldRenderSomeContractTimeline =
      (deal.type === DealType.Buyout || deal.type === DealType.Refi) &&
      deal.paperwork_type === PaperworkType.Esign &&
      [DealStateEnum.Closed, DealStateEnum.SentForSignatures, DealStateEnum.Signed].includes(
        deal.state,
      );
    if (!shouldRenderSomeContractTimeline) {
      return null;
    }

    const shouldRenderAdobeContractTimeline = !canBankUseR1(deal.financial_info?.bank);
    if (shouldRenderAdobeContractTimeline) {
      return <AdobeContractStatusTimeline />;
    }

    return <ContractStatusTimeline />;
  };

  return (
    <Box bgColor="snowyWhite">
      <HStack
        bgColor="queenBlue"
        px={5}
        py={2}
        flexDirection={{ base: 'column', md: 'row' }}
        spacing={{ base: 'unset', md: 'auto' }}
      >
        <Box minWidth="30%">
          <IconButton
            icon={<MdKeyboardArrowLeft size={35} />}
            borderRadius="full"
            aria-label="Return"
            onClick={() => history.goBack()}
          />

          <BackButton />

          {stateIsUnwindableToSet(deal.state, deal.set_date) ? (
            <UnwindDealToSet deal={deal} refetch={dealRefetch} />
          ) : null}

          {isDealInOrPastFunded(deal.state) ? (
            <UnwindDeal deal={deal} refetch={dealRefetch} />
          ) : null}
        </Box>

        <Box>
          <HStack>
            <Stack spacing={1}>
              <HStack>
                <Text color="white">State:</Text>
                <ChangeDealStateSelect />
                {deal.request_boot && (
                  <HStack justify="left">
                    <BootBox text="Boot Requested" />
                  </HStack>
                )}
              </HStack>
            </Stack>
          </HStack>
        </Box>
        <Box as={Row} minWidth="30%" flexDir="row-reverse">
          <Can I={PermissionEnum.BootDeal}>
            <BootAction deal={deal} />
          </Can>
          <ReviveBootAction />
          <Can I={PermissionEnum.RequestBoot}>
            <RequestBoot />
          </Can>
          {canEditDealTypeAcquisition && <ChangeDealTypeButton />}
          {deal.type === DealType.Acquisition && (
            <Button
              variant="secondary"
              size="lgWithIconLeft"
              leftIcon={<MdAssignment />}
              // eslint-disable-next-line security/detect-non-literal-fs-filename
              onClick={() => window.open(`${CARFAX_URL}${deal.car.vin}`)}
            >
              Carfax
            </Button>
          )}
          <ClaimButton />
        </Box>
      </HStack>

      <LeaseEndContainer fluid isLoading={isLoading}>
        <Notes>
          <Row className="pb-2">
            <Col md="9">
              <Row>
                <Col className="h-100" md="4">
                  <Row className="pb-2">
                    <Col className="h-100">
                      {deal.type === DealType.Buyout || deal.type === DealType.Refi ? (
                        <CustomerInfo bank={deal?.financial_info?.bank} />
                      ) : (
                        <CustomerInfoAcquisition />
                      )}
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col className="h-100">
                      <PayoffInfo />
                    </Col>
                  </Row>
                </Col>
                <Col md="8" className="pb-2 px-1 h-100">
                  {deal.type !== DealType.Acquisition || deal.state === DealStateEnum.Estimate ? (
                    renderCenterBox()
                  ) : (
                    <Card className="h-100" minH="863px">
                      <CardHeaderV2 title="Deal Info" showPodColor pod={deal.pod}>
                        <DealStatesModal />
                      </CardHeaderV2>
                      <Box p={6}>
                        <DealInfoAcquisition />
                      </Box>
                    </Card>
                  )}
                  {renderContractStatusTimeline()}
                </Col>
              </Row>
            </Col>
            <Col className="h-100" md="3">
              <Row className="pb-2">
                <Col className="h-100">
                  <DealInfo />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col className="h-100">
                  <VehicleInfo />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col className="h-100">
                  <TagInfo />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="px-1">
              <Box overflowY="scroll" flex="0 0 50%">
                <CommunicationHistory
                  canManagePins={canManagePins}
                  canEditNote={canEditNote}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  dealId={deal.id!}
                />
              </Box>
            </Col>
            <Col xs={12} md={6} className="px-1">
              <MediaCenter />
            </Col>
          </Row>
        </Notes>
      </LeaseEndContainer>
      <DealIssuesModal
        isOpen={showIssuesIfPresent}
        onAcknowledge={() => setShowIssuesIfPresent(false)}
        onDismiss={() => setShowIssuesIfPresent(false)}
        deal={deal}
        directPayState={directPayState}
        doubleTax={doubleTax}
        requiresWalkIn={jurisdiction.requireWalkIn ?? false}
      />
    </Box>
  );
};

export default DealDetail;
