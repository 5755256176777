import { getFullName } from '../../gql/customerGql';
import { Deal, DealStateLabelMap, DealTypeLabelMap } from '../../gql/dealGql';

import { formatDate, formatMoney } from '../../libs/utils';

export const completedDealColumns = [
  {
    header: 'Completion Date',
    getValue: (d: Deal) => formatDate(d.completion_date_utc, d.completion_date_tz),
  },
  {
    header: 'Finalized Date',
    getValue: (d: Deal) =>
      d.deal_dates?.dates?.finalized ? formatDate(d.deal_dates.dates.finalized) : '',
  },
  {
    header: 'Type',
    getValue: (d: Deal) => (d.type ? DealTypeLabelMap[d.type] : ''),
  },
  { header: 'Name', getValue: (d: Deal) => getFullName(d.customer) },
  { header: 'Year', getValue: (d: Deal) => d.car.year },
  { header: 'Make', getValue: (d: Deal) => d.car.make },
  { header: 'Model', getValue: (d: Deal) => d.car.model },
  { header: 'VIN', getValue: (d: Deal) => d.car.vin },
  { header: 'State', getValue: (d: Deal) => d.customer?.address?.state },
  { header: 'Advisor', getValue: (d: Deal) => d.setter?.name },
  { header: 'Financial Specialist', getValue: (d: Deal) => d.closer?.name },
  { header: 'Financial Specialist 2', getValue: (d: Deal) => d.closer2?.name },
  { header: 'Deal State', getValue: (d: Deal) => DealStateLabelMap[d.state] },
  {
    header: 'Lienholder',
    getValue: (d: Deal) => d.car.payoff?.lienholder_name,
  },
  {
    header: 'Good Through Date',
    getValue: (d: Deal) => formatDate(d.car.payoff?.good_through_date, 'utc'),
  },
  { header: 'Bank', getValue: (d: Deal) => d.financial_info?.bank },
  {
    header: 'Amount Financed',
    getValue: (d: Deal) => formatMoney(d.financial_info?.amount_financed),
  },
];
