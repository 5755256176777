/* eslint-disable @typescript-eslint/no-explicit-any */
import { isLocal } from '../config';
import { handleErrorWithSentry } from '../services/sentry';

export const logger = {
  // eslint-disable-next-line
  log(filename: string, message: string, data: any) {
    console.log(
      `Log - Filename: ${filename}`,
      `Message: ${message}`,
      `Data: ${JSON.stringify(data)}`,
    );
  },
  // eslint-disable-next-line
  warn(filename: string, message: string, data: any) {
    console.warn(
      `Warn - Filename: ${filename}`,
      `Message: ${message}`,
      `Data: ${JSON.stringify(data)}`,
    );
  },
  // eslint-disable-next-line
  error(filename: string, message: string, data?: any, error?: any) {
    console.error(
      `Error - Filename: ${filename}`,
      `Message: ${message}`,
      `Data: ${JSON.stringify(data)}; Error: ${error}`,
    );
    if (!isLocal) {
      handleErrorWithSentry(filename, message, data, error);
    }
  },
};
