import { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { MdOutlineSwitchLeft, MdOutlineSwitchRight } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { DealStateEnum, dealUpdateType } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import Modal from '../../components/shared/Modal';

import ROUTES from '../../constants/routes';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

const ChangeDealTypeButton = () => {
  const { deal, isLoading } = useContext(DealContext);
  const [updateDealType, { loading: dealTypeLoading }] = useMutation(dealUpdateType);
  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();

  const isBuyout = deal.type === DealType.Buyout;

  const changeDealType = () => {
    const newDealType = isBuyout ? DealType.Acquisition : DealType.Buyout;

    updateDealType({
      variables: { id: deal.id, type: newDealType },
    })
      .then(() => {
        history.push(ROUTES.DASHBOARD);
        setShowModal(false);
      })
      .catch((e: Error) => logger.error('ChangeDealTypeButton.tsx', '', null, e));

    return '';
  };

  const showButton =
    (deal.type === DealType.Buyout || deal.type === DealType.Acquisition) &&
    [DealStateEnum.SoftClose, DealStateEnum.Structuring, DealStateEnum.Closing].includes(
      deal.state,
    );

  return (
    <>
      <Button
        variant="secondary"
        size="lgWithIconLeft"
        leftIcon={isBuyout ? <MdOutlineSwitchLeft /> : <MdOutlineSwitchRight />}
        onClick={() => setShowModal(true)}
        hidden={!showButton}
        name="deal-type-button"
      >
        {isBuyout ? DealType.Acquisition : DealType.Buyout}
      </Button>
      <Modal
        size="md"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Change Deal Type"
        showDivider={false}
        leftButtons={
          <Button size="md" variant="warning" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        }
        rightButtons={
          <Button
            size="md"
            isLoading={dealTypeLoading || isLoading}
            loadingText={isBuyout ? DealType.Acquisition : DealType.Buyout}
            onClick={() => changeDealType()}
          >
            {`CHANGE TO ${isBuyout ? DealType.Acquisition : DealType.Buyout}`}
          </Button>
        }
      >
        Are you sure you want to change deal type?
      </Modal>
    </>
  );
};

export default ChangeDealTypeButton;
