import { ChangeEventHandler } from 'react';

import { Flex, FlexProps, StyleProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../gql/dealGql';
import { TtJurisdiction } from '../../../gql/generated/graphql';

import Switch from '../../shared/Switch';
import { switchContainerProps } from '../DealInfoBuyoutForm';

import InputRow from './InputRow';

interface SwitchesSectionProps extends FlexProps {
  isEditable: boolean;
  jurisdiction: TtJurisdiction;
  defaultFontSize?: StyleProps['fontSize'];
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const SwitchesSection = ({
  isEditable,
  defaultFontSize = 'sm',
  jurisdiction,
  customHandleChange,
  ...rest
}: SwitchesSectionProps) => {
  const { values, handleChange: formikHandleChange } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  return (
    <Flex direction="column" {...rest}>
      <InputRow
        label="Moved State During Lease"
        labelProps={{
          whiteSpace: 'normal',
        }}
      >
        <Switch
          id="moved_states"
          name="customer.address.moved_states"
          isNoYes
          noYesFontSize={defaultFontSize}
          isDisabled={!isEditable}
          customHandleChange={handleChange}
          {...switchContainerProps}
        />
      </InputRow>

      <InputRow
        label="Transfer Custom Plates"
        labelProps={{
          whiteSpace: 'normal',
        }}
      >
        <Switch
          id="plate_transfer"
          name="financial_info.plate_transfer"
          isNoYes
          noYesFontSize={defaultFontSize}
          isDisabled={
            !isEditable || values.financial_info?.title_only || !jurisdiction.allowPlateTransfer
          }
          customHandleChange={handleChange}
          {...switchContainerProps}
          switchProps={{
            ...switchContainerProps.switchProps,
            isChecked:
              values.financial_info?.title_only || !jurisdiction.allowPlateTransfer
                ? false
                : values.financial_info?.plate_transfer,
          }}
        />
      </InputRow>

      <InputRow
        label="Buyer or Co-Buyer is different than original lessee"
        labelProps={{
          whiteSpace: 'normal',
        }}
      >
        <Switch
          id="buyer_not_lessee"
          name="financial_info.buyer_not_lessee"
          isNoYes
          noYesFontSize={defaultFontSize}
          isDisabled={!isEditable}
          customHandleChange={handleChange}
          {...switchContainerProps}
        />
      </InputRow>
    </Flex>
  );
};
