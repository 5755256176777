import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '@chakra-ui/react';
import Modal from '../../../shared/Modal';

interface SignConfirmationModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleCompleteDeal: () => void;
}

const SignConfirmationModal: React.FC<SignConfirmationModalProps> = ({
  showModal,
  setShowModal,
  handleCompleteDeal,
}) => (
  <Modal
    size="md"
    isOpen={showModal}
    onClose={() => setShowModal(false)}
    title="Close Deal"
    showDivider={false}
    leftButtons={
      <Button size="md" variant="warning" onClick={() => setShowModal(false)}>
        Cancel
      </Button>
    }
    rightButtons={
      <Button size="md" onClick={() => handleCompleteDeal()}>
        Confirm
      </Button>
    }
  >
    Are you sure you want to close the deal?
  </Modal>
);

export default SignConfirmationModal;
