import { Auction, getAuctionNameById } from '../../gql/auctionGql';
import { getFullName } from '../../gql/customerGql';
import { Deal, DealStateLabelMap } from '../../gql/dealGql';

import { formatDate, formatMoney } from '../../libs/utils';

export const completedAcquisitionDealColumns = [
  {
    header: 'Completion Date',
    getValue: (d: Deal) => formatDate(d.completion_date_utc, d.completion_date_tz),
  },
  {
    header: 'Finalized Date',
    getValue: (d: Deal) => (d.finalized_date ? formatDate(d.finalized_date) : ''),
  },
  { header: 'Name', getValue: (d: Deal) => getFullName(d.customer) },
  { header: 'Year', getValue: (d: Deal) => d.car.year },
  { header: 'Make', getValue: (d: Deal) => d.car.make },
  { header: 'Model', getValue: (d: Deal) => d.car.model },
  { header: 'Mileage', getValue: (d: Deal) => d.car.mileage },
  { header: 'VIN', getValue: (d: Deal) => d.car.vin },
  { header: 'State', getValue: (d: Deal) => d.customer?.address?.state },
  { header: 'Advisor', getValue: (d: Deal) => d.setter?.name },
  { header: 'Financial Specialist', getValue: (d: Deal) => d.closer?.name },
  { header: 'Financial Specialist 2', getValue: (d: Deal) => d.closer2?.name },
  { header: 'Deal State', getValue: (d: Deal) => DealStateLabelMap[d.state] },
  { header: 'Lienholder', getValue: (d: Deal) => d.car.payoff?.lienholder_name },
  {
    header: 'Transport Cost',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.transport_cost),
  },
  {
    header: 'Auction',
    getValue: (d: Deal, auctions?: Auction[]) =>
      d.financial_info_acquisition?.auction_id && auctions?.length
        ? getAuctionNameById(d.financial_info_acquisition?.auction_id, auctions)
        : '',
  },
  { header: 'Payoff', getValue: (d: Deal) => formatMoney(d.car.payoff?.vehicle_payoff) },
  {
    header: 'Cash To Customer',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.cash_to_customer),
  },
  {
    header: 'Auction Fees',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.auction_fees),
  },
  {
    header: 'Total Cost',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.total_cost),
  },
  {
    header: 'Sell Price',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.sell_price),
  },
  {
    header: 'Net Profit',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.gross_profit),
  },
  {
    header: 'KBB Lending',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.kbb_lending),
  },
  {
    header: 'MMR',
    getValue: (d: Deal) => formatMoney(d.car.mmr),
  },
  {
    header: 'Appraised Value',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.appraised_value),
  },
  {
    header: 'Sold Date',
    getValue: (d: Deal) => (d.sold_date ? formatDate(d.sold_date) : ''),
  },
];
