import { FC } from 'react';

import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import { ResidenceTypeEnum } from '../../gql/addressGql';
import { Customer } from '../../gql/customerGql';
import { createNewEstimateAndSetFollowUp } from '../../gql/dealGql';

import { FollowUpFormType } from '../FollowUp/validationSchema';

import NewEstimateWithFollowUpForm from './NewEstimateWithFollowUpForm';
import { validationSchema } from './validationSchema';

import ROUTES from '../../constants/routes';
import { passValuesToSchema } from '../../libs/utils';

const NewEstimateFollowUpWrapper: FC = () => {
  const history = useHistory();
  const [createDealAndFollowUp] = useMutation(createNewEstimateAndSetFollowUp);

  const handleSubmit = async (values: {
    follow_up: FollowUpFormType;
    customer: Partial<Customer>;
  }) => {
    const { follow_up } = values;

    // format the date and time of the follow up
    const datePart = follow_up.date?.toISOString().split('T')[0];
    const timePart = follow_up.time?.toISOString().split('T')[1].replace('Z', '');

    await createDealAndFollowUp({
      variables: {
        ...values.customer,
        ...values.customer.address,
        date_utc: new Date(`${datePart}T${timePart}Z`),
        note: follow_up.note,
      },
      onCompleted: () => history.push(ROUTES.DASHBOARD),
    });
  };

  return (
    <Formik
      initialValues={{
        follow_up: {
          date: undefined,
          time: undefined,
          note: null,
        },
        customer: {
          first_name: '',
          last_name: '',
          phone_number: '',
          email: '',
          no_email: false,
          address: {
            address_line: '',
            address_line_2: '',
            zip: '',
            city: '',
            state: '',
            county: '',
            residence_type: ResidenceTypeEnum.Rent,
          },
        },
      }}
      validate={(values) => {
        passValuesToSchema(values, validationSchema);
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <NewEstimateWithFollowUpForm />
    </Formik>
  );
};

export default NewEstimateFollowUpWrapper;
