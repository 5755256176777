import { ComponentStyleConfig } from '@chakra-ui/react';

const checkbox: ComponentStyleConfig = {
  parts: ['control', 'icon', 'container', 'label'],
  baseStyle: {
    control: {
      _checked: {
        bg: 'tealGreen',
        borderColor: 'tealGreen',
        _hover: {
          bg: 'tealGreen',
          borderColor: 'tealGreen',
        },
      },
    },
  },
};

export default checkbox;
