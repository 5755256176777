import * as PDFJS from 'pdfjs-dist';

export const getBase64FromFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const result = reader.result as string;

      if (result.startsWith('data:image')) {
        return resolve(result.split(',').pop() as string);
      }

      PDFJS.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js`;

      const pdf = await PDFJS.getDocument(result).promise;
      const firstPage = await pdf.getPage(1);

      const scale = 1.5;
      const viewport = firstPage.getViewport({ scale });
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await firstPage.render({ canvasContext: context, viewport }).promise;
      const base64imgFromPdf = canvas.toDataURL('image/jpeg');

      return resolve(base64imgFromPdf.split(',').pop() as string);
    };
    reader.onerror = (error) => reject(error);
  });

export const normalizeFileName = (fileName: string) => {
  const i = fileName.lastIndexOf('.');
  const extension = fileName.substring(i + 1);
  const name = fileName.slice(0, i);

  return `${name
    .replace(/[^a-z0-9-_()]+/gi, '')
    .replace(/\s/g, '_')
    .replace(/__+/g, '_')}${extension ? `.${extension}` : ''}`;
};

export const truncateFileName = (fileNameWithExtension: string, maxTotalLength = 20): string => {
  if (fileNameWithExtension.length <= maxTotalLength) {
    return fileNameWithExtension;
  }

  const separator = '.';
  const ellipsis = '...';

  const separatorIndex = fileNameWithExtension.lastIndexOf(separator);
  if (separatorIndex === -1) {
    return fileNameWithExtension.substring(0, maxTotalLength);
  }

  const extensionIndex = separatorIndex + 1;
  const extensionLength = fileNameWithExtension.length - extensionIndex;

  const ellipsisLength = ellipsis.length;
  const separatorLength = separator.length;
  const fileNameMaxLength = maxTotalLength - ellipsisLength - separatorLength - extensionLength;

  const fileNameWithoutExtension = fileNameWithExtension.substring(0, separatorIndex);
  const fileExtension = fileNameWithExtension.substring(
    extensionIndex,
    fileNameWithExtension.length,
  );

  const fileNameTruncated = fileNameWithoutExtension.substring(0, fileNameMaxLength);
  const fileNameTruncatedWithEllipsis =
    fileNameWithoutExtension.length > fileNameMaxLength
      ? `${fileNameTruncated}${ellipsis}`
      : fileNameTruncated;

  return `${fileNameTruncatedWithEllipsis}.${fileExtension}`;
};

export const getFileNameAndExtension = (fileNameWithExtension: string, uppercase = false) => {
  const separatorIndex = fileNameWithExtension.lastIndexOf('.');
  const extension = fileNameWithExtension.substring(separatorIndex + 1);
  const fileName = fileNameWithExtension.slice(0, separatorIndex);

  if (uppercase) {
    return {
      fileName: fileName.toUpperCase(),
      extension: extension.toUpperCase(),
    };
  }

  return {
    fileName,
    extension,
  };
};

export const downloadFile = (url: string, name?: string) => {
  const linkElement = document.createElement('a');
  linkElement.style.display = 'none';
  linkElement.href = url;
  linkElement.target = '_blank';
  linkElement.download = name || url.split('/').pop() || 'filename';

  document.body.appendChild(linkElement);
  linkElement.click();

  document.body.removeChild(linkElement);
};
