import { ChangeEventHandler, FC, useContext, useEffect } from 'react';

import { Flex, FlexProps, StyleProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../gql/dealGql';
import { ProcessorEnum } from '../../../gql/financialInfoGql';

import DatePicker from '../../shared/DatePicker';
import Select from '../../shared/Select';

import InputRow from './InputRow';

import { DealContext } from '../../../libs/DealContext';
import { canSetProcessor, processorOptions } from '../../../utils/deals';

interface TitlingSectionProps extends FlexProps {
  dealIsInTitlingState: boolean;
  customSignedField: string;
  customTitleReceivedField: string;
  saveCustomDate: (fieldName: string, value: string) => Promise<void>;
  saveProcessor: (value?: ProcessorEnum) => Promise<void>;
  defaultFontSize?: StyleProps['fontSize'];
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const TitlingSection: FC<TitlingSectionProps> = ({
  dealIsInTitlingState,
  customSignedField,
  customTitleReceivedField,
  saveCustomDate,
  saveProcessor,
  defaultFontSize = 'sm',
  customHandleChange,
  ...rest
}) => {
  const { deal } = useContext(DealContext);

  const {
    initialValues,
    values,
    handleChange: formikHandleChange,
    touched,
  } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  useEffect(() => {
    if (
      initialValues.financial_info.processor === values.financial_info.processor &&
      !touched.financial_info?.processor
    ) {
      return;
    }

    saveProcessor(values.financial_info.processor);
  }, [values.financial_info.processor]);

  return (
    <Flex direction="column" {...rest}>
      {dealIsInTitlingState ? (
        <>
          <InputRow label="Signed Date">
            <DatePicker
              name={customSignedField}
              boxStyles={{
                w: '100%',
              }}
              inputProps={{
                fontSize: defaultFontSize,
              }}
              additionalHandleChange={(e) =>
                saveCustomDate(e.currentTarget.name, e.currentTarget.value)
              }
            />
          </InputRow>

          <InputRow label="Title Received Date">
            <DatePicker
              name={customTitleReceivedField}
              boxStyles={{
                w: '100%',
              }}
              inputProps={{ fontSize: defaultFontSize }}
              additionalHandleChange={(e) =>
                saveCustomDate(e.currentTarget.name, e.currentTarget.value)
              }
            />
          </InputRow>
        </>
      ) : null}

      {canSetProcessor(deal.state) ? (
        <InputRow label="Processor">
          <Select
            name="financial_info.processor"
            size="sm"
            options={processorOptions}
            onChange={handleChange}
          />
        </InputRow>
      ) : null}
    </Flex>
  );
};
