import { Dispatch, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import { carDetailLicensePlateQuery } from '../../gql/carGql';
import { Deal } from '../../gql/dealGql';

export const AutoExplodeLicensePlate = ({
  setCarDetailsLoading,
  setCallVinDecoder,
  callLicensePlateDecoder,
  setCallLicensePlateDecoder,
  isLicensePlateNumberDirty,
  isLicensePlateStateDirty,
  setLicensePlateNumberDirty,
  setLicensePlateStateDirty,
}: {
  setCarDetailsLoading?: Dispatch<boolean>;
  setCallVinDecoder: Dispatch<boolean>;
  callLicensePlateDecoder: boolean;
  setCallLicensePlateDecoder: Dispatch<boolean>;
  isLicensePlateNumberDirty: boolean;
  isLicensePlateStateDirty: boolean;
  setLicensePlateNumberDirty: Dispatch<boolean>;
  setLicensePlateStateDirty: Dispatch<boolean>;
}) => {
  const {
    values: {
      car: { license_plate_number, license_plate_state },
    },
    setFieldValue,
  } = useFormikContext<Deal>();

  const [
    carDetailsLicensePlate,
    { loading: licensePlateLoading, data: licensePlateData, error: licensePlateError },
  ] = useLazyQuery(carDetailLicensePlateQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (licensePlateData && licensePlateData?.carDetailLicensePlate) {
      setFieldValue('car.vin', licensePlateData.carDetailLicensePlate?.vin);
      setFieldValue('car.year', licensePlateData.carDetailLicensePlate?.year);
      setFieldValue('car.make', licensePlateData.carDetailLicensePlate?.make);
      setFieldValue('car.model', licensePlateData.carDetailLicensePlate?.model);
      setFieldValue('car.vehicle_type', licensePlateData.carDetailLicensePlate?.vehicle_type);
      setFieldValue('car.fuel_type', licensePlateData.carDetailLicensePlate?.fuel_type);
      setCallVinDecoder(false);
    }
  }, [licensePlateData]);

  useEffect(() => {
    if (licensePlateError) {
      toast.error('Failed to decode license plate');
    }
  }, [licensePlateError]);

  useEffect(() => {
    if (setCarDetailsLoading) {
      setCarDetailsLoading(licensePlateLoading);
    }
  }, [licensePlateLoading]);

  useEffect(() => {
    if (
      license_plate_number &&
      license_plate_state &&
      callLicensePlateDecoder &&
      (isLicensePlateNumberDirty || isLicensePlateStateDirty)
    ) {
      carDetailsLicensePlate({
        variables: { license_plate_state, license_plate_number },
      });
      setCallLicensePlateDecoder(false);
      setLicensePlateNumberDirty(false);
      setLicensePlateStateDirty(false);
    }
  }, [
    license_plate_number,
    license_plate_state,
    callLicensePlateDecoder,
    isLicensePlateNumberDirty,
    isLicensePlateStateDirty,
  ]);

  return null;
};
