import { MouseEventHandler, ReactNode, useState } from 'react';

import { Box, Collapse, HStack, Icon, IconProps, SlideFade, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { MdClear } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

interface BaseProps {
  slideFadeIn: boolean;
  title: string;
  titleIcon: IconType;
  titleIconColor: Pick<IconProps, 'color'>['color'];
  subTitle: string;
  details: ReactNode;
  onClose: () => void;

  rightTitle?: ReactNode;
  rightSubtitle?: ReactNode;
}

type ConditionalProps =
  | {
      dealId: number | null;
      customDetailsClick?: never;
    }
  | {
      customDetailsClick: MouseEventHandler<HTMLDivElement>;
      dealId?: never;
    };

type Props = BaseProps & ConditionalProps;

const NotificationToast = ({
  slideFadeIn,
  title,
  titleIcon,
  titleIconColor,
  subTitle,
  details,
  onClose,

  dealId,
  customDetailsClick,
  rightTitle = null,
  rightSubtitle = null,
}: Props) => {
  const history = useHistory();

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <SlideFade in={slideFadeIn}>
      <Box
        borderRadius="10px"
        fontSize={16}
        w="450px"
        onClick={() => setShowDetails(!showDetails)}
        cursor="pointer"
        boxShadow="2px 2px 8px rgba(0, 0, 0, 0.4)"
      >
        <HStack
          bg="electricBlue"
          borderTopRadius={10}
          minH="40px"
          p={3}
          color="white"
          fontSize={18}
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack>
            <Icon as={titleIcon} color={titleIconColor} boxSize={6} />
            <Text>{title}</Text>
          </HStack>

          <HStack>
            {!showDetails ? (
              rightTitle
            ) : (
              <Icon
                as={MdClear}
                onClick={onClose}
                w={6}
                h={6}
                _hover={{ bg: 'blue.500', borderRadius: '3px' }}
              />
            )}
          </HStack>
        </HStack>

        <HStack
          bg="blue.300"
          minH="40px"
          borderBottomRadius={showDetails ? 0 : 10}
          transition="border-radius 0.2s ease-in-out"
          paddingX={3}
          paddingY={2}
          justifyContent="space-between"
          color="white"
          borderColor="gray.200"
        >
          <Text>{subTitle}</Text>
          {rightSubtitle}
        </HStack>

        <Collapse in={showDetails}>
          <Box
            bg="white"
            borderBottomRadius={10}
            p={3}
            borderColor="gray.200"
            onClick={(e) => {
              if (customDetailsClick) {
                customDetailsClick(e);
                return;
              }

              e.stopPropagation();
              if (dealId) {
                history.push(`/deals/${dealId}`);
                onClose();
              }
            }}
          >
            {details}
          </Box>
        </Collapse>
      </Box>
    </SlideFade>
  );
};

export default NotificationToast;
