import { useContext } from 'react';

import { IconButton } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import { toast } from 'react-toastify';

import { Deal } from '../../../gql/dealGql';
import { useDealAssignMutation } from '../../../gql/generated/graphql';

import { PermissionEnum } from '../../../constants/permissions';
import { logger } from '../../../libs/Logger';
import { AbilityContext } from '../../../libs/contextLib';
import { handleClaimDealError } from '../../../utils/deals';

interface SignedActionProps {
  deal: Deal;
  assumedId?: string;
}

const SignedActions = ({ deal, assumedId }: SignedActionProps) => {
  const abilities = useContext(AbilityContext);
  const [assignDeal] = useDealAssignMutation();

  const assignAsAccountingClerk = async () => {
    try {
      await assignDeal({
        variables: {
          deal_id: deal.id ?? 0,
          assignee_id: assumedId,
          role: 'funding_clerk_id',
        },
      });
      toast.success('Deal Assigned!');
    } catch (e) {
      logger.error('SignedActions.tsx', '', null, e);
      handleClaimDealError(e);
    }
  };

  if (abilities.has(PermissionEnum.ClaimDealAsAccountingClerk) && !deal.funding_clerk) {
    return (
      <IconButton
        icon={<BiPlus />}
        variant="iconHover"
        size="xxs"
        fontSize={20}
        aria-label="Claim Deal"
        id={`assign-deal-${deal.id}`}
        onClick={assignAsAccountingClerk}
      />
    );
  }

  return null;
};

export default SignedActions;
