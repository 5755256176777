import { FC, useEffect, useState } from 'react';
import { Th, Tr, Td, Table, Thead, Tbody, Spinner } from '@chakra-ui/react';
// import { BsFillTelephoneOutboundFill } from 'react-icons/bs';

import { PayoffRequest } from '../../../gql/payoffRequestGql';
import { LienholderPRSType } from '../../../gql/prs/types';
import { snakeCaseToTitleCase } from '../../../utils/text';
import { useLienholder } from '../../../hooks/useLienholder';

enum DetailHeaders {
  PHONE_ICON = '',
  LIENHOLDER = 'Lienholder',
  REQUIRED1 = 'Required #1',
  REQUIRED2 = 'Required #2',
  REQUIRED3 = 'Required #3',
  REQUIRED4 = 'Required #4',
  DEALER_PAYOFF = 'Dealer Payoff',
  DIRECT_PAY = 'Direct Pay',
  SALES_TAX = 'Sales Tax',
  PAYOFF_METHOD = 'Payoff Method',
}

interface Props {
  collapsed: boolean;
  payoffRequest: PayoffRequest;
}

const getPayoffDetail = (lienholder: LienholderPRSType, doubleTax: boolean): string[] => {
  const requirements = lienholder.requirements_to_get_payoff.options;
  const payoffMethods = Object.entries(lienholder.requirements_to_get_payoff.get_payoff_methods)
    .map(([key, prevValue]) => {
      const value = typeof prevValue === 'string' ? prevValue : prevValue.value;
      if (key === 'id' || value === 'FALSE') {
        return null;
      }
      return value === 'TRUE'
        ? snakeCaseToTitleCase(key)
        : `${snakeCaseToTitleCase(key)} (${snakeCaseToTitleCase(value)})`;
    })
    .filter((value) => value !== null)
    .join(', ');

  return [
    lienholder.display_name,
    requirements[0] ? snakeCaseToTitleCase(requirements[0].join(', ')) : '--',
    requirements[1] ? snakeCaseToTitleCase(requirements[1].join(', ')) : '--',
    requirements[2] ? snakeCaseToTitleCase(requirements[2].join(', ')) : '--',
    requirements[3] ? snakeCaseToTitleCase(requirements[3].join(', ')) : '--',
    lienholder.requirements_to_payoff_lease.payoff_through_dealer.value ? 'YES' : 'NO',
    doubleTax ? 'YES' : 'NO',
    lienholder.requirements_to_payoff_lease.direct_pay.value ? 'YES' : 'NO',
    payoffMethods,
  ];
};

const PayoffDetail: FC<Props> = ({ collapsed, payoffRequest }) => {
  const [lienholder, setLienholder] = useState<LienholderPRSType>();

  const {
    lienholder: fetchedLienholder,
    doubleTax,
    loading,
  } = useLienholder({
    data: payoffRequest.deal_id ? payoffRequest.deal : payoffRequest.temporary_info?.data ?? {},
    skip: collapsed || !!lienholder,
  });

  useEffect(() => {
    if (fetchedLienholder) {
      setLienholder(fetchedLienholder);
    }
  }, [fetchedLienholder]);

  return (
    <Tr display={collapsed ? 'none' : 'default'}>
      <Td colSpan={16} p={0} paddingLeft={4} bgColor="hoverGray">
        {loading ? (
          <Spinner size="md" mt={1} mb={2} />
        ) : (
          <Table marginBottom={4}>
            <Thead>
              <Tr bgColor="silverLakeBlue">
                {Object.values(DetailHeaders).map((header) => (
                  <Th key={header} color="white" p={2} paddingLeft={4}>
                    {header}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody bgColor="white">
              <Tr p={1}>
                <Td p={2} paddingLeft={4} borderBottomLeftRadius={6}>
                  {/* 
                Commented this out until we add the click to call functionality
                <BsFillTelephoneOutboundFill /> 
                */}
                </Td>
                {lienholder
                  ? getPayoffDetail(lienholder, doubleTax).map((detail, i) => (
                      <Td
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${payoffRequest.id} ${i}`}
                        p={2}
                        paddingLeft={4}
                        borderBottomLeftRadius={0}
                      >
                        {detail}
                      </Td>
                    ))
                  : null}
              </Tr>
            </Tbody>
          </Table>
        )}
      </Td>
    </Tr>
  );
};

export default PayoffDetail;
