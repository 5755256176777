import React, { MouseEvent } from 'react';

import { Button } from '@chakra-ui/react';

interface ClaimDealButtonProps {
  isClaimed: boolean;
  allowedToClaim: boolean;
  onClick: (e: MouseEvent) => void;
}

const ClaimDealButton: React.FC<ClaimDealButtonProps> = ({
  isClaimed,
  allowedToClaim,
  onClick,
}) => {
  return (
    <Button
      size="sm"
      variant="secondary"
      width="75px"
      isDisabled={isClaimed && !allowedToClaim}
      onClick={onClick}
    >
      {isClaimed ? 'CLAIMED' : 'CLAIM'}
    </Button>
  );
};

export default ClaimDealButton;
