import { FC, ReactNode } from 'react';

import { MenuItem, MenuItemProps, StyleProps } from '@chakra-ui/react';
import button from '../../chakra/components/button';

interface Props extends MenuItemProps {
  children: ReactNode;
  activeProps?: StyleProps;
  isActive?: boolean;
}

const ActiveMenuItem: FC<Props> = ({ children, isActive, activeProps, ...props }) => {
  // eslint-disable-next-line no-underscore-dangle
  const activeNavBarButtonVariant = button.variants.navBar._active;

  const { bg, color } = activeProps ?? activeNavBarButtonVariant;
  const activeColorProps = isActive ? { bg, color } : undefined;

  return (
    <MenuItem _focus={activeColorProps} _hover={activeColorProps} {...activeColorProps} {...props}>
      {children}
    </MenuItem>
  );
};

export default ActiveMenuItem;
