import { Flex } from '@chakra-ui/react';

import { FC, ReactNode } from 'react';

interface NonButtonStepProps {
  children: ReactNode;
  width?: string;
}

export const NonButtonStep: FC<NonButtonStepProps> = ({ children, width }) => (
  <Flex
    w={width}
    fontSize="12px"
    fontWeight={550}
    h="32px"
    alignItems="center"
    justifyContent="center"
    letterSpacing="0.6px"
  >
    {children}
  </Flex>
);
