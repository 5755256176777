import { useContext } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { useCreateStructuringFollowUpMutation } from '../../gql/generated/graphql';

import Modal from '../shared/Modal';
import TextArea from '../shared/TextArea';

import StructuringFollowUpButton from './StructuringFollowUpButton';
import { FormValues, validationSchema } from './validationSchema';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

const AddStructuringFollowUp = () => {
  const { deal } = useContext(DealContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [createStructuringFollowUp, { loading }] = useCreateStructuringFollowUpMutation();

  const initialValues: FormValues = {
    notes: '',
  };

  const handleSubmit = async (values: FormValues) => {
    const trimmedNotes = values.notes.trim();
    if (!deal.id || !trimmedNotes) {
      return;
    }

    try {
      await createStructuringFollowUp({
        variables: {
          dealId: deal.id,
          notes: trimmedNotes,
        },
      });
      toast.success('Structuring Follow-Up added');
      onClose();
    } catch (e) {
      const error = e as Error;
      logger.error('AddStructuringFollowUp.tsx', 'createStructuringFollowUp', error);
      toast.error(
        `Error adding Structuring Follow-Up: ${
          error.message || error
        }. Please refresh and try again.`,
      );
    }
  };

  return (
    <>
      <StructuringFollowUpButton onOpen={onOpen} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Modal
          title="Add to Structuring Queue"
          hasForm
          isOpen={isOpen}
          onClose={onClose}
          leftButtons={
            <Button variant="warning" onClick={onClose}>
              CANCEL
            </Button>
          }
          rightButtons={
            <Button type="submit" isLoading={loading} loadingText="ADD TO QUEUE">
              ADD TO QUEUE
            </Button>
          }
          scrollBehavior="outside"
        >
          <TextArea
            name="notes"
            label="Notes"
            minH="6em"
            maxH="12em"
            resize="vertical"
            maxLength={300}
          />
        </Modal>
      </Formik>
    </>
  );
};

export default AddStructuringFollowUp;
