import React, { useContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { generateContractsQuery } from '../../gql/contractGql';
import { DealStateEnum } from '../../gql/dealGql';

import PreviewModals from './PreviewModals';

import { PermissionEnum } from '../../constants/permissions';
import { Can } from '../../libs/Can';
import { DealContext } from '../../libs/DealContext';

const GenerateAndSendContracts: React.FC = () => {
  const { deal } = useContext(DealContext);
  const [showModal, setShowModal] = useState(false);
  const [generateContracts, { data, loading, error }] = useLazyQuery(generateContractsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.generateContracts?.files) {
      setShowModal(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error?.message || 'Failed to generate contracts');
    }
  }, [error]);

  const handleGenerateContracts = () => {
    generateContracts({
      variables: {
        data: {
          deal_id: deal.id,
        },
      },
    });
  };

  return (
    <>
      <Can I={PermissionEnum.SeeGenerateContracts}>
        <Button
          isLoading={loading}
          loadingText="GENERATE CONTRACTS"
          hidden={deal.state !== DealStateEnum.Closed}
          name="generate-contracts"
          onClick={handleGenerateContracts}
        >
          GENERATE CONTRACTS
        </Button>
      </Can>
      <PreviewModals
        files={data?.generateContracts?.files ?? []}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default GenerateAndSendContracts;
