import { FC, ReactNode } from 'react';

import { Flex, StackProps, VStack } from '@chakra-ui/react';

interface Props extends StackProps {
  children: ReactNode;
  label?: string;
}

const MenuFilterContainer: FC<Props> = ({ children, label, ...rest }) => (
  <VStack align="start" w="full" p={3} pt={0} {...rest}>
    {label ? (
      <Flex whiteSpace="nowrap" fontWeight="bold" fontSize="lg">
        {label}
      </Flex>
    ) : null}
    {children}
  </VStack>
);

export default MenuFilterContainer;
