import { FC } from 'react';
import { Button } from '@chakra-ui/react';
import Modal from '../../../components/shared/Modal';

interface MoveAndCancelSigningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}

const MoveAndCancelSigningModal: FC<MoveAndCancelSigningModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  loading,
}) => {
  return (
    <Modal
      title="Delete Contract"
      showDivider={false}
      canDismiss={false}
      isOpen={isOpen}
      onClose={onClose}
      rightButtons={
        <>
          <Button
            variant="warning"
            onClick={onConfirm}
            isLoading={loading}
            loadingText="Delete Contracts"
          >
            Delete Contracts
          </Button>
          <Button onClick={onClose} isDisabled={loading}>
            Keep Contracts
          </Button>
        </>
      }
    >
      Are you sure you want to cancel all contracts and move this deal? This will require the
      customer to be re-contracted and re-sign. This action cannot be undone.
    </Modal>
  );
};

export default MoveAndCancelSigningModal;
