import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { ChromePicker, CirclePicker, ColorResult } from 'react-color';
import { BiPlusCircle } from 'react-icons/bi';

import useClickOutsideAction from '../../hooks/useClickOutsideAction';

interface ColorSelectProps {
  name: 'pod' | 'titlingPod';
}

const ColorSelect: React.FC<ColorSelectProps> = ({ name }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [customColor, setCustomColor] = useState('#ffffff');
  const [showCustomColorPicker, setShowCustomColorPicker] = useState(false);

  const colorPickerCardRef = useRef<HTMLDivElement>(null);

  useClickOutsideAction(colorPickerCardRef, () => {
    setShowColorPicker(false);
    setShowCustomColorPicker(false);
  });

  const fieldName = `${name}.color`;
  const { getFieldMeta, setFieldValue } =
    useFormikContext<{ pod: { color: string }; titlingPod: { color: string } }>();
  const { value, error, touched } = getFieldMeta<string>(fieldName);

  useEffect(() => {
    if (value) {
      setCustomColor(value);
    }
  }, [value]);

  const closeCustomColorPicker = () => {
    setShowCustomColorPicker(false);
  };

  const closeColorPicker = () => {
    setShowColorPicker(false);
  };

  const openColorPicker = () => {
    closeCustomColorPicker();
    setShowColorPicker(true);
  };

  const openCustomColorPicker = () => {
    setShowCustomColorPicker(true);
  };

  const handleChangeColor = (color: string) => {
    setFieldValue(fieldName, color);
    closeColorPicker();
    closeCustomColorPicker();
  };

  const renderColorPopover = () => {
    return (
      <Popover
        matchWidth
        isOpen={showColorPicker}
        returnFocusOnClose={false}
        onClose={closeColorPicker}
      >
        <PopoverTrigger>
          <FormControl>
            <FormLabel fontSize="sm">Color</FormLabel>
            <Box
              width="20px"
              height="20px"
              borderRadius="50%"
              left="8px"
              bottom="6px"
              bg={value}
              position="absolute"
              zIndex="1"
            />
            <Select
              as="select"
              name="color"
              isInvalid={touched && !!error}
              onClick={openColorPicker}
              border={touched && error ? '2px' : '1px'}
              borderColor={touched && error ? 'red.500' : 'gray.300'}
              width="60px"
              margin-right="-5px"
              zIndex="0"
            />
          </FormControl>
        </PopoverTrigger>
        <PopoverContent width="220px">
          <PopoverBody>
            <CirclePicker
              width="198px"
              circleSize={20}
              circleSpacing={2}
              color={value}
              onChange={(color: ColorResult) => handleChangeColor(color.hex)}
            />
            <Flex justify="space-between" marginTop="10px" marginRight="-2px">
              <Button size="sm" variant="warning" onClick={closeColorPicker}>
                CANCEL
              </Button>
              <Button
                size="smWithIconLeft"
                variant="secondary"
                leftIcon={<BiPlusCircle size="20" />}
                onClick={openCustomColorPicker}
              >
                CUSTOM
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <>
      <div ref={colorPickerCardRef}>
        <Popover
          matchWidth
          isOpen={showCustomColorPicker}
          returnFocusOnClose={false}
          onClose={closeCustomColorPicker}
        >
          <PopoverTrigger>
            <div>{renderColorPopover()}</div>
          </PopoverTrigger>
          <PopoverContent width="260px">
            <PopoverBody>
              <ChromePicker
                color={customColor}
                onChange={(color: ColorResult) => {
                  setCustomColor(color.hex);
                }}
                className="m-1 shadow-none"
              />
              <Flex justify="space-between" marginRight="-2px">
                <Button size="sm" variant="warning" onClick={openColorPicker}>
                  CANCEL
                </Button>
                <Button
                  size="smWithIconLeft"
                  variant="secondary"
                  leftIcon={<BiPlusCircle size="20" />}
                  onClick={() => handleChangeColor(customColor)}
                >
                  ADD
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <FormErrorMessage>{error}</FormErrorMessage>
      </div>
    </>
  );
};

export default ColorSelect;
