import styled from 'styled-components';
import { ColorsEnum } from '../../libs/colorLib';

const ClickMe = styled.span`
  color: ${ColorsEnum.BLUE};
  cursor: pointer;
  text-decoration: underline;
`;

export default ClickMe;
