import React, { useEffect, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { useImageSize } from 'react-image-size';

import Controls from './components/Controls';

type Props = {
  src: string;
  alt?: string;
};

const ImageMagnifier: React.FC<Props> = ({ src, alt }) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const [data] = useImageSize(src);

  const maxWidth = 500;
  const maxHeight = 750;

  const [rotation, setRotation] = useState(0);
  const [containerWidth, setContainerWidth] = useState(maxWidth);
  const [containerHeight, setContainerHeight] = useState<number>();
  const [width, setWidth] = useState(maxWidth);
  const [height, setHeight] = useState<number>();

  const round = (num: number) => Math.round(num * 100) / 100;

  const handleRotateLeft = () => {
    const newRotation = round(rotation - 90);
    setRotation(Math.abs(newRotation) === 360 ? 0 : newRotation);
  };

  const handleRotateRight = () => {
    const newRotation = round(rotation + 90);
    setRotation(Math.abs(newRotation) === 360 ? 0 : newRotation);
  };

  const initialScale = 1;
  const maxScale = 8;
  const minScale = 1;

  useEffect(() => {
    if (data?.width && data?.height) {
      const widthHeightRatio = data?.width / data?.height;
      const absoluteRotation = Math.abs(rotation);
      const isHorizontallyRotated = absoluteRotation === 90 || absoluteRotation === 270;

      if (data.width > data.height) {
        setHeight(maxWidth / widthHeightRatio);

        if (isHorizontallyRotated) {
          setContainerWidth(maxWidth / widthHeightRatio);
          setContainerHeight(maxWidth);
        } else {
          setContainerWidth(maxWidth);
          setContainerHeight(maxWidth / widthHeightRatio);
        }
      } else if (isHorizontallyRotated) {
        setHeight(maxWidth);
        setWidth(maxWidth * widthHeightRatio);
        setContainerWidth(maxWidth);
        setContainerHeight(maxWidth * widthHeightRatio);
      } else {
        setHeight(maxHeight);
        setWidth(maxHeight * widthHeightRatio);
        setContainerHeight(maxHeight);
        setContainerWidth(maxHeight * widthHeightRatio);
      }
    }
  }, [data?.width, data?.height, rotation]);

  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        maxScale={maxScale}
        minScale={minScale}
        ref={transformComponentRef}
        wheel={{ disabled: true }}
        panning={{ velocityDisabled: true }}
      >
        {(utils) => {
          return (
            <>
              <TransformComponent
                contentStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: containerHeight,
                  width: containerWidth,
                  flexWrap: 'nowrap',
                }}
              >
                <img
                  src={src}
                  alt={alt ?? 'img'}
                  style={{
                    height,
                    width,
                    transform: `rotate(${rotation}deg)`,
                    maxWidth: 'none',
                  }}
                />
              </TransformComponent>
              <Controls
                handleZoomIn={() => utils.zoomIn()}
                handleZoomOut={() => utils.zoomOut()}
                handleRotateLeft={handleRotateLeft}
                handleRotateRight={handleRotateRight}
                initialScale={initialScale}
                maxScale={maxScale}
                minScale={minScale}
              />
            </>
          );
        }}
      </TransformWrapper>
    </Flex>
  );
};

export default ImageMagnifier;
