import React, { useState } from 'react';
import { InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconButton } from '@chakra-ui/react';
import { BiCopyAlt } from 'react-icons/bi';
import styled from 'styled-components';
import { formatNumber } from '../../libs/utils';

// TODO: migrate to Chakra UI
const StyledAppend = styled(InputGroup.Append)`
  &&& {
    cursor: pointer;
  }
`;

interface CopyButtonProps {
  value: string;
  isFormatted?: boolean;
  visibility?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderTooltip = (props: any) => {
  return <Tooltip {...props}>Copied!</Tooltip>;
};

export const CopyButton: React.FC<CopyButtonProps> = ({
  value,
  isFormatted = false,
  visibility = true,
  ...props
}) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isFormatted) {
      navigator.clipboard.writeText(formatNumber(value));
    } else {
      navigator.clipboard.writeText(value.toString().toUpperCase());
    }

    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 2750);
  };

  return (
    <span>
      <OverlayTrigger trigger="click" overlay={show ? renderTooltip : <span />}>
        <IconButton
          icon={<BiCopyAlt />}
          variant="iconHover"
          size="xs"
          fontSize={17}
          aria-label="Copy"
          visibility={visibility ? 'visible' : 'hidden'}
          {...props}
          onClick={handleClick}
        />
      </OverlayTrigger>
    </span>
  );
};

export const InputCopyButton: React.FC<CopyButtonProps> = ({
  value,
  isFormatted = false,
  ...props
}) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClick = () => {
    if (isFormatted) {
      navigator.clipboard.writeText(formatNumber(value));
    } else {
      navigator.clipboard.writeText(value.toString().toUpperCase());
    }

    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 2750);
  };
  return (
    <StyledAppend onClick={handleClick}>
      <InputGroup.Text>
        <OverlayTrigger trigger="click" overlay={show ? renderTooltip : <span />}>
          <CopyButton value={value} isFormatted={isFormatted} {...props} />
        </OverlayTrigger>
      </InputGroup.Text>
    </StyledAppend>
  );
};
