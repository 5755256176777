import React, { ReactNode } from 'react';

import { Td } from '@chakra-ui/react';

import { Deal, DealStateEnum, DealTypeLabelMap } from '../../../../gql/dealGql';
import { Pod, PodUser } from '../../../../gql/generated/graphql';

import Name from '../../../shared/Table/ColumnComponents/Name';

import DealAge from './DealAge';
import DealStateAutoComplete from './DealStateAutoComplete';
import ProcessorAutoComplete from './ProcessorAutoComplete';
import TitleReceivedDateInput from './TitleReceivedDateInput';
import TitleTeamAutocomplete from './TitleTeamAutoComplete';
import UserAutocomplete from './UserAutocomplete';

import { formatDate, formatDateIfTrue } from '../../../../libs/utils';
import { getDealAge } from '../../../../utils/deals';
import { snakeCaseToUpperCase } from '../../../../utils/text';

export const TABLE_HEADERS = {
  AGE: 'Age',
  NAME: 'Name',
  VIN: 'VIN',
  BANK: 'Bank',
  STATE: 'State',
  TYPE: 'Type',
  DEAL_STATE: 'Deal State',
  SIGNED: 'Signed Date',
  CLOSED: 'Closed/Paid Off',
  TITLE_RECEIVED: 'Title Received',
  TITLE_TEAM: 'Title Team',
  TITLE_CLERK: 'Title Clerk',
  TITLE_CLERK2: 'Reg Assistant',
  LIENHOLDER: 'Lienholder',
  PROCESSOR: 'Processor',
};

export type TableHeadersType = typeof TABLE_HEADERS[keyof typeof TABLE_HEADERS];

export interface GetComponentParams {
  deal: Deal;
  titlingPods?: Pod[];
  titlingPodUsers?: PodUser[];
  value?: string;
  collapsed?: boolean;
  highlight?: boolean;
  setCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TableViewColumnsExperimentProps {
  getComponent: (params: GetComponentParams) => ReactNode;
  getValue: (deal: Deal) => string | undefined;
  sortingFieldName: string;
}

export const tableViewColumns: {
  [K in TableHeadersType]: TableViewColumnsExperimentProps;
} = {
  [TABLE_HEADERS.AGE]: {
    getComponent: ({ deal }) => <DealAge deal={deal} />,
    getValue: (deal) =>
      getDealAge(deal.deal_dates?.custom_dates?.signed, deal.completion_date_tz).toString(),
    sortingFieldName: 'deal_dates.custom_dates.signed',
  },
  [TABLE_HEADERS.NAME]: {
    getComponent: ({ deal, collapsed, setCollapsed }) => (
      <Name
        deal={deal}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        value={tableViewColumns[TABLE_HEADERS.NAME].getValue(deal)}
      />
    ),
    getValue: (deal) => `${deal.customer?.first_name} ${deal.customer?.last_name}`,
    sortingFieldName: 'customer.first_name',
  },
  [TABLE_HEADERS.VIN]: {
    getComponent: ({ deal }) => <Td>{tableViewColumns[TABLE_HEADERS.VIN].getValue(deal)}</Td>,
    getValue: (deal) => deal.car.vin.toUpperCase(),
    sortingFieldName: 'car.vin',
  },
  [TABLE_HEADERS.BANK]: {
    getComponent: ({ deal }) => <Td>{tableViewColumns[TABLE_HEADERS.BANK].getValue(deal)}</Td>,
    getValue: (deal) => deal.financial_info?.bank,
    sortingFieldName: 'financial_info.bank',
  },
  [TABLE_HEADERS.STATE]: {
    getComponent: ({ deal }) => <Td>{tableViewColumns[TABLE_HEADERS.STATE].getValue(deal)}</Td>,
    getValue: (deal) => deal.customer.address?.state,
    sortingFieldName: 'customer.address.state',
  },
  [TABLE_HEADERS.TYPE]: {
    getComponent: ({ deal }) => <Td>{tableViewColumns[TABLE_HEADERS.TYPE].getValue(deal)}</Td>,
    getValue: (deal) => (deal.type ? DealTypeLabelMap[deal.type] : ''),
    sortingFieldName: 'type',
  },
  [TABLE_HEADERS.DEAL_STATE]: {
    getComponent: ({ deal, highlight }) => (
      <Td onClick={(e) => e.stopPropagation()} minW="240px">
        <DealStateAutoComplete deal={deal} highlight={highlight} />
      </Td>
    ),
    getValue: (deal) => snakeCaseToUpperCase(deal.state),
    sortingFieldName: 'state',
  },
  [TABLE_HEADERS.SIGNED]: {
    getComponent: ({ deal }) => <Td>{tableViewColumns[TABLE_HEADERS.SIGNED].getValue(deal)}</Td>,
    getValue: (deal) => formatDateIfTrue(deal.deal_dates?.custom_dates?.signed),
    sortingFieldName: 'deal_dates.custom_dates.signed',
  },
  [TABLE_HEADERS.CLOSED]: {
    getComponent: ({ deal }) => <Td>{tableViewColumns[TABLE_HEADERS.CLOSED].getValue(deal)}</Td>,
    getValue: (deal) => formatDate(deal.completion_date_utc ?? ''),
    sortingFieldName: 'completion_date_utc',
  },
  [TABLE_HEADERS.TITLE_RECEIVED]: {
    getComponent: ({ deal }) => (
      <Td minW="200px" onClick={(e) => e.stopPropagation()}>
        <TitleReceivedDateInput
          dealId={deal.id}
          value={tableViewColumns[TABLE_HEADERS.TITLE_RECEIVED].getValue(deal)}
          dates={deal.deal_dates?.custom_dates}
        />
      </Td>
    ),
    getValue: (deal) => deal.deal_dates?.custom_dates?.[DealStateEnum.TitleReceived],
    sortingFieldName: `deal_dates.custom_dates.${DealStateEnum.TitleReceived}`,
  },
  [TABLE_HEADERS.TITLE_TEAM]: {
    getComponent: ({ deal, titlingPods }) => (
      <Td minW="225px" onClick={(e) => e.stopPropagation()}>
        <TitleTeamAutocomplete deal={deal} titlingPods={titlingPods ?? []} />
      </Td>
    ),
    getValue: (deal) => deal.titling_pod?.name,
    sortingFieldName: 'titling_pod.name',
  },
  [TABLE_HEADERS.TITLE_CLERK]: {
    getComponent: ({ deal, titlingPodUsers }) => (
      <Td minW="225px" onClick={(e) => e.stopPropagation()}>
        <UserAutocomplete
          dealProperty="title_clerk_id"
          titlingPodUsers={titlingPodUsers ?? []}
          deal={deal}
          user={deal.title_clerk}
        />
      </Td>
    ),
    getValue: (deal) => deal.title_clerk?.name,
    sortingFieldName: 'title_clerk.name',
  },
  [TABLE_HEADERS.TITLE_CLERK2]: {
    getComponent: ({ deal, titlingPodUsers }) => (
      <Td minW="225px" onClick={(e) => e.stopPropagation()}>
        <UserAutocomplete
          dealProperty="title_clerk2_id"
          titlingPodUsers={titlingPodUsers ?? []}
          deal={deal}
          user={deal.title_clerk2}
        />
      </Td>
    ),
    getValue: (deal) => deal.title_clerk2?.name,
    sortingFieldName: 'title_clerk2.name',
  },
  [TABLE_HEADERS.LIENHOLDER]: {
    getComponent: ({ deal }) => (
      <Td>{tableViewColumns[TABLE_HEADERS.LIENHOLDER].getValue(deal)}</Td>
    ),
    getValue: (deal) => deal.car?.payoff?.lienholder_name,
    sortingFieldName: 'car.payoff.lienholder_name',
  },
  [TABLE_HEADERS.PROCESSOR]: {
    getComponent: ({ deal }) => (
      <Td minW="225px" onClick={(e) => e.stopPropagation()}>
        <ProcessorAutoComplete deal={deal} />
      </Td>
    ),
    getValue: (deal) => deal.financial_info?.processor,
    sortingFieldName: 'financial_info.processor',
  },
};
