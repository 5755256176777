import { useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import { Col, FormGroup, Modal, OverlayTrigger } from 'react-bootstrap';
import { Button } from '@chakra-ui/react';
import styled from 'styled-components';
import { Deal, dealFinalizeAcquisition, DealStateEnum } from '../../gql/dealGql';
import { StyledTooltip } from './MmrButton';
import { NumberInput } from './FormComponents';
import { DealContext } from '../../libs/DealContext';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

// function to determine if payoff is potentially estimated
export const isPayoffEstimated = (payoff: number): boolean => {
  const numStr: string[] = payoff.toString().split('.');

  if (!numStr[1]) {
    return true;
  }

  const last4Chars = numStr[0].slice(-4).split('');

  if (
    last4Chars[0] === last4Chars[1] &&
    last4Chars[0] === last4Chars[2] &&
    last4Chars[0] === last4Chars[3]
  ) {
    return true;
  }

  return false;
};

// function to determine if mileage is potentially estimated
const isMileageEstimated = (mileage: number): boolean => {
  const numStr: string = mileage.toString().split('.')[0];

  if (numStr.length < 3) {
    return false;
  }

  const last2 = numStr.slice(-2);
  if (last2 === '00') {
    return true;
  }

  const last3Chars = numStr.slice(-3).split('');

  if (last3Chars[0] === last3Chars[1] && last3Chars[0] === last3Chars[2]) {
    return true;
  }

  return false;
};

interface FinalizeDealButtonProps {
  showPaperworkOptions: boolean;
  setShowPaperworkOptions: (showPaperworkOptions: boolean) => void;
}

const FinalizeDealButton: React.FC<FinalizeDealButtonProps> = ({
  showPaperworkOptions,
  setShowPaperworkOptions,
}) => {
  const { deal } = useContext(DealContext);
  const { values, isValid, isSubmitting, setSubmitting } = useFormikContext<Deal>();
  const [finalizeAcquisition] = useMutation(dealFinalizeAcquisition);
  const [showModal, setShowModal] = useState(false);

  const onFinalizeDeal = () => {
    finalizeAcquisition({
      variables: {
        id: values.id,
        address_line: values.customer.address?.address_line,
        address_line_2: values.customer.address?.address_line_2,
        city: values.customer.address?.city,
        state: values.customer.address?.state,
        zip: values.customer.address?.zip,
        county: values.customer.address?.county,
        dob: values.customer.dob,
        cash_to_customer: values.financial_info_acquisition.cash_to_customer ?? 0,
        mileage: values.car.mileage,
        vehicle_payoff: values.car.payoff.vehicle_payoff,
      },
    });
    setShowPaperworkOptions(true);
    setShowModal(false);
    setSubmitting(false);
  };

  const hidden = showPaperworkOptions || values.state !== DealStateEnum.Closing;

  const isDisabled = !isValid;
  const renderTooltip = () => (
    <StyledTooltip>
      Missing:
      {!values.customer.dob ? <div>Date of Birth</div> : ''}
      {!values.customer.address?.address_line ? <div>Address</div> : ''}
      {!values.customer.address?.zip ? <div>Zip Code</div> : ''}
      {!values.car.payoff.lienholder_name ? <div>Lienholder</div> : ''}
    </StyledTooltip>
  );

  const renderTitle = (mileage: number, vehicle_payoff: number) => {
    if (isMileageEstimated(mileage) && isPayoffEstimated(vehicle_payoff)) {
      return 'Please confirm the vehicle mileage and vehicle payoff are accurate and not estimates. Are these correct?';
    }
    if (isMileageEstimated(mileage) && !isPayoffEstimated(vehicle_payoff)) {
      return 'Please confirm the vehicle mileage is accurate and not an estimate. Is this correct?';
    }
    return 'Please confirm the vehicle payoff is accurate and not an estimate. Is this correct?';
  };

  return (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={isDisabled ? renderTooltip : <span />}
      >
        <span>
          <Button
            isDisabled={!isValid}
            hidden={hidden}
            onClick={() =>
              isMileageEstimated(deal.car.mileage ?? 0) ||
              isPayoffEstimated(deal.car.payoff.vehicle_payoff ?? 0)
                ? setShowModal(true)
                : onFinalizeDeal()
            }
          >
            FINALIZE DEAL
          </Button>
        </span>
      </OverlayTrigger>
      <Modal
        show={showModal}
        backdrop="static"
        onHide={() => setShowModal(false)}
        keyboard={false}
        dialogClassName="credit-app-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            {renderTitle(deal.car.mileage ?? 0, deal.car.payoff.vehicle_payoff ?? 0)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {isMileageEstimated(deal.car.mileage ?? 0) && (
            <StyledDiv>
              <Col md="2">
                <h5 className="m-0">Mileage</h5>
              </Col>
              <FormGroup className="m-0" as={Col} md={3}>
                <NumberInput path="car.mileage" isWholeNumber thousandSeparator max={9999999} />
              </FormGroup>
            </StyledDiv>
          )}
          {isPayoffEstimated(deal.car.payoff.vehicle_payoff ?? 0) && (
            <StyledDiv>
              <Col md="2">
                <h5 className="m-0">Payoff</h5>
              </Col>
              <FormGroup className="m-0" as={Col} md={3}>
                <NumberInput path="car.payoff.vehicle_payoff" isMoney max={9999999} />
              </FormGroup>
            </StyledDiv>
          )}
          <Button
            isLoading={isSubmitting}
            loadingText="FINALIZE DEAL"
            isDisabled={!isValid}
            hidden={hidden}
            onClick={onFinalizeDeal}
          >
            FINALIZE DEAL
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FinalizeDealButton;
