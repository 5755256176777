import { ChangeEventHandler, FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { NumberFormatValues } from 'react-number-format';

import { ResidenceTypeEnum, residenceTypeOptions } from '../../../gql/addressGql';
import { Customer } from '../../../gql/customerGql';
import { Deal } from '../../../gql/dealGql';

import CardSubHeaderV2 from '../../shared/Card/components/CardSubHeaderV2';
import { GridFormColumn, GridFormRow } from '../../shared/GridForm';
import NumberInput from '../../shared/NumberInput';
import Select from '../../shared/Select';

interface Props extends BoxProps {
  name: keyof Pick<Deal, 'customer' | 'cobuyer'>;
  objectName: keyof Pick<Customer, 'address' | 'prev_address'>;
  isUneditable: boolean;
  showTitle?: boolean;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const ResidenceForm: FC<Props> = ({
  name,
  objectName,
  isUneditable,
  showTitle = true,
  customHandleChange,
  ...rest
}) => {
  const isCobuyer = name === 'cobuyer';
  const isPrevAddress = objectName === 'prev_address';

  const { values, handleChange: formikHandleChange, setFieldValue } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const residenceTypeValue = values[name]?.[objectName]?.residence_type;
  const monthlyPaymentValue = values[name]?.[objectName]?.monthly_payment;

  return (
    <Box mb={5} {...rest}>
      {showTitle ? <CardSubHeaderV2 title="Residence Information" /> : null}
      <GridFormColumn>
        <GridFormRow minChildWidth={135}>
          {!isPrevAddress ? (
            <>
              <Select
                label="Status"
                emptyOption={false}
                name={`${name}.${objectName}.residence_type`}
                onChange={handleChange}
                isDisabled={isUneditable}
                options={residenceTypeOptions}
              />
              <NumberInput
                label="Monthly Payment"
                name={`${name}.${objectName}.monthly_payment`}
                showThousandSeparator
                isMoney
                isWhole
                additionalHandleChange={handleChange}
                isDisabled={residenceTypeValue === ResidenceTypeEnum.Own || isUneditable}
                _input={{
                  value: residenceTypeValue === ResidenceTypeEnum.Own ? 0 : monthlyPaymentValue,
                }}
              />
            </>
          ) : null}
          <NumberInput
            label="Years at Home"
            name={`${name}.${objectName}.years_at_home`}
            isDisabled={isUneditable}
            customOnValueChange={(numberValues: NumberFormatValues) => {
              setFieldValue(`${name}.${objectName}.years_at_home`, numberValues.floatValue);

              if (!isPrevAddress && !isCobuyer && values.cobuyer?.address) {
                setFieldValue(`cobuyer.address.years_at_home`, numberValues.floatValue);
              }

              return numberValues.floatValue;
            }}
            additionalHandleChange={handleChange}
          />
          <NumberInput
            label="Months at Home"
            labelProps={{
              whiteSpace: 'nowrap',
            }}
            name={`${name}.${objectName}.months_at_home`}
            isDisabled={isUneditable}
            additionalHandleChange={handleChange}
          />
          {isPrevAddress ? <Box /> : null}
          {isPrevAddress ? <Box /> : null}
        </GridFormRow>
      </GridFormColumn>
    </Box>
  );
};
