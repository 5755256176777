import { MouseEvent, useMemo } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  PayoffRequestConfirmInput,
  usePayoffRequestConfirmMutation,
} from '../../../../gql/generated/graphql';
import { Payoff } from '../../../../gql/payoffGql';
import { PayoffRequest } from '../../../../gql/payoffRequestGql';

import ConfirmPayoffForm from './ConfirmPayoffForm';

import { useLienholder } from '../../../../hooks/useLienholder';
import { getUTCDate, passValuesToSchema } from '../../../../libs/utils';
import { validateFuture } from '../../../../libs/yup-validators/dates';
import { MAX_PAYOFF_AMOUNT } from '../../../../libs/yup-validators/payoff';
import Loader from '../../../Loader';

type ConfirmPayoffProps = {
  disabled?: boolean;
  payoffRequest?: PayoffRequest;
};

const ConfirmPayoff = ({ disabled, payoffRequest }: ConfirmPayoffProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [payoffRequestConfirm] = usePayoffRequestConfirmMutation();

  const data = payoffRequest?.deal_id
    ? payoffRequest.deal ?? {}
    : payoffRequest?.temporary_info?.data ?? {};
  const { includeSalesTax, loading: lienholderLoading } = useLienholder({
    data,
    skip: !isOpen,
  });

  const initialValues: Partial<Payoff> = useMemo(
    () => ({
      vehicle_payoff: undefined,
      payoff_includes_sales_tax: includeSalesTax,
      sales_tax_from_payoff_entered_manually: false,
      sales_tax_from_payoff: undefined,
      good_through_date: '',

      doubleTaxApplied: false,
      previousTotalPayoff: undefined,
      totalPayoff: undefined,
      user_entered_total_payoff: undefined,
    }),
    [includeSalesTax],
  );

  const validationSchema = Yup.object().shape({
    vehicle_payoff: Yup.number().optional().nullable(),
    good_through_date: Yup.date()
      .nullable()
      .test('futureDate', 'Date should be in the future', validateFuture)
      .required('Please enter good through date'),
    payoff_includes_sales_tax: Yup.boolean(),
    sales_tax_from_payoff_entered_manually: Yup.boolean(),
    sales_tax_from_payoff: Yup.number()
      .when('sales_tax_from_payoff_entered_manually', {
        is: true,
        then: Yup.number().required('Please enter a value').nullable(),
      })
      .nullable(),
    next_payment_date: Yup.date().optional().nullable(true),
    user_entered_total_payoff: Yup.number().optional().nullable(true),

    doubleTaxApplied: Yup.boolean().optional().nullable(),
    previousTotalPayoff: Yup.number().optional().nullable(),
    totalPayoff: Yup.number()
      .nullable()
      .lessThan(MAX_PAYOFF_AMOUNT, 'Must be less than 1 million')
      .required('Please enter a value'),
  });

  const onSubmit = async (
    values: Partial<Payoff>,
    needsPayoffDocuments: boolean,
    payoffDocumentsUploaded: boolean,
  ) => {
    if (!values.good_through_date) {
      return;
    }

    let requestPayoffInput: PayoffRequestConfirmInput = {};
    if (payoffRequest?.deal_id) {
      const updatedDeal = {
        ...payoffRequest.deal,
        car: {
          ...payoffRequest.deal.car,
          payoff: {
            ...payoffRequest.deal.car.payoff,
            ...values,
          },
        },
      };

      if (updatedDeal.car.payoff.sales_tax_from_payoff === undefined) {
        toast.error('Error getting sales tax. Please try again.');
        return;
      }

      requestPayoffInput = {
        id: payoffRequest.id,
        vehicle_payoff: updatedDeal.car.payoff.vehicle_payoff,
        good_through_date: getUTCDate(updatedDeal.car.payoff.good_through_date ?? ''),
        sales_tax: updatedDeal.car.payoff.sales_tax_from_payoff,
        payoff_includes_sales_tax: updatedDeal.car.payoff.payoff_includes_sales_tax,
        sales_tax_from_payoff_entered_manually:
          updatedDeal.car.payoff.sales_tax_from_payoff_entered_manually,
        needs_payoff_documents: needsPayoffDocuments,
        payoff_documents_uploaded: payoffDocumentsUploaded,
        user_entered_total_payoff: updatedDeal.car.payoff.user_entered_total_payoff,
        double_tax_applied: updatedDeal.car.payoff.doubleTaxApplied,
      };
    } else {
      const updatedTemporaryData = {
        ...payoffRequest?.temporary_info?.data,
        ...values,
      };

      if (updatedTemporaryData.sales_tax_from_payoff === undefined) {
        toast.error('Error getting sales tax. Please try again.');
        return;
      }

      requestPayoffInput = {
        id: payoffRequest?.id,
        vehicle_payoff: updatedTemporaryData.vehicle_payoff,
        good_through_date: getUTCDate(updatedTemporaryData.good_through_date ?? ''),
        sales_tax: updatedTemporaryData.sales_tax_from_payoff,
        payoff_includes_sales_tax: updatedTemporaryData.payoff_includes_sales_tax,
        sales_tax_from_payoff_entered_manually:
          updatedTemporaryData.sales_tax_from_payoff_entered_manually,
        user_entered_total_payoff: updatedTemporaryData.user_entered_total_payoff,
        double_tax_applied: updatedTemporaryData.doubleTaxApplied,
      };
    }

    try {
      await payoffRequestConfirm({
        variables: {
          payoffRequest: requestPayoffInput,
        },
      });

      onClose();
      toast.success('Payoff confirmed');
    } catch (error) {
      toast.error('Failed to confirm Payoff');
    }
  };

  return (
    <>
      <Button
        size="sm"
        width="75px"
        isDisabled={disabled}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        CONFIRM
      </Button>

      {lienholderLoading ? (
        <Loader isLoading />
      ) : payoffRequest ? (
        <Formik
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          initialValues={initialValues}
          validate={(value) => passValuesToSchema(value, validationSchema)}
          enableReinitialize
        >
          <ConfirmPayoffForm
            isOpen={isOpen}
            payoffRequest={payoffRequest}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        </Formik>
      ) : null}
    </>
  );
};

export default ConfirmPayoff;
