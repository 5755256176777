import { components, GroupBase, MultiValueProps, Options, PropsValue } from 'react-select';

import MoreBadge from '../../../../shared/MoreBadge';
import { Option } from '../../../../shared/types';

interface LimitedMultiValueProps
  extends MultiValueProps<PropsValue<Option>, true, GroupBase<PropsValue<Option>>> {
  maxToShow?: number;
}

const LimitedMultiValue = ({ maxToShow = 1, ...rest }: LimitedMultiValueProps) => {
  const { index, getValue } = rest;
  const values: Options<unknown> = getValue();
  const overflow = (values as Option[]).slice(maxToShow);

  return index < maxToShow ? (
    <components.MultiValue {...rest} />
  ) : index === maxToShow ? (
    <MoreBadge number={overflow.length} />
  ) : null;
};

export default LimitedMultiValue;
