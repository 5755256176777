import { gql } from '@apollo/client';

export const mmrQuery = gql`
  query mmr(
    $vin: String!
    $mileage: Int!
    $color: String!
    $grade: Float!
    $trim: String!
    $state: String!
  ) {
    mmr(vin: $vin, mileage: $mileage, color: $color, grade: $grade, trim: $trim, state: $state) {
      average
    }
  }
`;
