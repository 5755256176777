import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Flex, FlexProps, HStack, StyleProps, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, DealStateEnum } from '../../../gql/dealGql';
import { DealType, TtJurisdiction, useNoteCreateMutation } from '../../../gql/generated/graphql';
import { NoteTypeEnum } from '../../../gql/noteGql';

import Switch from '../../shared/Switch';
import { DebouncedSave, switchContainerProps } from '../DealInfoBuyoutForm';

import InputRow from './InputRow';
import { Registration } from './Registration';
import { Taxes } from './Taxes';
import CalcFeesButton from './buttons/CalcFeesButton';

import { LE_TIME_ZONE } from '../../../constants/LeaseEndInfo';
import { useUser } from '../../../hooks/useUser';
import { DealContext } from '../../../libs/DealContext';
import { NotesContext } from '../../../libs/contextLib';
import { formatMoney } from '../../../libs/utils';
import { isNullOrUndefined } from '../../../utils';

export type InsertNoteIfValueManuallyChangedArgs = {
  fieldName: 'registration' | 'taxes';
  oldValue?: number;
  newValue?: number;
};

type TitleAndRegistrationSectionProps = FlexProps & {
  isEditable: boolean;
  debouncedSave: DebouncedSave;
  jurisdiction: TtJurisdiction;
  defaultFontSize?: StyleProps['fontSize'];
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
};

export const TitleAndRegistrationSection: FC<TitleAndRegistrationSectionProps> = ({
  isEditable,
  debouncedSave,
  defaultFontSize = 'sm',
  jurisdiction,
  customHandleChange,
  ...rest
}) => {
  const { deal } = useContext(DealContext);
  const { notesRefetch } = useContext(NotesContext);
  const user = useUser();

  const { values, setFieldValue, handleChange: formikHandleChange } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const [titleOnlyDisabled, setTitleOnlyDisabled] = useState<boolean>(false);

  const isRefi = deal.type === DealType.Refi;
  const inClosing = deal.state === DealStateEnum.Closing;
  const inStructuring = deal.state === DealStateEnum.Structuring;

  const [createNote] = useNoteCreateMutation();

  useEffect(() => {
    if (isRefi) {
      return;
    }

    if (
      isNullOrUndefined(jurisdiction.hasTitleOnly) ||
      isNullOrUndefined(jurisdiction.hasTitleAndRegistration)
    ) {
      return;
    }

    if (isNullOrUndefined(values.financial_info.title_only)) {
      if (jurisdiction.hasTitleOnly === false) {
        setTitleOnlyDisabled(true);
        setFieldValue('financial_info.title_only', false);
      } else if (jurisdiction.hasTitleAndRegistration === false) {
        setTitleOnlyDisabled(true);
        setFieldValue('financial_info.title_only', true);
      } else {
        setTitleOnlyDisabled(false);
        setFieldValue('financial_info.title_only', false);
      }
    } else if (
      (jurisdiction.hasTitleOnly === false && values.financial_info.title_only === false) ||
      (jurisdiction.hasTitleAndRegistration === false && values.financial_info.title_only === true)
    ) {
      setTitleOnlyDisabled(true);
    } else {
      setTitleOnlyDisabled(false);
    }

    if (
      isNullOrUndefined(values.financial_info.plate_transfer) &&
      jurisdiction.allowPlateTransfer === false
    ) {
      setFieldValue('financial_info.plate_transfer', false);
    }
  }, [jurisdiction, values.financial_info.title_only]);

  const handleTitleOnlyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('financial_info.title_only', e.target.checked);

    if (
      (jurisdiction.hasTitleAndRegistration === false && !e.target.checked) ||
      (jurisdiction.hasTitleOnly === false && e.target.checked)
    ) {
      return;
    }
    if (e.target.checked && values.financial_info?.plate_transfer) {
      setFieldValue('financial_info.plate_transfer', false);
    }

    handleChange(e);
  };

  const insertNoteIfValueManuallyChanged = useCallback(
    async ({ fieldName, oldValue, newValue }: InsertNoteIfValueManuallyChangedArgs) => {
      if (oldValue === newValue) {
        return;
      }

      await createNote({
        variables: {
          note: {
            author_id: user.id,
            deal_id: deal.id,
            creation_date_tz: LE_TIME_ZONE,
            note_type: NoteTypeEnum.System,
            text: `Calculated ${fieldName} of ${
              formatMoney(oldValue) || '$0.00'
            } manually updated to ${formatMoney(newValue) || '$0.00'}.`,
          },
        },
      });

      await notesRefetch();
    },
    [user.id, deal.id, notesRefetch],
  );

  return (
    <Flex direction="column" {...rest}>
      {!isRefi ? (
        <InputRow label="Title Only">
          <Switch
            id="title_only"
            name="financial_info.title_only"
            isNoYes
            noYesFontSize={defaultFontSize}
            isDisabled={titleOnlyDisabled || !isEditable}
            customHandleChange={handleTitleOnlyChange}
            {...switchContainerProps}
          />
        </InputRow>
      ) : null}
      <InputRow label="Title">
        <Text variant="dealInfoBuyout">{formatMoney(values.financial_info?.title)}</Text>
      </InputRow>
      {!isRefi ? (
        <InputRow label="Taxes">
          <Taxes
            dealDetailsPageBeingEdited={isEditable}
            debouncedSave={debouncedSave}
            insertNoteIfValueManuallyChanged={insertNoteIfValueManuallyChanged}
            fontSize={defaultFontSize}
          />
        </InputRow>
      ) : null}
      {!isRefi ? (
        <InputRow label="Registration">
          <Registration
            dealDetailsPageBeingEdited={isEditable}
            debouncedSave={debouncedSave}
            insertNoteIfValueManuallyChanged={insertNoteIfValueManuallyChanged}
            fontSize={defaultFontSize}
          />
        </InputRow>
      ) : null}
      <InputRow label="Doc Fee">
        <Text variant="dealInfoBuyout">{formatMoney(values.financial_info?.doc_fee)}</Text>
      </InputRow>
      <HStack alignItems="start" mt={3}>
        {!isRefi && (inStructuring || inClosing) ? <CalcFeesButton /> : null}
      </HStack>
    </Flex>
  );
};
