import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, DealStateEnum, acquisitionDealInfoUpsert } from '../../gql/dealGql';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { cleanDealForUpdate } from '../../utils/deals';

const SaveButton: React.FC = () => {
  const { dealRefetch } = useContext(DealContext);
  const { values } = useFormikContext<Deal>();
  const [isSaving, setSaving] = useState(false);
  const [upsertAcquisitionDealInfo] = useMutation(acquisitionDealInfoUpsert);

  const onSave = (deal: Deal) => {
    const newDeal = cleanDealForUpdate(deal);

    upsertAcquisitionDealInfo({
      variables: {
        deal: newDeal,
      },
    })
      .then(() => dealRefetch())
      .catch((e) => logger.error('SaveButtonSoftClose.tsx', '', null, e))
      .finally(() => setSaving(false));
  };

  return (
    <Button
      variant="secondary"
      isLoading={isSaving}
      loadingText="SAVE"
      hidden={values.state !== DealStateEnum.SoftClose}
      onClick={() => {
        setSaving(true);
        onSave(values);
      }}
    >
      SAVE
    </Button>
  );
};

export default SaveButton;
