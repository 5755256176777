import { gql } from '@apollo/client/core';

export enum AccidentsEnum {
  NoAccidents = 'no_accidents',
  OneAccident = 'one_accident',
  TwoOrMoreAccidents = 'two_or_more_accidents',
}

export enum TiresEnum {
  New = 'new',
  Fine = 'fine',
  NeedReplacing = 'need_replacing',
}

export enum ExteriorEnum {
  MinorScuffs = 'minor_scuffs',
  PaintDamage = 'paint_damage',
  DentsOrDings = 'dents_or_dings',
  Rusting = 'rusting',
  HailDamage = 'hail_damage',
  GlassWindshieldDamage = 'glass_windshield_damage',
}

export enum InteriorEnum {
  Stains = 'stains',
  RipsOrTears = 'rips_or_tears',
  SmellsOrOdors = 'smells_or_odors',
  EntertainmentConsoleDamage = 'entertainment_console_damage',
}

export enum OverallConditionEnum {
  LikeNew = 'like_new',
  SlightlyUsed = 'slightly_used',
  NeedsWork = 'needs_work',
}

export class ConditionReport {
  [x: string]: any;
  id?: number;
  deal_id?: number;
  accidents?: string;
  tires?: string;
  exterior?: string[];
  interior?: string[];
  smoked_in?: boolean;
  lights_on_dash?: boolean;
  overall_condition?: string;

  constructor(
    id?: number,
    deal_id?: number,
    accidents?: string,
    tires?: string,
    exterior?: string[],
    interior?: string[],
    smoked_in?: boolean,
    lights_on_dash?: boolean,
    overall_condition?: string,
    score?: number,
  ) {
    this.id = id;
    this.deal_id = deal_id;
    this.accidents = accidents;
    this.tires = tires;
    this.exterior = exterior;
    this.interior = interior;
    this.smoked_in = smoked_in;
    this.lights_on_dash = lights_on_dash;
    this.overall_condition = overall_condition;
  }
}

export const conditionReportUpsert = gql`
  mutation conditionReportUpsert($conditionReport: conditionReportUpsertInput) {
    conditionReportUpsert(conditionReport: $conditionReport) {
      id
    }
  }
`;

export const determineApprovalQuery = gql`
  query determineApprovalQuery($data: determineApprovalQueryInput) {
    determineApprovalQuery(data: $data) {
      approved
      failedCriteria
    }
  }
`;
