import { RefObject, useEffect } from 'react';

const useClickOutsideAction = (
  ref: RefObject<HTMLElement>,
  action: () => void,
  isCustomReactSelect = false,
) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Fix for react-select portaled to document.body
      if (isCustomReactSelect && (e.target as HTMLElement).id.includes('react-select-')) {
        return;
      }

      if (ref.current && !ref.current.contains(e.target as Node)) {
        action();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, action]);
};

export default useClickOutsideAction;
