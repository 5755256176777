import { Box } from '@chakra-ui/react';
import React from 'react';
import { getInitials } from '../../../utils/text';
import { getConversationColor } from '../TextModal';

interface CustomerBubbleProps {
  firstName: string;
  lastName: string;
  customerType: string;
}

const CustomerBubble: React.FC<CustomerBubbleProps> = ({ firstName, lastName, customerType }) => {
  const color = getConversationColor(customerType);
  return (
    <Box
      borderRadius="50%"
      w="42px"
      h="42px"
      bgColor={color}
      color="white"
      fontSize={19}
      p="7px 2px 2px 2px"
      textAlign="center"
      alignSelf="end"
    >
      {getInitials(`${firstName} ${lastName}`)}
    </Box>
  );
};

export default CustomerBubble;
