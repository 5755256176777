import { useContext, useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import Big from 'big.js';
import { useFormikContext } from 'formik';
import { Col, Form, ListGroup, Modal } from 'react-bootstrap';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

import { acquisitionOfferQuery } from '../../gql/acquisitionsGql';
import { determineApprovalQuery } from '../../gql/conditionReportGql';
import { Deal, DealStateEnum } from '../../gql/dealGql';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';
import { PaperworkType } from '../../gql/generated/graphql';

import ConditionReport from '../ConditionReport';
import MarkAsSentButton from '../DealInfoBuyout/components/buttons/MarkAsSentButton';
import EditDealInfoButton from '../EditDealInfoButton';
import GenerateAndSendContracts from '../GenerateDocs/GenerateAndSendContractsButton';
// eslint-disable-next-line import/order
import styled from 'styled-components';

import { NumberInput } from './FormComponents';
import SaveButton from './SaveButton';
import SaveEditButton from './SaveEditButton';
import SendToClosing from './SendToClosingButton';

import { DealContext } from '../../libs/DealContext';
import { ColorsEnum } from '../../libs/colorLib';
import { formatMoney } from '../../libs/utils';

const StyledRow = styled(Form.Row)`
  justify-content: center;
  align-items: center;
`;

const showButtonsStates = [
  DealStateEnum.Structuring,
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Signed,
];

const StructuringAndClosedView = () => {
  const { deal } = useContext(DealContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showXModal, setShowXModal] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<Deal>();
  const { financial_info_acquisition, car, condition_report, customer } = values;
  const {
    payoff: { vehicle_payoff },
  } = car;
  const {
    appraised_value,
    cash_to_customer,
    auction_fees,
    transport_cost,
    is_approved,
    max_total_cost,
  } = financial_info_acquisition;
  const [isEditing, setIsEditing] = useState(false);
  const isEsign = deal.paperwork_type === PaperworkType.Esign;

  let isConditionReportCompleted = false;
  if (
    condition_report?.accidents &&
    condition_report?.tires &&
    condition_report?.smoked_in !== null &&
    condition_report?.lights_on_dash !== null &&
    condition_report?.overall_condition
  ) {
    isConditionReportCompleted = true;
  }

  const [determineApproval, { data }] = useLazyQuery(determineApprovalQuery, {
    fetchPolicy: 'network-only',
  });

  const isApproved: boolean = data?.determineApprovalQuery?.approved ?? is_approved;
  const failedCriteria: string[] = data?.determineApprovalQuery?.failedCriteria ?? [];

  const [acquisitionOffer] = useLazyQuery(acquisitionOfferQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ acquisitionOffer: { appraisedValue, offer } }) => {
      if (!appraised_value) {
        setFieldValue('financial_info_acquisition.appraised_value', appraisedValue);
      }
      setFieldValue('financial_info_acquisition.max_total_cost', offer);
    },
  });

  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const accidents = condition_report?.accidents;
    const mileage = car?.mileage;
    const mmr_value = car?.mmr;
    const book_value = car?.book_value;
    const vehicle_type = car?.vehicle_type;
    const make = car?.make;
    const lienholder = car?.payoff?.lienholder_name;

    if (accidents && mileage && mmr_value && book_value && vehicle_type) {
      acquisitionOffer({
        variables: {
          accidents,
          mileage,
          mmr_value,
          book_value,
          vehicle_type,
          make,
          lienholder,
        },
      });
    }
  }, [car, condition_report]);

  useEffect(() => {
    if (isConditionReportCompleted) {
      determineApproval({
        variables: {
          data: {
            accidents: condition_report?.accidents,
            tires: condition_report?.tires,
            exterior: condition_report?.exterior,
            interior: condition_report?.interior,
            smoked_in: condition_report?.smoked_in,
            lights_on_dash: condition_report?.lights_on_dash,
            overall_condition: condition_report?.overall_condition,
            year: car.year,
            payoff: vehicle_payoff,
            book_value: car.book_value,
            kbb_trim: car.kbb_trim_name,
            vin: car.vin,
            mileage: car.mileage,
            color: car.color,
            vehicle_type: car.vehicle_type,
            state: customer.address?.state,
            financial_info_acquisition_id: values.financial_info_acquisition.id,
          },
        },
      });
    }
  }, [condition_report, isConditionReportCompleted, car]);

  useEffect(() => {
    if (max_total_cost && vehicle_payoff) {
      let maxCashToCustomer = new Big(max_total_cost).minus(vehicle_payoff).toNumber();

      if (maxCashToCustomer < 0) {
        maxCashToCustomer = 0;
      }

      setFieldValue('financial_info_acquisition.max_cash_to_customer', maxCashToCustomer);
    }
  }, [max_total_cost, vehicle_payoff]);

  useEffect(() => {
    const totalCost =
      (vehicle_payoff ?? 0) + (cash_to_customer ?? 0) + (auction_fees ?? 0) + (transport_cost ?? 0);

    setFieldValue('financial_info_acquisition.total_cost', totalCost);
  }, [vehicle_payoff, cash_to_customer, auction_fees, transport_cost]);

  useEffect(() => {
    upsertFinancialInfoAcquisition({
      variables: {
        financialInfoAcquisition: financial_info_acquisition,
        id: deal.id,
        state: deal.state,
        car_id: car?.id,
        mmr: car?.mmr,
        vehicle_payoff: car?.payoff?.vehicle_payoff,
      },
    });
  }, [financial_info_acquisition]);

  return (
    <>
      <StyledRow>
        <Col md="2">
          <h5>Payoff</h5>
        </Col>
        <Form.Group as={Col} md="2">
          <NumberInput isMoney path="car.payoff.vehicle_payoff" isEditing={isEditing} />
        </Form.Group>
        <Col md="2">
          <h5>KBB Lending</h5>
        </Col>
        <Form.Group as={Col} md="2">
          <NumberInput isMoney path="car.book_value" isEditing={isEditing} />
        </Form.Group>
      </StyledRow>
      <StyledRow>
        {deal.state === DealStateEnum.Structuring ? (
          <>
            <Col md="2">
              <h5>Max Cash To Customer</h5>
            </Col>
            <Col md="2">
              <h5>{formatMoney(values.financial_info_acquisition.max_cash_to_customer)}</h5>
            </Col>
          </>
        ) : (
          <>
            <Col md="2">
              <h5>Cash To Customer</h5>
            </Col>
            <Form.Group as={Col} md="2">
              <NumberInput
                isMoney
                path="financial_info_acquisition.cash_to_customer"
                isEditing={isEditing}
              />
            </Form.Group>
          </>
        )}
        <Col md="2">
          <h5>MMR</h5>
        </Col>
        <Form.Group as={Col} md="2">
          <NumberInput isMoney path="car.mmr" isEditing={isEditing} />
        </Form.Group>
      </StyledRow>
      <StyledRow className="mb-2">
        {deal.state === DealStateEnum.Structuring ? (
          <>
            <Col md="2">
              <h5>Max Total Cost</h5>
            </Col>
            <Col md="2">
              <h5>{formatMoney(values.financial_info_acquisition.max_total_cost)}</h5>
            </Col>
          </>
        ) : (
          <>
            <Col md="2">
              <h5>Total Cost</h5>
            </Col>
            <Col md="2">
              <h5>{formatMoney(values.financial_info_acquisition.total_cost)}</h5>
            </Col>
          </>
        )}
        <Col md="2">
          <h5>Appraised Value</h5>
        </Col>
        <Form.Group as={Col} md="2">
          <NumberInput
            isMoney
            path="financial_info_acquisition.appraised_value"
            isEditing={isEditing}
          />
        </Form.Group>
      </StyledRow>
      {showButtonsStates.includes(deal.state) && (
        <StyledRow>
          <Button
            size="lgWithIconRight"
            rightIcon={
              isConditionReportCompleted ? (
                <BiCheckCircle color="green" />
              ) : (
                <BiXCircle color="errorsRed" />
              )
            }
            isLoading={showModal}
            onClick={() => setShowModal(true)}
          >
            VIEW CONDITION REPORT
          </Button>
          <Modal
            show={showModal}
            backdrop="static"
            onHide={() => setShowModal(false)}
            keyboard={false}
            dialogClassName="condition-report-modal"
            className="text-center"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center w-100">Condition Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ConditionReport setShowModal={setShowModal} isEditing={isEditing} />
            </Modal.Body>
          </Modal>
          <SaveButton />
          <SendToClosing />
          <EditDealInfoButton isEditing={isEditing} setIsEditing={setIsEditing} />
          <SaveEditButton isEditing={isEditing} setIsEditing={setIsEditing} />
          <GenerateAndSendContracts />
          {isEsign && <MarkAsSentButton />}
        </StyledRow>
      )}
      <StyledRow>
        {!!deal.financial_info_acquisition?.cash_to_customer &&
          deal.state === DealStateEnum.Structuring && (
            <>
              <Col md="2">
                <h5>Cash To Customer</h5>
              </Col>
              <Form.Group as={Col} md="2" className="mt-4">
                <NumberInput
                  isMoney
                  path="financial_info_acquisition.cash_to_customer"
                  isEditing={isEditing}
                />
              </Form.Group>
            </>
          )}
      </StyledRow>
      {deal.state === DealStateEnum.Structuring && (
        <StyledRow>
          {isApproved ? (
            <BiCheckCircle size="200" className="ml-2 mb-4" color={ColorsEnum.GREEN} />
          ) : (
            <>
              <BiXCircle
                size="200"
                className="ml-2 mb-4"
                color={ColorsEnum.RED}
                onClick={() => setShowXModal(true)}
              />
              <Modal
                show={showXModal}
                backdrop="static"
                onHide={() => setShowXModal(false)}
                keyboard={false}
                dialogClassName="not-approved-modal"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title className="text-center w-100">
                    Reasons for not being approved...
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ListGroup>
                    {failedCriteria?.map((fc) => {
                      return (
                        <ListGroup.Item key={fc} variant="warning">
                          {fc}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Modal.Body>
              </Modal>
            </>
          )}
        </StyledRow>
      )}
    </>
  );
};

export default StructuringAndClosedView;
