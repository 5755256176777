import { useContext, useState } from 'react';

import { endOfDay, startOfDay } from 'date-fns';

import { Deal, DealStateEnum } from '../gql/dealGql';
import {
  DealType,
  useCompletedDealsQuery,
  useOnDealCompleteSubscription,
} from '../gql/generated/graphql';
import { User } from '../gql/userGql';

import { FiltersContext } from '../libs/contextLib';

export interface CompletedDealsProps {
  startDate: Date;
  endDate: Date;
  addressState?: string;
  assignedToMeFilter?: boolean;
  bootRequestedFilter?: boolean;
  assignedToDeletedUsersFilter?: boolean;
  dealState?: DealStateEnum;
  notifications?: boolean;
  skip?: boolean;
  user?: User;
  showActiveTags?: boolean;
  showMatureFollowUps?: boolean;
}

interface UseCompletedDealsProps extends CompletedDealsProps {
  types: DealType[];
}

export const useCompletedDeals = ({
  startDate,
  endDate,
  addressState,
  assignedToMeFilter = false,
  bootRequestedFilter = false,
  assignedToDeletedUsersFilter = false,
  types,
  dealState,
  notifications,
  skip = false,
  user,
  showActiveTags,
  showMatureFollowUps,
}: UseCompletedDealsProps) => {
  const {
    filters: {
      global: { selectedPodId, sources },
    },
  } = useContext(FiltersContext);

  const [completedDeals, setCompletedDeals] = useState<Deal[]>([]);
  const completedDealsQueryResult = useCompletedDealsQuery({
    skip,
    fetchPolicy: 'cache-and-network',
    variables: {
      assigned_to_me_filter: assignedToMeFilter,
      boot_requested_filter: bootRequestedFilter,
      assigned_to_deleted_users_filter: assignedToDeletedUsersFilter,
      start_date: startOfDay(startDate),
      end_date: endOfDay(endDate),
      sources,
      types,
      user_id: user && user.id,
      deal_state: dealState,
      address_state: addressState,
      pod_id: selectedPodId,
      notifications,
      showActiveTags,
      showMatureFollowUps,
    },
    onCompleted: (data) => {
      if (data?.completedDeals) {
        // TODO: temporary conversion. We should remove all classes and use the generated classes
        setCompletedDeals((data.completedDeals || []) as Deal[]);
      }
    },
  });

  useOnDealCompleteSubscription({
    skip,
    variables: { sources, types },
    onData: ({ data }) => {
      if (data?.data?.onDealComplete) {
        completedDealsQueryResult.refetch();
      }
    },
  });

  return { query: completedDealsQueryResult, completedDeals };
};
