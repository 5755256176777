import { useContext, useMemo } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Button, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../gql/generated/graphql';
import { Note } from '../../gql/noteGql';

import PayoffCheckInformationModal from '../PayoffInfo/PayoffCheckInformationModal';

import { useUser } from '../../hooks/useUser';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { AbilityContext, NotesContext } from '../../libs/contextLib';
import MoveToFinalizedModal from '../../pages/DealDetail/ProgressionButtons/MoveToFinalizedModal';
import { isClaimRequired } from '../../utils/permissions';

const STATE_UPDATE_MAPPING: Partial<Record<DealStateEnum, DealStateEnum>> = {
  [DealStateEnum.Funded]: DealStateEnum.SendPayoff,
  [DealStateEnum.SendPayoff]: DealStateEnum.WaitingForTitle,
  [DealStateEnum.WaitingForTitle]: DealStateEnum.TitleReceived,
  [DealStateEnum.TitleReceived]: DealStateEnum.SentToProcessor,
  [DealStateEnum.SentToProcessor]: DealStateEnum.Finalized,
};

const BUTTON_LABEL_MAPPING: Partial<Record<DealStateEnum, string>> = {
  [DealStateEnum.Funded]: 'MOVE TO SEND PAYOFF',
  [DealStateEnum.SendPayoff]: 'FINALIZE PAYOFF',
  [DealStateEnum.WaitingForTitle]: 'READY FOR CLERK',
  [DealStateEnum.TitleReceived]: 'SEND TO PROCESSOR/DMV',
  [DealStateEnum.SentToProcessor]: 'FINALIZE DEAL',
};

interface TitleWorkButtonProps {
  deal: Deal;
  dealRefetch?: () => Promise<ApolloQueryResult<Deal>>;
  notesRefetch?: () => Promise<ApolloQueryResult<Note[]>>;
}

export const TitleWorkButton = ({ deal, dealRefetch, notesRefetch }: TitleWorkButtonProps) => {
  const user = useUser();
  const abilities = useContext(AbilityContext);

  const claimIsRequired = useMemo(
    () => isClaimRequired(deal, abilities, user),
    [deal, abilities, user],
  );

  const moveToFinalizedModal = useDisclosure();
  const payoffCheckInformationModal = useDisclosure();

  const [updateDealState] = useDealUpdateStateMutation();

  const nextState = STATE_UPDATE_MAPPING[deal.state];
  const nextStateLabel = nextState ? DealStateLabelMap[nextState] : 'New State';
  const buttonLabel = BUTTON_LABEL_MAPPING[deal.state];

  const updateState = async () => {
    if (!deal.id) {
      return;
    }
    try {
      await updateDealState({
        variables: {
          id: deal.id,
          state: nextState,
        },
      });
      await dealRefetch?.();
      toast.success(`Moved deal to "${nextStateLabel}"`);
    } catch (e) {
      toast.error(`Failed to move deal to "${nextStateLabel}". Please refresh the page`);
      logger.error(
        'TitleWorkButton.tsx',
        `Failed to move deal to "${nextStateLabel}"`,
        { id: deal.id, state: nextState },
        e,
      );
    }
  };

  const onMoveToNextState = () => {
    if (nextState === DealStateEnum.Finalized) {
      if (!deal.financial_info.processor) {
        toast.error('You must select a processor to finalize the deal');
        return;
      }
      moveToFinalizedModal.onOpen();
      return;
    }

    const isFirstTime = !deal.deal_dates?.dates?.waiting_for_title;
    if (nextState === DealStateEnum.WaitingForTitle && isFirstTime) {
      payoffCheckInformationModal.onOpen();
      return;
    }

    updateState();
  };

  return (
    <>
      <Button onClick={onMoveToNextState} isDisabled={claimIsRequired}>
        {buttonLabel}
      </Button>

      <MoveToFinalizedModal
        isOpen={moveToFinalizedModal.isOpen}
        onClose={moveToFinalizedModal.onClose}
        handleConfirm={updateState}
      />
      <PayoffCheckInformationModal
        deal={deal}
        isOpen={payoffCheckInformationModal.isOpen}
        onClose={payoffCheckInformationModal.onClose}
        handleConfirm={updateState}
        notesRefetch={notesRefetch}
      />
    </>
  );
};

export const TitleWorkButtonWithContext = () => {
  const { deal, dealRefetch } = useContext(DealContext);
  const { notesRefetch } = useContext(NotesContext);

  return <TitleWorkButton deal={deal} dealRefetch={dealRefetch} notesRefetch={notesRefetch} />;
};
