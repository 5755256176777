import { useEffect, useState } from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import {
  HuntGroup,
  RoleIdEnumType,
  useGetTwilioNumbersLazyQuery,
} from '../../../gql/generated/graphql';
import { Pod } from '../../../gql/podGql';

import LEMultiSelect, { BaseOption } from '../../../components/LEMultiSelect/LEMultiSelect';
import Input from '../../../components/shared/Input';
import Select from '../../../components/shared/Select';
import { Option } from '../../../components/shared/types';

import { CreateUserModalFormikFields } from './CreateUserModal';

interface CreateUserModalFormProps {
  pods: Pod[];
  huntGroups: HuntGroup[];
}

// make roleIdEnumType an array
const roles = Object.values(RoleIdEnumType).map((role) => ({
  id: role,
  name: role,
}));

const CreateUserModalForm = ({ pods, huntGroups }: CreateUserModalFormProps) => {
  const [searchTwilio, setSearchTwilio] = useState<string>('');
  const { handleChange } = useFormikContext<CreateUserModalFormikFields>();
  const [numberOptions, setNumberOptions] = useState<Option[]>([]);

  const [searchTwilioNumbers, { data: twilioNumbers, loading: loadingTwilioNumbers }] =
    useGetTwilioNumbersLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (twilioNumbers?.getTwilioNumbers) {
      const currentOptions = numberOptions;

      setNumberOptions([
        ...currentOptions,
        ...twilioNumbers.getTwilioNumbers.map((n) => ({
          label: n?.friendlyName as string,
          value: n?.number as string,
        })),
      ]);
    }
  }, [twilioNumbers]);

  return (
    <Flex direction="column" gap={2}>
      <Flex gap={2} alignItems="flex-start">
        <Flex gap={2} flex={1} alignItems="flex-end">
          <Input
            label="Search Twilio Numbers"
            name="searchTwilioNumbers"
            onChange={(e) => {
              setSearchTwilio(e.target.value);
            }}
            value={searchTwilio}
          />

          <Button
            marginBottom={0}
            height={8}
            variant="secondary"
            onClick={() => {
              searchTwilioNumbers({ variables: { search: searchTwilio } });
            }}
          >
            {loadingTwilioNumbers ? 'Loading...' : 'Search'}
          </Button>
        </Flex>
        <Select name="phone_number" flex={1} label="Phone Number" options={numberOptions} />
      </Flex>
      <Flex gap={2}>
        <Input label="Name" name="name" onChange={handleChange} />
      </Flex>

      <Flex gap={2}>
        <Input label="Email" name="email" onChange={handleChange} />
        <Input label="Password" name="password" onChange={handleChange} />
      </Flex>
      <Flex gap={2}>
        <LEMultiSelect name="pods" label="Pods" options={pods as BaseOption[]} />
        <LEMultiSelect
          name="hunt_groups"
          label="Hunt Groups"
          options={huntGroups as BaseOption[]}
        />
      </Flex>
      <LEMultiSelect name="auth0_roles" label="Auth0 Roles" options={roles as BaseOption[]} />
    </Flex>
  );
};

export default CreateUserModalForm;
