import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import { Col, Form, InputGroup, Spinner } from 'react-bootstrap';
import { Button } from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import 'react-toggle/style.css';

import { Deal } from '../../gql/dealGql';
import { phoneRegExp, zipRegExp } from '../../constants/RegExps';
import { DealContext } from '../../libs/DealContext';
import { passValuesToSchema } from '../../libs/utils';

import { AutoExplodeZip } from '../AutoExplodeZip';
import { InputCopyButton } from '../CopyButton/CopyButton';
import { dobValidation } from '../../libs/yup-validators/dob';
import SELECT_OPTIONS from '../../constants/selectOptions';
import Select from '../shared/Select';
import { ssnLast4Mask } from '../../constants/masks';
import DatePicker from '../shared/DatePicker';

interface CustomerInfoAcquisitionFormProps {
  onSubmit: (values: Deal, setSubmitting: (isSubmitting: boolean) => void) => void;
}

const vSchema = Yup.object().shape({
  customer: Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone_number: Yup.string()
      .matches(phoneRegExp, {
        message: 'Please input a valid phone number',
        excludeEmptyString: true,
      })
      .test('phone_test', 'Phone number is required', function () {
        const { home_phone_number, phone_number } = this.options.context?.customer;

        const phoneNumberRequired = !home_phone_number;
        if (phoneNumberRequired) {
          return !!phone_number;
        }
        return true;
      }),
    home_phone_number: Yup.string().matches(phoneRegExp, {
      message: 'Please input a valid phone number',
      excludeEmptyString: true,
    }),
    email: Yup.string().optional().email('Please input a valid email address'),
    dob: dobValidation(),
    address: Yup.object().shape({
      address_line: Yup.string().required('Address is required'),
      address_line_2: Yup.string().optional().nullable(),
      zip: Yup.string().matches(zipRegExp, 'Please enter a valid zip code'),
    }),
  }),
});

const CustomerPersonalInfo: React.FC = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<Deal>();
  const [zipLoading, setZipLoading] = useState<boolean>(false);
  const [isAddressLineFocused, setAddressLineFocused] = useState<boolean>(false);

  const name = 'customer';
  const cust = values.customer;
  const touchedCust = touched.customer;
  const errCust = errors.customer;

  return (
    <>
      <h6>Personal Info</h6>
      <Form.Row>
        <Form.Group as={Col} md="4">
          <Form.Control
            type="text"
            name={`${name}.first_name`}
            placeholder="First Name"
            value={cust.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.first_name && !!errCust?.first_name}
          />
          <Form.Control.Feedback type="invalid">{errCust?.first_name}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Control
            type="text"
            name={`${name}.middle_name`}
            placeholder="Middle Name"
            value={cust.middle_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.middle_name && !!errCust?.middle_name}
          />
          <Form.Control.Feedback type="invalid">{errCust?.middle_name}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Control
            type="text"
            name={`${name}.last_name`}
            placeholder="Last Name"
            value={cust.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.last_name && !!errCust?.last_name}
          />
          <Form.Control.Feedback type="invalid">{errCust?.last_name}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="3">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Cell Phone Number"
              as={InputMask}
              mask="(999) 999-9999"
              name={`${name}.phone_number`}
              value={cust.phone_number}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touchedCust?.phone_number && !!errCust?.phone_number}
            />
            <InputCopyButton value={cust.phone_number} isFormatted />
            <Form.Control.Feedback type="invalid">{errCust?.phone_number}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Home Phone Number"
              as={InputMask}
              mask="(999) 999-9999"
              name={`${name}.home_phone_number`}
              value={cust.home_phone_number}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touchedCust?.home_phone_number && !!errCust?.home_phone_number}
            />
            <InputCopyButton value={cust.home_phone_number} isFormatted />
            <Form.Control.Feedback type="invalid">{errCust?.phone_number}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Control
            type="text"
            placeholder="Email"
            name={`${name}.email`}
            value={cust.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.email && !!errCust?.email}
          />
          <Form.Control.Feedback type="invalid">{errCust?.email}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="6">
          <Form.Control
            type="text"
            name={`${name}.address.address_line`}
            placeholder="Address Line 1"
            value={cust.address?.address_line}
            onChange={handleChange}
            onFocus={() => setAddressLineFocused(true)}
            onBlur={(e: React.SyntheticEvent) => {
              handleBlur(e);
              setAddressLineFocused(false);
            }}
            isInvalid={touchedCust?.address?.address_line && !!errCust?.address?.address_line}
          />
          <Form.Control.Feedback type="invalid">
            {errCust?.address?.address_line}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Control
            type="text"
            name={`${name}.address.address_line_2`}
            placeholder="Address Line 2"
            value={cust.address?.address_line_2}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.address_line_2 && !!errCust?.address?.address_line_2}
          />
          <Form.Control.Feedback type="invalid">
            {errCust?.address?.address_line_2}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="3">
          <Form.Control
            type="text"
            placeholder="Zip Code"
            as={InputMask}
            mask="99999"
            name={`${name}.address.zip`}
            value={cust.address?.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.zip && !!errCust?.address?.zip}
          />
          <Form.Control.Feedback type="invalid">{errCust?.address?.zip}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Control
            type="text"
            placeholder="City"
            name={`${name}.address.city`}
            value={cust.address?.city}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.city && !!errCust?.address?.city}
          />
          <Form.Control.Feedback type="invalid">{errCust?.address?.city}</Form.Control.Feedback>
          <Spinner
            animation="border"
            className="spinner"
            variant="primary"
            size="sm"
            hidden={!zipLoading}
          />
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Select
            emptyOption={false}
            placeholder="State"
            id="customer.address.state"
            name={`${name}.address.state`}
            defaultValue={cust.address?.state}
            options={SELECT_OPTIONS.US_STATES}
            isRequired
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.state && !!errCust?.address?.state}
          />
          <Spinner
            animation="border"
            className="spinner"
            variant="primary"
            size="sm"
            hidden={!zipLoading}
          />
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Control
            type="text"
            placeholder="County"
            name={`${name}.address.county`}
            value={cust.address?.county}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.county && !!errCust?.address?.county}
          />
          <Form.Control.Feedback type="invalid">{errCust?.address?.county}</Form.Control.Feedback>
          <Spinner
            animation="border"
            className="spinner"
            variant="primary"
            size="sm"
            hidden={!zipLoading}
          />
        </Form.Group>
        <AutoExplodeZip
          name={name}
          setZipLoading={setZipLoading}
          isAddressLineFocused={isAddressLineFocused}
        />
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="6">
          <Form.Control
            type="text"
            placeholder="SSN"
            as={InputMask}
            mask={ssnLast4Mask}
            name={`${name}.ssn`}
            value={cust?.ssn}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.ssn && !!errCust?.ssn}
          />
          <Form.Control.Feedback type="invalid">{errCust?.ssn}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <DatePicker
            name={`${name}.dob`}
            topLabel="Date of Birth"
            boxStyles={{ w: '100%' }}
            valueFormat="dateUTC"
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};

const CustomerInfoAcquisitionForm: React.FC<CustomerInfoAcquisitionFormProps> = ({ onSubmit }) => {
  const { deal } = useContext(DealContext);
  const [isSaving, setSaving] = useState<boolean>(false);

  return (
    <Formik
      validate={(value) => passValuesToSchema(value, vSchema)}
      onSubmit={(values: Deal, { setSubmitting }: FormikHelpers<Deal>) => {
        onSubmit({ ...values }, setSubmitting);
      }}
      initialValues={deal}
      enableReinitialize
    >
      {({ handleSubmit, values, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <CustomerPersonalInfo />
          <Form.Row className="d-flex justify-content-start">
            <Button
              variant="secondary"
              isLoading={isSaving}
              loadingText="SAVE"
              onClick={() => {
                if (!isValid) {
                  return;
                }

                setSaving(true);
                onSubmit(values, setSaving);
              }}
            >
              SAVE
            </Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerInfoAcquisitionForm;
