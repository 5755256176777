/* eslint-disable security/detect-object-injection */
import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { Box, Flex, useMultiStyleConfig } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { DealMediaTypeEnum } from '../../../../gql/generated/graphql';
import { DealMediaEdit, DealMediaTypeOptions } from '../../../../gql/mediaGql';

import Autocomplete from '../../../shared/Autocomplete';
import Input from '../../../shared/Input';
import { MediaModalType } from '../MediaModal';
import UploadPreview from '../UploadPreview';

interface MediaUploadFormProps {
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  index: number;
  remove?: (index: number) => void;
  variant?: 'reduced' | undefined;
  initialMediaType?: DealMediaTypeEnum | '';
}

const MediaUploadForm: React.FC<MediaUploadFormProps> = ({
  showModal,
  setShowModal,
  index,
  remove,
  variant,
  initialMediaType,
}) => {
  const { handleChange, values } = useFormikContext<{ dealMedia: DealMediaEdit[] }>();
  const { dealMedia } = values;

  const styles = useMultiStyleConfig('MediaForm', { variant });
  const formControlVariant = variant === 'reduced' ? 'reduced' : 'inline';

  const isNewMedia = !dealMedia[index]?.id;
  const previewUrl = isNewMedia
    ? URL.createObjectURL(dealMedia[index]?.selectedFile ?? new Blob())
    : dealMedia[index]?.signed_url ?? '';

  useEffect(() => {
    if (dealMedia[index]?.type || dealMedia[index]?.fileName || dealMedia[index]?.extension) {
      setShowModal({
        ...showModal,
        dealMedia: {
          ...showModal.dealMedia,
          extension: dealMedia[index]?.extension,
          type: dealMedia[index]?.type,
          fileName: dealMedia[index]?.fileName,
        },
      });
    }
  }, [dealMedia[index]?.type, dealMedia[index]?.fileName, dealMedia[index]?.extension]);

  return (
    <Flex __css={styles.flexContainer}>
      <Box __css={styles.previewContainer}>
        <UploadPreview
          index={index}
          url={previewUrl}
          sourceFileName={dealMedia[index]?.sourceFileName ?? ''}
          remove={remove}
        />
      </Box>
      <Flex __css={styles.inputsContainer}>
        <Input
          formControlVariant={formControlVariant}
          label="File Name"
          name={`dealMedia[${index}].fileName`}
          rightAddon={dealMedia[index]?.extension ? `.${dealMedia[index]?.extension}` : ''}
          onChange={handleChange}
        />
        <Autocomplete
          formControlVariant={formControlVariant}
          label="What is it?"
          name={`dealMedia[${index}].type`}
          options={DealMediaTypeOptions}
          defaultValue={initialMediaType}
          additionalOnChange={(newValue) => {
            setShowModal({
              ...showModal,
              dealMedia: {
                ...showModal.dealMedia,
                verified: false,
                type: newValue?.value as DealMediaTypeEnum,
              },
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default MediaUploadForm;
