import { PayoffRequest } from '../../gql/payoffRequestGql';

export enum FiltersActionKind {
  SET_LIENHOLDERS = 'SET_LIENHOLDERS',
}

export interface FiltersState {
  lienholders: string[];
}

export const getFiltersStates = (searchParams?: {
  [K in keyof FiltersState]?: string;
}): FiltersState => ({
  lienholders: searchParams?.lienholders ? searchParams.lienholders.split(',') : [],
});

export type StringArrayPayload = {
  type: FiltersActionKind.SET_LIENHOLDERS;
  payload: string[];
};

export type FiltersAction = StringArrayPayload;

export const filtersReducer = (state: FiltersState, action: FiltersAction): FiltersState => {
  const { type, payload } = action;

  switch (type) {
    case FiltersActionKind.SET_LIENHOLDERS:
      return { ...state, lienholders: payload };
    default:
      return state;
  }
};

export const filterActionsDic = {
  lienholders: (payoffRequest: PayoffRequest, lienholdersAssigned: string[]): boolean => {
    if (!lienholdersAssigned.length) {
      return true;
    }

    return lienholdersAssigned.includes(payoffRequest.deal.car.payoff.lienholder_slug);
  },
};
