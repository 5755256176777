import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../../components/shared/Modal';
import NoteForm from '../../components/NoteForm';
import { Note } from '../../gql/noteGql';

interface ButtonProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

interface ModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: (values: Note, setSubmitting: (isSubmitting: boolean) => void) => void;
  dealId: number;
  hasProblem?: boolean;
}

const CancelButton: React.FC<ButtonProps> = ({ setShow }) => {
  return (
    <>
      <Button variant="secondary" onClick={() => setShow(false)}>
        CANCEL
      </Button>
      <Spacer />
    </>
  );
};

export const ProblemModal: React.FC<ModalProps> = ({
  show,
  setShow,
  onSubmit,
  dealId,
  hasProblem,
}) => {
  const modalHeader = hasProblem
    ? 'How was the problem resolved?'
    : 'Why does this deal have a problem?';
  const placeholder = hasProblem
    ? 'Enter a descriptive resolution so that stranger would know what happened'
    : 'Enter a descriptive reason so that a stranger would know what the problem is and what is needed to fix it';
  return (
    <Modal isOpen={show} onClose={() => setShow(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <NoteForm
            note={new Note(dealId)}
            onSubmit={onSubmit}
            placeholder={placeholder}
            buttonName="Save"
            button={<CancelButton setShow={setShow} />}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
