import { gql } from '@apollo/client';

import { ReferralSourceEnum } from './generated/graphql';

import { Option } from '../components/shared/types';

const referralSourceLabels: Record<ReferralSourceEnum, string> = {
  [ReferralSourceEnum.SocialMediaOrBlogPost]: 'Social Media or Blog Post',
  [ReferralSourceEnum.TrustedReview]: 'Trusted Review (YouTube, BBB, TrustPilot, etc...)',
  [ReferralSourceEnum.RecommendationFromFriend]: 'Recommendation from a friend',
  [ReferralSourceEnum.Mailer]: 'Mailer',
  [ReferralSourceEnum.InternetSearch]: 'Internet Search',
  [ReferralSourceEnum.Text]: 'Text',
  [ReferralSourceEnum.DidNotAsk]: 'Did Not Ask',
  [ReferralSourceEnum.Other]: 'Other',
};

export const referralSourceOptions: Option[] = Object.entries(referralSourceLabels).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const ReferralSourceFragment = gql`
  fragment ReferralSourceFragment on referralSource {
    id
    source_name
    other_source_description
  }
`;
