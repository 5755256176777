const text = {
  variants: {
    inbox: {
      width: '100%',
      minHeight: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    dealInfoBuyout: {
      fontSize: 'sm',
      fontWeight: 'normal',
      pt: 3,
      pl: 3,
    },
  },
};

export default text;
