import React from 'react';
import './NotFound.css';

const NotFound: React.FC = () => {
  return (
    <div className="NotFound text-center">
      <h2>Sorry, page not found</h2>
    </div>
  );
};

export default NotFound;
