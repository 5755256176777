import { Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { BiExpand } from 'react-icons/bi';
import { MdPhoneEnabled } from 'react-icons/md';
import { formatPhoneNumberForDisplay } from '../../libs/utils';

interface Props {
  onPanelClick: (v: boolean) => void;
  name?: string;
  phoneNumber?: string;
}

const SmallCurrentCallPanel = ({ onPanelClick, name, phoneNumber }: Props) => {
  return (
    <Flex
      w="20%"
      position="fixed"
      bottom={0}
      right={0}
      left={0}
      ml="0.5%"
      mb="0.5%"
      bg="midnightblue"
      alignItems="center"
      rounded="2xl"
      zIndex={2000}
      p="15px"
      onClick={() => onPanelClick(false)}
    >
      <Flex
        rounded="full"
        w="30px"
        h="30px"
        bg="green"
        ml={2}
        justifyContent="center"
        alignItems="center"
        px={4}
      >
        <Icon as={MdPhoneEnabled} color="white" w={4} h={4} bg="transparent" m={0} />
      </Flex>
      <Flex w="100%" ml="4" flexDir="column">
        <Text
          color="white"
          fontSize={{ base: '14px', xl: '18px' }}
          textTransform={name ? 'capitalize' : undefined}
        >
          {name || ''}
        </Text>
        <Text color="white" fontSize={{ base: '13px', xl: '18px' }}>
          {formatPhoneNumberForDisplay(phoneNumber || '')}
        </Text>
      </Flex>
      <IconButton
        variant="icon"
        icon={<BiExpand />}
        color="white"
        fontSize={25}
        aria-label="Expand Panel"
      />
    </Flex>
  );
};

export default SmallCurrentCallPanel;
