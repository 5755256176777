import { FC, useContext, useEffect, useState } from 'react';

import { endOfDay, startOfDay, startOfMonth } from 'date-fns';

import { useKeyMetricsQuery } from '../../gql/generated/graphql';

import { BucketBody, BucketCard, BucketHeader } from '../DealsSwimlane/DealsSwimlane';
import Loader from '../Loader';

import DateForm from './DateForm';
import MetricsTable from './MetricsTable';

import { MetricType } from '../../constants/metrics';
import { useUserAbilityAndVisibleStates } from '../../hooks/useUserAbilityAndVisibleStates';
import { FiltersContext } from '../../libs/contextLib';
import { isNullOrUndefined } from '../../utils';

export interface KeyMetric {
  keyMetric: MetricType;
  value: {
    buyout: number;
    acquisition: number;
    total: number;
  };
}

const KeyMetricsContainer: FC = () => {
  const {
    filters: {
      global: { selectedPodId },
    },
  } = useContext(FiltersContext);

  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [keyMetrics, setKeyMetrics] = useState<KeyMetric[]>([]);

  const { loggedUserPods } = useUserAbilityAndVisibleStates();

  const podIds = selectedPodId ? [selectedPodId] : loggedUserPods.map((pod) => pod?.id);
  const {
    data: keyMetricsGql,
    loading,
    refetch: keyMetricsRefetch,
  } = useKeyMetricsQuery({
    variables: {
      start_date: startOfDay(startDate),
      end_date: endOfDay(endDate),
      pod_ids: podIds.reduce((acc, id) => (id ? [...acc, id.toString()] : acc), [] as string[]),
    },
    fetchPolicy: 'cache-and-network',
    // skip is broken in @apollo/client: "^3.3.15"
    // even with the skip it still sends 1 request to the server
    // with [null], however without the skip it sends 2 requests
    // with [null] to the server
    skip: podIds.every((id) => isNullOrUndefined(id)),
  });

  useEffect(() => {
    keyMetricsRefetch();
  }, [startDate, endDate]);

  useEffect(() => {
    if (keyMetricsGql?.keyMetrics) {
      setKeyMetrics(keyMetricsGql.keyMetrics as KeyMetric[]);
    }
  }, [keyMetricsGql]);

  return (
    <BucketCard>
      <BucketHeader>Key Metrics</BucketHeader>
      <BucketBody>
        <Loader isLoading={loading} />
        <DateForm
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
        <br />
        <MetricsTable keyMetrics={keyMetrics} />
      </BucketBody>
    </BucketCard>
  );
};

export default KeyMetricsContainer;
