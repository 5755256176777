import React, { FocusEvent } from 'react';
import { Col, Form } from 'react-bootstrap';
import { parseISO } from 'date-fns';
import { formatDateISO } from '../../libs/utils';

const START_DATE = 'Start Date';
const END_DATE = 'End Date';

interface Props {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const DateForm: React.FC<Props> = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const dates = [
    { label: START_DATE, value: startDate, setDate: setStartDate },
    { label: END_DATE, value: endDate, setDate: setEndDate },
  ];

  return (
    <Form>
      <Form.Row>
        {dates.map((date) => (
          <Form.Group as={Col} md="4" key={date.label}>
            <Form.Label>{date.label}</Form.Label>
            <Form.Control
              type="date"
              defaultValue={formatDateISO(date.value)}
              onBlur={(e: FocusEvent<HTMLInputElement>) => date.setDate(parseISO(e.target.value))}
            />
          </Form.Group>
        ))}
      </Form.Row>
    </Form>
  );
};

export default DateForm;
