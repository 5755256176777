import { PropsWithChildren } from 'react';

import {
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  TextProps,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';

import { PrequalificationCreditReportTradeline } from '../../../../gql/generated/graphql';

import { formatExperianDate, formatExperianMoney } from '../../../../utils/prequalification';

type TradelinesTableProps = {
  title: 'Revolving' | 'Installment' | 'Unknown';
  tradelines: PrequalificationCreditReportTradeline[];
};

const TradelineText = ({ children, ...rest }: PropsWithChildren<TextProps>) => (
  <Text fontSize="sm" lineHeight={4} {...rest}>
    {children}
  </Text>
);

const TradelinesTable = ({ title, tradelines }: TradelinesTableProps) => {
  return (
    <VStack spacing={0} w="full" alignItems="center">
      <Text decoration="underline" fontSize="xl" fontWeight="bold">
        Tradelines - {title}
      </Text>
      <TableContainer w="full">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>
                <VStack spacing={1}>
                  <Text>Account Name</Text>
                  <Text>KOB</Text>
                  <Text>Status</Text>
                  <Text>Acct Type</Text>
                </VStack>
              </Th>
              <Th>
                <VStack spacing={1}>
                  <Text>Opened</Text>
                  <Text>Reported</Text>
                </VStack>
              </Th>
              <Th>
                <VStack spacing={1}>
                  <Text>Credit Limit</Text>
                  <Text>High Balance</Text>
                  <Text>Past Due</Text>
                </VStack>
              </Th>
              <Th>
                <VStack spacing={1}>
                  <Text>Balance</Text>
                  <Text>Open or Closed</Text>
                  <Text>ECOA</Text>
                </VStack>
              </Th>
              <Th>
                <VStack spacing={1}>
                  <Text>Monthly Payment</Text>
                  <Text>Months History</Text>
                </VStack>
              </Th>
              <Th>
                <VStack spacing={1}>
                  <Text>Terms</Text>
                  <Text>30 60 90-180</Text>
                  <Text>Payment Pattern</Text>
                </VStack>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {tradelines.map((tradeline) => (
              <Tr>
                <Td maxW="3xs" whiteSpace="normal">
                  <VStack alignItems="flex-start" spacing={1}>
                    <TradelineText fontWeight="bold">
                      {tradeline.subscriberName || '-'}
                    </TradelineText>
                    <TradelineText>{tradeline.kob || '-'}</TradelineText>
                    <TradelineText>{tradeline.status}</TradelineText>
                    <TradelineText>{tradeline.accountType}</TradelineText>
                  </VStack>
                </Td>
                <Td maxW="3xs" whiteSpace="normal">
                  <VStack spacing={1}>
                    <TradelineText>{formatExperianDate(tradeline.openDate)}</TradelineText>
                    <TradelineText>{formatExperianDate(tradeline.balanceDate)}</TradelineText>
                  </VStack>
                </Td>
                <Td maxW="3xs" whiteSpace="normal">
                  <VStack spacing={1}>
                    <TradelineText>{formatExperianMoney(tradeline.amount1) || '-'}</TradelineText>
                    <TradelineText>{formatExperianMoney(tradeline.amount2) || '-'}</TradelineText>
                    <TradelineText>
                      {formatExperianMoney(tradeline.amountPastDue) || '-'}
                    </TradelineText>
                  </VStack>
                </Td>
                <Td maxW="3xs" whiteSpace="normal">
                  <VStack spacing={1}>
                    <TradelineText>
                      {formatExperianMoney(tradeline.balanceAmount) || '-'}
                    </TradelineText>
                    <TradelineText>{tradeline.openOrClosed || '-'}</TradelineText>
                    <TradelineText>{tradeline.ecoa || '-'}</TradelineText>
                  </VStack>
                </Td>
                <Td maxW="3xs" whiteSpace="normal">
                  <VStack spacing={1}>
                    <TradelineText>
                      {formatExperianMoney(tradeline.monthlyPaymentAmount) || '-'}
                    </TradelineText>
                    <TradelineText>{tradeline.monthsHistory || '-'}</TradelineText>
                  </VStack>
                </Td>
                <Td maxW="3xs" whiteSpace="normal">
                  <VStack spacing={1}>
                    <TradelineText>{tradeline.terms || '-'}</TradelineText>
                    <HStack>
                      <TradelineText
                        color={
                          tradeline.delinquency30Days && tradeline.delinquency30Days !== '00'
                            ? 'errorsRed'
                            : undefined
                        }
                      >
                        {tradeline.delinquency30Days || '00'}
                      </TradelineText>
                      <TradelineText
                        color={
                          tradeline.delinquency60Days && tradeline.delinquency60Days !== '00'
                            ? 'errorsRed'
                            : undefined
                        }
                      >
                        {tradeline.delinquency60Days || '00'}
                      </TradelineText>
                      <TradelineText
                        color={
                          tradeline.delinquency90to180Days &&
                          tradeline.delinquency90to180Days !== '00'
                            ? 'errorsRed'
                            : undefined
                        }
                      >
                        {tradeline.delinquency90to180Days || '00'}
                      </TradelineText>
                    </HStack>
                    <TradelineText fontSize="xs">{tradeline.paymentHistory || '-'}</TradelineText>
                  </VStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};

export default TradelinesTable;
