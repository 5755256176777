import { useContext, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';

import { Deal } from '../gql/dealGql';
import { getTotalFeeAmountConsideringTitleOnly } from '../gql/financialInfoGql';
import { PaymentOptionsQueryVariables, usePaymentOptionsLazyQuery } from '../gql/generated/graphql';

import { initialPaymentOptions } from '../components/DealInfoBuyout/types';
import { DealContext } from '../libs/DealContext';
import { BanksContext } from '../libs/contextLib';

import useDebounce from './useDebounce';

export const usePaymentOptions = () => {
  const {
    deal: {
      customer: { prequalification },
      setter_id,
      closer_id,
      closer2_id,
    },
  } = useContext(DealContext);

  const { banks } = useContext(BanksContext);

  const [getPaymentOptions, { loading, data }] = usePaymentOptionsLazyQuery();

  const {
    values: {
      financial_info,
      car: { payoff, mileage, year },
    },
  } = useFormikContext<Deal>();

  const dataInput: PaymentOptionsQueryVariables['data'] = {
    carYear: Number.parseInt(year, 10),
    carMileage: mileage,
    prequalificationCreditScore: prequalification?.credit_score,
    vehiclePayoff: payoff.vehicle_payoff,
    moneyDown: financial_info.money_down,
    bankFees: financial_info.bank_fees,
    userEnteredReserve: financial_info.user_entered_reserve,
    baseTaxAmount: financial_info.base_tax_amount,
    warrantyTaxAmount: financial_info.warranty_tax_amount,
    title: financial_info.title,
    totalFeeAmount: getTotalFeeAmountConsideringTitleOnly(financial_info),
    docFee: financial_info.doc_fee,
    bank: financial_info.bank,
    daysToPayment: financial_info.days_to_payment,
    vscPrice: financial_info.vsc_price,
    vscCost: financial_info.vsc_cost,
    gapPrice: financial_info.gap_price,
    gapCost: financial_info.gap_cost,
    buyRate: financial_info.buy_rate,
    sellRate: financial_info.sell_rate,
    term: financial_info.term,
    setterId: setter_id,
    closerId: closer_id,
    closer2Id: closer2_id,
    banks,
  };

  const debouncedGetPaymentOptions = useDebounce((value: typeof dataInput) => {
    getPaymentOptions({
      variables: {
        data: value,
      },
    });
  }, 500);

  const [paymentOptions, setPaymentOptions] = useState({
    ...initialPaymentOptions,
  });

  const listeningToArr = [
    year,
    mileage,
    prequalification?.credit_score,
    payoff.vehicle_payoff,
    financial_info.money_down,
    financial_info.bank_fees,
    financial_info.user_entered_reserve,
    financial_info.warranty_tax_amount,
    financial_info.base_tax_amount,
    financial_info.title,
    financial_info.total_fee_amount,
    financial_info.title_only,
    financial_info.bank,
    financial_info.days_to_payment,
    financial_info.vsc_price,
    financial_info.vsc_cost,
    financial_info.gap_price,
    financial_info.gap_cost,
    financial_info.buy_rate,
    financial_info.sell_rate,
    financial_info.term,
    setter_id,
    closer_id,
    closer2_id,
    banks,
  ];

  useEffect(() => {
    if (
      banks.length >= 1 &&
      financial_info.bank &&
      payoff.vehicle_payoff &&
      financial_info.days_to_payment &&
      financial_info.buy_rate &&
      financial_info.sell_rate &&
      financial_info.term
    ) {
      debouncedGetPaymentOptions(dataInput);
    }
  }, listeningToArr);

  useEffect(() => {
    if (!loading && data?.paymentOptions) {
      setPaymentOptions(data.paymentOptions as typeof initialPaymentOptions);
    }
  }, [data]);

  return [paymentOptions];
};
