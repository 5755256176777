import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import Modal from '../../../components/shared/Modal';

interface DeleteReviewModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: (a: boolean) => void;
  handleDelete: () => void;
}

const DeleteReviewModal: React.FC<DeleteReviewModalProps> = ({
  showDeleteModal,
  setShowDeleteModal,
  handleDelete,
}) => {
  return (
    <Modal
      size="md"
      isOpen={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      title="Delete Article"
      showDivider={false}
      centerButtons={
        <Button variant="boot" size="md" onClick={() => handleDelete()}>
          Delete
        </Button>
      }
    >
      <Text align="center">Are you sure you want to delete this review?</Text>
    </Modal>
  );
};

export default DeleteReviewModal;
