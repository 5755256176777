import { FC } from 'react';

import { HStack, IconButton, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { MdAddCircle, MdDoNotDisturbOn } from 'react-icons/md';

import { Cobuyer } from '../../../gql/customerGql';
import { DealContact } from '../../../gql/dealContactGql';
import { Deal } from '../../../gql/dealGql';

type ButtonType = 'cobuyer' | 'contact' | 'secondContact';

interface Props {
  isAdded: boolean;
  isDisabled?: boolean;
  type: ButtonType;
}

const AddCobuyerOrContactButton: FC<Props> = ({ isAdded, type, isDisabled }) => {
  const {
    values: { addCobuyer, addContact, addSecondContact, cobuyer, contact, id, second_contact },
    setFieldValue,
  } = useFormikContext<Deal>();

  const icon = isAdded ? <MdDoNotDisturbOn /> : <MdAddCircle />;

  const labeMap: Record<ButtonType, string> = {
    cobuyer: 'Cobuyer',
    contact: 'Contact',
    secondContact: 'Second Contact',
  };

  const buttonActions: Record<ButtonType, () => void> = {
    cobuyer: () => {
      // Add or remove a cobuyer
      if (!addCobuyer) {
        setFieldValue('cobuyer', cobuyer || new Cobuyer());
      }
      setFieldValue('addCobuyer', !addCobuyer);
    },
    contact: () => {
      // Add or remove a contact
      if (!addContact) {
        setFieldValue('contact', contact || new DealContact(id));
        setFieldValue('addContact', true);
        return;
      }

      // Make the second contact the primary contact if needed
      if (addSecondContact) {
        setFieldValue('contact', second_contact);
        setFieldValue('contact.id', contact?.id);
        setFieldValue('addSecondContact', false);
        return;
      }

      setFieldValue('addContact', false);
    },

    secondContact: () => {
      // Add or remove the second contact
      if (!addSecondContact) {
        setFieldValue('second_contact', second_contact || new DealContact(id));
      }
      setFieldValue('addSecondContact', !addSecondContact);
    },
  };

  return (
    <HStack mx={1}>
      <IconButton
        icon={icon}
        variant="icon"
        color={isDisabled ? 'gray' : isAdded ? 'errorsRed' : 'primary'}
        aria-label="add-cobuyer"
        fontSize={32}
        ml={4}
        mr={-2}
        onClick={buttonActions[type]}
        isDisabled={isDisabled}
      />
      <Text fontSize="lg">{`${isAdded ? 'Remove' : 'Add'} ${labeMap[type]}`}</Text>
    </HStack>
  );
};

export default AddCobuyerOrContactButton;
