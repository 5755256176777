import { FC, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button, ButtonProps } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { validateBankContractQuery } from '../../gql/documentsGql';

import { PermissionEnum } from '../../constants/permissions';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext } from '../../libs/contextLib';
import { isDocumentProgressSent } from '../../utils/routeOne';

interface Props {
  width?: string;
  size?: ButtonProps['size'];
}

const ValidateBankContractsButton: FC<Props> = ({ width, size = 'sm' }) => {
  const { deal, isRecalculatingPayoff, dealRefetch } = useContext(DealContext);

  const [validateBankContract, { data, loading, error }] = useLazyQuery(validateBankContractQuery, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.validateBankContract) {
      toast.success('Success');
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`, { autoClose: false });
    }
  }, [error]);

  const abilities = useContext(AbilityContext);
  const isDisabled =
    isDocumentProgressSent(deal.document_progress_status) ||
    !abilities.has(PermissionEnum.GenerateBankContract) ||
    isRecalculatingPayoff;

  return (
    <Button
      size={size}
      width={width}
      variant="secondary"
      isLoading={loading}
      loadingText="VALIDATE"
      isDisabled={isDisabled}
      onClick={async () => {
        try {
          await validateBankContract({
            variables: {
              deal_id: deal.id,
            },
          });

          // Refetch to update document_progress_status and the BackButton properly
          await dealRefetch();
        } catch {
          toast.error('Failed to save deal');
        }
      }}
    >
      VALIDATE
    </Button>
  );
};

export default ValidateBankContractsButton;
