const styles = {
  global: {
    body: {
      fontSize: '16px',
      fontWeight: 500,
      scrollBehavior: 'smooth',
      margin: 0,
      padding: 0,
      color: '#505050',
      MozOsxFontSmoothing: 'grayscale',
      webkitFontSmoothing: 'antialiased',
    },
    svg: {
      display: 'inline',
    },
  },
};

export default styles;
