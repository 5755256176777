import { Box, Button, ButtonGroup, Heading, IconButton } from '@chakra-ui/react';
import React from 'react';
import { GoTriangleDown } from 'react-icons/go';
import { MdAddCircle } from 'react-icons/md';
import Select from 'react-select';
import { DealMediaStatusEnum } from '../../../gql/mediaGql';

import ShippingLabelButton from './ShippingLabels';
import SignatureUploadModalButton from '../../SignatureUploadModalButton/SignatureUploadModalButton';

export interface MediaFilter {
  label: string;
  value: DealMediaStatusEnum;
}

interface MediaCenterHeaderProps {
  filterValue: MediaFilter;
  onAddMedia: () => void;
  onDelete: () => void;
  onFilter: (value: MediaFilter) => void;
  onPrint: () => void;
  canDelete: boolean;
}

const selectOptions = Object.entries(DealMediaStatusEnum).map(([key, value]) => ({
  label: key,
  value,
}));

const MediaCenterHeader: React.FC<MediaCenterHeaderProps> = ({
  canDelete,
  filterValue,
  onAddMedia,
  onDelete,
  onFilter,
  onPrint,
}) => (
  <Box backgroundColor="oxfordBlue" borderRadius="10px 10px 0 0">
    <Box alignItems="center" display="flex" justifyContent="space-between" px={4} py={2}>
      <Heading as="h3" color="white" fontSize="20px" fontWeight="medium">
        Media Center
      </Heading>
      <Box display="inline-flex">
        <Box minW="200px">
          <Select
            components={{
              DropdownIndicator: () => (
                <Box color="primary" mx={2}>
                  <GoTriangleDown size={20} />
                </Box>
              ),
            }}
            onChange={(val) => onFilter(val!)}
            options={selectOptions}
            styles={{
              indicatorSeparator: (base) => ({
                ...base,
                backgroundColor: 'white',
              }),
            }}
            value={filterValue}
          />
        </Box>
        <IconButton
          icon={<MdAddCircle />}
          variant="icon"
          fontSize={32}
          aria-label="Upload Media"
          onClick={onAddMedia}
        />
      </Box>
    </Box>
    <Box
      bgColor="queenBlue"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      px={4}
      py={2}
      rowGap={1}
    >
      <ButtonGroup>
        <Button size="sm" onClick={onPrint}>
          PRINT
        </Button>
        <ShippingLabelButton size="sm" />
      </ButtonGroup>
      <ButtonGroup>
        <SignatureUploadModalButton inMediaCenter />
        <Button size="sm" variant="boot" isDisabled={canDelete} onClick={onDelete}>
          DELETE
        </Button>
      </ButtonGroup>
    </Box>
  </Box>
);

export default MediaCenterHeader;
