import { Deal, DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';

export const getBootedDealState = (deal: Deal) => {
  if (!deal.deal_dates?.dates) {
    return null;
  }
  const sortedDates = Object.entries(deal.deal_dates.dates)
    .filter(([key]) => ![DealStateEnum.Booted].includes(key as DealStateEnum))
    .sort(([, a], [, b]) => (a > b ? -1 : 1));
  const label = sortedDates[0]?.[0] as keyof typeof DealStateLabelMap;
  return label ? DealStateLabelMap[label] : null;
};
