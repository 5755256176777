import { ChangeEvent, FC, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Checkbox, HStack } from '@chakra-ui/react';
import { FormikErrors, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';

import { Customer, relationshipToBuyerNoChildOptions } from '../../gql/customerGql';
import { DealContact } from '../../gql/dealContactGql';
import { Deal, DealStateEnum, creditAppUpsert, isUneditable } from '../../gql/dealGql';
import { Employment, isEmploymentInfoRequired } from '../../gql/employmentGql';
import { AddressTypeEnum, MaritalStatusEnum } from '../../gql/generated/graphql';

import MaskedSsnInput from '../MaskedSsn/MaskedSsnInput';
import { TotalPayoff } from '../TotalPayoff/TotalPayoff';
import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import DatePicker from '../shared/DatePicker';
import { GridFormColumn, GridFormRow } from '../shared/GridForm';
import Select from '../shared/Select';
import Switch from '../shared/Switch';
import { AddressForm } from './components/AddressForm';
import Autosaving from './components/Autosaving';
import { EmploymentForm } from './components/EmploymentForm';
import { InsuranceForm } from './components/InsuranceForm';
import { PersonalInformationForm } from './components/PersonalInformationForm';
import { ResidenceForm } from './components/ResidenceForm';

import {
  requirePreviousAddress,
  requirePreviousEmployment,
  validationSchema,
} from './validationSchema';

import {
  emptyPhoneNumberMask,
  emptySsnFullMask,
  emptyZipMask,
  ssnFullMask,
} from '../../constants/masks';
import useDebounce from '../../hooks/useDebounce';
import { useSetValuesByDealType } from '../../hooks/useSetValuesByDealType';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { states } from '../../libs/states';
import { isNumber, removeFailedValidation } from '../../libs/utils';
import DealStatesModal from '../../pages/DealDetail/DealStatesModal';
import { cleanDealForUpdate, setInsertedIds } from '../../utils/deals';
import { snakeCaseToUpperCase } from '../../utils/text';

interface CleanCustomerProps {
  isCobuyer?: boolean;
  values: Deal;
  deal: Deal;
}

export const cleanCustomer = ({ isCobuyer = false, values, deal }: CleanCustomerProps) => {
  const person = cloneDeep(isCobuyer && values.cobuyer ? values.cobuyer : values.customer);
  const { ssn, phone_number, home_phone_number, employment } = person;
  let { address } = person;
  if (isCobuyer && values.customer.has_same_address_as_cobuyer) {
    address = {
      ...values.customer.address,
      id: values.cobuyer?.address?.id || undefined,
    };
  }
  const { years_at_job, months_at_job, gross_income } = employment;

  const cleanedCustomer: Customer = {
    ...person,
    first_name: person.first_name.trim(),
    middle_name: person.middle_name.trim(),
    last_name: person.last_name.trim(),
    ssn: ssn === emptySsnFullMask ? '' : ssn,
    phone_number: phone_number === emptyPhoneNumberMask ? '' : phone_number,
    home_phone_number: home_phone_number === emptyPhoneNumberMask ? '' : home_phone_number,
    address: {
      ...address,
      zip: !address?.zip || address.zip === emptyZipMask ? '' : address.zip,
    },
    employment: {
      ...employment,
      years_at_job: isNumber(years_at_job) ? years_at_job : undefined,
      months_at_job: isNumber(months_at_job) ? months_at_job : undefined,
      gross_income: isNumber(gross_income) ? gross_income : undefined,
      phone_number: employment.phone_number === emptyPhoneNumberMask ? '' : employment.phone_number,
    },
  };

  if (isCobuyer && deal?.cobuyer?.id) {
    cleanedCustomer.id = deal.cobuyer.id;
  } else if (isCobuyer && !deal?.cobuyer) {
    delete cleanedCustomer.id;
    delete cleanedCustomer.address?.id;
    delete cleanedCustomer.prev_address?.id;
    delete cleanedCustomer.employment?.id;
    delete cleanedCustomer.prev_employment?.id;
  }
  delete cleanedCustomer.notification_agreement;
  delete cleanedCustomer.ssn_last_4;
  delete cleanedCustomer.ssn_last_6;

  return cleanedCustomer;
};

interface CleanContactProps {
  isSecond: boolean;
  values: Deal;
  deal: Deal;
}

export const cleanContact = ({ isSecond, values, deal }: CleanContactProps) => {
  const person = isSecond && values.second_contact ? values.second_contact : values.contact;
  const { phone_number } = person as DealContact;
  const cleanedContact: DealContact = {
    ...person,
    phone_number: phone_number === emptyPhoneNumberMask ? '' : phone_number,
  };

  if (!isSecond && deal?.contact_id) {
    cleanedContact.id = deal.contact_id;
  } else if (isSecond && deal?.contact2_id) {
    cleanedContact.id = deal.contact2_id;
  } else if ((!isSecond && !deal?.contact) || (isSecond && !deal?.second_contact)) {
    delete cleanedContact.id;
  }

  return cleanedContact;
};

interface CleanDealProps {
  values: Deal;
  deal: Deal;
}

export const cleanDeal = ({ values, deal }: CleanDealProps) => {
  const { addCobuyer, addContact, addSecondContact, contact, second_contact, ...newValues } =
    cleanDealForUpdate(values);

  const cleanedDeal: Deal = {
    ...newValues,
    customer: cleanCustomer({ values: newValues, deal }),
    cobuyer:
      newValues.cobuyer && addCobuyer
        ? cleanCustomer({ isCobuyer: true, values: newValues, deal })
        : undefined,
    contact: contact && addContact ? cleanContact({ isSecond: false, values, deal }) : undefined,
    second_contact:
      second_contact && addSecondContact
        ? cleanContact({ isSecond: true, values, deal })
        : undefined,
    addCobuyer: undefined,
    addContact: undefined,
    addSecondContact: undefined,
  };

  const valuesWithoutFailures = removeFailedValidation(cleanedDeal, validationSchema);

  return valuesWithoutFailures;
};

export const closeOrCancelButtonTriggedBlur = (
  e: SyntheticEvent<HTMLInputElement | HTMLSelectElement> | undefined,
) => {
  if ((e as unknown as MouseEvent).relatedTarget) {
    const relatedTarget = (e as unknown as MouseEvent).relatedTarget as HTMLElement;

    const isCloseButton =
      relatedTarget.className.toLowerCase().includes('close') ||
      relatedTarget.innerHTML.toLowerCase().includes('close') ||
      relatedTarget.innerText.toLowerCase().includes('close') ||
      relatedTarget.className.toLowerCase().includes('cancel') ||
      relatedTarget.innerHTML.toLowerCase().includes('cancel') ||
      relatedTarget.innerText.toLowerCase().includes('cancel');

    if (relatedTarget.tagName === 'BUTTON' && isCloseButton) {
      return true;
    }
  }

  return false;
};

interface CreditPersonProps {
  isCobuyer: boolean;
  isInsideModal?: boolean;
  adminEditOverride?: boolean;
  isBuyerStateWI?: boolean;
}

export const CreditPerson: FC<CreditPersonProps> = ({
  isCobuyer,
  isInsideModal,
  adminEditOverride = false,
  isBuyerStateWI = false,
}) => {
  const {
    deal,
    dispatch,
    isRecalculatingPayoff,
    setIsRecalculatingPayoff,
    autosaving,
    setAutosaving,
    userChangedSomething,
    setUserChangedSomething,
  } = useContext(DealContext);

  const {
    values,
    errors,
    setFieldValue: formikSetFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = useFormikContext<Deal>();

  // https://github.com/jaredpalmer/formik/issues/2059
  const setFieldValue = (field: string, value: unknown, shouldValidate = true) => {
    formikSetFieldValue(field, value, shouldValidate);
    setTimeout(() => setFieldTouched(field, true, shouldValidate), 0);
  };

  const [saveTime, setSaveTime] = useState<string>('');

  const [upsertCreditApp] = useMutation<{ creditAppUpsert: Deal }>(creditAppUpsert);

  const updateCobuyerAddressToMatchBuyerAddress = () => {
    if (values.cobuyer) {
      const { address: buyerAddress } = values.customer;
      setFieldValue('customer.has_same_address_as_cobuyer', true);
      setFieldValue('cobuyer.has_same_address_as_cobuyer', true);
      setFieldValue('cobuyer.address.address_type', AddressTypeEnum.CoBuyer);
      setFieldValue('cobuyer.address.address_line', buyerAddress?.address_line);
      setFieldValue('cobuyer.address.address_line_2', buyerAddress?.address_line_2);
      setFieldValue('cobuyer.address.zip', buyerAddress?.zip);
      setFieldValue('cobuyer.address.city', buyerAddress?.city);
      setFieldValue('cobuyer.address.state', buyerAddress?.state);
      setFieldValue('cobuyer.address.county', buyerAddress?.county);
      setFieldValue('cobuyer.address.years_at_home', buyerAddress?.years_at_home);
      setFieldValue('cobuyer.address.months_at_home', buyerAddress?.months_at_home);
      setFieldValue('cobuyer.address.monthly_payment', buyerAddress?.monthly_payment);
    }
  };

  const nullOutCobuyerAddress = () => {
    setFieldValue('customer.has_same_address_as_cobuyer', false);
    if (values.cobuyer) {
      const { address: cobuyerAddress } = values.cobuyer;
      setFieldValue('cobuyer.has_same_address_as_cobuyer', false);
      setFieldValue('cobuyer.address.address_line', cobuyerAddress?.address_line || '');
      setFieldValue('cobuyer.address.address_line_2', cobuyerAddress?.address_line_2 || '');
      setFieldValue('cobuyer.address.zip', cobuyerAddress?.zip || '');
      setFieldValue('cobuyer.address.city', cobuyerAddress?.city || '');
      setFieldValue('cobuyer.address.state', cobuyerAddress?.state || '');
      setFieldValue('cobuyer.address.county', cobuyerAddress?.county || '');
      setFieldValue('cobuyer.address.years_at_home', cobuyerAddress?.years_at_home || null);
      setFieldValue('cobuyer.address.months_at_home', cobuyerAddress?.months_at_home || null);
      setFieldValue('cobuyer.address.monthly_payment', cobuyerAddress?.monthly_payment || null);
    }
  };

  const onToggleLivesWithBuyer = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isCobuyer) {
      return;
    }

    if (e.target.checked) {
      updateCobuyerAddressToMatchBuyerAddress();
    } else {
      nullOutCobuyerAddress();
    }
  };

  useEffect(() => {
    if (!isCobuyer || !values.customer?.has_same_address_as_cobuyer) {
      return;
    }

    updateCobuyerAddressToMatchBuyerAddress();
  }, [
    values.customer.address.address_line,
    values.customer.address.address_line_2,
    values.customer.address.zip,
    values.customer.address.city,
    values.customer.address.state,
    values.customer.address.county,
    values.customer.address.years_at_home,
    values.customer.address.months_at_home,
    values.customer.address.monthly_payment,
  ]);

  let name: 'customer' | 'cobuyer' = 'customer';
  let cust = values.customer;
  let errCust = errors.customer;

  if (isCobuyer) {
    name = 'cobuyer';
    cust = values.cobuyer as Customer;
    errCust = errors.cobuyer as FormikErrors<Customer> | undefined;
  }

  const notificationWasPreviouslyAccepted = useMemo<boolean | undefined>(() => {
    const prevStates = deal.deal_states.map((dealState) => dealState.state);
    const wasAccepted =
      isCobuyer && !!values.cobuyer?.id && prevStates.includes(DealStateEnum.Structuring);

    setFieldValue(`${name}.notification_agreement`, wasAccepted);
    return wasAccepted;
  }, [deal, isCobuyer]);

  const [notificationAgreement, setNotificationAgreement] = useState<boolean | undefined>(
    notificationWasPreviouslyAccepted,
  );

  useEffect(() => {
    if (notificationWasPreviouslyAccepted) {
      setFieldValue(`${name}.notification_agreement`, true);
    } else {
      setFieldValue(`${name}.notification_agreement`, notificationAgreement);
    }
  }, [notificationAgreement, notificationWasPreviouslyAccepted, errCust?.notification_agreement]);

  const [previousIsEmploymentInfoRequired, setPreviousIsEmploymentInfoRequired] = useState<boolean>(
    isEmploymentInfoRequired(cust?.employment),
  );
  const [tempEmployment, setTempEmployment] = useState<Partial<Employment>>(cust?.employment);

  const cleanEmploymentValues = (employmentToClean: Employment) => {
    setTempEmployment(employmentToClean);

    setFieldValue(`${name}.employment.name`, '');
    setFieldValue(`${name}.employment.job_title`, '');
    setFieldValue(`${name}.employment.phone_number`, '');
  };

  const restoreEmploymentValues = (employmentToRestore: Partial<Employment>) => {
    setFieldValue(`${name}.employment.name`, employmentToRestore?.name);
    setFieldValue(`${name}.employment.job_title`, employmentToRestore?.job_title);
    setFieldValue(`${name}.employment.phone_number`, employmentToRestore?.phone_number);
  };

  useEffect(() => {
    if (!isEmploymentInfoRequired(cust?.employment)) {
      cleanEmploymentValues(cust?.employment);
    }
  }, []);

  useEffect(() => {
    const currentIsEmploymentInfoRequired = isEmploymentInfoRequired(cust?.employment);

    const shouldCleanEmploymentValues =
      previousIsEmploymentInfoRequired && !currentIsEmploymentInfoRequired;

    const shouldRestoreEmploymentValues =
      !previousIsEmploymentInfoRequired && currentIsEmploymentInfoRequired;

    if (shouldCleanEmploymentValues) {
      cleanEmploymentValues(cust?.employment);
    }

    if (shouldRestoreEmploymentValues) {
      restoreEmploymentValues(tempEmployment);
    }

    setPreviousIsEmploymentInfoRequired(currentIsEmploymentInfoRequired);
  }, [cust?.employment?.status]);

  useSetValuesByDealType();

  const debouncedSave = useDebounce(async (currentValues: Deal) => {
    try {
      const cleanedValues = cleanDeal({ values: currentValues, deal });
      const res = await upsertCreditApp({
        variables: {
          deal: cleanedValues,
        },
      });
      if (!res.data?.creditAppUpsert) {
        throw new Error('No data returned from creditAppUpsert');
      }

      setSaveTime(new Date().toLocaleTimeString('en-US'));

      // Avoids multiple inserts and "not a child of model" Objection error.
      setInsertedIds(values, isCobuyer, setFieldValue, res);
      dispatch({ type: DealActionsEnum.UpdateDeal, payload: res.data?.creditAppUpsert });
    } catch (e) {
      const error = e as Error;
      logger.error('CreditPerson.tsx', '', null, error);
      toast.error('Error while autosaving Credit Application. Please try manually saving it.');
    } finally {
      setAutosaving(false);
    }
  });

  const customHandleChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | undefined,
  ) => {
    setUserChangedSomething(true);
    handleChange(e);
  };

  // We can't call debouncedSave on the customHandleChange because values are not updated yet.
  useEffect(() => {
    // `setFieldTouched` makes `dirty` true so we can't use it here. We could use dirty if we set those values in the initial values.
    if (!userChangedSomething) {
      return;
    }

    setAutosaving(true);
    debouncedSave(values);
  }, [values]);

  const isLocked = !adminEditOverride && isUneditable(values.state);

  return (
    <Card variant="rounded">
      <CardHeaderV2
        title={`${isCobuyer ? 'Co-Buyer ' : ''}Credit Application`}
        showPodColor={!isCobuyer}
        pod={deal.pod}
        variant={isCobuyer ? 'square' : 'rounded'}
      >
        <Autosaving autosaving={autosaving} saveTime={saveTime} />
        {!isCobuyer ? <DealStatesModal /> : null}
      </CardHeaderV2>

      {isCobuyer ? (
        <Box mx={6} mb={5}>
          <HStack pt={3} alignItems="end">
            <Select
              label="Relation To Buyer"
              name={`${name}.relation_to_buyer`}
              onChange={customHandleChange}
              emptyOption={false}
              options={relationshipToBuyerNoChildOptions}
              formControlProps={{
                width: '25%',
              }}
            />
            <Switch
              name={`${name}.has_same_address_as_cobuyer`}
              label="Lives With Buyer"
              customHandleChange={onToggleLivesWithBuyer}
            />
          </HStack>
        </Box>
      ) : null}

      <Box mb={5}>
        <PersonalInformationForm
          name={name}
          customHandleChange={customHandleChange}
          isUneditable={isLocked}
          hideBuyerNotLessee={isInsideModal}
        />

        {!isCobuyer || !values.cobuyer?.has_same_address_as_cobuyer ? (
          <AddressForm
            name={name}
            objectName="address"
            customHandleChange={customHandleChange}
            isUneditable={isLocked}
          />
        ) : null}

        <GridFormColumn>
          <GridFormRow minChildWidth={180}>
            <DatePicker
              name={`${name}.dob`}
              topLabel="Date of Birth"
              valueFormat="dateUTC"
              additionalHandleChange={customHandleChange}
              isDisabled={isLocked}
            />
            <MaskedSsnInput
              name={`${name}.ssn`}
              ssnDetails={{ mask: ssnFullMask, label: 'SSN', emptyMask: emptySsnFullMask }}
              customHandleChange={customHandleChange}
              needsHidden={name === 'customer' ? !!deal.customer?.ssn : !!deal.cobuyer?.ssn}
              isDisabled={isLocked}
              replaceFixedCharacters
            />
            <Box />
            <Box />
          </GridFormRow>
        </GridFormColumn>

        {cust.address?.state === states.WISCONSIN || (!cust.address?.state && isBuyerStateWI) ? (
          <Box mx={6}>
            <HStack mt={3} alignItems="start">
              <Select
                label="Marital Status"
                emptyOption
                name={`${name}.marital_status`}
                onChange={customHandleChange}
                isDisabled={isLocked}
                options={Object.values(MaritalStatusEnum).map((status) => ({
                  label: snakeCaseToUpperCase(status),
                  value: status,
                }))}
                formControlProps={{
                  width: '50%',
                }}
              />
            </HStack>
            {isCobuyer &&
            [MaritalStatusEnum.Married, MaritalStatusEnum.Separated].includes(
              cust?.marital_status as unknown as MaritalStatusEnum,
            ) ? (
              <HStack mt={3} alignItems="start">
                <Checkbox
                  name="cobuyer.notification_agreement"
                  checked={notificationAgreement}
                  isDisabled={notificationWasPreviouslyAccepted}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setNotificationAgreement(e.target.checked);
                    customHandleChange(e);
                  }}
                >
                  By checking this box, the co-buyer acknowledges that Wisconsin law may require
                  that notice of this application of credit and subsequent account may be given to
                  their spouse.
                </Checkbox>
              </HStack>
            ) : null}
          </Box>
        ) : null}
      </Box>

      <EmploymentForm
        name={name}
        objectName="employment"
        customHandleChange={customHandleChange}
        isUneditable={isLocked}
        isEmploymentInfoRequired={isEmploymentInfoRequired(cust?.employment)}
      />

      {requirePreviousEmployment(cust?.employment?.years_at_job) ? (
        <EmploymentForm
          name={name}
          objectName="prev_employment"
          customHandleChange={customHandleChange}
          isUneditable={isLocked}
          isEmploymentInfoRequired
        />
      ) : null}

      {!isCobuyer || !cust.has_same_address_as_cobuyer ? (
        <ResidenceForm
          name={name}
          objectName="address"
          isUneditable={isLocked}
          customHandleChange={customHandleChange}
        />
      ) : null}

      {requirePreviousAddress(cust?.address?.years_at_home) ? (
        <>
          <AddressForm
            name={name}
            objectName="prev_address"
            customHandleChange={customHandleChange}
            isUneditable={isLocked}
          />
          <ResidenceForm
            name={name}
            objectName="prev_address"
            isUneditable={isLocked}
            showTitle={false}
            customHandleChange={customHandleChange}
          />
        </>
      ) : null}

      <InsuranceForm name={name} customHandleChange={customHandleChange} />

      {!isCobuyer && !isLocked ? (
        <TotalPayoff
          hidden
          isRecalculating={isRecalculatingPayoff}
          setIsRecalculating={setIsRecalculatingPayoff}
        />
      ) : null}
    </Card>
  );
};
