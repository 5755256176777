import { useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';

import { DealSourceEnum, DealStateEnum } from '../gql/dealGql';
import {
  DealType,
  Maybe,
  Pod,
  TeamType,
  usePodsLazyQuery,
  usePodsQuery,
  useUserPermissionsLazyQuery,
} from '../gql/generated/graphql';
import { User } from '../gql/userGql';

import { PermissionEnum } from '../constants/permissions';
import { FiltersActionKind } from '../globalFiltersUtils';
import { AbilityContext, FiltersContext } from '../libs/contextLib';
import { allSwimlanes } from '../utils/dashboard';
import { getAllowedDealSources } from '../utils/permissions';

import { useUser } from './useUser';

const getUserStates = (ability: Set<PermissionEnum>) =>
  allSwimlanes
    .filter((sl) => ability.has((PermissionEnum.See + sl.title) as PermissionEnum))
    .flatMap((sl) => sl.states);

export const useUserAbilityAndVisibleStates = () => {
  const { isAuthenticated } = useAuth0();
  const loggedUser = useUser();
  const loggedUserAbility = useContext(AbilityContext);

  const { data: loggedUserPodsData } = usePodsQuery({
    variables: { team_type: [TeamType.D2d, TeamType.Inbound, TeamType.Outbound] },
    skip: !isAuthenticated,
  });
  const loggedUserPods = loggedUserPodsData?.pods ?? [];

  const {
    filters: {
      global: { viewAsUserId: contextViewAsUserId, sources: contextSources },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const [viewAsUser, setViewAsUser] = useState<User>(loggedUser);
  const [viewingAsSelf, setViewingAsSelf] = useState<boolean>(true);
  const [viewAsUserAbility, setViewAsUserAbility] =
    useState<Set<PermissionEnum>>(loggedUserAbility);
  const [viewAsUserStates, setViewAsUserStates] = useState<DealStateEnum[]>(
    getUserStates(loggedUserAbility),
  );
  const [viewAsUserSources, setViewAsUserSources] = useState<DealSourceEnum[]>(
    getAllowedDealSources(loggedUserAbility),
  );
  const [viewAsUserTypes] = useState<DealType[]>(Object.values(DealType));
  const [viewAsUserPods, setViewAsUserPods] = useState<Maybe<Pod>[]>(loggedUserPods);

  const [loading, setLoading] = useState(true);

  const [getUserPods] = usePodsLazyQuery();
  const [getUserPermissions] = useUserPermissionsLazyQuery();

  const handleViewAsUserChange = (
    newUser: User,
    newAbilities: Set<PermissionEnum>,
    newPods: Maybe<Pod>[],
  ) => {
    setViewAsUser(newUser);
    setViewAsUserAbility(newAbilities);
    setViewAsUserStates(getUserStates(newAbilities));

    const newSources = getAllowedDealSources(newAbilities);
    setViewAsUserSources(newSources);
    // Reset the sources only if some of the selected sources are not allowed for the new user.
    if (contextSources.some((source) => !newSources.includes(source))) {
      dispatchFilters({
        type: FiltersActionKind.SET_SOURCES,
        payload: newSources,
      });
    }

    setViewAsUserPods(newPods);

    setViewingAsSelf(newUser.id === loggedUser.id);
  };

  const getViewAsUserValues = async (newViewAsUser: User) => {
    const { data: userPermissionsData } = await getUserPermissions({
      variables: {
        user_id: newViewAsUser.id,
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    const { data: viewAsUserPodsData } = await getUserPods({
      variables: {
        userId: newViewAsUser.id,
        permissions: userPermissionsData?.userPermissions ?? [],
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    const newViewAsUserAbility = new Set(
      userPermissionsData?.userPermissions ?? [],
    ) as Set<PermissionEnum>;
    const newViewAsUserPods = viewAsUserPodsData?.pods ?? [];
    handleViewAsUserChange(newViewAsUser, newViewAsUserAbility, newViewAsUserPods);

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    if (contextViewAsUserId && contextViewAsUserId !== loggedUser.id) {
      getViewAsUserValues(new User(contextViewAsUserId));
      return;
    }

    handleViewAsUserChange(loggedUser, loggedUserAbility, loggedUserPods);

    setLoading(false);
  }, [contextViewAsUserId]);

  return {
    viewAsUser,
    viewAsUserAbility,
    viewAsUserStates,
    viewAsUserSources,
    viewAsUserTypes,
    viewAsUserPods,
    loggedUser,
    loggedUserAbility,
    loggedUserStates: getUserStates(loggedUserAbility),
    loggedUserSources: getAllowedDealSources(loggedUserAbility),
    loggedUserPods,
    loading,
    viewingAsSelf,
  };
};
