import { FC, useContext } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { set } from 'date-fns';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { FollowUpTypeEnum, useFollowUpInsertMutation } from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import FollowUpButton from './FollowUpButton';
import FollowUpForm from './FollowUpForm';
import { FollowUpFormType, validationSchema } from './validationSchema';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { getTimezoneStr } from '../../libs/utils';

const AddFollowUp: FC = () => {
  const { deal } = useContext(DealContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [insertFollowUp, { loading }] = useFollowUpInsertMutation();

  const initialValues: FollowUpFormType = {
    date: undefined,
    time: undefined,
    type: FollowUpTypeEnum.Call,
    note: null,
  };

  const handleSubmit = async (values: FollowUpFormType) => {
    if (!deal.id || !values.date || !values.time) {
      return;
    }

    const hours = values.time.getHours();
    const minutes = values.time.getMinutes();

    try {
      await insertFollowUp({
        variables: {
          deal_id: deal.id,
          date_utc: set(values.date, {
            hours,
            minutes,
            seconds: 0,
            milliseconds: 0,
          }),
          tz: getTimezoneStr(),
          type: values.type ?? '',
          note: values.note,
        },
      });
      toast.success('Follow-Up added');
      onClose();
    } catch (e) {
      const error = e as Error;
      logger.error('AddFollowUp.tsx', 'insertFollowUp', error);
      toast.error(
        `Error adding Follow-Up: ${error.message || error}. Please refresh and try again.`,
      );
    }
  };

  return (
    <>
      <FollowUpButton onOpen={onOpen} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Modal
          title="Add Follow-Up"
          hasForm
          isOpen={isOpen}
          onClose={onClose}
          leftButtons={
            <Button variant="warning" onClick={onClose}>
              Cancel
            </Button>
          }
          rightButtons={
            <Button type="submit" isLoading={loading} loadingText="Add Follow-Up">
              Add Follow-Up
            </Button>
          }
          scrollBehavior="outside"
        >
          <FollowUpForm />
        </Modal>
      </Formik>
    </>
  );
};

export default AddFollowUp;
