import { gql } from '@apollo/client';

import { RoleIdEnumType } from './generated/graphql';

// Created a new gql file for this so that the queries in the userGql.ts file will have custom hooks in the generated graqhql file
export const usersByRolesQuery = gql`
  query usersByRoles(
    $teamType: teamType
    $roles: [roleIdEnumType]
  ) {
    usersByRoles(teamType: $teamType, roles: $roles) {
      ${Object.keys(RoleIdEnumType).map(
        (role) => `${role} {
            id
            name
        }`,
      )}
    }
  }
`;
