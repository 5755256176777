import { FC, useContext, useMemo, useState } from 'react';

import { Select, Text, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../gql/generated/graphql';

import MoveAndCancelSigningModal from './ProgressionButtons/MoveAndCancelSigningModal';
import { getUniqueArray } from './utils';

import { PermissionEnum } from '../../constants/permissions';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { getShouldCancelContracts } from '../../utils/routeOne';

const STATES_TO_HIDE = [
  DealStateEnum.Floor,
  DealStateEnum.StructuringInProgress,
  DealStateEnum.Booted,
];

// States that Closer can change to another one
const CLOSER_AVAILABLE_STATES = [
  DealStateEnum.Closing,
  DealStateEnum.Structuring,
  DealStateEnum.SoftClose,
  DealStateEnum.Estimate,
];

export const BootBox = (props: { text: string }) => {
  const { text } = props;
  return (
    <Text
      borderRadius="4px"
      bgColor="spanishPink"
      color="errorsRed"
      textAlign="center"
      width="fit-content"
      paddingX="1em"
      whiteSpace="nowrap"
    >
      {text}
    </Text>
  );
};

export const ChangeDealStateSelect: FC = () => {
  const { deal, dealRefetch } = useContext(DealContext);
  const ability = useContext(AbilityContext);
  const { setModal } = useContext(ModalContext);

  const [selectedState, setSelectedState] = useState<DealStateEnum>(deal.state);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateDealState, { loading }] = useDealUpdateStateMutation();

  const previousDealStates = useMemo(
    () =>
      getUniqueArray(deal.deal_states, 'state').filter((state) => !STATES_TO_HIDE.includes(state)),
    [deal.deal_states],
  );

  const canChangeStateToAnyPrevious = useMemo(() => {
    if (ability.has(PermissionEnum.Closer)) {
      return CLOSER_AVAILABLE_STATES.includes(deal.state);
    }

    return ability.has(PermissionEnum.ChangeStateToAnyPrevious);
  }, [deal.state]);

  const changeState = async (newSelectedState?: DealStateEnum) => {
    if (!deal.id) {
      return;
    }

    setModal({ DealStateSelect: true });
    try {
      await updateDealState({
        variables: {
          id: deal.id,
          state: newSelectedState ?? selectedState,
        },
      });
      toast.success('Success!');

      // Refetch to update document_progress_status and the BackButton properly
      await dealRefetch();
    } catch (e) {
      const error = e as Error;
      toast.error(error.message || 'Failed to update. Please refresh the page', {
        autoClose: error.message ? false : undefined,
      });
    } finally {
      setModal({ DealStateSelect: false });
      onClose();
    }
  };

  const select = (
    <>
      <Select
        size="sm"
        bg="white"
        value={deal.state}
        onChange={async (e) => {
          const newSelectedState = e.target.value as DealStateEnum;
          setSelectedState(newSelectedState);

          const shouldCancelContractsBeforeMoving = getShouldCancelContracts(
            deal,
            newSelectedState,
          );
          if (shouldCancelContractsBeforeMoving) {
            onOpen();
          } else {
            await changeState(newSelectedState);
          }
        }}
      >
        {previousDealStates.map((state) => (
          <option key={state} value={state}>
            {DealStateLabelMap[state as keyof typeof DealStateLabelMap]}
          </option>
        ))}
      </Select>
      <MoveAndCancelSigningModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => changeState()}
        loading={loading}
      />
      {deal.state === DealStateEnum.Booted ? <BootBox text="Booted" /> : null};
    </>
  );

  const labelValue =
    deal.state === DealStateEnum.Booted ? (
      <BootBox text="Booted" />
    ) : (
      <Text color="white">{DealStateLabelMap[deal.state]}</Text>
    );

  return canChangeStateToAnyPrevious ? select : labelValue;
};
