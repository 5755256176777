import { MouseEvent, ReactElement, useState } from 'react';

import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { IconBaseProps } from 'react-icons';
import { BiCopyAlt } from 'react-icons/bi';

import { formatNumber } from '../../libs/utils';

interface Props extends Omit<IconButtonProps, 'aria-label'> {
  value: string;
  isFormatted?: boolean;
  iconProps?: IconBaseProps;
  icon?: ReactElement;
  keepLowercase?: boolean;
}

const CopyButtonV2 = ({
  value,
  isFormatted = false,
  iconProps,
  icon,
  keepLowercase = false,
  ...props
}: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (isFormatted) {
      navigator.clipboard.writeText(formatNumber(value));
    } else if (keepLowercase) {
      navigator.clipboard.writeText(value);
    } else {
      navigator.clipboard.writeText(value.toUpperCase());
    }

    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2750);
  };

  const buttonIcon = icon || <BiCopyAlt {...iconProps} />;

  return (
    <Tooltip isOpen={show} hasArrow label="Copied!">
      <IconButton
        icon={buttonIcon}
        variant="iconHover"
        size="xs"
        fontSize={12}
        onClick={handleClick}
        {...props}
        aria-label="Copy"
      />
    </Tooltip>
  );
};

export default CopyButtonV2;
