import React, { useContext, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { AbilityContext } from '../../libs/contextLib';

export type AuthenticatedRouteProps = RouteProps & {
  requiredPermission?: PermissionEnum;
  excludedPermission?: PermissionEnum;
};

// eslint-disable-next-line react/function-component-definition
export default function AuthenticatedRoute({
  requiredPermission,
  excludedPermission,
  children,
  ...rest
}: AuthenticatedRouteProps) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const abilities = useContext(AbilityContext);

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [abilities]);

  const isSuperUser = abilities.has(PermissionEnum.SuperUser);
  const hasRequiredPermission = !!requiredPermission && abilities.has(requiredPermission);
  // if you have the excludedPermission, you don't have access
  const hasExcludedPermission = !!excludedPermission && abilities.has(excludedPermission);
  const hasAccess =
    isSuperUser ||
    ((!requiredPermission || hasRequiredPermission) &&
      (!excludedPermission || !hasExcludedPermission));

  // abilities.size will only be truthy after the user has been loaded,
  // wait to render an authenticated route until that is in place
  const hasPermissions = abilities.size > 0;

  if (isAuthenticated && hasPermissions && !hasAccess) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  return (
    <Route {...rest}>{isAuthenticated && hasPermissions && hasAccess ? children : null}</Route>
  );
}
