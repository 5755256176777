import { PropsWithChildren, forwardRef } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import { Pod } from '../../gql/podGql';

type Props = PropsWithChildren<BoxProps> & {
  pod?: Pod;
};

export const PodColor = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { pod, ...rest } = props;
  const podName = pod?.name ?? 'No Pod Assigned';
  const podColor = pod?.color ?? 'white';
  const textColor = podColor === 'white' ? 'black' : 'white';

  return (
    <Box
      ref={ref}
      position="absolute"
      display="flex"
      alignItems="center"
      left={0}
      top={0}
      height="100%"
      width="10px"
      borderRadius={0}
      borderLeftRadius="inherit"
      backgroundColor={podColor}
      overflow="hidden"
      whiteSpace="nowrap"
      fontSize="11px"
      fontWeight="500"
      textTransform="none"
      color={podColor}
      _hover={{
        padding: '5px',
        width: 'fit-content',
        color: textColor,
        zIndex: 9,
      }}
      {...rest}
    >
      {podName}
    </Box>
  );
});
