import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { MdArticle } from 'react-icons/md';

import { getFullName } from '../../gql/customerGql';
import {
  Customer,
  DashboardDocumentUpload,
  useOnDashboardDocumentUploadSubscription,
} from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

const DashboardUploadHandler = () => {
  const [uploads, setUploads] = useState<DashboardDocumentUpload[]>([]);

  useOnDashboardDocumentUploadSubscription({
    fetchPolicy: 'network-only',
    onData: async ({ data: { data } }) => {
      if (!data?.onDashboardDocumentUpload) {
        return;
      }
      setUploads([...uploads, data.onDashboardDocumentUpload]);
    },
  });

  return (
    <>
      {uploads.map((u) => {
        const title = `${getFullName(u.deal?.customer as Customer)} - Deal ${u.deal?.id}`;

        return (
          <NotificationToast
            key={`${u.deal?.id}`}
            dealId={u?.deal?.id ?? null}
            slideFadeIn={!!uploads.length}
            title={title}
            titleIcon={MdArticle}
            titleIconColor="green"
            subTitle="Document Uploaded from Web Dashboard"
            details={<Text>{u.fileType} uploaded to the Media Center</Text>}
            onClose={() => setUploads(uploads.filter((upl) => upl.deal?.id !== u.deal?.id))}
          />
        );
      })}
    </>
  );
};

export default DashboardUploadHandler;
