import React, { useContext } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { DealContext } from '../../libs/DealContext';
import { FinancialInfoAcquisition } from '../../gql/financialInfoAcquisitionGql';
import { DealStateEnum } from '../../gql/dealGql';
import { passValuesToSchema } from '../../libs/utils';
import './DealInfoAcquisition.css';
import {
  validationSchemaSoftClose,
  validationSchemaStructuring,
  validationSchemaClosing,
} from './validationSchemas';
import SoftCloseView from './SoftCloseView';
import StructuringAndClosedView from './StructuringAndClosedView';
import ClosingView from './ClosingView';
import LogisticsView from './LogisticsView';

const DealInfoAcquisition: React.FC = () => {
  const { deal } = useContext(DealContext);
  const initialVals = {
    ...deal,
    financial_info_acquisition:
      deal.financial_info_acquisition ?? new FinancialInfoAcquisition(deal.id, deal.car.book_value),
  };

  const renderDealInfoByState = () => {
    switch (deal.state) {
      case DealStateEnum.SoftClose:
        return <SoftCloseView />;
      case DealStateEnum.Structuring:
      case DealStateEnum.Closed:
      case DealStateEnum.SentForSignatures:
      case DealStateEnum.Signed:
        return <StructuringAndClosedView />;
      case DealStateEnum.Closing:
        return <ClosingView />;
      case DealStateEnum.ReadyForPickup:
      case DealStateEnum.PaidOff:
      case DealStateEnum.AtAuction:
      case DealStateEnum.Sold:
      case DealStateEnum.TitleSent:
      case DealStateEnum.Finalized:
        return <LogisticsView />;
      default:
        return null;
    }
  };

  const validationSchemaPicker = () => {
    switch (deal.state) {
      case DealStateEnum.SoftClose:
        return validationSchemaSoftClose;
      case DealStateEnum.Structuring:
        return validationSchemaStructuring;
      case DealStateEnum.Closing:
        return validationSchemaClosing;
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={initialVals}
      onSubmit={() => undefined}
      validateOnMount
      enableReinitialize
      validate={(value) => passValuesToSchema(value, validationSchemaPicker())}
    >
      {() => {
        return (
          <Form noValidate className="mt-3">
            {renderDealInfoByState()}
          </Form>
        );
      }}
    </Formik>
  );
};

export default DealInfoAcquisition;
