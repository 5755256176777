import { Formik, FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { Col, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Button, Flex } from '@chakra-ui/react';
import { Note } from '../../gql/noteGql';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface NoteFormProps {
  note: Note;
  onSubmit: (
    values: Note,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: () => void,
  ) => void;
  placeholder?: string;
  buttonName?: string;
  button?: ReactElement;
}

const validationSchema = Yup.object().shape({
  text: Yup.string().required('Please enter a note'),
});

const NoteForm: React.FC<NoteFormProps> = ({
  note,
  placeholder = 'Enter a note',
  button,
  onSubmit,
  buttonName = 'Add note',
}) => {
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values: Note, { setSubmitting, resetForm }: FormikHelpers<Note>) => {
        onSubmit(values, setSubmitting, resetForm);
      }}
      initialValues={note}
      validateOnBlur={false}
      isInitialValid={false}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }) => (
        <StyledForm noValidate onSubmit={handleSubmit} className="h-100">
          <Form.Row className="h-90">
            <Form.Group as={Col} md="12" className="h-100">
              <Form.Control
                as="textarea"
                rows={5}
                name="text"
                placeholder={placeholder}
                value={values.text}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.text && !!errors.text}
                className="h-100"
              />
            </Form.Group>
          </Form.Row>
          <Flex>
            {button}
            <Button
              width={button ? 'unset' : '100%'}
              type="submit"
              isDisabled={!isValid}
              isLoading={isSubmitting}
              loadingText="SUBMIT"
            >
              {buttonName.toUpperCase()}
            </Button>
          </Flex>
        </StyledForm>
      )}
    </Formik>
  );
};

export default NoteForm;
