import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { Dispatch, useEffect } from 'react';
import { toast } from 'react-toastify';
import { carDetailVinQuery } from '../../gql/carGql';
import { Deal } from '../../gql/dealGql';

export const AutoExplodeVin = ({
  setCarDetailsLoading,
  callVinDecoder,
  setCallVinDecoder,
  isVinDirty,
  setVinDirty,
}: {
  setCarDetailsLoading?: Dispatch<boolean>;
  callVinDecoder: boolean;
  setCallVinDecoder: Dispatch<boolean>;
  isVinDirty: boolean;
  setVinDirty: Dispatch<boolean>;
}) => {
  const {
    values: {
      car: { vin },
    },
    setFieldValue,
  } = useFormikContext<Deal>();

  const [carDetailsVin, { loading: vinLoading, data: vinData, error: vinError }] = useLazyQuery(
    carDetailVinQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (vinData && vinData?.carDetailVin) {
      setFieldValue('car.year', vinData.carDetailVin?.year);
      setFieldValue('car.make', vinData.carDetailVin?.make);
      setFieldValue('car.model', vinData.carDetailVin?.model);
      setFieldValue('car.vehicle_type', vinData.carDetailVin?.vehicle_type);
      setFieldValue('car.fuel_type', vinData.carDetailVin?.fuel_type);
    }
  }, [vinData]);

  useEffect(() => {
    if (vinError) {
      toast.error('Failed to decode VIN');
    }
  }, [vinError]);

  useEffect(() => {
    if (setCarDetailsLoading) {
      setCarDetailsLoading(vinLoading);
    }
  }, [vinLoading]);

  useEffect(() => {
    if (vin.length === 17 && callVinDecoder && isVinDirty) {
      carDetailsVin({
        variables: { vin },
      });
      setCallVinDecoder(false);
      setVinDirty(false);
    }
  }, [vin, callVinDecoder, isVinDirty]);

  return null;
};
