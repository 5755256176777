import React, { useEffect } from 'react';
import { useToast, CreateToastFnReturn, Flex, Button, CloseButton } from '@chakra-ui/react';

interface ToastComponentProps {
  toast: CreateToastFnReturn;
  id: number;
  messageText: string;
  buttonText?: string;
  onClick?: () => void;
}

const ToastComponent: React.FC<ToastComponentProps> = ({
  toast,
  id,
  messageText,
  buttonText,
  onClick,
}) => {
  return (
    <Flex
      width="100%"
      p="10px"
      bgColor="electricBlue"
      borderRadius="5px 5px 0 0"
      color="white"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        maxWidth="650px"
        marginLeft="-10px"
      >
        {messageText}
        <Button onClick={onClick} marginLeft="10px">
          {buttonText}
        </Button>
      </Flex>
      <CloseButton onClick={() => toast.close(id)} position="absolute" right="5px" top="5px" />
    </Flex>
  );
};

interface ToastNotificationProps {
  id: number;
  showToast: boolean;
  messageText: string;
  buttonText?: string;
  onClick?: () => void;
}

const ToastNotification = ({
  id,
  showToast,
  messageText,
  buttonText,
  onClick,
}: ToastNotificationProps) => {
  const toast = useToast();

  useEffect(() => {
    if (id && !toast.isActive(id) && showToast) {
      toast({
        id,
        duration: null,
        status: 'success',
        containerStyle: {
          width: '1000px',
          maxWidth: '95%',
          marginTop: '-5px',
        },
        render: () => (
          <ToastComponent
            id={id}
            toast={toast}
            messageText={messageText}
            buttonText={buttonText}
            onClick={onClick}
          />
        ),
      });
    } else if (id && toast.isActive(id) && !showToast) {
      toast.close(id);
    }
  }, [id, showToast, messageText, buttonText, onClick]);

  useEffect(() => {
    return () => toast.closeAll();
  }, []);
};

export default ToastNotification;
