import { DOC_FEE } from '../gql/financialInfoGql';
import {
  GetFeesQueryVariables,
  StateAbbreviation,
  TemporaryData,
  TtFuelType,
} from '../gql/generated/graphql';

import { transformVehicleType } from './cars';

export interface TemporaryDataWithTotalPayoff extends TemporaryData {
  totalPayoff?: number;
}

export const mapTemporaryDataToGetTTFeesVariables = (
  temporaryData: TemporaryData,
): GetFeesQueryVariables => ({
  state: temporaryData.state as StateAbbreviation,
  previouslyTitledState:
    ((temporaryData.license_plate_state || temporaryData.state) as StateAbbreviation) ?? '',
  city: temporaryData.city ?? '',
  county: temporaryData.county ?? '',
  zip: temporaryData.zip ?? '',
  vin: temporaryData.vin ?? '',
  make: temporaryData.make ?? '',
  model: temporaryData.model ?? '',
  year: parseInt(temporaryData.year ?? '', 10),
  vehicleType: transformVehicleType(temporaryData.vehicle_type ?? ''),
  fuelType:
    temporaryData.fuel_type && TtFuelType[temporaryData.fuel_type as TtFuelType]
      ? (temporaryData.fuel_type as TtFuelType)
      : TtFuelType.Gasoline,
  firstName: temporaryData.first_name ?? '',
  lastName: temporaryData.last_name ?? '',
  retailBookValue: temporaryData.retail_book_value ?? 0,
  bookValue: temporaryData.book_value ?? 0,
  docFee: temporaryData.doc_fee ?? DOC_FEE,
  payoff: temporaryData.vehicle_payoff ?? 0,
  estimatedPayoff: 0,
  ssn: temporaryData.ssn || '111-11-1111',
  warranty: 0,
});
