import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  radioAnatomy.keys,
);

export const baseStyle = definePartsStyle({
  label: {
    _checked: {
      color: 'caribbeanGreen.500',
    },
  },
  control: {
    borderWidth: '1px',
    borderColor: 'silverSlateGray',
    _checked: {
      bg: 'white',
      _hover: { opacity: '100%' },
      _before: {
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '10px',
        bg: 'caribbeanGreen.500',
      },
    },
    _hover: {
      bg: 'white',
      borderColor: 'caribbeanGreen.500',
      opacity: '30%',
      _before: {
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '10px',
        bg: 'caribbeanGreen.500',
      },
    },
    _disabled: {
      bg: 'backgroundGray',
      opacity: '30%',
      _before: {
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '10px',
      },
      pointerEvents: 'none',
    },
  },
});

const radio = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'caribbeanGreen',
  },
});

export default radio;
