import React, { useMemo } from 'react';

import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import { Deal } from '../../../../gql/dealGql';
import { PodUser, useDealUpdateAssignmentsMutation } from '../../../../gql/generated/graphql';
import { User } from '../../../../gql/userGql';

import CustomReactSelect from '../../../shared/CustomReactSelect';
import { Option } from '../../../shared/types';

interface UserAutocompleteProps {
  dealProperty: 'title_clerk_id' | 'title_clerk2_id';
  titlingPodUsers: PodUser[];
  deal: Deal;
  user?: User;
}

const UserAutocomplete: React.FC<UserAutocompleteProps> = ({
  dealProperty,
  titlingPodUsers,
  deal,
  user,
}) => {
  const [updateDealAssignments] = useDealUpdateAssignmentsMutation();

  const users = useMemo(() => {
    const newUsers = [...titlingPodUsers];
    const selectedUserBelongsToCurrentPod = titlingPodUsers.some(
      (titlingUser) => titlingUser.user_id === user?.id,
    );
    if (user && !selectedUserBelongsToCurrentPod) {
      newUsers.unshift({ user_id: user.id, user });
    }

    return newUsers;
  }, [user?.id, titlingPodUsers]);

  const userOptions = useMemo(
    () =>
      users.map((userGql) => ({
        value: userGql.user_id ?? '',
        label: userGql.user?.name ?? '',
      })),
    [users],
  );

  const selectedUserOption = useMemo(
    () => (user ? { value: user.id, label: user.name } : null),
    [user?.id, user?.name],
  );

  const handleChange = async (newSelectedUserOption: SingleValue<Option>) => {
    if (!deal.id) {
      return;
    }

    const newSelectedUser = users.find(
      (userGql) => userGql.user_id === newSelectedUserOption?.value,
    );

    try {
      await updateDealAssignments({
        variables: {
          id: deal.id,
          [dealProperty]: newSelectedUser?.user_id ?? null,
        },
      });

      toast.success('Deal Assignment updated');
    } catch (error) {
      toast.error('Failed to update Deal Assignment');
    }
  };

  return (
    <CustomReactSelect
      placeholder=""
      isClearable
      options={userOptions}
      value={selectedUserOption}
      onChange={handleChange}
    />
  );
};

export default UserAutocomplete;
