import { useCallback, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { SingleValue } from 'react-select';

import { getFullName } from '../../gql/customerGql';
import { useSearchDealsLazyQuery } from '../../gql/generated/graphql';

import Suggest, { Suggestion } from './Suggest';

import useDebounce from '../../hooks/useDebounce';
import { AbilityContext } from '../../libs/contextLib';
import { getIsClickable } from '../../utils/deals';

const DealSuggest = () => {
  const abilities = useContext(AbilityContext);
  const history = useHistory();

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const [searchDeals, { loading }] = useSearchDealsLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.searchDeals) {
        setSuggestions(
          data.searchDeals.map((deal) => ({
            isDeal: true,
            isClickable: getIsClickable(deal, abilities),
            id: deal?.id,
            state: deal?.state,
            customerName: getFullName(deal?.customer),
            phoneNumber: deal?.customer?.phone_number,
            homePhoneNumber: deal?.customer?.home_phone_number,
            email: deal?.customer?.email,
            vin: deal?.car?.vin,
          })),
        );
      }
    },
  });

  const debouncedSearch = useDebounce((value: string) => {
    searchDeals({
      variables: {
        query: value,
      },
    });
  }, 500);

  const handleChange = useCallback(
    (newValue: SingleValue<Suggestion>) => {
      if (newValue && newValue.isClickable) {
        history.push(`/deals/${newValue.id}`);
      }
    },
    [history],
  );

  return (
    <Suggest
      label="Deals"
      suggestions={suggestions}
      setSuggestions={setSuggestions}
      search={debouncedSearch}
      isLoading={loading}
      handleChange={handleChange}
      order={{ base: 3, lg: 1 }}
      alignSelf="center"
      ml="auto"
      mb={{ base: '2px', lg: 0 }}
    />
  );
};

export default DealSuggest;
