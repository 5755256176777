import React, { useMemo } from 'react';

import { Box, Flex, Icon, Td, Text } from '@chakra-ui/react';
import { BsFillExclamationOctagonFill } from 'react-icons/bs';
import { FaAngleRight } from 'react-icons/fa';

import { getDealNotificationCountByType } from '../../../../gql/notificationsGql';

import { GetComponentParams as PayoffDealsTableGetComponentParams } from '../../../PayoffDealsTable/components/ColumnComponents';
import ConfirmPayoffFailure from '../../../PayoffDealsTable/components/ColumnComponents/ConfirmPayoffFailure';
import { GetComponentParams as TableViewGetComponentParams } from '../../../TableView/components/ColumnComponents';
import Notifications from '../Components/Notifications';

import { CopyButton } from '../../../CopyButton/CopyButton';
import TextNotificationBadgeV2 from '../../../NotificationBadge/TextNotificationBadgeV2';

interface Props extends PayoffDealsTableGetComponentParams, TableViewGetComponentParams {}

const Name: React.FC<Props> = ({ deal, payoffRequest, collapsed, setCollapsed, value }) => {
  const dealNotifications = useMemo(() => getDealNotificationCountByType(deal), [deal]);

  const handleClick = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    if (setCollapsed) {
      setCollapsed(!collapsed);
    }
  };

  const confirmPayoffFailureModalButton = payoffRequest ? (
    <ConfirmPayoffFailure payoffRequest={payoffRequest} />
  ) : null;

  return (
    <Td
      pl={payoffRequest ? '2rem' : '2.5rem'}
      position="relative"
      borderBottomWidth="1px"
      borderColor="gray.100"
    >
      <Flex alignItems="center">
        <Flex ml="-1.25rem" mr="0.25rem">
          <CopyButton
            value={`${window.location.origin}/deals/${deal.id}`}
            isFormatted
            visibility={!!deal.id && !!payoffRequest}
          />
        </Flex>
        <Box position="absolute" left="0.5rem">
          <TextNotificationBadgeV2 count={dealNotifications.unseenMessages} deal={deal} />
        </Box>
        {confirmPayoffFailureModalButton}
        <Icon
          as={FaAngleRight}
          transform={collapsed ? 'rotate(0deg)' : 'rotate(90deg)'}
          transition="transform 0.2s"
          marginRight={4}
          onClick={handleClick}
        />
        <Text>{value}</Text>
        {false && (
          <>
            {deal?.has_problem && (
              <Icon as={BsFillExclamationOctagonFill} w={5} h={5} ml={4} color="red" />
            )}
            <Notifications deal={deal} ml={4} />
          </>
        )}
      </Flex>
    </Td>
  );
};

export default Name;
