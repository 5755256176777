import React, { useState } from 'react';
import { HStack, IconButton } from '@chakra-ui/react';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { MdRotate90DegreesCcw } from 'react-icons/md';
import { useTransformEffect } from 'react-zoom-pan-pinch';

type Props = {
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleRotateLeft: () => void;
  handleRotateRight: () => void;
  initialScale: number;
  maxScale: number;
  minScale: number;
};

const Controls: React.FC<Props> = ({
  handleZoomIn,
  handleZoomOut,
  handleRotateLeft,
  handleRotateRight,
  initialScale,
  maxScale,
  minScale,
}) => {
  const [scale, setScale] = useState(initialScale);

  useTransformEffect(({ state }) => {
    setScale(state.scale);

    return () => {
      // unmount
    };
  });

  return (
    <HStack alignItems="center" flex={1} spacing={0} my={2} justifyContent="center">
      <IconButton
        variant="iconHover"
        aria-label="Rotate Clockwise"
        icon={<MdRotate90DegreesCcw />}
        size="md"
        onClick={() => {
          handleRotateRight();
        }}
        transform="scaleX(-1)"
      />
      <IconButton
        variant="iconHover"
        aria-label="Rotate Counter Clockwise"
        icon={<MdRotate90DegreesCcw />}
        size="md"
        onClick={() => {
          handleRotateLeft();
        }}
      />
      <IconButton
        variant="iconHover"
        aria-label="Zoom out"
        icon={<BiZoomOut />}
        size="md"
        isDisabled={scale === minScale}
        onClick={() => {
          handleZoomOut();
        }}
      />
      <IconButton
        variant="iconHover"
        aria-label="Zoom in"
        icon={<BiZoomIn />}
        size="md"
        isDisabled={scale === maxScale}
        onClick={() => {
          handleZoomIn();
        }}
      />
    </HStack>
  );
};

export default Controls;
