import React from 'react';
import { Th, Tr, Td, Table, Thead, Tbody } from '@chakra-ui/react';
import { Deal } from '../../../gql/dealGql';

enum DetailHeaders {
  STATE = 'State',
  YEAR = 'Year',
  MAKE = 'Make',
  MODEL = 'Model',
  DEAL_ID = 'Deal ID',
  DEAL_TASKS = 'Deal Tasks',
}

interface Props {
  collapsed: boolean;
  deal: Deal;
}

const dealDetail = (deal: Deal): string[] => [
  deal.customer.address?.state,
  deal.car.year,
  deal.car.make,
  deal.car.model,
  deal.id ? deal.id.toString() : '',
  '',
];

const DealDetail: React.FC<Props> = ({ collapsed, deal }) => {
  return (
    <Tr display={collapsed ? 'default' : 'none'}>
      <Td colSpan={6} p={0} paddingLeft={4} bgColor="hoverGray">
        <Table marginBottom={4}>
          <Thead>
            <Tr bgColor="mediumElectricBlue">
              {Object.values(DetailHeaders).map((header) => (
                <Th key={header} color="white" p={2} paddingLeft={4}>
                  {header}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody bgColor="white">
            <Tr p={1}>
              {dealDetail(deal).map((detail, index) => (
                <Td
                  key={`${deal.id}${detail}`}
                  p={2}
                  paddingLeft={4}
                  borderBottomLeftRadius={index === 0 ? 6 : 0}
                >
                  {detail}
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>
      </Td>
    </Tr>
  );
};

export default DealDetail;
