import React, { useContext } from 'react';
import {
  HStack,
  VStack,
  Text,
  Skeleton,
  StackDivider,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  IconButton,
  Box,
  Icon,
} from '@chakra-ui/react';
import { BiHistory } from 'react-icons/bi';
import { BsFillExclamationOctagonFill } from 'react-icons/bs';
import StatusIcons from './StatusIcons';
import { DealContext } from '../../../libs/DealContext';
import { getFullName } from '../../../gql/customerGql';

interface HeaderProps {
  loading: boolean;
  onOpen: () => void;
}

const Header: React.FC<HeaderProps> = ({ loading, onOpen }) => {
  const { deal } = useContext(DealContext);
  const { id, customer, cobuyer, source: dealSource, closer, funding_clerk, has_problem } = deal;

  return (
    <Box m={2} position="relative">
      <HStack
        p={4}
        h="160px"
        color="white"
        justifyContent="space-between"
        bgColor="mediumElectricBlue"
        borderTopRadius="10px"
      >
        <VStack alignItems="start" alignSelf="start">
          <Skeleton isLoaded={!loading}>
            <Text fontSize="2xl">
              {has_problem && <Icon as={BsFillExclamationOctagonFill} mr={2} mb={1} color="red" />}
              {`${getFullName(customer)}${cobuyer ? ` / ${getFullName(cobuyer)}` : ''}`}
            </Text>
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            <Text fontSize="xs" mt={0}>
              DEAL ID
            </Text>
            <Text fontSize="lg">{id}</Text>
          </Skeleton>
        </VStack>
        <StatusIcons loading={loading} deal={deal} />
        <HStack fontSize="xs" alignItems="start" divider={<StackDivider borderColor="gray.200" />}>
          <VStack alignItems="start" mr={8}>
            <HStack>
              <Text>TYPE:</Text>
              <Text casing="capitalize">{dealSource}</Text>
            </HStack>
            <HStack>
              <Text>FINANCIAL SPECIALIST:</Text>
              <Text>{closer?.name}</Text>
            </HStack>
          </VStack>
          <VStack alignItems="start" ml={2}>
            <HStack>
              <Text>ACCOUNTING:</Text>
              <Text>{funding_clerk?.name}</Text>
            </HStack>
          </VStack>
        </HStack>
        <VStack fontSize="xs" alignSelf="flex-end">
          <HStack>
            <Text color="white">Last Saved: TODO</Text>
            <IconButton
              icon={<BiHistory />}
              variant="iconYellow" // Same style as yellow IconButtons, but with white icon
              color="white"
              fontSize={24}
              aria-label="history"
              onClick={onOpen}
            />
          </HStack>
        </VStack>
      </HStack>
      <HStack alignItems="start" justifyContent="space-between" position="absolute" top="125px">
        <Tabs variant="enclosed" border={0}>
          <TabList border={0}>
            <Tab bg="white" mx={1} borderTopRadius="5px" border={0}>
              Deal Information
            </Tab>
            <Tab bg="white" mx={1} borderTopRadius="5px" border={0}>
              Notes
            </Tab>
            <Tab bg="white" mx={1} borderTopRadius="5px" border={0}>
              Customer Communications
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>TODO 1</TabPanel>
            <TabPanel>TODO 2</TabPanel>
            <TabPanel>TODO 3</TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </Box>
  );
};

export default Header;
