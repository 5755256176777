import { FC, useContext } from 'react';
import { Button } from '@chakra-ui/react';
import Modal from '../shared/Modal';
import { ModalContext } from '../../libs/contextLib';

interface ConfirmEditModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose?: () => void;
}

const ConfirmEditModal: FC<ConfirmEditModalProps> = ({ isOpen, onConfirm, onClose }) => {
  const { setModal } = useContext(ModalContext);
  const closeModal = () => {
    if (onClose) {
      onClose();
    }
    setModal({ ConfirmEdit: false });
  };

  return (
    <Modal
      title="Edit Info"
      isOpen={isOpen}
      onClose={closeModal}
      size="md"
      headerSize="md"
      canDismiss={false}
      showDivider={false}
      leftButtons={
        <Button
          variant="warning"
          size="md"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
      }
      rightButtons={
        <Button
          size="md"
          onClick={() => {
            onConfirm();
            closeModal();
          }}
        >
          Confirm
        </Button>
      }
    >
      Are you sure you want to edit the Deal Info?
    </Modal>
  );
};

export default ConfirmEditModal;
