import { useContext, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Checkbox, Divider, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { Bank, BankEnum } from '../../../../gql/bankGql';
import { Deal, submitToRouteOne } from '../../../../gql/dealGql';
import { ConsentNameEnumType } from '../../../../gql/generated/graphql';

import Modal from '../../../shared/Modal';
import Tooltip from '../../../shared/Tooltip';
import NoHardConsentModal from '../modals/NoHardConsentModal';

import { PermissionEnum } from '../../../../constants/permissions';
import { Can } from '../../../../libs/Can';
import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { isNumber } from '../../../../libs/utils';
import { cleanDealForUpdate } from '../../../../utils/deals';

const canSubmitCreditApp = (potentialLender: Bank): potentialLender is LenderOptions =>
  !!potentialLender.name && !!potentialLender.r1_fsid;

type LenderOptions = {
  name: string;
  r1_fsid: string;
  selected?: boolean;
};

const mapBankToLender = ({ name, r1_fsid }: LenderOptions): LenderOptions => ({
  name,
  r1_fsid,
  selected: false,
});

interface SubmitCreditAppButtonProps {
  banks: Bank[];
}

const SubmitCreditAppButton = ({ banks }: SubmitCreditAppButtonProps) => {
  const { isRecalculatingPayoff, deal, dispatch } = useContext(DealContext);

  const hasHardConsent = deal.consents?.some(
    (consent) => consent.type && consent.type === ConsentNameEnumType.CreditScoreHardPull,
  );

  const { values, setFieldValue, errors } = useFormikContext<Deal>();

  const [lenderOptions, setLenderOptions] = useState<LenderOptions[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const noHardConsentModalProps = useDisclosure();

  const [submitToR1, { loading }] = useMutation<{ submitToRouteOne: Deal }>(submitToRouteOne, {
    onCompleted: (data) => {
      setFieldValue('financial_info.id', data.submitToRouteOne.financial_info.id);
      setFieldValue('r1_jacket_id', data.submitToRouteOne.r1_jacket_id);

      toast.success('Credit App Created In Route One');
    },
    onError: (e) => {
      toast.error(`${e}`, { autoClose: false });
    },
  });

  useEffect(
    () =>
      setLenderOptions(
        banks
          .filter(canSubmitCreditApp)
          .map(mapBankToLender)
          .sort((a, b) => a.name.localeCompare(b.name)),
      ),
    [banks],
  );

  const selectedLendersCount = useMemo(
    () => lenderOptions.filter((l) => l.selected).length,
    [lenderOptions],
  );

  useEffect(() => {
    setIsDisabled(
      !values.car.year ||
        !values.car.vin ||
        !values.car.mileage ||
        !isNumber(values.car.retail_book_value) ||
        !isNumber(values.car.payoff.vehicle_payoff) ||
        !values.customer.address?.state ||
        !!errors.car?.vehicle_type ||
        !!errors.car?.payoff?.vehicle_payoff,
    );
  }, [values.customer.address, values.car, values.financial_info]);

  const handleOnOpen = () => {
    if (!hasHardConsent) {
      noHardConsentModalProps.onOpen();
    } else {
      onOpen();
    }
  };

  const handleOnClose = () => {
    setLenderOptions(
      lenderOptions.map((l) => ({
        ...l,
        selected: false,
      })),
    );
    onClose();
  };

  const submitToLenders = async () => {
    const cleanDeal = {
      ...cleanDealForUpdate(values),
      financial_info: values.financial_info,
      // Take R1 values from deal to avoid creating new a Deal Jacket.
      r1_jacket_id: deal.r1_jacket_id,
      r1_jacket_id_created_date_utc: deal.r1_jacket_id_created_date_utc,
    };

    try {
      const response = await submitToR1({
        variables: {
          deal: cleanDeal,
          lenderIds: lenderOptions.filter((l) => l.selected).map((l) => l.r1_fsid),
        },
      });

      handleOnClose();

      if (response.data?.submitToRouteOne) {
        const { r1_jacket_id, r1_jacket_id_created_date_utc } = response.data?.submitToRouteOne;

        // Dispatch R1 values to avoid creating new a Deal Jacket.
        dispatch({
          type: DealActionsEnum.UpdateDeal,
          payload: {
            r1_jacket_id,
            r1_jacket_id_created_date_utc,
          },
        });
      }
    } catch (e) {
      toast.error(`${e}`);
    }
  };

  return (
    <Can I={PermissionEnum.SubmitCreditApplication}>
      <Tooltip errors={[errors.car?.vehicle_type, errors.car?.payoff?.vehicle_payoff]}>
        <Button
          variant="secondary"
          float="right"
          isLoading={loading}
          loadingText="SUBMIT CREDIT APP"
          isDisabled={isDisabled || isRecalculatingPayoff}
          onClick={handleOnOpen}
        >
          Submit Credit App
        </Button>
      </Tooltip>
      <Modal
        size="2xl"
        title="Route One Lender Selection"
        isOpen={isOpen}
        canDismiss={!loading}
        onClose={handleOnClose}
        leftButtons={
          <Button onClick={handleOnClose} variant="warning" isDisabled={loading}>
            CANCEL
          </Button>
        }
        rightButtons={
          <Button
            onClick={submitToLenders}
            isLoading={loading}
            isDisabled={selectedLendersCount === 0}
          >
            SUBMIT
          </Button>
        }
      >
        <Flex direction="column">
          {lenderOptions.map((l, index) => (
            <Checkbox
              key={l.r1_fsid}
              isChecked={l.selected}
              onChange={() => {
                const newLenderOptions = [...lenderOptions];
                newLenderOptions[index] = { ...l, selected: !l.selected };

                setLenderOptions(newLenderOptions);
              }}
              isDisabled={selectedLendersCount >= 6 && !l.selected}
            >
              {l.name}
            </Checkbox>
          ))}
          {banks.some((bank) => bank.name === BankEnum.AFCU) && (
            <>
              <Divider my={2} />
              <Text>
                NOTE: This deal is eligible for {BankEnum.AFCU}. Submit application through CUDL.
              </Text>
            </>
          )}
        </Flex>

        {selectedLendersCount >= 6 ? 'Route One only allows a maximum of 6 lenders at a time.' : ''}
      </Modal>
      {!hasHardConsent && <NoHardConsentModal {...noHardConsentModalProps} />}
    </Can>
  );
};

export default SubmitCreditAppButton;
