import { ChangeEventHandler, FC, useContext } from 'react';

import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, DealStateEnum } from '../../../gql/dealGql';

import NumberInput from '../../shared/NumberInput';

import InputRow from './InputRow';
import VscRatesModal from './VscRatesModal';
import CalcGapButton from './buttons/CalcGapButton';

import { PermissionEnum } from '../../../constants/permissions';
import { Can } from '../../../libs/Can';
import { DealContext } from '../../../libs/DealContext';
import { AbilityContext } from '../../../libs/contextLib';
import { formatMoney } from '../../../libs/utils';

interface VscAndGapSectionProps extends FlexProps {
  isEditable: boolean;
  inputIsReadOnlyByPermissions: boolean;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const VscAndGapSection: FC<VscAndGapSectionProps> = ({
  isEditable,
  inputIsReadOnlyByPermissions,
  customHandleChange,
  ...rest
}) => {
  const { deal } = useContext(DealContext);
  const abilities = useContext(AbilityContext);

  const { values, handleChange: formikHandleChange } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const inClosing = deal.state === DealStateEnum.Closing;
  const inStructuring = deal.state === DealStateEnum.Structuring;
  const showProducts = inClosing || inStructuring;

  return (
    <Flex direction="column" {...rest}>
      <InputRow label="VSC Terms">
        <Text variant="dealInfoBuyout">
          {values.financial_info?.vsc_type ? `${values.financial_info?.vsc_type} - ` : ''}
          {values.financial_info?.vsc_term || ''}
        </Text>
      </InputRow>

      <InputRow label="VSC">
        {abilities.has(PermissionEnum.EditPriceAndCost) ? (
          <>
            <NumberInput
              name="financial_info.vsc_price"
              isMoney
              showThousandSeparator
              additionalHandleChange={handleChange}
              isDisabled={inputIsReadOnlyByPermissions || !isEditable}
            />
            <Can I={PermissionEnum.SeeCosts}>
              <NumberInput
                name="financial_info.vsc_cost"
                isMoney
                showThousandSeparator
                additionalHandleChange={handleChange}
                isDisabled={inputIsReadOnlyByPermissions || !isEditable}
              />
            </Can>
          </>
        ) : (
          <>
            <Text w="50%" variant="dealInfoBuyout">
              {formatMoney(values.financial_info?.vsc_price)}
            </Text>
            <Can I={PermissionEnum.SeeCosts}>
              <Text w="50%" variant="dealInfoBuyout">
                {formatMoney(values.financial_info?.vsc_cost)}
              </Text>
            </Can>
          </>
        )}
      </InputRow>

      <InputRow label="GAP">
        {abilities.has(PermissionEnum.EditPriceAndCost) ? (
          <>
            <NumberInput
              name="financial_info.gap_price"
              isMoney
              showThousandSeparator
              additionalHandleChange={handleChange}
              isDisabled={inputIsReadOnlyByPermissions || !isEditable}
            />
            <Can I={PermissionEnum.SeeCosts}>
              <NumberInput
                name="financial_info.gap_cost"
                isMoney
                showThousandSeparator
                additionalHandleChange={handleChange}
                isDisabled={inputIsReadOnlyByPermissions || !isEditable}
              />
            </Can>
          </>
        ) : (
          <>
            <Text w="50%" variant="dealInfoBuyout">
              {formatMoney(values.financial_info?.gap_price)}
            </Text>
            <Can I={PermissionEnum.SeeCosts}>
              <Text w="50%" variant="dealInfoBuyout">
                {formatMoney(values.financial_info?.gap_cost)}
              </Text>
            </Can>
          </>
        )}
      </InputRow>

      <Flex
        justifyContent="start"
        mt={3}
        gap={2}
        direction={{ base: 'row', xl: 'column', '2xl': 'row' }}
      >
        {showProducts && <VscRatesModal />}
        {showProducts && <CalcGapButton />}
      </Flex>
    </Flex>
  );
};
