import { gql } from '@apollo/client';
import { User } from './userGql';

// needs to match Enum in api types/note
export enum NoteTypeEnum {
  Manual = 'manual',
  System = 'system',
  TextOutgoing = 'text_outgoing',
  TextIncoming = 'text_incoming',
  CallOutgoing = 'call_outgoing',
  CallIncoming = 'call_incoming',
}

export class Note {
  id?: number;
  text: string;
  author_id?: string;
  deal_id?: number;
  created_at?: string;
  creation_date_tz?: string;
  note_type?: NoteTypeEnum;
  pinned?: boolean;

  author?: User;

  constructor(dealId: number) {
    this.text = '';
    this.deal_id = dealId;
  }
}

export const NoteTypeArray = Object.values(NoteTypeEnum);

export enum GroupedNoteTypeEnum {
  All = 'all',
  Manual = 'manual',
  AllCommunications = 'all_communications',
  Texts = 'texts',
  Calls = 'calls',
  System = 'system',
}

export const GroupedNoteTypeFilter = {
  all: (note: Note) => note,
  manual: (note: Note) => note.note_type === NoteTypeEnum.Manual,
  all_communications: (note: Note) =>
    note.note_type === NoteTypeEnum.TextOutgoing ||
    note.note_type === NoteTypeEnum.TextIncoming ||
    note.note_type === NoteTypeEnum.CallOutgoing ||
    note.note_type === NoteTypeEnum.CallIncoming,
  texts: (note: Note) =>
    note.note_type === NoteTypeEnum.TextOutgoing || note.note_type === NoteTypeEnum.TextIncoming,
  calls: (note: Note) =>
    note.note_type === NoteTypeEnum.CallOutgoing || note.note_type === NoteTypeEnum.CallIncoming,
  system: (note: Note) =>
    !note.note_type ||
    !NoteTypeArray.includes(note.note_type) ||
    note.note_type === NoteTypeEnum.System,
};

export enum GroupedNoteTypeDisplay {
  all = 'All',
  manual = 'Manual',
  all_communications = 'All Communications',
  texts = 'Texts',
  calls = 'Calls',
  system = 'System',
}

export const noteCreate = gql`
  mutation noteCreate($note: noteInput) {
    noteCreate(note: $note) {
      id
    }
  }
`;

export const noteUpdate = gql`
  mutation noteUpdate($note: noteInput) {
    noteUpdate(note: $note) {
      id
    }
  }
`;

export const noteDelete = gql`
  mutation noteDelete($id: ID!) {
    noteDelete(id: $id) {
      id
    }
  }
`;

export const notesFetch = gql`
  query notes($dealId: ID!) {
    notes(deal_id: $dealId) {
      id
      author_id
      text
      deal_id
      created_at
      creation_date_tz
      note_type
      pinned
      author {
        name
      }
    }
  }
`;
