import { FC, Fragment, useState } from 'react';

import { Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Deal } from '../../../gql/dealGql';
import { Pod, PodUser } from '../../../gql/generated/graphql';

import { tableViewColumns } from './ColumnComponents';
import DealDetail from './DealDetail';
import StateProgressBar from './StateProgressBar';
import ToastNotification from './ToastNotification';

import { useUser } from '../../../hooks/useUser';
import { handleClickOrCommandClick } from '../../../libs/eventHandlers';
import { showCreatePacketAlert } from '../../../utils/deals';

interface Props {
  deal: Deal;
  titlingPods: Pod[];
  titlingPodUsers: PodUser[];
}

const DealRowContainer: FC<Props> = ({ deal, titlingPods, titlingPodUsers }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const history = useHistory();
  const user = useUser();
  const tableColumns = Object.entries(tableViewColumns);

  ToastNotification({
    id: deal.id!,
    showToast: showCreatePacketAlert(deal, user),
    messageText: `Deal ${deal.id} - ${deal.customer.first_name} ${deal.customer.last_name} is ready for packet creation`,
    buttonText: 'CREATE PACKET',
    onClick: () => history.push(`/deals/${deal.id}`),
  });

  return (
    <>
      <Tr
        cursor="pointer"
        _hover={{ bgColor: 'hoverGray' }}
        onClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
        onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
        whiteSpace="nowrap"
      >
        {tableColumns.map(([header, { getComponent }]) => (
          <Fragment key={header}>
            {getComponent({
              deal,
              titlingPods,
              titlingPodUsers,
              collapsed,
              setCollapsed,
              highlight: showCreatePacketAlert(deal, user),
            })}
          </Fragment>
        ))}
      </Tr>
      <StateProgressBar deal={deal} key={`progress-${deal.id}`} numColumns={tableColumns.length} />
      <DealDetail collapsed={collapsed} deal={deal} />
    </>
  );
};

export default DealRowContainer;
