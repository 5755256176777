/* eslint-disable react-hooks/rules-of-hooks */
import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, Button, Flex, HStack, Textarea } from '@chakra-ui/react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from 'react-router-dom';

import { Deal, DealStateEnum } from '../../gql/dealGql';
import { referralSourceOptions } from '../../gql/dealReferralSourceGql';
import { DealType, ReferralSourceEnum, TeamType } from '../../gql/generated/graphql';
import { NoteTypeEnum } from '../../gql/noteGql';

import { AddressForm } from '../CreditApplication/components/AddressForm';
import { PersonalInformationForm } from '../CreditApplication/components/PersonalInformationForm';
import DealIssuesModal from '../DealIssuesModal';
import { DuplicateDealsModal } from '../DuplicateDealsModal/DuplicateDealsModal';
import { GridFormColumn, GridFormRow } from '../shared/GridForm';
import Input from '../shared/Input';
import Select from '../shared/Select';
import Tooltip from '../shared/Tooltip';

import './EstimateForm.scss';
import { PayoffForm } from './PayoffForm';
import { getValidationSchema } from './ValidationSchema';
import { VehicleForm } from './VehicleForm';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { useLienholder } from '../../hooks/useLienholder';
import { useSetValuesByDealType } from '../../hooks/useSetValuesByDealType';
import { useUser } from '../../hooks/useUser';
import { Can } from '../../libs/Can';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { getTimezoneStr, passValuesToSchema } from '../../libs/utils';
import DisabledLienholderModal from '../../pages/DealDetail/DisabledLienholderModal';
import { getTotalPayoff } from '../../utils/payoffs';

interface EstimateFormProps {
  onSave: (values: Deal, setSaving: (isSaving: boolean) => void) => void;
  requiredCombinations: string[][];
  setRequiredCombinations: Dispatch<SetStateAction<string[][]>>;
  showNoteField?: boolean;
  setShowPayoffRequestButton?: Dispatch<SetStateAction<boolean>>;
  setDuplicateDeals?: Dispatch<SetStateAction<Deal[]>>;
  isDuplicateModalOpen?: boolean;
  onOpenDuplicateModal?: () => void;
}

const EstimateForm = ({
  onSave,
  showNoteField,
  requiredCombinations,
  setRequiredCombinations,
  setShowPayoffRequestButton,
  setDuplicateDeals,
  isDuplicateModalOpen,
  onOpenDuplicateModal,
}: EstimateFormProps) => {
  const { deal, jurisdiction, payoffRequest, isPayoffRequested } = useContext(DealContext);
  const user = useUser();
  const abilities = useContext(AbilityContext);
  const isAdmin = abilities.has(PermissionEnum.SuperUser);
  const history = useHistory();

  const [carDetailsLoading, setCarDetailsLoading] = useState<boolean>(false);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [isSubmittingEstimate, setSubmittingEstimate] = useState<boolean>(false);
  const isClaimingOnSubmit = useRef<boolean>(false);
  const [callVinDecoder, setCallVinDecoder] = useState<boolean>(false);
  const [callLicensePlateDecoder, setCallLicensePlateDecoder] = useState<boolean>(false);
  const [isLicensePlateNumberDirty, setLicensePlateNumberDirty] = useState<boolean>(false);
  const [isLicensePlateStateDirty, setLicensePlateStateDirty] = useState<boolean>(false);
  const [isVinDirty, setVinDirty] = useState<boolean>(false);
  const [showDealIssuesModal, setShowDealIssuesModal] = useState<boolean>(false);
  const [isRecalculatingPayoff, setIsRecalculatingPayoff] = useState<boolean>(false);
  const [isPayoffRequestLoading, setIsPayoffRequestLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    setFieldValue,
    validateForm,
  } = useFormikContext<Deal>();

  const { doubleTax, directPayState, includeSalesTax } = useLienholder({
    data: values,
  });

  useSetValuesByDealType();

  useEffect(() => {
    if (payoffRequest?.status) {
      validateForm();
    }
  }, [payoffRequest?.status]);

  useEffect(() => {
    if (typeof values.car?.payoff?.payoff_includes_sales_tax !== 'boolean') {
      setFieldValue('car.payoff.payoff_includes_sales_tax', includeSalesTax);
    }
  }, [includeSalesTax]);

  useEffect(() => {
    if (!values.car.payoff?.sales_tax_from_payoff_entered_manually) {
      setFieldValue('car.payoff.sales_tax_from_payoff_entered_manually', false);
    }
  }, []);

  const handleCarBlur = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleBlur(e);

    if (
      values.car.license_plate_number &&
      values.car.license_plate_state &&
      (e.target.name === 'car.license_plate_number' ||
        e.target.name === 'car.license_plate_state') &&
      (isLicensePlateNumberDirty || isLicensePlateStateDirty)
    ) {
      setCallLicensePlateDecoder(true);
    }

    if (values.car.vin.length === 17 && e.target.name === 'car.vin') {
      setCallVinDecoder(true);
    }
  };

  const handleCarChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    if (
      e.target.name === 'car.license_plate_number' &&
      e.target.value !== values.car.license_plate_number
    ) {
      setLicensePlateNumberDirty(true);
    }
    if (
      e.target.name === 'car.license_plate_state' &&
      e.target.value !== values.car.license_plate_state
    ) {
      setLicensePlateStateDirty(true);
    }
    if (e.target.name === 'car.vin' && e.target.value !== values.car.vin) {
      setVinDirty(true);
    }

    handleChange(e);
  };

  const overlappingErrors = [
    errors.customer?.first_name,
    errors.customer?.last_name,
    errors.car?.vin,
    errors.car?.year,
    errors.car?.make,
    errors.car?.model,
    errors.customer?.phone_number,
    errors.customer?.home_phone_number,
    errors.customer?.address?.zip,
    errors.customer?.address?.state,
  ];

  const submitButtonErrors = [
    ...overlappingErrors,
    errors.car?.color,
    errors.car?.mileage,
    errors.car?.book_value,
    errors.car?.retail_book_value,
    errors.car?.jdp_adjusted_clean_trade,
    errors.car?.jdp_adjusted_clean_retail,
    errors.car?.license_plate_number,
    errors.car?.license_plate_state,
    errors.car?.registration_expiration,
    errors.car?.payoff?.lienholder_name,
    errors.car?.payoff?.lienholder_slug,
    errors.car?.payoff?.totalPayoff,
    errors.car?.payoff?.payoff_includes_sales_tax,
    errors.car?.payoff?.sales_tax_from_payoff_entered_manually,
    errors.car?.payoff?.sales_tax_from_payoff,
    errors.car?.payoff?.good_through_date,
    errors.car?.payoff?.next_payment_date,
    errors.customer?.email,
    errors.customer?.address?.address_line,
    errors.customer?.address?.address_line_2,
  ];

  const totalPayoffFieldErrors = [errors.customer?.address?.city, errors.customer?.address?.county];

  const isPayoffFieldDisabled = totalPayoffFieldErrors.some(Boolean) || isPayoffRequested;

  const saveButtonErrors = [
    errors.customer?.first_name,
    errors.customer?.last_name,
    errors.car?.registration_expiration,
  ];

  const onSubmit = () => {
    if (isClaimingOnSubmit.current) {
      handleSubmit();
    } else {
      setSubmittingEstimate(true);
      onSave(
        {
          ...values,
          state: DealStateEnum.SoftClose,
        },
        setSubmittingEstimate,
      );
    }
  };

  const checkForPossibleIssues = () => {
    if (directPayState || doubleTax || !!jurisdiction.requireWalkIn) {
      setShowDealIssuesModal(true);
    } else {
      onSubmit();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box mb={5}>
        <PersonalInformationForm name="customer" isUneditable={false} />
        <AddressForm name="customer" objectName="address" isUneditable={false} />
      </Box>

      {user.pods?.[0]?.team_type === TeamType.Inbound || isAdmin ? (
        <Box mb={5}>
          <GridFormColumn>
            <GridFormRow minChildWidth={180}>
              <Select
                label="How Did You Hear About Us?"
                name="referral_source.source_name"
                onChange={(e) => {
                  if (!e.target.value || e.target.value !== ReferralSourceEnum.Other) {
                    setFieldValue('referral_source.other_source_description', null, true);
                  }

                  handleChange(e);
                }}
                options={referralSourceOptions}
              />
              <Input
                name="referral_source.other_source_description"
                label="Description"
                isDisabled={values.referral_source?.source_name !== ReferralSourceEnum.Other}
              />
            </GridFormRow>
          </GridFormColumn>
        </Box>
      ) : null}

      <VehicleForm
        handleOnChange={handleCarChange}
        handleOnBlur={handleCarBlur}
        carDetailsLoading={carDetailsLoading}
        setCarDetailsLoading={setCarDetailsLoading}
        callVinDecoder={callVinDecoder}
        setCallVinDecoder={setCallVinDecoder}
        callLicensePlateDecoder={callLicensePlateDecoder}
        setCallLicensePlateDecoder={setCallLicensePlateDecoder}
        isLicensePlateNumberDirty={isLicensePlateNumberDirty}
        isLicensePlateStateDirty={isLicensePlateStateDirty}
        setLicensePlateNumberDirty={setLicensePlateNumberDirty}
        setLicensePlateStateDirty={setLicensePlateStateDirty}
        isVinDirty={isVinDirty}
        setVinDirty={setVinDirty}
      />
      <PayoffForm
        requiredCombinations={requiredCombinations}
        setRequiredCombinations={setRequiredCombinations}
        totalPayoffFieldErrors={totalPayoffFieldErrors}
        isPayoffFieldDisabled={isPayoffFieldDisabled}
        isRecalculatingPayoff={isRecalculatingPayoff}
        setIsRecalculatingPayoff={setIsRecalculatingPayoff}
        setShowPayoffRequestButton={setShowPayoffRequestButton}
        setDuplicateDeals={setDuplicateDeals}
        isDuplicateModalOpen={isDuplicateModalOpen}
        onOpenDuplicateModal={onOpenDuplicateModal}
        isPayoffRequestLoading={isPayoffRequestLoading}
        setIsPayoffRequestLoading={setIsPayoffRequestLoading}
        isSaving={isSaving}
      />

      {showNoteField && (
        <HStack mt={4} mx={5}>
          <Box w="100%">
            <Textarea
              bgColor="white"
              name="notes"
              onBlur={(e: FocusEvent<HTMLTextAreaElement>) => {
                const notes = e.target.value
                  ? [
                      {
                        text: e.target.value,
                        author_id: user.id,
                        note_type: NoteTypeEnum.Manual,
                        creation_date_tz: getTimezoneStr(),
                      },
                    ]
                  : [];
                setFieldValue('notes', notes);
                handleBlur(e);
              }}
              onChange={handleChange}
              placeholder="Write a note..."
              value={values.notes[0]?.text}
              rows={5}
            />
          </Box>
        </HStack>
      )}
      <Box mt={6} pt={5} pr={5} borderTopColor="gray.100" borderTopWidth="2px">
        <DealIssuesModal
          isOpen={showDealIssuesModal}
          onAcknowledge={() => onSubmit()}
          onDismiss={() => setShowDealIssuesModal(false)}
          deal={deal}
          directPayState={directPayState}
          doubleTax={doubleTax}
          requiresWalkIn={jurisdiction.requireWalkIn ?? false}
        />
        <Flex mx={5} justifyContent="space-between">
          {history.location.pathname === '/estimates/new' ? (
            <LinkContainer to={ROUTES.DASHBOARD}>
              <Button variant="warning" alignSelf="left">
                CANCEL
              </Button>
            </LinkContainer>
          ) : (
            <Box />
          )}

          <Flex wrap="wrap">
            <Tooltip errors={saveButtonErrors}>
              <Button
                variant="secondary"
                isLoading={isSaving}
                loadingText="SAVE"
                isDisabled={
                  saveButtonErrors.some(Boolean) ||
                  isSubmitting ||
                  isRecalculatingPayoff ||
                  isPayoffRequestLoading
                }
                onClick={() => {
                  setSaving(true);
                  onSave(
                    {
                      ...values,
                      state: deal.state,
                    },
                    setSaving,
                  );
                  validateForm();
                }}
              >
                SAVE ESTIMATE
              </Button>
            </Tooltip>

            <Tooltip errors={submitButtonErrors}>
              <Button
                isLoading={isSubmittingEstimate}
                loadingText="SUBMIT ESTIMATE"
                isDisabled={
                  isSaving ||
                  isRecalculatingPayoff ||
                  submitButtonErrors.some(Boolean) ||
                  isPayoffRequestLoading
                }
                onClick={() => {
                  isClaimingOnSubmit.current = false;
                  checkForPossibleIssues();
                }}
              >
                SUBMIT ESTIMATE
              </Button>
            </Tooltip>

            <Can I={PermissionEnum.SubmitAndClaimEstimate}>
              <Tooltip errors={submitButtonErrors}>
                <Button
                  isLoading={isSubmitting}
                  loadingText="SUBMIT AND CLAIM"
                  isDisabled={
                    isSaving ||
                    isRecalculatingPayoff ||
                    submitButtonErrors.some(Boolean) ||
                    isPayoffRequestLoading
                  }
                  onClick={() => {
                    isClaimingOnSubmit.current = true;
                    checkForPossibleIssues();
                  }}
                >
                  SUBMIT AND CLAIM
                </Button>
              </Tooltip>
            </Can>
          </Flex>
        </Flex>
      </Box>
    </Form>
  );
};

interface EstimateFormContainerProps {
  estimate: Deal;
  onSave: (values: Deal, setSaving: (isSaving: boolean) => void) => void;
  showNoteField?: boolean;
  duplicateDeals?: Deal[];
  setDuplicateDeals?: Dispatch<SetStateAction<Deal[]>>;
  submitEstimate?: (values: Deal, setSubmitting: (isSubmitting: boolean) => void) => void;
  upsertEstimateLoading?: boolean;
  isDuplicateModalOpen?: boolean;
  closeDuplicateModal?: () => void;
  onOpenDuplicateModal?: () => void;
}

const EstimateFormContainer = ({
  estimate,
  onSave,
  showNoteField,
  duplicateDeals,
  setDuplicateDeals,
  submitEstimate,
  upsertEstimateLoading,
  isDuplicateModalOpen,
  closeDuplicateModal,
  onOpenDuplicateModal,
}: EstimateFormContainerProps) => {
  const { modals } = useContext(ModalContext);
  const user = useUser();
  const abilities = useContext(AbilityContext);
  const isAdmin = abilities.has(PermissionEnum.SuperUser);
  const { payoffRequest } = useContext(DealContext);

  const [requiredCombinations, setRequiredCombinations] = useState<string[][]>([]);
  const [showPayoffRequestButton, setShowPayoffRequestButton] = useState<boolean>(false);

  const initialValues: Deal = useMemo(
    () => ({
      ...estimate,
      customer: {
        ...estimate.customer,
        ssn_last_4: estimate.customer?.ssn
          ? estimate.customer.ssn.startsWith('___-__')
            ? `XXX-XX-${estimate.customer.ssn.slice(-4)}`
            : estimate.customer.ssn
          : '',
        ssn_last_6: estimate.customer?.ssn
          ? estimate.customer.ssn.startsWith('___')
            ? `XXX-${estimate.customer.ssn.slice(-7)}`
            : estimate.customer.ssn
          : '',
      },
      car: {
        ...estimate.car,
        payoff: {
          ...estimate.car.payoff,
          payoff_includes_sales_tax: !!estimate.car.payoff?.payoff_includes_sales_tax,
          sales_tax_from_payoff_entered_manually:
            !!estimate.car.payoff?.sales_tax_from_payoff_entered_manually,

          totalPayoff: getTotalPayoff(estimate.car.payoff),
        },
      },
    }),
    [modals, estimate],
  );

  return (
    <Formik
      onSubmit={(values: Deal, { setSubmitting }: FormikHelpers<Deal>) =>
        onSave({ ...values, state: DealStateEnum.SoftClose }, setSubmitting)
      }
      initialValues={initialValues}
      validate={(values) =>
        passValuesToSchema(
          values,
          getValidationSchema(
            user.pods?.[0]?.team_type === TeamType.Inbound || isAdmin,
            values.type === DealType.Refi,
          ),
          {
            ...values,
            requiredCombinations,
            payoffRequest,
          },
        )
      }
      enableReinitialize
      validateOnMount
    >
      <>
        <EstimateForm
          onSave={onSave}
          showNoteField={showNoteField}
          requiredCombinations={requiredCombinations}
          setRequiredCombinations={setRequiredCombinations}
          setShowPayoffRequestButton={setShowPayoffRequestButton}
          setDuplicateDeals={setDuplicateDeals}
          isDuplicateModalOpen={isDuplicateModalOpen}
          onOpenDuplicateModal={onOpenDuplicateModal}
        />
        {duplicateDeals ? (
          <DuplicateDealsModal
            isDuplicateModalOpen={isDuplicateModalOpen}
            closeDuplicateModal={closeDuplicateModal}
            setShowPayoffRequestButton={setShowPayoffRequestButton}
            showPayoffRequestButton={showPayoffRequestButton}
            duplicateDeals={duplicateDeals}
            submitEstimate={submitEstimate}
            upsertEstimateLoading={upsertEstimateLoading}
          />
        ) : null}

        <DisabledLienholderModal />
      </>
    </Formik>
  );
};

export default EstimateFormContainer;
