import { ChangeEvent, ChangeEventHandler, FC, FocusEventHandler, ReactNode, useMemo } from 'react';

import {
  Box,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAddonProps,
  InputGroup,
  InputGroupProps,
  InputRightAddon,
  InputRightElement,
  Spinner,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { MdOutlineVisibilityOff } from 'react-icons/md';
import InputMask from 'react-input-mask';

import { CopyButton } from '../CopyButton/CopyButton';

type InputProps = {
  label: string;
  name: string;
  showErrorMessage?: boolean;
  rightAddon?: string;
  formControlVariant?: 'inline' | 'reduced' | 'bold' | undefined;
  formControlProps?: FormControlProps;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?:
    | FocusEventHandler<HTMLInputElement>
    | ((e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void);
  mask?: string;
  copyButton?: boolean;
  viewButton?: boolean;
  turnViewOff?: (value: boolean) => void;
  isLoading?: boolean;
  floatingInput?: ReactNode;
  helperText?: string;
  isInvalid?: boolean;
  inputProps?: InputAddonProps;
  inputGroupProps?: InputGroupProps;
} & ChakraInputProps;

const Input: FC<InputProps> = ({
  label,
  name,
  showErrorMessage = true,
  rightAddon,
  formControlVariant,
  formControlProps,
  onChange,
  onBlur,
  type = 'text',
  mask,
  copyButton,
  viewButton,
  turnViewOff,
  isLoading,
  floatingInput,
  helperText,
  isInvalid,
  inputProps,
  inputGroupProps,
  ...rest
}) => {
  const { handleBlur, handleChange, getFieldMeta, setFieldTouched, isSubmitting } =
    useFormikContext();
  const { value, error, touched } = getFieldMeta(name);
  const defaultIsInvalid = touched && !!error;

  const styles = useMultiStyleConfig('FormControl', { variant: formControlVariant });

  const currentLabel = useMemo(
    () => `${label} ${rest.isRequired ? '*' : ''}`,
    [label, rest.isRequired],
  );

  // Workaround for dynamic form fields.
  if (isSubmitting && !touched) {
    setFieldTouched(name, true, false);
  }

  return (
    <FormControl
      isInvalid={isInvalid ?? defaultIsInvalid}
      sx={styles.control}
      {...formControlProps}
    >
      <FormLabel whiteSpace="nowrap" sx={styles.label}>
        {currentLabel}
        <Box float="right">{floatingInput}</Box>
      </FormLabel>
      <InputGroup sx={styles.input} {...inputGroupProps}>
        <ChakraInput
          as={mask ? InputMask : undefined}
          mask={mask}
          type={type}
          name={name}
          onBlur={onBlur ?? handleBlur}
          onChange={onChange ?? handleChange}
          value={(value as string) ?? ''}
          _hover={{
            // fix input style props not being overwritten with customized theme
            // https://github.com/chakra-ui/chakra-ui/issues/2347
            borderColor: 'currentColor',
          }}
          {...inputProps}
          {...rest}
        />
        {isLoading ? (
          <InputRightElement alignItems="center">
            <Spinner size="sm" />
          </InputRightElement>
        ) : null}
        {viewButton && turnViewOff ? (
          <InputRightElement width="30px">
            <IconButton
              icon={<MdOutlineVisibilityOff size={15} />}
              variant="iconHover"
              size="xs"
              aria-label="Hide SSN"
              onClick={() => turnViewOff(true)}
            />
          </InputRightElement>
        ) : null}
        {rightAddon ? <InputRightAddon>{rightAddon}</InputRightAddon> : null}
        {copyButton ? (
          <InputRightAddon>
            <CopyButton value={value as string} isFormatted />
          </InputRightAddon>
        ) : null}
      </InputGroup>
      {helperText ? <FormHelperText sx={styles.helperText}>{helperText}</FormHelperText> : null}
      {showErrorMessage ? <FormErrorMessage sx={styles.error}>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default Input;
