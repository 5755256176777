import { cloneDeep } from 'lodash';

import { Bank, BankEnum } from '../gql/bankGql';
import { DEFAULT_BANKS_BY_DEAL_TYPE, FinancialInfo } from '../gql/financialInfoGql';
import { DealType } from '../gql/generated/graphql';

export const cleanFinancialInfo = (financialInfo?: FinancialInfo) => {
  if (!financialInfo) {
    return financialInfo;
  }

  const financialInfoCopy = cloneDeep(financialInfo);

  delete financialInfoCopy.newBaseTaxAmount;
  delete financialInfoCopy.totalTaxAmount;
  delete financialInfoCopy.feesQueryFailed;
  delete financialInfoCopy.maxMarkup;
  delete financialInfoCopy.tempUserEnteredReserve;

  return financialInfoCopy;
};

export const nonR1Banks = [BankEnum.ICCU, BankEnum.MountainAmerica, BankEnum.AFCU];
export const canBankUseR1 = (bankName?: BankEnum) => bankName && !nonR1Banks.includes(bankName);

export const getMaxBankMarkup = (banks: Bank[], bankName?: BankEnum, term?: number) => {
  if (banks.length === 0 || !bankName || !term) {
    return null;
  }

  const bank = banks.find((b) => b.name === bankName);
  const bankMarkup = bank?.bank_markups?.find(
    (m) => (m.term_min ?? 0) <= term && (m.term_max ?? 0) >= term,
  );

  return bankMarkup?.max_markup ?? null;
};

export const getDefaultBankByDealType = (dealType = DealType.Buyout) =>
  DEFAULT_BANKS_BY_DEAL_TYPE[dealType];
