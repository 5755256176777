import { Box } from '@chakra-ui/react';
import React from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';

interface DropzoneProps {
  onDrop: (a: File[]) => void;
  acceptedFiles?: File[];
  accept?: string[] | string;
  multiple?: boolean;
  showFiles?: boolean;
  marginTop?: number;
}

const Dropzone: React.FC<DropzoneProps> = ({
  onDrop,
  acceptedFiles,
  accept,
  multiple,
  showFiles = true,
  marginTop = 0,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  const files =
    showFiles && acceptedFiles?.length
      ? acceptedFiles.map((file: FileWithPath) => <li key={file.path}>{file.path}</li>)
      : null;

  return (
    <Box
      {...getRootProps()}
      border="dashed 2px"
      borderRadius="2xl"
      w="100%"
      p="8"
      alignContent="center"
      justifyContent="center"
      borderColor="mediumElectricBlue"
      bg="gray.100"
      display="flex"
      marginTop={marginTop}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className="m-0">Drop the files here...</p>
      ) : files?.length ? (
        <ul className="m-0">{files}</ul>
      ) : (
        <p className="m-0">Drag and Drop or Click to Select Files</p>
      )}
    </Box>
  );
};

export default Dropzone;
