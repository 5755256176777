import { FollowUpTypeEnum } from '../gql/generated/graphql';

export const getFollowUpTypeLabel = (type?: FollowUpTypeEnum): string => {
  switch (type) {
    case FollowUpTypeEnum.Email:
      return 'Email';
    case FollowUpTypeEnum.Text:
      return 'Text';
    case FollowUpTypeEnum.SendContracts:
      return 'Send Contracts';
    default:
      return 'Call';
  }
};
