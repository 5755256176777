import { gql } from '@apollo/client';

export const acquisitionOfferQuery = gql`
  query acquisitionOffer(
    $accidents: accidentsEnumType!
    $mileage: Int!
    $mmr_value: Float!
    $book_value: Int!
    $vehicle_type: String
    $lienholder: String
    $make: String
  ) {
    acquisitionOffer(
      accidents: $accidents
      mileage: $mileage
      mmr_value: $mmr_value
      book_value: $book_value
      vehicle_type: $vehicle_type
      lienholder: $lienholder
      make: $make
    ) {
      offer
      appraisedValue
    }
  }
`;
