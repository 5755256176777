import { DaysToPaymentEnum } from '../../gql/financialInfoGql';

import { Option } from '../shared/types';

export const daysToPaymentArray = [
  DaysToPaymentEnum.Thirty,
  DaysToPaymentEnum.FortyFive,
  DaysToPaymentEnum.Sixty,
  DaysToPaymentEnum.Ninety,
];

export const daysToPaymentOptions: Option[] = daysToPaymentArray.map((days) => ({
  label: days.toString(),
  value: days.toString(),
}));

export const getTermName = (months: number, miles: number) =>
  `${months / 12} years/${miles / 1000}k`;

export const plateTransferArr = [
  { id: 1, name: 'false', label: 'No' },
  { id: 2, name: 'true', label: 'Yes' },
];

export const CARD_PAYMENT_AMOUNT_MIN = 1000;
export const CARD_PAYMENT_AMOUNT_MAX = 3000;
