import { gql } from '@apollo/client';

import { PodUser, TeamRole } from './generated/graphql';

import { PermissionEnum } from '../constants/permissions';

import { Deal } from './dealGql';
import {
  CloserNotificationStates,
  FundingNotificationStates,
  SetterNotificationStates,
  TitlingNotificationStates,
} from './messageGql';
import { Pod } from './podGql';

export class User {
  id: string;
  email: string;
  name: string;
  nickname: string;
  phone_number?: string;
  twilio_number?: string;
  permissions?: PermissionEnum[];
  role?: string;
  pods?: Pod[];
  titlingPods?: Pod[];
  deleted_at?: string;
  created_at?: string;
  updated_at?: string;

  // Easier way for admins to control if the user can claim deals
  can_claim_as_closer?: boolean;

  hours: string;
  on_vacation: boolean;
  auto_assign_deals: boolean;
  overnight_deals: boolean;

  constructor(id?: string) {
    this.id = id || '';
    this.email = '';
    this.name = '';
    this.nickname = '';
    this.phone_number = '';
    this.twilio_number = '';
    this.hours = '';
    this.on_vacation = false;
    this.auto_assign_deals = false;
    this.overnight_deals = false;
  }
}

export type getAvailableUsersStatusType = {
  id: number;
  email: string;
  name: string;
  nickname: string;
  phone_number: string;
  twilio_number: string;
  in_call: string | null;
};

export type UserWithTitlingRoleType = {
  id: string;
  name: string;
  teamRole: TeamRole;
};

export type PodUsersByPod = {
  all: PodUser[];
  active: PodUser[];
  [key: string]: PodUser[];
};

export const usersQuery = gql`
  query users(
    $pod_id: ID
    $include_financial_specialists: Boolean
    $include_accounting_clerks: Boolean
    $include_structuring_managers: Boolean
  ) {
    users(
      pod_id: $pod_id
      include_financial_specialists: $include_financial_specialists
      include_accounting_clerks: $include_accounting_clerks
      include_structuring_managers: $include_structuring_managers
    ) {
      id
      email
      name
      nickname
      deleted_at
      pods {
        id
      }
    }
  }
`;

export const getUserInfo = gql`
  query userInfo($ids: [String]!) {
    users(ids: $ids) {
      twilio_number
      hours
      on_vacation
      auto_assign_deals
      overnight_deals
    }
  }
`;

export const usersWithRoleQuery = gql`
  query usersWithRole {
    usersWithRole {
      id
      email
      name
      nickname
      role
      deleted_at
      phone_number
      twilio_number
      pods {
        id
        color
        name
      }
      hours
      on_vacation
      auto_assign_deals
      overnight_deals
      created_at
    }
  }
`;

export const createUserMutation = gql`
  mutation createUser($createUserInput: createUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
      email
      name
      nickname
    }
  }
`;

export const deleteUserMutation = gql`
  mutation softDeleteUser($id: String!) {
    softDeleteUser(id: $id)
  }
`;

export const getUserCreationDataQuery = gql`
  query getUserCreationData {
    getUserCreationData {
      huntGroups {
        id
        name
        slug
      }
      pods {
        id
        name
      }
    }
  }
`;

export const userPermissionsQuery = gql`
  query userPermissions($user_id: String!) {
    userPermissions(user_id: $user_id)
  }
`;

export const titlingUsersQuery = gql`
  query titlingUsers($titling_pod_id: ID) {
    titlingUsers(titling_pod_id: $titling_pod_id) {
      user_id
      pod_id
      team_role
      user {
        id
        email
        name
        nickname
        deleted_at
      }
    }
  }
`;

export const getUserTwilioNumber = gql`
  query getUserTwilioNumber {
    getUserTwilioNumber
  }
`;

// this query is doing two unrelated queries in once API call
export const getAvailableUsersAndAllHuntGroups = gql`
  query getAvailableUsersAndAllHuntGroups {
    getAvailableUsers {
      id
      email
      name
      nickname
      phone_number
      twilio_number
      call_status
      in_call
    }
    getAllHuntGroups {
      id
      name
      phone_number
      slug
    }
  }
`;

export const updateUserCallStatus = gql`
  mutation updateUserCallStatus($status: String!) {
    updateUserCallStatus(status: $status) {
      call_status
    }
  }
`;

export const updateUserSettings = gql`
  mutation updateUserSettings(
    $id: String!
    $hours: String!
    $on_vacation: Boolean!
    $auto_assign_deals: Boolean!
    $overnight_deals: Boolean!
  ) {
    updateUserSettings(
      id: $id
      hours: $hours
      on_vacation: $on_vacation
      auto_assign_deals: $auto_assign_deals
      overnight_deals: $overnight_deals
    ) {
      id
      hours
      on_vacation
      auto_assign_deals
      overnight_deals
    }
  }
`;

export const loggedUserQuery = gql`
  query loggedUser {
    loggedUser {
      name
      call_status
      can_claim_as_closer
      hours
      on_vacation
      pods {
        team_type
      }
    }
  }
`;

export const userTitlingPodsQuery = gql`
  query userTitlingPods {
    userTitlingPods {
      id
      name
      color
      us_states_object {
        states
      }
      problem_solver
      archived
      pod_users {
        id
        user_id
        pod_id
        team_role
        user {
          id
          name
        }
      }
    }
  }
`;

export const switchCallStatus = gql`
  subscription switchCallStatus {
    switchCallStatus
  }
`;

export const switchCallStatusIndividual = gql`
  subscription switchCallStatusIndividual {
    switchCallStatusIndividual
  }
`;

export const isUserAssignedToDeal = (user: User, deal?: Deal) => {
  if (!deal) {
    return false;
  }

  return (
    deal.setter_id === user.id ||
    deal.setter?.id === user.id ||
    deal.closer_id === user.id ||
    deal.closer?.id === user.id ||
    deal.closer2_id === user.id ||
    deal.closer2?.id === user.id ||
    deal.funding_clerk_id === user.id ||
    deal.funding_clerk?.id === user.id ||
    deal.title_clerk_id === user.id ||
    deal.title_clerk?.id === user.id ||
    deal.title_clerk2_id === user.id ||
    deal.title_clerk2?.id === user.id
  );
};

export const isUserAssignedTitleTeamLead = (user: User, deal?: Deal) => {
  if (!deal?.titling_pod?.leads?.length) {
    return false;
  }

  return deal.titling_pod.leads.some((lead) => lead.id === user.id);
};

export const isDealStateRelevantForRole = (user: User, deal?: Deal) => {
  if (!deal) {
    return false;
  }

  return (
    (deal.setter_id === user.id && SetterNotificationStates.includes(deal.state)) ||
    (deal.closer_id === user.id && CloserNotificationStates.includes(deal.state)) ||
    (deal.closer2_id === user.id && CloserNotificationStates.includes(deal.state)) ||
    (deal.funding_clerk_id === user.id && FundingNotificationStates.includes(deal.state)) ||
    (deal.title_clerk_id === user.id && TitlingNotificationStates.includes(deal.state)) ||
    (deal.title_clerk2_id === user.id && TitlingNotificationStates.includes(deal.state))
  );
};
