import { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { SingleValue } from 'react-select';

import {
  Pod as GeneratedPod,
  Maybe,
  useTitlingUsersQuery,
  useUsersQuery,
} from '../../gql/generated/graphql';
import { Pod } from '../../gql/podGql';
import { User, userPermissionsQuery } from '../../gql/userGql';

import CustomReactSelect from './CustomReactSelect';

import { PermissionEnum } from '../../constants/permissions';

interface UserAutocompleteProps extends InputGroupProps {
  size?: 'sm' | 'lg';
  label?: string;
  includeEmptyOption?: boolean;
  emptyOptionLabel?: string;
  user?: User;
  setUser: (user?: User) => void;
  setSelectedUserAbilities?: (permissions: Set<PermissionEnum>) => void;
  podType?: 'sales' | 'titling';
  selectedPod?: Pod | Maybe<GeneratedPod>;
  name?: string;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  filterOnlyActive?: boolean;
}

const UserAutocomplete = ({
  label,
  size,
  includeEmptyOption,
  emptyOptionLabel,
  user,
  setUser,
  setSelectedUserAbilities,
  podType = 'sales',
  selectedPod,
  name,
  loading = false,
  disabled = false,
  required = false,
  error = '',
  filterOnlyActive,
  ...rest
}: UserAutocompleteProps) => {
  const styles = useMultiStyleConfig('FormControl');

  const [users, setUsers] = useState<User[]>([]);

  const usersGql = useUsersQuery({
    variables: {
      pod_id: selectedPod?.id,
      include_financial_specialists: !name || name === 'closer' || name === 'closer2',
      include_accounting_clerks: !name || name === 'funding_clerk',
      include_structuring_managers: !name || name === 'structuring_manager',
    },
    skip: podType === 'titling',
  });
  const titlingUsersGql = useTitlingUsersQuery({
    variables: { titling_pod_id: selectedPod?.id },
    skip: podType === 'sales',
  });

  const queryResults: Maybe<User[]> = useMemo(
    () =>
      (podType === 'sales'
        ? usersGql.data?.users
        : titlingUsersGql.data?.titlingUsers?.map((podUser) => podUser?.user) || []) as Maybe<
        User[]
      >,
    [usersGql.data?.users, titlingUsersGql.data?.titlingUsers],
  );

  const userPermissionsGql = useQuery<{ userPermissions: PermissionEnum[] }>(userPermissionsQuery, {
    variables: {
      user_id: user?.id,
    },
    skip: !user || !setSelectedUserAbilities,
  });

  const userOptions = useMemo(() => {
    const mapFn = (userGql: User) => ({
      value: userGql?.id,
      label: userGql?.name,
    });
    if (filterOnlyActive) {
      return users
        .filter((u) =>
          user ? (user.id !== u?.id && !u?.deleted_at) || u?.id === user.id : !u?.deleted_at,
        )
        .map(mapFn);
    }

    return users.map(mapFn);
  }, [users]);

  const selectedUserOption = useMemo(() => {
    if (!user) {
      return null;
    }

    if (user?.name) {
      return { value: user.id, label: user.name };
    }

    const userFromGql = users.find((userGql) => userGql?.id === user?.id);
    return userFromGql ? { value: userFromGql?.id, label: userFromGql?.name } : null;
  }, [user, users]);

  useEffect(() => {
    if (queryResults) {
      const newUsers = [...queryResults];

      const selectedUserBelongsToCurrentPod = queryResults.some(
        (podUser) => podUser?.id === user?.id,
      );
      if (user && !selectedUserBelongsToCurrentPod) {
        newUsers.unshift(user);
      }

      if (newUsers) {
        setUsers(newUsers);
      }
    }
  }, [queryResults]);

  useEffect(() => {
    if (
      userPermissionsGql.data &&
      userPermissionsGql.data.userPermissions &&
      setSelectedUserAbilities
    ) {
      setSelectedUserAbilities(new Set(userPermissionsGql.data.userPermissions));
    }
  }, [userPermissionsGql.data]);

  const handleChange = async (
    newSelectedUserOption: SingleValue<{ value: Maybe<number> | string; label: Maybe<string> }>,
  ) => {
    const newUser = users.find((userGql) => userGql?.id === newSelectedUserOption?.value);

    if (newUser) {
      setUser(newUser);
    } else if (includeEmptyOption) {
      setUser(undefined);
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <InputGroup size={size} {...rest}>
        {!!label && <InputLeftAddon width="160px"> {label}</InputLeftAddon>}
        <CustomReactSelect
          hasLeftAddon={!!label}
          isClearable={includeEmptyOption}
          placeholder={emptyOptionLabel}
          options={userOptions}
          value={selectedUserOption}
          onChange={handleChange}
          name={name}
          isLoading={loading}
          isDisabled={disabled}
          required={required}
          isInvalid={!!error}
        />
      </InputGroup>
      <FormErrorMessage sx={styles.error}>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default UserAutocomplete;
