import { Dispatch, MouseEventHandler, SetStateAction } from 'react';

import { AvatarGroup, Box, Button, Divider, Link, Text } from '@chakra-ui/react';

import { getFullName } from '../../gql/customerGql';
import { Deal, DealStateLabelMap } from '../../gql/dealGql';

import RequestPayoffButton from '../EstimateForm/RequestPayoffButton';
import UserAvatar from '../Inbox/components/UserAvatar';
import Modal from '../shared/Modal';

interface DuplicateDealsModalProps {
  isDuplicateModalOpen?: boolean;
  closeDuplicateModal?: () => void;
  setShowPayoffRequestButton: Dispatch<SetStateAction<boolean>>;
  showPayoffRequestButton: boolean;
  duplicateDeals: Deal[];
  submitEstimate?: (values: Deal, setSubmitting: (isSubmitting: boolean) => void) => void;
  upsertEstimateLoading?: boolean;
}

export const DuplicateDealsModal = ({
  isDuplicateModalOpen,
  closeDuplicateModal,
  setShowPayoffRequestButton,
  showPayoffRequestButton,
  duplicateDeals,
  submitEstimate,
  upsertEstimateLoading,
}: DuplicateDealsModalProps) => {
  return (
    <Modal
      title="Duplicate Deal"
      isOpen={!!isDuplicateModalOpen}
      onClose={() => {
        if (closeDuplicateModal) {
          closeDuplicateModal();
        }
        setShowPayoffRequestButton(false);
      }}
      leftButtons={
        <Button
          onClick={() => {
            if (closeDuplicateModal) {
              closeDuplicateModal();
            }
            setShowPayoffRequestButton(false);
          }}
          variant="warning"
        >
          CANCEL
        </Button>
      }
      rightButtons={
        showPayoffRequestButton ? (
          <RequestPayoffButton />
        ) : (
          <Button
            isLoading={upsertEstimateLoading}
            onClick={submitEstimate as unknown as MouseEventHandler<HTMLElement>}
          >
            CREATE DEAL
          </Button>
        )
      }
      size="4xl"
    >
      <Text mb={8}>
        This information matches others in our system with the same Name or VIN combination. Are you
        sure that you want to create a duplicate deal?
      </Text>
      {duplicateDeals.map((d) => (
        <div key={d.id}>
          <Box display="flex" flexDirection="row" my={4}>
            <Link
              href={`/deals/${d.id}`}
              target="_blank"
              rel="noreferrer"
              width="10%"
              fontWeight="bold"
              color="mediumElectricBlue"
            >
              {d.id}
            </Link>
            <Text width="25%" fontWeight="bold">
              {getFullName(d.customer)}
            </Text>
            <Text width="30%" fontWeight="normal">
              {d.car.year} {d.car.make} {d.car.model}
            </Text>
            <Text width="25%" fontWeight="normal">
              {DealStateLabelMap[d.state]}
            </Text>
            <Box width="10%">
              <AvatarGroup my="auto" spacing={-5}>
                <UserAvatar key={`${d.id}Advisor`} assignedRole="Advisor" user={d.setter} />
                <UserAvatar
                  key={`${d.id}FinancialSpecialist`}
                  assignedRole="Financial Specialist"
                  user={d.closer}
                />
                <UserAvatar
                  key={`${d.id}FinancialSpecialist2`}
                  assignedRole="Financial Specialist 2"
                  user={d.closer2}
                />
                <UserAvatar
                  key={`${d.id}StructuringManager`}
                  assignedRole="Structuring Manager"
                  user={d.structuring_manager}
                />
                <UserAvatar
                  key={`${d.id}AccountingClerk`}
                  assignedRole="Accounting Clerk"
                  user={d.funding_clerk}
                />
                <UserAvatar
                  key={`${d.id}TitleClerk`}
                  assignedRole="Title Clerk"
                  user={d.title_clerk}
                />
                <UserAvatar
                  key={`${d.id}TitleClerk2`}
                  assignedRole="Reg Assistant"
                  user={d.title_clerk2}
                />
              </AvatarGroup>
            </Box>
          </Box>
          <Divider borderColor="gray.500" />
        </div>
      ))}
    </Modal>
  );
};
