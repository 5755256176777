import { ReactNode } from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { BiMenu } from 'react-icons/bi';

import { MetricsPages } from '../../../gql/generated/graphql';
import SidebarContent from './SidebarContent';

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const background = useColorModeValue('white', 'gray.900');
  const borderBottomColor = useColorModeValue('gray.200', 'gray.700');
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={background}
      borderBottomWidth="1px"
      borderBottomColor={borderBottomColor}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<BiMenu />} />
      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Metrics
      </Text>
    </Flex>
  );
};

const MetricsSidebar = ({
  metricsPages,
  children,
  onPressItem,
  itemSelected,
}: {
  metricsPages: MetricsPages[];
  children: ReactNode;
  itemSelected?: MetricsPages;
  onPressItem: (item: MetricsPages) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const backgroundColor = useColorModeValue('gray.100', 'gray.900');
  return (
    <Box minH="94vh" bg={backgroundColor}>
      <SidebarContent
        metricsPages={metricsPages}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        onPressItem={onPressItem}
        itemSelected={itemSelected}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            metricsPages={metricsPages}
            onClose={onClose}
            onPressItem={onPressItem}
            itemSelected={itemSelected}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 160 }} p="4">
        {children}
      </Box>
    </Box>
  );
};

export default MetricsSidebar;
