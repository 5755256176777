import { FC, ReactNode } from 'react';

import { Box, Text } from '@chakra-ui/react';

interface Props {
  title: string;
  children?: ReactNode;
}

const CardSubHeaderV2: FC<Props> = ({ title, children }) => {
  return (
    <Box bgColor="queenBlue" color="white" fontSize="sm" h={9} pl={6} py={2}>
      <Text>{title}</Text>
      {children}
    </Box>
  );
};

export default CardSubHeaderV2;
