import { useContext } from 'react';

import { Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Notification, getDealNotificationCountByType } from '../../gql/notificationsGql';

import { StructuringFollowUpDeals } from './StructuringFollowUpTable';
import { TABLE_COLUMNS_ENTRIES } from './columnConfig';

import { useUser } from '../../hooks/useUser';
import { AbilityContext } from '../../libs/contextLib';
import { handleClickOrCommandClick } from '../../libs/eventHandlers';

interface StructuringFollowUpTableRowProps {
  deal: NonNullable<StructuringFollowUpDeals[number]>;
}

const StructuringFollowUpTableRow = ({ deal }: StructuringFollowUpTableRowProps) => {
  const user = useUser();
  const abilities = useContext(AbilityContext);
  const history = useHistory();

  const notifications =
    (deal.notifications?.filter((notif) => notif != null) as Notification[]) ?? [];
  const { unseenMessages } = getDealNotificationCountByType({
    notifications,
  });

  return (
    <Tr
      key={deal.id}
      verticalAlign="top"
      height="55px"
      borderBottom="2px"
      borderX="1px"
      borderColor="backgroundGray"
      cursor="pointer"
      _hover={{ bgColor: 'hoverGray' }}
      onClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
      onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
    >
      {TABLE_COLUMNS_ENTRIES.map(([, { getComponent }]) =>
        getComponent({
          deal,
          userId: user.id,
          permissions: abilities,
          unseenMessages,
        }),
      )}
    </Tr>
  );
};

export default StructuringFollowUpTableRow;
