import { gql } from '@apollo/client';

import {
  DealMediaSourceEnum,
  DealMediaStateEnum,
  DealMediaTypeEnum,
  Maybe,
} from './generated/graphql';

import { TTDocumentName, TTValidationName, TTValidationType } from './taterTitleGql';

const DocumentTypeMap: Record<TTDocumentName, DealMediaTypeEnum> = {
  [TTDocumentName.BackOfDriversLicense]: DealMediaTypeEnum.BackOfDriversLicense,
  [TTDocumentName.CobuyerBackOfDriversLicense]: DealMediaTypeEnum.BackOfCoBuyersLicense,
  [TTDocumentName.CobuyerDriversLicense]: DealMediaTypeEnum.FrontOfCoBuyersLicense,
  [TTDocumentName.DriversLicense]: DealMediaTypeEnum.FrontOfDriversLicense,
  [TTDocumentName.Emissions]: DealMediaTypeEnum.Emissions,
  [TTDocumentName.Odometer]: DealMediaTypeEnum.OdoDoc,
  [TTDocumentName.HondaSpecificOdometerStatement]: DealMediaTypeEnum.HondaSpecificOdometerDoc,
  [TTDocumentName.AcuraSpecificOdometerStatement]: DealMediaTypeEnum.AcuraSpecificOdometerDoc,
  [TTDocumentName.ProofOfInsurance]: DealMediaTypeEnum.FrontOfInsuranceCard,
  [TTDocumentName.ProofOfResidence]: DealMediaTypeEnum.ProofOfResidence,
  [TTDocumentName.ProofOfResidence2]: DealMediaTypeEnum.ProofOfResidence2,
  [TTDocumentName.PropertyTaxReceipt]: DealMediaTypeEnum.PropertyTaxReceipt,
  [TTDocumentName.Registration]: DealMediaTypeEnum.Registration,
  [TTDocumentName.VINInspection]: DealMediaTypeEnum.VinInspection,
  [TTDocumentName.SafetyInspection]: DealMediaTypeEnum.SafetyInspection,
  [TTDocumentName.PowerOfAttorney]: DealMediaTypeEnum.PowerOfAttorney,
  [TTDocumentName.StateSpecificPowerOfAttorney]: DealMediaTypeEnum.StateSpecificPowerOfAttorney,
  [TTDocumentName.SecurePowerOfAttorney]: DealMediaTypeEnum.SecurePowerOfAttorney,
  [TTDocumentName.BillOfSale]: DealMediaTypeEnum.PurchaseOrder,
  [TTDocumentName.StateSpecificBillOfSale]: DealMediaTypeEnum.StateSpecificPurchaseOrder,
  [TTDocumentName.OdometerStatement]: DealMediaTypeEnum.LeaseendOdoDoc,
  [TTDocumentName.CobuyerOdometerStatement]: DealMediaTypeEnum.LeaseendOdoDocCobuyer,
  [TTDocumentName.JointOdometerStatement]: DealMediaTypeEnum.LeaseendOdoDocJoint,
  [TTDocumentName.StateSpecificOdometerStatement]: DealMediaTypeEnum.StateSpecificOdoDoc,
  [TTDocumentName.CobuyerStateSpecificOdometerStatement]:
    DealMediaTypeEnum.CobuyerStateSpecificOdoDoc,
  [TTDocumentName.TitleApp]: DealMediaTypeEnum.TitleApplication,
  [TTDocumentName.StateSpecificDamageDisclosure]: DealMediaTypeEnum.StateSpecificDamageDisclosure,
  [TTDocumentName.RegistrationSpecificPowerOfAttorney]:
    DealMediaTypeEnum.RegistrationSpecificPowerOfAttorney,
  [TTDocumentName.CobuyerPowerOfAttorney]: DealMediaTypeEnum.PowerOfAttorneyCobuyer,
  [TTDocumentName.CobuyerStateSpecificPowerOfAttorney]:
    DealMediaTypeEnum.StateSpecificPowerOfAttorneyCobuyer,
  [TTDocumentName.CobuyerRegistrationSpecificPowerOfAttorney]:
    DealMediaTypeEnum.CobuyerRegistrationSpecificPowerOfAttorney,
  [TTDocumentName.CobuyerSecurePowerOfAttorney]: DealMediaTypeEnum.CobuyerSecurePowerOfAttorney,
  [TTDocumentName.SalesTaxExemption]: DealMediaTypeEnum.SalesTaxExemption,
  [TTDocumentName.OhioSpecificHondaSpecificPowerOfAttorney]:
    DealMediaTypeEnum.StateAndLienholderSpecificPowerOfAttorney,
  [TTDocumentName.PurchaseOrder]: DealMediaTypeEnum.PurchaseOrder,
  [TTDocumentName.ColoradoSupplementalIdentificationClause]:
    DealMediaTypeEnum.ColoradoSupplementalIdentificationClause,
  [TTDocumentName.ColoradoCobuyerSupplementalIdentificationClause]:
    DealMediaTypeEnum.ColoradoCobuyerSupplementalIdentificationClause,
  [TTDocumentName.ColoradoJointTenancy]: DealMediaTypeEnum.ColoradoJointTenancy,
  [TTDocumentName.GmLeasePacket]: DealMediaTypeEnum.GmLeasePacket,
  [TTDocumentName.PlateTransferLetter]: DealMediaTypeEnum.PlateTransferLetter,
  [TTDocumentName.ElectronicSignatureAttestation]: DealMediaTypeEnum.ElectronicSignatureAttestation,
  [TTDocumentName.ElectronicSignatureCertification]:
    DealMediaTypeEnum.ElectronicSignatureCertification,
  [TTDocumentName.LicensePlate]: DealMediaTypeEnum.LicensePlate,
  [TTDocumentName.IdahoSalesTaxExemption]: DealMediaTypeEnum.IdahoSalesTaxExemption,
};

// keep in sync with the backend
export const DealMediaTypeLabels: Record<DealMediaTypeEnum, string> = {
  [DealMediaTypeEnum.RateExceptionForm]: 'Rate Exception Form',
  [DealMediaTypeEnum.PicturesOfVehicle]: 'Pictures of Vehicle',
  [DealMediaTypeEnum.OdometerPicture]: 'Odometer Picture',
  [DealMediaTypeEnum.FrontOfDriversLicense]: "Driver's License - Front",
  [DealMediaTypeEnum.BackOfDriversLicense]: "Driver's License - Back",
  [DealMediaTypeEnum.FrontOfInsuranceCard]: 'Insurance Card - Front',
  [DealMediaTypeEnum.BackOfInsuranceCard]: 'Insurance Card - Back',
  [DealMediaTypeEnum.Registration]: 'Registration',
  [DealMediaTypeEnum.LeaseAgreement]: 'Lease Agreement',
  [DealMediaTypeEnum.OdoDoc]: 'Odo Doc',
  [DealMediaTypeEnum.LeaseendOdoDoc]: 'Lease End Odo',
  [DealMediaTypeEnum.LeaseendOdoDocCobuyer]: 'Lease End Odo (Co-buyer)',
  [DealMediaTypeEnum.LeaseendOdoDocJoint]: 'Lease End Odo (Joint)',
  [DealMediaTypeEnum.GmLeasePacket]: 'GM Lease Packet',
  [DealMediaTypeEnum.HondaSpecificOdometerDoc]: 'Honda Specific Odo Doc',
  [DealMediaTypeEnum.AcuraSpecificOdometerDoc]: 'Acura Specific Odo Doc',
  [DealMediaTypeEnum.StateSpecificOdoDoc]: 'State Specific Odo Doc',
  [DealMediaTypeEnum.CobuyerStateSpecificOdoDoc]: 'State Specific Odo Doc (Co-buyer)',
  [DealMediaTypeEnum.VinInspection]: 'VIN Inspection',
  [DealMediaTypeEnum.SafetyInspection]: 'Safety Inspection',
  [DealMediaTypeEnum.BuyersGuide]: "Buyer's Guide",
  [DealMediaTypeEnum.TaterDocs]: 'Tater Docs',
  [DealMediaTypeEnum.TaxExemption]: 'Tax Exemption',
  [DealMediaTypeEnum.PowerOfAttorney]: 'Power of Attorney',
  [DealMediaTypeEnum.StateSpecificPowerOfAttorney]: 'State Specific Power of Attorney',
  [DealMediaTypeEnum.PowerOfAttorneyCobuyer]: 'Power of Attorney (Co-buyer)',
  [DealMediaTypeEnum.StateSpecificPowerOfAttorneyCobuyer]:
    'State Specific Power of Attorney (Co-buyer)',
  [DealMediaTypeEnum.SecurePowerOfAttorney]: 'Secure Power of Attorney',
  [DealMediaTypeEnum.StateSpecificDocs]: 'State Specific Docs',
  [DealMediaTypeEnum.PlateTransferLetter]: 'Plate Transfer Letter',
  [DealMediaTypeEnum.Contract]: 'Contract',
  [DealMediaTypeEnum.Title]: 'Title',
  [DealMediaTypeEnum.TitleApplication]: 'Title Application',
  [DealMediaTypeEnum.TitlePacket]: 'Title Packet',
  [DealMediaTypeEnum.TitleRelease]: 'Title Release',
  [DealMediaTypeEnum.CreditApproval]: 'Credit Approval',
  [DealMediaTypeEnum.ReassignmentDisclosure]: 'Reassignment Disclosure',
  [DealMediaTypeEnum.PayoffAuthorization]: 'Payoff Authorization',
  [DealMediaTypeEnum.PurchaseOrder]: 'Purchase Order',
  [DealMediaTypeEnum.StateSpecificPurchaseOrder]: 'State Specific Purchase Order',
  [DealMediaTypeEnum.LeaseEndCashback]: 'Lease End Cashback',
  [DealMediaTypeEnum.DamageDisclosure]: 'Damage Disclosure',
  [DealMediaTypeEnum.StateSpecificDamageDisclosure]: 'State Specific Damage Disclosure',
  [DealMediaTypeEnum.TexasHidalgoDoc]: 'Texas Hidalgo Doc',
  [DealMediaTypeEnum.AgreementToFurnishInsurance]: 'Agreement to Furnish Insurance',
  [DealMediaTypeEnum.LeaseEndAcqContract]: 'Lease End Acq Contract',
  [DealMediaTypeEnum.BookSheet]: 'Book Sheet',
  [DealMediaTypeEnum.RegistrationComplete]: 'Registration/Title - Complete',
  [DealMediaTypeEnum.R1EContract]: 'R1 E-Contract',
  [DealMediaTypeEnum.MembershipForm]: 'Membership Form',
  [DealMediaTypeEnum.ESignConsent]: 'E-Sign Consent',
  [DealMediaTypeEnum.PrivacyPolicy]: 'Privacy Policy',
  [DealMediaTypeEnum.Other]: 'Other',

  [DealMediaTypeEnum.FrontOfCoBuyersLicense]: 'Driver’s License (Co-Buyer) - Front',
  [DealMediaTypeEnum.BackOfCoBuyersLicense]: 'Driver’s License (Co-Buyer) - Back',
  [DealMediaTypeEnum.FrontOfCoBuyersInsurance]: 'Insurance (Co-Buyer) - Front',
  [DealMediaTypeEnum.BackOfCoBuyersInsurance]: 'Insurance (Co-buyer) - Back',
  [DealMediaTypeEnum.Emissions]: 'Emissions',
  [DealMediaTypeEnum.PropertyTaxReceipt]: 'Property Tax Receipt',
  [DealMediaTypeEnum.ProofOfResidence]: 'Proof of Residence',
  [DealMediaTypeEnum.ProofOfResidence2]: 'Proof of Residence 2',

  [DealMediaTypeEnum.RegistrationSpecificPowerOfAttorney]:
    'Registration Specific Power of Attorney',
  [DealMediaTypeEnum.CobuyerRegistrationSpecificPowerOfAttorney]:
    'Registration Specific Power of Attorney (Co-buyer)',
  [DealMediaTypeEnum.CobuyerSecurePowerOfAttorney]: 'Secure Power of Attorney (Co-buyer)',

  [DealMediaTypeEnum.PhysicalSignatureCoverLetter]: 'Physical Signature Cover Letter',
  [DealMediaTypeEnum.CreditApplication]: 'Credit Application',

  [DealMediaTypeEnum.PayoffDocs]: 'Payoff Docs',
  [DealMediaTypeEnum.ProofOfIncome]: 'Proof of Income',
  [DealMediaTypeEnum.DownPayment]: 'Down Payment',
  [DealMediaTypeEnum.SignedDocs]: 'Signed Docs',
  [DealMediaTypeEnum.SalesTaxExemption]: 'Sales Tax Exemption',

  [DealMediaTypeEnum.VscAndGap]: 'VSC & GAP',
  [DealMediaTypeEnum.DemandLetter]: 'Demand Letter',

  [DealMediaTypeEnum.StateAndLienholderSpecificPowerOfAttorney]:
    'State and Lienholder Specific Power of Attorney',

  [DealMediaTypeEnum.ColoradoSupplementalIdentificationClause]:
    'Colorado Supplemental Identification',
  [DealMediaTypeEnum.ColoradoCobuyerSupplementalIdentificationClause]:
    'Colorado Supplemental Identification (Co-buyer)',
  [DealMediaTypeEnum.ColoradoJointTenancy]: 'Colorado Joint Tenancy Form',

  [DealMediaTypeEnum.CaliforniaStatementOfFacts]: 'California Statement of Facts',
  [DealMediaTypeEnum.CaliforniaStatementOfFactsCobuyer]: 'California Statement of Facts (Co-buyer)',

  [DealMediaTypeEnum.ElectronicSignatureVerification]: 'Electronic Signature Verification',
  [DealMediaTypeEnum.ElectronicSignatureAttestation]: 'Electronic Signature Attestation',
  [DealMediaTypeEnum.ElectronicSignatureCertification]: 'Electronic Signature Certification',

  [DealMediaTypeEnum.LicensePlate]: 'License Plate',
  [DealMediaTypeEnum.TempTag]: 'Temp Tag',

  [DealMediaTypeEnum.IdahoSalesTaxExemption]: 'Idaho Sales Tax Exemption',
};

const dealMediaGroupOnlyTypes = [DealMediaTypeEnum.ElectronicSignatureVerification];

export const getDealMediaTypeLabel = (value: Maybe<DealMediaTypeEnum | ''>) =>
  value ? DealMediaTypeLabels[value] : '';

export const DealMediaTypeOptions = Object.values(DealMediaTypeEnum)
  .filter((value) => !dealMediaGroupOnlyTypes.includes(value))
  .map((value) => ({
    value,
    label: getDealMediaTypeLabel(value),
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export const DealMediaCustomerTypes = [
  DealMediaTypeEnum.PicturesOfVehicle,
  DealMediaTypeEnum.OdometerPicture,
  DealMediaTypeEnum.FrontOfDriversLicense,
  DealMediaTypeEnum.BackOfDriversLicense,
  DealMediaTypeEnum.FrontOfInsuranceCard,
  DealMediaTypeEnum.BackOfInsuranceCard,
  DealMediaTypeEnum.Registration,
  DealMediaTypeEnum.VinInspection,
  DealMediaTypeEnum.SafetyInspection,

  DealMediaTypeEnum.FrontOfCoBuyersLicense,
  DealMediaTypeEnum.BackOfCoBuyersLicense,
  DealMediaTypeEnum.FrontOfCoBuyersInsurance,
  DealMediaTypeEnum.BackOfCoBuyersInsurance,
  DealMediaTypeEnum.Emissions,
  DealMediaTypeEnum.PropertyTaxReceipt,
  DealMediaTypeEnum.ProofOfResidence,
  DealMediaTypeEnum.ProofOfResidence2,

  DealMediaTypeEnum.ProofOfIncome,
  DealMediaTypeEnum.PlateTransferLetter,

  DealMediaTypeEnum.LicensePlate,
];

export const DealMediaCustomerNoPhotoTypes = [
  DealMediaTypeEnum.Registration,
  DealMediaTypeEnum.VinInspection,
  DealMediaTypeEnum.SafetyInspection,
  DealMediaTypeEnum.Emissions,
  DealMediaTypeEnum.PlateTransferLetter,
];

export const DealMediaNoSignatureUpload = [
  ...DealMediaCustomerTypes,
  DealMediaTypeEnum.PayoffDocs,
  DealMediaTypeEnum.BookSheet,
  DealMediaTypeEnum.CreditApproval,
  DealMediaTypeEnum.RateExceptionForm,
  DealMediaTypeEnum.TitleRelease,
  DealMediaTypeEnum.ElectronicSignatureAttestation,
  DealMediaTypeEnum.ElectronicSignatureCertification,
  DealMediaTypeEnum.SignedDocs,
];

export const transformMetadata = (
  metadata?: { [key: string]: string } | DriverLicenseMetadata | null,
) => {
  if (!metadata) {
    return null;
  }

  const values = Object.values(metadata);
  if (values.length === 0 || values.every((value) => !value)) {
    return null;
  }

  return metadata;
};

export enum MismatchSelectionEnum {
  Application = 'application',
  Document = 'document',
}

export class DriverLicenseMetadata {
  name?: string;
  nameVerified?: boolean;
  nameMismatchSelection?: MismatchSelectionEnum;
  address?: string;
  addressVerified?: boolean;
  addressMismatchSelection?: MismatchSelectionEnum;
  address2?: string;
  address2Verified?: boolean;
  city?: string;
  cityVerified?: boolean;
  state?: string;
  stateVerified?: boolean;
  zip?: string;
  zipVerified?: boolean;
  expirationDate?: string;
  expirationDateVerified?: boolean;
}

export type DriverLicenseMetadataValueFields = keyof Pick<
  DriverLicenseMetadata,
  'name' | 'address' | 'address2' | 'city' | 'state' | 'zip' | 'expirationDate'
>;

export type DriverLicenseMetadataVerifiedFields = keyof Pick<
  DriverLicenseMetadata,
  | 'nameVerified'
  | 'addressVerified'
  | 'address2Verified'
  | 'cityVerified'
  | 'stateVerified'
  | 'zipVerified'
  | 'expirationDateVerified'
>;

export type DriverLicenseMetadataMismatchFields = keyof Pick<
  DriverLicenseMetadata,
  'nameMismatchSelection' | 'addressMismatchSelection'
>;

export const DriverLicenseVerifiedFieldMap = new Map<
  DriverLicenseMetadataValueFields,
  DriverLicenseMetadataVerifiedFields
>([
  ['name', 'nameVerified'],
  ['address', 'addressVerified'],
  ['address2', 'address2Verified'],
  ['city', 'cityVerified'],
  ['state', 'stateVerified'],
  ['zip', 'zipVerified'],
  ['expirationDate', 'expirationDateVerified'],
]);

export const DriverLicenseMismatchFieldMap = new Map<
  DriverLicenseMetadataValueFields,
  DriverLicenseMetadataMismatchFields
>([
  ['name', 'nameMismatchSelection'],
  ['address', 'addressMismatchSelection'],
]);

export class InsuranceCardMetadata {
  name?: string;
  nameVerified?: boolean;
  state?: string;
  stateVerified?: boolean;
  vin?: string;
  vinVerified?: boolean;
  expirationDate?: string;
  expirationDateVerified?: boolean;
  cobuyerOnInsurance?: string;
  insuranceCompany?: string;
  insuranceCompanyVerified?: boolean;
  policyNo?: string;
  policyNoVerified?: boolean;
}

export type InsuranceCardMetadataValueFields = keyof Pick<
  InsuranceCardMetadata,
  | 'name'
  | 'state'
  | 'vin'
  | 'expirationDate'
  | 'cobuyerOnInsurance'
  | 'insuranceCompany'
  | 'policyNo'
>;

export class DealMedia {
  id?: number;
  key?: string;
  signed_url?: string;
  type?: DealMediaTypeEnum | '';
  metadata?: { [key: string]: string | boolean };
  deal_id?: number;
  verified?: boolean;
  verified_digital_signature_required?: boolean;
  notary_required?: boolean;
  wet_signature_required?: boolean;
  has_verified_digital_signature?: boolean;
  has_wet_signature?: boolean;
  is_notarized?: boolean;
  created_at?: Date;
  updated_at?: Date;
  state?: DealMediaStateEnum;
  source?: DealMediaSourceEnum;
}

export type DealMediaEdit = {
  selectedFile?: File;
  sourceFileName?: string;
  fileName?: string;
  extension?: string;
} & DealMedia;

export const mediaQuery = gql`
  query media($dealId: ID!) {
    media(dealId: $dealId) {
      id
      key
      signed_url
      type
      metadata
      deal_id
      verified
      state
      source
      has_verified_digital_signature
      has_wet_signature
      is_notarized
      created_at
    }
  }
`;

export const mediaInsertMutation = gql`
  mutation mediaInsert($key: String!, $type: String, $dealId: ID!, $metadata: JSONObject) {
    mediaInsert(key: $key, type: $type, dealId: $dealId, metadata: $metadata) {
      id
      key
      signed_url
      type
      metadata
      deal_id
      has_verified_digital_signature
      has_wet_signature
      is_notarized
    }
  }
`;

export interface GetUploadUrl {
  key: string;
  url: string;
}

export interface GetUploadUrlResult {
  getUploadUrl: GetUploadUrl;
}

export const getUploadUrl = gql`
  query getUploadUrl($dealId: ID!, $fileName: String!) {
    getUploadUrl(dealId: $dealId, fileName: $fileName) {
      key
      url
    }
  }
`;

export const mediaDelete = gql`
  mutation mediaDelete($filePath: String!) {
    mediaDelete(filePath: $filePath)
  }
`;

export const deleteAndReplaceFiles = gql`
  mutation deleteAndReplaceFiles(
    $deal_id: ID!
    $files_to_delete: [addDocumentsToMediaCenterInput]!
    $files_to_upload: [addDocumentsToMediaCenterInput]!
  ) {
    deleteAndReplaceFiles(
      deal_id: $deal_id
      files_to_delete: $files_to_delete
      files_to_upload: $files_to_upload
    )
  }
`;

export const mediaRename = gql`
  mutation mediaRename($dealId: ID!, $filePath: String!, $fileName: String!) {
    mediaRename(dealId: $dealId, filePath: $filePath, fileName: $fileName)
  }
`;

export const mediaUpdate = gql`
  mutation mediaUpdate(
    $dealId: ID!
    $oldKey: String!
    $newFileName: String
    $type: String
    $metadata: JSONObject
    $verified: Boolean
    $has_verified_digital_signature: Boolean
    $has_wet_signature: Boolean
    $is_notarized: Boolean
  ) {
    mediaUpdate(
      dealId: $dealId
      oldKey: $oldKey
      newFileName: $newFileName
      type: $type
      metadata: $metadata
      verified: $verified
      has_verified_digital_signature: $has_verified_digital_signature
      has_wet_signature: $has_wet_signature
      is_notarized: $is_notarized
    ) {
      id
      key
      signed_url
      type
      metadata
      deal_id
      verified
      has_verified_digital_signature
      has_wet_signature
      is_notarized
    }
  }
`;

export const getArticleImageUploadUrl = gql`
  query articleImageUploadUrl {
    articleImageUploadUrl {
      url
      key
    }
  }
`;

export const getBase64StringFromS3Object = gql`
  query getBase64StringFromS3Object($key: String!) {
    getBase64StringFromS3Object(key: $key)
  }
`;

export const mapTTDocumentType = (type: Maybe<TTDocumentName>): DealMediaTypeEnum | string => {
  if (!type) {
    return '';
  }

  return DocumentTypeMap[type] || '';
};

export const isVerifiedDigitalSignature = (validations?: TTValidationType[]): boolean =>
  validations?.some(({ name }) => name === TTValidationName.VerifiedDigitalSignature) ?? false;

export const isWetSignatureRequired = (validations?: TTValidationType[]): boolean =>
  validations?.some(({ name }) => name === TTValidationName.WetSignature) ?? false;

export const isNotaryRequired = (validations?: TTValidationType[]): boolean =>
  validations?.some(({ name }) => name === TTValidationName.NotarizationRequired) ?? false;

export enum DealMediaStatusEnum {
  All = 'all',
  Missing = 'missing',
  Verified = 'verified',
  Unverified = 'unverified',
  Uncategorized = 'uncategorized',
}

export const mapTTValidationNamesToRequiredMetadataFields = (
  ttValidationNames: TTValidationName[],
) => {
  const alwaysRequiredDriverLicenseMetadataFields = new Map<
    DriverLicenseMetadataValueFields,
    boolean
  >();
  alwaysRequiredDriverLicenseMetadataFields.set('expirationDate', true);

  const alwaysRequiredInsuranceCardMetadataFields = new Map<
    InsuranceCardMetadataValueFields,
    boolean
  >();
  alwaysRequiredInsuranceCardMetadataFields.set('expirationDate', true);
  alwaysRequiredInsuranceCardMetadataFields.set('insuranceCompany', true);
  alwaysRequiredInsuranceCardMetadataFields.set('policyNo', true);

  return ttValidationNames.reduce(
    (acc, ttValidationName) => {
      switch (ttValidationName) {
        case TTValidationName.DriversLicenseAddressMatch:
          acc.requiredDriverLicenseMetadataFields.set('address', true);
          acc.requiredDriverLicenseMetadataFields.set('address2', true);
          acc.requiredDriverLicenseMetadataFields.set('city', true);
          acc.requiredDriverLicenseMetadataFields.set('state', true);
          acc.requiredDriverLicenseMetadataFields.set('zip', true);
          return acc;
        case TTValidationName.DriversLicenseNameMatch:
          acc.requiredDriverLicenseMetadataFields.set('name', true);
          return acc;
        case TTValidationName.DriversLicenseStateMatch:
          acc.requiredDriverLicenseMetadataFields.set('state', true);
          return acc;
        case TTValidationName.InsuranceNameMatch:
          acc.requiredInsuranceCardMetadataFields.set('name', true);
          return acc;
        case TTValidationName.InsuranceStateMatch:
          acc.requiredInsuranceCardMetadataFields.set('state', true);
          return acc;
        case TTValidationName.InsuranceVinMatch:
          acc.requiredInsuranceCardMetadataFields.set('vin', true);
          return acc;
        case TTValidationName.CobuyerOnInsurance:
          acc.requiredInsuranceCardMetadataFields.set('cobuyerOnInsurance', true);
          return acc;
        default:
          return acc;
      }
    },
    {
      requiredDriverLicenseMetadataFields: alwaysRequiredDriverLicenseMetadataFields,
      requiredInsuranceCardMetadataFields: alwaysRequiredInsuranceCardMetadataFields,
    },
  );
};
