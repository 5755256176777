import { BootReasonsEnum, DealTypeLabelMap } from '../gql/dealGql';
import { DealType } from '../gql/generated/graphql';
import { FailPayoffRequestReasonEnum } from '../gql/payoffRequestGql';

import { Option } from '../components/shared/types';
import { statesArray } from '../libs/states';

const enumToSelectOptions = <T>(enumObj: { [s: string]: T }) =>
  Object.values(enumObj).map((e, key) => ({ value: e, label: e, key }));

const BOOT_REASONS = enumToSelectOptions(BootReasonsEnum);
const FAIL_PAYOFF_REQUEST_REASONS = enumToSelectOptions(FailPayoffRequestReasonEnum);

const US_STATES = statesArray.map((stateName) => ({
  label: stateName,
  value: stateName,
}));

const DEAL_TYPES: Option[] = Object.values(DealType)
  .map((value) => ({
    label: DealTypeLabelMap[value],
    value,
  }))
  .filter(({ value }) => value !== DealType.Acquisition);

const SELECT_OPTIONS = {
  BOOT_REASONS,
  US_STATES,
  FAIL_PAYOFF_REQUEST_REASONS,
  DEAL_TYPES,
};

export default SELECT_OPTIONS;
