import { gql } from '@apollo/client';

import { formatPhoneNumberForDisplay } from '../libs/utils';

import { Customer, getFullName } from './customerGql';
import { Deal } from './dealGql';
import { User } from './userGql';

export type GotNewEstimateOption = 'yes' | 'no' | 'n/a';

export enum NewCustomerCallOutcomeEnum {
  NewEstimate = 'new_estimate',

  BankDMVProcessor = 'bank_dmv_processor',
  NewLeaseOrAcquisition = 'wants_new_lease_or_acquisition',
  CashBuyer = 'cash_buyer',
  DealershipOrLienholder = 'mistaken_for_dealership_or_lienholder',
  NoLease = 'no_lease',
  ComplaintRemoveMarketing = 'complaint_remove_marketing',
  MisdirectedCall = 'misdirected_call',
  Other = 'other',
}

export interface Call {
  id: string;
  deal_id?: number;
  callStatus: string;
  direction: string;
  twilioNumber: string;
  fromNumber: string;
  calledNumber: string;
  user_id?: string;
  recordingSid: string;
  recordingUrl: string;
  callDuration: string;
  calledCity: string;
  calledState: string;
  created_at: Date;
  user?: User;
  deal?: Deal;
  outcome?: string;
}

export enum CallDirectionEnum {
  Inbound = 'inbound',
  OutboundApi = 'outbound-api',
  OutboundDial = 'outbound-dial',
}

export const getCallerNameString = (call: Call, deal?: Deal) => {
  let fullName = '';
  const phoneNumber =
    call.direction === CallDirectionEnum.Inbound && call.fromNumber && call.calledNumber
      ? formatPhoneNumberForDisplay(call.fromNumber)
      : formatPhoneNumberForDisplay(call.calledNumber);

  if (deal?.id === call.deal?.id) {
    if (
      deal?.customer?.phone_number &&
      phoneNumber === formatPhoneNumberForDisplay(deal.customer.phone_number)
    ) {
      fullName = `${getFullName(deal.customer)}`;
    } else if (
      deal?.cobuyer?.phone_number &&
      phoneNumber === formatPhoneNumberForDisplay(deal.cobuyer.phone_number)
    ) {
      fullName = `${getFullName(deal.cobuyer as Customer)} - Cobuyer`;
    } else if (
      deal?.contact?.phone_number &&
      phoneNumber === formatPhoneNumberForDisplay(deal.contact.phone_number)
    ) {
      fullName = `${getFullName(deal.contact as Customer)} - External Contact`;
    } else if (
      deal?.second_contact?.phone_number &&
      phoneNumber === formatPhoneNumberForDisplay(deal.second_contact.phone_number)
    ) {
      fullName = `${getFullName(deal.second_contact as Customer)} - External Contact`;
    }
  }

  return fullName ? `${fullName} | ${phoneNumber}` : `${phoneNumber}`;
};

// Queries
export const getUserCallHistory = gql`
  query getUserCallHistory($items_per_page: Int!, $page: Int!) {
    getUserCallHistory(items_per_page: $items_per_page, page: $page) {
      calls {
        id
        deal {
          id
          state
          customer {
            id
            first_name
            last_name
            phone_number
          }
          cobuyer {
            id
            first_name
            last_name
            phone_number
          }
          contact {
            id
            first_name
            last_name
            phone_number
          }
          second_contact {
            id
            first_name
            last_name
            phone_number
          }
        }
        callStatus
        direction
        twilioNumber
        fromNumber
        calledNumber
        user_id
        recordingSid
        recordingUrl
        outcome
        calledCity
        calledState
        created_at
      }
      totalRecords
    }
  }
`;

// Mutations
export const callOut = gql`
  mutation callOut($calledNumber: String!, $id: ID) {
    callOut(calledNumber: $calledNumber, id: $id) {
      calledNumber
      id
    }
  }
`;

export const hangUpCall = gql`
  mutation hangUpCall($conference_id: String!) {
    hangUpCall(conference_id: $conference_id)
  }
`;

export const saveNewCustomerCallOutcome = gql`
  mutation saveNewCustomerCallOutcome(
    $call_id: String!
    $outcome: String
    $outcome_description: String
  ) {
    saveNewCustomerCallOutcome(
      call_id: $call_id
      outcome: $outcome
      outcome_description: $outcome_description
    ) {
      id
    }
  }
`;
