import { ChangeEventHandler, ComponentProps, FC } from 'react';

import { Box, BoxProps, StyleProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../gql/dealGql';

import CardSubHeaderV2 from '../../shared/Card/components/CardSubHeaderV2';
import DatePicker from '../../shared/DatePicker';
import { GridFormColumn, GridFormRow } from '../../shared/GridForm';
import Input from '../../shared/Input';

type Variant = 'row' | 'column';

const variants: Record<
  Variant,
  ComponentProps<typeof GridFormRow> & {
    formControlVariant?: 'bold';
    datePickerLabelStyles?: StyleProps;
  }
> = {
  row: { minChildWidth: 135 },
  column: {
    minChildWidth: 270,
    formControlVariant: 'bold',
    datePickerLabelStyles: { color: 'black', fontWeight: 'bold' },
    pb: 5,
  },
};

interface Props extends BoxProps {
  name: keyof Pick<Deal, 'customer' | 'cobuyer'>;
  variant?: Variant;
  showHeader?: boolean;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const InsuranceForm: FC<Props> = ({
  name,
  variant = 'row',
  showHeader = true,
  customHandleChange,
  ...rest
}) => {
  const { handleChange: formikHandleChange } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const { formControlVariant, datePickerLabelStyles, ...variantProps } = variants[variant];

  return (
    <Box {...rest}>
      {showHeader ? <CardSubHeaderV2 title="Insurance Information" /> : null}
      <GridFormColumn>
        <GridFormRow {...variantProps}>
          <Input
            label="Insurance Company"
            name={`${name}.proof_of_insurance.company_name`}
            onChange={handleChange}
            formControlVariant={formControlVariant}
          />
          <Input
            label="Policy No."
            name={`${name}.proof_of_insurance.policy_number`}
            onChange={handleChange}
            formControlVariant={formControlVariant}
          />
          <DatePicker
            topLabel="Expiration Date"
            name={`${name}.proof_of_insurance.expires`}
            boxStyles={{ width: '100%' }}
            labelStyles={datePickerLabelStyles}
            additionalHandleChange={handleChange}
          />
        </GridFormRow>
      </GridFormColumn>
    </Box>
  );
};
