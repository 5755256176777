import { gql } from '@apollo/client';

// Queries
export const generateDocumentsQuery = gql`
  query generateDocuments($data: documentsInput) {
    generateDocuments(data: $data) {
      files {
        url
        filename
        key
        mediaType
      }
      errors
    }
  }
`;

export const retrieveDocumentsQuery = gql`
  query retrieveDealDocsUploadedToR1($dealJacketID: String!, $includePdfBinary: Boolean) {
    retrieveDealDocsUploadedToR1(dealJacketID: $dealJacketID, includePdfBinary: $includePdfBinary) {
      documentName
      documentType
      signatureStatus
    }
  }
`;

export const validateBankContractQuery = gql`
  query validateBankContract($deal_id: ID!) {
    validateBankContract(deal_id: $deal_id)
  }
`;

export const generatePurchaseOrder = gql`
  query generatePurchaseOrder($deal_id: ID!) {
    generatePurchaseOrder(deal_id: $deal_id) {
      files {
        url
        filename
        key
      }
    }
  }
`;

export const sendPurchaseOrder = gql`
  query sendPurchaseOrder($deal_id: ID!, $key: String!) {
    sendPurchaseOrder(deal_id: $deal_id, key: $key)
  }
`;

// Mutations
export const uploadDocumentToRouteOne = gql`
  mutation uploadDocumentToRouteOne(
    $jacketId: String
    $doc: uploadDocumentsInput
    $withCobuyer: Boolean
  ) {
    uploadDocumentToRouteOne(jacketId: $jacketId, doc: $doc, withCobuyer: $withCobuyer)
  }
`;

export const setReadyForSignaturesStatus = gql`
  mutation setReadyForSignaturesStatus(
    $jacketId: String
    $documentProgressStatus: DocumentProgressStatus
  ) {
    setReadyForSignaturesStatus(
      jacketId: $jacketId
      documentProgressStatus: $documentProgressStatus
    )
  }
`;

export const mergeFiles = gql`
  mutation mergeFiles(
    $deal_id: ID!
    $docs: [dealMediaInput]
    $missing_required_media_list: [dealMediaTypeEnum]
  ) {
    mergeFiles(
      deal_id: $deal_id
      docs: $docs
      missing_required_media_list: $missing_required_media_list
    ) {
      url
      filename
      key
    }
  }
`;

export const addDocumentsToMediaCenterMutation = gql`
  mutation addDocumentsToMediaCenter($deal_id: ID!, $files: [addDocumentsToMediaCenterInput]!) {
    addDocumentsToMediaCenter(deal_id: $deal_id, files: $files)
  }
`;

export const sendDocsWithAdobeAndUpdateState = gql`
  mutation sendDocumentsWithAdobeAndUpdateState(
    $deal_id: ID!
    $keys: [String]!
    $state: dealStateEnum!
  ) {
    sendDocumentsWithAdobeAndUpdateState(deal_id: $deal_id, keys: $keys, state: $state) {
      id
      state
    }
  }
`;

// Subscriptions
export const onContractValidation = gql`
  subscription onContractValidation($fundingClerkOnly: Boolean, $deal_id: ID) {
    onContractValidation(fundingClerkOnly: $fundingClerkOnly, deal_id: $deal_id) {
      id
      document_progress_status
      r1_contract_validation_errors_object {
        errors
      }
      r1_contract_validation_warnings_object {
        warnings
      }
      r1_contract_manually_validated
      customer {
        first_name
        last_name
      }
    }
  }
`;
export const onDashboardDocumentUpload = gql`
  subscription onDashboardDocumentUpload {
    onDashboardDocumentUpload {
      deal {
        id
        customer {
          first_name
          last_name
        }
      }
      fileType
    }
  }
`;
