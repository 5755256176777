import { FC } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Button, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';

import {
  FollowUp,
  GetFollowUpDealsPaginatedQuery,
  useFollowUpDeleteMutation,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import { logger } from '../../libs/Logger';

interface DeleteFollowUpProps {
  followUp: FollowUp;
  refetch?: () => Promise<ApolloQueryResult<GetFollowUpDealsPaginatedQuery>>;
  showIconButton?: boolean;
}

const DeleteFollowUp: FC<DeleteFollowUpProps> = ({ followUp, refetch, showIconButton = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteFollowUp, { loading }] = useFollowUpDeleteMutation();

  const handleDelete = async () => {
    if (!followUp.id || !followUp.deal_id) {
      return;
    }

    try {
      await deleteFollowUp({
        variables: {
          id: followUp.id,
        },
      });
      toast.success('Follow-Up deleted');
      await refetch?.();
      onClose();
    } catch (e) {
      const error = e as Error;
      logger.error('DeleteFollowUp.tsx', 'deleteFollowUp', error);
      toast.error(
        `Error deleting Follow-Up: ${error.message || error}. Please refresh and try again.`,
      );
    }
  };

  return (
    <>
      {showIconButton ? (
        <IconButton
          icon={<BiTrash />}
          variant="iconHover"
          size="xs"
          color="errorsRed"
          aria-label="Delete Follow-Up"
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        />
      ) : (
        <Button variant="warning" onClick={onOpen}>
          Delete Follow-Up
        </Button>
      )}

      <Modal
        title="Delete Follow-Up"
        isOpen={isOpen}
        onClose={onClose}
        leftButtons={
          <Button variant="warning" onClick={onClose}>
            CANCEL
          </Button>
        }
        rightButtons={
          <Button variant="boot" onClick={handleDelete} isLoading={loading} loadingText="Delete">
            DELETE
          </Button>
        }
      >
        <Text align="center">Are you sure you want to delete this Follow-Up?</Text>
      </Modal>
    </>
  );
};

export default DeleteFollowUp;
