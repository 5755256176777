import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { IconButton } from '@chakra-ui/react';
import { BsExclamationOctagonFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { Deal, dealHasProblemSwitch, isTitling } from '../../gql/dealGql';
import { Note } from '../../gql/noteGql';

import { ProblemModal } from './DealProblemModal';

import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { NotesContext } from '../../libs/contextLib';
import { getTimezoneStr } from '../../libs/utils';

interface DealProblemProps {
  deal: Deal;
}

const DealProblem: React.FC<DealProblemProps> = ({ deal }) => {
  const { dispatch } = useContext(DealContext);
  const [hasProblem, setHasProblem] = useState(deal.has_problem);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [color, setColor] = useState(hasProblem ? 'errorsRed' : 'brightGray');
  const [dealHasProblemSwitchMutation] = useMutation(dealHasProblemSwitch);
  const { notesRefetch } = useContext(NotesContext);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleSave = async (note: Note, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      const {
        data: { dealHasProblemSwitch: res },
      } = await dealHasProblemSwitchMutation({
        variables: {
          id: deal.id,
          has_problem: !hasProblem,
          note: {
            ...note,
            creation_date_tz: getTimezoneStr(),
          },
        },
      });
      dispatch({ type: DealActionsEnum.UpdateDeal, payload: res });
      const { has_problem } = res;
      setHasProblem(has_problem);
      setColor(has_problem ? 'errorsRed' : 'gray');
      setShowModal(false);
      setSubmitting(false);
      toast.success(has_problem ? 'Problem deal enabled' : 'Problem deal disabled');
      notesRefetch();
    } catch {
      toast.error('Something went wrong');
    }
  };

  return isTitling(deal?.state) ? (
    <>
      <IconButton
        icon={<BsExclamationOctagonFill />}
        variant="icon"
        size="xs"
        fontSize={18}
        color={color}
        float="right"
        aria-label="Deal Problem"
        onClick={handleClick}
      />
      <ProblemModal
        show={showModal}
        setShow={setShowModal}
        onSubmit={handleSave}
        dealId={deal.id ?? 0}
        hasProblem={hasProblem}
      />
    </>
  ) : null;
};

export default DealProblem;
