export const getInitials = (name: string): string => {
  const [first, last] = name.split(' ');
  const fInit = first.slice(0, 1);
  const lInit = last?.slice(0, 1) ?? '';

  return `${fInit}${lInit}`;
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const snakeCaseToUpperCase = (str: string) =>
  str
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);

export const snakeCaseToTitleCase = (str: string) =>
  snakeCaseToUpperCase(str)
    .split(' ')
    .map((w) => capitalizeFirstLetter(w))
    .join(' ');

export const pascalCaseToTitleCase = (str: string): string =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());

export const toSnakeCase = (str: string): string => {
  const match = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);

  if (!match) {
    return str;
  }
  return match.map((s: string) => s.toLowerCase()).join('_');
};

export const replaceCharAtWith = (str: string, index: number, char: string) =>
  `${str.substring(0, index)}${char}${str.substring(index + 1)}`;

export const removeWhiteSpaces = (str?: string) => str?.replaceAll(' ', '') ?? '';

export const removeWhiteSpacesAndLowercase = (str?: string | null) =>
  str?.replaceAll(' ', '')?.toLowerCase() ?? '';

export const parseOutHtmlEntityEncodedTags = (strings: (string | null | undefined)[]) =>
  strings.map((str) => str?.replace(/&lt;[^]*?&gt;/g, ''));
