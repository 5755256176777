import React, { useContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import { DealStates, dealStatesQuery } from '../../gql/dealStatesGql';

import { DealContext } from '../../libs/DealContext';
import { formatDateWithTime } from '../../libs/utils';
import { snakeCaseToUpperCase } from '../../utils/text';

import Modal from '../shared/Modal';

const MODAL_TITLE = 'Deal States History';
const SYSTEM = 'System';

enum TABLE_HEADERS {
  DATE = 'Date',
  STATE = 'State',
  USER = 'User',
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DealStateHistory: React.FC<Props> = ({ isOpen, onClose }) => {
  const [dealStates, setDealStates] = useState<DealStates[]>([]);
  const [getDealStates] = useLazyQuery(dealStatesQuery, { fetchPolicy: 'cache-and-network' });

  const { deal } = useContext(DealContext);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        const { data } = await getDealStates({
          variables: { dealId: deal.id },
          fetchPolicy: 'cache-and-network',
        });

        setDealStates(data.dealStates);
      })();
    }
  }, [deal, isOpen]);

  return (
    <Modal title={MODAL_TITLE} isOpen={isOpen} onClose={onClose} size="xl">
      <Table variant="unstyled" borderBottomRadius={6}>
        <Thead bgColor="mediumElectricBlue">
          <Tr>
            {Object.values(TABLE_HEADERS).map((header) => (
              <Th color="white" key={header}>
                <Text fontSize="sm">{header}</Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {dealStates.map((dealState) => (
            <Tr key={dealState.id}>
              <Td>{formatDateWithTime(dealState?.updated_date_utc?.toString())}</Td>
              <Td>{snakeCaseToUpperCase(dealState?.state || '')}</Td>
              <Td>{dealState?.user?.name || SYSTEM}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Modal>
  );
};

export default DealStateHistory;
