import { ComponentStyleConfig } from '@chakra-ui/react';

const switchComponent: ComponentStyleConfig = {
  parts: ['container', 'track', 'thumb', 'label'],
  baseStyle: {
    track: {
      _checked: {
        bg: 'tealGreen',
        _disabled: {
          opacity: 0.5,
        },
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    thumb: {
      mt: '1px',
      ml: '1px',
      w: '13px',
      h: '13px',
    },
  },
  sizes: {
    lg: {
      thumb: {
        mt: '1px',
        ml: '1px',
        w: '22px',
        h: '22px',
      },
      label: {
        fontSize: 'lg',
      },
    },
    md: {
      label: {
        fontSize: 'sm',
      },
    },
  },
  variants: {
    checked: {
      label: {
        color: 'tealGreen',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default switchComponent;
