import { useState } from 'react';

import {
  DealMediaTypeEnum,
  Maybe,
  TtDocumentName,
  useGetBase64StringFromS3ObjectLazyQuery,
  useUploadDocumentMutation,
  useUploadDriversLicenseMutation,
} from '../gql/generated/graphql';
import { TTDocumentFieldNames } from '../gql/taterTitleGql';

import { logger } from '../libs/Logger';
import { parseAndFormatDate } from '../utils/dates';
import { getBase64FromFile } from '../utils/files';

const useLazyExtractDataFromFile = () => {
  const [loading, setLoading] = useState(false);

  const [getBase64FromS3Object] = useGetBase64StringFromS3ObjectLazyQuery();
  const [uploadDriversLicenseMutation] = useUploadDriversLicenseMutation();
  const [uploadDocumentMutation] = useUploadDocumentMutation();

  const getBase64 = async (mediaKeyOrFile: string | File) => {
    const isMediaKey = typeof mediaKeyOrFile === 'string';

    if (isMediaKey) {
      const result = await getBase64FromS3Object({
        variables: { key: mediaKeyOrFile },
      });
      return result?.data?.getBase64StringFromS3Object;
    }

    const result = await getBase64FromFile(mediaKeyOrFile);
    return result;
  };

  const extractDataFromFile = async (
    mediaType?: Maybe<DealMediaTypeEnum | ''>,
    metadata?: { [key: string]: string | boolean },
    mediaKeyOrFile?: string | File,
  ) => {
    try {
      setLoading(true);

      if (mediaKeyOrFile && mediaType === DealMediaTypeEnum.FrontOfDriversLicense) {
        const result = await getBase64(mediaKeyOrFile);

        if (result) {
          const response = await uploadDriversLicenseMutation({
            variables: { bytes: result },
          });

          const driversLicense = response.data?.uploadDriversLicense;

          const nameArr = [];
          if (driversLicense?.firstName) {
            nameArr.push(driversLicense.firstName);
          }
          if (driversLicense?.middleName) {
            nameArr.push(driversLicense.middleName);
          }
          if (driversLicense?.lastName) {
            nameArr.push(driversLicense.lastName);
          }

          return {
            ...metadata,
            name: nameArr.join(' '),
            nameVerified: false,
            address: driversLicense?.address ?? '',
            addressVerified: false,
            city: driversLicense?.city ?? '',
            cityVerified: false,
            state: driversLicense?.state ?? '',
            stateVerified: false,
            zip: driversLicense?.zip?.substring(0, 5) ?? '',
            zipVerified: false,
            expirationDate: driversLicense?.expirationDate ?? '',
            expirationDateVerified: false,
          } as { [key: string]: string | boolean };
        }
      }

      if (mediaKeyOrFile && mediaType === DealMediaTypeEnum.FrontOfInsuranceCard) {
        const result = await getBase64(mediaKeyOrFile);

        if (result) {
          const response = await uploadDocumentMutation({
            variables: {
              bytes: result,
              documentType: TtDocumentName.ProofOfInsurance,
            },
          });

          const insuranceCard = response.data?.uploadDocument;

          return {
            ...metadata,
            name:
              insuranceCard?.fields?.find((f) => f?.name === TTDocumentFieldNames.NameOfInsured)
                ?.value ?? '',
            nameVerified: false,
            state:
              insuranceCard?.fields?.find((f) => f?.name === TTDocumentFieldNames.StateName)
                ?.value ?? '',
            stateVerified: false,
            vin:
              insuranceCard?.fields?.find((f) => f?.name === TTDocumentFieldNames.Vin)?.value ?? '',
            vinVerified: false,
            expirationDate: parseAndFormatDate(
              insuranceCard?.fields?.find((f) => f?.name === TTDocumentFieldNames.ExpirationDate)
                ?.value,
            ),
            expirationDateVerified: false,
            insuranceCompany:
              insuranceCard?.fields?.find((f) => f?.name === TTDocumentFieldNames.Company)?.value ??
              '',
            insuranceCompanyVerified: false,
            policyNo:
              insuranceCard?.fields?.find((f) => f?.name === TTDocumentFieldNames.PolicyNumber)
                ?.value ?? '',
            policyNoVerified: false,
          } as { [key: string]: string | boolean };
        }
      }
    } catch (error) {
      logger.error('useLazyExtractDataFromFile.ts', '', null, error);
    } finally {
      setLoading(false);
    }

    return metadata;
  };

  return {
    extractDataFromFile,
    loading,
  };
};

export default useLazyExtractDataFromFile;
