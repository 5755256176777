import { useMutation } from '@apollo/client';
import React from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { Deal, DealStateEnum } from '../../gql/dealGql';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';
import ROUTES from '../../constants/routes';
import { logger } from '../../libs/Logger';

const RequestApprovalButton: React.FC = () => {
  const { values, isSubmitting, setSubmitting } = useFormikContext<Deal>();
  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert);
  const history = useHistory();

  const onRequestApproval = async (updatedValues: Deal) => {
    try {
      await upsertFinancialInfoAcquisition({
        variables: {
          financialInfoAcquisition: updatedValues.financial_info_acquisition,
          id: updatedValues.id,
          state: updatedValues.state,
          clear_override: true,
        },
      });
      history.push(ROUTES.DASHBOARD);
    } catch (error) {
      logger.error('RequestApprovalButton.tsx', '', null, error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Button
      isLoading={isSubmitting}
      loadingText="REQUEST APPROVAL"
      onClick={() => {
        onRequestApproval({ ...values, state: DealStateEnum.Structuring });
      }}
    >
      REQUEST APPROVAL
    </Button>
  );
};

export default RequestApprovalButton;
