import { Box, BoxProps } from '@chakra-ui/react';

export const NewTitleNotificationsContainer = (props: BoxProps) => (
  <Box
    position="absolute"
    minH="10px"
    display="inline-block"
    top="0px"
    right="0px"
    transform="translateY(-50%)"
    {...props}
  />
);

export const NewTableNotificationsContainer = (props: BoxProps) => (
  <Box position="relative" {...props} />
);
