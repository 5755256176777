import { FC, useContext, useMemo } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { IoMdArrowBack } from 'react-icons/io';
import { toast } from 'react-toastify';

import { DealStateEnum, DealStateLabelMap } from '../../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../../gql/generated/graphql';

import MoveAndCancelSigningModal from './MoveAndCancelSigningModal';

import { PermissionEnum } from '../../../constants/permissions';
import { Can } from '../../../libs/Can';
import { DealContext } from '../../../libs/DealContext';
import { getShouldCancelContracts } from '../../../utils/routeOne';

const BackButton: FC = () => {
  const { deal, dealRefetch } = useContext(DealContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateDealState, { loading }] = useDealUpdateStateMutation();

  const previousState = useMemo(() => {
    switch (deal.state) {
      case DealStateEnum.Closing: {
        return DealStateEnum.Structuring;
      }
      case DealStateEnum.Closed: {
        return DealStateEnum.Closing;
      }
      case DealStateEnum.SentForSignatures: {
        return DealStateEnum.Closed;
      }
      case DealStateEnum.Signed: {
        return DealStateEnum.Closed;
      }
      case DealStateEnum.Funded: {
        return DealStateEnum.Signed;
      }
      case DealStateEnum.ReadyForPickup: {
        return DealStateEnum.PaidOff;
      }
      case DealStateEnum.AtAuction: {
        return DealStateEnum.ReadyForPickup;
      }
      case DealStateEnum.Sold: {
        return DealStateEnum.AtAuction;
      }
      case DealStateEnum.TitleSent: {
        return DealStateEnum.Sold;
      }
      case DealStateEnum.SendPayoff: {
        return DealStateEnum.Funded;
      }
      case DealStateEnum.WaitingForTitle: {
        return DealStateEnum.SendPayoff;
      }
      case DealStateEnum.TitleReceived: {
        return DealStateEnum.WaitingForTitle;
      }
      case DealStateEnum.SentToProcessor: {
        return DealStateEnum.TitleReceived;
      }
      default: {
        return null;
      }
    }
  }, [deal.state]);

  const shouldCancelContracts = useMemo(
    () => getShouldCancelContracts(deal, previousState),
    [deal, previousState],
  );

  const buttonText = useMemo(() => {
    if (!previousState) {
      return null;
    }

    if (shouldCancelContracts) {
      return 'MOVE & CANCEL CONTRACTS';
    }

    return `Send to ${DealStateLabelMap[previousState as DealStateEnum]}`.toUpperCase();
  }, [previousState, shouldCancelContracts]);

  const revertState = async () => {
    if (!deal.id) {
      return;
    }

    try {
      await updateDealState({
        variables: {
          id: deal.id,
          state: previousState,
        },
      });
      toast.success('Success');
      await dealRefetch();
    } catch (e) {
      const error = e as Error;
      toast.error(error.message || 'Could not revert deal state', {
        autoClose: error.message ? false : undefined,
      });
    } finally {
      onClose();
    }
  };

  return previousState ? (
    <Can I={PermissionEnum.RevertState}>
      <Button
        variant={shouldCancelContracts ? 'boot' : 'secondary'}
        size="lgWithIconLeft"
        leftIcon={<IoMdArrowBack />}
        onClick={async () => {
          if (shouldCancelContracts) {
            onOpen();
          } else {
            await revertState();
          }
        }}
      >
        {buttonText}
      </Button>

      <MoveAndCancelSigningModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={revertState}
        loading={loading}
      />
    </Can>
  ) : null;
};

export default BackButton;
