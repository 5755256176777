import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@chakra-ui/react';
import { Deal } from '../../gql/dealGql';

interface PayoffReminderModalProps {
  show: boolean;
  hide: () => void;
  saveFn: (setSaving: (isSaving: boolean) => void) => void;
  finalizeFn: (values: Deal, setSubmitting: (isSubmitting: boolean) => void) => void;
  values: Deal;
}

const PayoffReminderModal: React.FC<PayoffReminderModalProps> = ({
  show,
  hide,
  saveFn,
  finalizeFn,
  values,
}) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={hide}
      keyboard={false}
      dialogClassName="reminder-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          Has the payoff sheet been uploaded to the Media Center?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          onClick={() => {
            hide();
            finalizeFn(values, () => null);
          }}
        >
          YES
        </Button>
        <Button
          onClick={() => {
            hide();
            saveFn(() => null);
          }}
        >
          NO
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default PayoffReminderModal;
