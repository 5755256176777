import React from 'react';
import InputMask from 'react-input-mask';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { Formik } from 'formik';
import { Form, Col } from 'react-bootstrap';
import { Auction, auctionUpsert } from '../../gql/auctionGql';
import { NumberInput } from './FormComponents';
import { validationSchemaAddAuction } from './validationSchemas';
import { logger } from '../../libs/Logger';

interface AddAuctionProps {
  setShowModal: (showModal: boolean) => void;
  auctionsRefetch: () => Promise<ApolloQueryResult<Auction>>;
}

export const AddAuction: React.FC<AddAuctionProps> = ({ setShowModal, auctionsRefetch }) => {
  const initialVals = { auction: new Auction() };
  const [upsertAuction] = useMutation(auctionUpsert);

  const handleSubmit = async (auction: Auction) => {
    try {
      await upsertAuction({
        variables: {
          auction: {
            ...auction,
            state: auction.state?.toUpperCase(),
          },
        },
      });

      setShowModal(false);
      await auctionsRefetch();
    } catch (e) {
      logger.error('AddAuction.tsx', '', null, e);
    }
  };

  return (
    <Formik
      initialValues={initialVals}
      onSubmit={({ auction }) => {
        handleSubmit(auction);
      }}
      validationSchema={validationSchemaAddAuction}
      validateOnMount
    >
      {({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        isValid,
        submitForm,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Form.Row>
              <Col md="6">
                <h5>Name</h5>
              </Col>
              <Form.Group as={Col} md="6">
                <Form.Control
                  type="text"
                  name="auction.name"
                  value={values.auction?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.name && !!errors.auction?.name}
                />
                <Form.Control.Feedback type="invalid">{errors.auction?.name}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col md="6">
                <h5>Address</h5>
              </Col>
              <Form.Group as={Col} md="6">
                <Form.Control
                  type="text"
                  name="auction.address_line"
                  value={values.auction?.address_line}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.address_line && !!errors.auction?.address_line}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.auction?.address_line}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col md="6">
                <h5>City</h5>
              </Col>
              <Form.Group as={Col} md="6">
                <Form.Control
                  type="text"
                  name="auction.city"
                  value={values.auction?.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.city && !!errors.auction?.city}
                />
                <Form.Control.Feedback type="invalid">{errors.auction?.city}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col md="6">
                <h5>State</h5>
              </Col>
              <Form.Group as={Col} md="6">
                <Form.Control
                  type="text"
                  as={InputMask}
                  mask="aa"
                  name="auction.state"
                  value={values.auction?.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.state && !!errors.auction?.state}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.auction?.state}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col md="6">
                <h5>Zip</h5>
              </Col>
              <Form.Group as={Col} md="6">
                <Form.Control
                  type="text"
                  as={InputMask}
                  mask="99999"
                  name="auction.zip"
                  value={values.auction?.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.zip && !!errors.auction?.zip}
                />
                <Form.Control.Feedback type="invalid">{errors.auction?.zip}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col md="6">
                <h5>Phone Number</h5>
              </Col>
              <Form.Group as={Col} md="6">
                <Form.Control
                  type="text"
                  as={InputMask}
                  mask="(999) 999-9999"
                  name="auction.phone_number"
                  value={values.auction?.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.phone_number && !!errors.auction?.phone_number}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.auction?.phone_number}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col md="6">
                <h5>Average Transport Cost (optional)</h5>
              </Col>
              <Form.Group as={Col} md="6">
                <NumberInput
                  isMoney
                  isEditing
                  isWholeNumber
                  path="auction.average_transport_cost"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.auction?.phone_number}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-center">
              <Button
                variant="secondary"
                isLoading={isSubmitting}
                loadingText="SAVE"
                isDisabled={!isValid}
                onClick={submitForm}
              >
                SAVE
              </Button>
            </Form.Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddAuction;
