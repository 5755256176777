import LeaseEndContainer from '../../components/Container/Container';
import ButtonsRow from '../../components/Dashboards/ButtonsRow';
import StructuringFollowUpsTable from '../../components/StructuringFollowUp/StructuringFollowUpTable';

const StructuringFollowUpsPage = () => {
  return (
    <>
      <ButtonsRow />
      <LeaseEndContainer fluid>
        <StructuringFollowUpsTable />
      </LeaseEndContainer>
    </>
  );
};

export default StructuringFollowUpsPage;
