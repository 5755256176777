import * as Yup from 'yup';

import { validationSchema as followUpValidationSchema } from '../FollowUp/validationSchema';

import { phoneRegExp, zipRegExp } from '../../constants/RegExps';

const addressValidationSchema = Yup.object().shape({
  address_line: Yup.string().optional().nullable(),
  address_line_2: Yup.string().optional().nullable(),
  zip: Yup.string().optional().nullable().matches(zipRegExp, `Please enter a valid Zip Code`),
  city: Yup.string().optional().nullable(),
  state: Yup.string().optional().nullable(),
  county: Yup.string().optional().nullable(),
});

const minimalCustomerValidationSchema = Yup.object().shape({
  first_name: Yup.string().optional().required('First Name is required'),
  last_name: Yup.string().optional().required('Last Name is required'),
  phone_number: Yup.string().optional().required('Phone Number is required').matches(phoneRegExp, {
    message: 'Please input a valid phone number',
    excludeEmptyString: true,
  }),
  email: Yup.string()
    .email('Please input a valid Email address')
    .lowercase()
    .when(['no_email'], {
      is: false,
      then: Yup.string().optional().required('Email is required'),
      otherwise: Yup.string().optional().nullable(),
    }),
  address: addressValidationSchema,
});

export const validationSchema = Yup.object().shape({
  customer: minimalCustomerValidationSchema,
  follow_up: followUpValidationSchema.pick(['date', 'time', 'note']),
});
