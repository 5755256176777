import React, { useState } from 'react';
import { IconButton, Spacer } from '@chakra-ui/react';
import { MdOutlineVisibilityOff, MdOutlineVisibility } from 'react-icons/md';
import { useUser } from '../../hooks/useUser';
import { useCreateSsnLogMutation } from '../../gql/generated/graphql';

interface MaskedSsnButtonProps {
  type: 'form' | 'payoff' | 'customerInfo';
  onClick: (value: boolean) => void;
  customerId?: number;
  tempInfoId?: number | null;
  visible?: boolean;
}

const MaskedSsnButton: React.FC<MaskedSsnButtonProps> = ({
  type,
  onClick,
  customerId,
  tempInfoId,
  visible,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const user = useUser();
  const [createSsnLog] = useCreateSsnLogMutation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isHidden) {
      createSsnLog({
        variables: {
          log: {
            user_id: user.id,
            customer_id: parseInt(customerId as unknown as string, 10) || null,
            temp_info_id: tempInfoId || null,
          },
        },
      });
    }
    setIsHidden(!isHidden);
    onClick(!isHidden);
  };
  return (
    <>
      {type === 'form' && <Spacer />}
      <IconButton
        variant="iconHover"
        size="xs"
        height="100%"
        icon={isHidden ? <MdOutlineVisibility size={15} /> : <MdOutlineVisibilityOff size={15} />}
        visibility={type === 'payoff' && !visible ? 'hidden' : 'visible'}
        onClick={handleClick}
        aria-label="Show/Hide SSN"
      />
      {type !== 'form' && <Spacer />}
    </>
  );
};

export default MaskedSsnButton;
