import React, { ReactNode } from 'react';
import { Td } from '@chakra-ui/react';

import { TemporaryInfo } from '../../../../gql/generated/graphql';
import { Deal } from '../../../../gql/dealGql';
import { PayoffRequest } from '../../../../gql/payoffRequestGql';

import Name from '../../../shared/Table/ColumnComponents/Name';
import PayoffAge from './PayoffAge';
import PayoffDealActions from './PayoffDealActions';

import { getDealAge } from '../../../../utils/deals';
import MaskedSsnPayoff from '../../../MaskedSsn/MaskedSsnPayoff';
import { formatDate } from '../../../../libs/utils';

export const TABLE_HEADERS = {
  AGE: 'Age',
  NAME: 'Name',
  LIENHOLDER: 'Lienholder',
  YEAR: 'Year',
  MAKE: 'Make',
  MODEL: 'Model',
  VIN: 'VIN',
  ACCOUNT_NUMBER: 'Account #',
  PHONE_NUMBER: 'Phone Number',
  ODOMETER: 'Odometer',
  SSN: 'SSN',
  ZIP: 'Zip Code',
  BIRTHDATE: 'Birthdate',
  SOURCE: 'Source',
  ASSIGNED: 'Assigned',
  ACTIONS: 'Actions',
} as const;

const firstNameAndLastInitial = (name: string | undefined) => {
  if (name) {
    const names = name.split(' ');
    return names.length > 1 ? `${names[0]} ${names[names.length - 1][0]}` : `${names[0]}`;
  }
  return null;
};

const missingInfoDashes = '--';

export type TableHeadersType = typeof TABLE_HEADERS[keyof typeof TABLE_HEADERS];

export interface GetComponentParams {
  deal: Deal;
  tempInfo?: TemporaryInfo;
  payoffRequest?: PayoffRequest;
  value?: string;
  collapsed?: boolean;
  setCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PayoffTableColumnsProps {
  getComponent: (params: GetComponentParams) => ReactNode;
  getValue: (
    deal: Deal,
    tempInfo?: TemporaryInfo,
    payoffRequest?: PayoffRequest,
    params?: GetComponentParams,
  ) => string | undefined;
  sortingFieldName: string;
  isDisabled?: boolean;
}

export const payoffTableColumns: {
  [K in TableHeadersType]: PayoffTableColumnsProps;
} = {
  [TABLE_HEADERS.AGE]: {
    getComponent: ({ payoffRequest }) => <PayoffAge payoffRequest={payoffRequest} />,
    getValue: (deal) => getDealAge(deal.created_at).toString() || '--',
    sortingFieldName: 'created_at',
  },
  [TABLE_HEADERS.NAME]: {
    getComponent: ({ deal, payoffRequest, collapsed, setCollapsed }) => (
      <Name
        deal={deal}
        payoffRequest={payoffRequest}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        value={payoffTableColumns[TABLE_HEADERS.NAME].getValue(deal)}
      />
    ),
    getValue: (deal) =>
      `${deal.customer?.first_name} ${deal.customer?.last_name}` || missingInfoDashes,
    sortingFieldName: 'deal.customer.first_name',
  },
  [TABLE_HEADERS.LIENHOLDER]: {
    getComponent: ({ deal }) => (
      <Td>{payoffTableColumns[TABLE_HEADERS.LIENHOLDER].getValue(deal)}</Td>
    ),
    getValue: (deal) => deal.car?.payoff?.lienholder_name || missingInfoDashes,
    sortingFieldName: 'deal.car.payoff.lienholder_name',
  },
  [TABLE_HEADERS.YEAR]: {
    getComponent: ({ deal }) => <Td>{payoffTableColumns[TABLE_HEADERS.YEAR].getValue(deal)}</Td>,
    getValue: (deal) => deal.car?.year || missingInfoDashes,
    sortingFieldName: 'deal.car.year',
  },
  [TABLE_HEADERS.MAKE]: {
    getComponent: ({ deal }) => <Td>{payoffTableColumns[TABLE_HEADERS.MAKE].getValue(deal)}</Td>,
    getValue: (deal) => deal.car?.make.toUpperCase() || missingInfoDashes,
    sortingFieldName: 'deal.car.make',
  },
  [TABLE_HEADERS.MODEL]: {
    getComponent: ({ deal }) => <Td>{payoffTableColumns[TABLE_HEADERS.MODEL].getValue(deal)}</Td>,
    getValue: (deal) => deal.car?.model.toUpperCase() || missingInfoDashes,
    sortingFieldName: 'deal.car.model',
  },
  [TABLE_HEADERS.VIN]: {
    getComponent: ({ deal }) => <Td>{payoffTableColumns[TABLE_HEADERS.VIN].getValue(deal)}</Td>,
    getValue: (deal) => deal.car?.vin.toUpperCase() || missingInfoDashes,
    sortingFieldName: 'deal.car.vin',
  },
  [TABLE_HEADERS.ACCOUNT_NUMBER]: {
    getComponent: ({ deal }) => (
      <Td>{payoffTableColumns[TABLE_HEADERS.ACCOUNT_NUMBER].getValue(deal)}</Td>
    ),
    getValue: (deal) => deal.car?.payoff.account_number || missingInfoDashes,
    sortingFieldName: 'deal.car.payoff.account_number',
  },
  [TABLE_HEADERS.PHONE_NUMBER]: {
    getComponent: ({ deal, tempInfo }) => (
      <Td>{payoffTableColumns[TABLE_HEADERS.PHONE_NUMBER].getValue(deal, tempInfo)}</Td>
    ),
    getValue: (deal, tempInfo) =>
      deal.customer?.phone_number ||
      deal.customer?.home_phone_number ||
      tempInfo?.data?.phone_number ||
      missingInfoDashes,
    sortingFieldName: 'deal.customer.phone_number',
  },
  [TABLE_HEADERS.ODOMETER]: {
    getComponent: ({ deal }) => (
      <Td>{payoffTableColumns[TABLE_HEADERS.ODOMETER].getValue(deal)}</Td>
    ),
    getValue: (deal) => deal.car?.mileage?.toLocaleString() || missingInfoDashes,
    sortingFieldName: 'deal.car.mileage',
  },
  [TABLE_HEADERS.SSN]: {
    getComponent: ({ deal, tempInfo, payoffRequest }) => (
      <Td>
        {deal?.customer?.ssn || tempInfo?.data?.ssn ? (
          <MaskedSsnPayoff
            ssn={deal.customer.ssn ?? tempInfo?.data?.ssn}
            customerId={deal?.customer?.id}
            tempInfoId={tempInfo?.id}
            claimedBy={payoffRequest?.payoff_clerk_id}
          />
        ) : (
          payoffTableColumns[TABLE_HEADERS.SSN].getValue(deal, tempInfo)
        )}
      </Td>
    ),
    getValue: (deal, tempInfo) => deal.customer?.ssn || tempInfo?.data?.ssn || missingInfoDashes,
    sortingFieldName: 'deal.customer.ssn',
  },
  [TABLE_HEADERS.ZIP]: {
    getComponent: ({ deal }) => <Td>{payoffTableColumns[TABLE_HEADERS.ZIP].getValue(deal)}</Td>,
    getValue: (deal) => deal.customer?.address?.zip || missingInfoDashes,
    sortingFieldName: 'deal.customer.address.zip',
  },
  [TABLE_HEADERS.BIRTHDATE]: {
    getComponent: ({ deal }) => (
      <Td>{payoffTableColumns[TABLE_HEADERS.BIRTHDATE].getValue(deal)}</Td>
    ),
    getValue: (deal, tempInfo) =>
      deal.customer?.dob
        ? formatDate(deal.customer?.dob, 'utc')
        : tempInfo?.data?.dob
        ? formatDate(tempInfo.data.dob, 'utc')
        : missingInfoDashes,
    sortingFieldName: 'deal.customer.dob',
  },
  [TABLE_HEADERS.SOURCE]: {
    getComponent: ({ deal }) => <Td>{payoffTableColumns[TABLE_HEADERS.SOURCE].getValue(deal)}</Td>,
    getValue: (deal) =>
      deal.id ? firstNameAndLastInitial(deal.closer?.name) || missingInfoDashes : 'Web',
    sortingFieldName: 'deal.closer_id',
  },
  [TABLE_HEADERS.ASSIGNED]: {
    getComponent: ({ deal, tempInfo, payoffRequest }) => (
      <Td>{payoffTableColumns[TABLE_HEADERS.ASSIGNED].getValue(deal, tempInfo, payoffRequest)}</Td>
    ),
    getValue: (_deal, _tempInfo, payoffRequest) =>
      firstNameAndLastInitial(payoffRequest?.payoff_clerk?.name) || missingInfoDashes,
    sortingFieldName: 'payoff_request.payoff_clerk_id',
  },
  [TABLE_HEADERS.ACTIONS]: {
    getComponent: ({ deal, payoffRequest, tempInfo }) => (
      <PayoffDealActions
        payoffRequest={payoffRequest}
        dealId={deal.id?.toString()}
        tempInfoId={tempInfo?.id?.toString()}
        claimedBy={payoffRequest?.payoff_clerk?.id}
      />
    ),
    getValue: (deal) => String(deal.id) || missingInfoDashes,
    sortingFieldName: '',
    isDisabled: true,
  },
};
