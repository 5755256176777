import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tr } from '@chakra-ui/react';

import { TemporaryInfo } from '../../../gql/generated/graphql';
import { PayoffRequest } from '../../../gql/payoffRequestGql';

import { handleClickOrCommandClick } from '../../../libs/eventHandlers';

import { payoffTableColumns } from './ColumnComponents';
import PayoffDetail from './PayoffDetail';

interface Props {
  payoffRequest: PayoffRequest;
}

const DealRowContainer: React.FC<Props> = ({ payoffRequest }) => {
  const { deal } = payoffRequest;
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const history = useHistory();
  const tableColumns = Object.entries(payoffTableColumns);

  return deal?.id ? (
    <>
      <Tr
        cursor="pointer"
        _hover={{ bgColor: 'hoverGray' }}
        onClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
        onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
        whiteSpace="nowrap"
      >
        {tableColumns.map(([header, { getComponent }]) => (
          <React.Fragment key={header}>
            {getComponent({
              collapsed,
              setCollapsed,
              tempInfo: payoffRequest.temporary_info as TemporaryInfo,
              payoffRequest,
              deal,
            })}
          </React.Fragment>
        ))}
      </Tr>
      <PayoffDetail collapsed={collapsed} payoffRequest={payoffRequest} />
    </>
  ) : (
    <>
      <Tr>
        {tableColumns.map(([header, { getComponent }]) => (
          <React.Fragment key={header}>
            {getComponent({
              collapsed,
              setCollapsed,
              tempInfo: payoffRequest.temporary_info as TemporaryInfo,
              payoffRequest,
              deal,
            })}
          </React.Fragment>
        ))}
      </Tr>
      <PayoffDetail collapsed={collapsed} payoffRequest={payoffRequest} />
    </>
  );
};

export default DealRowContainer;
