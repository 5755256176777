import React, { useEffect, useState } from 'react';

import { Box, Heading } from '@chakra-ui/react';

const Transcriptions = () => {
  const [transcription, setTranscription] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const job = urlParams.get('job');
    const url = `https://leaseend-twilio-voice-recordings.s3.us-east-2.amazonaws.com/transcriptions/redacted-${job}.json`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const text = data?.results?.transcripts[0]?.transcript ?? 'No transcription found';
        setTranscription(text);
      })
      .catch(() => {
        setTranscription('No transcription found');
      });
  }, []);

  return (
    <Box p="4">
      <Heading>Transcription:</Heading>
      <p>{transcription}</p>
    </Box>
  );
};

export default Transcriptions;
