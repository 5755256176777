import { useContext, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { isBefore } from 'date-fns';
import { Formik, useFormikContext } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toggle/style.css';

import { MileageWarningsEnum, OdometerStatus } from '../../gql/carGql';
import { Cobuyer } from '../../gql/customerGql';
import { DealContact } from '../../gql/dealContactGql';
import {
  Deal,
  DealStateEnum,
  creditAppAutomate,
  creditAppUpsert,
  isDealInOrPastFunded,
  isUneditable,
} from '../../gql/dealGql';
import { FinancialInfo } from '../../gql/financialInfoGql';
import { PayoffVerificationStatus, useVehicleMileageLazyQuery } from '../../gql/generated/graphql';

import ConfirmEditModal from '../ConfirmEditModal/ConfirmEditModal';
import MileageWarningModal from '../MileageWarningModal';
import PayoffWarningModal from '../PayoffWarningModal/PayoffWarningModal';
import Card from '../shared/Card';
import AddCobuyerOrContactButton from './components/AddCobuyerOrContactButton';
import PrequalificationSection from './components/Prequalification/PrequalificationSection';
import SubmitApplicationButtons from './components/SubmitApplicationButtons';

import AdditionalContact from './AdditionalContact';
import { CreditPerson, cleanDeal } from './CreditPerson';
import SwapBuyerCobuyerButton from './SwapBuyerCobuyerButton';
import { validationSchema } from './validationSchema';

import { LDFlags } from '../../constants/experiments';
import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import useFlag from '../../hooks/useFlag';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { states } from '../../libs/states';
import { passValuesToSchema } from '../../libs/utils';
import DisabledLienholderModal from '../../pages/DealDetail/DisabledLienholderModal';
import { getMileageWarning } from '../../utils/cars';
import { setInsertedIds } from '../../utils/deals';

interface OnSaveProps {
  creditAppFormValues: Deal;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  autoStructure?: boolean;
}

interface CreditApplicationFormProps {
  isSaving: boolean;
  onSave: (onSaveProps: OnSaveProps) => void;
  closeModal?: () => void;
  showPrequalification?: boolean;
}

const CreditApplicationForm = ({
  isSaving,
  onSave,
  closeModal,
  showPrequalification = false,
}: CreditApplicationFormProps) => {
  const prequalAutoStructureFlag = useFlag(LDFlags.PREQUAL_AUTOSTRUCTURE);

  const { deal, isRecalculatingPayoff, autosaving } = useContext(DealContext);
  const abilities = useContext(AbilityContext);
  const { setModal, modals } = useContext(ModalContext);
  const history = useHistory();

  const { values, handleSubmit, setFieldValue, validateForm } = useFormikContext<Deal>();

  const mileageModal = useDisclosure();
  const payoffModal = useDisclosure();

  const [dealState, setDealState] = useState<DealStateEnum>(DealStateEnum.Structuring);
  const [mileageWarning, setMileageWarning] =
    useState<{ status: MileageWarningsEnum; warning: string }>();

  const [ignoreInvalidPayoff, setIgnoreInvalidPayoff] = useState<boolean>(false);
  const [ignoreInvalidMileage, setIgnoreInvalidMileage] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isInsideModal = !!closeModal;

  const canEditFundedOnwards =
    abilities.has(PermissionEnum.EditFundedOnwards) && isDealInOrPastFunded(values.state);

  useEffect(() => {
    if (values.customer.email !== deal.customer.email && deal.customer.auth0_id) {
      setFieldValue('customer.auth0_id', null);
    }
    if (values?.cobuyer?.email !== deal.cobuyer?.email && deal.cobuyer?.auth0_id) {
      setFieldValue('cobuyer.auth0_id', null);
    }
  }, [values.customer.email, values?.cobuyer?.email]);

  const handleOnSave = (dealStateParam?: DealStateEnum) => {
    const dealStateToSave = dealStateParam ?? dealState;

    onSave({
      creditAppFormValues: {
        ...values,
        state: dealStateToSave,
        ...(values.car?.mileage && {
          car: {
            ...values.car,
            odometer_status:
              mileageWarning?.status === MileageWarningsEnum.RoundNumber ||
              mileageWarning?.status === MileageWarningsEnum.RepeatingEnd
                ? OdometerStatus.Warning
                : OdometerStatus.Verified,
          },
        }),
      },
      setFieldValue,
      autoStructure: dealStateToSave === DealStateEnum.StructuringInProgress,
    });

    history.push(ROUTES.DASHBOARD);
  };

  const [checkVehicleMileage, { loading: isCheckingMileage }] = useVehicleMileageLazyQuery({
    fetchPolicy: 'network-only',
  });
  const isSavingOrCheckingMileage = isSaving || autosaving || isCheckingMileage;

  const isPayoffValid = () => {
    const good_through_date = values.car?.payoff?.good_through_date;
    const verification_status = values.car?.payoff?.verification_status;

    const payoffIsInvalid =
      verification_status !== PayoffVerificationStatus.Verified ||
      (good_through_date && isBefore(new Date(good_through_date), new Date()));
    if (payoffIsInvalid) {
      payoffModal.onOpen();
      return false;
    }

    return true;
  };

  const isMileageValid = async () => {
    const { error: vehicleMileageError, data: vehicleMileageData } = await checkVehicleMileage({
      variables: { vin: values.car.vin },
    });
    if (vehicleMileageError) {
      toast.error('Error checking vehicle mileage');
      return false;
    }

    const newMileageWarning = getMileageWarning(
      vehicleMileageData?.vehicleMileage,
      values.car.mileage,
    );
    if (newMileageWarning) {
      setMileageWarning(newMileageWarning);
      mileageModal.onOpen();
      return false;
    }

    return true;
  };

  const validatePayoffAndMileage = async ({
    dealStateParam,
    skipPayoffValidation = false,
    skipMileageValidation = false,
  }: {
    dealStateParam?: DealStateEnum;
    skipPayoffValidation?: boolean;
    skipMileageValidation?: boolean;
  } = {}) => {
    const validations = [];
    if (!skipPayoffValidation) {
      const payoffIsValid = isPayoffValid();
      validations.push(payoffIsValid);
    }

    if (!skipMileageValidation) {
      const mileageIsValid = await isMileageValid();
      validations.push(mileageIsValid);
    }

    if (validations.every((result) => result === true)) {
      handleOnSave(dealStateParam);
    }
  };

  return (
    <Card variant="roundedWithBorder">
      <Form noValidate onSubmit={handleSubmit}>
        <CreditPerson
          adminEditOverride={isEditing}
          isCobuyer={false}
          isInsideModal={isInsideModal}
        />

        <DisabledLienholderModal />

        <HStack>
          {/* Add/Remove cobuyer button */}
          <AddCobuyerOrContactButton
            isAdded={values.addCobuyer}
            isDisabled={canEditFundedOnwards ? !isEditing : isUneditable(values.state)}
            type="cobuyer"
          />
          {/* Add/Remove contact button above cobuyer info */}
          {!values.addCobuyer ? (
            <AddCobuyerOrContactButton isAdded={values.addContact} type="contact" />
          ) : null}
        </HStack>

        {values.addCobuyer ? (
          <CreditPerson
            isCobuyer
            adminEditOverride={isEditing}
            isInsideModal={isInsideModal}
            isBuyerStateWI={values.customer.address.state === states.WISCONSIN}
          />
        ) : null}
        {/* Add/Remove Contact button below cobuyer info */}
        {values.addCobuyer ? (
          <AddCobuyerOrContactButton isAdded={values.addContact} type="contact" />
        ) : null}
        {values.addContact ? <AdditionalContact /> : null}
        {/* Add/Remove second contact button */}
        {values.addContact ? (
          <AddCobuyerOrContactButton isAdded={values.addSecondContact} type="secondContact" />
        ) : null}
        {values.addSecondContact ? <AdditionalContact isSecond /> : null}

        {prequalAutoStructureFlag && showPrequalification && <PrequalificationSection />}

        <HStack
          justifyContent="right"
          pt={5}
          pr={5}
          borderTopColor="gray.100"
          borderTopWidth="2px"
          flexWrap="wrap"
          spacing={1}
        >
          {isEditing || !canEditFundedOnwards ? (
            <Button
              variant="secondary"
              isLoading={isSavingOrCheckingMileage}
              isDisabled={isRecalculatingPayoff}
              loadingText="SAVE"
              onClick={() => {
                validateForm();
                setIsEditing(false);
                onSave({ creditAppFormValues: values, setFieldValue });
              }}
            >
              Save
            </Button>
          ) : (
            <>
              <Button
                variant="secondary"
                hidden={!canEditFundedOnwards}
                onClick={() => {
                  setModal({ ConfirmEdit: true });
                }}
              >
                Edit
              </Button>
              <ConfirmEditModal
                isOpen={modals.ConfirmEdit}
                onConfirm={() => {
                  setIsEditing(true);
                }}
              />
            </>
          )}

          <SwapBuyerCobuyerButton isSavingOrCheckingMileage={isSavingOrCheckingMileage} />

          {values.state === DealStateEnum.SoftClose && (
            <>
              <SubmitApplicationButtons
                isSavingOrCheckingMileage={isSavingOrCheckingMileage}
                setDealState={setDealState}
                validatePayoffAndMileage={validatePayoffAndMileage}
              />

              <MileageWarningModal
                {...mileageModal}
                status={mileageWarning?.status}
                warning={mileageWarning?.warning}
                onSuccess={() => {
                  const newIgnoreInvalidMileage = true;
                  setIgnoreInvalidMileage(newIgnoreInvalidMileage);
                  mileageModal.onClose();

                  validatePayoffAndMileage({
                    skipMileageValidation: newIgnoreInvalidMileage,
                    skipPayoffValidation: ignoreInvalidPayoff,
                  });
                }}
                onClose={() => {
                  setIgnoreInvalidMileage(false);
                  mileageModal.onClose();
                }}
              />

              <PayoffWarningModal
                {...payoffModal}
                payoffValue={values.car?.payoff?.vehicle_payoff}
                onMoveDeal={() => {
                  const newIgnoreInvalidPayoff = true;
                  setIgnoreInvalidPayoff(newIgnoreInvalidPayoff);
                  payoffModal.onClose();

                  validatePayoffAndMileage({
                    skipPayoffValidation: newIgnoreInvalidPayoff,
                    skipMileageValidation: ignoreInvalidMileage,
                  });
                }}
                onClose={() => {
                  setIgnoreInvalidPayoff(false);
                  payoffModal.onClose();
                }}
              />
            </>
          )}
          <Button
            hidden={!isInsideModal}
            loadingText="CLOSE"
            onClick={() => {
              if (isInsideModal) {
                closeModal();
              }
            }}
          >
            Close
          </Button>
        </HStack>
        {prequalAutoStructureFlag && showPrequalification && (
          <Text mt="10px" mx="20px" fontWeight="normal" fontStyle="italic" color="silverSlateGray">
            *By clicking Submit Application, I am confirming that I have obtained consent from the
            driver to submit an application for credit.
          </Text>
        )}
      </Form>
    </Card>
  );
};

type CreditApplicationProps = {
  showInsideModal?: boolean;
};

const CreditApplication = ({ showInsideModal }: CreditApplicationProps) => {
  const { deal, dispatch } = useContext(DealContext);
  const { modals, setModal } = useContext(ModalContext);

  const [isSaving, setSaving] = useState<boolean>(false);

  const [upsertCreditApp] = useMutation<{ creditAppUpsert: Deal }>(creditAppUpsert);
  const [automateCreditApp] = useMutation(creditAppAutomate);

  const initialValues: Deal = useMemo(
    () => ({
      ...deal,
      financial_info: deal.financial_info ?? new FinancialInfo(deal.id),
      cobuyer: deal.cobuyer || new Cobuyer(),
      addCobuyer: !!deal.cobuyer,
      contact: deal.contact || new DealContact(deal.id),
      addContact: !!deal.contact,
      second_contact: deal.second_contact || new DealContact(deal.id),
      addSecondContact: !!deal.second_contact,
    }),
    // `deal` here, as a dependency, breaks the autosave: updates with previous values if the user continues editing while autosaving.
    // To recalculate initial values, you can use `modals` or the specific deal value you want to update.
    [modals],
  );

  const onSave = async ({
    creditAppFormValues,
    setFieldValue,
    autoStructure = false,
  }: OnSaveProps) => {
    setSaving(true);
    const cleanedValues = cleanDeal({ values: creditAppFormValues, deal });

    try {
      const res = await upsertCreditApp({
        variables: {
          deal: cleanedValues,
        },
      });
      if (!res.data?.creditAppUpsert) {
        throw new Error('No data returned from creditAppUpsert');
      }

      toast.success('Credit Application saved successfully.');

      // Avoids multiple inserts and "not a child of model" Objection error related to cobuyer and address.
      setInsertedIds(creditAppFormValues, false, setFieldValue, res);
      setInsertedIds(creditAppFormValues, true, setFieldValue, res);
      dispatch({ type: DealActionsEnum.UpdateDeal, payload: res.data.creditAppUpsert });

      if (autoStructure) {
        automateCreditApp({
          variables: {
            deal_id: deal.id,
          },
        });
      }
    } catch (e) {
      const error = e as Error;
      logger.error('CreditApplication.tsx', '', null, error);
      toast.error('Error while saving Credit Application. Please refresh and try again.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Formik
      validate={(values) => passValuesToSchema(values, validationSchema)}
      onSubmit={() => undefined}
      initialValues={initialValues}
      validateOnMount
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        showInsideModal ? (
          <Modal
            show={modals.CustomerInfo}
            backdrop="static"
            onHide={() => (setModal ? setModal({ CustomerInfo: false }) : false)}
            keyboard={false}
            dialogClassName="credit-app-modal"
            centered
          >
            <Modal.Header
              onHide={() => onSave({ creditAppFormValues: values, setFieldValue })}
              closeButton
            >
              <Modal.Title className="text-center w-100">Customer Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreditApplicationForm
                isSaving={isSaving}
                onSave={onSave}
                closeModal={() => setModal({ CustomerInfo: false })}
              />
            </Modal.Body>
          </Modal>
        ) : (
          <CreditApplicationForm
            isSaving={isSaving}
            onSave={onSave}
            showPrequalification={values.state === DealStateEnum.SoftClose}
          />
        )
      }
    </Formik>
  );
};

export default CreditApplication;
