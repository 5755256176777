import React from 'react';
import { Button, Text, Icon, Flex } from '@chakra-ui/react';
import { MdWarning } from 'react-icons/md';
import Modal from '../shared/Modal';

import { MileageWarningsEnum } from '../../gql/carGql';

interface Props {
  onSuccess: () => void;
  isOpen: boolean;
  onClose: () => void;
  status?: MileageWarningsEnum;
  warning?: string;
}

const shouldShowWarningIcon = (warning: MileageWarningsEnum | undefined) =>
  warning === MileageWarningsEnum.HighMileage || warning === MileageWarningsEnum.LowMileage;

const MileageWarningModal: React.FC<Props> = ({ onSuccess, isOpen, onClose, status, warning }) => (
  <Modal
    title="Mileage Warning"
    isOpen={isOpen}
    onClose={onClose}
    isCentered
    rightButtons={
      <>
        {status !== MileageWarningsEnum.LowerThanReported && (
          <Button variant="secondary" onClick={onSuccess} mr={2}>
            YES, CONTINUE
          </Button>
        )}
        <Button onClick={onClose}>REVIEW MILEAGE</Button>
      </>
    }
  >
    <Flex alignItems="center">
      {shouldShowWarningIcon(status) && <Icon mb={1} mr={2} as={MdWarning} />}
      <Text>{warning ?? status}</Text>
    </Flex>
  </Modal>
);

export default MileageWarningModal;
