import React, { Dispatch, SetStateAction, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button, FormControl, HStack, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Car } from '../../gql/carGql';
import { updateInsuranceInfo } from '../../gql/customerGql';
import { Deal } from '../../gql/dealGql';
import { useUpdateCarMutation } from '../../gql/generated/graphql';

import { InsuranceForm } from '../CreditApplication/components/InsuranceForm';
import DatePicker from '../shared/DatePicker';
import FormErrorContainer from '../shared/FormErrorContainer';
import Modal from '../shared/Modal';

import { DealContext } from '../../libs/DealContext';
import { createDateISO, isDateLessThanNumDaysAway } from '../../utils/dates';

interface ModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  paperworkType: string;
  selectPaperwork: (
    paperworkType: string,
    dealValues: Deal,
    beenNotifiedOfRegistration?: boolean,
  ) => void;
  loading: boolean;
  needToUpdateCarRegistrationExpiration: boolean;
}

const InsuranceModal: React.FC<ModalProps> = ({
  showModal,
  setShowModal,
  paperworkType,
  selectPaperwork,
  loading,
  needToUpdateCarRegistrationExpiration,
}) => {
  const [updateInsurance] = useMutation(updateInsuranceInfo);
  const [updateCarRegistrationExpiration] = useUpdateCarMutation();
  const { deal } = useContext(DealContext);

  const ninetyDaysWarning =
    'This registration expires in less than 90 days. The customer will need to renew their registration to avoid being impounded or experiencing other issues in titling.';

  const nextPaymentWarning =
    'The buyer will still be responsible for the next payment to avoid any penalties.';

  const additionalInfoValidationSchema = Yup.object().shape({
    customer: Yup.object().shape({
      proof_of_insurance: Yup.object().shape({
        company_name: Yup.string().nullable().required('Insurance Company is required'),
        policy_number: Yup.string().nullable().required('Policy No. is required'),
        expires: Yup.date().nullable().required('Expiration Date is required'),
      }),
    }),
    car: needToUpdateCarRegistrationExpiration
      ? Yup.object().shape({
          registration_expiration: Yup.date()
            .nullable()
            .required('Registration Expiration Date is required'),
        })
      : Yup.object().shape({
          registration_expiration: Yup.date().nullable(),
        }),
  });

  const updateCarRegistrationExpirationDate = (car: Car) => {
    return updateCarRegistrationExpiration({
      variables: {
        car: {
          id: deal.car.id,
          registration_expiration: car.registration_expiration,
        },
      },
    });
  };
  const canSubmit = (enteredValues: Deal) => {
    // Submit only when form is filled
    const canSubmitInsuranceValues =
      !deal.customer?.proof_of_insurance?.company_name ||
      !deal.customer?.proof_of_insurance?.policy_number ||
      !deal.customer?.proof_of_insurance?.expires
        ? !!enteredValues?.customer?.proof_of_insurance?.company_name &&
          !!enteredValues?.customer?.proof_of_insurance?.policy_number &&
          !!enteredValues?.customer?.proof_of_insurance?.expires
        : true;

    const canSubmitCarRegistration = needToUpdateCarRegistrationExpiration
      ? !!enteredValues?.car?.registration_expiration
      : true;

    return canSubmitInsuranceValues && canSubmitCarRegistration;
  };

  const handleSubmit = async (values: Deal) => {
    if (
      values.customer?.id &&
      values.customer?.proof_of_insurance?.company_name &&
      values.customer?.proof_of_insurance?.policy_number &&
      values.customer?.proof_of_insurance?.expires
    ) {
      const date = createDateISO(values.customer.proof_of_insurance.expires);
      await updateInsurance({
        variables: {
          id: values.customer.id,
          insurance_company: values.customer.proof_of_insurance.company_name,
          policy_no: values.customer.proof_of_insurance.policy_number,
          expiration_date: date,
        },
      });
    }

    if (values.car.registration_expiration) {
      await updateCarRegistrationExpirationDate(values.car);
    }
    selectPaperwork(
      paperworkType,
      { ...deal, customer: values.customer, car: values.car },
      canSubmit(values),
    );
  };

  return (
    <Modal
      title="Additional Information Required"
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size="2xl"
    >
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={deal}
        validationSchema={additionalInfoValidationSchema}
      >
        {({ touched, values }) => {
          return (
            <Form>
              <Text color="black" align="left">
                In order to generate the final documents and ensure no issues in completing the
                title process, the following additional information is required to proceed:
              </Text>
              {!deal.customer?.proof_of_insurance?.company_name ||
              !deal.customer?.proof_of_insurance?.policy_number ||
              !deal.customer?.proof_of_insurance?.expires ? (
                <InsuranceForm
                  name="customer"
                  variant="column"
                  showHeader={false}
                  margin={4}
                  width="95%"
                />
              ) : null}
              {needToUpdateCarRegistrationExpiration && (
                <FormControl margin={4} width="95%">
                  <DatePicker
                    topLabel="Registration Expiration"
                    name="car.registration_expiration"
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    labelStyles={{ color: 'black', fontWeight: 'bold' }}
                  />
                  {(isDateLessThanNumDaysAway(90, values.car.registration_expiration) ||
                    (deal.car.registration_expiration &&
                      !touched?.car?.registration_expiration)) && (
                    <FormErrorContainer mt={4}>
                      <Text fontSize={14} fontWeight="normal">
                        {ninetyDaysWarning}
                      </Text>
                    </FormErrorContainer>
                  )}
                </FormControl>
              )}

              {isDateLessThanNumDaysAway(10, values.car.payoff.next_payment_date) ? (
                <FormErrorContainer margin={4} width="95%">
                  <Text fontSize={14} fontWeight="normal">
                    {nextPaymentWarning}
                  </Text>
                </FormErrorContainer>
              ) : null}

              <HStack width="95%" mt={4} spacing="auto" mx="auto">
                <Button variant="warning" onClick={() => setShowModal(false)}>
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  isLoading={loading}
                  loadingText="SUBMIT"
                  isDisabled={!canSubmit(values)}
                >
                  SUBMIT
                </Button>
              </HStack>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default InsuranceModal;
