import { FC, useState } from 'react';

import { Tab, TabList, Tabs } from '@chakra-ui/react';

import FinancialSpecialist from './FinancialSpecialist';
import TeamsTable from './TeamsTable';
import UsersTable from './UsersTable';

const TeamsManager: FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(1);

  return (
    <>
      <Tabs
        index={selectedTabIndex}
        onChange={(index) => setSelectedTabIndex(index)}
        variant="enclosed"
      >
        <TabList pt={1} mb={5} borderBottom="1px">
          <Tab
            bgColor={selectedTabIndex === 0 ? 'white' : 'gray.200'}
            borderRadius="8px 8px 0 0"
            fontWeight="bold"
            ml={1}
            px={8}
          >
            Users
          </Tab>

          <Tab
            bgColor={selectedTabIndex === 1 ? 'white' : 'gray.200'}
            borderRadius="8px 8px 0 0"
            fontWeight="bold"
            ml={1.5}
            px={8}
          >
            Teams
          </Tab>

          <Tab
            bgColor={selectedTabIndex === 2 ? 'white' : 'gray.200'}
            borderRadius="8px 8px 0 0"
            fontWeight="bold"
            ml={1.5}
            px={8}
          >
            Financial Specialists
          </Tab>
        </TabList>
      </Tabs>
      {selectedTabIndex === 0 && <UsersTable />}
      {selectedTabIndex === 1 && <TeamsTable />}
      {selectedTabIndex === 2 && <FinancialSpecialist />}
    </>
  );
};

export default TeamsManager;
