import { useMemo } from 'react';

import { Button } from '@chakra-ui/react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { InferType } from 'yup';

import {
  HuntGroup,
  RoleIdEnumType,
  useCreateUserMutation,
  useGetUserCreationDataQuery,
} from '../../../gql/generated/graphql';
import { Pod } from '../../../gql/podGql';

import Modal from '../../../components/shared/Modal';
import { createUserValidationSchema } from '../utils';

import CreateUserModalForm from './CreateUserModalForm';

import { RemoveIndex } from '../../../utils/types';

export type CreateUserModalFormikFields = RemoveIndex<InferType<typeof createUserValidationSchema>>;

interface CreateUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateUserModal = ({ isOpen, onClose }: CreateUserModalProps) => {
  const { data } = useGetUserCreationDataQuery({
    skip: !isOpen,
  });

  const [createUser, { loading }] = useCreateUserMutation();
  const handleCloseModal = () => {
    onClose();
  };

  const initialValues = useMemo(() => {
    const fields: CreateUserModalFormikFields = {
      pods: [],
      hunt_groups: [],
      auth0_roles: [],
      email: '',
      password: '',
      phone_number: '',
      name: '',
    };
    return fields;
  }, []);

  const onSave = async (
    values: CreateUserModalFormikFields,
    formikHelpers: { resetForm: () => void },
  ) => {
    try {
      const user = await createUser({
        variables: {
          createUserInput: {
            email: values.email,
            password: values.password,
            phone_number: values.phone_number,
            name: values.name,
            pod_ids: values.pods?.map((pod) => pod.id),
            hunt_group_ids: values.hunt_groups?.map((huntGroup) => huntGroup.id),
            auth0_roles: values.auth0_roles?.map((role) => role.id) as RoleIdEnumType[],
          },
        },
      });

      if (user?.data?.createUser) {
        toast.success('User created successfully');
      } else {
        toast.error('Failed to create user');
        return;
      }

      handleCloseModal();
      formikHelpers.resetForm();
    } catch (e) {
      const error = e as Error;
      toast.error(error.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createUserValidationSchema}
      validateOnMount
      onSubmit={onSave}
      enableReinitialize
    >
      {({ isValid, resetForm, values }) => {
        return (
          <Modal
            title="Create New User"
            size="3xl"
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              handleCloseModal();
              resetForm();
            }}
            leftButtons={
              <Button
                variant="warning"
                onClick={() => {
                  handleCloseModal();
                  resetForm();
                }}
              >
                CANCEL
              </Button>
            }
            rightButtons={
              <Button
                variant="primary"
                type="submit"
                isDisabled={!isValid}
                isLoading={loading}
                onClick={async () => {
                  await onSave(values, { resetForm });
                }}
              >
                ADD USER
              </Button>
            }
          >
            <CreateUserModalForm
              huntGroups={(data?.getUserCreationData?.huntGroups || []) as HuntGroup[]}
              pods={(data?.getUserCreationData?.pods || []) as Pod[]}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreateUserModal;
