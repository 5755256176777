import { ApolloClient } from '@apollo/client';

import { DealMediaTypeEnum, useMediaInsertMutation } from '../gql/generated/graphql';
import { DealMedia, DealMediaEdit, GetUploadUrlResult, getUploadUrl } from '../gql/mediaGql';

import { getFileNameAndExtension, normalizeFileName } from './files';

export const insertMediaByUrl = async (
  selectedMedia: DealMediaEdit[],
  dealId: number,
  client: ApolloClient<object>,
  mediaInsert: ReturnType<typeof useMediaInsertMutation>[0],
) => {
  if (dealId && selectedMedia.length) {
    await Promise.all(
      selectedMedia.map(async (media) => {
        const { data } = await client.query<GetUploadUrlResult>({
          query: getUploadUrl,
          variables: {
            dealId,
            fileName: normalizeFileName(`${media.fileName}.${media.extension}`),
          },
        });

        const { url, key } = data.getUploadUrl;

        await fetch(url, {
          method: 'PUT',
          body: media.selectedFile,
        });

        return mediaInsert({
          variables: {
            key,
            dealId,
            type: media.type,
          },
        });
      }),
    );
  }
};

export const getDealMediaFromAcceptedFiles = async (acceptedFiles: File[]) =>
  Promise.all(
    acceptedFiles.map(async (file) => {
      const normalizedFileName = normalizeFileName(file.name);
      const { fileName, extension } = getFileNameAndExtension(normalizedFileName);

      return {
        selectedFile: file,
        sourceFileName: normalizedFileName,
        fileName,
        extension,
        type: DealMediaTypeEnum.PayoffDocs,
      } as DealMediaEdit;
    }),
  );

export const isFileDeletable = (file: DealMedia) =>
  !file.has_verified_digital_signature &&
  !file.has_wet_signature &&
  !file.is_notarized &&
  file.type !== DealMediaTypeEnum.R1EContract &&
  file.type !== DealMediaTypeEnum.SignedDocs;
