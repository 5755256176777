import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { DealContext } from '../libs/DealContext';
import { NotesContext } from '../libs/contextLib';
import { Note, notesFetch } from '../gql/noteGql';

interface NotesProps {
  children: React.ReactNode;
}

const Notes: React.FC<NotesProps> = ({ children }: NotesProps) => {
  const { deal } = useContext(DealContext);
  const [notes, setNotes] = useState<Note[]>([]);
  const notesGql = useQuery(notesFetch, {
    variables: {
      dealId: deal.id,
    },
    skip: !deal.id,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (notesGql.data?.notes) {
      setNotes(notesGql.data.notes);
    }
  }, [notesGql.data]);

  return (
    <NotesContext.Provider value={{ notes, notesRefetch: notesGql.refetch }}>
      {children}
    </NotesContext.Provider>
  );
};

export default Notes;
