import { useContext, useMemo, useState } from 'react';

import { Box, HStack, Icon, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
  MdCheckCircle,
  MdDoNotDisturbOn,
  MdInfo,
  MdOutlineError,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdOutlineWarning,
} from 'react-icons/md';

import { PermissionEnum } from '../../../../constants/permissions';
import { AbilityContext } from '../../../../libs/contextLib';
import { PrequalCheckStatusType, creditScoreTierRanges } from '../../../../utils/prequalification';

const iconMap: Record<PrequalCheckStatusType, IconType> = {
  ideal: MdCheckCircle,
  warning: MdOutlineError,
  critical: MdOutlineError,
  failed: MdDoNotDisturbOn,
  noData: MdOutlineWarning,
  frozen: MdOutlineWarning,
  locked: MdOutlineWarning,
};

export const creditScoreColorMap: Record<PrequalCheckStatusType, string> = {
  ideal: 'green',
  warning: 'primary',
  critical: 'americanOrange',
  failed: 'errorsRed',
  noData: 'americanOrange',
  frozen: 'americanOrange',
  locked: 'americanOrange',
};

interface Props {
  label: string;
  value: string;
  status: PrequalCheckStatusType;
  statusNotes: string[] | undefined;
  isCreditRow?: boolean;
  removeCobuyerNotes?: boolean;
  creditScoreNumber?: number;
}

const PrequalChecksRow = ({
  label,
  value,
  status,
  statusNotes,
  isCreditRow = false,
  removeCobuyerNotes = false,
  creditScoreNumber = 0,
}: Props) => {
  const abilities = useContext(AbilityContext);

  const [showCreditScoreNumber, setShowCreditScoreNumber] = useState(false);
  const valueToShow = showCreditScoreNumber ? creditScoreNumber : value;
  const tooltipLabel = useMemo(() => {
    if (!isCreditRow) {
      return '';
    }

    const creditScoreRange = creditScoreTierRanges.find(
      (range) => creditScoreNumber >= range.min && creditScoreNumber <= range.max,
    );

    return creditScoreRange
      ? `${value} tier will include a Credit Score range of ${creditScoreRange.min}-${creditScoreRange.max}`
      : 'No Credit Score range';
  }, [isCreditRow, creditScoreNumber, value]);

  const filteredNotes = statusNotes?.filter((note) =>
    removeCobuyerNotes ? !note.includes('co-buyer') : note,
  );

  return (
    <Stack>
      <HStack>
        <Icon as={iconMap[status]} boxSize={6} color={creditScoreColorMap[status]} />
        <Text w="350px" fontWeight="bold">
          {label}
        </Text>
        <Text
          w={180}
          color={isCreditRow ? creditScoreColorMap[status] : undefined}
          fontWeight={isCreditRow ? 'bold' : undefined}
        >
          {valueToShow !== 'No Response' ? valueToShow : 'No Credit Score'}
        </Text>
        <Box w={8}>
          {isCreditRow ? (
            abilities.has(PermissionEnum.ViewCreditScore) ? (
              <IconButton
                variant="iconHover"
                size="xs"
                h="full"
                icon={
                  showCreditScoreNumber ? (
                    <MdOutlineVisibilityOff size={15} />
                  ) : (
                    <MdOutlineVisibility size={15} />
                  )
                }
                onClick={() => setShowCreditScoreNumber(!showCreditScoreNumber)}
                aria-label="Show/Hide exact value"
              />
            ) : (
              <Tooltip
                placement="top"
                hasArrow
                width="150px"
                label={tooltipLabel}
                backgroundColor="gray.700"
                borderRadius="5px"
                marginX={2}
                textAlign="center"
              >
                <Box>
                  <MdInfo />
                </Box>
              </Tooltip>
            )
          ) : null}
        </Box>
      </HStack>
      {filteredNotes?.map((note) => (
        <HStack key={note} m={0} h="auto" spacing="auto ">
          <Text ml={8} fontWeight="normal">
            {`• ${note}`}
          </Text>
        </HStack>
      ))}
    </Stack>
  );
};

export default PrequalChecksRow;
