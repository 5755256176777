import React from 'react';

import { Badge } from '@chakra-ui/react';

interface Props {
  notificationCount: number;
  bgColor: string;
}

const NotificationBadge: React.FC<Props> = ({ notificationCount, bgColor }) => {
  if (notificationCount === 0) {
    return null;
  }

  return (
    <Badge px={2} color="white" borderRadius="3xl" bgColor={bgColor}>
      {notificationCount}
    </Badge>
  );
};

export default NotificationBadge;
