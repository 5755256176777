import { FC, useContext, useState } from 'react';

import { Text } from '@chakra-ui/react';
import Big from 'big.js';
import { MdOutlineAttachMoney } from 'react-icons/md';

import { Customer, getFullName } from '../../gql/customerGql';
import {
  PayoffRequestStatus,
  PayoffRequestStatusUpdate,
  useOnPayoffRequestStatusUpdateSubscription,
} from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

import { InteractedContext } from '../../libs/contextLib';
import { formatDateIfTrue, formatMoney } from '../../libs/utils';
import { capitalizeFirstLetter } from '../../utils/text';

const PayoffRequestHandler: FC = () => {
  const [payoffRequests, setPayoffRequests] = useState<PayoffRequestStatusUpdate[]>([]);
  const hasInteracted = useContext(InteractedContext);

  useOnPayoffRequestStatusUpdateSubscription({
    onData: ({ data }) => {
      if (!data.data?.onPayoffRequestStatusUpdate) {
        return;
      }

      const newPayoffRequest = data.data.onPayoffRequestStatusUpdate;
      if (
        newPayoffRequest.status !== PayoffRequestStatus.Cancelled &&
        newPayoffRequest.status !== PayoffRequestStatus.Pending
      ) {
        setPayoffRequests([...payoffRequests, newPayoffRequest]);
      }

      if (hasInteracted) {
        new Audio('/sounds/sms_notification.mp3').play();
      }
    },
  });

  return (
    <>
      {payoffRequests.map((payoffRequest) => {
        const title = `${getFullName(
          payoffRequest?.deal?.customer as unknown as Customer,
        )} - Deal ${payoffRequest?.deal?.id}`;

        const { vehicle_payoff, sales_tax, good_through_date, deal } = payoffRequest;
        const { payoff_includes_sales_tax } = deal?.car?.payoff || {};
        const totalPayoff = new Big(vehicle_payoff || 0).plus(sales_tax || 0).toNumber();
        const salesTaxIncluded = payoff_includes_sales_tax
          ? `Yes, ${formatMoney(sales_tax || 0)}`
          : 'No';

        return (
          <NotificationToast
            key={`${payoffRequest.id}_${payoffRequest.status}`}
            dealId={payoffRequest.deal_id ?? null}
            slideFadeIn={!!payoffRequests.length}
            title={title}
            titleIcon={MdOutlineAttachMoney}
            titleIconColor="green"
            subTitle={`Payoff Request ${capitalizeFirstLetter(payoffRequest.status ?? '')}`}
            details={
              payoffRequest.status === PayoffRequestStatus.Completed ? (
                <>
                  <Text>{`Total Payoff: ${formatMoney(totalPayoff)}`}</Text>
                  <Text>{`Vehicle Payoff: ${formatMoney(vehicle_payoff as number)}`}</Text>
                  <Text>{`Sales Tax Included: ${salesTaxIncluded}`}</Text>
                  <Text>{`Good Through: ${formatDateIfTrue(good_through_date)}`}</Text>
                </>
              ) : payoffRequest.status === PayoffRequestStatus.Failed ? (
                <>
                  <Text>{`Reason: ${payoffRequest?.fail_reason?.reason}`}</Text>
                  <Text>{`Comment: ${payoffRequest?.fail_reason?.comment}`}</Text>
                </>
              ) : null
            }
            onClose={() =>
              setPayoffRequests(payoffRequests.filter((pr) => pr.id !== payoffRequest.id))
            }
          />
        );
      })}
    </>
  );
};

export default PayoffRequestHandler;
