import { FC } from 'react';

import { Avatar, Text, Tooltip, WrapItem, WrapItemProps } from '@chakra-ui/react';

import { User } from '../../../gql/userGql';

interface UserAvatarProps extends WrapItemProps {
  assignedRole?:
    | 'Advisor'
    | 'Financial Specialist'
    | 'Financial Specialist 2'
    | 'Structuring Manager'
    | 'Accounting Clerk'
    | 'Title Clerk'
    | 'Reg Assistant';
  user?: User;
}

const UserAvatar: FC<UserAvatarProps> = ({ assignedRole, user, ...rest }) => {
  if (!user) {
    return null;
  }

  const podList = user.pods || [];
  const userColor = user.pods && user.pods.length ? user.pods[0].color : 'gray';

  return (
    <WrapItem {...rest}>
      <Tooltip
        label={
          <span>
            {user.name}
            {assignedRole ? ` - ${assignedRole}` : ''}
            {podList.map((pod) => (
              <Text key={pod.name}>{pod.name}</Text>
            ))}
          </span>
        }
      >
        <Avatar
          size="sm"
          bg={userColor}
          color="white"
          showBorder
          _hover={{
            transform: 'scale(1.1)',
            transition: 'transform 0.2s',
            zIndex: 1,
          }}
          name={user.name}
        />
      </Tooltip>
    </WrapItem>
  );
};

export default UserAvatar;
