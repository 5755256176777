import { FC, useContext } from 'react';

import { CheckIcon } from '@chakra-ui/icons';
import { Avatar, Box, IconButton } from '@chakra-ui/react';
import { MdOutlineTimer } from 'react-icons/md';

import { DealStateEnum } from '../../gql/dealGql';

import { DealContext } from '../../libs/DealContext';

interface FollowUpButtonProps {
  onOpen: () => void;
  hasFollowUp?: boolean;
}

const FollowUpButton: FC<FollowUpButtonProps> = ({ onOpen, hasFollowUp }) => {
  const { deal } = useContext(DealContext);

  const hiddenDealStates = [DealStateEnum.Booted, DealStateEnum.Finalized];

  return (
    <Box pos="relative" hidden={hiddenDealStates.includes(deal.state)}>
      <IconButton
        aria-label={`${hasFollowUp ? 'edit' : 'add'} follow-up`}
        rounded="full"
        size="lg"
        variant="secondary"
        onClick={onOpen}
        icon={<MdOutlineTimer size={24} />}
      />

      {hasFollowUp ? (
        <Avatar
          icon={<CheckIcon />}
          size="xs"
          bg="tealGreen"
          pos="absolute"
          top={-1.5}
          right={-1.5}
        />
      ) : null}
    </Box>
  );
};

export default FollowUpButton;
