import { Td } from '@chakra-ui/react';

import { GetComponentParams, tableViewColumns, TABLE_HEADERS } from '.';
import { getDealAgeColor } from '../../../../utils/deals';

const DealAge = ({ deal }: GetComponentParams) => {
  const dealAge = tableViewColumns[TABLE_HEADERS.AGE].getValue(deal) || '';
  return (
    <Td
      bgColor={getDealAgeColor(parseInt(dealAge, 10))}
      p={0}
      w={2}
      color="white"
      textAlign="center"
      borderBottom="4px solid white"
      position="relative"
    >
      {dealAge}
    </Td>
  );
};

export default DealAge;
