import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Flex, HStack, Progress, Text } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { skipTimelineStep } from '../../../gql/dealGql';
import {
  DocumentProgressStatus,
  Deal as GeneratedDeal,
  useOnContractValidationSubscription,
} from '../../../gql/generated/graphql';

import MarkAsSentButton from '../../../components/DealInfoBuyout/components/buttons/MarkAsSentButton';
import GenerateDocumentsButton from '../../../components/GenerateDocs/GenerateDocumentsButton';
import ValidateBankContractsButton from '../../../components/GenerateDocs/ValidateBankContractButton';
import SignatureUploadModalButton from '../../../components/SignatureUploadModalButton/SignatureUploadModalButton';
import SigningModalButton from '../../../components/SigningModalButton/SigningModalButton';
import Card from '../../../components/shared/Card';
import CardHeaderV2 from '../../../components/shared/Card/components/CardHeaderV2';
import MarkAsSigned from '../ProgressionButtons/MarkAsSigned';

import TimelineIcon from './TimelineIcon';

import { PermissionEnum } from '../../../constants/permissions';
import { DealActionsEnum, DealContext } from '../../../libs/DealContext';
import { AbilityContext, NotesContext } from '../../../libs/contextLib';
import { getValidationMessage } from '../../../utils/routeOne';
import { parseOutHtmlEntityEncodedTags } from '../../../utils/text';

const ContractStatusTimeline: FC = () => {
  const { deal, dispatch } = useContext(DealContext);
  const status = deal.document_progress_status;
  const { notesRefetch } = useContext(NotesContext);
  const abilities = useContext(AbilityContext);

  const width = '100px';

  const [r1ContractValidationStatus, setR1ContractValidationStatus] = useState(status);
  const [validationErrors, setValidationErrors] = useState<(string | null | undefined)[]>(
    deal.r1_contract_validation_errors_object?.errors ?? [],
  );
  const [validationWarnings, setValidationWarnings] = useState<(string | null | undefined)[]>(
    deal.r1_contract_validation_warnings_object?.warnings ?? [],
  );

  const [steps] = useState([1, 2, 3, 4, 5, 6]);

  const [skipTimelineStepMutation] = useMutation(skipTimelineStep);

  useEffect(() => {
    setR1ContractValidationStatus(status);
  }, [status]);

  const getStep = () => {
    switch (r1ContractValidationStatus) {
      case DocumentProgressStatus.GenerateDocuments:
        return 2;
      case DocumentProgressStatus.UploadDocuments:
        return 3;
      case DocumentProgressStatus.ReadyForSignatures:
        return 4;
      case DocumentProgressStatus.SentForSignatures:
        return 5;
      case DocumentProgressStatus.Signed:
        return 6;
      default:
        return 1;
    }
  };

  const setValuesAndDispatch = async (updatedDeal: GeneratedDeal) => {
    const newStatus = (updatedDeal.document_progress_status as DocumentProgressStatus) ?? status;
    const newErrors = updatedDeal.r1_contract_validation_errors_object?.errors ?? [];
    const newWarnings = updatedDeal.r1_contract_validation_warnings_object?.warnings ?? [];
    const newManuallyValidated = !!updatedDeal.r1_contract_manually_validated;

    setR1ContractValidationStatus(newStatus);
    setValidationErrors(parseOutHtmlEntityEncodedTags(newErrors));
    setValidationWarnings(parseOutHtmlEntityEncodedTags(newWarnings));

    dispatch({
      type: DealActionsEnum.UpdateDeal,
      payload: {
        document_progress_status: newStatus,
        r1_contract_validation_errors_object: { errors: newErrors as string[] },
        r1_contract_validation_warnings_object: { warnings: newWarnings as string[] },
        r1_contract_manually_validated: newManuallyValidated,
      },
    });

    // if validation was skipped, a new note was created
    await notesRefetch();
  };

  useOnContractValidationSubscription({
    variables: {
      deal_id: deal.id,
    },
    fetchPolicy: 'network-only',
    skip: !deal.id,
    onData: async ({ data }) => {
      const deals = data.data?.onContractValidation;
      if (deals && deals[0]) {
        await setValuesAndDispatch(deals[0]);
      }
    },
  });

  const validationInfo = useMemo(() => {
    const { PendingLender, Failed } = DocumentProgressStatus;

    switch (r1ContractValidationStatus) {
      case PendingLender: {
        const newLienholderName = deal.financial_info.new_lienholder?.name || 'Lienholder';

        return [`${newLienholderName} validation pending`];
      }
      case Failed:
        return parseOutHtmlEntityEncodedTags(validationErrors);
      default:
        return parseOutHtmlEntityEncodedTags(validationWarnings);
    }
  }, [deal, r1ContractValidationStatus, validationErrors, validationWarnings]);

  const handleSkip = async () => {
    try {
      const result = await skipTimelineStepMutation({
        variables: {
          jacketId: deal.r1_jacket_id,
          documentProgressStatus: deal.document_progress_status,
        },
      });

      if (result.data?.skipTimelineStep) {
        await setValuesAndDispatch(result.data.skipTimelineStep);
        toast.success('Success');
      }
    } catch (e) {
      const error = e as Error;
      toast.error(error.message);
    }
  };

  return (
    <Card variant="rounded" pb={0}>
      <CardHeaderV2 title="Contract Status" variant="rounded" pod={deal.pod} showPodColor />
      <Flex overflowX="auto">
        <Box minW="580px" mb={4} w="full">
          <Flex margin={5} justifyContent="space-between">
            <ValidateBankContractsButton width={width} />
            <GenerateDocumentsButton useMiniButton width={width} />
            <SignatureUploadModalButton width={width} />
            <MarkAsSentButton width={width} />
            <SigningModalButton width={width} />
            <MarkAsSigned width={width} />
          </Flex>
          <Flex
            marginX="60px"
            marginY="0px"
            justifyContent="space-between"
            alignItems="center"
            height={8}
            textAlign="center"
          >
            {steps.map((step) => (
              <TimelineIcon
                key={step}
                position={step}
                step={getStep()}
                canSkip={
                  (abilities.has(PermissionEnum.SkipContractValidation) && step === 1) ||
                  (abilities.has(PermissionEnum.SkipGenerateAndUpload) &&
                    (step === 2 || step === 3))
                }
                handleSkip={handleSkip}
              />
            ))}
          </Flex>
          <Progress
            margin="-18px 60px 25px 65px"
            height="5px"
            colorScheme="caribbeanGreen"
            bgColor="black"
            value={(getStep() - 1) * (100 / 5)} // Each step is 1/5 of the progress bar
          />
          <HStack alignItems="start">
            <Text ml={6} fontWeight="bold" fontSize="18" color="black" whiteSpace="nowrap">
              Worksheet Status:
            </Text>
            <Text fontWeight="bold" fontSize="18" color={getValidationMessage(deal).textColor}>
              {`Validation ${getValidationMessage(deal).copy}`}
            </Text>
          </HStack>

          {validationInfo.map((listItem: string | undefined | null) => {
            return (
              <HStack>
                <Text fontWeight="normal" fontSize="14px" color="black" ml={16} mt={2} pr={16}>
                  <FaCircle size={5} /> {listItem}
                </Text>
              </HStack>
            );
          })}
        </Box>
      </Flex>
    </Card>
  );
};

export default ContractStatusTimeline;
