import { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Box, Button, HStack, Input, Text } from '@chakra-ui/react';
import { MdPhoneForwarded } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import {
  GetAvailableUsers,
  useAddParticipantMutation,
  useCallOutMutation,
  useGetAvailableUsersAndAllHuntGroupsQuery,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import { HuntGroupSection } from './HuntGroupSection';
import { UsersCallList } from './UsersCallList';

import { useCallsContext } from '../../libs/callsContext';
import { formatPhoneNumberForDisplay } from '../../libs/utils';

interface TransferCallModalProps {
  isOutboundCallModal?: boolean;
  showModal?: boolean;
  closeOutboundModal?: Dispatch<SetStateAction<boolean>>;
}

const TransferCallModal = ({
  isOutboundCallModal,
  showModal,
  closeOutboundModal,
}: TransferCallModalProps) => {
  const { twilioNumber, isVisibleTransferCallModal, handleTransferCallModal, current } =
    useCallsContext();
  const [callOutbound] = useCallOutMutation();
  const [dialedNumber, setDialedNumber] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  const [addParticipant] = useAddParticipantMutation();

  const closeModal = () => {
    if (isOutboundCallModal && closeOutboundModal) {
      closeOutboundModal(false);
    }
    handleTransferCallModal(false);
  };

  const onChangeDialedNumber = (event: ChangeEvent<HTMLInputElement>) => {
    setDialedNumber(event.target.value);
  };

  const onPressTransfer = async (newUser: GetAvailableUsers | string) => {
    try {
      const new_agent_phone =
        typeof newUser === 'string' ? newUser : newUser.phone_number || newUser.twilio_number;
      const new_agent_user_id = typeof newUser === 'string' ? null : newUser.id;
      const message =
        typeof newUser === 'string' ? formatPhoneNumberForDisplay(newUser) : newUser.name;

      await addParticipant({
        variables: {
          conference_id: current?.conferenceId,
          agent_phone: twilioNumber,
          new_agent_phone,
          new_agent_user_id,
        },
      });
      toast.success(`Call transferred to ${message}`);
    } catch (error) {
      toast.error('Failed in transfer call');
    }
  };

  const onTransferFromDial = (phoneNum?: string) => {
    const transferTo = (phoneNum ?? dialedNumber).replace(/[\D]/g, '');
    if (!transferTo || transferTo.length !== 10) {
      toast.error('Invalid phone number');
      return;
    }

    onPressTransfer(transferTo);
    closeModal();
  };

  const callDialedNumber = async (phoneNum?: string) => {
    const callOutTo = (phoneNum ?? dialedNumber).replace(/[\D]/g, '');
    if (!callOutTo || callOutTo.length !== 10) {
      toast.error('Invalid phone number');
      return;
    }
    callOutbound({
      variables: {
        calledNumber: callOutTo,
      },
    });
    closeModal();
  };

  const callUserOutbound = async (newUser: GetAvailableUsers) => {
    try {
      if (newUser.twilio_number) {
        await callOutbound({
          variables: {
            calledNumber: newUser.twilio_number,
          },
        });
      }
      toast.success(`Called ${newUser.name}`);
    } catch (error) {
      toast.error('Outbound call failed');
    }
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const { data } = useGetAvailableUsersAndAllHuntGroupsQuery({
    fetchPolicy: 'network-only',
  });

  const huntGroups = useMemo<{ name: string; phone_number: string }[]>(() => {
    if (data && data?.getAllHuntGroups?.length) {
      return data.getAllHuntGroups
        .filter((hg) => hg?.phone_number && hg?.name)
        .map((hg) => ({
          name: hg?.name ?? '',
          phone_number: hg?.phone_number ?? '',
        }));
    }
    return [];
  }, [data?.getAllHuntGroups]);

  const filteredUsers = useMemo(() => {
    const digitSearch = search.replace(/[\D]/g, '');
    const availableUsers = (data?.getAvailableUsers as GetAvailableUsers[]) ?? [];
    return availableUsers.filter(
      (user) =>
        user?.name?.toLowerCase().includes(search.toLowerCase()) ||
        (digitSearch.length && user?.twilio_number?.includes(digitSearch)),
    );
  }, [data?.getAvailableUsers, search]);

  return (
    <Modal
      title={isOutboundCallModal ? 'Outbound Call' : 'Transfer Call To'}
      titleIcon={isOutboundCallModal ? undefined : MdPhoneForwarded}
      isOpen={showModal || isVisibleTransferCallModal}
      closeOnOverlayClick
      onClose={closeModal}
      size="2xl"
      variant="fullContent"
    >
      <Box m={3} pb={4} px={6} borderBottom="1px" borderColor="lightGray">
        <Text fontWeight="bold" fontSize={20}>
          Manual Dial
        </Text>
        <HStack spacing={6}>
          <Input
            maxW="50%"
            value={dialedNumber}
            onChange={onChangeDialedNumber}
            as={InputMask}
            mask="(999) 999-9999"
          />
          <Button
            variant={isOutboundCallModal ? 'primary' : 'secondary'}
            size="md"
            m={2}
            h={9}
            onClick={() => {
              if (isOutboundCallModal) {
                callDialedNumber();
                return;
              }
              onTransferFromDial();
            }}
          >
            {isOutboundCallModal ? 'Dial' : 'Transfer'}
          </Button>
        </HStack>
      </Box>
      <HuntGroupSection
        huntGroups={huntGroups}
        isOutboundCallModal={isOutboundCallModal}
        callHuntGroup={isOutboundCallModal ? callDialedNumber : onTransferFromDial}
      />
      <UsersCallList
        isOutboundCallModal={!!isOutboundCallModal}
        callUserOutbound={callUserOutbound}
        onTransferCall={onPressTransfer}
        search={search}
        onSearchChange={onSearchChange}
        userList={filteredUsers}
      />
    </Modal>
  );
};

export default TransferCallModal;
