import { FC, useContext } from 'react';

import { Button, Text } from '@chakra-ui/react';

import Modal from '../../../shared/Modal';

import { DealContext } from '../../../../libs/DealContext';

interface Props {
  handleMarkAsSent: (signingOnCom: boolean) => void;
  isOpen: boolean;
  onClose: () => void;
}

const SelectSigningModal: FC<Props> = ({ handleMarkAsSent, isOpen, onClose }) => {
  const {
    deal: { customer, cobuyer },
  } = useContext(DealContext);

  return (
    <Modal
      title="Send for Signatures"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      size="2xl"
      showDivider={false}
      rightButtons={
        <>
          <Button
            variant="secondary"
            onClick={() => {
              handleMarkAsSent(false);
              onClose();
            }}
          >
            Sign On Route One
          </Button>
          <Button
            onClick={() => {
              handleMarkAsSent(true);
              onClose();
            }}
          >
            Sign On Leaseend.com
          </Button>
        </>
      }
    >
      <Text mb={4} fontWeight="normal">
        Please verify the email(s) below with the customer(s) and ensure your contracts are fully
        prepared for signing.
      </Text>
      <Text mb={4} fontWeight="normal">
        If selecting to sign on web, no further action is necessary. If using Route One, ensure all
        contracts are validated and generated and trigger e-remote sign email in Route One.
      </Text>
      <Text marginX="50px" display="flex">
        <Text fontWeight="bold" w="20%">
          • Buyer:
        </Text>
        <Text justifySelf="end" fontWeight="normal">
          {customer.email}
        </Text>
      </Text>
      {cobuyer && (
        <Text marginX="50px" display="flex">
          <Text fontWeight="bold" w="20%">
            • CoBuyer:
          </Text>
          <Text justifySelf="end" fontWeight="normal">
            {cobuyer.email}
          </Text>
        </Text>
      )}
    </Modal>
  );
};

export default SelectSigningModal;
