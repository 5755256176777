import { useContext, useMemo } from 'react';

import { Button } from '@chakra-ui/react';
import { MdAddCircleOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import { DealStateEnum } from '../../../gql/dealGql';
import { useDealAssignMutation } from '../../../gql/generated/graphql';

import { useUser } from '../../../hooks/useUser';
import { DealContext } from '../../../libs/DealContext';
import { AbilityContext } from '../../../libs/contextLib';
import { handleClaimDealError } from '../../../utils/deals';
import { getClaimRole } from '../../../utils/permissions';

const ClaimButton = () => {
  const user = useUser();
  const abilities = useContext(AbilityContext);
  const { deal, dealRefetch } = useContext(DealContext);

  const [assignDeal] = useDealAssignMutation();

  // If the user is claiming the deal from the floor, move off the floor, else keep in same state
  const claimState = deal.state === DealStateEnum.Floor ? DealStateEnum.SoftClose : deal.state;

  const claimRole = useMemo(() => getClaimRole(deal, abilities, user), [deal, abilities, user]);
  if (claimRole === null) {
    return null;
  }

  const handleClaim = async () => {
    try {
      await assignDeal({
        variables: {
          deal_id: deal.id ?? 0,
          deal_state: claimState,
          role: claimRole,
        },
      });
      toast.success('Deal Assigned!');
    } catch (e: unknown) {
      handleClaimDealError(e);
    } finally {
      await dealRefetch();
    }
  };

  return (
    <Button
      size="lgWithIconLeft"
      id={`assign-deal-${deal.id}`}
      leftIcon={<MdAddCircleOutline />}
      onClick={handleClaim}
    >
      CLAIM DEAL
    </Button>
  );
};

export default ClaimButton;
