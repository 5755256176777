export enum CustomerInfo {
  VIN = 'VIN',
  ZIP = 'ZIP',
  DOB = 'DOB',
  SSN = 'SSN',
  SSN_LAST_6 = 'SSN_LAST_6',
  SSN_LAST_4 = 'SSN_LAST_4',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  CURRENT_ODOMETER = 'CURRENT_ODOMETER',
}

export type CustomerInfoValues = {
  [K in typeof CustomerInfo[keyof typeof CustomerInfo]]: string;
};

export type Option = CustomerInfo[];
export type Options = Option[];
export type PayoffMethod = { [key: string]: { value: string } | string };
export type PayoffMethods = { [key: string]: PayoffMethod };

export type LienholderPRSType = {
  id: number;
  display_name: string;
  slug: string;
  phone_numbers: [{ name: string; phone_numbers: [string] }];
  requirements_to_get_payoff: {
    id: string;
    options: string[][];
    get_payoff_methods: {
      [key: string]: { value: 'TRUE' | 'FALSE' } | string;
    };
  };
  requirements_to_payoff_lease: {
    [key: string]: { value: boolean };
  };
  required_documents_to_payoff_lease: {
    [key: string]: { value: boolean };
  };
  double_tax: boolean;
  probableLienholder: boolean;
  enabled: boolean;
};

export type LienholderPRSFormType = {
  phone_numbers: string[];
  payoff_methods: { key: string; value: string }[];
  payoff_requirements: string[][];
};
