import { ReactNode } from 'react';

import { SimpleGrid, SimpleGridProps, Stack, StackProps } from '@chakra-ui/react';

interface GridFormColumnProps extends StackProps {
  children?: ReactNode;
}

export const GridFormColumn = ({ children, ...rest }: GridFormColumnProps) => {
  return (
    <Stack mx={6} mt={5} spacing={3} {...rest}>
      {children}
    </Stack>
  );
};

interface GridFormRowProps extends SimpleGridProps {
  minChildWidth?: 90 | 135 | 180 | 225 | 270;
  children?: ReactNode;
}

export const GridFormRow = ({ minChildWidth = 90, children, ...rest }: GridFormRowProps) => {
  return (
    <SimpleGrid minChildWidth={minChildWidth} spacingX={2} spacingY={3} {...rest}>
      {children}
    </SimpleGrid>
  );
};
