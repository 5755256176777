import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Table, Tbody } from '@chakra-ui/react';

import { getFullName } from '../../gql/customerGql';
import { Deal, customerInfoUpsert } from '../../gql/dealGql';

import CustomerContact from '../CustomerContact';
import { IBuyerInfo } from '../CustomerInfo/CustomerInfo';
import CustomerInfoAcquisitionForm from '../CustomerInfoAcquisitionForm';
import { InfoRow } from '../InfoCard/InfoCardV2';
import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import Modal from '../shared/Modal';

import './CustomerInfoAcquisition.css';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { cleanDealForUpdate } from '../../utils/deals';

const CustomerInfoAcquisition: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { deal, dealRefetch } = useContext(DealContext);
  const [upsertCustomerInfo] = useMutation(customerInfoUpsert);

  const onSubmit = (customerInfoValues: Deal, setSubmitting: (isSubmitting: boolean) => void) => {
    const newDeal = cleanDealForUpdate(customerInfoValues);

    const { ...dealArgs } = newDeal;

    upsertCustomerInfo({
      variables: {
        deal: dealArgs,
      },
    })
      .then(() => {
        dealRefetch();
        setShowModal(false);
      })
      .catch((e) => logger.error('CustomerinfoAcquisition.tsx', '', null, e))
      .finally(() => setSubmitting(false));
  };

  const values: IBuyerInfo = {
    Name: getFullName(deal.customer),
    'Address Line 1': deal.customer.address?.address_line,
    'Apt., Suite, Unit #': deal.customer.address?.address_line_2,
    City: deal.customer.address?.city,
    State: deal.customer.address?.state,
    Zip: deal.customer.address?.zip,
    Phone: deal.customer.phone_number,
    SSN: deal.customer.ssn,
    Email: deal.customer.email,
  };

  return (
    <>
      <Card variant="roundedNoMargin">
        <CardHeaderV2 title="Customer Info" onClick={() => setShowModal(true)} />
        <Table>
          <Tbody>
            {Object.entries(values).map(([key, value]) => (
              <InfoRow key={key} name={key} value={value} />
            ))}
          </Tbody>
        </Table>
        <CustomerContact />
      </Card>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Customer Info">
        <CustomerInfoAcquisitionForm onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

export default CustomerInfoAcquisition;
