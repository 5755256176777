import { format } from 'date-fns';
import { FormikErrors } from 'formik';

import {
  CreditScoreTierEnumType,
  Customer,
  Maybe,
  Prequalification,
  PrequalificationCreditReportTradeline,
} from '../gql/generated/graphql';

import { LEExperianErrorEnum } from '../components/CreditApplication/components/Prequalification/PrequalificationSection';
import { formatMoney } from '../libs/utils';

const LTV_THRESHOLD = 1.2; // 120%
const PAYMENT_RATIO_THRESHOLD = 0.15; // 15%
const MILEAGE_THRESHOLD = 149_999;
const VEHICLE_AGE_THRESHOLD = 10;

const AUTOSTRUCTURE_MIN_CREDIT_SCORE = 600;

// Keep in sync with API
export const creditScoreTierRanges = [
  { name: CreditScoreTierEnumType.VeryGood, min: 720, max: 850 },
  { name: CreditScoreTierEnumType.Good, min: 680, max: 719 },
  { name: CreditScoreTierEnumType.Fair, min: 600, max: 679 },
  { name: CreditScoreTierEnumType.Poor, min: 300, max: 599 },
] as const;

const CreditScoreTierLabels: Record<CreditScoreTierEnumType, string> = {
  [CreditScoreTierEnumType.VeryGood]: 'Very Good',
  [CreditScoreTierEnumType.Good]: 'Good',
  [CreditScoreTierEnumType.Fair]: 'Fair',
  [CreditScoreTierEnumType.Poor]: 'Poor',
  [CreditScoreTierEnumType.NoResponse]: 'No Response',
} as const;

export const PrequalCategoryLabels = {
  ...CreditScoreTierLabels,
  FROZEN: 'Credit Frozen',
  LOCKED: 'Credit Locked',
} as const;

type ManualChecksType = 'ltv' | 'paymentRatio' | 'mileage' | 'vehicleAge';

const prequalErrors = {
  address_line: 'address line is required',
  gross_income: 'gross income is required',
  pay_frequency: 'pay frequency is required',
  city: 'city is required',
  state: 'state is required',
  zip: 'zip code is required',
  first_name: 'first name is required',
  last_name: 'last name is required',
  ssn: 'SSN is required',
  dob: 'date of birth is required',
};

type PrequalErrorKeys = keyof typeof prequalErrors;
export type PrequalCheckStatusType =
  | 'ideal'
  | 'warning'
  | 'critical'
  | 'failed'
  | 'noData'
  | 'frozen'
  | 'locked';
export type PrequalificationStatusType =
  | 'Unstarted'
  | 'Review'
  | 'Passed'
  | 'Credit Frozen'
  | 'Credit Locked';

const warningStatusMessages = {
  ltv: ['Check vehicle options', 'Verify payoff'],
  paymentRatio: ['Ask about additional forms of income', 'Add a co-buyer'],
  mileage: ['Verify odometer'],
  vehicleAge: ['Verify vehicle year'],
  noData: ['No Credit Score received from Experian'],
};

const checkStatus = (
  value: number,
  threshold: number,
  type: ManualChecksType,
): { status: PrequalCheckStatusType; statusNotes: string[] } => {
  if (value > threshold) {
    return {
      status: 'warning' as PrequalCheckStatusType,
      statusNotes: warningStatusMessages[type],
    };
  }

  return { status: 'ideal' as PrequalCheckStatusType, statusNotes: [] };
};

// If there is no Credit Score we do NOT want to autostructure
// Additional checks only use `ideal` and `warning` statuses.
export const checkStatusesPassed = (statuses: Maybe<PrequalCheckStatusType>[]): boolean =>
  statuses.every((status) => status === 'ideal');

export const isCreditScoreAllowedForAutostructure = (creditScore: Maybe<number>) =>
  !!creditScore && creditScore >= AUTOSTRUCTURE_MIN_CREDIT_SCORE;

export const getPrequalificationInputErrors = (
  errors: FormikErrors<Customer> | undefined,
  isBuyer = false,
): string[] => {
  if (!errors) {
    return [];
  }

  const errorList = Object.keys(errors).flatMap((key) => {
    if (key === 'address') {
      return Object.keys(errors.address ?? {}).filter(
        (addressKey) => prequalErrors[addressKey as PrequalErrorKeys],
      );
    }
    if (key === 'employment') {
      return Object.keys(errors?.employment ?? {}).filter(
        (employmentKey) => prequalErrors[employmentKey as PrequalErrorKeys],
      );
    }

    if (!prequalErrors[key as PrequalErrorKeys]) {
      return [];
    }

    return key;
  });

  return errorList.map(
    (key) => `${isBuyer ? 'Customer' : 'Cobuyer'} ${prequalErrors[key as PrequalErrorKeys]}`,
  );
};

export const getCreditScoreDetails = (
  prequalification?: Prequalification,
): {
  number: number;
  category: typeof PrequalCategoryLabels[keyof typeof PrequalCategoryLabels];
  status: PrequalCheckStatusType;
  statusNotes: string[];
} => {
  if (prequalification?.output?.errors?.includes(LEExperianErrorEnum.Frozen)) {
    return {
      number: 0,
      category: PrequalCategoryLabels[LEExperianErrorEnum.Frozen],
      status: 'frozen',
      statusNotes: ["Driver's credit is frozen"],
    };
  }

  if (prequalification?.output?.errors?.includes(LEExperianErrorEnum.Locked)) {
    return {
      number: 0,
      category: PrequalCategoryLabels[LEExperianErrorEnum.Locked],
      status: 'locked',
      statusNotes: ["Driver's credit is locked"],
    };
  }

  if (
    !prequalification?.credit_score &&
    prequalification?.output?.errors?.some((error) => error !== LEExperianErrorEnum.NoRecordFound)
  ) {
    return {
      number: 0,
      category: CreditScoreTierLabels[CreditScoreTierEnumType.NoResponse],
      status: 'warning',
      statusNotes: ['No credit score available', 'Verify all information is correct'],
    };
  }

  if (
    !prequalification?.credit_score ||
    prequalification?.output?.errors?.includes(LEExperianErrorEnum.NoRecordFound)
  ) {
    return {
      number: 0,
      category: CreditScoreTierLabels[CreditScoreTierEnumType.NoResponse],
      status: 'noData',
      statusNotes: ['No record found', 'Verify all information is correct'],
    };
  }

  // VeryGood and NoResponse are ideal
  // If there is not credit score we will NOT autostructure
  let status: PrequalCheckStatusType = 'ideal';
  switch (prequalification.credit_score_tier) {
    case CreditScoreTierEnumType.Good:
      status = 'warning';
      break;
    case CreditScoreTierEnumType.Fair:
      status = 'critical';
      break;
    case CreditScoreTierEnumType.Poor:
      status = 'failed';
      break;
    default:
      break;
  }

  const number = Number(prequalification.credit_score);
  const category = prequalification.credit_score_tier
    ? CreditScoreTierLabels[prequalification.credit_score_tier]
    : CreditScoreTierLabels[CreditScoreTierEnumType.NoResponse];

  return {
    number,
    category,
    status,
    statusNotes: status !== 'warning' ? [] : ['Ask about adding a co-buyer'],
  };
};

export const getPrequalCheckDetails = (
  ltv: number,
  paymentRatio: number,
  mileage: number,
  vehicleAge: number,
) => {
  return {
    ltv: checkStatus(ltv, LTV_THRESHOLD, 'ltv'),
    paymentRatio: checkStatus(paymentRatio, PAYMENT_RATIO_THRESHOLD, 'paymentRatio'),
    mileage: checkStatus(mileage, MILEAGE_THRESHOLD, 'mileage'),
    vehicleAge: checkStatus(vehicleAge, VEHICLE_AGE_THRESHOLD, 'vehicleAge'),
  };
};

export const formatExperianDate = (dateStr?: string | null) => {
  if (dateStr?.length !== 8) {
    return 'UNK';
  }

  const month = parseInt(dateStr.slice(0, 2), 10) - 1;
  const day = parseInt(dateStr.slice(2, 4), 10);
  const year = parseInt(dateStr.slice(4, 8), 10);

  return format(new Date(year, month, day), 'MM/dd/yyyy');
};

export const formatExperianMoney = (value?: string | null) => {
  if (!value) {
    return undefined;
  }

  return formatMoney(parseInt(value, 10), { noDecimal: true });
};

type TradelineSummary = {
  highCredit: number;
  creditLimit: number;
  balance: number;
  pastDue: number;
  monthlyPayment: number;
};

export const getTradelineSummary = (
  tradelines: PrequalificationCreditReportTradeline[],
): TradelineSummary =>
  tradelines.reduce(
    (acc, tradeline) => {
      const amount2 = parseInt(tradeline.amount2 ?? '0', 10);
      const amount1 = parseInt(tradeline.amount1 ?? '0', 10);
      const balance = parseInt(tradeline.balanceAmount ?? '0', 10);
      const pastDue = parseInt(tradeline.amountPastDue ?? '0', 10);
      const monthlyPayment = parseInt(tradeline.monthlyPaymentAmount ?? '0', 10);

      acc.highCredit += Number.isNaN(amount2) ? 0 : amount2;
      acc.creditLimit += Number.isNaN(amount1) ? 0 : amount1;
      acc.balance += Number.isNaN(balance) ? 0 : balance;
      acc.pastDue += Number.isNaN(pastDue) ? 0 : pastDue;
      acc.monthlyPayment += Number.isNaN(monthlyPayment) ? 0 : monthlyPayment;
      return acc;
    },
    {
      highCredit: 0,
      creditLimit: 0,
      balance: 0,
      pastDue: 0,
      monthlyPayment: 0,
    },
  );
