import { Box, Tooltip } from '@chakra-ui/react';
import { MdOutlineTimer } from 'react-icons/md';

import { FollowUp, FollowUpTypeEnum } from '../../gql/generated/graphql';

import { formatDateAndTime } from '../../utils/dates';
import { getFollowUpColor } from '../../utils/deals';
import { getFollowUpTypeLabel } from '../../utils/followUps';

type FollowUpBadgeProps = {
  followUp: FollowUp;
  size: number;
};

const FollowUpBadge = ({ followUp, size = 20 }: FollowUpBadgeProps) => {
  const tooltipNotes =
    (followUp.note?.length ?? 0) > 75 ? `${followUp.note?.slice(0, 75)}...` : followUp.note;

  const tooltipLabel = `${formatDateAndTime(followUp.date_utc)} - ${getFollowUpTypeLabel(
    followUp.type as FollowUpTypeEnum,
  )}${tooltipNotes ? ` - ${tooltipNotes}` : ''}`;

  return (
    <Box lineHeight={`${size}px`}>
      <Tooltip hasArrow label={tooltipLabel} placement="top" w="220px" borderRadius={5}>
        <Box color={getFollowUpColor(followUp)}>
          <MdOutlineTimer size={size} />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default FollowUpBadge;
