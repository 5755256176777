import { gql } from '@apollo/client';

import { DealMediaTypeEnum } from './generated/graphql';

export interface File {
  url: string;
  filename: string;
  key: string;
  mediaType: DealMediaTypeEnum;
}

export const generateContractsQuery = gql`
  query generateContracts($data: contractsInput) {
    generateContracts(data: $data) {
      files {
        url
        filename
        key
        mediaType
      }
    }
  }
`;

export const deleteContractsMutation = gql`
  mutation deleteContracts($filePaths: [String]!) {
    deleteContracts(filePaths: $filePaths)
  }
`;

export const sendContractsForSigning = gql`
  mutation sendContracts($deal_id: ID!, $keys: [String]!) {
    sendContracts(deal_id: $deal_id, keys: $keys)
  }
`;
