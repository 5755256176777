import { useMemo } from 'react';

import { Deal } from '../gql/dealGql';
import { PrequalificationRequest } from '../gql/generated/graphql';

export const usePrequalificationInputValues = ({
  isCustomer,
  deal,
}: {
  isCustomer: boolean;
  deal: Deal;
}) => {
  const { customer, cobuyer, car, financial_info } = deal;
  const customerOrCoBuyer = isCustomer ? customer : cobuyer;
  const hasCobuyer = !!cobuyer;

  const currentInputValues = useMemo(
    (): PrequalificationRequest => ({
      first_name: customerOrCoBuyer?.first_name,
      last_name: customerOrCoBuyer?.last_name,
      ssn: customerOrCoBuyer?.ssn,
      zip: customerOrCoBuyer?.address.zip,
      gross_income: customerOrCoBuyer?.employment?.gross_income,
      pay_frequency: customerOrCoBuyer?.employment?.pay_frequency,
      vehicle_payoff: car?.payoff?.vehicle_payoff,
      lending_book_value: car?.book_value,
      money_down: financial_info?.money_down,
      ...(isCustomer ? { income_sources: hasCobuyer ? 2 : 1 } : {}),
    }),
    [
      customerOrCoBuyer?.first_name,
      customerOrCoBuyer?.last_name,
      customerOrCoBuyer?.ssn,
      customerOrCoBuyer?.address.zip,
      customerOrCoBuyer?.employment?.gross_income,
      customerOrCoBuyer?.employment?.pay_frequency,
      car?.payoff?.vehicle_payoff,
      car?.book_value,
      financial_info?.money_down,
      isCustomer,
      hasCobuyer,
    ],
  );

  return currentInputValues;
};
