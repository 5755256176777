import { gql } from '@apollo/client';

import { DealStateEnum } from './dealGql';
import { User } from './userGql';

export class DealStates {
  id: number;
  deal_id: number;
  state: DealStateEnum;
  updated_date_utc?: Date | string;
  updated_at?: Date | string;
  user_id?: string;
  user?: User;

  constructor() {
    this.id = 0;
    this.deal_id = 0;
    this.state = DealStateEnum.Estimate;
    this.user_id = '';
    this.user = new User();
  }
}

export const dealStatesQuery = gql`
  query dealStates($dealId: ID!) {
    dealStates(dealId: $dealId) {
      id
      state
      updated_date_utc
      updated_at
      user {
        name
      }
    }
  }
`;
