import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Checkbox, Icon } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';

import {
  FilterOptionsEnum,
  FilterOptionsType,
  FiltersAction,
  handleClearFilter,
} from '../../utils';

interface MoreFiltersProps {
  options: FilterOptionsType[];
  selectedFilters: FilterOptionsEnum[];
  setSelectedFilters: Dispatch<SetStateAction<FilterOptionsEnum[]>>;
  dispatch: Dispatch<FiltersAction>;
}

const MoreFilters = ({
  options,
  selectedFilters,
  setSelectedFilters,
  dispatch,
}: MoreFiltersProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleChange = (option: FilterOptionsType) => {
    if (selectedFilters.some((sf) => sf === option.label)) {
      handleClearFilter(option, option.actionKind, dispatch, selectedFilters, setSelectedFilters);
    } else {
      setSelectedFilters(() => [...selectedFilters, option.label]);
    }
  };
  return (
    <Box fontSize="20" zIndex="2" cursor="pointer" onMouseLeave={() => setIsOpen(false)} pr={2}>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <Icon as={MdAdd} mb={1} /> More Filters
      </Box>
      <Box position="absolute" hidden={!isOpen}>
        {options.map((opt) => {
          return (
            <Box mt={1} boxShadow="md" borderRadius={10} key={opt.label}>
              <Checkbox
                size="lg"
                _hover={{ bg: 'gray.100' }}
                bg="white"
                w="200px"
                mb={0}
                mt={-1}
                p={3}
                borderTopRadius={options.indexOf(opt) === 0 ? 10 : 0}
                borderBottomRadius={options.indexOf(opt) === options.length - 1 ? 10 : 0}
                onChange={() => handleChange(opt)}
                isChecked={selectedFilters.some((sf) => sf === opt.label)}
              >
                {opt.label}
              </Checkbox>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MoreFilters;
