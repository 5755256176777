import React from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { OverlayTrigger } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Deal, DealStateEnum, acquisitionDealInfoUpsert } from '../../gql/dealGql';

import { StyledTooltip } from './MmrButton';

import ROUTES from '../../constants/routes';
import { logger } from '../../libs/Logger';
import { cleanDealForUpdate } from '../../utils/deals';

const SendToStructuringOrClosing: React.FC = () => {
  const { values, isValid, isSubmitting, setSubmitting } = useFormikContext<Deal>();
  const [upsertAcquisitionDealInfo] = useMutation(acquisitionDealInfoUpsert);
  const history = useHistory();

  const onSendToStructuringOrClosing = async (deal: Deal) => {
    const newDeal = cleanDealForUpdate(deal);

    try {
      await upsertAcquisitionDealInfo({
        variables: {
          deal: newDeal,
        },
      });
      history.push(ROUTES.DASHBOARD);
    } catch (error) {
      logger.error('SendToStructuringOrClosingButton.tsx', '', null, error);
    } finally {
      setSubmitting(false);
    }
  };

  const renderTooltip = () => (
    <StyledTooltip>
      Missing:
      {!values.customer.first_name ? <div>First Name</div> : ''}
      {!values.customer.last_name ? <div>Last Name</div> : ''}
      {!values.customer.phone_number ? <div>Phone Number</div> : ''}
      {!values.customer.email ? <div>E-mail</div> : ''}
      {!values.car.vin ? <div>VIN</div> : ''}
      {!values.car.payoff.vehicle_payoff ? <div>Payoff Amount</div> : ''}
      {!values.car.mileage ? <div>Mileage</div> : ''}
      {!values.financial_info_acquisition.kbb_lending ? <div>KBB</div> : ''}
      {!values.car.mmr ? <div>MMR</div> : ''}
      {!values.car.color ? <div>Color</div> : ''}
      {!values.condition_report ? <div>Condition Report</div> : ''}
    </StyledTooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={!isValid ? renderTooltip : <span />}
    >
      <span>
        <Button
          isLoading={isSubmitting}
          loadingText={
            values.financial_info_acquisition.is_approved
              ? 'SEND TO CLOSING'
              : 'SEND TO STRUCTURING'
          }
          isDisabled={!isValid}
          hidden={values.state !== DealStateEnum.SoftClose}
          style={!isValid ? { pointerEvents: 'none' } : {}}
          onClick={() =>
            onSendToStructuringOrClosing({
              ...values,
              state: values.financial_info_acquisition.is_approved
                ? DealStateEnum.Closing
                : DealStateEnum.Structuring,
            })
          }
        >
          {values.financial_info_acquisition.is_approved
            ? 'SEND TO CLOSING'
            : 'SEND TO STRUCTURING'}
        </Button>
      </span>
    </OverlayTrigger>
  );
};

export default SendToStructuringOrClosing;
