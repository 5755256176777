import { FC, ReactNode } from 'react';

import { FormLabel, FormLabelProps, HStack, StackProps } from '@chakra-ui/react';

interface InputRowProps extends StackProps {
  label: string;
  children: ReactNode;
  labelProps?: FormLabelProps;
}

const InputRow: FC<InputRowProps> = ({ label, children, labelProps, ...rest }) => (
  <HStack mt={1} alignItems="start" {...rest}>
    <FormLabel
      minW={{ base: '50%', xl: '40%', '2xl': '50%' }}
      maxW={{ base: '50%', xl: '40%', '2xl': '50%' }}
      mt={3}
      fontSize="sm"
      whiteSpace={{ base: 'normal', '2xl': 'nowrap' }}
      {...labelProps}
    >
      {label}
    </FormLabel>
    {children}
  </HStack>
);

export default InputRow;
