import { states } from '../../libs/states';

export const docStates = [
  states.ALABAMA,
  states.ALASKA,
  states.ARIZONA,
  states.ARKANSAS,
  states.CONNECTICUT,
  states.FLORIDA,
  states.HAWAII,
  states.IDAHO,
  states.ILLINOIS,
  states.MAINE,
  states.MICHIGAN,
  states.MISSISSIPPI,
  states.NEVADA,
  states.NEW_JERSEY,
  states.NEW_YORK,
  states.OHIO,
  states.WASHINGTON,
];
