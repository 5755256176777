import { Button, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';

import {
  Maybe,
  StructuringFollowUp,
  useDeleteStructuringFollowUpMutation,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import { logger } from '../../libs/Logger';

interface DeleteStructuringFollowUpProps {
  structuringFollowUp: Maybe<StructuringFollowUp>;
  showIconButton?: boolean;
}

const DeleteStructuringFollowUp = ({
  structuringFollowUp,
  showIconButton = false,
}: DeleteStructuringFollowUpProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteStructuringFollowUp, { loading }] = useDeleteStructuringFollowUpMutation();

  const handleDelete = async () => {
    if (!structuringFollowUp?.id) {
      return;
    }

    try {
      await deleteStructuringFollowUp({
        variables: {
          id: structuringFollowUp.id,
        },
      });
      toast.success('Structuring Follow-Up deleted');
      onClose();
    } catch (e) {
      const error = e as Error;
      logger.error('DeleteStructuringFollowUp.tsx', 'deleteStructuringFollowUp', error);
      toast.error(
        `Error deleting Structuring Follow-Up: ${
          error.message || error
        }. Please refresh and try again.`,
      );
    }
  };

  return (
    <>
      {showIconButton ? (
        <IconButton
          icon={<BiTrash />}
          aria-label="Delete Structuring Follow-Up"
          size="xs"
          variant="iconHover"
          color="errorsRed"
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
          isLoading={loading}
        />
      ) : (
        <Button
          variant="warning"
          onClick={onOpen}
          isLoading={loading}
          loadingText="REMOVE FROM QUEUE"
        >
          REMOVE FROM QUEUE
        </Button>
      )}

      <Modal
        title="Delete Structuring Follow-Up"
        isOpen={isOpen}
        onClose={onClose}
        leftButtons={
          <Button variant="warning" onClick={onClose}>
            CANCEL
          </Button>
        }
        rightButtons={
          <Button variant="boot" onClick={handleDelete} isLoading={loading} loadingText="Delete">
            DELETE
          </Button>
        }
      >
        <Text align="center">Are you sure you want to delete this Structuring Follow-Up?</Text>
      </Modal>
    </>
  );
};

export default DeleteStructuringFollowUp;
