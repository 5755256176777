import { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Card, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { Auction, auctionsQuery } from '../../gql/auctionGql';
import { DealTypeLabelMap } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';
import {
  getDealNotificationCountByType,
  getDealsNotificationCountByType,
} from '../../gql/notificationsGql';

import { BucketCard, BucketHeader } from '../DealsSwimlane/DealsSwimlane';
import ExportDealsToXlsxButton from '../ExportDealsToXlsxButton/ExportDealsToXlsxButton';
import Loader from '../Loader';
import {
  NewTableNotificationsContainer,
  NewTitleNotificationsContainer,
} from '../NotificationBadge/NotificationsContainer';
import TextNotificationBadgeV2 from '../NotificationBadge/TextNotificationBadgeV2';

import { completedAcquisitionDealColumns } from './constants';

import { CompletedDealsProps, useCompletedDeals } from '../../hooks/useCompletedDeals';
import { FiltersContext } from '../../libs/contextLib';
import { handleClickOrCommandClick } from '../../libs/eventHandlers';

const CompletedAcquisitionDealsTable = ({
  startDate,
  endDate,
  user,
  dealState,
  addressState,
  notifications,
}: CompletedDealsProps) => {
  const {
    filters: {
      global: {
        showAssignedToMe,
        showBootRequested,
        showAssignedToDeletedUsers,
        showActiveTags,
        showMatureFollowUps,
      },
    },
  } = useContext(FiltersContext);

  const [auctions, setAuctions] = useState<Auction[]>([]);

  const auctionsGql = useQuery(auctionsQuery);

  useEffect(() => {
    if (auctionsGql.data && auctionsGql.data.auctions) {
      setAuctions(auctionsGql.data.auctions);
    }
  }, [auctionsGql.data]);

  const { query, completedDeals } = useCompletedDeals({
    assignedToMeFilter: showAssignedToMe,
    bootRequestedFilter: showBootRequested,
    assignedToDeletedUsersFilter: showAssignedToDeletedUsers,
    types: [DealType.Acquisition],
    startDate,
    endDate,
    user,
    dealState,
    addressState,
    notifications,
    showActiveTags,
    showMatureFollowUps,
  });

  const notificationCountByType = useMemo(
    () => getDealsNotificationCountByType(completedDeals),
    [completedDeals],
  );

  return (
    <BucketCard>
      <BucketHeader>
        Completed {DealTypeLabelMap[DealType.Acquisition]} Deals
        <NewTitleNotificationsContainer>
          <TextNotificationBadgeV2 count={notificationCountByType.messages} />
        </NewTitleNotificationsContainer>
        <ExportDealsToXlsxButton
          deals={completedDeals}
          columns={completedAcquisitionDealColumns}
          auctions={auctions}
        />
      </BucketHeader>
      <Card.Body style={{ marginTop: '-1px', padding: 0, overflowX: 'scroll', overflowY: 'auto' }}>
        <Table hover>
          <thead>
            <tr>
              <th />
              {completedAcquisitionDealColumns.map(({ header }) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {completedDeals.map((deal) => {
              const dealNotificationCountByType = getDealNotificationCountByType(deal);

              return (
                <LinkContainer
                  key={deal.id}
                  to={`/deals/${deal.id}`}
                  onClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`)}
                  onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`)}
                >
                  <tr style={{ cursor: 'pointer' }}>
                    <td key="Notifications" style={{ whiteSpace: 'nowrap' }}>
                      <NewTableNotificationsContainer>
                        <TextNotificationBadgeV2
                          count={dealNotificationCountByType.messages}
                          deal={deal}
                        />
                      </NewTableNotificationsContainer>
                    </td>
                    {completedAcquisitionDealColumns.map(({ header, getValue }) => (
                      <td key={header} style={{ whiteSpace: 'nowrap' }}>
                        {getValue(deal, auctions)}
                      </td>
                    ))}
                  </tr>
                </LinkContainer>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
      <Loader isLoading={query.loading} />
    </BucketCard>
  );
};

export default CompletedAcquisitionDealsTable;
