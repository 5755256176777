import React, { useContext, useEffect, useState } from 'react';
import Big from 'big.js';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Form, Col, Modal, Spinner } from 'react-bootstrap';
import { useQuery, useLazyQuery } from '@apollo/client';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import { Button } from '@chakra-ui/react';
import { Deal, DealStateEnum } from '../../gql/dealGql';
import { DealContext } from '../../libs/DealContext';
import { Auction, auctionsQuery, auctionAverageTransportCostQuery } from '../../gql/auctionGql';
import { ColorsEnum } from '../../libs/colorLib';
import ConditionReport from '../ConditionReport';
import EditDealInfoButton from '../EditDealInfoButton';
import { formatMoney } from '../../libs/utils';
import StructuringAndClosedView from './StructuringAndClosedView';
import { NumberInput } from './FormComponents';
import { AddAuction } from './AddAuction';
import SaveEditButton from './SaveEditButton';
import AuctionDistancesButton from './AuctionDistancesButton';
import LogisticsViewSaveButton from './LogisiticsViewSaveButton';

const StyledRow = styled(Form.Row)`
  justify-content: center;
  align-items: center;
`;

const editableDealStates = [
  DealStateEnum.ReadyForPickup,
  DealStateEnum.PaidOff,
  DealStateEnum.AtAuction,
  DealStateEnum.Sold,
];

const LogisticsView: React.FC = () => {
  const { deal, dealRefetch } = useContext(DealContext);
  const { values, handleBlur, touched, errors, setFieldValue } = useFormikContext<Deal>();
  const {
    customer: {
      address: { address_line, zip },
    },
  } = deal;
  useFormikContext<Deal>();
  const [isEditing, setIsEditing] = useState(editableDealStates.includes(deal.state));
  const auctionsGql = useQuery(auctionsQuery, { fetchPolicy: 'network-only' });
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAuctionModal, setShowAuctionModal] = useState<boolean>(false);
  let isConditionReportCompleted = false;

  const inReadyForPickup = deal.state === DealStateEnum.ReadyForPickup;
  const inPaidOff = deal.state === DealStateEnum.PaidOff;

  if (
    deal?.condition_report?.accidents &&
    deal?.condition_report.tires &&
    deal?.condition_report.smoked_in !== null &&
    deal?.condition_report.lights_on_dash !== null &&
    deal?.condition_report.overall_condition
  ) {
    isConditionReportCompleted = true;
  }

  const [getAverageTransportCost, { loading }] = useLazyQuery(auctionAverageTransportCostQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ auctionAverageTransportCost }) => {
      setFieldValue('financial_info_acquisition.transport_cost', auctionAverageTransportCost);
    },
  });

  useEffect(() => {
    if (auctionsGql.data && auctionsGql.data.auctions) {
      setAuctions(auctionsGql.data.auctions);
    }
  }, [auctionsGql.data]);

  useEffect(() => {
    if (
      values.financial_info_acquisition.total_cost &&
      values.financial_info_acquisition.sell_price
    ) {
      setFieldValue(
        'financial_info_acquisition.gross_profit',
        new Big(values.financial_info_acquisition.sell_price)
          .minus(values.financial_info_acquisition.total_cost)
          .toNumber(),
      );
    }
  }, [values.financial_info_acquisition.total_cost, values.financial_info_acquisition.sell_price]);

  const handleAuctionChange = (id: string) => {
    if (id) {
      setFieldValue('financial_info_acquisition.auction_id', id);
      const customerAddress = `${address_line} ${zip}`;
      getAverageTransportCost({ variables: { id, customerAddress } });
    } else {
      setFieldValue('financial_info_acquisition.transport_cost', '');
    }
  };

  return (
    <>
      <StyledRow>
        <Col md="4" className="pb-4">
          <Button isDisabled={!isEditing} onClick={() => setShowAuctionModal(true)}>
            ADD AUCTION
          </Button>
          <Modal
            show={showAuctionModal}
            backdrop="static"
            onHide={() => setShowAuctionModal(false)}
            keyboard={false}
            dialogClassName="add-auction-modal"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center w-100">Add Auction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddAuction
                setShowModal={setShowAuctionModal}
                auctionsRefetch={auctionsGql.refetch}
              />
            </Modal.Body>
          </Modal>
        </Col>
        <Col md="4" className="pb-4">
          {(inReadyForPickup || inPaidOff) && <AuctionDistancesButton auctions={auctions} />}
        </Col>
      </StyledRow>

      <StyledRow className="my-4">
        <Col md="2">
          <h5>Auction</h5>
        </Col>
        <Form.Group as={Col} md="4" className="m-0">
          <Form.Control
            as="select"
            placeholder="Auction"
            name="financial_info_acquisition.auction_id"
            value={values.financial_info_acquisition?.auction_id}
            onChange={(e) => handleAuctionChange(e.target.value)}
            onBlur={handleBlur}
            disabled={!isEditing}
            isInvalid={
              touched.financial_info_acquisition?.auction_id &&
              !!errors.financial_info_acquisition?.auction_id
            }
          >
            <option key={0} value="">
              Select Auction
            </option>
            {auctions.map((e) => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Col md="2">{loading && <Spinner animation="grow" size="sm" />}</Col>
      </StyledRow>
      <StyledRow>
        <Col md="2">
          <h5>Transport Cost</h5>
        </Col>
        <Form.Group as={Col} md="2">
          <NumberInput
            isMoney
            path="financial_info_acquisition.transport_cost"
            isEditing={isEditing}
          />
        </Form.Group>
        <Col md="2">
          <h5>Auction Fees</h5>
        </Col>
        <Form.Group as={Col} md="2">
          <NumberInput
            isMoney
            path="financial_info_acquisition.auction_fees"
            isEditing={isEditing}
          />
        </Form.Group>
      </StyledRow>
      <StructuringAndClosedView />
      <StyledRow>
        <Col md="2">
          <h5>Gross Profit</h5>
        </Col>
        <Col md="2">
          <h5>{formatMoney(values.financial_info_acquisition.gross_profit)}</h5>
        </Col>
        <Col md="2">
          <h5>Sell Price</h5>
        </Col>
        <Form.Group as={Col} md="2">
          <NumberInput isMoney path="financial_info_acquisition.sell_price" isEditing={isEditing} />
        </Form.Group>
      </StyledRow>
      <StyledRow>
        <Button
          size="lgWithIconLeft"
          rightIcon={
            isConditionReportCompleted ? (
              <BiCheckCircle color={ColorsEnum.GREEN} />
            ) : (
              <BiXCircle color={ColorsEnum.RED} />
            )
          }
          isLoading={showModal}
          onClick={() => setShowModal(true)}
        >
          VIEW CONDITION REPORT
        </Button>
        <Modal
          show={showModal}
          backdrop="static"
          onHide={() => setShowModal(false)}
          keyboard={false}
          dialogClassName="condition-report-modal"
          className="text-center"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100">Condition Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ConditionReport setShowModal={setShowModal} isEditing={false} />
          </Modal.Body>
        </Modal>
        <LogisticsViewSaveButton dealRefetch={dealRefetch} />
        <EditDealInfoButton isEditing={isEditing} setIsEditing={setIsEditing} />
        <SaveEditButton isEditing={isEditing} setIsEditing={setIsEditing} />
      </StyledRow>
    </>
  );
};

export default LogisticsView;
