import { Dispatch, SetStateAction, useContext } from 'react';

import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, DealStateEnum } from '../../../gql/dealGql';
import { DealType } from '../../../gql/generated/graphql';

import Tooltip from '../../shared/Tooltip';

import { LDFlags } from '../../../constants/experiments';
import { PermissionEnum } from '../../../constants/permissions';
import useFlag from '../../../hooks/useFlag';
import { DealContext } from '../../../libs/DealContext';
import { AbilityContext } from '../../../libs/contextLib';

interface Props {
  isSavingOrCheckingMileage: boolean;
  setDealState: Dispatch<SetStateAction<DealStateEnum>>;
  validatePayoffAndMileage: ({
    dealStateParam,
    skipPayoffValidation,
    skipMileageValidation,
  }: {
    dealStateParam?: DealStateEnum | undefined;
    skipPayoffValidation?: boolean | undefined;
    skipMileageValidation?: boolean | undefined;
  }) => Promise<void>;
}

const SubmitApplicationButtons = ({
  isSavingOrCheckingMileage,
  setDealState,
  validatePayoffAndMileage,
}: Props) => {
  const prequalAutoStructureFlag = useFlag(LDFlags.PREQUAL_AUTOSTRUCTURE);

  const {
    deal,
    canAutoStructure,
    isRecalculatingPayoff,
    everyoneIsPrequalified,
    autosaving,
    isPayoffRequested,
  } = useContext(DealContext);
  const ability = useContext(AbilityContext);

  const { errors, isValid } = useFormikContext<Deal>();

  const isRefi = deal.type === DealType.Refi;
  const isMissingPrequalification = prequalAutoStructureFlag && !everyoneIsPrequalified;
  const isFollowUpScheduled = !!deal.follow_up;

  const isDisabled =
    !isValid ||
    isPayoffRequested ||
    isRecalculatingPayoff ||
    isMissingPrequalification ||
    isFollowUpScheduled ||
    autosaving;

  const allErrors = {
    ...errors,
    ...(isPayoffRequested ? { payoffError: 'Payoff was requested' } : {}),
    ...(isMissingPrequalification ? { prequalificationError: 'Prequalification is required' } : {}),
    ...(isFollowUpScheduled ? { followupError: 'Cannot have a current follow up scheduled' } : {}),
  };

  const handleOnClick = (
    newDealState: DealStateEnum.Structuring | DealStateEnum.StructuringInProgress,
  ) => {
    if (!isValid) {
      return;
    }

    setDealState(newDealState);
    validatePayoffAndMileage({ dealStateParam: newDealState });
  };

  return (
    <>
      {ability.has(PermissionEnum.MoveToStructuring) && (
        <Tooltip errors={allErrors}>
          <Button
            variant="secondary"
            isLoading={isSavingOrCheckingMileage}
            loadingText="MOVE TO STRUCTURING"
            isDisabled={isDisabled}
            onClick={() => handleOnClick(DealStateEnum.Structuring)}
          >
            MOVE TO STRUCTURING
          </Button>
        </Tooltip>
      )}

      <Tooltip errors={allErrors}>
        <Button
          isLoading={isSavingOrCheckingMileage}
          loadingText="SUBMIT APPLICATION"
          isDisabled={isDisabled}
          onClick={() =>
            handleOnClick(
              !isRefi &&
                (!prequalAutoStructureFlag || canAutoStructure) &&
                ability.has(PermissionEnum.AutomateCreditApplication)
                ? DealStateEnum.StructuringInProgress
                : DealStateEnum.Structuring,
            )
          }
        >
          SUBMIT APPLICATION
        </Button>
      </Tooltip>
    </>
  );
};

export default SubmitApplicationButtons;
