import React, { ReactNode, useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { AbilityContext } from '../../libs/contextLib';

type UnauthenticatedRouteProps = RouteProps & {
  children: ReactNode;
};
const UnauthenticatedRoute = ({ children, ...rest }: UnauthenticatedRouteProps) => {
  const { isAuthenticated } = useAuth0();
  const abilities = useContext(AbilityContext);

  // abilities.size will only be truthy after the user has been loaded,
  // wait to render an authenticated route until that is in place
  const hasPermissions = abilities.size > 0;

  const defaultRoute =
    !abilities.has(PermissionEnum.SuperUser) && abilities.has(PermissionEnum.SeeStructuringQueue)
      ? ROUTES.STRUCTURING_FOLLOW_UPS
      : ROUTES.DASHBOARD;

  return (
    <Route {...rest}>
      {isAuthenticated && hasPermissions ? <Redirect to={defaultRoute} /> : children}
    </Route>
  );
};

export default UnauthenticatedRoute;
