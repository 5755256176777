import React, { useState, useContext } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { PermissionEnum } from '../../constants/permissions';
import { AbilityContext } from '../../libs/contextLib';
import { useUser } from '../../hooks/useUser';
import { SsnMask } from './MaskedSsnCustomerInfo';
import MaskedSsnButton from './MaskedSsnButton';

interface MaskedSsnPayoffProps {
  ssn: string;
  claimedBy?: string;
  customerId?: number;
  tempInfoId?: number | null;
}

const MaskedSsnPayoff: React.FC<MaskedSsnPayoffProps> = ({
  ssn,
  claimedBy,
  customerId,
  tempInfoId,
}) => {
  const [ssnHidden, setSsnHidden] = useState(true);
  const ability = useContext(AbilityContext);
  const user = useUser();
  const canViewSsn =
    ability.has(PermissionEnum.SuperUser) ||
    ability.has(PermissionEnum.PayoffManager) ||
    claimedBy === user.id;

  return (
    <HStack maxWidth="100%" spacing="auto">
      {ssnHidden ? (
        <SsnMask type="payoff" />
      ) : (
        <Text minWidth="96px" mr="18px">
          {ssn}
        </Text>
      )}
      <MaskedSsnButton
        type="payoff"
        customerId={customerId}
        tempInfoId={tempInfoId}
        onClick={setSsnHidden}
        visible={canViewSsn}
      />
    </HStack>
  );
};

export default MaskedSsnPayoff;
