import { MileageWarningsEnum, VehicleEnum } from '../gql/carGql';
import { TtVehicleType, VehicleMileageGql } from '../gql/generated/graphql';
import { formatMileage } from '../libs/utils';

export const getMileageWarning = (
  vehicleMileage?: VehicleMileageGql | undefined | null,
  mileage = 0,
) => {
  if (vehicleMileage?.lastOdometer && mileage < vehicleMileage.lastOdometer) {
    return {
      status: MileageWarningsEnum.LowerThanReported,
      warning: MileageWarningsEnum.LowerThanReported.replace(
        '{value}',
        (formatMileage(mileage) as string).toString(),
      ).replace('{expected}', (formatMileage(vehicleMileage.lastOdometer) as string).toString()),
    };
  }
  if (mileage.toString().endsWith('00')) {
    return {
      status: MileageWarningsEnum.RoundNumber,
      warning: MileageWarningsEnum.RoundNumber.replace(
        '{value}',
        (formatMileage(mileage) as string).toString(),
      ),
    };
  }
  if (/([1-9])\1\1$/.test(mileage.toString())) {
    return {
      status: MileageWarningsEnum.RepeatingEnd,
      warning: MileageWarningsEnum.RepeatingEnd.replace(
        '{value}',
        (formatMileage(mileage) as string).toString(),
      ),
    };
  }

  return null;
};

export const transformVehicleType = (type: string) => {
  switch (type) {
    case VehicleEnum.PASSENGER:
      return TtVehicleType.PassengerCar;
    case VehicleEnum.MPV:
      return TtVehicleType.MultipurposePassengerVehicle;
    case VehicleEnum.TRUCK:
      return TtVehicleType.Truck;
    default:
      return TtVehicleType.PassengerCar;
  }
};
