import React, { Dispatch, SetStateAction } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Box, Button, Flex, Textarea } from '@chakra-ui/react';

import { Note } from '../../gql/noteGql';

interface NewNoteProps {
  setShowWidget: Dispatch<SetStateAction<boolean>>;
  onSubmit: (note: Note, { resetForm, setSubmitting }: FormikHelpers<Note>) => Promise<void>;
  showCancelButton?: boolean;
  borderRadius?: string;
  height?: string;
}

export const NewNote: React.FC<NewNoteProps> = ({
  setShowWidget,
  onSubmit,
  showCancelButton = false,
  borderRadius = '10px',
  height = '100%',
}) => {
  return (
    <Formik initialValues={{ text: '' }} onSubmit={onSubmit}>
      {({ isSubmitting, handleBlur, handleChange, handleSubmit, values }) => (
        <Box bgColor="cadetBlue" borderRadius={borderRadius} p="0.75rem">
          <Textarea
            bgColor="white"
            name="text"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Write a note..."
            value={values.text}
            height={height}
          />
          <Flex justifyContent="flex-end" mt={3}>
            {showCancelButton && (
              <Button
                variant="warning"
                isDisabled={isSubmitting}
                onClick={() => setShowWidget(false)}
              >
                CANCEL
              </Button>
            )}
            <Button
              isLoading={isSubmitting}
              loadingText="ADD NOTE"
              onClick={async () => {
                await handleSubmit();
                setShowWidget(false);
              }}
            >
              ADD NOTE
            </Button>
          </Flex>
        </Box>
      )}
    </Formik>
  );
};
