import React from 'react';

import { Button } from '@chakra-ui/react';
import { Formik } from 'formik';
import { Form, Modal, Row } from 'react-bootstrap';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import * as Yup from 'yup';

import { Review } from '../../../gql/reviewGql';

interface UpsertReviewModalProps {
  showModal: boolean;
  setShowModal: (a: boolean) => void;
  handleOnSubmit: (a: Review) => void;
  reviewToEdit: Review | null;
}

const validationSchema = Yup.object().shape({
  customer_name: Yup.string().required('Please enter a customer name'),
  review: Yup.string().required('Please enter a review'),
  com_visible: Yup.boolean().required('Please enter COM visibility'),
});

const UpsertReviewModal: React.FC<UpsertReviewModalProps> = ({
  showModal,
  setShowModal,
  handleOnSubmit,
  reviewToEdit,
}) => {
  const initialValues = {
    customer_name: reviewToEdit?.customer_name ?? '',
    location: reviewToEdit?.location ?? '',
    review: reviewToEdit?.review ?? '',
    com_visible: reviewToEdit?.com_visible ?? false,
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      onHide={() => setShowModal(false)}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          {reviewToEdit ? 'Edit Review' : 'Add Review to COM'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          onSubmit={(values) => handleOnSubmit(values)}
          initialValues={reviewToEdit || initialValues}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            isSubmitting,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Customer Name</Form.Label>
                <Form.Control
                  name="customer_name"
                  type="text"
                  value={values.customer_name}
                  onChange={handleChange}
                  isInvalid={touched.customer_name && !!errors.customer_name}
                  onBlur={handleBlur}
                  maxLength={30}
                />
                <Form.Control.Feedback type="invalid">{errors.customer_name}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  isInvalid={touched.location && !!errors.location}
                  onBlur={handleBlur}
                  maxLength={30}
                />
                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="h-100">
                <Form.Label>Customer Review</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="review"
                  value={values.review}
                  onChange={handleChange}
                  isInvalid={touched.review && !!errors.review}
                  onBlur={handleBlur}
                  maxLength={1500}
                />
                <Form.Control.Feedback type="invalid">{errors.review}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="h-100 d-flex flex-column">
                <Form.Label>COM Visibility</Form.Label>
                <Toggle
                  icons={false}
                  name="com_visible"
                  checked={values.com_visible}
                  onChange={handleChange}
                  className="w-25"
                />
              </Form.Group>
              <Row className="justify-content-center">
                <Button
                  variant="secondary"
                  isLoading={isSubmitting}
                  loadingText="SAVE REVIEW"
                  isDisabled={reviewToEdit ? false : !isValid}
                  type="submit"
                >
                  SAVE REVIEW
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpsertReviewModal;
