import { FC } from 'react';
import { Text, Td, HStack, Stack, Spacer, Link, Tr, Icon } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { MdPhoneCallback, MdPhoneMissed, MdCall } from 'react-icons/md';
import { Call, CallDirectionEnum, getCallerNameString } from '../../../gql/callGql';
import { DealStateLabelMap } from '../../../gql/dealGql';
import { handleClickOrCommandClick } from '../../../libs/eventHandlers';
import { formatTime } from '../../../libs/utils';
import { getMinutesAgo } from '../../../utils/dates';

interface PhoneCallRowProps {
  call: Call;
}

const PhoneCallRow: FC<PhoneCallRowProps> = ({ call }) => {
  const history = useHistory();

  const { deal } = call;
  const stateLabel = DealStateLabelMap[deal?.state as keyof typeof DealStateLabelMap] ?? '';
  const wasAnswered = call.outcome === 'answered';
  const isInboundCall = call.direction === CallDirectionEnum.Inbound;
  const isCallWithinLastHour = (getMinutesAgo(call.created_at) ?? 0) < 60;

  return (
    <Tr>
      <Td borderColor="lightGray">
        <HStack justifyContent="space-between" ml="15px" mr="15px">
          {isInboundCall ? (
            wasAnswered ? (
              <Icon as={MdPhoneCallback} color="green" />
            ) : (
              <Icon as={MdPhoneMissed} color="errorsRed" />
            )
          ) : (
            <Icon as={MdCall} color="oxfordBlue" />
          )}

          <Stack spacing={0}>
            <Text fontWeight="bold">{`${getCallerNameString(call, deal)}`}</Text>
            <Text fontWeight="normal" fontSize="12px">
              {isCallWithinLastHour
                ? `${getMinutesAgo(call.created_at)} minutes ago`
                : formatTime(call.created_at?.toString())}
            </Text>
          </Stack>
          <Spacer />
          <Stack spacing={0}>
            <Link
              fontWeight="bold"
              textDecoration="underline"
              onClick={(event) => handleClickOrCommandClick(event, `/deals/${deal?.id}`, history)}
              onAuxClick={(event) =>
                handleClickOrCommandClick(event, `/deals/${deal?.id}`, history)
              }
            >
              {deal?.id ?? ''}
            </Link>
            <Text variant="inbox" fontWeight="normal" fontSize="12px" width="100px">
              {deal?.id ? stateLabel : ''}
            </Text>
          </Stack>
        </HStack>
      </Td>
    </Tr>
  );
};

export default PhoneCallRow;
