import { PodUser, RoleIdEnumType, TeamType, UserByRole } from '../gql/generated/graphql';
import { PodUsersByPod } from '../gql/userGql';

export enum PodModalFormRoles {
  closer = 'closer',
  setter = 'setter',
  setterLead = 'setter_lead',
  titleClerk = 'title_clerk',
}

export const getSpecificRoleIdByTeamTypeAndRole = (
  teamType: TeamType,
  role: PodModalFormRoles,
): RoleIdEnumType => {
  switch (teamType) {
    case TeamType.D2d:
      switch (role) {
        case PodModalFormRoles.closer:
          return RoleIdEnumType.D2dCloser;
        case PodModalFormRoles.setter:
          return RoleIdEnumType.D2dSetter;
        default:
          return RoleIdEnumType.D2dSetter;
      }
    case TeamType.Inbound:
      switch (role) {
        case PodModalFormRoles.closer:
          return RoleIdEnumType.InboundFinancialSpecialist;
        case PodModalFormRoles.setter:
          return RoleIdEnumType.InboundSalesAdvisor;
        case PodModalFormRoles.setterLead:
          return RoleIdEnumType.InboundSalesPodLead;
        default:
          return RoleIdEnumType.InboundSalesAdvisor;
      }
    case TeamType.Outbound:
      switch (role) {
        case PodModalFormRoles.closer:
          return RoleIdEnumType.OutboundFinancialSpecialist;
        case PodModalFormRoles.setter:
          return RoleIdEnumType.OutboundSetter;
        case PodModalFormRoles.setterLead:
          return RoleIdEnumType.OutboundSalesManager;
        default:
          return RoleIdEnumType.OutboundSetter;
      }
    case TeamType.Titling:
      return RoleIdEnumType.TitleClerk;
    default:
      return RoleIdEnumType.InboundSalesAdvisor;
  }
};

export const getSelectedUsersByRoleAndTeamRole = (
  users: { id: string; name: string }[],
  usersByRolesObject: { [key in RoleIdEnumType]: UserByRole[] },
  RoleId: RoleIdEnumType,
) => {
  return users.length && usersByRolesObject[RoleId]?.length
    ? users.filter((user) =>
        usersByRolesObject[RoleId]?.map((u: UserByRole) => u.id).includes(user.id),
      ) || []
    : [];
};

export const groupTitlingUsersByPods = (allPodUsers: PodUser[]) => {
  const byPod = allPodUsers.reduce((acc, podUser) => {
    if (podUser.pod_id) {
      acc[podUser.pod_id] = acc[podUser.pod_id] ?? [];
      acc[podUser.pod_id].push(podUser);
    }

    return acc;
  }, {} as PodUsersByPod);

  return {
    ...byPod,
    all: allPodUsers,
    active: allPodUsers.filter((podUser) => !podUser.user?.deleted_at),
  };
};
