import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { formatISO } from 'date-fns';
import { toast } from 'react-toastify';

import { DealStateEnum, dealUpdateStateAndSendEmails } from '../../../gql/dealGql';

import { PermissionEnum } from '../../../constants/permissions';
import { Can } from '../../../libs/Can';
import { DealContext } from '../../../libs/DealContext';

const SendToSignaturesButton = () => {
  const { deal, dealRefetch, claimIsRequired } = useContext(DealContext);

  const [updateDealStateAndFirstPaymentDate] = useMutation(dealUpdateStateAndSendEmails);

  const handleSendForSignatures = async () => {
    try {
      await updateDealStateAndFirstPaymentDate({
        variables: {
          id: deal.id,
          state: DealStateEnum.SentForSignatures,
          date: formatISO(new Date()),
        },
      });
      toast.success('Moved deal to "Sent for Signatures"!');
      dealRefetch();
    } catch (e) {
      toast.error('Failed to move deal to "Sent for Signatures". Please refresh the page');
    }
  };

  return (
    <Can I={PermissionEnum.ManageSigningProcess}>
      <Button
        id={`closed-${deal?.customer?.first_name}-${deal?.customer?.last_name}`}
        onClick={handleSendForSignatures}
        isDisabled={claimIsRequired}
      >
        SEND FOR SIGNATURES
      </Button>
    </Can>
  );
};

export default SendToSignaturesButton;
