import { useEffect, useMemo, useRef } from 'react';

import { DebounceSettings, debounce } from 'lodash';

/**
 * Keeps the reference of the callback between re-renders.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDebounce = <T extends (...args: any[]) => R, R = void>(
  callback: T,
  wait = 2000,
  options?: DebounceSettings,
) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: Parameters<T>): ReturnType<T> => {
      return ref.current?.(...args) as ReturnType<T>;
    };

    return debounce(func, wait, options);
  }, []);

  return debouncedCallback;
};

export default useDebounce;
