import { useState } from 'react';

import { ModalType } from '../libs/contextLib';

const useModal = (prop?: ModalType) => {
  const [modals, _setModal] = useState(prop || {});

  const setModal = (thing: ModalType) => {
    const newModals = { ...modals, ...thing };
    _setModal(newModals);
  };

  return { modals, setModal };
};

export default useModal;
