import { Button } from '@chakra-ui/react';

import Modal from '../../../components/shared/Modal';

interface FinalizeStateStopModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
}

const FinalizeStateStopModal = ({
  isOpen,
  onClose,
  handleConfirm,
}: FinalizeStateStopModalProps) => {
  return (
    <Modal
      title="Finalize Deal"
      size="md"
      showDivider={false}
      isOpen={isOpen}
      onClose={onClose}
      leftButtons={
        <Button size="md" variant="warning" onClick={onClose}>
          Cancel
        </Button>
      }
      rightButtons={
        <Button size="md" onClick={() => handleConfirm()}>
          Confirm
        </Button>
      }
    >
      Are you sure you want to finalize the deal?
    </Modal>
  );
};

export default FinalizeStateStopModal;
