import { Box, Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { Formik } from 'formik';
import { BiEdit } from 'react-icons/bi';
import { toast } from 'react-toastify';

import {
  Maybe,
  StructuringFollowUp,
  useUpdateStructuringFollowUpNotesMutation,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';
import TextArea from '../shared/TextArea';

import DeleteStructuringFollowUp from './DeleteStructuringFollowUp';
import StructuringFollowUpButton from './StructuringFollowUpButton';
import { FormValues, validationSchema } from './validationSchema';

import { logger } from '../../libs/Logger';

interface EditStructuringFollowUpProps {
  structuringFollowUp: Maybe<StructuringFollowUp>;
  showIconButton?: boolean;
}

const EditStructuringFollowUp = ({
  structuringFollowUp,
  showIconButton = false,
}: EditStructuringFollowUpProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateStructuringFollowUpNotes, { loading }] = useUpdateStructuringFollowUpNotesMutation();

  const initialValues: FormValues = {
    notes: structuringFollowUp?.notes ?? '',
  };

  const handleSubmit = async (values: FormValues) => {
    const trimmedNotes = values.notes.trim();
    if (!structuringFollowUp?.id || !trimmedNotes) {
      return;
    }

    try {
      await updateStructuringFollowUpNotes({
        variables: {
          id: structuringFollowUp.id,
          notes: trimmedNotes,
        },
      });
      toast.success('Structuring Follow-Up edited');
      onClose();
    } catch (e) {
      const error = e as Error;
      logger.error('EditStructuringFollowUp.tsx', 'updateStructuringFollowUpNotes', error);
      toast.error(
        `Error editing Structuring Follow-Up: ${
          error.message || error
        }. Please refresh and try again.`,
      );
    }
  };

  return (
    <>
      {showIconButton ? (
        <Box pos="relative">
          <IconButton
            icon={<BiEdit />}
            aria-label="Edit Structuring Follow-Up"
            size="xs"
            variant="iconHover"
            onClick={(e) => {
              e.stopPropagation();
              onOpen();
            }}
            isLoading={loading}
          />
        </Box>
      ) : (
        <StructuringFollowUpButton hasStructuringFollowUp onOpen={onOpen} />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Modal
          title="Edit Structuring Queue"
          hasForm
          isOpen={isOpen}
          onClose={onClose}
          leftButtons={<DeleteStructuringFollowUp structuringFollowUp={structuringFollowUp} />}
          rightButtons={
            <Button type="submit" isLoading={loading} loadingText="SAVE CHANGES">
              SAVE CHANGES
            </Button>
          }
          scrollBehavior="outside"
        >
          <TextArea
            name="notes"
            label="Notes"
            minH="6em"
            maxH="12em"
            resize="vertical"
            maxLength={300}
          />
        </Modal>
      </Formik>
    </>
  );
};

export default EditStructuringFollowUp;
