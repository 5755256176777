import { useContext, useEffect, useState } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { File } from '../../gql/contractGql';
import {
  DealMediaTypeEnum,
  PaperworkType,
  useGenerateDocumentsLazyQuery,
} from '../../gql/generated/graphql';

import RequiredDocModal from '../DealInfoBuyout/components/RequiredDocModal';

import PreviewModals from './PreviewModals';

import { PermissionEnum } from '../../constants/permissions';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { canBankUseR1 } from '../../utils/financialInfos';
import { isDocumentProgressSent, isDocumentProgressValidated } from '../../utils/routeOne';

interface Props {
  useMiniButton?: boolean;
  width?: string;
}

const GenerateDocumentsButton = ({ useMiniButton, width }: Props) => {
  const { deal, documentMedia, isRecalculatingPayoff, dealRefetch } = useContext(DealContext);
  const { unverifiedRequiredMediaList, missingRequiredMediaList, internalMediaList } =
    documentMedia;
  const { modals, setModal } = useContext(ModalContext);
  const abilities = useContext(AbilityContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const requiredDocModal = useDisclosure();

  const [files, setFiles] = useState<File[]>([]);
  // Temporary: https://leaseend.atlassian.net/browse/LE-5471
  const [r1EContractGenerated, setR1EContractGenerated] = useState<boolean>(false);

  const [generateDocuments, { loading }] = useGenerateDocumentsLazyQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      if (
        !data.generateDocuments ||
        (!data.generateDocuments.errors?.length && !data.generateDocuments.files?.length)
      ) {
        toast.error('Failed to generate documents');
        return;
      }

      const { errors, files: newFiles } = data.generateDocuments;
      if (errors?.length) {
        errors.map((error) => toast.error(error, { autoClose: false }));
      }
      if (newFiles?.length) {
        setR1EContractGenerated(
          newFiles.some((file) => file?.mediaType === DealMediaTypeEnum.R1EContract),
        );

        const newFilesWithoutR1EContract = newFiles.filter(
          (file) => file?.mediaType !== DealMediaTypeEnum.R1EContract,
        );
        setFiles(newFilesWithoutR1EContract as File[]);
        setShowModal(true);
      }
    },
  });

  useEffect(() => {
    if (showModal && !modals.PreviewModals) {
      setModal({ PreviewModals: true });
      return;
    }

    // Refetch deal to get updated VSC/GAP ids and values.
    const recalculateInitialValues = async () => {
      await dealRefetch();
      setModal({ PreviewModals: false });
    };

    if (!showModal && modals.PreviewModals) {
      recalculateInitialValues();
    }
  }, [showModal]);

  const isDisabled =
    ((!deal.document_progress_status ||
      !isDocumentProgressValidated(deal.document_progress_status) ||
      isDocumentProgressSent(deal.document_progress_status)) &&
      !abilities.has(PermissionEnum.SuperUser) &&
      !abilities.has(PermissionEnum.FundingManager) &&
      deal.paperwork_type !== PaperworkType.Paper &&
      canBankUseR1(deal.financial_info.bank)) ||
    !abilities.has(PermissionEnum.GenerateDocuments) ||
    isRecalculatingPayoff;

  const shouldShowRequiredDocModal = () =>
    !requiredDocModal.isOpen &&
    (unverifiedRequiredMediaList.length > 0 || missingRequiredMediaList.length > 0);

  const handleGenerateDocuments = async () => {
    if (shouldShowRequiredDocModal()) {
      requiredDocModal.onOpen();
      return;
    }

    requiredDocModal.onClose();

    const internalMedia = internalMediaList.map((media) => media.type);

    await generateDocuments({
      variables: {
        data: {
          deal_id: deal.id,
          internal_media: internalMedia as DealMediaTypeEnum[],
        },
      },
    });
  };

  return (
    <>
      <Button
        size={useMiniButton ? 'sm' : 'lg'}
        width={width}
        variant="secondary"
        isLoading={loading}
        loadingText="GENERATE"
        isDisabled={isDisabled}
        onClick={handleGenerateDocuments}
      >
        GENERATE
      </Button>
      <RequiredDocModal
        {...requiredDocModal}
        unverifiedRequiredMediaList={unverifiedRequiredMediaList}
        missingRequiredMediaList={missingRequiredMediaList}
        onSuccessButtonLabel="Generate Documents"
        onSuccess={handleGenerateDocuments}
      />
      <PreviewModals
        files={files}
        show={showModal}
        setShowModal={setShowModal}
        r1EContractGenerated={r1EContractGenerated}
      />
    </>
  );
};

export default GenerateDocumentsButton;
