import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import { Deal, DealStateEnum, creditAppAutomate } from '../../../../gql/dealGql';
import { ConsentNameEnumType, useDealUpdateStateMutation } from '../../../../gql/generated/graphql';

import Tooltip from '../../../shared/Tooltip';
import NoHardConsentModal from '../modals/NoHardConsentModal';

import ROUTES from '../../../../constants/routes';
import useCanAutoStructure from '../../../../hooks/useCanAutoStructure';
import { DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';

const AutoStructureButton = () => {
  const { deal, isPayoffRequested, isRecalculatingPayoff, autosaving } = useContext(DealContext);
  const history = useHistory();

  const hasHardConsent = deal.consents?.some(
    (consent) => consent.type && consent.type === ConsentNameEnumType.CreditScoreHardPull,
  );

  const noHardConsentModalProps = useDisclosure();

  const { errors, isValid, isSubmitting, setSubmitting } = useFormikContext<Deal>();

  const { buyerCreditScoreIsAllowedForAutostructure } = useCanAutoStructure(deal);

  const isFollowUpScheduled = !!deal.follow_up;
  const isDisabled =
    !isValid ||
    isPayoffRequested ||
    isRecalculatingPayoff ||
    isFollowUpScheduled ||
    autosaving ||
    // This button is only shown in `structuring` and here, we only consider the buyer's credit score
    !buyerCreditScoreIsAllowedForAutostructure;

  const allErrors = {
    ...errors,
    ...(isPayoffRequested ? { payoffError: 'Payoff was requested' } : {}),
    ...(isFollowUpScheduled ? { followupError: 'Cannot have a current follow up scheduled' } : {}),
    ...(!buyerCreditScoreIsAllowedForAutostructure
      ? { creditScoreError: 'Buyer credit score is not allowed for auto structure' }
      : {}),
  };

  const [updateDealState] = useDealUpdateStateMutation();
  const [automateCreditApp] = useMutation(creditAppAutomate);

  const handleClick = async () => {
    if (!deal.id) {
      return;
    }

    if (!hasHardConsent) {
      noHardConsentModalProps.onOpen();
      return;
    }

    setSubmitting(true);
    try {
      await updateDealState({
        variables: {
          id: deal.id,
          state: DealStateEnum.StructuringInProgress,
        },
      });

      automateCreditApp({
        variables: {
          deal_id: deal.id,
        },
      });

      history.push(ROUTES.DASHBOARD);
    } catch (e) {
      logger.error('AutoStructureButton.tsx', '', null, e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Tooltip errors={allErrors}>
        <Button
          isLoading={isSubmitting}
          loadingText="AUTO STRUCTURE"
          isDisabled={isDisabled}
          onClick={handleClick}
        >
          AUTO STRUCTURE
        </Button>
      </Tooltip>
      {!hasHardConsent && <NoHardConsentModal {...noHardConsentModalProps} />}
    </>
  );
};

export default AutoStructureButton;
