import { date } from 'yup';
import { format, parse, isDate } from 'date-fns';
import { getAge } from '../utils';

export const minDate = new Date('1920-01-01');
export const minDateFormatted = format(minDate, 'MM/dd/yyyy');

const isEighteenOrOlder = (birthdate: Date) => getAge(birthdate) >= 18;
const isOlderThanMinDate = (birthdate: Date) => minDate < birthdate;
const dobValidator = (validator: (date: Date) => boolean, value?: Date | null) => {
  const birthdate = value ?? new Date();
  return validator(birthdate);
};
export const transformDateValue = (_: unknown, originalValue: string) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date());
  // eslint-disable-next-line no-restricted-globals
  return parsedDate instanceof Date && !isNaN(parsedDate as unknown as number) ? parsedDate : null;
};

export const testIsAdult = (value: Date | null | undefined) =>
  dobValidator(isEighteenOrOlder, value);

export const testIsOldEnough = (value: Date | null | undefined) =>
  dobValidator(isOlderThanMinDate, value);

export const dobValidation = (person?: string) =>
  date()
    .nullable()
    .transform(transformDateValue)
    .required(`${person || ''}Date of Birth is required`)
    .test('is-adult', `${person || ''}Must be 18 years old`, testIsAdult)
    .test(
      'is-not-too-old',
      `${person || ''}Date of Birth must be after ${minDateFormatted}`,
      testIsOldEnough,
    );
