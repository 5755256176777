import { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { DealStateEnum, dealCompleted } from '../../../gql/dealGql';
import { DealType } from '../../../gql/generated/graphql';

import SignConfirmationModal from '../../../components/Task/components/SignConfirmationModal';

import { PermissionEnum } from '../../../constants/permissions';
import { Can } from '../../../libs/Can';
import { DealContext } from '../../../libs/DealContext';

const MoveToFundedOrReadyForPickup = () => {
  const { deal, dealRefetch, claimIsRequired } = useContext(DealContext);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [completeDeal] = useMutation(dealCompleted);

  const handleCompleteDeal = async () => {
    try {
      await completeDeal({
        variables: {
          id: deal.id,
          state: deal.type === DealType.Acquisition ? DealStateEnum.PaidOff : DealStateEnum.Funded,
        },
      });
      toast.success('Deal Completed!');
      await dealRefetch();
      setShowModal(false);
    } catch {
      toast.error('Failed to complete the deal. Please refresh the page');
    }
  };

  return (
    <Can I={PermissionEnum.ManageSigningProcess}>
      <Button onClick={() => setShowModal(true)} isDisabled={claimIsRequired}>
        {deal.type === DealType.Acquisition ? 'MOVE TO PAID OFF' : 'FUND DEAL'}
      </Button>
      <SignConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleCompleteDeal={handleCompleteDeal}
      />
    </Can>
  );
};

export default MoveToFundedOrReadyForPickup;
