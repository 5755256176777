import React from 'react';
import './Home.css';

const Home: React.FC = () => {
  return (
    <div className="Home">
      <div className="lander">
        <h1>Lease End</h1>
        <p className="text-muted">Welcome to Lease End</p>
      </div>
    </div>
  );
};

export default Home;
