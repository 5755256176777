import { ComponentStyleConfig } from '@chakra-ui/react';

const mediaForm: ComponentStyleConfig = {
  parts: ['flexContainer', 'previewContainer', 'inputsContainer', 'metadataContainer'],
  baseStyle: {
    flexContainer: {
      display: 'flex',
      mb: 3,
      minH: '100px',
      alignItems: 'center',
      width: '100%',
    },
    previewContainer: {
      direction: 'column',
      display: 'contents',
    },
    inputsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    metadataContainer: {},
  },
  variants: {
    reduced: {
      flexContainer: {
        minH: '72px',
        alignItems: 'start',
      },
      previewContainer: {
        display: 'none',
      },
      inputsContainer: {
        flexDirection: 'row',
      },
      metadataContainer: { display: 'none' },
    },
  },
};

export default mediaForm;
