import { useMemo, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { InferType } from 'yup';

import { BankEnum } from '../../gql/bankGql';
import { Deal } from '../../gql/dealGql';
import { DealType, useNoteCreateMutation } from '../../gql/generated/graphql';
import { Note, NoteTypeEnum } from '../../gql/noteGql';

import DatePicker from '../shared/DatePicker';
import Input from '../shared/Input';
import LenderAutocomplete from '../shared/LenderAutocomplete';
import LienholderSelects from '../shared/LienholderSelects';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';
import { RadioGroup } from '../shared/RadioGroup';
import Select from '../shared/Select';
import Tooltip from '../shared/Tooltip';
import { Option } from '../shared/types';

import { useBanks } from '../../hooks/useBanks';
import { useUser } from '../../hooks/useUser';
import { logger } from '../../libs/Logger';
import { formatMoney, getTimezoneStr, passValuesToSchema } from '../../libs/utils';
import { RemoveIndex } from '../../utils/types';

const PAYOFF_AS = {
  LEASE_END: 'Lease End',
  TATER_FINANCE: 'Tater Finance',
} as const;

const validationSchema = Yup.object()
  .required()
  .shape({
    isRefi: Yup.boolean(),
    payoffAs: Yup.string().oneOf(Object.values(PAYOFF_AS)).required('Payoff As is required'),
    trackingId: Yup.string().required('Tracking ID is required'),
    lienholderSlug: Yup.string().when('isRefi', {
      is: false,
      then: Yup.string().required('Lienholder is required'),
    }),
    lienholderName: Yup.string().when('isRefi', {
      is: false,
      then: Yup.string().required('Lienholder is required'),
    }),
    lenderName: Yup.string().when('isRefi', {
      is: true,
      then: Yup.string().required('Lender is required'),
    }),
    checkHash: Yup.string().required('Check # is required'),
    checkDate: Yup.date().required('Check Date is required'),
    checkTotal: Yup.number().required('Check Total is required'),
    salesTaxPaid: Yup.boolean().required('Sales Tax Paid is required'),
    salesTaxTotal: Yup.number().when('salesTaxPaid', {
      is: true,
      then: Yup.number().required('Sales Tax Total is required'),
    }),
    doubleSalesTaxCollected: Yup.boolean().when('salesTaxPaid', {
      is: true,
      then: Yup.boolean().required('Double Sales Tax is required'),
    }),
    doubleSalesTaxTotal: Yup.number().when('doubleSalesTaxCollected', {
      is: true,
      then: Yup.number().required('Double Sales Tax Total is required'),
    }),
    fundingBank: Yup.string().oneOf(Object.values(BankEnum)).required('Funding Bank is required'),
  });

type FormValues = RemoveIndex<InferType<typeof validationSchema>>;
interface PayoffCheckInformationModalProps {
  deal: Deal;
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  notesRefetch?: () => Promise<ApolloQueryResult<Note[]>>;
}

const PayoffCheckInformationModal = ({
  deal,
  isOpen,
  onClose,
  handleConfirm,
  notesRefetch,
}: PayoffCheckInformationModalProps) => {
  const user = useUser();

  const [showVirtualLienholderSelect, setShowVirtualLienholderSelect] = useState<boolean>(false);

  const { banks } = useBanks({ onlyActive: true, deal });
  const [noteCreate] = useNoteCreateMutation();

  const bankOptions: Option[] = useMemo(
    () => banks.map((bank) => ({ label: bank.name ?? '', value: bank.name ?? '' })),
    [banks],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      isRefi: deal.type === DealType.Refi,
      payoffAs: PAYOFF_AS.LEASE_END,
      trackingId: '',
      lienholderSlug: deal.car?.payoff?.lienholder_slug ?? '',
      lienholderName: deal.car?.payoff?.lienholder_name ?? '',
      lenderName: deal.car?.payoff?.lender_name ?? '',
      checkHash: '',
      checkDate: new Date(),
      checkTotal: 0,
      salesTaxPaid: false,
      salesTaxTotal: 0,
      doubleSalesTaxCollected: false,
      doubleSalesTaxTotal: 0,
      fundingBank: deal.financial_info?.bank ?? '',
    }),
    [
      deal.type,
      deal.car?.payoff?.lienholder_slug,
      deal.car?.payoff?.lienholder_name,
      deal.car?.payoff?.lender_name,
      deal.financial_info?.bank,
    ],
  );

  const onSubmit = async (values: FormValues) => {
    const {
      isRefi,
      payoffAs,
      trackingId,
      lienholderName,
      lenderName,
      checkHash,
      checkDate,
      checkTotal,
      salesTaxPaid,
      salesTaxTotal,
      doubleSalesTaxCollected,
      doubleSalesTaxTotal,
      fundingBank,
    } = values;

    const text = [
      `Payoff finalized by ${user.name}:`,
      `- Payoff As: ${payoffAs}`,
      `- Tracking ID: ${trackingId}`,
      isRefi ? `- Lender Name: ${lenderName}` : `- Lienholder Name: ${lienholderName}`,
      `- Check #: ${checkHash}`,
      `- Check Date: ${format(checkDate, 'MM/dd/yyyy')}`,
      `- Check Total: ${formatMoney(checkTotal)}`,
      `- Sales Tax Paid: ${salesTaxPaid ? 'Yes' : 'No'}`,
      `- Sales Tax Total: ${formatMoney(salesTaxTotal)}`,
      `- Double Sales Tax Collected: ${doubleSalesTaxCollected ? 'Yes' : 'No'}`,
      `- Double Sales Tax Total: ${formatMoney(doubleSalesTaxTotal)}`,
      `- Funding Bank: ${fundingBank}`,
    ].join('\n');

    try {
      await noteCreate({
        variables: {
          note: {
            text,
            author_id: user.id,
            deal_id: deal.id,
            creation_date_tz: getTimezoneStr(),
            note_type: NoteTypeEnum.Manual,
            pinned: true,
          },
        },
      });
      await notesRefetch?.();
      onClose();

      handleConfirm();
    } catch (e) {
      const error = e as Error;
      logger.error('PayoffCheckInformationModal.tsx', '', null, error);
      toast.error(`Failed to create note: ${error.message}`);
    }
  };

  return (
    <Modal
      title="Payoff Check Information"
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      canDismiss={false}
    >
      <Formik
        initialValues={initialValues}
        validate={(values) => passValuesToSchema(values, validationSchema)}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, values, setFieldValue, errors, isValid, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Stack spacing="10px">
              <Select
                name="payoffAs"
                label="Payoff As"
                options={Object.values(PAYOFF_AS).map((value) => ({ value, label: value }))}
                emptyOption={false}
              />
              <Input name="trackingId" label="Tracking ID" />
              {deal.type === DealType.Refi ? (
                <LenderAutocomplete name="lenderName" isLocked={false} />
              ) : (
                <LienholderSelects
                  nameField="lienholderName"
                  slugField="lienholderSlug"
                  showVirtualLienholderSelect={showVirtualLienholderSelect}
                  setShowVirtualLienholderSelect={setShowVirtualLienholderSelect}
                  direction="column"
                  isLocked={false}
                />
              )}
              <Input name="checkHash" label="Check #" />
              <DatePicker name="checkDate" topLabel="Check Date" />
              <NumberInput name="checkTotal" label="Check Total" isMoney />
              <HStack spacing="auto">
                <Text fontWeight="bold" fontSize="14px">
                  Sales Tax Paid?
                </Text>
                <RadioGroup
                  value={!!values.salesTaxPaid}
                  onChange={(val) => setFieldValue('salesTaxPaid', val === 'true')}
                />
              </HStack>
              {values.salesTaxPaid && (
                <>
                  <NumberInput name="salesTaxTotal" label="Sales Tax Total" isMoney />
                  <HStack spacing="auto">
                    <Text fontWeight="bold" fontSize="14px">
                      Double Sales Tax Collected?
                    </Text>
                    <RadioGroup
                      value={!!values.doubleSalesTaxCollected}
                      onChange={(val) => setFieldValue('doubleSalesTaxCollected', val === 'true')}
                    />
                  </HStack>
                  {values.doubleSalesTaxCollected && (
                    <NumberInput
                      name="doubleSalesTaxTotal"
                      label="Double Sales Tax Total"
                      isMoney
                    />
                  )}
                </>
              )}
              <Select
                name="fundingBank"
                label="Funding Bank"
                options={bankOptions}
                emptyOption={false}
              />
            </Stack>

            <HStack spacing="auto" mt={5} mb={1}>
              <Button variant="warning" onClick={onClose} isDisabled={isSubmitting}>
                CANCEL
              </Button>

              <Tooltip errors={errors}>
                <Button
                  type="submit"
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                  loadingText="SAVE"
                >
                  SAVE
                </Button>
              </Tooltip>
            </HStack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PayoffCheckInformationModal;
