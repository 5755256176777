import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Button } from '@chakra-ui/react';
import { Deal, DealStateEnum } from '../../gql/dealGql';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';
import { logger } from '../../libs/Logger';

const showSaveButtonStates = [
  DealStateEnum.Structuring,
  DealStateEnum.ReadyForPickup,
  DealStateEnum.PaidOff,
  DealStateEnum.AtAuction,
  DealStateEnum.Sold,
];

const SaveButton: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<Deal>();
  const [isSaving, setSaving] = useState(false);
  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert);

  const onSave = (deal: Deal, setIsSaving: (isSaving: boolean) => void) => {
    upsertFinancialInfoAcquisition({
      variables: {
        financialInfoAcquisition: deal.financial_info_acquisition,
        id: deal.id,
        state: deal.state,
        car_id: deal.car?.id,
        mmr: deal.car?.mmr,
        vehicle_payoff: deal.car?.payoff?.vehicle_payoff,
      },
    })
      .then(({ data }) => {
        setFieldValue('financial_info_acquisition.id', data.financialInfoAcquisitionUpsert.id);
        toast.success('Deal saved');
      })
      .catch((e) => logger.error('SaveButton.tsx', '', null, e))
      .finally(() => setIsSaving(false));
  };

  return (
    <Button
      variant="secondary"
      isLoading={isSaving}
      loadingText="SAVE"
      hidden={showSaveButtonStates.indexOf(values.state) === -1}
      onClick={() => onSave({ ...values }, setSaving)}
    >
      SAVE
    </Button>
  );
};

export default SaveButton;
