import { FC, ReactElement, useContext, MouseEvent } from 'react';
import { HStack, IconButton, Td } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { BsFillPinFill, BsPin } from 'react-icons/bs';
import { RiPencilFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { useNoteDeleteMutation, useNoteUpdateMutation } from '../../gql/generated/graphql';

import { ModalDetails } from './index';

import { NotesContext } from '../../libs/contextLib';

interface ActionsProps {
  isNoteAuthor: boolean;
  setModalDetails: (modalDetails: ModalDetails) => void;
  setShowModal: (arg: boolean) => void;
  id: number;
  noteText: string;
  pinned: boolean;
  canManagePins: boolean;
  canEditNote: boolean;
  isNoteManual: boolean;
}

export const Actions: FC<ActionsProps> = ({
  isNoteAuthor,
  setModalDetails,
  setShowModal,
  id,
  noteText,
  pinned,
  canManagePins,
  canEditNote,
  isNoteManual,
}) => {
  const { notesRefetch } = useContext(NotesContext);

  const [deleteNote, { loading: deleteLoading }] = useNoteDeleteMutation();
  const [updateNote, { loading: updateLoading }] = useNoteUpdateMutation();

  const handleDelete = async () => {
    try {
      await deleteNote({
        variables: { id },
      });
      toast.success('Note Removed');
      notesRefetch();
    } catch (error) {
      toast.error('Failed to remove note.');
    } finally {
      setShowModal(false);
    }
  };

  const handleUpdate = async (text?: string) => {
    try {
      await updateNote({
        variables: {
          note: {
            text,
            id,
          },
        },
      });
      toast.success('Note Updated');
      notesRefetch();
    } catch (error) {
      toast.error('Failed to update note.');
    } finally {
      setShowModal(false);
    }
  };

  const handlePin = async (oldPinned: boolean) => {
    const newPinned = !oldPinned;
    try {
      await updateNote({
        variables: {
          note: {
            pinned: newPinned,
            id,
          },
        },
      });
      toast.success(`Note ${newPinned ? 'Pinned' : 'Unpinned'}`);
      notesRefetch();
    } catch (error) {
      toast.error(`Failed to ${newPinned ? 'pin' : 'unpin'} note.`);
    } finally {
      setShowModal(false);
    }
  };

  interface ActionButtonProps {
    icon: ReactElement;
    name: 'edit' | 'delete' | 'pin';
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    isDisabled?: boolean;
  }

  const ActionButton: FC<ActionButtonProps> = ({ icon, name, onClick, isDisabled }) => (
    <IconButton
      icon={icon}
      variant="iconHover"
      size="xs"
      color={name === 'delete' ? 'errorsRed' : undefined}
      aria-label={`${name} note`}
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );

  return (
    <Td w={10} paddingX={2}>
      <HStack alignItems="center" w="100%" spacing={1}>
        {/* Pin Button */}
        {isNoteAuthor || canManagePins || pinned ? (
          <ActionButton
            icon={pinned ? <BsFillPinFill /> : <BsPin />}
            name="pin"
            isDisabled={!canManagePins && pinned}
            onClick={() => {
              handlePin(pinned);
            }}
          />
        ) : null}
        {/* Edit Button */}
        {(isNoteAuthor || canEditNote) && isNoteManual ? (
          <ActionButton
            icon={<RiPencilFill />}
            name="edit"
            onClick={(e) => {
              e.stopPropagation();

              setModalDetails({
                title: 'Update Note',
                clickText: 'Submit',
                handleClick: handleUpdate,
                type: 'update',
                noteText,
                loading: updateLoading,
              });
              setShowModal(true);
            }}
          />
        ) : null}
        {/* Delete Button */}
        {isNoteAuthor && isNoteManual ? (
          <ActionButton
            name="delete"
            icon={<BiTrash />}
            onClick={(e) => {
              e.stopPropagation();

              setModalDetails({
                title: 'Do you really want to delete this note?',
                clickText: 'Delete',
                handleClick: handleDelete,
                type: 'delete',
                loading: deleteLoading,
              });
              setShowModal(true);
            }}
          />
        ) : null}
      </HStack>
    </Td>
  );
};
