import { Maybe } from '../gql/generated/graphql';

// Used to verify an object has a specific key of any type.
// Allows you to index with strings or numbers regardless of the original index type.
export const hasKey = <O extends object>(obj: O, key: PropertyKey): key is keyof O => key in obj;

export const objectsHaveSameValues = <T extends object>(
  previousObj: Maybe<T>,
  currentObj: T,
): boolean => {
  if (!previousObj) {
    return false;
  }

  const keysToCheck = Object.keys(currentObj) as (keyof T)[];

  return keysToCheck.every((key) => currentObj[key] === previousObj[key]);
};
