const breakpoints = {
  base: '0',
  xs: '375px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1400px',
  '3xl': '1600px',
  '4xl': '1800px',
};

export default breakpoints;
