import React, { Key, MouseEvent, useContext, useEffect, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Button, HStack, Spinner, VStack } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import { callOut } from '../../gql/callGql';
import { Customer, CustomerContactTypeEnum } from '../../gql/customerGql';
import { Deal } from '../../gql/dealGql';
import {
  useMessagesDbPaginatedQuery,
  useMessagesQuery,
  useOnReceivedMessageSubscription,
} from '../../gql/generated/graphql';
import { MediaListObj, Message } from '../../gql/messageGql';

import ContactPanel from './Components/ContactPanel';
import MessageBubble from './Components/Message';
import MessageInput from './Components/MessageInput';

import { ColorsEnum } from '../../libs/colorLib';
import { NotesContext, TextWidgetContext } from '../../libs/contextLib';
import { formatPhone } from '../../libs/utils';

interface TextModalProps {
  deal: Deal | null;
  children: React.ReactNode;
}

export interface ChatMessage {
  id?: string;
  date: Date;
  fromCustomer: boolean;
  author: string;
  data: {
    text?: string;
    code?: string;
    dateCreated?: string;
    mediaListObj: MediaListObj | null;
  };
}

export const getConversationColor = (role: string) => {
  switch (role) {
    case CustomerContactTypeEnum.Customer:
      return '#48BB78';
    case CustomerContactTypeEnum.Cobuyer:
      return 'teal.400';
    case CustomerContactTypeEnum.Contact:
      return 'blue.700';
    case CustomerContactTypeEnum.Second:
      return 'orange.400';
    default:
      return ColorsEnum.BLUE;
  }
};

export const getHoverColor = (role: string) => {
  switch (role) {
    case CustomerContactTypeEnum.Customer:
      return '#68D391';
    case CustomerContactTypeEnum.Cobuyer:
      return 'teal.300';
    case CustomerContactTypeEnum.Contact:
      return 'blue.600';
    case CustomerContactTypeEnum.Second:
      return 'orange.300';
    default:
      return ColorsEnum.BLUE;
  }
};

const TextModal: React.FC<TextModalProps> = ({ deal, children }) => {
  const { textDealId, activeConversation, unreadCount, setUnreadCount } =
    useContext(TextWidgetContext);
  const { pathname } = useLocation();
  const history = useHistory();
  const [recipient, setRecipient] = useState<Customer>(deal?.customer as Customer);
  const [showContacts, setShowContacts] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingOlderMessages, setLoadingOlderMessages] = useState<boolean>(false);
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [pagination, setPagination] = useState(1);

  const [messageList, setMessageList] = useState<ChatMessage[]>([]);
  const { notesRefetch } = useContext(NotesContext);

  const [callOutbound] = useMutation(callOut);
  const onCall = (phoneNum: string) => {
    callOutbound({
      variables: {
        calledNumber: formatPhone(phoneNum),
        id: textDealId,
      },
    });
  };

  const addMessages = (messages: Message[]) => {
    const chatMessages: ChatMessage[] = messages.map((m) => {
      return {
        id: m.id,
        date: new Date(m?.dateCreated || new Date()),
        fromCustomer: !!m.fromCustomer,
        author: m.senderName,
        data: {
          text: m.body,
          dateCreated: new Date(m.dateCreated ?? new Date())
            .toLocaleString('en-US', {
              day: 'numeric',
              month: 'short',
              weekday: 'short',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
            .replace(/,/g, (a, b) => {
              return b > 5 ? ' at' : ',';
            }),
          mediaListObj: m.mediaListObj as MediaListObj,
        },
      };
    });
    const newMessageList = [...messageList, ...chatMessages].reduce((acc, current) => {
      const messageAlreadyInWidget = acc.some((item) => item.id === current.id);
      if (!messageAlreadyInWidget) {
        return acc.concat([current]);
      }
      return acc;
    }, [] as ChatMessage[]);

    setMessageList(newMessageList.sort((a, b) => (a.date > b.date ? 1 : -1)));
  };

  const formattedPhone = formatPhone(activeConversation.phone_number ?? '');
  const dealPhoneNumbers = [
    deal?.customer.phone_number,
    deal?.customer.home_phone_number,
    deal?.cobuyer?.phone_number,
    deal?.cobuyer?.home_phone_number,
    deal?.contact?.phone_number,
    deal?.second_contact?.phone_number,
  ]
    .filter((phoneNumber) => phoneNumber)
    .map((phoneNumber) => formatPhone(phoneNumber));
  const skipMessagesQueries = !formattedPhone || !dealPhoneNumbers.includes(formattedPhone);

  useOnReceivedMessageSubscription({
    variables: {
      dealPhoneNumbers: [formattedPhone],
    },
    onData: ({ data }) => {
      if (data?.data?.onReceivedMessage) {
        // make sure message belongs to this conversation
        if (data.data.onReceivedMessage?.message?.from === formattedPhone) {
          addMessages([data.data.onReceivedMessage?.message as unknown as Message]);
          notesRefetch();
          setUnreadCount(unreadCount + 1);
        }
      }
    },
  });

  useMessagesQuery({
    variables: {
      dealPhoneNumber: formattedPhone,
    },
    skip: skipMessagesQueries,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.messages?.messages?.length) {
        const messages = data.messages.messages as unknown as Message[];
        addMessages(messages);
      } else {
        setLoading(false);
      }

      if (data?.messages?.hasErrors) {
        toast.error('Some messages failed to load.');
      }
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't fetch messages from server.");
    },
  });

  useMessagesDbPaginatedQuery({
    variables: {
      dealPhoneNumber: formattedPhone,
      dealId: deal?.id?.toString() ?? '',
      page: pagination,
    },
    skip: skipMessagesQueries,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.messagesDbPaginated?.messages.length) {
        const messages = data.messagesDbPaginated.messages as unknown as Message[];
        const previousScrollHeight = containerRef?.current?.scrollHeight ?? 0;
        const previousScrollTop = containerRef?.current?.scrollTop ?? 0;

        addMessages(messages);
        setUnreadCount(0);
        setHasMoreMessages(data.messagesDbPaginated.hasMoreMessages);
        if (containerRef.current) {
          // Set the scroll position to stay at the current position
          setTimeout(() => {
            if (containerRef.current) {
              const newScrollHeight = containerRef.current.scrollHeight;
              containerRef.current.scrollTop =
                newScrollHeight - previousScrollHeight + previousScrollTop;
            }
          }, 0);
        }
      } else {
        setLoading(false);
        setLoadingOlderMessages(false);
      }
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't fetch messages from database.");
    },
  });

  const fetchOlderMessages = (newPage: number) => {
    setLoadingOlderMessages(true);
    setPagination(newPage);
  };

  useEffect(() => {
    setLoading(true);
    setMessageList([]);
  }, [activeConversation.phone_number]);

  useEffect(() => {
    if (messageList?.length) {
      setLoading(false);
      setLoadingOlderMessages(false);
    }
  }, [messageList]);

  useEffect(() => {
    if (!loading && messageList?.length && !loadingOlderMessages) {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading, messageList]);

  return (
    <Box
      position="fixed"
      right="25px"
      bottom="50px"
      w="650px"
      h="620px"
      boxShadow="xl"
      borderRadius="15px"
      zIndex="modal"
      fontSize={16}
    >
      <HStack
        h="70px"
        bgColor="#09254A"
        color="white"
        justifyContent="space-between"
        p={3}
        borderRadius="15px 15px 0px 0px"
      >
        <HStack>
          <Box
            textDecoration="underline"
            ml={3}
            cursor="pointer"
            fontSize={18}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onCall(activeConversation.phone_number);
            }}
          >
            {activeConversation.phone_number}
          </Box>
          <Box
            cursor="pointer"
            fontSize={18}
            onClick={() => {
              const dealId = deal?.id?.toString();
              if (!pathname.includes(dealId ?? '')) {
                history.push(`/deals/${dealId}`);
              }
            }}
          >
            | {deal?.customer.first_name} {deal?.customer.last_name[0]}. - <u>Deal ID {deal?.id}</u>
          </Box>
        </HStack>
        {children}
      </HStack>
      <HStack bgColor="white" borderBottomRadius="15px" spacing={0} h="550px">
        <ContactPanel
          showContacts={showContacts}
          setShowContacts={setShowContacts}
          deal={deal}
          setRecipient={setRecipient}
        />
        <Box
          w={showContacts ? '65%' : '92%'}
          ml={0}
          pl={0}
          h="100%"
          bgColor="snowyWhite"
          borderBottomRadius="15px"
        >
          <SimpleReactLightbox>
            <SRLWrapper>
              <VStack ref={containerRef} h="374px" overflowY="scroll" align="unset" w="100%" p={4}>
                {loading ? (
                  <HStack h="65%" w="100%" align="center" justifyContent="center">
                    <Spinner />
                    <Box>Loading Messages...</Box>
                  </HStack>
                ) : messageList.length ? (
                  <>
                    {loadingOlderMessages ? (
                      <HStack h="65%" w="100%" align="center" justifyContent="center">
                        <Spinner />
                        <Box>Loading Messages...</Box>
                      </HStack>
                    ) : hasMoreMessages ? (
                      <Button onClick={() => fetchOlderMessages(pagination + 1)}>
                        Load More Messages
                      </Button>
                    ) : null}
                    {messageList.map((m, index) => (
                      <MessageBubble
                        key={index as Key}
                        message={m}
                        activeConversation={activeConversation}
                      />
                    ))}
                    <div ref={messagesEndRef} />
                  </>
                ) : (
                  <HStack h="65%" w="100%" align="center" justifyContent="center">
                    <Box>No messages to show. Start a conversation!</Box>
                  </HStack>
                )}
              </VStack>
            </SRLWrapper>
          </SimpleReactLightbox>

          <MessageInput
            deal={deal}
            phone={formattedPhone}
            recipient={recipient}
            messageList={messageList}
            setMessageList={setMessageList}
          />
        </Box>
      </HStack>
    </Box>
  );
};

export default TextModal;
