import { ChangeEvent, ChangeEventHandler, FC, useState } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Customer } from '../../../gql/customerGql';
import { Deal } from '../../../gql/dealGql';

import CardSubHeaderV2 from '../../shared/Card/components/CardSubHeaderV2';
import { GridFormColumn, GridFormRow } from '../../shared/GridForm';
import Input from '../../shared/Input';
import Select from '../../shared/Select';

import SELECT_OPTIONS from '../../../constants/selectOptions';
import { AutoExplodeZip } from '../../AutoExplodeZip';

interface Props extends BoxProps {
  name: keyof Pick<Deal, 'customer' | 'cobuyer'>;
  objectName: keyof Pick<Customer, 'address' | 'prev_address'>;
  isUneditable: boolean;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const AddressForm: FC<Props> = ({
  name,
  objectName,
  isUneditable,
  customHandleChange,
  ...rest
}) => {
  const { handleBlur, handleChange: formikHandleChange } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const isPrevAddress = objectName === 'prev_address';

  const [isAddressLineFocused, setAddressLineFocused] = useState<boolean>(false);
  const [zipLoading, setZipLoading] = useState<boolean>(false);

  return (
    <Box {...rest}>
      {isPrevAddress ? <CardSubHeaderV2 title="Previous Address" /> : null}
      <GridFormColumn>
        <GridFormRow minChildWidth={180}>
          <Input
            label="Address 1"
            name={`${name}.${objectName}.address_line`}
            onFocus={() => setAddressLineFocused(true)}
            onBlur={(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
              setAddressLineFocused(false);
              handleBlur(e);
            }}
            onChange={handleChange}
            isDisabled={isUneditable}
          />
          <Input
            label="Apt., Suite, Unit #"
            name={`${name}.${objectName}.address_line_2`}
            onChange={handleChange}
            isDisabled={isUneditable}
          />
        </GridFormRow>
        <GridFormRow>
          <Input
            label="Zip"
            name={`${name}.${objectName}.zip`}
            onChange={handleChange}
            isDisabled={isUneditable}
            mask="99999"
          />
          <Input
            label="City"
            name={`${name}.${objectName}.city`}
            onChange={handleChange}
            isDisabled={isUneditable}
            isLoading={zipLoading}
          />
          <Select
            label="State"
            emptyOption={false}
            placeholder="State"
            name={`${name}.${objectName}.state`}
            options={SELECT_OPTIONS.US_STATES}
            onChange={handleChange}
            isDisabled={isUneditable}
            isLoading={zipLoading}
          />
          <Input
            label="County"
            name={`${name}.${objectName}.county`}
            onChange={handleChange}
            isDisabled={isUneditable}
            isLoading={zipLoading}
          />
        </GridFormRow>
      </GridFormColumn>
      <AutoExplodeZip
        name={name}
        isPrevAddress={objectName === 'prev_address'}
        isAddressLineFocused={isAddressLineFocused}
        setZipLoading={setZipLoading}
      />
    </Box>
  );
};
