import { CheckIcon } from '@chakra-ui/icons';
import { Avatar, Box, IconButton } from '@chakra-ui/react';
import { RiExchangeDollarLine } from 'react-icons/ri';

interface StructuringFollowUpButtonProps {
  onOpen: () => void;
  hasStructuringFollowUp?: boolean;
}

const StructuringFollowUpButton = ({
  onOpen,
  hasStructuringFollowUp,
}: StructuringFollowUpButtonProps) => {
  return (
    <Box pos="relative">
      <IconButton
        aria-label={`${hasStructuringFollowUp ? 'Edit' : 'Add'} Structuring Follow-Up`}
        rounded="full"
        size="lg"
        variant="secondary"
        onClick={onOpen}
        icon={<RiExchangeDollarLine size={28} />}
      />
      {hasStructuringFollowUp ? (
        <Avatar
          icon={<CheckIcon />}
          size="xs"
          bg="tealGreen"
          pos="absolute"
          top={-1.5}
          right={-1.5}
        />
      ) : null}
    </Box>
  );
};

export default StructuringFollowUpButton;
