import Big from 'big.js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { Button } from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import { useLazyQuery, useMutation } from '@apollo/client';
import InputMask from 'react-input-mask';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import { Deal } from '../../gql/dealGql';
import { ColorEnum } from '../../gql/carGql';
import ConditionReport from '../ConditionReport';
import { DealContext } from '../../libs/DealContext';
import MmrButton from './MmrButton';
import { mmrQuery } from '../../gql/mmr';
import { determineApprovalQuery } from '../../gql/conditionReportGql';
import { InputCopyButton } from '../CopyButton/CopyButton';
import { NumberInput } from './FormComponents';
import SendToStructuringOrClosing from './SendToStructuringOrClosingButton';
import SaveButton from './SaveButtonSoftClose';
import { acquisitionOfferQuery } from '../../gql/acquisitionsGql';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';
import { ssnLast4Mask } from '../../constants/masks';

const SoftCloseView: React.FC = () => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext<Deal>();
  const { customer, car, condition_report, financial_info_acquisition } = values;
  const {
    payoff: { vehicle_payoff },
  } = car;
  const { max_total_cost, appraised_value } = financial_info_acquisition;
  const { deal } = useContext(DealContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const firstUpdate = useRef(true);

  let isConditionReportCompleted = false;
  if (
    deal?.condition_report?.accidents &&
    deal?.condition_report.tires &&
    deal?.condition_report.smoked_in !== null &&
    deal?.condition_report.lights_on_dash !== null &&
    deal?.condition_report.overall_condition
  ) {
    isConditionReportCompleted = true;
  }

  const [determineApproval] = useLazyQuery(determineApprovalQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ determineApprovalQuery: { approved } }) => {
      setFieldValue('financial_info_acquisition.is_approved', approved);
    },
  });

  const [getMmr] = useLazyQuery(mmrQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.mmr.average) {
        setFieldValue('car.mmr', data.mmr.average);
      } else {
        setFieldValue('car.mmr', (car?.book_value ?? 0) * 0.9);
      }
    },
  });

  const [acquisitionOffer] = useLazyQuery(acquisitionOfferQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ acquisitionOffer: { appraisedValue, offer } }) => {
      if (!appraised_value) {
        setFieldValue('financial_info_acquisition.appraised_value', appraisedValue);
      }
      setFieldValue('financial_info_acquisition.max_total_cost', offer);
    },
  });

  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert, {
    fetchPolicy: 'network-only',
    onCompleted: ({ financialInfoAcquisitionUpsert: { id } }) => {
      setFieldValue('financial_info_acquisition.id', id);
    },
  });

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (typeof deal.condition_report?.score === 'number') {
      getMmr({
        variables: {
          vin: values.car.vin,
          mileage: values.car.mileage,
          color: values.car.color,
          grade: deal.condition_report?.score,
          trim: values.car.kbb_trim_name,
          state: customer.address?.state,
        },
      });
    }
  }, [deal.condition_report?.score]);

  useEffect(() => {
    if (isConditionReportCompleted) {
      const accidents = condition_report?.accidents;
      const { mileage, mmr: mmr_value, book_value, vehicle_type, make } = car;
      const lienholder = car?.payoff?.lienholder_name;

      determineApproval({
        variables: {
          data: {
            accidents,
            tires: condition_report?.tires,
            exterior: condition_report?.exterior,
            interior: condition_report?.interior,
            smoked_in: condition_report?.smoked_in,
            lights_on_dash: condition_report?.lights_on_dash,
            overall_condition: condition_report?.overall_condition,
            year: car.year,
            payoff: vehicle_payoff,
            book_value,
            kbb_trim: car.kbb_trim_name,
            vin: car.vin,
            mileage,
            color: car.color,
            vehicle_type,
            state: customer.address?.state,
            financial_info_acquisition_id: values.financial_info_acquisition.id,
          },
        },
      });

      if (accidents && mileage && mmr_value && book_value && vehicle_type) {
        acquisitionOffer({
          variables: {
            accidents,
            mileage,
            mmr_value,
            book_value,
            vehicle_type,
            make,
            lienholder,
          },
        });
      }
    }
  }, [condition_report, car, isConditionReportCompleted]);

  useEffect(() => {
    if (max_total_cost && vehicle_payoff) {
      let maxCashToCustomer = new Big(max_total_cost).minus(vehicle_payoff).toNumber();

      if (maxCashToCustomer < 0) {
        maxCashToCustomer = 0;
      }

      setFieldValue('financial_info_acquisition.max_cash_to_customer', maxCashToCustomer);
    }
  }, [max_total_cost, vehicle_payoff]);

  useEffect(() => {
    upsertFinancialInfoAcquisition({
      variables: {
        financialInfoAcquisition: financial_info_acquisition,
        id: deal.id,
        state: deal.state,
        car_id: car?.id,
        mmr: car?.mmr,
        vehicle_payoff,
      },
    });
  }, [financial_info_acquisition]);

  return (
    <>
      <h5>Personal Info</h5>
      <Form.Row>
        <Form.Group as={Col} md="4">
          <Form.Control
            type="text"
            name="customer.first_name"
            placeholder="First Name"
            value={customer.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.customer?.first_name && !!errors.customer?.first_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.customer?.first_name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Control
            type="text"
            name="customer.middle_name"
            placeholder="Middle Name"
            value={customer.middle_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.customer?.middle_name && !!errors.customer?.middle_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.customer?.middle_name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Control
            type="text"
            name="customer.last_name"
            placeholder="Last Name"
            value={customer.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.customer?.last_name && !!errors.customer?.last_name}
          />
          <Form.Control.Feedback type="invalid">{errors.customer?.last_name}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="3">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Cell Phone Number"
              as={InputMask}
              mask="(999) 999-9999"
              name="customer.phone_number"
              value={customer.phone_number}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.customer?.phone_number && !!errors.customer?.phone_number}
            />
            <InputCopyButton value={customer.phone_number} isFormatted />
            <Form.Control.Feedback type="invalid">
              {errors.customer?.phone_number}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Home Phone Number"
              as={InputMask}
              mask="(999) 999-9999"
              name="customer.home_phone_number"
              value={customer.home_phone_number}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={
                touched.customer?.home_phone_number && !!errors.customer?.home_phone_number
              }
            />
            <InputCopyButton value={customer.home_phone_number} isFormatted />
            <Form.Control.Feedback type="invalid">
              {errors.customer?.phone_number}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Control
            type="text"
            placeholder="Email"
            name="customer.email"
            value={customer.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.customer?.email && !!errors.customer?.email}
          />
          <Form.Control.Feedback type="invalid">{errors.customer?.email}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="2">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>SSN</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="SSN"
              as={InputMask}
              mask={ssnLast4Mask}
              name="customer.ssn"
              value={values.customer?.ssn}
              isInvalid={touched.customer?.ssn && !!errors.customer?.ssn}
              disabled
            />
            <Form.Control.Feedback type="invalid">{errors.customer?.ssn}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Col>
          <h5>Vehicle Info</h5>
        </Col>
        <Col>
          <h5>Financial Info</h5>
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>VIN</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="VIN"
              name="car.vin"
              value={car.vin}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.car?.vin && !!errors.car?.vin}
            />
            <Form.Control.Feedback type="invalid">{errors.car?.vin}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Payoff</InputGroup.Text>
            </InputGroup.Prepend>
            <NumberInput isMoney path="car.payoff.vehicle_payoff" />
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>MILEAGE</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as={NumberFormat}
              thousandSeparator
              decimalScale={0}
              placeholder="MILEAGE"
              name="car.mileage"
              value={car.mileage}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.car?.mileage && !!errors.car?.mileage}
            />
            <Form.Control.Feedback type="invalid">{errors.car?.mileage}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>KBB Lending</InputGroup.Text>
            </InputGroup.Prepend>
            <NumberInput isMoney path="financial_info_acquisition.kbb_lending" />
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Color</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as="select"
              placeholder="Color"
              name="car.color"
              value={car?.color}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.car?.color && !!errors.car?.color}
            >
              <option key={0} value="">
                Select Color
              </option>
              {Object.values(ColorEnum).map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.car?.color}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>MMR</InputGroup.Text>
            </InputGroup.Prepend>
            <NumberInput isMoney path="car.mmr" />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>CR Score</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as={NumberFormat}
              name="condition_report.score"
              value={condition_report?.score}
            />
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <MmrButton />
        <Button
          size="lgWithIconRight"
          isLoading={showModal}
          loadingText="VIEW CONDITION REPORT"
          rightIcon={
            isConditionReportCompleted ? (
              <BiCheckCircle color="green" />
            ) : (
              <BiXCircle color="errorsRed" />
            )
          }
          name="condition-report"
          onClick={() => setShowModal(true)}
        >
          VIEW CONDITION REPORT
        </Button>
        <Modal
          show={showModal}
          backdrop="static"
          onHide={() => setShowModal(false)}
          keyboard={false}
          dialogClassName="condition-report-modal"
          className="text-center"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100">Condition Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ConditionReport setShowModal={setShowModal} />
          </Modal.Body>
        </Modal>
        <SaveButton />
        <SendToStructuringOrClosing />
      </Form.Row>
    </>
  );
};

export default SoftCloseView;
