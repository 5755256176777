import { Option } from '../components/shared/types';
import {
  emptySsnFullMask,
  emptySsnLast4Mask,
  emptySsnLast6Mask,
  ssnFullMask,
  ssnLast4Mask,
  ssnLast6Mask,
} from '../constants/masks';
import { Deal, isDeal } from '../gql/dealGql';
import { TemporaryData } from '../gql/generated/graphql';
import { LienholderPRSType } from '../gql/prs/types';

export enum SsnTypeEnum {
  Full = 'SSN_FULL',
  Last4 = 'SSN_LAST_4',
  Last6 = 'SSN_LAST_6',
}

export const ssnDetailMap: {
  [key: string]: { mask: string; label: string; emptyMask: string };
} = {
  [SsnTypeEnum.Full]: {
    mask: ssnFullMask,
    label: 'SSN',
    emptyMask: emptySsnFullMask,
  },
  [SsnTypeEnum.Last4]: {
    mask: ssnLast4Mask,
    label: 'Last 4 Of SSN',
    emptyMask: emptySsnLast4Mask,
  },
  [SsnTypeEnum.Last6]: {
    mask: ssnLast6Mask,
    label: 'Last 6 Of SSN',
    emptyMask: emptySsnLast6Mask,
  },
};

export const getSsnDetails = (
  ssnRequired?: string,
): { mask: string; label: string; emptyMask: string } | null => {
  if (!ssnRequired) {
    return null;
  }

  const ssnDetail = ssnDetailMap[ssnRequired as SsnTypeEnum];

  return ssnDetail;
};

export const getPayoffMethods = (payoffMethodOptions: {
  [key: string]:
    | string
    | {
        value: 'TRUE' | 'FALSE';
      };
}) =>
  Object.entries(payoffMethodOptions)
    .map(([key, value]) => ({ key, value: typeof value === 'string' ? value : value.value }))
    .filter(({ key, value }) => key !== 'id' && value !== 'FALSE');

export const getLienholderPhoneNumbers = (
  phoneNumbers: [
    {
      name: string;
      phone_numbers: [string];
    },
  ],
) => phoneNumbers?.flatMap((numbers) => numbers.phone_numbers);

export const getLienholderQueryVariables = (data: Deal | TemporaryData) => {
  if (isDeal(data)) {
    return {
      state: data.customer?.address?.state,
      slug: data.car?.payoff?.lienholder_slug,
    };
  }

  return {
    state: data?.state ?? '',
    slug: data?.lienholder_slug,
  };
};

export const mapPRSLienholdersToOptions = (lienholders: LienholderPRSType[]): Option[] =>
  lienholders.map((lienholder) => ({
    label: lienholder.display_name,
    value: lienholder.slug,
    enabled: lienholder.enabled,
  }));
