import { useMemo } from 'react';

import { Deal } from '../gql/dealGql';

import { LEExperianErrorEnum } from '../components/CreditApplication/components/Prequalification/PrequalificationSection';
import {
  checkStatusesPassed,
  getCreditScoreDetails,
  getPrequalCheckDetails,
  isCreditScoreAllowedForAutostructure,
} from '../utils/prequalification';

const useCanAutoStructure = (deal: Deal) => {
  const { customer, cobuyer, car } = deal;

  const vehicleAge = new Date().getFullYear() - Number(car?.year ?? 0);
  const ltv = customer?.prequalification?.ltv ?? 0;
  const paymentRatio = customer?.prequalification?.payment_to_income ?? 0;
  const mileage = car?.mileage ?? 0;
  const details = getPrequalCheckDetails(ltv, paymentRatio, mileage, vehicleAge);

  const buyerCreditScore = useMemo(
    () => (customer?.prequalification ? getCreditScoreDetails(customer?.prequalification) : null),
    [customer?.prequalification],
  );

  const cobuyerCreditScore = useMemo(
    () => (cobuyer?.prequalification ? getCreditScoreDetails(cobuyer?.prequalification) : null),
    [cobuyer?.prequalification],
  );

  const additionalChecksPassed = checkStatusesPassed([
    details.ltv.status,
    details.paymentRatio.status,
    details.mileage.status,
    details.vehicleAge.status,
  ]);

  const statuses = [buyerCreditScore?.status];
  if (cobuyerCreditScore?.status) {
    statuses.push(cobuyerCreditScore.status);
  }
  const creditScoresPassed = checkStatusesPassed(statuses);

  const buyerCreditScoreIsAllowedForAutostructure = isCreditScoreAllowedForAutostructure(
    buyerCreditScore?.number,
  );

  const isBuyerOrCobuyerFrozen =
    customer?.prequalification?.output?.errors?.includes(LEExperianErrorEnum.Frozen) ||
    cobuyer?.prequalification?.output?.errors?.includes(LEExperianErrorEnum.Frozen);

  const isBuyerOrCobuyerLocked =
    customer?.prequalification?.output?.errors?.includes(LEExperianErrorEnum.Locked) ||
    cobuyer?.prequalification?.output?.errors?.includes(LEExperianErrorEnum.Locked);

  const ret = {
    canAutoStructure: false,
    details,
    vehicleAge,
    ltv,
    paymentRatio,
    mileage,
    additionalChecksPassed,
    creditScoresPassed,
    buyerCreditScoreIsAllowedForAutostructure,
    buyerCreditScore,
    cobuyerCreditScore,
    isBuyerOrCobuyerFrozen,
    isBuyerOrCobuyerLocked,
  };
  if (additionalChecksPassed && (creditScoresPassed || buyerCreditScoreIsAllowedForAutostructure)) {
    ret.canAutoStructure = true;
  }
  return ret;
};

export default useCanAutoStructure;
