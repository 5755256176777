import React, { Dispatch, SetStateAction } from 'react';
import { FormikHelpers } from 'formik';
import { Box } from '@chakra-ui/react';

import { Note } from '../../gql/noteGql';
import { NewNote } from './NewNote';

interface NewNoteWidgetProps {
  setShowWidget: Dispatch<SetStateAction<boolean>>;
  onSubmit: (note: Note, { resetForm, setSubmitting }: FormikHelpers<Note>) => Promise<void>;
}

export const NewNoteWidget: React.FC<NewNoteWidgetProps> = ({ setShowWidget, onSubmit }) => {
  return (
    <Box
      position="fixed"
      left="40px"
      bottom="50px"
      w="650px"
      boxShadow="xl"
      borderRadius="15px"
      zIndex="modal"
      fontSize={16}
    >
      <NewNote setShowWidget={setShowWidget} onSubmit={onSubmit} showCancelButton height="300px" />
    </Box>
  );
};
