import { useContext, useEffect, useState } from 'react';

import { Flex, IconButton, NumberInputProps, Text, Tooltip } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BiCheckCircle, BiEditAlt, BiXCircle } from 'react-icons/bi';

import { Deal, DealStateEnum } from '../../../gql/dealGql';
import { getTotalTaxes, isVscSelected } from '../../../gql/financialInfoGql';

import NumberInput from '../../shared/NumberInput';
import { DebouncedSave } from '../DealInfoBuyoutForm';

import { InsertNoteIfValueManuallyChangedArgs } from './TitleAndRegistrationSection';

import { PermissionEnum } from '../../../constants/permissions';
import { DealContext } from '../../../libs/DealContext';
import { AbilityContext } from '../../../libs/contextLib';
import { formatMoney } from '../../../libs/utils';

interface TaxesProps {
  dealDetailsPageBeingEdited: boolean;
  debouncedSave: DebouncedSave;
  insertNoteIfValueManuallyChanged: (args: InsertNoteIfValueManuallyChangedArgs) => Promise<void>;
  fontSize?: Pick<NumberInputProps, 'fontSize'>['fontSize'];
}

export const Taxes = ({
  dealDetailsPageBeingEdited,
  debouncedSave,
  insertNoteIfValueManuallyChanged,
  fontSize = 'md',
}: TaxesProps) => {
  const { deal } = useContext(DealContext);
  const abilities = useContext(AbilityContext);

  const { values, errors, setFieldValue } = useFormikContext<Deal>();

  const [clickedEditTaxes, setClickedEditTaxes] = useState(false);

  const inStructuringOrClosing =
    deal.state === DealStateEnum.Structuring || deal.state === DealStateEnum.Closing;
  const canEditTaxes =
    values.financial_info.feesQueryFailed ||
    (abilities.has(PermissionEnum.EditTaxes) &&
      (dealDetailsPageBeingEdited || inStructuringOrClosing));

  const handleSaveTaxesClick = async () => {
    setFieldValue('financial_info.base_tax_amount', values.financial_info.newBaseTaxAmount ?? 0);

    await insertNoteIfValueManuallyChanged({
      fieldName: 'taxes',
      oldValue: deal.financial_info.base_tax_amount,
      newValue: values.financial_info.newBaseTaxAmount,
    });

    debouncedSave();
  };

  useEffect(() => {
    if (values.financial_info.totalTaxAmount === undefined) {
      setFieldValue('financial_info.totalTaxAmount', getTotalTaxes(values.financial_info));
    }

    if (values.financial_info.feesQueryFailed === undefined) {
      setFieldValue('financial_info.feesQueryFailed', false);
    }
  }, [values.financial_info.totalTaxAmount, values.financial_info.feesQueryFailed]);

  useEffect(() => {
    if (!clickedEditTaxes) {
      return;
    }

    setFieldValue('financial_info.totalTaxAmount', getTotalTaxes(values.financial_info));

    setClickedEditTaxes(false);
  }, [values.financial_info.base_tax_amount]);

  if (canEditTaxes && clickedEditTaxes) {
    return (
      <Tooltip
        isOpen={
          isVscSelected(values.financial_info?.option_type) &&
          (values.financial_info?.warranty_tax_amount ?? 0) > 0
        }
        hasArrow
        placement="top"
        aria-label="VSC Tax"
        label={`+ ${formatMoney(values.financial_info?.warranty_tax_amount)} (VSC Tax)`}
      >
        <Flex>
          <NumberInput autoFocus isMoney name="financial_info.newBaseTaxAmount" />
          <Flex alignItems="center" ml={1}>
            <IconButton
              icon={<BiXCircle />}
              variant="iconHover"
              aria-label="Cancel"
              onClick={() => {
                setClickedEditTaxes(false);
              }}
              size="xs"
            />
            <IconButton
              icon={<BiCheckCircle />}
              variant="iconHover"
              size="xs"
              aria-label="Save Taxes"
              isDisabled={
                !!errors.financial_info?.newBaseTaxAmount ||
                (values.financial_info.base_tax_amount ?? 0) ===
                  (values.financial_info.newBaseTaxAmount ?? 0)
              }
              onClick={handleSaveTaxesClick}
            />
          </Flex>
        </Flex>
      </Tooltip>
    );
  }

  return (
    <Flex alignItems="center" pt={2} pl={3}>
      <Text fontWeight="normal" fontSize={fontSize}>
        {formatMoney(values.financial_info?.totalTaxAmount)}
      </Text>
      {canEditTaxes ? (
        <IconButton
          icon={<BiEditAlt />}
          variant="iconHover"
          size="xs"
          aria-label="Edit Taxes"
          onClick={() => {
            setFieldValue('financial_info.newBaseTaxAmount', values.financial_info.base_tax_amount);
            setClickedEditTaxes(true);
          }}
        />
      ) : null}
    </Flex>
  );
};
