import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import Modal from '../../../components/shared/Modal';

interface DeleteArticleModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: (state: boolean) => void;
  handleDelete: () => void;
}

const DeleteArticleModal: React.FC<DeleteArticleModalProps> = ({
  showDeleteModal,
  setShowDeleteModal,
  handleDelete,
}) => {
  return (
    <Modal
      isOpen={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      title="Delete Article"
      showDivider={false}
      centerButtons={
        <Button variant="boot" size="md" onClick={() => handleDelete()}>
          Delete
        </Button>
      }
    >
      <Text align="center">Are you sure you want to delete this featured article?</Text>
    </Modal>
  );
};

export default DeleteArticleModal;
