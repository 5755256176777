import {
  AT_AUCTION,
  CLOSED,
  CLOSING,
  Deal,
  DealStateEnum,
  DealStateLabelMap,
  FUNDED,
  NEW_ESTIMATES,
  PAID_OFF,
  READY_FOR_PICKUP,
  SEND_PAYOFF,
  SENT,
  SENT_TO_PROCESSOR,
  SIGNED,
  SIGNING,
  SOFT_CLOSE,
  SOLD,
  STRUCTURING,
  TITLE_RECEIVED,
  TITLE_SENT,
  WAITING_FOR_TITLE,
} from '../gql/dealGql';

export type Swimlane = { [K in DealStateEnum]: Deal[] };

export const allSwimlanes = [
  {
    title: NEW_ESTIMATES,
    states: [DealStateEnum.Floor, DealStateEnum.Estimate],
  },
  {
    title: SOFT_CLOSE,
    states: [DealStateEnum.SoftClose],
  },
  {
    title: STRUCTURING,
    states: [DealStateEnum.StructuringInProgress, DealStateEnum.Structuring],
  },
  {
    title: CLOSING,
    states: [DealStateEnum.Closing],
  },
  {
    title: SIGNING,
    states: [DealStateEnum.Closed, DealStateEnum.SentForSignatures],
  },
  {
    title: CLOSED,
    states: [DealStateEnum.Closed],
  },
  {
    title: SENT,
    states: [DealStateEnum.SentForSignatures],
  },
  {
    title: SIGNED,
    states: [DealStateEnum.Signed],
  },
  {
    title: PAID_OFF,
    states: [DealStateEnum.PaidOff],
  },
  {
    title: READY_FOR_PICKUP,
    states: [DealStateEnum.ReadyForPickup],
  },
  {
    title: AT_AUCTION,
    states: [DealStateEnum.AtAuction],
  },
  {
    title: SOLD,
    states: [DealStateEnum.Sold],
  },
  {
    title: TITLE_SENT,
    states: [DealStateEnum.TitleSent],
  },
  {
    title: FUNDED,
    states: [DealStateEnum.Funded],
  },
  {
    title: SEND_PAYOFF,
    states: [DealStateEnum.SendPayoff],
  },
  {
    title: WAITING_FOR_TITLE,
    label: DealStateLabelMap[DealStateEnum.WaitingForTitle],
    states: [DealStateEnum.WaitingForTitle],
  },
  {
    title: TITLE_RECEIVED,
    label: DealStateLabelMap[DealStateEnum.TitleReceived],
    states: [DealStateEnum.TitleReceived],
  },
  {
    title: SENT_TO_PROCESSOR,
    label: DealStateLabelMap[DealStateEnum.SentToProcessor],
    states: [DealStateEnum.SentToProcessor],
  },
];

export const groupDealsByState = (deals: Deal[]): Swimlane =>
  deals.reduce((sl, deal) => {
    if (sl[deal.state]) {
      sl[deal.state].push(deal); // Push the deal to the swimlane that corresponds to its state
    } else {
      // eslint-disable-next-line no-param-reassign
      sl[deal.state] = [deal]; // If the swimlane doesn't exist, create it
    }

    return sl;
  }, {} as Swimlane);
