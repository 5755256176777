/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeEvent,
  ComponentProps,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Box, Flex, FlexProps, HStack, StyleProps, Text } from '@chakra-ui/react';
import { getIn, useFormikContext } from 'formik';
import { DebouncedFunc, startCase } from 'lodash';
import { toast } from 'react-toastify';

import { Bank } from '../../gql/bankGql';
import { DealDates, customDatesUpdate } from '../../gql/dealDatesGql';
import { Deal, DealStateEnum, dealInfoUpsert, isBeforeClosing, isTitling } from '../../gql/dealGql';
import { FinancialInfo, ProcessorEnum, processorUpdate } from '../../gql/financialInfoGql';
import {
  BanksQuery,
  DealType,
  Maybe,
  TtJurisdiction,
  useGetCreditDecisionByR1FsidQuery,
} from '../../gql/generated/graphql';

import Autosaving from '../CreditApplication/components/Autosaving';
import PrequalificationSection from '../CreditApplication/components/Prequalification/PrequalificationSection';
import EditDealInfoButton from '../EditDealInfoButton';
import { TotalPayoff } from '../TotalPayoff/TotalPayoff';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import NumberInput from '../shared/NumberInput';
import Switch from '../shared/Switch';
import { BankAndPaymentsSection } from './components/BankAndPaymentsSection';
import InputRow from './components/InputRow';
import PaymentOptions from './components/PaymentOptions/PaymentOptions';
import { SwitchesSection } from './components/SwitchesSection';
import { TitleAndRegistrationSection } from './components/TitleAndRegistrationSection';
import { TitlingSection } from './components/TitlingSection';
import { VscAndGapSection } from './components/VscAndGapSection';
import SaveEditButton from './components/buttons/SaveEditButton';
import SendPurchaseOrderButton from './components/buttons/SendPurchaseOrder';

import { CARD_PAYMENT_AMOUNT_MAX, CARD_PAYMENT_AMOUNT_MIN } from './constants';
import { validationSchema } from './validationSchema';

import { LDFlags } from '../../constants/experiments';
import { PermissionEnum } from '../../constants/permissions';
import useDebounce from '../../hooks/useDebounce';
import useFlag from '../../hooks/useFlag';
import { useSetValuesByDealType } from '../../hooks/useSetValuesByDealType';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { AbilityContext, BanksContext } from '../../libs/contextLib';
import { formatMoney, removeFailedValidation } from '../../libs/utils';
import DealProblem from '../../pages/DealDetail/DealProblem';
import DealStatesModal from '../../pages/DealDetail/DealStatesModal';
import { canSetProcessor } from '../../utils/deals';
import { isInputReadOnly } from '../../utils/permissions';

export const sectionMarginTop: StyleProps['mt'] = 12;
export const smallSectionMarginTop: StyleProps['mt'] = 5;
export const smallerSectionMarginTop: StyleProps['mt'] = 3;
export const switchContainerProps: Partial<ComponentProps<typeof Switch>> = {
  pt: 2,
  pl: 3,
  switchProps: {
    size: 'md',
  },
};
export const taterTitleInputFields: string[] = [
  'financial_info.plate_transfer',
  'financial_info.title_only',
  'customer.address.moved_states',
];

interface DebouncedSaveArgs {
  newValues?: Deal;
  forceSave?: boolean;
}
export type DebouncedSave = DebouncedFunc<(args?: DebouncedSaveArgs) => Promise<void>>;

interface DealInfoBuyoutFormProps {
  banks: Bank[];
  banksRefetch: () => Promise<ApolloQueryResult<BanksQuery>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  isEditable: boolean;
  jurisdiction: TtJurisdiction;
}

export const DealInfoBuyoutForm = ({
  banks,
  banksRefetch,
  isEditing,
  setIsEditing,
  jurisdiction,
  isEditable,
}: DealInfoBuyoutFormProps) => {
  const abilities = useContext(AbilityContext);
  const {
    deal,
    dispatch,
    isRecalculatingPayoff,
    setIsRecalculatingPayoff,
    userChangedSomething,
    setUserChangedSomething,
    isPayoffRequested,
  } = useContext(DealContext);
  const { values, setFieldValue, validateForm, handleChange } = useFormikContext<Deal>();

  const { data: getCreditDecisionByR1FSIDData } = useGetCreditDecisionByR1FsidQuery({
    skip: !deal?.financial_info?.new_lienholder?.r1_fsid || !deal?.id,
    variables: {
      deal_id: deal.id!,
      r1_fsid: deal.financial_info.new_lienholder?.r1_fsid || '',
    },
  });

  const applicationNumber =
    getCreditDecisionByR1FSIDData?.getCreditDecisionByR1FSID?.r1_application_number ?? '';

  const [autosaving, setAutosaving] = useState<boolean>(false);
  const [saveTime, setSaveTime] = useState<string>('');

  const [upsertDealInfo] = useMutation<{ dealInfoUpsert: Maybe<Deal> }>(dealInfoUpsert);
  const [updateCustomDates] =
    useMutation<{ customDatesUpdate: Maybe<DealDates> }>(customDatesUpdate);
  const [updateProcessor] = useMutation<{ updateProcessor: Maybe<FinancialInfo> }>(processorUpdate);

  const dealIsInTitlingState = isTitling(deal.state); // deal non-editable statuses
  const inClosing = deal.state === DealStateEnum.Closing;
  const inputIsReadOnlyByDealState = isInputReadOnly({
    abilities,
    dealState: values.state,
    determineReadOnlyByDealState: true,
    isEditing,
  });
  const inputIsReadOnlyByPermissions = isInputReadOnly({
    abilities,
    dealState: values.state,
    determineReadOnlyByDealState: false,
    isEditing,
  });

  const comDashboardDownPaymentEnabled = useFlag(LDFlags.COM_DASHBOARD_DOWN_PAYMENT);
  const showCardPaymentLimitToggle =
    comDashboardDownPaymentEnabled &&
    abilities.has(PermissionEnum.ChangeCardPaymentAmountLimit) &&
    (deal.financial_info?.money_down ?? 0) > CARD_PAYMENT_AMOUNT_MIN &&
    !isBeforeClosing(deal.state);

  const customSignedField = `deal_dates.custom_dates.${DealStateEnum.Signed}`;
  const customTitleReceivedField = `deal_dates.custom_dates.${DealStateEnum.TitleReceived}`;
  const processorField = 'financial_info.processor';
  const baseTaxAmountField = 'financial_info.base_tax_amount';
  const newBaseTaxAmountField = 'financial_info.newBaseTaxAmount';
  const totalFeeAmountField = 'financial_info.total_fee_amount';
  const customDateFields = [customSignedField, customTitleReceivedField];
  const manuallySaveFields = [
    ...customDateFields,
    processorField,
    baseTaxAmountField,
    newBaseTaxAmountField,
    totalFeeAmountField,
  ];

  const maxMarkupField = 'financial_info.maxMarkup';
  const tempUserEnteredReserveField = 'financial_info.tempUserEnteredReserve';
  const frontendOnlyFields = [maxMarkupField, tempUserEnteredReserveField];

  const bankFeesField = 'financial_info.bank_fees';
  const userEnteredReserveField = 'financial_info.user_entered_reserve';
  const cardPaymentAmountLimitField = 'financial_info.card_payment_amount_limit';
  const forceSaveFields = [bankFeesField, userEnteredReserveField, cardPaymentAmountLimitField];

  const isCustomDateField = (fieldName: string) => customDateFields.includes(fieldName);

  const sectionsContainerProps: FlexProps = {
    gap: 8,
    p: 4,
    direction: { base: 'column', xl: 'row' },
  };
  const sectionWidth: StyleProps['w'] = { base: '100%', xl: '50%' };
  const dividerProps: FlexProps = {
    borderColor: 'gray.100',
    borderWidth: '1px',
  };

  const autosaveTimer = () => {
    setSaveTime(new Date().toLocaleTimeString('en-US'));
    setAutosaving(true);
    setTimeout(() => setAutosaving(false), 900);
  };

  const debouncedSave = useDebounce(
    async ({ newValues, forceSave = false }: DebouncedSaveArgs = {}) => {
      if (!isEditable && !forceSave) {
        return;
      }

      // In some cases the `values` in the Formik state are not updated yet.
      const valuesToSave = newValues ?? values;
      const valuesToSaveWithoutFailures = removeFailedValidation(valuesToSave, validationSchema);

      autosaveTimer();
      try {
        const { data } = await upsertDealInfo({
          variables: {
            financialInfo: valuesToSaveWithoutFailures.financial_info,
            car: valuesToSaveWithoutFailures.car,
            customer: valuesToSaveWithoutFailures.customer,
          },
        });

        if (!data?.dealInfoUpsert) {
          toast.error('Failed to update Deal Info');
          return;
        }
        dispatch({ type: DealActionsEnum.UpdateDeal, payload: data.dealInfoUpsert });
      } catch (error) {
        toast.error('Failed to update Deal Info');
        logger.error('DealInfoBuyoutForm.tsx', 'Failed to update Deal Info', null, error);
      }
    },
  );

  const saveCustomDate = async (fieldName: string, newValue: string) => {
    setUserChangedSomething(true);
    const { deal_dates: dealDatesErrors } = await validateForm();

    if (
      dealIsInTitlingState &&
      !dealDatesErrors &&
      values?.deal_dates?.custom_dates &&
      Object.keys(values.deal_dates.custom_dates).length &&
      isCustomDateField(fieldName)
    ) {
      autosaveTimer();
      const dateKey = fieldName.split('.').pop();
      const dateLabel = `${startCase(dateKey)} Date`;

      if (dateKey) {
        try {
          const { data } = await updateCustomDates({
            variables: {
              deal_id: values.id,
              date_key: dateKey,
              date: new Date(newValue),
            },
          });
          if (!data?.customDatesUpdate) {
            toast.error(`Failed to update ${dateLabel}`);
            return;
          }

          dispatch({
            type: DealActionsEnum.UpdateDeal,
            payload: {
              deal_dates: { ...deal.deal_dates, ...data.customDatesUpdate },
            },
          });
          toast.success(`${dateLabel} updated`);
        } catch (error) {
          toast.error(`Failed to update ${dateLabel}`);
          logger.error('DealInfoBuyoutForm.tsx', '', null, error);
        }
      }
    }
  };

  const saveProcessor = async (newValue?: ProcessorEnum) => {
    setUserChangedSomething(true);
    const { financial_info: financialInfoErrors } = await validateForm();

    if (
      canSetProcessor(deal.state) &&
      values.id &&
      values.financial_info.id &&
      !financialInfoErrors?.processor
    ) {
      autosaveTimer();
      try {
        const { data } = await updateProcessor({
          variables: {
            deal_id: values.id,
            fi_id: values.financial_info.id,
            processor:
              newValue && Object.values(ProcessorEnum).includes(newValue) ? newValue : null,
          },
        });
        if (!data?.updateProcessor) {
          toast.error('Failed to update Processor');
          return;
        }

        dispatch({
          type: DealActionsEnum.UpdateDeal,
          payload: {
            financial_info: { ...deal.financial_info, ...data.updateProcessor },
          },
        });
        toast.success('Processor updated');
      } catch (error) {
        toast.error('Failed to update processor');
        logger.error('DealInfoBuyoutForm.tsx', 'Failed to update processor', null, error);
      }
    }
  };

  useEffect(() => {
    if (
      deal.financial_info?.first_payment_date &&
      deal.financial_info?.first_payment_date !== values.financial_info?.first_payment_date
    ) {
      setFieldValue('financial_info.first_payment_date', deal.financial_info.first_payment_date);
    }
  }, [deal.financial_info?.first_payment_date]);

  useEffect(() => {
    if (
      deal.financial_info?.money_down &&
      deal.financial_info.money_down <= CARD_PAYMENT_AMOUNT_MIN
    ) {
      setFieldValue('financial_info.card_payment_amount_limit', CARD_PAYMENT_AMOUNT_MIN);
    }
  }, [deal.financial_info?.money_down]);

  useSetValuesByDealType({ banks });

  const customHandleChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | undefined,
  ) => {
    setUserChangedSomething(true);
    handleChange(e);
  };

  // We can't call `debouncedSave` on the `customHandleChange` because values are not updated yet.
  useEffect(() => {
    // `setFieldTouched` makes the form dirty so we can't use `dirty` it here.
    // If we can remove those and trigger initial validations in a different way, `customHandleChange` can be removed.
    if (!userChangedSomething) {
      return;
    }

    const someManuallySavedFieldChanged = manuallySaveFields.reduce((isChanged, fieldName) => {
      if (isCustomDateField(fieldName)) {
        const dealDate = getIn(deal, fieldName);
        const dealISODate = dealDate ? new Date(dealDate).toISOString() : null;

        const valuesDate = getIn(values, fieldName);
        const valuesISODate = valuesDate ? new Date(valuesDate).toISOString() : null;

        return isChanged || dealISODate !== valuesISODate;
      }

      return isChanged || getIn(deal, fieldName) !== getIn(values, fieldName);
    }, false);
    const someFrontendOnlyFieldChanged = frontendOnlyFields.reduce(
      (isChanged, fieldName) => isChanged || getIn(deal, fieldName) !== getIn(values, fieldName),
      false,
    );
    const someForceSaveFieldChanged = forceSaveFields.reduce(
      (isChanged, fieldName) => isChanged || getIn(deal, fieldName) !== getIn(values, fieldName),
      false,
    );
    if (
      (someManuallySavedFieldChanged || someFrontendOnlyFieldChanged) &&
      !someForceSaveFieldChanged
    ) {
      return;
    }

    debouncedSave({ newValues: values, forceSave: someForceSaveFieldChanged });
  }, [values]);

  const handleCardLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserChangedSomething(true);
    if (e.target.checked) {
      setFieldValue('financial_info.card_payment_amount_limit', CARD_PAYMENT_AMOUNT_MAX);
    } else {
      setFieldValue('financial_info.card_payment_amount_limit', CARD_PAYMENT_AMOUNT_MIN);
    }
  };

  return (
    <>
      <CardHeaderV2 title="Deal Details" showPodColor pod={deal.pod} variant="rounded">
        <Autosaving autosaving={autosaving} saveTime={saveTime} />
        <DealStatesModal />
        <DealProblem deal={deal} />
      </CardHeaderV2>
      <Flex bg="queenBlue" justifyContent="space-between" alignItems="center">
        <Box p={2} color="white" fontWeight="bold" fontSize="lg">
          Bank Application #: {applicationNumber}
        </Box>
        <Box p={2}>
          <PrequalificationSection inStructuringOrBeyond />
        </Box>
      </Flex>

      <BanksContext.Provider value={{ banks, banksRefetch }}>
        <PaymentOptions customHandleChange={customHandleChange} debounceSave={debouncedSave} />
      </BanksContext.Provider>

      <Flex {...sectionsContainerProps}>
        <Flex direction="column" w={sectionWidth}>
          <InputRow label="Vehicle Payoff">
            <NumberInput
              name="car.payoff.vehicle_payoff"
              isMoney
              showThousandSeparator
              additionalHandleChange={customHandleChange}
              isDisabled={inputIsReadOnlyByPermissions || !isEditable || isPayoffRequested}
            />
          </InputRow>

          <InputRow label="Money Down">
            <NumberInput
              name="financial_info.money_down"
              isMoney
              showThousandSeparator
              additionalHandleChange={customHandleChange}
              isDisabled={inputIsReadOnlyByDealState || !isEditable}
            />
          </InputRow>

          {showCardPaymentLimitToggle && (
            <InputRow label="Card Limit">
              <Switch
                id="card_payment_amount_limit"
                name="financial_info.card_payment_amount_limit"
                isNoYes
                noYesFontSize="sm"
                noLabel="$1k"
                yesLabel="$3k"
                customHandleChange={handleCardLimitChange}
                switchProps={{
                  isChecked:
                    values.financial_info.card_payment_amount_limit === CARD_PAYMENT_AMOUNT_MAX,
                }}
                py={2}
              />
            </InputRow>
          )}

          <InputRow label="Bank Fees">
            <NumberInput
              name="financial_info.bank_fees"
              isMoney
              showThousandSeparator
              additionalHandleChange={customHandleChange}
            />
          </InputRow>

          <TitleAndRegistrationSection
            mt={sectionMarginTop}
            isEditable={isEditable}
            debouncedSave={debouncedSave}
            jurisdiction={jurisdiction}
            customHandleChange={customHandleChange}
          />
          {deal.type !== DealType.Refi ? (
            <SwitchesSection
              mt={sectionMarginTop}
              isEditable={isEditable}
              jurisdiction={jurisdiction}
              customHandleChange={customHandleChange}
            />
          ) : null}
        </Flex>

        <Flex direction="column" {...dividerProps} />

        <Flex direction="column" w={sectionWidth}>
          <BankAndPaymentsSection
            isEditable={isEditable}
            inputIsReadOnlyByPermissions={inputIsReadOnlyByPermissions}
            banks={banks}
            setUserChangedSomething={setUserChangedSomething}
          />

          <EditDealInfoButton
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            mt={smallSectionMarginTop}
          />
          <SaveEditButton
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            mt={smallSectionMarginTop}
          />

          <VscAndGapSection
            mt={smallerSectionMarginTop}
            isEditable={isEditable}
            inputIsReadOnlyByPermissions={inputIsReadOnlyByPermissions}
            customHandleChange={customHandleChange}
          />

          <TitlingSection
            mt={sectionMarginTop}
            dealIsInTitlingState={dealIsInTitlingState}
            customSignedField={customSignedField}
            customTitleReceivedField={customTitleReceivedField}
            customHandleChange={customHandleChange}
            saveCustomDate={saveCustomDate}
            saveProcessor={saveProcessor}
          />
        </Flex>
      </Flex>

      <Flex {...sectionsContainerProps}>
        <Flex direction="column" w={sectionWidth}>
          <HStack visibility="hidden" mt={6}>
            {inClosing && <SendPurchaseOrderButton />}
          </HStack>
        </Flex>

        <Flex visibility="hidden" direction="column" {...dividerProps} />

        <Flex direction="column" w={sectionWidth}>
          <InputRow label="Amount Financed">
            <Text p={2} bgColor="azureishWhite" fontSize="26px" fontWeight="normal">
              {formatMoney(values.financial_info?.amount_financed)}
            </Text>
          </InputRow>
        </Flex>
      </Flex>

      {isEditable ? (
        <TotalPayoff
          hidden
          isRecalculating={isRecalculatingPayoff}
          setIsRecalculating={setIsRecalculatingPayoff}
        />
      ) : null}

      <Flex direction="row" {...dividerProps} />
    </>
  );
};
