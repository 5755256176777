import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect } from 'react';

import { Box, Checkbox, Flex, HStack, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { DealMediaTypeEnum } from '../../../../../gql/generated/graphql';
import {
  DealMedia,
  InsuranceCardMetadata,
  InsuranceCardMetadataValueFields,
} from '../../../../../gql/mediaGql';

import DatePicker from '../../../../shared/DatePicker';
import Input from '../../../../shared/Input';
import Select from '../../../../shared/Select';
import { dateDoesNotMatchMessage, stringDoesNotMatchMessage } from '../validations';

import FormError from './FormError';

import { MediaModalType } from '..';
import SELECT_OPTIONS from '../../../../../constants/selectOptions';
import { DealContext } from '../../../../../libs/DealContext';
import { formatDate } from '../../../../../libs/utils';
import { removeWhiteSpaces } from '../../../../../utils/text';

interface InsuranceFormProps {
  setIsVerifiable: (verifiable: boolean) => void;
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  isLoading: boolean;
  applicationValues: InsuranceCardMetadata;
  requiredFields?: Map<InsuranceCardMetadataValueFields, boolean>;
}

const InsuranceForm: React.FC<InsuranceFormProps> = ({
  setIsVerifiable,
  showModal,
  setShowModal,
  isLoading,
  applicationValues,
  requiredFields,
}) => {
  const { deal } = useContext(DealContext);
  const {
    values,
    handleChange,
    setFieldValue,
    initialValues,
    errors: formikErrors,
    validateForm,
  } = useFormikContext<DealMedia>();
  const { metadata } = values as { metadata: InsuranceCardMetadata };
  const { metadata: errors } = formikErrors as { metadata: InsuranceCardMetadata | undefined };

  useEffect(() => {
    // Validates form on first render.
    // This is needed to avoid enabled verification checkboxes when form is invalid.
    if (!isLoading) {
      validateForm();
    }
  }, [isLoading]);

  useEffect(() => {
    if (metadata && showModal.dealMedia?.type === DealMediaTypeEnum.FrontOfInsuranceCard) {
      setTimeout(
        () =>
          setIsVerifiable(
            (!requiredFields?.has('name') || !!metadata.nameVerified) &&
              (!requiredFields?.has('state') || !!metadata.stateVerified) &&
              (!requiredFields?.has('vin') || !!metadata.vinVerified) &&
              (!requiredFields?.has('expirationDate') || !!metadata.expirationDateVerified) &&
              (!requiredFields?.has('insuranceCompany') || !!metadata.insuranceCompanyVerified) &&
              (!requiredFields?.has('policyNo') || !!metadata.policyNoVerified),
          ),
        0,
      );
    }

    setTimeout(
      () =>
        setShowModal({
          ...showModal,
          dealMedia: {
            ...showModal.dealMedia,
            verified: !!values.verified,
            metadata: metadata as { [key: string]: string | boolean } | undefined,
          },
        }),
      0,
    );
  }, [metadata, values.verified, requiredFields]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const isNewValue =
      values?.metadata &&
      e.target?.value !== values.metadata[e.target?.name.replace('metadata.', '')];

    if (isNewValue) {
      setFieldValue(`${e.target.name}Verified`, false);
      setFieldValue('verified', false);
    }

    handleChange(e);
  };

  return (
    <Box
      marginBottom={2}
      hidden={isLoading || showModal.dealMedia?.type !== DealMediaTypeEnum.FrontOfInsuranceCard}
    >
      <Flex direction="column" justifyContent="center">
        <VStack hidden={!requiredFields?.has('name')}>
          <HStack w="100%">
            <Input
              label="Name:"
              name="metadata.name"
              formControlVariant="inline"
              onChange={onChange}
              showErrorMessage={false}
            />
            <Checkbox
              isChecked={metadata?.nameVerified as unknown as boolean}
              name="metadata.nameVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={!!errors?.name}
            />
          </HStack>
          <FormError
            fieldName="name"
            showError={!!errors?.name}
            documentValue={(values.metadata?.name as string) ?? ''}
            applicationValue={applicationValues.name}
          />
        </VStack>
        <VStack hidden={!requiredFields?.has('state')}>
          <HStack w="100%">
            <Select
              label="State:"
              name="metadata.state"
              formControlVariant="inline"
              options={SELECT_OPTIONS.US_STATES}
              defaultValue={initialValues.metadata?.state as string | undefined}
              emptyOption
              onChange={onChange}
              showErrorMessage={false}
            />
            <Checkbox
              isChecked={metadata?.stateVerified as unknown as boolean}
              name="metadata.stateVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={!!errors?.state}
            />
          </HStack>
          <FormError
            fieldName="state"
            showError={!!errors?.state}
            documentValue={(values.metadata?.state as string) ?? ''}
            applicationValue={applicationValues.state}
          />
        </VStack>
        <VStack hidden={!requiredFields?.has('vin')}>
          <HStack w="100%">
            <Input
              label="Vin:"
              name="metadata.vin"
              formControlVariant="inline"
              onChange={onChange}
              showErrorMessage={false}
            />
            <Checkbox
              isChecked={metadata?.vinVerified as unknown as boolean}
              name="metadata.vinVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={!!errors?.vin}
            />
          </HStack>
          <FormError
            fieldName="vin"
            showError={!!errors?.vin}
            documentValue={(values.metadata?.vin as string) ?? ''}
            applicationValue={applicationValues.vin}
          />
        </VStack>
        <VStack hidden={!requiredFields?.has('expirationDate')}>
          <HStack w="100%">
            <DatePicker
              topLabel="Exp. Date:"
              name="metadata.expirationDate"
              valueFormat="dateUTC"
              additionalHandleChange={onChange}
              showErrorMessage={false}
              isInvalid={
                (!!applicationValues.expirationDate &&
                  errors?.expirationDate === dateDoesNotMatchMessage) ||
                (!!errors?.expirationDate && errors?.expirationDate !== dateDoesNotMatchMessage)
              }
              formControlVariant="inline"
              boxStyles={{
                w: 'full',
              }}
            />
            <Checkbox
              isChecked={metadata?.expirationDateVerified as unknown as boolean}
              name="metadata.expirationDateVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={
                !!errors?.expirationDate && errors?.expirationDate !== dateDoesNotMatchMessage
              }
            />
          </HStack>
          <FormError
            fieldName="expirationDate"
            showError={!!metadata?.expirationDate && !!errors?.expirationDate}
            documentValue={formatDate(metadata?.expirationDate, 'utc')}
            applicationValue={formatDate(applicationValues.expirationDate, 'utc')}
            canUpdateApplication={errors?.expirationDate === dateDoesNotMatchMessage}
          />
        </VStack>
        <VStack hidden={!requiredFields?.has('cobuyerOnInsurance')} marginTop={2}>
          <FormError
            fieldName="cobuyerOnInsurance"
            showError={!!deal.cobuyer && !!requiredFields?.has('cobuyerOnInsurance')}
            documentValue={metadata?.cobuyerOnInsurance ?? ''}
          />
        </VStack>
        <VStack hidden={!requiredFields?.has('insuranceCompany')}>
          <HStack w="100%">
            <Input
              label="Insurance Company:"
              name="metadata.insuranceCompany"
              formControlVariant="inline"
              onChange={onChange}
              showErrorMessage={false}
              isInvalid={
                (!!applicationValues.insuranceCompany &&
                  errors?.insuranceCompany === stringDoesNotMatchMessage) ||
                (!!errors?.insuranceCompany &&
                  errors?.insuranceCompany !== stringDoesNotMatchMessage)
              }
            />
            <Checkbox
              isChecked={metadata?.insuranceCompanyVerified as unknown as boolean}
              name="metadata.insuranceCompanyVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={!removeWhiteSpaces(metadata?.insuranceCompany)}
            />
          </HStack>
          <FormError
            fieldName="insuranceCompany"
            showError={!!errors?.insuranceCompany}
            documentValue={(values.metadata?.insuranceCompany as string) ?? ''}
            applicationValue={applicationValues.insuranceCompany}
            documentValueIsValid={!!removeWhiteSpaces(metadata?.insuranceCompany)}
            canUpdateApplication
          />
        </VStack>
        <VStack hidden={!requiredFields?.has('policyNo')}>
          <HStack w="100%">
            <Input
              label="Policy No.:"
              name="metadata.policyNo"
              formControlVariant="inline"
              onChange={onChange}
              showErrorMessage={false}
              isInvalid={
                (!!applicationValues.policyNo && errors?.policyNo === stringDoesNotMatchMessage) ||
                (!!errors?.policyNo && errors?.policyNo !== stringDoesNotMatchMessage)
              }
            />
            <Checkbox
              isChecked={metadata?.policyNoVerified as unknown as boolean}
              name="metadata.policyNoVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={!removeWhiteSpaces(metadata?.policyNo)}
            />
          </HStack>
          <FormError
            fieldName="policyNo"
            showError={!!errors?.policyNo}
            documentValue={(values.metadata?.policyNo as string) ?? ''}
            applicationValue={applicationValues.policyNo}
            documentValueIsValid={!!removeWhiteSpaces(metadata?.policyNo)}
            canUpdateApplication
          />
        </VStack>
      </Flex>
    </Box>
  );
};

export default InsuranceForm;
