import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Select as ChakraSelect, Flex, FormLabel } from '@chakra-ui/react';
import { getIn, useFormikContext } from 'formik';

import { Deal, isUneditable } from '../../gql/dealGql';

import Select from './Select';

import { OtherLienholderSlug } from '../../constants/lienholders';
import { useLienholders } from '../../hooks/useLienholders';

interface LienholderSelectsProps {
  showVirtualLienholderSelect: boolean;
  setShowVirtualLienholderSelect: Dispatch<SetStateAction<boolean>>;
  direction?: 'row' | 'column';
  isLocked?: boolean;
  slugField?: string;
  nameField?: string;
}

const LienholderSelects = ({
  showVirtualLienholderSelect,
  setShowVirtualLienholderSelect,
  direction = 'row',
  isLocked,
  slugField = 'car.payoff.lienholder_slug',
  nameField = 'car.payoff.lienholder_name',
}: LienholderSelectsProps) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext<Deal>();

  const [virtualLienholderValue, setVirtualLienholderValue] = useState<string>('');

  const isDisabled = isLocked ?? isUneditable(values.state);
  const fieldLabel = 'Lienholder';

  useEffect(() => {
    setShowVirtualLienholderSelect(virtualLienholderValue === OtherLienholderSlug);
  }, [virtualLienholderValue]);

  const {
    options: allLienholdersOptions,
    probableOptions: makeLienholdersOptions,
    loading: lienholdersLoading,
  } = useLienholders({
    state: values.customer?.address?.state,
    make: values.car?.make,
  });

  const actualSelectOptions = showVirtualLienholderSelect
    ? allLienholdersOptions
    : makeLienholdersOptions;

  const setLienholderNameAndSlug = ({
    slug,
    virtualSlug,
    shouldValidate = true,
  }: {
    slug: string;
    virtualSlug?: string;
    shouldValidate?: boolean;
  }) => {
    if (virtualSlug !== undefined) {
      setVirtualLienholderValue(virtualSlug);
    }

    const lienholderName = slug
      ? allLienholdersOptions.find((option) => option.value === slug)?.label ?? ''
      : '';

    setFieldValue(slugField, slug, shouldValidate);
    setFieldValue(nameField, lienholderName, shouldValidate);
  };

  useEffect(() => {
    const lienholderSlug = getIn(values, slugField);
    if (!makeLienholdersOptions.length || !lienholderSlug) {
      return;
    }

    const selectedIsIncludedInMakeLienholders = makeLienholdersOptions.some(
      (makeLienholderOption) => makeLienholderOption.value === lienholderSlug,
    );
    const newVirtualSlug =
      !selectedIsIncludedInMakeLienholders || lienholderSlug === OtherLienholderSlug
        ? OtherLienholderSlug
        : '';
    setLienholderNameAndSlug({
      slug: lienholderSlug,
      virtualSlug: newVirtualSlug,
      shouldValidate: false,
    });
  }, [makeLienholdersOptions]);

  return (
    <Flex direction={direction} gap={2}>
      {showVirtualLienholderSelect ? (
        <Box w="100%">
          <FormLabel whiteSpace="nowrap" fontSize="sm">
            {fieldLabel}
          </FormLabel>
          <ChakraSelect
            mb={direction === 'column' ? 2 : 0}
            placeholder={`Select a ${fieldLabel}`}
            value={virtualLienholderValue}
            onChange={(e) => {
              if (e.target.value !== OtherLienholderSlug) {
                setLienholderNameAndSlug({
                  slug: e.target.value,
                  virtualSlug: '',
                });
              }
            }}
            isDisabled={isDisabled}
          >
            {makeLienholdersOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </ChakraSelect>
        </Box>
      ) : null}
      <Select
        label={showVirtualLienholderSelect ? `Other ${fieldLabel}` : fieldLabel}
        emptyOption={false}
        placeholder={`Select a ${fieldLabel}`}
        id={slugField}
        name={slugField}
        options={actualSelectOptions}
        isLoading={lienholdersLoading}
        onChange={(e) => {
          if (!showVirtualLienholderSelect && e.target.value === OtherLienholderSlug) {
            setLienholderNameAndSlug({
              slug: '',
              shouldValidate: false,
              virtualSlug: OtherLienholderSlug,
            });
            return;
          }

          setLienholderNameAndSlug({
            slug: e.target.value,
          });

          handleChange(e);
        }}
        onBlur={handleBlur}
        isDisabled={isDisabled}
        isInvalid={getIn(touched, slugField) && !!getIn(errors, slugField)}
      />
    </Flex>
  );
};

export default LienholderSelects;
