import { Box, Icon } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdClear } from 'react-icons/md';
import { DealMediaEdit } from '../../../gql/mediaGql';

interface Props {
  media: DealMediaEdit;
  onClear: () => void;
}

export const FileSelect: React.FC<Props> = ({ media, onClear }) => {
  const [showUnselect, setShowUnselect] = useState<boolean>(false);
  return (
    <Box
      onMouseOver={() => setShowUnselect(true)}
      onMouseOut={() => setShowUnselect(false)}
      cursor="pointer"
    >
      <Box
        position="relative"
        bgColor="blue.300"
        border="1px solid"
        borderColor="blue.400"
        borderRadius="5px"
        w="40px"
        h="40px"
        fontSize="16px"
        display="flex"
        justifyContent="center"
        top={showUnselect ? '10px' : '0px'}
      >
        <Box alignSelf="center">{media.extension?.toUpperCase()}</Box>
      </Box>
      {showUnselect && (
        <Box
          borderRadius="50%"
          bgColor="#C40606"
          w="5"
          h="5"
          color="white"
          display="flex"
          justifyContent="center"
          border="1px solid"
          borderColor="red.600"
          cursor="pointer"
          position="relative"
          left="28px"
          bottom="38px"
          onClick={() => onClear()}
        >
          <Icon as={MdClear} boxSize={4} alignSelf="center" />
        </Box>
      )}
    </Box>
  );
};

export default FileSelect;
