import { FC, ReactNode, useRef, useState } from 'react';

import { Box, BoxProps, Heading, IconButton } from '@chakra-ui/react';
import { RiPencilFill } from 'react-icons/ri';

import { Pod } from '../../../../gql/podGql';

import { PodColor } from '../../PodColor';

type Variant = 'square' | 'rounded';

interface CardHeaderV2Props extends BoxProps {
  title: string;
  children?: ReactNode;
  onClick?: () => void;
  showPodColor?: boolean;
  pod?: Pod;
  variant?: Variant;
}

const variants: Record<Variant, BoxProps> = {
  square: {},
  rounded: { borderRadius: '10px 10px 0 0' },
};

const CardHeaderV2: FC<CardHeaderV2Props> = ({
  title,
  children,
  onClick,
  showPodColor,
  pod,
  variant = 'rounded',
  ...rest
}) => {
  const [hoveringPodColor, setHoveringPodColor] = useState(false);
  const podColorRef = useRef<HTMLDivElement>(null);

  const variantProps = variants[variant];

  const defaultTitlePadding = showPodColor ? '10px' : 0;

  return (
    <Box
      bgColor="oxfordBlue"
      color="white"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      textAlign="center"
      p="12px"
      position="relative"
      borderLeft="none"
      minH="48px"
      {...variantProps}
      {...rest}
    >
      {showPodColor ? (
        <PodColor
          ref={podColorRef}
          pod={pod}
          borderTopLeftRadius="8px"
          onMouseEnter={() => {
            setHoveringPodColor(true);
          }}
          onMouseLeave={() => {
            setHoveringPodColor(false);
          }}
        >
          {pod?.name}
        </PodColor>
      ) : null}
      <Heading
        fontSize="16px"
        fontWeight="medium"
        paddingLeft={
          hoveringPodColor ? `${podColorRef.current?.offsetWidth ?? 0}px` : defaultTitlePadding
        }
      >
        {title}
      </Heading>
      {children}
      {onClick ? (
        <IconButton
          icon={<RiPencilFill />}
          variant="icon"
          size="xs"
          aria-label="Edit Info"
          onClick={onClick}
        />
      ) : null}
    </Box>
  );
};

export default CardHeaderV2;
