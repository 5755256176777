import { useEffect, useMemo, useReducer } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  FiltersAction as PayoffFiltersAction,
  FiltersState as PayoffFiltersState,
  filtersReducer as payoffFiltersReducer,
  getFiltersStates as payoffGetFiltersStates,
} from '../components/PayoffDealsTable/util';
import {
  FiltersAction as TitlingFiltersAction,
  FiltersState as TitlingFiltersState,
  filtersReducer as titlingFiltersReducer,
  getFiltersStates as titlingGetFiltersStates,
} from '../components/TableView/utils';
import ROUTES from '../constants/routes';
import {
  FiltersActions as GlobalFiltersAction,
  FiltersStates as GlobalFiltersState,
  filtersReducer as globalFiltersReducer,
  getFiltersStates as globalGetFiltersStates,
} from '../globalFiltersUtils';
import { formatDateISO } from '../libs/utils';
import {
  FiltersActions as CompletedFiltersActions,
  FiltersStates as CompletedFiltersStates,
  filtersReducer as completedFiltersReducer,
  getFiltersStates as completedGetFiltersStates,
} from '../pages/CompletedDealsPage/utils';

export type CombinedFilterStates = {
  global: GlobalFiltersState;
  titling: TitlingFiltersState;
  completed: CompletedFiltersStates;
  payoff: PayoffFiltersState;
};

export type CombinedFilterActions =
  | GlobalFiltersAction
  | TitlingFiltersAction
  | CompletedFiltersActions
  | PayoffFiltersAction;

interface ObjectWithId {
  id: string;
}

const isObjectWithId = (value: unknown) => (value as ObjectWithId).id !== undefined;

export const getSearchParams = (search: string) => {
  const urlSearchParams = new URLSearchParams(search);

  return Object.fromEntries(urlSearchParams);
};

export const getSearchString = <T extends object>(state: T) => {
  const searchArray = Object.keys(state).map((key) => {
    const value = state[key as keyof T];
    if (value === undefined) {
      return '';
    }

    const type = typeof state[key as keyof T];
    if (type === 'object' && value instanceof Date) {
      return `${key}=${formatDateISO(value as Date)}`;
    }
    if (type === 'object' && isObjectWithId(value)) {
      return `${key}=${(value as unknown as ObjectWithId).id}`;
    }
    if (type === 'object' && Array.isArray(value)) {
      return value.length ? `${key}=${value.join(',')}` : '';
    }

    return `${key}=${value}`;
  });

  return searchArray.length > 0
    ? `?${searchArray.filter((searchParam) => searchParam !== '').join('&')}`
    : '';
};

export const useUrlQueryParamsWithMultipleReducers = () => {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const searchParams = useMemo(() => getSearchParams(search), [search]);

  const combinedReducers = useMemo(
    () =>
      (state: CombinedFilterStates, action: CombinedFilterActions): CombinedFilterStates => ({
        global: globalFiltersReducer(state.global, action as GlobalFiltersAction),
        titling: titlingFiltersReducer(state.titling, action as TitlingFiltersAction),
        completed: completedFiltersReducer(state.completed, action as CompletedFiltersActions),
        payoff: payoffFiltersReducer(state.payoff, action as PayoffFiltersAction),
      }),
    [],
  );

  const initialStates = useMemo(
    (): CombinedFilterStates => ({
      global: globalGetFiltersStates(searchParams),
      titling: titlingGetFiltersStates(searchParams),
      completed: completedGetFiltersStates(searchParams),
      payoff: payoffGetFiltersStates(searchParams),
    }),
    [searchParams],
  );

  // Because the dispatch is only one, Actions are a union so have to be named differently to call the correct reducer.
  const [filters, dispatch] = useReducer(combinedReducers, initialStates);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const baseRoutes = [
      ROUTES.DASHBOARD,
      ROUTES.COMPLETED_DEALS,
      ROUTES.FOLLOW_UPS,
      ROUTES.REVIVE_BOOT,
      ROUTES.STRUCTURING_FOLLOW_UPS,
    ] as string[];
    if (!baseRoutes.includes(pathname)) {
      return;
    }

    const flattenedFilters = Object.values(filters).reduce(
      (acc, state) => ({ ...acc, ...state }),
      {},
    );

    history.push(`${pathname}${getSearchString(flattenedFilters)}`);
  }, [history, pathname, filters, isAuthenticated]);

  return {
    filters,
    dispatch,
  };
};
