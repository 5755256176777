import { extendTheme } from '@chakra-ui/react';

import Button from './components/button';
import Checkbox from './components/checkbox';
import FormControl from './components/formControl';
import Input from './components/input';
import MediaEditForm from './components/mediaEditForm';
import MediaForm from './components/mediaForm';
import Modal from './components/modal';
import Radio from './components/radio';
import Select from './components/select';
import Spinner from './components/spinner';
import SwitchComponent from './components/switch';
import Text from './components/text';
import Textarea from './components/textarea';

import breakpoints from './breakpoints';
import fonts from './fonts';
import colors from './foundations/colors';
import styles from './styles';

const customTheme = extendTheme({
  styles,
  breakpoints,
  fonts,
  colors,
  components: {
    Button,
    FormControl,
    Modal,
    MediaForm,
    MediaEditForm,
    Text,
    Spinner,
    Switch: SwitchComponent,
    Checkbox,
    Input,
    Select,
    Textarea,
    Radio,
  },
});

export default customTheme;
