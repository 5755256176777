import { Customer } from '../gql/customerGql';
import { DealContact } from '../gql/dealContactGql';
import { Customer as GeneratedCustomer } from '../gql/generated/graphql';

export const getNamesObject = (
  fullName?: string,
): Pick<Customer | DealContact, 'first_name' | 'middle_name' | 'last_name'> => {
  if (!fullName) {
    return {
      first_name: '',
      middle_name: '',
      last_name: '',
    };
  }

  const nameParts = fullName.split(' ').filter((part) => part !== '');
  if (nameParts.length === 1) {
    return {
      first_name: fullName,
      middle_name: '',
      last_name: '',
    };
  }
  if (nameParts.length === 2) {
    return {
      first_name: nameParts[0],
      middle_name: '',
      last_name: nameParts[1],
    };
  }

  return {
    first_name: nameParts[0],
    middle_name: nameParts.slice(1, nameParts.length - 1).join(' '),
    last_name: nameParts[nameParts.length - 1],
  };
};

// TODO: move the logic from `CreditPerson` here and rename it to `cleanCustomer`.
// This is called in the Apollo middleware
export const cleanCustomerPrequalification = (customer: GeneratedCustomer) => {
  const cleanedCustomer = { ...customer };

  delete cleanedCustomer.prequalification;

  return cleanedCustomer;
};
