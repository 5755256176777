import { useContext, useEffect, useMemo, useState } from 'react';

import { Box, HStack, SimpleGrid, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { FormikErrors, useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { Deal } from '../../../../gql/dealGql';
import {
  Customer as GeneratedCustomer,
  Deal as GeneratedDeal,
  TtGetFeesSourceType,
  useGetDdSoftPullLazyQuery,
} from '../../../../gql/generated/graphql';

import CardSubHeaderV2 from '../../../shared/Card/components/CardSubHeaderV2';
import Tooltip from '../../../shared/Tooltip';

import PrequalificationButton from './PrequalificationButton';
import PrequalificationModal from './PrequalificationModal';

import { usePrequalificationInputValues } from '../../../../hooks/usePrequalificationInputValues';
import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';
import { passValuesToSchema } from '../../../../libs/utils';
import { mapDealToGetPaymentEstimateVariables } from '../../../../utils/deals';
import { objectsHaveSameValues } from '../../../../utils/objects';
import {
  PrequalificationStatusType,
  getPrequalificationInputErrors,
} from '../../../../utils/prequalification';
import { validationSchema } from '../../validationSchema';

// Needs to match backend. Couldn't be codegened
export enum LEExperianErrorEnum {
  Frozen = 'FROZEN',
  Locked = 'LOCKED',
  NoRecordFound = 'NO_RECORD_FOUND',
}

const shouldThrowError = (errors: string[]) =>
  errors.some(
    (error) =>
      error !== LEExperianErrorEnum.Frozen &&
      error !== LEExperianErrorEnum.Locked &&
      error !== LEExperianErrorEnum.NoRecordFound,
  );

interface PrequalificationSectionProps {
  inStructuringOrBeyond?: boolean;
}

const PrequalificationSection = ({
  inStructuringOrBeyond = false,
}: PrequalificationSectionProps) => {
  const { deal, dispatch, everyoneIsPrequalified, setEveryoneIsPrequalified, autosaving } =
    useContext(DealContext);
  const { customer, cobuyer } = deal;

  const { values, errors } = useFormikContext<Deal>();

  const [prequalificationStatus, setPrequalificationStatus] =
    useState<PrequalificationStatusType>('Unstarted');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasCobuyer = inStructuringOrBeyond ? !!deal.cobuyer : values.addCobuyer;

  const prequalificationInputErrors = useMemo(() => {
    let errorsCustomer = errors?.customer;
    let errorsCobuyer = errors?.cobuyer;
    if (inStructuringOrBeyond) {
      // Validate again because `DealInfoBuyout` doesn't have the customer and cobuyer fields
      const dealWithAddCobuyer = { ...deal, addCobuyer: hasCobuyer };

      const structuringErrors = passValuesToSchema(dealWithAddCobuyer, validationSchema);
      errorsCustomer = structuringErrors.customer;
      errorsCobuyer = structuringErrors.cobuyer;
    }
    const customerErrors = getPrequalificationInputErrors(errorsCustomer, true);
    const cobuyerErrors = getPrequalificationInputErrors(
      errorsCobuyer as FormikErrors<GeneratedCustomer>,
    );

    return [...customerErrors, ...cobuyerErrors];
  }, [
    inStructuringOrBeyond,
    hasCobuyer,
    deal.customer,
    deal.cobuyer,
    errors?.customer,
    errors?.cobuyer,
  ]);

  const canPrequalify = !prequalificationInputErrors.length;

  const [getPrequalification, { loading }] = useGetDdSoftPullLazyQuery({
    fetchPolicy: 'network-only',
  });

  const prequalifyPerson = async (shouldForceRetry?: boolean) => {
    try {
      if (!deal.id) {
        return;
      }

      const prequalResult = await getPrequalification({
        variables: {
          dealId: deal.id,
          paymentEstimateInput: mapDealToGetPaymentEstimateVariables(
            deal as GeneratedDeal,
            TtGetFeesSourceType.DdPrequalification,
          ).data,
          shouldForceRetry,
        },
      });

      const prequalResultData = prequalResult.data?.getDDSoftPull;
      if (!prequalResultData) {
        throw new Error(prequalResult.error?.message);
      }

      const shouldThrowErrorForCustomer = shouldThrowError(
        prequalResultData.customer.output?.errors || [],
      );
      const shouldThrowErrorForCobuyer = shouldThrowError(
        prequalResultData.cobuyer?.output?.errors || [],
      );

      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          customer: {
            ...deal.customer,
            prequalification: prequalResultData.customer,
          },
          ...(!!deal.cobuyer && {
            cobuyer: {
              ...deal.cobuyer,
              ...(!!prequalResultData.cobuyer && {
                prequalification: prequalResultData.cobuyer,
              }),
            },
          }),
        },
      });

      if (shouldThrowErrorForCustomer || shouldThrowErrorForCobuyer) {
        let errorMessage = '';
        if (shouldThrowErrorForCustomer) {
          errorMessage = `Customer: ${prequalResultData?.customer.output?.errors?.join(', ')}`;
        }
        if (shouldThrowErrorForCobuyer) {
          errorMessage += `Cobuyer: ${prequalResultData?.cobuyer?.output?.errors?.join(', ')}`;
        }
        throw new Error(errorMessage);
      }

      toast.success('Successfully prequalified drivers on the deal');
    } catch (e) {
      const error = e as Error;
      logger.error('PrequalificationSection.tsx', '', null, error);
      toast.error(`Experian Errors: ${error.message}`, {
        autoClose: false,
      });
    }
  };

  const handleClickPrequalify = async () => {
    if (everyoneIsPrequalified) {
      onOpen();
      return;
    }

    await prequalifyPerson();

    onOpen();
  };

  const customerPrequalificationCurrentInput = usePrequalificationInputValues({
    isCustomer: true,
    deal,
  });
  const cobuyerPrequalificationCurrentInput = usePrequalificationInputValues({
    isCustomer: false,
    deal,
  });

  useEffect(() => {
    const customerPrequalificationPreviousInput = customer.prequalification?.input;
    const customerPrequalifiedWithCurrentValues =
      customer.prequalification?.ltv != null &&
      customer.prequalification?.payment_to_income != null &&
      objectsHaveSameValues(
        customerPrequalificationPreviousInput,
        customerPrequalificationCurrentInput,
      );

    if (!hasCobuyer) {
      setEveryoneIsPrequalified(customerPrequalifiedWithCurrentValues);
      return;
    }

    const cobuyerPrequalificationPreviousInput = cobuyer?.prequalification?.input;
    const cobuyerPrequalifiedWithCurrentValues =
      cobuyer?.prequalification?.ltv != null &&
      cobuyer?.prequalification?.payment_to_income != null &&
      objectsHaveSameValues(
        cobuyerPrequalificationPreviousInput,
        cobuyerPrequalificationCurrentInput,
      );

    setEveryoneIsPrequalified(
      customerPrequalifiedWithCurrentValues && cobuyerPrequalifiedWithCurrentValues,
    );
  }, [
    hasCobuyer,
    customer.prequalification,
    cobuyer?.prequalification,
    customerPrequalificationCurrentInput,
    cobuyerPrequalificationCurrentInput,
  ]);

  const statusColor =
    prequalificationStatus === 'Review'
      ? 'primary'
      : prequalificationStatus === 'Passed'
      ? 'green'
      : prequalificationStatus === 'Credit Locked' || prequalificationStatus === 'Credit Frozen'
      ? 'errorsRed'
      : 'ceruleanBlue';

  const button = (
    <Tooltip errors={prequalificationInputErrors}>
      <PrequalificationButton
        isLoading={loading}
        isDisabled={loading || !canPrequalify || autosaving}
        onClick={handleClickPrequalify}
      />
    </Tooltip>
  );

  return (
    <>
      {inStructuringOrBeyond ? (
        button
      ) : (
        <Box>
          <CardSubHeaderV2 title="Prequalification" />
          <SimpleGrid
            templateColumns={{
              base: '1fr',
              sm: '3fr 1fr',
            }}
          >
            <Stack p={4}>
              <Text fontWeight="normal">
                By clicking Prequalify, I confirm I have obtained consent from the driver to perform
                a soft credit pull.
              </Text>
              <HStack justifyContent="center">
                <Text color="black" fontWeight="bold" fontSize="18px">
                  Status:
                </Text>
                <Text fontWeight="bold" fontSize="18px" color={statusColor}>
                  {prequalificationStatus}
                </Text>
              </HStack>
            </Stack>
            <Stack bgColor="azureishWhite" justifyContent="center" alignItems="center" p={2}>
              {button}
            </Stack>
          </SimpleGrid>
        </Box>
      )}
      <PrequalificationModal
        hasCobuyer={hasCobuyer}
        isOpen={isOpen}
        onClose={onClose}
        setPrequalificationStatus={setPrequalificationStatus}
        prequalifyPerson={prequalifyPerson}
      />
    </>
  );
};

export default PrequalificationSection;
