import { FC } from 'react';

import { Avatar, Box, Flex, Tooltip } from '@chakra-ui/react';
import { isBefore } from 'date-fns';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { MdWarning } from 'react-icons/md';

import { PayoffVerificationStatus } from '../../gql/generated/graphql';
import { Payoff } from '../../gql/payoffGql';

import { isNullOrUndefined } from '../../utils';
import { getDoubleTaxApplied } from '../../utils/payoffs';

export const DoubleTaxAppliedIcon: FC = () => (
  <Tooltip hasArrow label="Double Sales Tax Applied" placement="top">
    <Avatar icon={<Box />} size="xs" w="19.5px" h="19.5px" bg="violetRed">
      2x
    </Avatar>
  </Tooltip>
);

const getPayoffStatusIconAndTooltip = (payoff: Payoff) => {
  if (payoff.good_through_date && isBefore(new Date(payoff.good_through_date), new Date())) {
    return {
      tooltip: 'Good Through Date has expired. A new payoff amount should be requested.',
      Icon: <MdWarning color="#F3C43D" size={24} />,
    };
  }
  switch (payoff.verification_status) {
    case PayoffVerificationStatus.CustomerProvided:
      return {
        tooltip: 'This payoff value was provided by customer and has not been verified.',
        Icon: <MdWarning color="#F3C43D" size={24} />,
      };
    case PayoffVerificationStatus.Edited:
      return {
        tooltip: 'This payoff value has been changed and has not been verified.',
        Icon: <MdWarning color="#F3C43D" size={24} />,
      };
    case PayoffVerificationStatus.Verified:
      return { tooltip: 'Verified', Icon: <IoIosCheckmarkCircle color="#8CC63F" size={24} /> };
    default:
      return null;
  }
};

interface PayoffStatusProps {
  payoff: Payoff;
  buyerNotLessee?: boolean;
}

const PayoffStatus: FC<PayoffStatusProps> = ({ payoff, buyerNotLessee }) => {
  const payoffStatusIconAndTooltip = getPayoffStatusIconAndTooltip(payoff);

  const { Icon, tooltip } = payoffStatusIconAndTooltip ?? {};

  const doubleTaxApplied = isNullOrUndefined(payoff.doubleTaxApplied)
    ? getDoubleTaxApplied(payoff, buyerNotLessee)
    : payoff.doubleTaxApplied;

  return (
    <Flex alignItems="center">
      {payoffStatusIconAndTooltip ? (
        <Tooltip hasArrow label={tooltip} placement="top">
          <Box minW="16px">{Icon}</Box>
        </Tooltip>
      ) : null}
      {doubleTaxApplied ? <DoubleTaxAppliedIcon /> : null}
    </Flex>
  );
};

export default PayoffStatus;
