import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum } from '../../gql/dealGql';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';

import { PermissionEnum } from '../../constants/permissions';
import { Can } from '../../libs/Can';
import { logger } from '../../libs/Logger';

interface SaveEditButtonProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

const SaveEditButton: React.FC<SaveEditButtonProps> = ({ isEditing, setIsEditing }) => {
  const { values, setFieldValue } = useFormikContext<Deal>();
  const [isSaving, setSaving] = useState<boolean>(false);
  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert);

  const onSave = (deal: Deal) => {
    upsertFinancialInfoAcquisition({
      variables: {
        financialInfoAcquisition: deal.financial_info_acquisition,
        id: values.id,
        state: deal.state,
        car_id: deal.car?.id,
        mmr: deal.car?.mmr,
        vehicle_payoff: deal.car?.payoff?.vehicle_payoff,
      },
    })
      .then(({ data }) => {
        setFieldValue('financial_info_acquisition.id', data.financialInfoAcquisitionUpsert.id);
        toast.success('Deal saved');
      })
      .catch((e) => logger.error('SaveEditButton.tsx', '', null, e))
      .finally(() => {
        setIsEditing(false);
        setSaving(false);
      });
  };

  return (
    <Can I={PermissionEnum.EditDeal}>
      <Button
        variant="secondary"
        isLoading={isSaving}
        loadingText="SAVE"
        onClick={() => {
          onSave(values);
        }}
        hidden={values.state !== DealStateEnum.Closed || !isEditing}
      >
        SAVE
      </Button>
    </Can>
  );
};

export default SaveEditButton;
