import React, { useContext, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Flex, IconButton } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { getFullName } from '../../../gql/customerGql';
import {
  BootReason,
  BootReasonsEnum,
  Deal,
  DealStateEnum,
  dealUpdateRequestBoot,
} from '../../../gql/dealGql';
import { Note } from '../../../gql/noteGql';

import Select from '../../shared/Select';
import TextArea from '../../shared/TextArea';

import { PermissionEnum } from '../../../constants/permissions';
import SELECT_OPTIONS from '../../../constants/selectOptions';
import { useUser } from '../../../hooks/useUser';
import { AbilityContext } from '../../../libs/contextLib';
import { getTimezoneStr, passValuesToSchema } from '../../../libs/utils';

const MAX_COMMENT_LENGTH = 150;

const validationSchema = Yup.object().shape({
  reason: Yup.string()
    .required('Reason is required')
    .oneOf(Object.values(BootReasonsEnum), 'Invalid Reason'),
  comment: Yup.string().max(MAX_COMMENT_LENGTH).required('Comment is required'),
});

type BootActionProps = {
  deal: Deal;
  renderAsIcon?: boolean;
  renderAsIconSize?: number;
  allowAllToBoot?: boolean;
  isModalOpen?: boolean;
};

const BootActions = ({
  deal,
  renderAsIcon = false,
  renderAsIconSize = 15,
  allowAllToBoot = false,
  isModalOpen = false,
}: BootActionProps) => {
  const [showModal, setShowModal] = useState<boolean>(isModalOpen);
  const [requestBoot, { loading }] = useMutation(dealUpdateRequestBoot);
  const initialValues = useMemo(() => {
    return {
      reason: deal?.boot_reason?.reason || ('' as BootReasonsEnum),
      comment: deal?.boot_reason?.comment || '',
    };
  }, [deal.boot_reason]);

  const user = useUser();
  const ability = useContext(AbilityContext);

  const handleSubmit = async ({ reason, comment }: BootReason) => {
    const bootReason = new BootReason(reason, comment);
    try {
      const partial_note = {
        deal_id: deal.id,
        author_id: user.id,
        creation_date_tz: getTimezoneStr(),
      } as Note;

      await requestBoot({
        variables: {
          partial_note,
          id: deal.id,
          request_boot: false,
          boot_reason: bootReason,
        },
      });

      toast.success('Deal Removed');
    } catch (e) {
      toast.error('Failed to remove deal.');
    } finally {
      setShowModal(false);
    }
  };

  const standardBootPermission = ability.has(PermissionEnum.BootDeal);
  const selectCaseBootPermission =
    allowAllToBoot && ability.has(PermissionEnum.BootAcquisitionDeal);
  const renderBoot = standardBootPermission || selectCaseBootPermission;

  return renderBoot ? (
    <>
      {renderAsIcon ? (
        <IconButton
          icon={<MdDelete />}
          variant="iconHover"
          color="errorsRed"
          size="xxs"
          fontSize={renderAsIconSize}
          aria-label="Boot Deal"
          onClick={() => setShowModal(true)}
        />
      ) : (
        <Button
          size="lgWithIconLeft"
          leftIcon={<MdDelete size={20} />}
          onClick={() => setShowModal(true)}
          variant="boot"
          hidden={deal.state === DealStateEnum.Booted}
        >
          BOOT DEAL
        </Button>
      )}
      <Modal
        show={showModal}
        backdrop="static"
        onHide={() => setShowModal(false)}
        keyboard={false}
        centered
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnMount
          validateOnChange
          validate={(value) => passValuesToSchema(value, validationSchema)}
        >
          {({ isValid, handleChange }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Remove {getFullName(deal.customer)}?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Flex direction="column" gap={4}>
                  <Select
                    label="Reason"
                    placeholder=""
                    id="reason"
                    name="reason"
                    options={SELECT_OPTIONS.BOOT_REASONS}
                    isRequired
                    onChange={handleChange}
                  />
                  <TextArea
                    as="textarea"
                    label="Comment"
                    placeholder="Comment"
                    id="comment"
                    name="comment"
                    maxLength={MAX_COMMENT_LENGTH}
                    rows={3}
                    isRequired
                    onChange={handleChange}
                  />
                </Flex>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  CLOSE
                </Button>
                <Button
                  variant="boot"
                  isLoading={loading}
                  loadingText="BOOT"
                  isDisabled={!isValid}
                  type="submit"
                >
                  BOOT
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  ) : (
    <IconButton
      icon={<MdDelete />}
      variant="iconHover"
      color="errorsRed"
      size="xxs"
      fontSize={renderAsIconSize}
      aria-label="Boot Deal"
      onClick={() => undefined}
    />
  );
};

export default BootActions;
