import { defineStyleConfig } from '@chakra-ui/react';

import { baseStyle } from './input';

const textarea = defineStyleConfig({
  baseStyle: baseStyle.field,
  defaultProps: {
    size: 'sm',
    variant: undefined,
  },
});

export default textarea;
