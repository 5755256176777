import React, { useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Modal, OverlayTrigger, Row } from 'react-bootstrap';
import { Button } from '@chakra-ui/react';
import styled from 'styled-components';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';
import { Deal, DealStateEnum } from '../../gql/dealGql';
import { StyledTooltip } from './MmrButton';
import ROUTES from '../../constants/routes';
import { AbilityContext } from '../../libs/contextLib';
import { PermissionEnum } from '../../constants/permissions';
import { logger } from '../../libs/Logger';

const StyledRow = styled(Row)`
  justify-content: center;
`;

const SendToClosing: React.FC = () => {
  const { values, isValid, isSubmitting, setSubmitting } = useFormikContext<Deal>();
  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const history = useHistory();

  const ability = useContext(AbilityContext);
  const canOverrideFailedApprovalAcquisitions = ability.has(
    PermissionEnum.OverrideFailedApprovalAcquisitionDeals,
  );

  const onSendToClosing = (deal: Deal) => {
    upsertFinancialInfoAcquisition({
      variables: {
        financialInfoAcquisition: deal.financial_info_acquisition,
        id: deal.id,
        state: deal.state,
        car_id: deal.car?.id,
        mmr: deal.car?.mmr,
        vehicle_payoff: deal.car?.payoff?.vehicle_payoff,
        override:
          canOverrideFailedApprovalAcquisitions && !values.financial_info_acquisition.is_approved,
        clear_override: !deal.financial_info_acquisition.is_approved,
      },
    })
      .then(() => history.push(ROUTES.DASHBOARD))
      .catch((e) => logger.error('SendToClosingButton.tsx', '', null, e))
      .finally(() => setSubmitting(false));
  };

  const renderTooltip = (props: React.ComponentProps<typeof StyledTooltip>) => (
    <StyledTooltip {...props}>
      Missing:
      {!values.car.payoff.vehicle_payoff ? <div>Payoff Amount</div> : ''}
      {!values.financial_info_acquisition.mmr ? <div>MMR</div> : ''}
      {!values.financial_info_acquisition.kbb_lending ? <div>KBB</div> : ''}
    </StyledTooltip>
  );
  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={!isValid ? renderTooltip : <span />}
      >
        <span>
          <Button
            isLoading={showApprovalModal}
            loadingText="SEND TO CLOSING"
            isDisabled={!isValid}
            hidden={values.state !== DealStateEnum.Structuring}
            name="send-closing"
            onClick={() => {
              setShowApprovalModal(true);
            }}
          >
            SEND TO CLOSING
          </Button>
        </span>
      </OverlayTrigger>
      <Modal
        show={showApprovalModal}
        backdrop="static"
        onHide={() => setShowApprovalModal(false)}
        keyboard={false}
        dialogClassName="approval-modal"
        centered
      >
        <Modal.Header className="justify-content-center">Is Deal Approved?</Modal.Header>
        <Modal.Body className="justify-content-center">
          <StyledRow>
            <Button
              isLoading={isSubmitting}
              loadingText="YES"
              onClick={() =>
                onSendToClosing({
                  ...values,
                  state: DealStateEnum.Closing,
                  financial_info_acquisition: {
                    ...values.financial_info_acquisition,
                    is_approved: true,
                  },
                })
              }
            >
              YES
            </Button>
            <Button
              isLoading={isSubmitting}
              loadingText="NO"
              onClick={() =>
                onSendToClosing({
                  ...values,
                  state: DealStateEnum.Closing,
                  financial_info_acquisition: {
                    ...values.financial_info_acquisition,
                    is_approved: false,
                  },
                })
              }
            >
              NO
            </Button>
          </StyledRow>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SendToClosing;
