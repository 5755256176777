import { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { TermEnum } from '../../gql/financialInfoGql';
import {
  Deal as GeneratedDeal,
  TtGetFeesSourceType,
  usePaymentEstimateLazyQuery,
} from '../../gql/generated/graphql';

import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import Tooltip from '../shared/Tooltip';

import colors from '../../chakra/foundations/colors';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { ModalContext } from '../../libs/contextLib';
import { formatMoney } from '../../libs/utils';
import { mapDealToGetPaymentEstimateVariables } from '../../utils/deals';

const PaymentEstimator = () => {
  const { deal, dispatch, userChangedSomething, setUserChangedSomething } = useContext(DealContext);
  const { setModal } = useContext(ModalContext);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [term, setTerm] = useState<number>(deal.financial_info?.term ?? TermEnum.SeventyTwo);
  const [down, setDown] = useState<number>(deal.financial_info?.money_down ?? 0);
  const [hasZip, setHasZip] = useState<boolean>(!!deal?.customer?.address?.zip);

  const defaultValue = '$XXX - $XXX';
  const [value, setValue] = useState<string>(defaultValue);

  const [paymentEstimateQuery, { loading, error }] = usePaymentEstimateLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ paymentEstimate }) => {
      const newValue = `${formatMoney(paymentEstimate?.paymentEstimateLow, {
        noDecimal: true,
      })} - ${formatMoney(paymentEstimate?.paymentEstimateHigh, { noDecimal: true })}`;
      setValue(newValue);
    },
    onError: () => {
      setValue(defaultValue);
    },
  });

  const paymentEstimateFn = async () => {
    if (hasZip) {
      await paymentEstimateQuery({
        variables: {
          ...mapDealToGetPaymentEstimateVariables(
            deal as GeneratedDeal,
            TtGetFeesSourceType.DdPaymentEstimate,
          ),
        },
      });
    }
  };

  useEffect(() => {
    if (!userChangedSomething) {
      return;
    }
    // Not specifically a modal but a component outside of the form that needs to trigger the recalculation of the initial values.
    setModal({ PaymentEstimator: true });
    dispatch({
      type: DealActionsEnum.UpdateDeal,
      payload: { ...deal, financial_info: { ...deal.financial_info, term, money_down: down } },
    });
    setModal({ PaymentEstimator: false });
  }, [term, down]);

  useEffect(() => {
    setHasZip(!!deal?.customer?.address?.zip);
  }, [deal?.customer?.address?.zip]);

  return (
    <Box mb={3}>
      <Card variant="roundedNoMargin" p={0}>
        <CardHeaderV2 title="Payment Estimate" onClick={() => setIsEditing(!isEditing)} />
        <Flex ml={6} alignItems="center" gap={2}>
          <FormControl width="33%">
            <FormLabel fontSize="sm">Term - Months</FormLabel>
            <Select
              id="term"
              value={term}
              onChange={(e) => {
                setUserChangedSomething(true);
                setTerm(parseInt(e.target.value, 10));
              }}
              isDisabled={!isEditing}
            >
              <option>{TermEnum.FortyEight}</option>
              <option>{TermEnum.Sixty}</option>
              <option>{TermEnum.SeventyTwo}</option>
              <option>{TermEnum.EightyFour}</option>
            </Select>
          </FormControl>

          <FormControl width="33%" ml={2}>
            <FormLabel fontSize="sm">Money Down</FormLabel>
            <Input
              as={NumberFormat}
              thousandSeparator
              prefix="$"
              value={down}
              onValueChange={(values: NumberFormatValues) => {
                setUserChangedSomething(true);
                setDown(values.floatValue ?? 0);
              }}
              maxLength={13}
              isDisabled={!isEditing}
            />
          </FormControl>

          <VStack
            py={8}
            w="33%"
            h="150px"
            bgColor={colors.azureishWhite}
            borderBottomRightRadius="lg"
          >
            <Box fontSize="3xl" fontWeight="normal" textAlign="center">
              {loading ? <Spinner variant="thick" /> : value}
            </Box>
            <Tooltip
              errors={
                hasZip && !error?.message.includes('Invalid Zip Code')
                  ? undefined
                  : ['Invalid Zip Code']
              }
            >
              <Button
                size="sm"
                variant="secondary"
                bgColor="azureishWhite" // Special color scheme for this button
                _hover={{ bgColor: 'white' }}
                isDisabled={(hasZip && error?.message?.includes('Invalid Zip Code')) || loading}
                onClick={paymentEstimateFn}
              >
                Get Payment Estimate
              </Button>
            </Tooltip>
          </VStack>
        </Flex>
      </Card>
    </Box>
  );
};

export default PaymentEstimator;
