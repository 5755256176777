import { FC, useContext } from 'react';

import { Box, Text, Tooltip } from '@chakra-ui/react';
import { MdChatBubble } from 'react-icons/md';

import { CustomerContactTypeEnum } from '../../gql/customerGql';
import { Deal } from '../../gql/dealGql';
import { Deal as GenDeal } from '../../gql/generated/graphql';

import { TextWidgetContext } from '../../libs/contextLib';

interface TextNotificationBadgeProps {
  deal?: GenDeal | Deal;
  count?: number;
  size?: number;
  showSimplifiedCount?: boolean;
}

const TextNotificationBadge: FC<TextNotificationBadgeProps> = ({
  deal,
  count,
  size = 28,
  showSimplifiedCount = false,
}) => {
  const { setTextDealId, setActiveConversation } = useContext(TextWidgetContext);

  if (!count) {
    return null;
  }

  const sizePx = `${size}px`;

  const getSimplifiedCount = (originalCount?: number) =>
    originalCount !== undefined && originalCount > 9 ? '9+' : originalCount;

  const countToShow = showSimplifiedCount ? getSimplifiedCount(count) : count;

  return (
    <Box
      pos="relative"
      lineHeight={sizePx}
      cursor={deal ? 'pointer' : 'default'}
      onClick={(e) => {
        e.stopPropagation();
        if (deal) {
          if (deal.id) {
            setTextDealId(deal.id);
          }
          setActiveConversation({
            role: CustomerContactTypeEnum.Customer,
            phone_number: deal.customer?.phone_number ?? '',
            first_name: deal.customer?.first_name ?? '',
            last_name: deal.customer?.last_name ?? '',
          });
        }
      }}
    >
      <Tooltip isDisabled={!count} hasArrow label={`${count} unread`} placement="top">
        <Box pos="relative" w={sizePx} h={sizePx}>
          <MdChatBubble color="#8CC63F" size={size} />
          <Text
            lineHeight="0.5rem"
            fontSize={size > 24 ? '0.75rem' : '0.5rem'}
            pos="absolute"
            top="47.5%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="white"
          >
            {countToShow}
          </Text>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default TextNotificationBadge;
