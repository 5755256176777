import { useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  BootReason,
  Deal,
  dealHasProblemSwitch,
  dealQuery,
  dealUpdateRequestBoot,
} from '../../gql/dealGql';
import { Note } from '../../gql/noteGql';

import BootDealModal from '../../components/BootDealModal';
import DealStateHistory from '../../components/DealStateHistory';
import Footer from './components/Footer';
import Header from './components/Header';

import { useUser } from '../../hooks/useUser';
import { DealProvider } from '../../libs/DealContext';
import { FiltersContext } from '../../libs/contextLib';
import { getTimezoneStr } from '../../libs/utils';

const TitlingDealDetail = () => {
  const {
    filters: {
      global: { sources, types },
    },
  } = useContext(FiltersContext);
  const user = useUser();
  const { id } = useParams<{ id: string }>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isBootModalOpen,
    onClose: onCloseBootModal,
    onOpen: onBootModalOpen,
  } = useDisclosure();

  const [requestBoot] = useMutation(dealUpdateRequestBoot);
  const [dealHasProblemSwitchMutation] = useMutation(dealHasProblemSwitch);

  const { data, loading, refetch } = useQuery(dealQuery, {
    variables: {
      id,
      sources,
      types,
    },
    fetchPolicy: 'network-only',
  });

  const deal = data?.deal ?? new Deal();

  const switchHasProblem = async () => {
    await dealHasProblemSwitchMutation({
      variables: { id, has_problem: deal?.has_problem, usState: deal.customer.address?.state },
    });
    refetch();
  };

  const onBootConfirm = async (bootReason: BootReason) => {
    try {
      const partial_note = {
        deal_id: deal.id,
        author_id: user.id,
        creation_date_tz: getTimezoneStr(),
      } as Note;

      await requestBoot({
        variables: {
          partial_note,
          id: deal.id,
          request_boot: true,
          boot_reason: bootReason,
        },
      });
      toast.success('Deal Removed');
      onCloseBootModal();
    } catch (e) {
      toast.error('Failed to remove deal.');
    }
  };

  return (
    <DealProvider>
      <Header loading={loading} onOpen={onOpen} />
      <Footer
        loading={loading}
        switchHasProblem={switchHasProblem}
        setShowBootModal={onBootModalOpen}
      />
      <DealStateHistory isOpen={isOpen} onClose={onClose} />
      <BootDealModal
        isOpen={isBootModalOpen}
        onClose={onCloseBootModal}
        onBootConfirm={onBootConfirm}
      />
    </DealProvider>
  );
};

export default TitlingDealDetail;
