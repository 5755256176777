import { FC, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Container from 'react-bootstrap/esm/Container';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Notification } from './gql/generated/graphql';
import { User } from './gql/userGql';

import CurrentCallPanel from './components/CurrentCallPanel';
import CallAlreadyAnsweredPanel from './components/CurrentCallPanel/CallAlreadyAnsweredPanel';
import ErrorBoundary from './components/ErrorBoundary';
import Navbar from './components/Navbar';
import NotificationsHandler from './components/NotificationsHandler';
import PostCallSurveyModal from './components/PostCallSurveyModal/PostCallSurveyModal';
import TextWidget from './components/TextWidget';
import { ActiveConversation } from './components/TextWidget/Components/Message';

import Routes from './Routes';
import { PermissionEnum } from './constants/permissions';
import { FiltersActionKind } from './globalFiltersUtils';
import useModal from './hooks/useModal';
import usePageTitle from './hooks/usePageTitle';
import usePagination, { PaginationContext } from './hooks/usePagination';
import { useUrlQueryParamsWithMultipleReducers } from './hooks/useUrlQueryParamsWithMultipleReducers';
import { CallsProvider } from './libs/callsContext';
import {
  AbilityContext,
  FiltersContext,
  InteractedContext,
  ModalContext,
  TextWidgetContext,
} from './libs/contextLib';
import { getAllowedDealSources } from './utils/permissions';

const App: FC = () => {
  const { user, loginWithRedirect, logout, isLoading, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const [abilities, setAbilities] = useState<Set<PermissionEnum>>(new Set());
  const [interacted, setInteracted] = useState(false);
  const [textDealId, setTextDealId] = useState<number | null>(null);
  const [activeConversation, setActiveConversation] = useState<ActiveConversation>({
    role: '',
    phone_number: '',
    first_name: '',
    last_name: '',
  });
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  const [unreadNotifications, setUnreadNotifications] = useState<Notification[]>([]);

  const { filters, dispatch } = useUrlQueryParamsWithMultipleReducers();
  const {
    global: { sources },
  } = filters;

  usePageTitle();

  const { modals, setModal } = useModal({});
  const {
    currentPage,
    itemsPerPage,
    setItemsPerPage,
    nextPage,
    prevPage,
    totalRecords,
    setTotalRecords,
    maxPage,
    jumpToPage,
  } = usePagination();

  const paginationContextValue = {
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    nextPage,
    prevPage,
    totalRecords,
    setTotalRecords,
    maxPage,
    jumpToPage,
  };

  useEffect(() => {
    const interactedFunc = () => {
      if (interacted) {
        document.removeEventListener('mousemove', interactedFunc);
      }
      setInteracted(true);
    };
    document.addEventListener('mousemove', interactedFunc);

    return () => {
      document.removeEventListener('mousemove', interactedFunc);
    };
  }, [interacted]);

  useEffect(() => {
    if (isAuthenticated && getAccessTokenSilently) {
      getAccessTokenSilently().then((data) => {
        const accessToken =
          jwt_decode<{ permissions: string[]; 'https://api.leaseend.com/role': string[] }>(data);
        if (accessToken?.permissions) {
          setAbilities(new Set<PermissionEnum>(accessToken.permissions as PermissionEnum[]));
        }
      });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (sources.length === 0) {
      dispatch({
        type: FiltersActionKind.SET_SOURCES,
        payload: getAllowedDealSources(abilities),
      });
    }
  }, [abilities]);

  const ldClient = useLDClient();

  useEffect(() => {
    if (user?.sub) {
      ldClient?.identify({
        kind: 'user',
        key: user.sub,
      });
    }
  }, [user?.sub]);

  return !isLoading ? (
    <Container fluid className="px-0">
      <CallsProvider>
        <PaginationContext.Provider value={paginationContextValue}>
          <FiltersContext.Provider value={{ filters, dispatch }}>
            {(sources || !isAuthenticated) && (
              <AbilityContext.Provider value={abilities}>
                <InteractedContext.Provider value={interacted}>
                  <TextWidgetContext.Provider
                    value={{
                      textDealId,
                      setTextDealId,
                      activeConversation,
                      setActiveConversation,
                      unreadCount: unreadMessagesCount,
                      setUnreadCount: setUnreadMessagesCount,
                      unreadNotifications,
                      setUnreadNotifications,
                    }}
                  >
                    <ModalContext.Provider value={{ modals, setModal }}>
                      <ErrorBoundary>
                        <Navbar
                          user={user as User}
                          loginWithRedirect={loginWithRedirect}
                          logout={logout}
                          isAuthenticated={isAuthenticated}
                        />
                        {isAuthenticated && <NotificationsHandler />}
                        {isAuthenticated && <TextWidget />}
                        <Routes />
                        {isAuthenticated && <CurrentCallPanel />}
                        {isAuthenticated && <CallAlreadyAnsweredPanel />}
                        {isAuthenticated && <PostCallSurveyModal />}
                      </ErrorBoundary>
                    </ModalContext.Provider>
                  </TextWidgetContext.Provider>
                </InteractedContext.Provider>
              </AbilityContext.Provider>
            )}
          </FiltersContext.Provider>
        </PaginationContext.Provider>
        <ToastContainer />
      </CallsProvider>
    </Container>
  ) : null;
};

export default App;
