import { FC, useContext, useState } from 'react';

import { MdFactCheck } from 'react-icons/md';

import { getFullName } from '../../gql/customerGql';
import { Deal } from '../../gql/dealGql';
import {
  DocumentProgressStatus,
  useOnContractValidationSubscription,
} from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

import { getValidationMessage } from '../../utils/routeOne';
import { InteractedContext } from '../../libs/contextLib';

const ContractHandler: FC = () => {
  const hasInteracted = useContext(InteractedContext);

  const [deals, setDeals] = useState<Deal[]>([]);

  useOnContractValidationSubscription({
    variables: {
      fundingClerkOnly: true,
    },
    onData: ({ data }) => {
      if (!data.data?.onContractValidation?.length) {
        return;
      }

      const newDeals = data.data.onContractValidation as Deal[];
      const filteredNewDeals = newDeals.filter(
        (deal) =>
          deal.document_progress_status === DocumentProgressStatus.Failed ||
          deal.document_progress_status === DocumentProgressStatus.GenerateDocuments,
      );

      if (!filteredNewDeals.length) {
        return;
      }

      setDeals([...deals, ...filteredNewDeals]);

      if (hasInteracted) {
        new Audio('/sounds/sms_notification.mp3').play();
      }
    },
  });

  return (
    <>
      {deals.map((deal) => (
        <NotificationToast
          key={`${deal.id}_${deal.document_progress_status}`}
          dealId={deal.id ?? null}
          slideFadeIn={!!deals.length}
          title={`${getFullName(deal.customer)} - Deal ${deal.id}`}
          titleIcon={MdFactCheck}
          titleIconColor="white"
          subTitle="Worksheet Validation"
          details={`Worksheet Validation ${getValidationMessage(deal).copy}`}
          onClose={() => setDeals(deals.filter((d) => d.id !== deal.id))}
        />
      ))}
    </>
  );
};

export default ContractHandler;
