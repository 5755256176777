import React, { Dispatch, useContext } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, duplicateCheckQuery, estimateUpsert } from '../../gql/dealGql';
import {
  useCreatePayoffRequestMutation,
  usePayoffRequestCancelMutation,
} from '../../gql/generated/graphql';

import Tooltip from '../shared/Tooltip';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { useUser } from '../../hooks/useUser';
import { Can } from '../../libs/Can';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { getTimezoneStr } from '../../libs/utils';
import { cleanDealForEstimateUpsert } from '../../utils/deals';

type RequestPayoffButtonProps = ButtonProps & {
  isDealDetail?: boolean;
  onClick?: () => void;
  setShowPayoffRequestButton?: Dispatch<React.SetStateAction<boolean>>;
  setDuplicateDeals?: Dispatch<React.SetStateAction<Deal[]>>;
  isDuplicateModalOpen?: boolean;
  onOpenDuplicateModal?: () => void;
  isPayoffRequestLoading?: boolean;
  setIsPayoffRequestLoading?: Dispatch<React.SetStateAction<boolean>>;
  isSaving?: boolean;
};

const RequestPayoffButton = ({
  isDealDetail,
  onClick,
  setShowPayoffRequestButton,
  setDuplicateDeals,
  isDuplicateModalOpen,
  onOpenDuplicateModal,
  isPayoffRequestLoading,
  setIsPayoffRequestLoading,
  isSaving = false,
  ...rest
}: RequestPayoffButtonProps) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useUser();
  const { dispatch, payoffRequest, isPayoffRequested, payoffRequestRefetch } =
    useContext(DealContext);

  const [upsertEstimate] = useMutation(estimateUpsert);
  const [createPayoffRequest, { loading: isCreatingPayoffRequest }] =
    useCreatePayoffRequestMutation();
  const [cancelPayoffRequest, { loading: isCancelingPayoffRequest }] =
    usePayoffRequestCancelMutation();
  const [checkDuplicates] = useLazyQuery(duplicateCheckQuery, { fetchPolicy: 'network-only' });

  const {
    values,
    errors: { customer: customerError, car: carError },
    setFieldValue,
  } = useFormikContext<Deal>();

  const requestPayoffButtonErrors = [
    customerError?.first_name,
    customerError?.last_name,
    customerError?.address?.zip,
    customerError?.phone_number,
    customerError?.home_phone_number,
    customerError?.ssn,
    customerError?.ssn_last_4,
    customerError?.ssn_last_6,
    customerError?.dob,
    carError?.year,
    carError?.make,
    carError?.vin,
    carError?.mileage,
    carError?.model,
    carError?.payoff?.lienholder_slug,
    carError?.payoff?.account_number,
  ];

  const isDisabled =
    requestPayoffButtonErrors.some(Boolean) ||
    isPayoffRequestLoading ||
    isPayoffRequested ||
    isSaving;

  const requestPayoff = async () => {
    if (isDisabled) {
      return;
    }

    if (setShowPayoffRequestButton) {
      setShowPayoffRequestButton(true);
    }

    if (setIsPayoffRequestLoading) {
      setIsPayoffRequestLoading(true);
    }

    let deal_id = values.id;

    try {
      const updatedDeal = cleanDealForEstimateUpsert(values);

      const duplicateData = await checkDuplicates({
        variables: {
          first_name: updatedDeal.customer?.first_name,
          last_name: updatedDeal.customer?.last_name,
          vin: updatedDeal.car?.vin,
          phone_number: updatedDeal.customer?.phone_number,
          home_phone_number: updatedDeal.customer?.home_phone_number,
        },
      });

      const dups = duplicateData.data.duplicateDeals?.filter(
        (dupDeal: { id: number | undefined }) => dupDeal.id !== values.id,
      );

      if (dups && setDuplicateDeals) {
        setDuplicateDeals(dups);

        if (
          dups.length &&
          !isDuplicateModalOpen &&
          onOpenDuplicateModal &&
          setIsPayoffRequestLoading
        ) {
          setIsPayoffRequestLoading(false);
          onOpenDuplicateModal();
          return;
        }
      }

      if (!deal_id) {
        const {
          data: {
            estimateUpsert: {
              id: new_deal_id,
              car: { id: car_id },
              customer: { id: customer_id },
            },
          },
        } = await upsertEstimate({
          variables: {
            deal: {
              ...updatedDeal,
              creation_date_utc: new Date(),
              creation_date_tz: getTimezoneStr(),
              notes: [
                ...updatedDeal.notes,
                {
                  text: 'Deal Created',
                  author_id: id,
                  creation_date_tz: getTimezoneStr(),
                },
              ],
            },
          },
        });

        deal_id = new_deal_id as number;

        setFieldValue('id', deal_id, false);
        setFieldValue('car.id', car_id, false);
        setFieldValue('customer.id', customer_id, false);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!isDealDetail && values.state === DealStateEnum.Estimate) {
          const res = await upsertEstimate({
            variables: {
              deal: updatedDeal,
            },
          });

          dispatch({ type: DealActionsEnum.UpdateDeal, payload: res.data.estimateUpsert });
        }
      }

      onClick?.();

      await createPayoffRequest({ variables: { deal_id } });

      payoffRequestRefetch();

      if (location.pathname === ROUTES.NEW_ESTIMATE) {
        history.push(`/deals/${deal_id}`);
      }
    } catch (error) {
      toast.error('Failed to request payoff');
      logger.error('RequestPayoffButton.tsx', 'Failed to request payoff', null, error);
    }

    if (setIsPayoffRequestLoading) {
      setIsPayoffRequestLoading(false);
    }
  };

  const cancelPayoff = async () => {
    try {
      if (payoffRequest?.id) {
        await cancelPayoffRequest({
          variables: { payoffRequestId: payoffRequest.id },
        });
        payoffRequestRefetch();
      }
    } catch (error) {
      toast.error('Failed to cancel payoff');
      logger.error('RequestPayoffButton.tsx', 'Failed to cancel payoff request', null, error);
    }
  };

  return (
    <Can I={PermissionEnum.RequestPayoff}>
      <Tooltip errors={requestPayoffButtonErrors}>
        {isPayoffRequested ? (
          <Button
            {...rest}
            variant="warning"
            isLoading={isCancelingPayoffRequest}
            loadingText="CANCEL REQUEST"
            onClick={cancelPayoff}
          >
            CANCEL REQUEST
          </Button>
        ) : (
          <Button
            {...rest}
            isDisabled={isDisabled}
            isLoading={isCreatingPayoffRequest}
            loadingText="REQUEST PAYOFF"
            onClick={requestPayoff}
          >
            REQUEST PAYOFF
          </Button>
        )}
      </Tooltip>
    </Can>
  );
};

export default RequestPayoffButton;
