import { FC, useContext, useState } from 'react';

import { MessageData, useOnReceivedMessageSubscription } from '../../gql/generated/graphql';

import TextNotificationToast from './components/TextNotificationToast';

import { InteractedContext, TextWidgetContext } from '../../libs/contextLib';

const TextHandler: FC = () => {
  const { activeConversation, textDealId } = useContext(TextWidgetContext);
  const hasInteracted = useContext(InteractedContext);

  const [messages, setMessages] = useState<MessageData[]>([]);

  useOnReceivedMessageSubscription({
    variables: {
      isNotification: true,
      isWidgetOpen: !!textDealId,
      activePhoneNumber: activeConversation.phone_number,
    },
    onData: ({ data }) => {
      if (!data.data?.onReceivedMessage || messages.includes(data.data.onReceivedMessage)) {
        return;
      }

      const newMessage = data.data.onReceivedMessage;
      setMessages([...messages, newMessage]);

      if (hasInteracted) {
        new Audio('/sounds/sms_notification.mp3').play();
      }
    },
  });

  return (
    <>
      {messages.map((messageData) => (
        <TextNotificationToast
          key={`${messageData.message?.id}`}
          messageData={messageData}
          onClose={() => setMessages(messages.filter((md) => md !== messageData))}
        />
      ))}
    </>
  );
};

export default TextHandler;
