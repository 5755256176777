import { Buffer, Workbook } from 'exceljs';
import { Button } from '@chakra-ui/react';
import { BsDownload } from 'react-icons/bs';
import { Deal } from '../../gql/dealGql';
import { Auction } from '../../gql/auctionGql';
import { logger } from '../../libs/Logger';

interface ExportDealsToXlsxButtonProps {
  deals: Deal[];
  columns: {
    header: string;
    getValue: (d: Deal, auctions?: Auction[]) => string | number | undefined;
  }[];
  auctions?: Auction[];
  dealType?: 'closed' | 'booted';
}

const ExportDealsToXlsxButton = ({
  deals,
  columns,
  auctions,
  dealType = 'closed',
}: ExportDealsToXlsxButtonProps) => {
  const saveByteArray = (reportName: string, byte: Buffer) => {
    const blob = new Blob([byte], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const downloadData = () => {
    const headers = columns.map(({ header }) => ({
      header,
      key: header,
    }));
    const rows = deals.map((deal) =>
      Object.assign(
        {},
        ...columns.map(({ header, getValue }) => ({
          [header]: getValue(deal, auctions),
        })),
      ),
    );

    const workbook = new Workbook();
    const sheet = workbook.addWorksheet('Sheet1');
    sheet.columns = headers;
    rows.forEach((row) => {
      sheet.addRow(row);
    });
    workbook.xlsx
      .writeBuffer()
      .then((data) => saveByteArray(`${dealType}_deals.xlsx`, data))
      .catch((err) => logger.error('ExportDealsToXlsxButton.tsx', '', null, err));
  };

  return (
    <Button
      leftIcon={<BsDownload />}
      onClick={() => downloadData()}
      variant="secondary"
      size="lgWithIconLeft"
      float="right"
    >
      DOWNLOAD
    </Button>
  );
};

export default ExportDealsToXlsxButton;
