import { FocusEventHandler, useEffect } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { SingleValue } from 'react-select';

import CustomReactSelect from './CustomReactSelect';
import { Option } from './types';

interface AutocompleteProps extends InputGroupProps {
  name: string;
  options: Option[];
  label?: string;
  placeholder?: string;
  formControlVariant?: 'inline' | 'reduced' | undefined;
  additionalOnChange?: (newValue: SingleValue<Option>) => void | Promise<void>;
  leftAddon?: string;
  error?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  setInputValue?: (value: string) => void;
}

const Autocomplete = ({
  name,
  options,
  defaultValue,
  label = '',
  placeholder = '',
  formControlVariant,
  additionalOnChange,
  leftAddon,
  error = '',
  isLoading = false,
  isDisabled = false,
  setInputValue,
}: AutocompleteProps) => {
  const {
    handleBlur: formikHandleBlur,
    handleChange: formikHandleChange,
    getFieldMeta,
    setFieldTouched,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();
  const { value, error: metaError, touched } = getFieldMeta(name);
  const styles = useMultiStyleConfig('FormControl', { variant: formControlVariant });

  const isInvalid = (touched && !!metaError) || !!error;

  // Workaround for dynamic form fields.
  if (isSubmitting && !touched) {
    setFieldTouched(name, true, false);
  }

  useEffect(() => {
    if (defaultValue) {
      setFieldValue(name, defaultValue);
    }
  }, []);

  // Fix for the issue where the field is not marked as touched when the user clicks outside the field.
  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    setFieldTouched(name, true, false);
    formikHandleBlur(event);
  };

  const handleChange = async (newValue: SingleValue<Option>) => {
    formikHandleChange({
      target: {
        name,
        value: newValue?.value,
      },
    });

    if (additionalOnChange) {
      await additionalOnChange(newValue);
    }
  };

  return (
    <FormControl isInvalid={isInvalid} sx={styles.control}>
      {label && <FormLabel sx={styles.label}>{label}</FormLabel>}
      <InputGroup sx={styles.input}>
        {!!leftAddon && <InputLeftAddon width="160px">{leftAddon}</InputLeftAddon>}
        <CustomReactSelect
          name={name}
          options={options}
          value={options ? options.find((option) => option.value === value) : null}
          onBlur={handleBlur}
          onChange={handleChange}
          onInputChange={setInputValue}
          placeholder={placeholder}
          isLoading={isLoading}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          isClearable
        />
      </InputGroup>
      {((touched && metaError) || error) && (
        <FormErrorMessage sx={styles.error}>{error || metaError}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default Autocomplete;
