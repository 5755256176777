import { Dispatch, SetStateAction } from 'react';

import { Icon, Th } from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import {
  StructuringFollowUpSortColumn,
  StructuringFollowUpSortDirection,
} from '../../gql/generated/graphql';

type StructuringFollowUpTableHeaderBaseProps = {
  header: string;
  hideHeader?: boolean;
};

type StructuringFollowUpTableHeaderConditionalProps =
  | {
      canSort: boolean;
      columnName: StructuringFollowUpSortColumn;
      sortColumn: StructuringFollowUpSortColumn;
      setSortColumn: Dispatch<SetStateAction<StructuringFollowUpSortColumn>>;
      sortDirection: StructuringFollowUpSortDirection;
      setSortDirection: Dispatch<SetStateAction<StructuringFollowUpSortDirection>>;
    }
  | {
      canSort?: never;
      columnName?: never;
      sortColumn?: never;
      setSortColumn?: never;
      sortDirection?: never;
      setSortDirection?: never;
    };

type StructuringFollowUpTableHeaderProps = StructuringFollowUpTableHeaderBaseProps &
  StructuringFollowUpTableHeaderConditionalProps;

const StructuringFollowUpTableHeader = ({
  header,
  hideHeader = false,
  canSort,
  columnName,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
}: StructuringFollowUpTableHeaderProps) => (
  <Th
    color="white"
    cursor={canSort ? 'pointer' : 'default'}
    onClick={() => {
      if (!canSort) {
        return;
      }

      if (sortColumn === columnName) {
        setSortDirection(
          sortDirection === StructuringFollowUpSortDirection.Asc
            ? StructuringFollowUpSortDirection.Desc
            : StructuringFollowUpSortDirection.Asc,
        );
      } else {
        setSortColumn(columnName);
        setSortDirection(StructuringFollowUpSortDirection.Asc);
      }
    }}
  >
    {hideHeader ? '' : header}
    {sortColumn === columnName && canSort && (
      <Icon as={sortDirection ? BiChevronDown : BiChevronUp} marginLeft={1} />
    )}
  </Th>
);

export default StructuringFollowUpTableHeader;
