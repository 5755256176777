import { useEffect, useMemo, useState } from 'react';

import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { Deal, isUneditable } from '../../gql/dealGql';
import { Lender, Maybe, useLendersLazyQuery } from '../../gql/generated/graphql';

import Autocomplete from './Autocomplete';
import { Option } from './types';

import useDebounce from '../../hooks/useDebounce';
import { logger } from '../../libs/Logger';

interface LenderAutocompleteProps {
  name?: string;
  label?: string;
  isLocked?: boolean;
}

const LenderAutocomplete = ({
  name = 'car.payoff.lender_name',
  label = 'Lender',
  isLocked,
}: LenderAutocompleteProps) => {
  const { values, getFieldMeta } = useFormikContext<Deal>();
  const { value } = getFieldMeta<Maybe<string>>(name);

  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Option[]>([]);

  const isDisabled = isLocked ?? isUneditable(values.state);

  const defaultOptions = useMemo((): Option[] => (value ? [{ label: value, value }] : []), [value]);

  const [lendersLazyQuery, { loading: isLoadingLenders }] = useLendersLazyQuery();

  const mapLenderToOption = (lender: Maybe<Lender>): Option => ({
    label: lender?.name || '',
    value: lender?.name || '',
  });

  const debouncedLendersLazyQuery = useDebounce(async (newInputValue: string) => {
    if (!newInputValue.trim()) {
      setOptions(defaultOptions);
      return;
    }

    try {
      const queryResult = await lendersLazyQuery({
        variables: { query: newInputValue },
      });

      if (!queryResult.data?.lenders) {
        setOptions(defaultOptions);
        return;
      }

      setOptions(queryResult.data.lenders.map(mapLenderToOption));
    } catch (e) {
      const error = e as Error;
      logger.error('LenderAutocomplete.tsx', '', null, error);
      toast.error('Failed to fetch lenders');
    }
  }, 500);

  useEffect(() => {
    debouncedLendersLazyQuery(inputValue);
  }, [inputValue]);

  return (
    <Autocomplete
      name={name}
      label={label}
      placeholder="Search for a Lender"
      setInputValue={setInputValue}
      options={options}
      isLoading={isLoadingLenders}
      isDisabled={isDisabled}
    />
  );
};

export default LenderAutocomplete;
