import { useContext } from 'react';

import { Badge, Text, useDisclosure } from '@chakra-ui/react';

import { useOnPayoffRequestStatusUpdateSubscription } from '../../gql/generated/graphql';

import InfoCardV2 from '../InfoCard/InfoCardV2';

import EditTags from './EditTags';

import { DealActionsEnum, DealContext } from '../../libs/DealContext';

const TagInfo = () => {
  const { deal, dispatch } = useContext(DealContext);

  const disclosureProps = useDisclosure();

  useOnPayoffRequestStatusUpdateSubscription({
    variables: { dealId: deal.id },
    onData: async ({ data }) => {
      if (!data.data?.onPayoffRequestStatusUpdate?.deal?.tags) {
        return;
      }

      const updatedTags = data.data.onPayoffRequestStatusUpdate.deal.tags;
      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          tags: updatedTags,
        },
      });
    },
  });

  return (
    <>
      <InfoCardV2 name="Deal Tags" showEditable editAction={() => disclosureProps.onOpen()}>
        {!deal?.tags?.length ? (
          <Text>No Tags</Text>
        ) : (
          deal?.tags?.map((tag) => (
            <Badge
              key={tag.id}
              mx={1}
              rounded="md"
              textTransform="uppercase"
              bg={tag.color}
              color="white"
              fontSize={12}
            >
              {tag.display_name}
            </Badge>
          ))
        )}
      </InfoCardV2>
      <EditTags disclosureProps={disclosureProps} />
    </>
  );
};

export default TagInfo;
