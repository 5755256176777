import { FC, MouseEvent, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import { dealsWithNotificationQuery } from '../../gql/dealGql';
import { DealsWithNotification } from '../../gql/generated/graphql';
import { User, updateUserCallStatus } from '../../gql/userGql';

import chakraButton from '../../chakra/components/button';
import NotificationsAvatar from '../NotificationBadge/NotificationAvatar';
import DealSuggest from '../Suggest/DealSuggest';
import { UserStatusSwitcher } from '../UserStatusSwitcher/UserStatusSwitcher';

import ActiveMenuItem from './ActiveMenuItem';

import config from '../../config';
import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { FiltersActionKind } from '../../globalFiltersUtils';
import {
  getSearchParams,
  getSearchString,
} from '../../hooks/useUrlQueryParamsWithMultipleReducers';
import { AbilityContext, FiltersContext } from '../../libs/contextLib';
import { handleClickOrCommandClick } from '../../libs/eventHandlers';
import { CurrentView } from '../../pages/Dashboard';

interface NavBarButton {
  label: string;
  shouldRender: boolean;
  page: string;
  menuOnly?: boolean;
  view?: CurrentView;
  messageNotifications?: number;
  notMessageNotifications?: number;
  search?: string;
}

interface Props {
  user: User | undefined;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  logout: (options?: LogoutOptions) => void;
  isAuthenticated: boolean;
}

const Navbar: FC<Props> = ({ user, loginWithRedirect, logout, isAuthenticated }) => {
  const history = useHistory();
  const location = useLocation();
  const currentPage = location.pathname;

  const [updateStatus] = useMutation(updateUserCallStatus);
  const abilities = useContext(AbilityContext);
  const {
    filters: {
      global: { selectedPodId, sources, types, currentView },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const numberOfButtonsToShowInNavBar = useBreakpointValue({
    base: 0,
    lg: 1,
    xl: 3,
    '2xl': 4,
    '3xl': 5,
    '4xl': 7,
  });

  // eslint-disable-next-line no-underscore-dangle
  const activeNavBarButtonVariant = chakraButton.variants.navBar._active;

  const [dealsNotification, setDealsNotification] = useState<DealsWithNotification>();

  useQuery<{ dealsWithNotification: DealsWithNotification }>(dealsWithNotificationQuery, {
    variables: {
      sources,
      types,
      pod_id: selectedPodId,
    },
    onCompleted: (data) => {
      if (!data?.dealsWithNotification) {
        return;
      }

      setDealsNotification(data.dealsWithNotification);
    },
    fetchPolicy: 'cache-and-network',
  });

  const buttons = useMemo(
    (): NavBarButton[] => [
      {
        label: 'Swimlanes',
        shouldRender: true,
        menuOnly: false,
        page: ROUTES.DASHBOARD,
        view: CurrentView.DashboardView,
      },
      {
        label: 'Payoffs',
        shouldRender: abilities.has(PermissionEnum.SeeAllPayoffRequests),
        menuOnly: false,
        page: ROUTES.DASHBOARD,
        view: CurrentView.PayoffRequestsView,
      },
      {
        label: 'Titling',
        shouldRender:
          abilities.has(PermissionEnum.TitleClerk) || abilities.has(PermissionEnum.SuperUser),
        menuOnly: false,
        page: ROUTES.DASHBOARD,
        view: CurrentView.TitleTableView,
      },
      {
        label: 'Structuring',
        shouldRender: abilities.has(PermissionEnum.SeeStructuringQueue),
        menuOnly: false,
        page: ROUTES.STRUCTURING_FOLLOW_UPS,
      },
      {
        label: 'Completed',
        shouldRender: !abilities.has(PermissionEnum.CannotSeeCompletedPage),
        menuOnly: false,
        page: ROUTES.COMPLETED_DEALS,
        messageNotifications: dealsNotification?.completedDealsNotificationsMessages ?? 0,
        notMessageNotifications: dealsNotification?.completedDealsNotificationsNotMessages ?? 0,
      },
      {
        label: 'Booted',
        shouldRender: !abilities.has(PermissionEnum.CannotSeeBootedPage),
        menuOnly: false,
        page: ROUTES.REVIVE_BOOT,
        messageNotifications: dealsNotification?.bootedDealsNotificationsMessages ?? 0,
        notMessageNotifications: dealsNotification?.bootedDealsNotificationsNotMessages ?? 0,
      },
      {
        label: 'Follow-Ups',
        shouldRender: !abilities.has(PermissionEnum.CannotSeeFollowUpsPage),
        menuOnly: false,
        page: ROUTES.FOLLOW_UPS,
        messageNotifications: dealsNotification?.followUpDealsNotificationsMessages ?? 0,
        notMessageNotifications: dealsNotification?.followUpDealsNotificationsNotMessages ?? 0,
      },
      {
        label: 'Metrics',
        shouldRender: !abilities.has(PermissionEnum.CannotSeeMetricsPage),
        menuOnly: false,
        page: ROUTES.METRICS,
        search: '?page=Overview',
      },
      {
        label: 'Teams',
        shouldRender: abilities.has(PermissionEnum.ManagePods),
        menuOnly: true,
        page: ROUTES.TEAMS,
      },
      {
        label: 'Com Reviews',
        shouldRender: abilities.has(PermissionEnum.SuperUser),
        menuOnly: true,
        page: ROUTES.COM_REVIEWS,
      },
      {
        label: 'Press Page Manager',
        shouldRender: abilities.has(PermissionEnum.SuperUser),
        menuOnly: true,
        page: ROUTES.PRESS_PAGE_MANAGER,
      },
    ],
    [abilities, dealsNotification],
  );

  const { navBarButtons, menuButtons } = useMemo(() => {
    const possibleNavBarButtons = buttons.filter(
      ({ shouldRender, menuOnly }) => shouldRender && !menuOnly,
    );

    const onlyMenuButtons = buttons.filter(
      ({ shouldRender, menuOnly }) => shouldRender && menuOnly,
    );

    return {
      navBarButtons: possibleNavBarButtons.slice(0, numberOfButtonsToShowInNavBar),
      menuButtons: [
        ...possibleNavBarButtons.slice(numberOfButtonsToShowInNavBar),
        ...onlyMenuButtons,
      ],
    };
  }, [buttons, numberOfButtonsToShowInNavBar]);

  const defaultView = useMemo(() => {
    if (abilities.has(PermissionEnum.SuperUser)) {
      return CurrentView.DashboardView;
    }
    if (abilities.has(PermissionEnum.TitleClerk)) {
      return CurrentView.TitleTableView;
    }
    if (abilities.has(PermissionEnum.PayoffClerk) || abilities.has(PermissionEnum.PayoffManager)) {
      return CurrentView.PayoffRequestsView;
    }
    return CurrentView.DashboardView;
  }, [abilities]);

  useEffect(() => {
    dispatchFilters({
      type: FiltersActionKind.SET_CURRENT_VIEW,
      payload: currentView ?? defaultView,
    });
  }, [defaultView, currentView]);

  const handleLogout = async () => {
    // set user to inactive when they log out
    await updateStatus({
      variables: {
        status: 'inactive',
      },
    });
    logout({ returnTo: config.auth0.logoutUri });
  };

  const isActive = ({ page, view }: NavBarButton) =>
    currentPage === page && (!view || view === currentView);

  const handleNavBarButtonClick = (
    e: MouseEvent,
    { page, view, search }: Pick<NavBarButton, 'page' | 'view' | 'search'>,
  ) => {
    const currentSearchParams = getSearchParams(location.search);
    const newSearchString = getSearchString({
      ...currentSearchParams,
      currentView: view || currentSearchParams.currentView,
    });

    const pageWithSearch = history.createHref({
      pathname: page,
      search: newSearchString,
    });

    handleClickOrCommandClick(e, isAuthenticated ? pageWithSearch : ROUTES.HOME, undefined, () => {
      if (view) {
        dispatchFilters({
          type: FiltersActionKind.SET_CURRENT_VIEW,
          payload: view,
        });
      }

      if (history.location.pathname !== page) {
        history.push({ pathname: page, search });
      }
    });
  };

  return (
    <Stack
      direction="row"
      py={2}
      px={8}
      bgColor="white"
      borderBottomColor="gray.100"
      borderBottomWidth="2px"
      justifyContent="space-between"
      overflowX="clip"
    >
      <Stack direction={{ base: 'column', lg: 'row' }} alignItems="center">
        <Box
          w={{ base: '92px', sm: '136px' }}
          mt={1}
          cursor="pointer"
          onClick={(e) => handleNavBarButtonClick(e, { page: ROUTES.DASHBOARD, view: defaultView })}
          onAuxClick={(e) =>
            handleNavBarButtonClick(e, { page: ROUTES.DASHBOARD, view: defaultView })
          }
        >
          <Image src="/2020_Goode_Logo_All_Variations_LE_FC.png" alt="Lease End" />
        </Box>

        <Spacer display={{ base: 'block', lg: 'none' }} />

        {isAuthenticated ? (
          <HStack>
            {navBarButtons.map((button) => (
              <Button
                key={button.label}
                variant="navBar"
                size={{ base: 'sm', sm: 'md' }}
                isActive={isActive(button)}
                onClick={(e) => handleNavBarButtonClick(e, button)}
                onAuxClick={(e) => handleNavBarButtonClick(e, button)}
              >
                {button.label}
                <NotificationsAvatar count={button.messageNotifications} />
              </Button>
            ))}

            {menuButtons.length ? (
              <Menu>
                <MenuButton
                  as={Button}
                  variant="navBar"
                  size={{ base: 'sm', sm: 'md' }}
                  rightIcon={<ChevronDownIcon w="2em" h="2em" />}
                  {...(menuButtons.some((button) => isActive(button))
                    ? activeNavBarButtonVariant
                    : {})}
                >
                  More
                </MenuButton>

                <MenuList zIndex="dropdown" fontSize={{ base: 'xs', sm: 'md' }}>
                  {menuButtons.map((button) => (
                    <ActiveMenuItem
                      key={button.label}
                      isActive={isActive(button)}
                      onClick={(e) => handleNavBarButtonClick(e, button)}
                      justifyContent="space-between"
                    >
                      {button.label}
                      <NotificationsAvatar count={button.messageNotifications} />
                    </ActiveMenuItem>
                  ))}
                </MenuList>
              </Menu>
            ) : null}
          </HStack>
        ) : null}
      </Stack>

      <Stack direction={{ base: 'column', lg: 'row' }}>
        {isAuthenticated ? (
          <>
            <DealSuggest />
            <Spacer display={{ base: 'block', lg: 'none' }} order={2} />
          </>
        ) : null}

        <HStack order={{ base: 1, lg: 3 }} mt="0 !important">
          {isAuthenticated ? <UserStatusSwitcher /> : null}

          <Menu>
            <MenuButton
              as={Button}
              variant="navBar"
              size={{ base: 'sm', sm: 'md' }}
              rightIcon={<ChevronDownIcon w="2em" h="2em" />}
            >
              {user?.name ?? 'User'}
            </MenuButton>
            <MenuList zIndex="dropdown" fontSize={{ base: 'xs', sm: 'md' }}>
              {isAuthenticated ? (
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              ) : (
                <MenuItem onClick={loginWithRedirect}>Login</MenuItem>
              )}
            </MenuList>
          </Menu>
        </HStack>
      </Stack>
    </Stack>
  );
};

export default Navbar;
