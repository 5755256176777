import { FC } from 'react';

import LeaseEndContainer from '../../components/Container';
import NewEstimateWithFollowUpWrapper from '../../components/NewEstimateWithFollowUp/NewEstimateWithFollowUpWrapper';

const ScheduleFollowUpPage: FC = () => {
  return (
    <LeaseEndContainer>
      <NewEstimateWithFollowUpWrapper />
    </LeaseEndContainer>
  );
};
export default ScheduleFollowUpPage;
