import { Dispatch, FC, useContext, useState } from 'react';

import { Box, Flex, Icon, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { toast } from 'react-toastify';

import { Deal } from '../../gql/dealGql';
import {
  Pod,
  PodUser,
  TeamRole,
  TeamType,
  usePodsQuery,
  useTitlingUsersQuery,
} from '../../gql/generated/graphql';
import { PodUsersByPod } from '../../gql/userGql';

import Loader from '../Loader';
import Pagination from '../Pagination';
import { tableViewColumns } from './components/ColumnComponents';
import DealRowContainer from './components/DealRowContainer';
import TableFilters from './components/TableFilters';

import {
  FiltersActionKind,
  SortingAction,
  SortingActionKind,
  SortingDirectionKind,
  SortingState,
  isSortingAsc,
} from './utils';

import { PermissionEnum } from '../../constants/permissions';
import { PaginationContext } from '../../hooks/usePagination';
import { useUser } from '../../hooks/useUser';
import { AbilityContext, FiltersContext } from '../../libs/contextLib';
import { groupTitlingUsersByPods } from '../../utils/pods';

interface Props {
  deals: Deal[];
  maxAge: string | undefined;
  loading: boolean;
  sorting: SortingState;
  dispatchSorting: Dispatch<SortingAction>;
}

const TableView: FC<Props> = ({ deals, maxAge, loading, sorting, dispatchSorting }) => {
  const user = useUser();

  const ability = useContext(AbilityContext);
  const paginationContext = useContext(PaginationContext);
  const {
    filters: {
      titling: { userChangedFilters, currentPage, itemsPerPage },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const { data: titlingPods } = usePodsQuery({
    variables: { includeDetails: true, team_type: [TeamType.Titling] },
    onError: (error) => {
      toast.error(`Couldn't load teams. Please refresh. Error: ${error.message}`);
    },
  });

  const isTitleClerk = ability.has(PermissionEnum.TitleClerk);

  const [titlingUsersByPod, setTitlingUsersByPod] = useState<PodUsersByPod>({
    all: [],
    active: [],
  });

  usePodsQuery({
    skip: !isTitleClerk || userChangedFilters,
    variables: { userId: user.id, team_type: [TeamType.Titling], includeDetails: true },
    onCompleted: (data) => {
      if (data?.pods?.length) {
        const titlingPod = data.pods[0];
        // check to see if userId is a lead
        const isLead = titlingPod?.pod_users?.some(
          (podUser) => podUser?.user_id === user.id && podUser?.team_role === TeamRole.Lead,
        );

        if (isLead && titlingPod?.id) {
          dispatchFilters({
            type: FiltersActionKind.SET_TITLING_PODS,
            payload: [titlingPod.id.toString()],
          });
          return;
        }

        dispatchFilters({
          type: FiltersActionKind.SET_ASSIGNEES,
          payload: [user.id],
        });
      }
    },
  });

  useTitlingUsersQuery({
    onCompleted: (data) => {
      if (data?.titlingUsers?.length) {
        setTitlingUsersByPod(groupTitlingUsersByPods(data.titlingUsers as PodUser[]));
      }
    },
  });

  const handleSorting = (sortingFieldName: string) => {
    if (sorting.field !== sortingFieldName) {
      dispatchSorting({
        type: SortingActionKind.SET_FIELD,
        payload: sortingFieldName,
      });
      dispatchFilters({
        type: FiltersActionKind.SET_FIELD,
        payload: sortingFieldName,
      });

      return;
    }

    dispatchSorting({
      type: SortingActionKind.SET_DIRECTION,
      payload: isSortingAsc(sorting.direction)
        ? SortingDirectionKind.DESC
        : SortingDirectionKind.ASC,
    });
    dispatchFilters({
      type: FiltersActionKind.SET_DIRECTION,
      payload: isSortingAsc(sorting.direction)
        ? SortingDirectionKind.DESC
        : SortingDirectionKind.ASC,
    });
  };

  const calculateLeftPx = (name: string) => {
    return `${(document.getElementsByClassName(name)[0] as HTMLElement)?.offsetWidth ?? 100}px`;
  };

  const getSortIcon = (header: string) => {
    if (header === 'Age') {
      return isSortingAsc(sorting.direction) ? BiChevronDown : BiChevronUp;
    }
    return isSortingAsc(sorting.direction) ? BiChevronUp : BiChevronDown;
  };

  const getDealRowAssignableUsers = (podUsers: PodUser[], deal: Deal) => {
    if (!podUsers || !deal) {
      return [];
    }

    const userIds = podUsers.map((podUser) => podUser?.user_id);
    const uniqueUserIds = new Set<string>();
    const result = podUsers.filter((podUser) => {
      if (uniqueUserIds.has(podUser?.user_id as string)) {
        return false;
      }
      uniqueUserIds.add(podUser?.user_id as string);
      return true;
    });

    if (deal.closer_id && !userIds.includes(deal.closer_id)) {
      result.push(
        titlingUsersByPod.all?.find((podUser) => podUser.user_id === deal.closer_id) as PodUser,
      );
    }

    if (deal.closer2_id && !userIds.includes(deal.closer2_id)) {
      result.push(
        titlingUsersByPod.all?.find((podUser) => podUser.user_id === deal.closer2_id) as PodUser,
      );
    }

    return result.filter(Boolean);
  };

  return (
    <Box w="100%">
      <Loader isLoading={loading} />
      <TableFilters maxAge={maxAge} titlingPodUsers={titlingUsersByPod.active ?? []} />
      <Box w="100%" overflowX="scroll">
        <Table>
          <Thead bgColor="silverLakeBlue">
            <Tr>
              {Object.entries(tableViewColumns).map(([header, { sortingFieldName }]) => (
                <Th
                  position="relative"
                  color="white"
                  key={`${header}-key`}
                  px={6}
                  whiteSpace="nowrap"
                >
                  <Flex
                    alignItems="center"
                    onClick={() => handleSorting(sortingFieldName)}
                    cursor="pointer"
                    position="relative"
                  >
                    <span className={`${header}-title`}>{header}</span>
                    {sortingFieldName === sorting.field && (
                      <Icon
                        position="absolute"
                        left={calculateLeftPx(`${header}-title`)}
                        w={5}
                        h={5}
                        as={getSortIcon(header)}
                      />
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {deals.map((deal) => (
              <DealRowContainer
                key={deal.id}
                deal={deal}
                titlingPods={titlingPods?.pods as Pod[]}
                titlingPodUsers={getDealRowAssignableUsers(
                  titlingUsersByPod[deal.titling_pod_id ?? 'active']?.filter(Boolean) ?? [],
                  deal,
                )}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        filters={{
          currentPage: currentPage ?? 0,
          itemsPerPage: itemsPerPage ?? 25,
        }}
        dispatchFilters={dispatchFilters}
        numItemsShown={deals?.length}
        paginationContext={paginationContext}
      />
    </Box>
  );
};

export default TableView;
