import * as Yup from 'yup';

import { Deal } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

type Value = number | null | undefined | { name: string; id: string };
interface Context {
  options: {
    context?: Partial<Deal>;
  };
  path: string;
}
const requiredIfAlreadyThere = (value: Value, context: Context) => {
  if (!context?.options?.context?.[context?.path]) {
    return true;
  }
  return typeof value === 'number' ? !!value : !!value?.name && !!value?.id;
};

export const validationSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(Object.values(DealType), 'Invalid Deal Type')
    .required('Deal Type is required'),

  pod_id: Yup.number().nullable().test('pod_id', 'Pod is required', requiredIfAlreadyThere),

  setter: Yup.object()
    .shape({
      id: Yup.string().required('Advisor is required.'),
      name: Yup.string().required('Advisor is required.'),
    })
    .nullable()
    .test('setter', 'Advisor is required', requiredIfAlreadyThere),

  closer: Yup.object().nullable().shape({
    id: Yup.string().nullable(),
    name: Yup.string().nullable(),
  }),

  closer2: Yup.object().nullable().shape({
    id: Yup.string().nullable(),
    name: Yup.string().nullable(),
  }),

  structuring_manager: Yup.object()
    .shape({
      id: Yup.string().required('Structuring Manager is required.'),
      name: Yup.string().required('Structuring Manager is required.'),
    })
    .nullable()
    .test('structuring_manager', 'Structuring Manager is required', requiredIfAlreadyThere),

  funding_clerk: Yup.object()
    .shape({
      id: Yup.string().required('Accounting Clerk is required.'),
      name: Yup.string().required('Accounting Clerk is required.'),
    })
    .nullable()
    .test('funding_clerk', 'Accounting Clerk is required', requiredIfAlreadyThere),

  titling_pod_id: Yup.number().nullable(),

  title_clerk: Yup.object()
    .shape({
      id: Yup.string().nullable(),
      name: Yup.string().nullable(),
    })
    .nullable(),

  title_clerk2: Yup.object()
    .shape({
      id: Yup.string().nullable(),
      name: Yup.string().nullable(),
    })
    .nullable(),

  deal_source: Yup.string().required('Deal Source is required.'),
});
