import { CSSObject } from '@chakra-ui/react';

export const container: CSSObject = {
  w: '20%',
  h: '72px',
  mr: 2,
};

export const containerNoFilename: CSSObject = {
  my: 'auto',
  mr: 3,
};

export const imageContainer: CSSObject = {
  minW: '72px',
  minH: '72px',
  display: 'flex',
  alignItems: 'center',
};

export const pdfContainer: CSSObject = {
  minW: '72px',
  minH: '72px',
  ml: '3px',
  display: 'flex',
  alignItems: 'center',
};

export const image: CSSObject = {
  maxH: '72px',
  margin: 'auto',
};

export const closeButton: CSSObject = {
  margin: '-16px 0 0 -24px',
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '50%',
};
