import { FormLabel } from '@chakra-ui/react';
import { FC } from 'react';
import { DealMediaEdit } from '../../../../gql/mediaGql';
import { getDealMediaFromAcceptedFiles } from '../../../../utils/media';
import Dropzone from '../../../Dropzone';
import { RadioGroup } from '../../../shared/RadioGroup';

interface UploadPayoffDocsRadioAndDropzoneProps {
  selectedMedia: DealMediaEdit[];
  setSelectedMedia: (selectedMedia: DealMediaEdit[]) => void;
  needsPayoffDocuments: boolean;
  setNeedsPayoffDocuments: (needsPayoffDocuments: boolean) => void;
  setPayoffDocumentsUploaded: (payoffDocumentsUploaded: boolean) => void;
  inConfirmModal?: boolean;
  dropzoneMarginTop?: number;
}

const UploadPayoffDocsRadioAndDropzone: FC<UploadPayoffDocsRadioAndDropzoneProps> = ({
  selectedMedia,
  setSelectedMedia,
  needsPayoffDocuments,
  setNeedsPayoffDocuments,
  setPayoffDocumentsUploaded,
  inConfirmModal = false,
  dropzoneMarginTop = 0,
}) => {
  return (
    <>
      <FormLabel>Do you need to upload a payoff doc?</FormLabel>
      <RadioGroup
        onChange={(e) => {
          setNeedsPayoffDocuments(e === 'true');

          if (e === 'false') {
            setSelectedMedia([]);
            setPayoffDocumentsUploaded(false);
          }
        }}
        value={needsPayoffDocuments}
      />
      {needsPayoffDocuments ? (
        <>
          <Dropzone
            onDrop={async (acceptedFiles: File[]) => {
              const media = await getDealMediaFromAcceptedFiles(acceptedFiles);
              setSelectedMedia(media);
              setPayoffDocumentsUploaded(true);
            }}
            showFiles
            acceptedFiles={selectedMedia.map((m) => m.selectedFile as File)}
            multiple
            marginTop={dropzoneMarginTop}
          />
          {inConfirmModal ? (
            <FormLabel alignSelf="center">
              (Documents can also be uploaded after confirming)
            </FormLabel>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default UploadPayoffDocsRadioAndDropzone;
