import React from 'react';
import { Box, Tr, Td } from '@chakra-ui/react';

import { tableViewColumns, TABLE_HEADERS } from './ColumnComponents';
import { DealStateEnum, Deal } from '../../../gql/dealGql';
import { getDealAgeColor } from '../../../utils/deals';

interface Props {
  deal: Deal;
  numColumns: number;
}

const TOTAL_STATES = 5;

const StateProgressBar: React.FC<Props> = ({ deal, numColumns }) => {
  const dealAge = tableViewColumns[TABLE_HEADERS.AGE].getValue(deal) || '';

  const getWidth = () => {
    switch (deal?.state) {
      case DealStateEnum.Funded:
        return (1 / TOTAL_STATES) * 100;
      case DealStateEnum.SendPayoff:
        return (2 / TOTAL_STATES) * 100;
      case DealStateEnum.WaitingForTitle:
        return (3 / TOTAL_STATES) * 100;
      case DealStateEnum.TitleReceived:
        return (4 / TOTAL_STATES) * 100;
      case DealStateEnum.SentToProcessor:
        return (5 / TOTAL_STATES) * 100;
      default:
        return 0;
    }
  };

  return (
    <Tr>
      <Td position="relative" colSpan={numColumns} m={0} p={0} bgColor="platinum">
        <Box
          position="absolute"
          bottom={1}
          left={0}
          h="2px"
          bgColor={getDealAgeColor(parseInt(dealAge, 10))}
          w={`${getWidth()}%`}
        />
      </Td>
    </Tr>
  );
};

export default StateProgressBar;
