import { PropsWithChildren, useContext, useMemo } from 'react';

import {
  Badge,
  Box,
  Link as ChakraLink,
  Flex,
  Grid,
  GridItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Link as ReactRouterLink } from 'react-router-dom';

import { getFullName } from '../../gql/customerGql';
import {
  Deal,
  DealSourceEnum,
  DealSourceLabelMap,
  DealStateEnum,
  DealTypeLabelMap,
} from '../../gql/dealGql';
import { getDealNotificationCountByType } from '../../gql/notificationsGql';

import FollowUpBadge from '../NotificationBadge/FollowUpBadge';
import TextNotificationBadgeV2 from '../NotificationBadge/TextNotificationBadgeV2';
import { PodColor } from '../shared/PodColor';
import Tooltip from '../shared/Tooltip';

import { getAttributionLine, renderTaskDetails, showBootActions } from './Task.utils';
import BootActions from './TaskActions/BootAction';
import useDealColor, { STRUCTURING_IN_PROGRESS_GRAY } from './hooks/useDealColor';

import { LDFlags } from '../../constants/experiments';
import { PermissionEnum } from '../../constants/permissions';
import useFlag from '../../hooks/useFlag';
import { useUser } from '../../hooks/useUser';
import { AbilityContext } from '../../libs/contextLib';
import { getIsClickable } from '../../utils/deals';

type LELinkProps = PropsWithChildren<{ to: string; isDisabled?: boolean }>;

const LELink = ({ to, isDisabled, children }: LELinkProps) => {
  return isDisabled ? (
    <>{children}</>
  ) : (
    <ChakraLink
      as={ReactRouterLink}
      to={to}
      _hover={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      {children}
    </ChakraLink>
  );
};

type TaskProps = {
  deal: Deal;
  assumedId?: string;
};

const Task = ({ deal, assumedId }: TaskProps) => {
  const autoAssigningFlag = useFlag(LDFlags.AUTO_ASSIGNING_DEALS);
  const autoAssigningDealsFlag = useFlag(LDFlags.AUTO_ASSIGNING_DEALS);
  const abilities = useContext(AbilityContext);
  const user = useUser();

  const isClickable = useMemo(
    () => getIsClickable(deal, abilities),
    [deal.source, deal.state, deal.closer_id, deal.closer?.id, abilities],
  );

  const color = useDealColor({
    autoAssigningFlag,
    canClaimAsFinancialSpecialistInSoftClose: abilities.has(
      PermissionEnum.ClaimFinancialSpecialistInSoftClose,
    ),
    deal,
  });

  const dealNotificationCountByType = useMemo(
    () => getDealNotificationCountByType(deal),
    [deal.notifications],
  );

  return (
    <Box
      bg={color}
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      shadow="sm"
      p={2}
      pl={3}
      position="relative"
      {...(color === STRUCTURING_IN_PROGRESS_GRAY && { opacity: '85%' })}
    >
      <Grid templateColumns="1px repeat(6, 1fr)" gridTemplateRows="1fr">
        <GridItem colSpan={1} rowSpan={2}>
          <PodColor w="5px" pod={deal.pod} roundedLeft="md" />
        </GridItem>
        <GridItem colSpan={5}>
          <LELink to={`/deals/${deal.id}`} isDisabled={!isClickable}>
            <VStack alignItems="flex-start" justifyContent="space-between" h="full">
              <Text fontWeight="bold" fontSize="lg">
                {getFullName(deal.customer)}
              </Text>
              <Box pb={2}>
                <Text fontSize="xs">
                  {getAttributionLine({ deal, separator: '|', includeStructuringManager: true })}
                </Text>
                {deal.type && deal.source ? (
                  <Text fontSize="2xs" color="gray.500" textTransform="uppercase">
                    {DealTypeLabelMap[deal.type]} |{' '}
                    {DealSourceLabelMap[deal.source as DealSourceEnum]}
                  </Text>
                ) : null}
              </Box>
            </VStack>
          </LELink>
        </GridItem>
        <GridItem colSpan={1} py={2}>
          <VStack h="full">
            {renderTaskDetails(deal, assumedId, abilities, user, autoAssigningDealsFlag)}
            {deal.follow_up ? <FollowUpBadge followUp={deal.follow_up} size={20} /> : null}
            {dealNotificationCountByType.messages ? (
              <TextNotificationBadgeV2
                count={dealNotificationCountByType.unseenMessages}
                deal={deal}
                size={24}
                showSimplifiedCount
              />
            ) : null}
            {deal.state !== DealStateEnum.StructuringInProgress &&
              showBootActions(deal.state, deal) && (
                <BootActions deal={deal} renderAsIcon renderAsIconSize={20} />
              )}
          </VStack>
        </GridItem>
        <GridItem colSpan={5} borderTop="1px solid" borderColor="gray.300" pt={2}>
          {(deal.tags || []).length > 0 && (
            <Flex gap={1} alignItems="center" h="full" flexWrap="wrap">
              {(deal.tags || [])
                .filter((tag) => tag.is_dashboard_visible)
                .map((tag) => (
                  <Badge
                    key={tag.id}
                    rounded="md"
                    textTransform="uppercase"
                    bg={tag.color}
                    color="white"
                    fontSize={10}
                  >
                    {tag.display_name}
                  </Badge>
                ))}
            </Flex>
          )}
        </GridItem>
        <GridItem colSpan={1} borderTop="1px solid" borderColor="gray.300" pt={2}>
          <Flex justifyContent="center" alignItems="center" h="full">
            <Tooltip
              label={formatDistanceToNow(
                deal.lane_arrival_utc ? new Date(deal.lane_arrival_utc) : new Date(),
              )}
              placement="top"
              px={2}
              py={1}
              bg="gray.800"
            >
              <Box>
                <AiOutlineClockCircle size={14} />
              </Box>
            </Tooltip>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Task;
