import { useCallback, useContext, useMemo } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Tag, useDealTagsUpsertMutation, useTagsQuery } from '../../gql/generated/graphql';

import LEMultiSelect from '../LEMultiSelect/LEMultiSelect';
import Modal from '../shared/Modal';

import { DealActionsEnum, DealContext } from '../../libs/DealContext';

export const validationSchema = Yup.object().shape({
  tags: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      name: Yup.string().required(),
    }),
  ),
});

type FormValues = {
  tags: { id: Tag['id']; name: Tag['display_name'] }[];
};

type EditTagsProps = {
  disclosureProps: ReturnType<typeof useDisclosure>;
};

const EditTags = ({ disclosureProps }: EditTagsProps) => {
  const { deal, dispatch } = useContext(DealContext);

  const { data: tagsData, loading: tagsLoading } = useTagsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [updateDealTags, { loading }] = useDealTagsUpsertMutation();

  const initialValues: FormValues = {
    tags: deal.tags?.map((tag) => ({ id: tag.id, name: tag.display_name, color: tag.color })) || [],
  };

  const tagOptions = useMemo(
    () =>
      tagsData?.tags.map((tag) => ({ id: tag.id, name: tag.display_name, color: tag.color })) || [],
    [tagsData?.tags],
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!deal?.id) {
        return;
      }

      const { data } = await updateDealTags({
        variables: {
          dealId: deal.id,
          tagIds: values.tags.map((tag) => tag.id),
        },
      });

      if (!data?.dealTagsUpsert) {
        return;
      }

      disclosureProps.onClose();

      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          ...deal,
          tags: data.dealTagsUpsert.map((tag) => ({
            id: tag.id,
            slug: tag.slug,
            display_name: tag.display_name,
            is_active: tag.is_active,
            color: tag.color,
            is_dashboard_visible: tag.is_dashboard_visible,
          })),
        },
      });
    },
    [deal.id],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, isValid }) => (
        <Modal
          title="Edit Deal Tags"
          size="xl"
          variant="noMargin"
          {...disclosureProps}
          leftButtons={
            <Button variant="warning" isDisabled={loading} onClick={disclosureProps.onClose}>
              CANCEL
            </Button>
          }
          rightButtons={
            <Button
              type="submit"
              isDisabled={!isValid}
              isLoading={loading}
              onClick={() => onSubmit(values)}
            >
              SAVE
            </Button>
          }
        >
          <LEMultiSelect
            name="tags"
            options={tagOptions}
            loading={tagsLoading}
            _tagProps={{ textTransform: 'uppercase', color: 'white' }}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default EditTags;
