import { FC, useContext, useState } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { File } from '../../../../gql/contractGql';
import { DealStateEnum } from '../../../../gql/dealGql';
import {
  DealStateEnum as GeneratedDealStateEnum,
  useSendDocumentsWithAdobeAndUpdateStateMutation,
} from '../../../../gql/generated/graphql';

import Modal from '../../../shared/Modal';

import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';
import DocumentsTable from '../../../DocumentsTable/DocumentsTable';
import PDFViewer from '../../../PDFViewer.tsx';

interface Props {
  width?: string;
  inContractStatusTimeline?: boolean;
}

const SendWithAdobeSign: FC<Props> = ({ width, inContractStatusTimeline = false }) => {
  const { deal, documentMedia, isRecalculatingPayoff, dispatch } = useContext(DealContext);
  const { signatureFileList } = documentMedia;
  const [sendDocsWithAdobeAndUpdateState, { loading }] =
    useSendDocumentsWithAdobeAndUpdateStateMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedFilesIndexes, setSelectedFilesIndexes] = useState<number[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  const handleOpenFile = (file: File) => {
    setFileUrl(file.url);
    setFileName(file.filename);
    setShowPreview(true);
  };

  const handleSendForSignatures = async () => {
    try {
      if (deal.id) {
        const docs = signatureFileList
          .filter((_, index) => selectedFilesIndexes.includes(index))
          .map((m) => m.key);

        const { data } = await sendDocsWithAdobeAndUpdateState({
          variables: {
            deal_id: deal.id,
            keys: docs,
            state: GeneratedDealStateEnum.SentForSign,
          },
        });

        if (data?.sendDocumentsWithAdobeAndUpdateState?.state) {
          dispatch({
            type: DealActionsEnum.UpdateDeal,
            payload: {
              ...deal,
              state: data?.sendDocumentsWithAdobeAndUpdateState.state as DealStateEnum,
            },
          });
        }
      }
      toast.success('Moved deal to "Sent for Signatures"!');
    } catch (e) {
      logger.error('MarkAssentButton.tsx', '', null, e);
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Button
        name="send-for-signatures"
        isDisabled={isRecalculatingPayoff}
        onClick={onOpen}
        width={width}
        variant={inContractStatusTimeline ? 'secondary' : undefined}
        size={inContractStatusTimeline ? 'sm' : undefined}
      >
        {inContractStatusTimeline ? 'SEND' : 'SEND FOR SIGNATURES'}
      </Button>
      <Modal
        title="Upload For Signatures"
        isOpen={isOpen}
        onClose={onClose}
        canDismiss={!loading}
        closeOnOverlayClick={false}
        size="3xl"
        leftButtons={
          <Button variant="warning" onClick={onClose} isDisabled={loading}>
            Cancel
          </Button>
        }
        rightButtons={
          <Button
            name="send-with-adobe-sign"
            isLoading={loading}
            loadingText="SEND W/ ADOBE SIGN"
            isDisabled={!selectedFilesIndexes.length}
            onClick={() => {
              handleSendForSignatures();
            }}
          >
            SEND W/ ADOBE SIGN
          </Button>
        }
      >
        <DocumentsTable
          infoText="Select the documents you would like to upload for digital signing. Documents with wet signature requirements are excluded."
          files={signatureFileList}
          selectedFilesIndexes={selectedFilesIndexes}
          setSelectedFilesIndexes={setSelectedFilesIndexes}
          onOpenFile={handleOpenFile}
          modal="sign"
        />
      </Modal>

      <Modal
        isOpen={showPreview}
        onClose={() => setShowPreview(false)}
        closeOnEsc={false}
        title={`Previewing: ${fileName}`}
        size="full"
      >
        <PDFViewer fileUrl={fileUrl} />
      </Modal>
    </>
  );
};

export default SendWithAdobeSign;
