import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FocusEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Input as ChakraInput,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightElement,
  Spinner,
  StyleProps,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useField } from 'formik';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

type FormattedValue = number | string | undefined;

interface Props extends FormControlProps {
  name: NonNullable<InputProps['name']>;
  placeholder?: NonNullable<InputProps['placeholder']>;
  type?: NonNullable<InputProps['type']>;
  label?: string;
  leftLabel?: string;
  maxLength?: NonNullable<InputProps['maxLength']>;
  debounce?: boolean;
  autoFocus?: InputProps['autoFocus'];
  max?: number;
  min?: number;
  showThousandSeparator?: boolean;
  allowNegative?: boolean;
  isAllowed?: (_values: NumberFormatValues) => boolean;
  isMoney?: boolean;
  isWhole?: boolean;
  isPercentage?: boolean;
  isLoading?: boolean;
  customOnValueChange?: (values: NumberFormatValues) => FormattedValue;
  additionalHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  labelProps?: StyleProps;
  _input?: InputProps;
  icon?: ReactNode;
  hideValueOnDisabled?: boolean;
  formControlVariant?: 'inline' | 'reduced' | 'bold' | undefined;
  helperText?: string;
  valueIsNumericString?: boolean;
  decimalScale?: number;
}

// TODO: unify with Input.tsx
const NumberInput: FC<Props> = ({
  name,
  placeholder,
  type = 'tel',
  label,
  leftLabel,
  maxLength,
  autoFocus = false,
  max = Infinity,
  min = -Infinity,
  showThousandSeparator,
  allowNegative = false,
  isAllowed,
  isMoney = false,
  isWhole = false,
  isPercentage = false,
  isLoading = false,
  customOnValueChange,
  additionalHandleChange,
  onBlur,
  labelProps = {},
  icon,
  hideValueOnDisabled = false,
  formControlVariant,
  helperText,
  valueIsNumericString = false,
  decimalScale,

  _input,
  ...rest
}) => {
  const styles = useMultiStyleConfig('FormControl', { variant: formControlVariant });

  const [hideValue, setHideValue] = useState<boolean>(false);

  const currentLabel = useMemo(
    () => `${label} ${rest.isRequired ? '*' : ''}`,
    [label, rest.isRequired],
  );

  const [field, meta, { setValue, setTouched }] = useField(name);
  const isInvalid = meta.touched && meta.error != null;

  const moneyProps = isMoney
    ? {
        thousandSeparator: true,
        prefix: '$',
        decimalSeparator: '.',
        decimalScale: decimalScale ?? 2,
      }
    : {};

  const wholeNumberProps = isWhole
    ? {
        decimalScale: decimalScale ?? 0,
      }
    : {};

  const percentageProps = isPercentage ? { suffix: '%', decimalScale } : {};

  const defaultOnValueChange = (values: NumberFormatValues) => {
    if (hideValue) {
      return undefined;
    }

    const formattedValue = valueIsNumericString ? values.floatValue?.toString() : values.floatValue;
    setValue(formattedValue);
    setTimeout(() => setTouched(true, true));

    return formattedValue;
  };

  const onValueChange = (values: NumberFormatValues) => {
    const formattedValue: FormattedValue =
      customOnValueChange?.(values) ?? defaultOnValueChange(values);
    if (formattedValue === undefined) {
      return;
    }

    additionalHandleChange?.({
      target: { name, value: formattedValue },
      currentTarget: { name, value: formattedValue },
    } as unknown as ChangeEvent<HTMLInputElement>);
  };

  useEffect(() => {
    setHideValue((rest.isDisabled && hideValueOnDisabled) ?? false);
  }, [rest.isDisabled, hideValueOnDisabled]);

  return (
    <FormControl isInvalid={isInvalid} sx={styles.control} {...rest}>
      {label && (
        <FormLabel whiteSpace="nowrap" fontSize="sm" sx={styles.label} {...labelProps}>
          {currentLabel}
        </FormLabel>
      )}
      <InputGroup sx={styles.input}>
        {leftLabel ? (
          <InputLeftAddon {...labelProps}>
            <Text>{leftLabel}</Text>
          </InputLeftAddon>
        ) : null}

        <ChakraInput
          as={NumberFormat}
          {...field}
          {...moneyProps}
          {...wholeNumberProps}
          {...percentageProps}
          isAllowed={isAllowed}
          type={type}
          max={max}
          min={min}
          thousandSeparator={
            showThousandSeparator === undefined && isMoney ? true : showThousandSeparator
          }
          allowNegative={allowNegative}
          value={hideValue ? 0 : field.value}
          onValueChange={onValueChange}
          onBlur={onBlur ?? field.onBlur}
          placeholder={placeholder}
          isInvalid={isInvalid}
          maxLength={maxLength}
          name={name}
          autoFocus={autoFocus}
          onChange={() => undefined}
          _hover={{
            // fix input style props not being overwritten with customized theme
            // https://github.com/chakra-ui/chakra-ui/issues/2347
            borderColor: 'currentColor',
          }}
          {..._input}
        />
        {isLoading && (
          <InputRightElement alignItems="center">
            <Spinner size="sm" />
          </InputRightElement>
        )}
        {!isLoading && icon ? (
          <InputRightElement h="100%" alignItems="center">
            {icon}
          </InputRightElement>
        ) : null}
      </InputGroup>
      {!isInvalid && helperText ? (
        <FormHelperText sx={styles.helperText}>{helperText}</FormHelperText>
      ) : null}
      <FormErrorMessage sx={styles.error}>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default NumberInput;
