import { useContext, useState } from 'react';

import {
  Maybe,
  RequiredDocumentsAndValidationsQuery,
  useRequiredDocumentsAndValidationsQuery,
} from '../gql/generated/graphql';
import { mapTTDocumentType } from '../gql/mediaGql';
import { TTDocumentName, TTValidationName } from '../gql/taterTitleGql';

import { DealContext } from '../libs/DealContext';
import { getRequiredDocsVariables } from '../utils/deals';

interface UseRequiredDocumentsAndValidationsProps {
  shouldExecute: boolean;
}

export const useRequiredDocumentsAndValidations = ({
  shouldExecute,
}: UseRequiredDocumentsAndValidationsProps) => {
  const {
    deal,
    documentMedia: { mediaList },
  } = useContext(DealContext);

  const [rawData, setRawData] = useState<RequiredDocumentsAndValidationsQuery>({});
  const [requiredDocuments, setRequiredDocuments] = useState<string[]>([]);
  const [validations, setValidations] = useState<TTValidationName[]>([]);

  const isMissingRequiredVariables =
    !deal.customer?.address?.state || !deal.financial_info || !deal.car;

  const { loading } = useRequiredDocumentsAndValidationsQuery({
    skip: !shouldExecute || isMissingRequiredVariables,
    fetchPolicy: 'cache-and-network',
    variables: getRequiredDocsVariables(deal, mediaList),
    onCompleted: (data) => {
      if (!data.jurisdiction?.products?.items?.length) {
        return;
      }

      const { productsItemsDocuments, productItemsValidations } =
        data.jurisdiction.products.items.reduce(
          (acc, productItem) => {
            const productItemDocuments =
              productItem?.documents?.items
                ?.map((documentItem) =>
                  mapTTDocumentType(documentItem?.type?.name as Maybe<TTDocumentName>),
                )
                .filter(Boolean) ?? [];
            const productItemValidations =
              productItem?.validations
                ?.map((validation) => validation?.name as TTValidationName)
                .filter(Boolean) ?? [];

            return {
              productsItemsDocuments: [...acc.productsItemsDocuments, ...productItemDocuments],
              productItemsValidations: [...acc.productItemsValidations, ...productItemValidations],
            };
          },
          {
            productsItemsDocuments: [] as string[],
            productItemsValidations: [] as TTValidationName[],
          },
        );

      setRawData(data);
      setRequiredDocuments(productsItemsDocuments);
      setValidations(productItemsValidations);
    },
  });

  return {
    loading,
    rawData,
    requiredDocuments,
    validations,
  };
};
