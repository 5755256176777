import { FC, useContext, useMemo } from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import {
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import { getFullName } from '../../gql/customerGql';
import { BootReason, BootReasonsEnum, Deal, dealUpdateRequestBoot } from '../../gql/dealGql';
import { Note } from '../../gql/noteGql';

import { validationSchema } from '../CreditApplication/validationSchema';
import Modal from '../shared/Modal';
import Select from '../shared/Select';
import TextArea from '../shared/TextArea';

import SELECT_OPTIONS from '../../constants/selectOptions';
import { useUser } from '../../hooks/useUser';
import { DealContext } from '../../libs/DealContext';
import { getTimezoneStr, passValuesToSchema } from '../../libs/utils';

const MAX_COMMENT_LENGTH = 150;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch?: () => Promise<ApolloQueryResult<Deal>>;
}

const UnwindButtonBootDealModal: FC<Props> = ({ isOpen, onClose, refetch }) => {
  const user = useUser();

  const { deal } = useContext(DealContext);

  const [requestBoot, { loading }] = useMutation(dealUpdateRequestBoot);

  const initialValues = useMemo(() => {
    return {
      reason: deal?.boot_reason?.reason || ('' as BootReasonsEnum),
      comment: deal?.boot_reason?.comment || '',
    };
  }, [deal.boot_reason]);

  const handleSubmit = async ({ reason, comment }: BootReason) => {
    const bootReason = new BootReason(reason, comment);
    try {
      const partial_note = {
        deal_id: deal.id,
        author_id: user.id,
        creation_date_tz: getTimezoneStr(),
      } as Note;

      await requestBoot({
        variables: {
          partial_note,
          id: deal.id,
          request_boot: false,
          boot_reason: bootReason,
        },
      });

      toast.success('Deal Removed');
      refetch?.();
    } catch (e) {
      toast.error('Failed to remove deal.');
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnMount
          validateOnChange
          validate={(value) => passValuesToSchema(value, validationSchema)}
        >
          {({ isValid, handleChange }) => (
            <Form>
              <ModalHeader>
                <Text>Remove {getFullName(deal.customer)}?</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="column" gap={4}>
                  <Select
                    label="Reason"
                    placeholder=""
                    id="reason"
                    name="reason"
                    options={SELECT_OPTIONS.BOOT_REASONS}
                    isRequired
                    onChange={handleChange}
                  />
                  <TextArea
                    as="textarea"
                    label="Comment"
                    placeholder="Comment"
                    id="comment"
                    name="comment"
                    maxLength={MAX_COMMENT_LENGTH}
                    rows={3}
                    isRequired
                    onChange={handleChange}
                  />
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button variant="secondary" onClick={() => onClose()}>
                  CLOSE
                </Button>
                <Button
                  isLoading={loading}
                  loadingText="BOOT"
                  variant="boot"
                  isDisabled={!isValid}
                  type="submit"
                >
                  BOOT
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default UnwindButtonBootDealModal;
