import { FC, useState } from 'react';

import { Box, Button, Select as ChakraSelect, HStack, Text } from '@chakra-ui/react';

interface Props {
  callHuntGroup: (phoneNum: string) => void;
  huntGroups: { name: string; phone_number: string }[];
  isOutboundCallModal: boolean | undefined;
}

export const HuntGroupSection: FC<Props> = ({
  callHuntGroup,
  huntGroups,
  isOutboundCallModal = false,
}) => {
  const [huntGroupNumber, setHuntGroupNumber] = useState<string>('');

  return (
    <Box m={3} pb={4} px={6} borderBottom="1px" borderColor="lightGray">
      <Text fontWeight="bold" fontSize={20}>
        Hunt Groups
      </Text>
      <HStack spacing={6}>
        {/* Using the base Chakra Select because we aren't using Formik context here */}
        <ChakraSelect
          name="huntGroups"
          w="50%"
          mt={1}
          disabled={huntGroups.length === 0}
          onChange={(e) => {
            setHuntGroupNumber(e.target.value);
          }}
          _hover={{
            // fix input style props not being overwritten with customized theme
            // https://github.com/chakra-ui/chakra-ui/issues/2347
            borderColor: 'currentColor',
          }}
        >
          <option value="">Select an Option</option>
          {huntGroups.map((group: { name: string; phone_number: string }) => (
            <option key={group.name} value={group.phone_number}>
              {group.name}
            </option>
          ))}
        </ChakraSelect>
        <Button
          size="md"
          m={2}
          h={9}
          variant={isOutboundCallModal ? 'primary' : 'secondary'}
          onClick={() => callHuntGroup(huntGroupNumber)}
        >
          {isOutboundCallModal ? 'Call' : 'Transfer'}
        </Button>
      </HStack>
    </Box>
  );
};
