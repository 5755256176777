import { useMemo, useRef } from 'react';

import { Button, HStack, Text } from '@chakra-ui/react';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';

import {
  UpdateUserSettingsMutationVariables,
  UserWithRole,
  useUpdateUserSettingsMutation,
} from '../../gql/generated/graphql';
import { User } from '../../gql/userGql';

import Modal from '../../components/shared/Modal';
import Switch from '../../components/shared/Switch';

import WorkingHoursTable from './WorkingHoursTable/WorkingHoursTable';
import {
  UserSettingsFormikFields,
  getWorkingHoursFields,
  getWorkingHoursString,
  userValidationSchema,
} from './utils';

import { logger } from '../../libs/Logger';

interface UserSettingsModalProps {
  user: User | UserWithRole | null;
  isOpen: boolean;
  onClose: () => void;
  refetchUsers: () => Promise<unknown>;
}
const UserSettingsModal = ({ user, isOpen, onClose, refetchUsers }: UserSettingsModalProps) => {
  const formRef = useRef<FormikProps<UserSettingsFormikFields>>(null);

  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const initialValues = useMemo(
    (): UserSettingsFormikFields => ({
      workingHours: getWorkingHoursFields(user?.hours ?? undefined),
      on_vacation: user?.on_vacation ?? false,
      auto_assign_deals: user?.auto_assign_deals ?? false,
      overnight_deals: user?.overnight_deals ?? false,
    }),
    [user],
  );

  const handleClose = () => {
    onClose();
    formRef.current?.resetForm();
  };

  const onSubmit = async (values: UserSettingsFormikFields) => {
    const variables: UpdateUserSettingsMutationVariables = {
      id: user?.id ?? '',
      hours: getWorkingHoursString(values.workingHours),
      on_vacation: values.on_vacation,
      auto_assign_deals: values.auto_assign_deals,
      overnight_deals: values.overnight_deals,
    };

    await updateUserSettings({
      variables,
      onCompleted: async () => {
        toast.success('User updated successfully');
        handleClose();
        await refetchUsers();
      },
      onError: (error) => {
        logger.error(
          'src/pages/Pods/Components/UserSettingsModal.tsx',
          'updateUserHoursAndOnVacation',
          variables,
          error,
        );
        toast.error(`Error updating User: ${error.message}`);
      },
    });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={userValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Modal
            title="User Settings"
            size="3xl"
            hasForm
            isOpen={isOpen}
            onClose={handleClose}
            canDismiss={!isSubmitting}
            leftButtons={
              <Button variant="warning" onClick={handleClose} isDisabled={isSubmitting}>
                CANCEL
              </Button>
            }
            rightButtons={
              <Button type="submit" isLoading={isSubmitting} loadingText="SAVE CHANGES">
                SAVE CHANGES
              </Button>
            }
          >
            <HStack pt={3} alignItems="start" justifyContent="space-between">
              <Text>{user?.name}</Text>
              <HStack>
                <Switch name="overnight_deals" label="Overnight Deals" />
                <Switch name="auto_assign_deals" label="Auto Assign Deals" />
                <Switch name="on_vacation" label="On Vacation" />
              </HStack>
            </HStack>
            <WorkingHoursTable />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default UserSettingsModal;
