import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { useLoggedUserQuery, useUserInfoQuery } from '../gql/generated/graphql';
import { Pod } from '../gql/podGql';
import { User } from '../gql/userGql';

export const useUser = (): User => {
  const { user: auth0User } = useAuth0();

  const { data: dbUser } = useUserInfoQuery({
    variables: { ids: [auth0User?.sub] },
  });

  const dbUserInfo = dbUser?.users?.[0];

  const [user, setUser] = useState<User>({
    ...auth0User,
    id: auth0User?.sub || '',
    email: auth0User?.email || '',
    name: auth0User?.name || '',
    nickname: auth0User?.nickname || '',

    phone_number: dbUserInfo?.twilio_number || '',
    hours: dbUserInfo?.hours || '',
    on_vacation: dbUserInfo?.on_vacation || false,
    auto_assign_deals: dbUserInfo?.auto_assign_deals || false,
    overnight_deals: dbUserInfo?.overnight_deals || false,
  });

  useLoggedUserQuery({
    onCompleted: (data) => {
      setUser({
        ...user,
        can_claim_as_closer: data.loggedUser?.can_claim_as_closer ?? false,
        pods: (data.loggedUser?.pods as unknown as Pod[]) ?? [],
      });
    },
  });

  return user;
};
