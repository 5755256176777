import React from 'react';
import { MdBrokenImage, MdPictureAsPdf } from 'react-icons/md';
import { Flex, Box, Image, Text, CloseButton } from '@chakra-ui/react';

import * as styles from './UploadPreview.styles';

import { truncateFileName } from '../../../utils/files';

interface UploadPreviewProps {
  url: string;
  sourceFileName: string;
  remove?: (index: number) => void;
  index?: number;
  showFileName?: boolean;
  onClick?: () => void;
  setMediaLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadPreview: React.FC<UploadPreviewProps> = ({
  index,
  url,
  sourceFileName,
  showFileName = false,
  remove,
  onClick,
  setMediaLoading,
}) => {
  const { container, containerNoFilename, imageContainer, pdfContainer, image, closeButton } =
    styles;
  const isPdf = sourceFileName.toLocaleLowerCase().endsWith('.pdf');

  return (
    <Flex
      onClick={onClick}
      direction="row"
      sx={showFileName || !isPdf ? container : containerNoFilename}
    >
      <Box sx={isPdf ? pdfContainer : imageContainer}>
        {isPdf ? (
          <MdPictureAsPdf size={48} />
        ) : (
          <Image
            sx={image}
            src={url}
            alt={sourceFileName}
            onLoad={() => setMediaLoading?.(false)}
            h="100%"
            fallback={<MdBrokenImage size={54} />}
          />
        )}
      </Box>
      {remove && index !== undefined && (
        <CloseButton sx={closeButton} onClick={() => remove(index)} />
      )}
      {showFileName && (
        <Flex alignItems="center" w="100%">
          <Text>{truncateFileName(sourceFileName)}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default UploadPreview;
