import React from 'react';
import { Radio, RadioGroup as ChakraRadioGroup, Stack } from '@chakra-ui/react';

interface Props {
  onChange: (e: string) => void;
  value?: boolean;
  isDisabled?: boolean;
  orientation?: 'row' | 'column';
  showNoFirst?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const RadioGroup: React.FC<Props> = ({
  onChange,
  value = false,
  isDisabled = false,
  orientation = 'row',
  showNoFirst = true,
  size = 'md',
}) => {
  return (
    <>
      <ChakraRadioGroup
        size={size}
        onChange={onChange}
        value={value ? 'true' : 'false'}
        isDisabled={isDisabled}
      >
        <Stack direction={orientation}>
          {showNoFirst ? (
            <>
              <Radio value="false" mb={0}>
                No
              </Radio>
              <Radio value="true">Yes</Radio>
            </>
          ) : (
            <>
              <Radio value="true">Yes</Radio>
              <Radio value="false" mb={0}>
                No
              </Radio>
            </>
          )}
        </Stack>
      </ChakraRadioGroup>
    </>
  );
};
