import { Spinner } from '@chakra-ui/react';

import { Deal, DealStateEnum } from '../../gql/dealGql';
import { User } from '../../gql/userGql';

import ClaimActions from './TaskActions/ClaimActions';
import SignedActions from './TaskActions/SignedActions';

import { PermissionEnum } from '../../constants/permissions';
import {
  canManuallyClaimCloser,
  canManuallyClaimSetter,
  canManuallyClaimStructuringManager,
  isCloserUnclaimed,
  isSetterUnclaimed,
  isStructuringManagerClaimed,
} from '../../utils/permissions';
import { getName } from '../../utils/users';

// TODO: this should be refactored with the `forwardButton` in `DealInfoBuyout` and `getClaimRole` in `permissions` util.
// Logic is duplicated.
export const renderTaskDetails = (
  deal: Deal,
  assumedId: string | undefined,
  permissions: Set<PermissionEnum>,
  user: User,
  autoAssigningDealsFlag: boolean,
) => {
  if (deal.state === DealStateEnum.StructuringInProgress) {
    return <Spinner size="xs" color="gray.800" />;
  }

  if (
    isCloserUnclaimed(deal) &&
    canManuallyClaimCloser(deal, permissions, user) &&
    !autoAssigningDealsFlag
  ) {
    return <ClaimActions setRole="closer_id" deal={deal} assumedId={assumedId} />;
  }

  if (
    isSetterUnclaimed(deal) &&
    canManuallyClaimSetter(deal, permissions) &&
    !permissions.has(PermissionEnum.ClaimFinancialSpecialistInSoftClose)
  ) {
    return <ClaimActions setRole="setter_id" deal={deal} assumedId={assumedId} />;
  }

  if (!isStructuringManagerClaimed(deal) && canManuallyClaimStructuringManager(deal, permissions)) {
    return <ClaimActions setRole="structuring_manager_id" deal={deal} assumedId={assumedId} />;
  }

  if (deal.state === DealStateEnum.Signed) {
    return <SignedActions deal={deal} assumedId={assumedId} />;
  }

  return null;
};

export const showBootActions = (dealState: DealStateEnum, deal: Deal): boolean =>
  [
    DealStateEnum.ReadyForPickup,
    DealStateEnum.PaidOff,
    DealStateEnum.Sold,
    DealStateEnum.AtAuction,
    DealStateEnum.TitleSent,
  ].indexOf(dealState) === -1 && deal.request_boot;

export const showPaperworkColorStates = [
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
];

type GetAttributionLineOptions = {
  deal: Deal;
  separator: '|' | ',';
  includeStructuringManager?: boolean;
};
export const getAttributionLine = ({
  deal,
  separator,
  includeStructuringManager,
}: GetAttributionLineOptions): string => {
  const owners = new Set<string>();

  if (deal?.setter) {
    owners.add(getName(deal.setter.name, { lastInitial: true }));
  }

  if (deal?.closer) {
    owners.add(getName(deal.closer.name, { lastInitial: true }));
  }

  if (includeStructuringManager && !deal?.closer && deal?.structuring_manager) {
    owners.add(getName(deal.structuring_manager.name, { lastInitial: true }));
  }

  if (deal?.closer2) {
    owners.add(getName(deal.closer2.name, { lastInitial: true }));
  }

  if (deal?.funding_clerk) {
    owners.add(getName(deal.funding_clerk.name, { lastInitial: true }));
  }

  return owners.size
    ? Array.from(owners).join(`${separator === '|' ? ` ${separator} ` : `${separator} `}`)
    : '-';
};

export const getTitleAttributionLine = (deal: Deal): string => {
  const owners = [];

  if (deal.titling_pod?.name) {
    owners.push(deal.titling_pod?.name);
  }

  if (deal?.title_clerk) {
    owners.push(getName(deal.title_clerk.name, { lastInitial: true }));
  }

  if (deal?.title_clerk2 && deal?.title_clerk_id !== deal?.title_clerk2_id) {
    owners.push(getName(deal.title_clerk2.name, { lastInitial: true }));
  }

  return owners.join(', ');
};
