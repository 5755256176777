import React, { useEffect, useState } from 'react';
import { Col, Form, Modal } from 'react-bootstrap';
import { Button } from '@chakra-ui/react';
import { ModalDetails } from './index';

interface ModalProps extends ModalDetails {
  setShowModal: (arg: boolean) => void;
  showModal: boolean;
}

export const UpdateNoteModal: React.FC<ModalProps> = ({
  showModal,
  setShowModal,
  loading,
  title,
  handleClick,
  clickText,
  type,
  noteText,
}) => {
  const [val, setVal] = useState(noteText);
  const isUpdateModal = type === 'update';

  useEffect(() => {
    setVal(noteText);
  }, [noteText]);

  return (
    <Modal
      show={showModal}
      backdrop="static"
      onHide={() => setShowModal(false)}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      {isUpdateModal ? (
        <Form.Group as={Col} md="12" className="h-100">
          <Form.Control
            as="textarea"
            rows={5}
            name="note"
            placeholder="Update note"
            value={val}
            onChange={(e) => {
              setVal(e.target.value);
            }}
          />
        </Form.Group>
      ) : null}

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          CLOSE
        </Button>
        <Button
          isLoading={loading}
          loadingText={clickText.toUpperCase()}
          variant={isUpdateModal ? 'primary' : 'warning'}
          onClick={() => {
            if (isUpdateModal) {
              handleClick(val);
            } else {
              handleClick();
            }
          }}
        >
          {clickText.toUpperCase()}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
