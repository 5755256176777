import * as Yup from 'yup';

import { DealMediaTypeEnum } from '../../gql/generated/graphql';

export const dealMediaValidationSchema = Yup.object().shape({
  fileName: Yup.string().required('File Name is required'),
  type: Yup.string()
    .required('Media Type is required')
    .oneOf(Object.values(DealMediaTypeEnum), 'Invalid Media Type'),
  metadata: Yup.object().optional().nullable(),
});

export const validationSchema = Yup.object().shape({
  dealMedia: Yup.array().of(dealMediaValidationSchema),
});

export interface FileDetails {
  fileExtension: string;
  sourceFileName: string;
  friendlyName: string;
  isPdf: boolean;
}

export const acceptedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
const acceptedFileExtensions = ['pdf', 'png', 'jpg', 'jpeg'];

export const parseFilename = (name: string): FileDetails => {
  if (!name) {
    return {
      fileExtension: '',
      sourceFileName: '',
      friendlyName: '',
      isPdf: false,
    };
  }

  const dealIdSeparatorIndex = name.indexOf('/');
  const filenameSeparatorIndex = name.indexOf('__');
  const fileNameStartIndex =
    filenameSeparatorIndex === -1 ? dealIdSeparatorIndex + 1 : filenameSeparatorIndex + 2;
  const sourceFileName = decodeURIComponent(name.substring(fileNameStartIndex));

  const extensionSeparatorIndex = sourceFileName.lastIndexOf('.');
  const extension =
    extensionSeparatorIndex === -1 ? '' : sourceFileName.substring(extensionSeparatorIndex + 1);

  const fileExtension = acceptedFileExtensions.includes(extension.toLowerCase()) ? extension : '';
  const friendlyName =
    extensionSeparatorIndex === -1
      ? sourceFileName
      : sourceFileName.substring(0, extensionSeparatorIndex);
  const isPdf = fileExtension.toLowerCase() === 'pdf'; // will not work for MMS with no extension

  return {
    fileExtension,
    sourceFileName,
    friendlyName,
    isPdf,
  };
};
