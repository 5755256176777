import { PropsValue, StylesConfig } from 'react-select';

import colors from '../../../../../chakra/foundations/colors';

import { Option } from '../../../../shared/types';

export const getCustomStyles = (width?: number): StylesConfig<PropsValue<Option>, true> => {
  const minWidth = 240;
  const fixedMinWidth = width && width >= minWidth ? width : minWidth;
  const fixedMinWidthPx = `${fixedMinWidth}px`;
  const maxValueWidth = fixedMinWidth / 2 - 10;
  const maxValueWidthPx = `${maxValueWidth}px`;

  const customStyles: StylesConfig<PropsValue<Option>, true> = {
    container: () => ({
      width: fixedMinWidthPx,
      minWidth: fixedMinWidthPx,
      maxHeight: '40px',
    }),
    control: () => ({
      backgroundColor: colors.azureishWhite,
      minHeight: '40px',
      maxHeight: '40px',
    }),
    valueContainer: () => ({
      maxHeight: '40px',
      margin: '1px',
      padding: '8px 10px',
    }),
    indicatorsContainer: () => ({
      maxHeight: '38px',
    }),
    option: (_provided, { isFocused, isSelected }) => ({
      color: 'black',
      backgroundColor: isSelected
        ? colors.babyBlueEyes
        : isFocused
        ? colors.azureishWhite
        : colors.white,
    }),
    multiValue: () => ({
      maxWidth: maxValueWidthPx,
      backgroundColor: colors.azureishWhite,
      margin: 0,
      padding: 0,
    }),
  };

  return customStyles;
};
