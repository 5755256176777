import { FC, useContext } from 'react';

import { Checkbox, Stack } from '@chakra-ui/react';
import { Col, Form, Row } from 'react-bootstrap';

import { DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import CompletedAcquisitionDealsTable from '../../components/CompletedAcquisitionDealsTable/CompletedAcquisitionDealsTable';
import CompletedDealsTable from '../../components/CompletedDealsTable/CompletedDealsTable';
import LeaseEndContainer from '../../components/Container/Container';
import ButtonsRow from '../../components/Dashboards/ButtonsRow';
import UserAutocomplete from '../../components/shared/UserAutocomplete';

import { PermissionEnum } from '../../constants/permissions';
import { FiltersActionKind } from '../../globalFiltersUtils';
import { Can } from '../../libs/Can';
import { FiltersContext } from '../../libs/contextLib';
import { states } from '../../libs/states';
import { formatDateISO } from '../../libs/utils';

const CompletedDealsPage: FC = () => {
  const {
    filters: {
      completed: { endDate, startDate, dealState, addressState, user },
      global: { showOnlyUnread, showAssignedToMe, types },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  return (
    <>
      <ButtonsRow />
      <LeaseEndContainer fluid>
        <Row className="align-items-start mb-2">
          <Form.Group as={Col} md="2">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              defaultValue={formatDateISO(startDate)}
              onChange={(e) => dispatchFilters({ type: 'setStartDate', payload: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              defaultValue={formatDateISO(endDate)}
              onChange={(e) => dispatchFilters({ type: 'setEndDate', payload: e.target.value })}
            />
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>Deal State</Form.Label>
            <Form.Control
              as="select"
              value={dealState}
              onChange={(e) => dispatchFilters({ type: 'setDealState', payload: e.target.value })}
            >
              <option value="all">ALL</option>
              {Object.values(DealStateEnum).map((stateValue) => (
                <option key={stateValue} value={stateValue}>
                  {DealStateLabelMap[stateValue as keyof typeof DealStateLabelMap]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="1">
            <Form.Label>State</Form.Label>
            <Form.Control
              as="select"
              value={addressState}
              onChange={(e) =>
                dispatchFilters({ type: 'setAddressState', payload: e.target.value })
              }
            >
              <option value="all">ALL</option>
              {Object.keys(states).map((stateKey) => (
                <option key={stateKey} value={states[stateKey as keyof typeof states]}>
                  {states[stateKey as keyof typeof states]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>User</Form.Label>
            <UserAutocomplete
              includeEmptyOption
              emptyOptionLabel="ALL"
              user={user}
              setUser={(selectedUser) =>
                dispatchFilters({ type: 'setUser', payload: selectedUser })
              }
            />
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Stack mt={4} spacing="1">
              <Checkbox
                onChange={(e) =>
                  dispatchFilters({
                    type: FiltersActionKind.SET_SHOW_ONLY_UNREAD,
                    payload: e.target.checked,
                  })
                }
                isChecked={showOnlyUnread}
              >
                Unread Messages
              </Checkbox>
              <Can I={PermissionEnum.SeeAssignedToMeFilter}>
                <Checkbox
                  isChecked={showAssignedToMe}
                  onChange={(e) =>
                    dispatchFilters({
                      type: FiltersActionKind.SET_SHOW_ASSIGNED_TO_ME,
                      payload: e.target.checked,
                    })
                  }
                >
                  Assigned to Me
                </Checkbox>
              </Can>
            </Stack>
          </Form.Group>
        </Row>
        {(types.includes(DealType.Buyout) || types.includes(DealType.Refi) || !types.length) && (
          <CompletedDealsTable
            startDate={startDate}
            endDate={endDate}
            user={user}
            dealState={dealState}
            addressState={addressState}
            notifications={showOnlyUnread}
          />
        )}
        {(types.includes(DealType.Acquisition) || !types.length) && (
          <CompletedAcquisitionDealsTable
            startDate={startDate}
            endDate={endDate}
            user={user}
            dealState={dealState}
            addressState={addressState}
            notifications={showOnlyUnread}
          />
        )}
      </LeaseEndContainer>
    </>
  );
};

export default CompletedDealsPage;
