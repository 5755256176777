import { ComponentStyleConfig } from '@chakra-ui/react';

const formControl: ComponentStyleConfig = {
  parts: ['control', 'label', 'input', 'helperText', 'error'],
  baseStyle: {
    label: {
      fontSize: 'sm',
    },
    helperText: {
      fontSize: '10px',
    },
    error: {
      fontSize: '10px',
    },
  },
  variants: {
    inline: {
      control: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        my: 1,
      },
      label: {
        flex: '0 1 25%',
      },
      input: {
        flex: '1 0 70%',
      },
      error: {
        flex: '1 0 100%',
        mb: 2,
      },
    },
    reduced: {
      control: {
        mr: 2,
      },
      label: {
        display: 'none',
      },
    },
    bold: {
      label: {
        color: 'black',
        fontWeight: 'bold',
      },
    },
  },
};

export default formControl;
