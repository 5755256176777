import { FC, useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Table, Tbody, Td, Text, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { resendEmails } from '../../gql/dealGql';
import {
  DealDocument,
  DocumentProgressStatus,
  DocumentSignatureStatusEnum,
  Maybe,
  useRetrieveDealDocsUploadedToR1LazyQuery,
} from '../../gql/generated/graphql';

import ContractNotFoundModal from '../DealInfoBuyout/components/modals/ContractNotFoundModal';
import Modal from '../shared/Modal';

import { PermissionEnum } from '../../constants/permissions';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { AbilityContext } from '../../libs/contextLib';
import { NonButtonStep } from '../../pages/DealDetail/ContractStatusTimeline/NonButtonStep';
import {
  CONTRACT_NOT_FOUND_ERROR,
  isDocumentProgressSent,
  isDocumentProgressUploaded,
  isDocumentProgressValidated,
} from '../../utils/routeOne';
import { snakeCaseToTitleCase } from '../../utils/text';

interface SigningModalButtonProps {
  width?: string;
}

const SigningModalButton: FC<SigningModalButtonProps> = ({ width }) => {
  const {
    deal: { id, r1_jacket_id, document_progress_status, signing_on_com },
  } = useContext(DealContext);
  const abilities = useContext(AbilityContext);

  const [documents, setDocuments] = useState<Maybe<DealDocument>[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenContractNotFound,
    onClose: onCloseContractNotFound,
    onOpen: onOpenContractNotFound,
  } = useDisclosure();

  const allDocumentsAreSigned = documents.every(
    (document) => document?.signatureStatus === DocumentSignatureStatusEnum.Completed,
  );
  const showLabel = !signing_on_com;
  const isDisabled =
    (!isDocumentProgressValidated(document_progress_status) ||
      !isDocumentProgressUploaded(document_progress_status) ||
      !isDocumentProgressSent(document_progress_status) ||
      document_progress_status !== DocumentProgressStatus.SentForSignatures) &&
    !abilities.has(PermissionEnum.SuperUser) &&
    !abilities.has(PermissionEnum.FundingManager);

  const [resendEmailsMutation, { loading: isResendingEmails }] = useMutation<{
    resendEmails?: boolean;
  }>(resendEmails, {
    variables: {
      dealId: id,
    },
    onCompleted: (data) => {
      toast.success(
        data?.resendEmails ? 'Emails resent successfully' : 'No emails needed resending',
      );
      onClose();
    },
    onError: (e) => {
      onClose();

      const error = e as Error;
      if (error.message === CONTRACT_NOT_FOUND_ERROR) {
        onOpenContractNotFound();
        return;
      }

      logger.error('SigningModalButton.tsx', 'resendEmailsMutation', { id }, e);
      toast.error(`Error resending emails: ${e.message ?? e}`);
    },
  });

  const [retrieveDealDocsUploadedToR1, { loading: isRetrievingDocsUploadedToR1 }] =
    useRetrieveDealDocsUploadedToR1LazyQuery({
      variables: { dealJacketID: r1_jacket_id ?? '' },
      fetchPolicy: 'no-cache',
      onCompleted(newData) {
        setDocuments(newData?.retrieveDealDocsUploadedToR1 ?? []);
      },
      onError: (e) => {
        logger.error('SigningButton.tsx', 'retrieveDealDocsUploadedToR1', { r1_jacket_id }, e);
        toast.error(`Error retrieving documents uploaded to R1: ${e.message ?? e}`);
      },
    });

  return (
    <>
      {showLabel ? (
        <NonButtonStep width={width}>SIGNING</NonButtonStep>
      ) : (
        <Button
          variant="secondary"
          size="sm"
          w={width}
          onClick={async () => {
            await retrieveDealDocsUploadedToR1();
            onOpen();
          }}
          isDisabled={isDisabled}
          isLoading={isRetrievingDocsUploadedToR1}
          loadingText="SIGNING"
        >
          SIGNING
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        title="Signature Status"
        canDismiss={!isResendingEmails}
        rightButtons={
          <>
            <Button
              variant="secondary"
              onClick={async () => {
                await resendEmailsMutation();
              }}
              isDisabled={allDocumentsAreSigned}
              isLoading={isResendingEmails}
              loadingText="RESEND EMAILS"
            >
              RESEND EMAILS
            </Button>
            <Button onClick={onClose} isDisabled={isResendingEmails}>
              OK
            </Button>
          </>
        }
      >
        {documents.length === 0 ? (
          <Text>No documents uploaded to R1</Text>
        ) : (
          <Table variant="striped" size="sm">
            <Thead>
              <Td>Document</Td>
              <Td>Signature Status</Td>
            </Thead>
            <Tbody>
              {documents.map((document) => (
                <Tr>
                  <Td>{document?.documentName}</Td>
                  <Td>{snakeCaseToTitleCase(document?.signatureStatus ?? '')}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Modal>

      <ContractNotFoundModal
        isOpen={isOpenContractNotFound}
        onClose={onCloseContractNotFound}
        handleOk={() => {
          onCloseContractNotFound();
          onClose();
        }}
      />
    </>
  );
};

export default SigningModalButton;
