import { FC, useContext, useState } from 'react';

import {
  Button,
  HStack,
  IconButton,
  Stack,
  StackProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { BiCalendarEvent } from 'react-icons/bi';
import { MdAddCircle, MdPhone, MdSmartphone } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import TransferCallModal from '../Calls/transferCallModal';
import GlobalFiltersMenu from '../GlobalFiltersMenu';
import WebLeadSuggest from '../Suggest/WebLeadSuggest';

import config from '../../config';
import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { Can } from '../../libs/Can';
import { AbilityContext, FiltersContext } from '../../libs/contextLib';
import { CurrentView } from '../../pages/Dashboard';

const ButtonsRow: FC<StackProps> = ({ ...rest }) => {
  const abilities = useContext(AbilityContext);
  const {
    filters: {
      global: { currentView },
    },
  } = useContext(FiltersContext);

  const [showOutboundCallModal, setShowOutboundCallModal] = useState(false);

  const history = useHistory();

  const showButtonIcons = useBreakpointValue({ base: false, sm: true });

  return (
    <>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        py={2}
        px={8}
        bgColor="queenBlue"
        justify="space-between"
        {...rest}
      >
        <HStack>
          {(!abilities.has(PermissionEnum.TitleClerk) ||
            abilities.has(PermissionEnum.SuperUser)) && (
            <>
              <Can I={PermissionEnum.CreateEstimate}>
                <Button
                  variant="primary"
                  size={{ base: 'sm', sm: 'md' }}
                  leftIcon={showButtonIcons ? <MdAddCircle size="20px" /> : undefined}
                  onClick={() => history.push(ROUTES.NEW_ESTIMATE)}
                >
                  New Estimate
                </Button>
              </Can>
              <Can I={PermissionEnum.SeeScheduleFollowUpButton}>
                <Button
                  size={{ base: 'sm', sm: 'md' }}
                  leftIcon={showButtonIcons ? <BiCalendarEvent size="20px" /> : undefined}
                  onClick={() => history.push(ROUTES.SCHEDULE_FOLLOW_UP)}
                >
                  SCHEDULE FOLLOW UP
                </Button>
              </Can>
              <Can I={PermissionEnum.SeeAIChat}>
                <Button
                  variant="primary"
                  size={{ base: 'sm', sm: 'md' }}
                  leftIcon={showButtonIcons ? <MdSmartphone size="20px" /> : undefined}
                  // eslint-disable-next-line security/detect-non-literal-fs-filename
                  onClick={() => window.open(config.urls.chatUiRoot, '_blank')}
                >
                  Engage New Drivers
                </Button>
              </Can>
            </>
          )}
          <IconButton
            icon={<MdPhone size="20px" />}
            size="md"
            borderRadius="full"
            aria-label="Outbound Call"
            onClick={() => setShowOutboundCallModal(true)}
          />
        </HStack>
        <HStack>
          <HStack>
            <Can I={PermissionEnum.SeeWebLeadsSearch}>
              <WebLeadSuggest />
            </Can>

            {currentView !== CurrentView.PayoffRequestsView ? <GlobalFiltersMenu /> : null}
          </HStack>
        </HStack>
      </Stack>
      <TransferCallModal
        isOutboundCallModal
        showModal={showOutboundCallModal}
        closeOutboundModal={setShowOutboundCallModal}
      />
    </>
  );
};

export default ButtonsRow;
