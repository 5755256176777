import React from 'react';
import { ScaleLoader } from 'react-spinners';
import styled from 'styled-components';

interface LoaderProps {
  isLoading: boolean;
}

export const LoadingOverlay = styled.div`
  position: fixed;
  background-color: rgb(0, 0, 0, 0.3);
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const Loader: React.FC<LoaderProps> = ({ isLoading }) => {
  return (
    <LoadingOverlay style={{ display: isLoading ? 'block' : 'none', left: 0 }}>
      <ScaleLoader
        loading
        height={75}
        width={10}
        color="white"
        css={`
          z-index: 1000;
          position: absolute;
          top: calc(50% - 50px);
          left: calc(50% - 50px);
        `}
      />
    </LoadingOverlay>
  );
};

export default Loader;
