import React from 'react';
import {
  Box,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import Modal from '../shared/Modal';
import { Deal, DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import { Customer, getFullName } from '../../gql/customerGql';
import { DealContact } from '../../gql/dealContactGql';

const getDriverName = (deal: Deal) => {
  let driver;
  if (deal.customer) {
    driver = deal.customer;
  } else if (deal.cobuyer) {
    driver = deal.cobuyer;
  } else if (deal.contact) {
    driver = deal.contact;
  }

  if (!driver) {
    return null;
  }

  return getFullName(driver as Customer | DealContact);
};

interface Props {
  deals: Deal[];
  isOpen: boolean;
  onClose: () => void;
  openTextWidget?: boolean;
  handleOpenTextWidget?: (deal: Deal) => void;
}

const MultipleDealsModal: React.FC<Props> = ({
  deals,
  isOpen,
  onClose,
  openTextWidget = false,
  handleOpenTextWidget,
}) => (
  <Modal isCentered isOpen={isOpen} onClose={onClose} size="3xl">
    <ModalOverlay />
    <ModalContent maxH="90%">
      <ModalHeader bgColor="oxfordBlue" color="white" fontSize="24px" pb={2} verticalAlign="middle">
        Select Deal
      </ModalHeader>
      <ModalCloseButton color="white" top={3} />
      <ModalBody fontWeight="normal" overflowY="scroll">
        <Box p={5}>
          This number is associated with multiple deals. Please choose which deal you'd like to
          view.
        </Box>
        <Table>
          <Tbody>
            {deals.map((deal) => {
              const driverName = deal ? getDriverName(deal) : '';

              return (
                <Tr key={deal.id}>
                  <Td>
                    <Link
                      color="blue.600"
                      fontWeight="bold"
                      textDecoration="underline"
                      href={`/deals/${deal.id}`}
                      onClick={(e) => {
                        if (openTextWidget && handleOpenTextWidget) {
                          e.preventDefault();

                          handleOpenTextWidget(deal);
                        }
                      }}
                    >
                      {deal.id}
                    </Link>
                  </Td>
                  <Td fontWeight="bold" px={3} textTransform="capitalize">
                    {driverName}
                  </Td>
                  <Td px={3}>{`${deal?.car?.year} ${deal?.car?.make} ${deal?.car?.model}`}</Td>
                  <Td>{deal?.state && DealStateLabelMap[deal?.state as DealStateEnum]}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default MultipleDealsModal;
