import * as yup from 'yup';

import { getFullNameWithMiddle } from '../../../../gql/customerGql';
import { Deal } from '../../../../gql/dealGql';
import { DealMediaTypeEnum } from '../../../../gql/generated/graphql';
import {
  DealMediaEdit,
  DriverLicenseMetadataValueFields,
  InsuranceCardMetadataValueFields,
} from '../../../../gql/mediaGql';

import { zipRegExp } from '../../../../constants/RegExps';
import { formatDateTimeISOWithUTC } from '../../../../libs/utils';
import { validateFuture } from '../../../../libs/yup-validators/dates';
import { removeWhiteSpacesAndLowercase } from '../../../../utils/text';

export const stringDoesNotMatchMessage = 'String does not match value';
export const enterValidZipMessage = 'Please enter a valid Zip';

export const yupStringMatchesValue = (
  value?: string | null,
  required = true,
  customFirstValidation?: yup.StringSchema,
) => {
  const baseValidation = (customFirstValidation ?? yup.string()).test(
    'stringMatchesValue',
    stringDoesNotMatchMessage,
    (fieldString) =>
      removeWhiteSpacesAndLowercase(fieldString) === removeWhiteSpacesAndLowercase(value),
  );

  return required ? baseValidation.required() : baseValidation;
};

export const yupFutureDate = () =>
  yup.date().required().test('expired', 'Date is in the past', validateFuture);

export const dateDoesNotMatchMessage = 'Date does not match value';

export const getValidationSchema = (
  deal: Deal,
  dealMedia: DealMediaEdit,
  requiredDriverLicenseFields?: Map<DriverLicenseMetadataValueFields, boolean>,
  requiredInsuranceCardFields?: Map<InsuranceCardMetadataValueFields, boolean>,
) => {
  if (dealMedia.type === DealMediaTypeEnum.FrontOfDriversLicense) {
    const metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key in DriverLicenseMetadataValueFields]?: any;
    } = {
      name: requiredDriverLicenseFields?.has('name')
        ? yupStringMatchesValue(getFullNameWithMiddle(deal.customer))
        : undefined,
      state:
        requiredDriverLicenseFields?.has('state') || requiredDriverLicenseFields?.has('address')
          ? yupStringMatchesValue(deal.customer?.address?.state)
          : undefined,
      address: requiredDriverLicenseFields?.has('address')
        ? yupStringMatchesValue(deal.customer?.address?.address_line)
        : undefined,
      address2: requiredDriverLicenseFields?.has('address')
        ? yupStringMatchesValue(deal.customer?.address?.address_line_2, false)
        : undefined,
      city: requiredDriverLicenseFields?.has('address')
        ? yupStringMatchesValue(deal.customer?.address?.city)
        : undefined,
      zip: requiredDriverLicenseFields?.has('address')
        ? yupStringMatchesValue(
            deal.customer?.address?.zip,
            true,
            yup.string().matches(zipRegExp, {
              message: enterValidZipMessage,
            }),
          )
        : undefined,
      expirationDate: requiredDriverLicenseFields?.has('expirationDate')
        ? yupFutureDate()
        : undefined,
    };

    return yup.object().shape({ metadata: yup.object().shape(metadata) });
  }

  if (dealMedia.type === DealMediaTypeEnum.FrontOfInsuranceCard) {
    const metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key in InsuranceCardMetadataValueFields]?: any;
    } = {
      name: requiredInsuranceCardFields?.has('name')
        ? yupStringMatchesValue(getFullNameWithMiddle(deal.customer))
        : undefined,
      state: requiredInsuranceCardFields?.has('state')
        ? yupStringMatchesValue(deal.customer?.address?.state)
        : undefined,
      vin: requiredInsuranceCardFields?.has('vin')
        ? yupStringMatchesValue(deal.car.vin)
        : undefined,
      expirationDate: requiredInsuranceCardFields?.has('expirationDate')
        ? yupFutureDate().test('dateMatchesValue', dateDoesNotMatchMessage, (fieldDate) => {
            if (!fieldDate) {
              return true;
            }

            return (
              formatDateTimeISOWithUTC(fieldDate) === deal.customer?.proof_of_insurance?.expires
            );
          })
        : undefined,
      insuranceCompany: requiredInsuranceCardFields?.has('insuranceCompany')
        ? yupStringMatchesValue(deal.customer?.proof_of_insurance?.company_name)
        : undefined,
      policyNo: requiredInsuranceCardFields?.has('policyNo')
        ? yupStringMatchesValue(deal.customer?.proof_of_insurance?.policy_number)
        : undefined,
    };

    return yup.object().shape({ metadata: yup.object().shape(metadata) });
  }

  return yup.object().shape({});
};
