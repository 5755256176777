import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  HStack,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderMark,
} from '@chakra-ui/react';
import { BsFillCircleFill } from 'react-icons/bs';
import { differenceInCalendarDays, subDays } from 'date-fns';

import { FiltersAction, FiltersActionKind } from '../../utils';
import { getDealAge } from '../../../../utils/deals';

interface Props {
  maxAge: string | undefined;
  dispatch: React.Dispatch<FiltersAction>;
  value: { from: Date | undefined; to: Date | undefined };
}

const RangeFilter: React.FC<Props> = ({ maxAge, dispatch, value }) => {
  const MIN_VALUE = 0;
  const today = new Date();
  const [maxValue, setMaxValue] = useState<number>(100);
  const [rangeValues, setRangeValues] = useState<number[]>([MIN_VALUE, maxValue]);
  const [filterValues, setFilterValues] = useState<number[]>([MIN_VALUE, maxValue]);
  const [currentHover, setCurrentHover] = useState<number | undefined>(undefined);

  const handleChange = useCallback((values: number[]) => {
    setRangeValues(values);
  }, []);

  const handleEnd = (values: number[]) => {
    setRangeValues(values);
    if (values.some((v, i) => v !== filterValues[i])) {
      if (values[0] !== filterValues[0]) {
        dispatch({
          type: FiltersActionKind.SET_TO_DATE,
          payload: values[0] === MIN_VALUE ? undefined : subDays(today, values[0]),
        });
      }
      if (values[1] !== filterValues[1]) {
        dispatch({
          type: FiltersActionKind.SET_FROM_DATE,
          payload: values[1] === maxValue ? undefined : subDays(today, values[1]),
        });
      }
      setFilterValues(values);
    }
  };

  const updateValues = (max = maxValue) => {
    const values = [
      value.to ? differenceInCalendarDays(today, new Date(value.to)) : MIN_VALUE,
      value.from ? differenceInCalendarDays(today, new Date(value.from)) : max,
    ];
    if (values.some((v, i) => v !== rangeValues[i])) {
      setRangeValues(values);
    }
    if (values.some((v, i) => v !== filterValues[i])) {
      setFilterValues(values);
    }
  };

  useEffect(() => {
    updateValues();
  }, [value.to, value.from]);

  useEffect(() => {
    if (maxAge) {
      const maxDealAge = getDealAge(maxAge);
      setMaxValue(maxDealAge);
      updateValues(maxDealAge);
    }
  }, [maxAge]);

  return (
    <HStack minW="275px" h="40px" pr={5} pl={5} bgColor="azureishWhite" borderRadius={3}>
      <RangeSlider
        // eslint-disable-next-line jsx-a11y/aria-proptypes
        aria-label={['min', 'max']}
        isReversed
        max={maxValue}
        focusThumbOnChange={false}
        minStepsBetweenThumbs={1}
        onChange={handleChange}
        onChangeEnd={handleEnd}
        value={rangeValues}
      >
        <RangeSliderTrack bgGradient="linear(to-r, red, yellow, green)">
          <RangeSliderFilledTrack bg="transparent" />
        </RangeSliderTrack>
        <RangeSliderThumb
          boxSize={5}
          index={0}
          onMouseEnter={() => setCurrentHover(0)}
          onMouseLeave={() => setCurrentHover(undefined)}
        >
          <Box color="orange" as={BsFillCircleFill} />
        </RangeSliderThumb>
        <RangeSliderThumb
          boxSize={5}
          index={1}
          onMouseEnter={() => setCurrentHover(1)}
          onMouseLeave={() => setCurrentHover(undefined)}
        >
          <Box color="orange" as={BsFillCircleFill} />
        </RangeSliderThumb>
        {currentHover === 0 && (
          <RangeSliderMark
            value={rangeValues[0]}
            textAlign="center"
            bg="orange"
            color="white"
            borderRadius={3}
            w={10}
            p={1}
            mt={-10}
            ml={-5}
          >
            {rangeValues[0]}
          </RangeSliderMark>
        )}
        {currentHover === 1 && (
          <RangeSliderMark
            value={rangeValues[1]}
            textAlign="center"
            bg="orange"
            color="white"
            borderRadius={3}
            w={10}
            p={1}
            mt={-10}
            ml={-5}
          >
            {rangeValues[1]}
          </RangeSliderMark>
        )}
      </RangeSlider>
    </HStack>
  );
};

export default RangeFilter;
