import React from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import get from 'lodash/get';
import { Deal, DealStateEnum } from '../../gql/dealGql';

const editableStates = [DealStateEnum.SoftClose, DealStateEnum.Structuring, DealStateEnum.Closing];

interface NumberInputProps {
  path: string;
  isMoney?: boolean;
  isWholeNumber?: boolean;
  placeholder?: string;
  isEditing?: boolean;
  max?: number;
  thousandSeparator?: boolean;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  path,
  isMoney = false,
  isWholeNumber = false,
  placeholder,
  isEditing = false,
  max,
  thousandSeparator = false,
}) => {
  const { setFieldValue, values, errors, touched, handleBlur } = useFormikContext<Deal>();

  const moneyProps = isMoney ? { thousandSeparator: true, prefix: '$' } : {};
  const wholeNumberProps = isWholeNumber ? { decimalScale: 0 } : { decimalScale: 2 };
  const isReadOnly = isEditing ? false : editableStates.indexOf(values.state) === -1;
  const isInvalid = isReadOnly ? false : get(touched, path) && !!get(errors, path);
  const withValueLimit = ({ floatValue }: NumberFormatValues) =>
    max && floatValue ? floatValue <= max : true;
  const thousandSeparatorProps = thousandSeparator ? { thousandSeparator: true } : {};

  return (
    <>
      <Form.Control
        as={NumberFormat}
        {...moneyProps}
        {...wholeNumberProps}
        {...thousandSeparatorProps}
        isAllowed={withValueLimit}
        placeholder={placeholder}
        name={path}
        value={get(values, path)}
        onValueChange={(newValues: { floatValue: number | undefined }) =>
          setFieldValue(path, newValues.floatValue)
        }
        onBlur={handleBlur}
        isInvalid={isInvalid}
        readOnly={isReadOnly}
      />
      <Form.Control.Feedback type="invalid">
        <>{get(errors, path)}</>
      </Form.Control.Feedback>
    </>
  );
};
