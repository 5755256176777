import { Dispatch } from 'react';

import { VStack, Wrap } from '@chakra-ui/react';

import { TABLE_HEADERS } from '../ColumnComponents';

import MultiAutocomplete from './MultiAutocomplete';

import { useLienholders } from '../../../../hooks/useLienholders';
import { FiltersAction, FiltersActionKind, FiltersState } from '../../util';

interface Props {
  dispatch: Dispatch<FiltersAction>;
  filters: FiltersState;
}

const TableFilters = ({ dispatch, filters }: Props) => {
  const { options: lienholderOptions } = useLienholders();

  return (
    <VStack p={6} bgColor="babyBlueEyes" align="start" borderTopRadius={8} w="100%">
      <Wrap overflow="visible">
        <MultiAutocomplete
          placeholder={TABLE_HEADERS.LIENHOLDER}
          options={lienholderOptions}
          dispatch={dispatch}
          actionKind={FiltersActionKind.SET_LIENHOLDERS}
          value={filters.lienholders}
        />
      </Wrap>
    </VStack>
  );
};

export default TableFilters;
