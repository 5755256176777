import { useContext, useMemo, useState } from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import { Deal, DealStateEnum, processPaperwork } from '../../gql/dealGql';
import { DealType, PaperworkType } from '../../gql/generated/graphql';

import Tooltip from '../shared/Tooltip';

import InsuranceModal from './InsuranceModal';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { Can } from '../../libs/Can';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

interface PaperworkOptionsProps {
  showPaperworkOptions: boolean;
  setShowPaperworkOptions?: (value: boolean) => void;
  refetch?: () => Promise<ApolloQueryResult<Deal>>;
}

const PaperworkOptionsButtons = ({
  showPaperworkOptions,
  setShowPaperworkOptions,
  refetch,
}: PaperworkOptionsProps) => {
  const { isRecalculatingPayoff } = useContext(DealContext);

  const { values, errors } = useFormikContext<Deal>();
  const [updatePaperwork, { loading }] = useMutation(processPaperwork);
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [paperwork, setPaperwork] = useState<string>(PaperworkType.Esign);

  const isTitleOnly = values.financial_info?.title_only === true;
  const registrationExpiresWithin90Days = (date: Date | undefined) => {
    // Check if the registration expiration date is within 90 days
    if (!date) {
      return true;
    }
    const today = new Date();
    const ninetyDaysFromNow = new Date(today.setDate(today.getDate() + 90));
    const expirationDate = new Date(date);
    return expirationDate < ninetyDaysFromNow;
  };

  const needToUpdateRegistration =
    isTitleOnly && registrationExpiresWithin90Days(values.car.registration_expiration);

  const onPaperworkSelection = (
    paperworkType: string,
    dealValues: Deal,
    beenNotifiedOfRegistration?: boolean,
  ) => {
    const cust = dealValues.customer;
    if (
      dealValues.type === DealType.Acquisition ||
      (cust?.proof_of_insurance?.company_name &&
        cust?.proof_of_insurance?.policy_number &&
        cust?.proof_of_insurance?.expires &&
        ((isTitleOnly &&
          (!registrationExpiresWithin90Days(dealValues.car.registration_expiration) ||
            beenNotifiedOfRegistration)) ||
          !isTitleOnly))
    ) {
      updatePaperwork({
        variables: {
          id: dealValues.id,
          state: DealStateEnum.Closed,
          paperwork_type: paperworkType,
        },
      })
        .then(() => {
          if (refetch && setShowPaperworkOptions) {
            refetch();
            setShowPaperworkOptions(false);
          } else {
            history.push(ROUTES.DASHBOARD);
          }
        })
        .then(() => {
          history.push(ROUTES.DASHBOARD);
        })
        .catch((e) => logger.error('PaperworkOptionsButtons.tsx', '', null, e));
    } else {
      setPaperwork(paperworkType);
      setShowModal(true);
    }
  };

  const tooltipErrors = useMemo(() => {
    const newTooltipErrors = [];
    if (!values.customer.email) {
      newTooltipErrors.push('Buyer email is required to E-Sign contracts');
    }

    if (values.cobuyer && !values.cobuyer.email) {
      newTooltipErrors.push('Co-buyer email is required to E-Sign contracts');
    }

    return newTooltipErrors;
  }, [values.customer, values.cobuyer, errors]);

  return (
    <Can I={PermissionEnum.FinalizePayment}>
      <Button
        isLoading={loading}
        isDisabled={isRecalculatingPayoff}
        hidden={!showPaperworkOptions}
        onClick={() => onPaperworkSelection(PaperworkType.Paper, values)}
      >
        PAPER
      </Button>
      <Tooltip errors={tooltipErrors}>
        <Button
          isLoading={loading}
          isDisabled={isRecalculatingPayoff || tooltipErrors.length > 0}
          hidden={!showPaperworkOptions}
          onClick={() => onPaperworkSelection(PaperworkType.Esign, values)}
        >
          E-SIGN
        </Button>
      </Tooltip>
      <InsuranceModal
        showModal={showModal}
        setShowModal={setShowModal}
        paperworkType={paperwork}
        selectPaperwork={onPaperworkSelection}
        loading={loading}
        needToUpdateCarRegistrationExpiration={needToUpdateRegistration}
      />
    </Can>
  );
};

export default PaperworkOptionsButtons;
