/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import { Row, Col, Table } from 'react-bootstrap';
import { Button, IconButton } from '@chakra-ui/react';
import { LinkContainer } from 'react-router-bootstrap';
import { BiArrowBack, BiTrash, BiEdit } from 'react-icons/bi';
import { FaNewspaper } from 'react-icons/fa';
import Toggle from 'react-toggle';
import LeaseEndContainer from '../../components/Container/Container';
import Loader from '../../components/Loader';
import ROUTES from '../../constants/routes';
import {
  Article,
  createArticle,
  listArticles,
  deleteArticle,
  updateArticle,
} from '../../gql/articleGql';
import { formatDate, truncateString } from '../../libs/utils';
import UpsertArticleModal from './components/UpsertArticleModal';
import DeleteArticleModal from './components/DeleteArticleModal';

const PressManagerPage: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const [articleToEdit, setArticleToEdit] = useState<Article | null>(null);
  const [createArticleMutation] = useMutation(createArticle);
  const [deleteArticleMutation] = useMutation(deleteArticle);
  const [updateArticleMutation] = useMutation(updateArticle);

  const { data, loading } = useQuery(listArticles, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const dataArticles = data?.articles;

    if (dataArticles) {
      setArticles(dataArticles);
    }
  }, [data]);

  const handleUpdate = async (values: Article) => {
    try {
      const article = { ...values };
      delete article.thumbnail;
      await updateArticleMutation({ variables: { article } });
      setArticles((prev) =>
        prev.map((prevArticle) => (prevArticle.id === values.id ? values : prevArticle)),
      );

      return values.id;
    } catch {
      toast.error('Failed to update article.');
      return undefined;
    }
  };

  const handleCreate = async (values: Article) => {
    try {
      const article = { ...values };
      delete article.thumbnail;
      const {
        data: {
          createArticle: { id },
        },
      } = await createArticleMutation({
        variables: { article },
      });
      setArticles((prev) => [{ ...values, id }, ...prev]);

      return id;
    } catch {
      toast.error('Failed to add article.');
      return undefined;
    }
  };

  const handleSubmit = async (values: Article) => {
    if (articleToEdit) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }

    setShowModal(false);
  };

  const handleDelete = async () => {
    try {
      await deleteArticleMutation({
        variables: { id: idToDelete },
      });
      setArticles((prev) => prev.filter((article) => article.id !== idToDelete));
      setShowDeleteModal(false);
    } catch {
      toast.error('Failed to delete article.');
    }
  };

  return (
    <>
      <Loader isLoading={loading} />
      <LeaseEndContainer fluid>
        <Row className="align-items-start justify-content-between mb-3">
          <Col xs="auto">
            <LinkContainer to={ROUTES.DASHBOARD}>
              <Button leftIcon={<BiArrowBack />} variant="secondary" size="lgWithIconLeft">
                DASHBOARD
              </Button>
            </LinkContainer>
            <Button
              size="lgWithIconLeft"
              leftIcon={<FaNewspaper />}
              onClick={() => {
                setArticleToEdit(null);
                setShowModal(true);
              }}
            >
              ADD FEATURED ARTICLE
            </Button>
          </Col>
        </Row>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Thumbnail</th>
              <th>Title</th>
              <th>Headline</th>
              <th>Date Added</th>
              <th className="text-center">COM Visibility</th>
              <th className="text-center">Edit</th>
              <th className="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            {articles.map((article) => (
              <tr key={article.id}>
                <td>
                  <img src={article.thumbnail} alt="thumbnail" height="100px" width="100px" />
                </td>
                <td>{article.title}</td>
                <td>{truncateString(article.headline, 50)}</td>
                <td>{formatDate(article.created_at)}</td>
                <td align="center">
                  <Toggle
                    icons={false}
                    name="com_visible"
                    checked={article.com_visible}
                    onChange={() =>
                      handleUpdate({
                        ...article,
                        com_visible: !article.com_visible,
                      })
                    }
                  />
                </td>
                <td align="center">
                  <IconButton
                    icon={<BiEdit />}
                    variant="iconHover"
                    size="xs"
                    aria-label="Edit Article"
                    onClick={() => {
                      setShowModal(true);
                      setArticleToEdit(article);
                    }}
                  />
                </td>
                <td align="center">
                  <IconButton
                    icon={<BiTrash />}
                    variant="iconHover"
                    size="xs"
                    color="errorsRed"
                    aria-label="Delete Article"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setIdToDelete(article.id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </LeaseEndContainer>
      <UpsertArticleModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        articleToEdit={articleToEdit}
      />
      <DeleteArticleModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default PressManagerPage;
