import { FC, MouseEventHandler, ReactNode } from 'react';

import { Box, BoxProps, Flex, IconButton } from '@chakra-ui/react';
import { BsGearFill } from 'react-icons/bs';

import { Pod } from '../../gql/podGql';

interface Props extends BoxProps {
  children?: ReactNode;
  text?: string;
  pod?: Pod;
  titlingPod?: Pod;
  handleCardClick?: MouseEventHandler<HTMLDivElement>;
  handleGearClick?: MouseEventHandler<HTMLButtonElement>;
}
const HomeCard: FC<Props> = ({
  children,
  text,
  borderLeftColor,
  handleCardClick,
  handleGearClick,
  ...rest
}) => {
  return (
    <Box
      width="385px"
      border="2px solid lightGray"
      borderLeft="5px solid"
      borderLeftColor={borderLeftColor ?? 'black'}
      mb={4}
      {...rest}
    >
      <Flex
        p={2}
        onClick={handleCardClick}
        fontSize="22px"
        paddingLeft="10px"
        cursor={handleCardClick ? 'pointer' : undefined}
        _hover={{ bgColor: 'hoverGray' }}
        justifyContent="space-between"
      >
        {text ?? ''}
        <IconButton
          icon={<BsGearFill />}
          variant="iconHover"
          size="sm"
          float="right"
          m={0}
          aria-label="Settings"
          onClick={handleGearClick}
        />
      </Flex>
      {children}
    </Box>
  );
};

export default HomeCard;
