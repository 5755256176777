import { ComponentProps, FC, ReactNode } from 'react';

import {
  // eslint-disable-next-line no-restricted-imports
  Modal as ChakraModal,
  Flex,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Form } from 'formik';
import { IconType } from 'react-icons';

export interface LEModalProps extends Omit<ModalProps, 'children'> {
  title?: string;
  children?: ReactNode;
  titleIcon?: IconType;
  secondaryTitle?: string | ReactNode;
  subTitle?: string;
  leftButtons?: ReactNode;
  centerButtons?: ReactNode;
  rightButtons?: ReactNode;
  headerSize?: 'sm' | 'md' | 'lg';
  canDismiss?: boolean;
  hasForm?: boolean;
  formProps?: ComponentProps<typeof Form>;
  showDivider?: boolean;
  closeOnOverlayClick?: boolean;
}

const Modal: FC<LEModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  titleIcon,
  secondaryTitle,
  subTitle,
  leftButtons,
  centerButtons,
  rightButtons,
  size = 'lg',
  headerSize = 'md',
  canDismiss = true,
  hasForm = false,
  showDivider = true,
  formProps = {},
  closeOnOverlayClick,
  ...rest
}) => {
  const headerStyles = useMultiStyleConfig('Modal', { size: headerSize });

  const content = (
    <>
      <ModalHeader display="flex" sx={headerStyles.header}>
        <Text flex={1} sx={headerStyles.title}>
          {titleIcon ? <Icon as={titleIcon} color="primary" mr="0.5em" /> : null}
          {title}
        </Text>
        {secondaryTitle ? (
          <Text alignSelf="center" sx={headerStyles.secondaryTitle}>
            {secondaryTitle}
          </Text>
        ) : null}
        <Text flex={1} />
      </ModalHeader>
      {canDismiss ? <ModalCloseButton sx={headerStyles.closeButton} /> : null}
      {subTitle ? (
        <Flex alignItems="center" sx={headerStyles.subHeader}>
          {subTitle}
        </Flex>
      ) : null}

      <ModalBody>{children}</ModalBody>

      {leftButtons || centerButtons || rightButtons ? (
        <ModalFooter
          borderTopColor={showDivider ? 'gray.100' : undefined}
          borderTopWidth={showDivider ? '2px' : undefined}
        >
          <Flex flex={1} justifyContent="start">
            {leftButtons}
          </Flex>
          <Flex flex={1} justifyContent="center">
            {centerButtons}
          </Flex>
          <Flex flex={1} justifyContent="end">
            {rightButtons}
          </Flex>
        </ModalFooter>
      ) : null}
    </>
  );

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      isCentered
      scrollBehavior="inside"
      closeOnEsc={canDismiss}
      closeOnOverlayClick={closeOnOverlayClick ?? canDismiss}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>{hasForm ? <Form {...formProps}>{content}</Form> : content}</ModalContent>
    </ChakraModal>
  );
};

export default Modal;
