import { gql } from '@apollo/client/core';

export class FinancialInfoAcquisition {
  id?: number;
  deal_id?: number;
  kbb_lending: number | undefined;
  max_cash_to_customer?: number;
  max_total_cost?: number;
  appraised_value?: number;
  cash_to_customer?: number;
  is_approved?: boolean;
  auction_fees?: number;
  transport_cost?: number;
  total_cost?: number;
  auction_id?: number;
  gross_profit?: number;
  sell_price?: number;
  overriding_user_id?: number;
  mmr?: number;
  constructor(deal_id?: number, book_value?: number) {
    this.deal_id = deal_id;
    this.kbb_lending = book_value;
  }
}

export const financialInfoAcquisitionUpsert = gql`
  mutation financialInfoAcquisitionUpsert(
    $financialInfoAcquisition: financialInfoAcquisitionInput
    $id: ID!
    $state: String!
    $car_id: ID
    $mmr: Float
    $vehicle_payoff: Float
    $override: Boolean
    $clear_override: Boolean
  ) {
    financialInfoAcquisitionUpsert(
      financialInfoAcquisition: $financialInfoAcquisition
      id: $id
      state: $state
      car_id: $car_id
      mmr: $mmr
      vehicle_payoff: $vehicle_payoff
      override: $override
      clear_override: $clear_override
    ) {
      id
    }
  }
`;
