import { Dispatch, SetStateAction } from 'react';

import { Icon } from '@chakra-ui/react';
import { MdHighlightOff } from 'react-icons/md';

import {
  FilterOptionsEnum,
  FilterOptionsType,
  FiltersAction,
  FiltersActionKind,
  handleClearFilter,
} from '../../utils';

interface ClearIconProps {
  title: FilterOptionsType;
  selectedFilters: FilterOptionsEnum[];
  setSelectedFilters: Dispatch<SetStateAction<FilterOptionsEnum[]>>;
  dispatch: Dispatch<FiltersAction>;
  actionKind: FiltersActionKind[];
}

const ClearFilterIcon = ({
  title,
  selectedFilters,
  setSelectedFilters,
  dispatch,
  actionKind,
}: ClearIconProps) => {
  return (
    <Icon
      as={MdHighlightOff}
      mb={1}
      ml={1}
      color="red"
      cursor="pointer"
      onClick={() =>
        handleClearFilter(title, actionKind, dispatch, selectedFilters, setSelectedFilters)
      }
    />
  );
};

export default ClearFilterIcon;
