import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';

import { buyerCobuyerSwap } from '../../gql/dealGql';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

interface SwapBuyerCobuyerButtonProps {
  isSavingOrCheckingMileage: boolean;
}
const SwapBuyerCobuyerButton = ({ isSavingOrCheckingMileage }: SwapBuyerCobuyerButtonProps) => {
  const { deal } = useContext(DealContext);
  const [swapBuyerCobuyer, { loading }] = useMutation(buyerCobuyerSwap);

  const onSwapBuyerCobuyer = async () => {
    try {
      await swapBuyerCobuyer({
        variables: {
          deal_id: deal.id,
          customer_id: deal.customer.id,
          cobuyer_id: deal.cobuyer?.id,
        },
      });
      window.location.reload();
    } catch (e) {
      logger.error('SwapBuyerCobuyerButton.tsx', '', null, e);
    }
  };

  return (
    <Button
      variant="secondary"
      isLoading={loading}
      isDisabled={isSavingOrCheckingMileage}
      loadingText="SWAP BUYER/CO-BUYER"
      hidden={!deal.cobuyer}
      onClick={onSwapBuyerCobuyer}
    >
      SWAP BUYER/CO-BUYER
    </Button>
  );
};

export default SwapBuyerCobuyerButton;
