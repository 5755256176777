import { ChangeEvent, FC } from 'react';

import {
  Box,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { MdPermPhoneMsg, MdPhoneForwarded, MdSearch } from 'react-icons/md';

import { GetAvailableUsers } from '../../gql/generated/graphql';

import { getOptionColor } from '../UserStatusSwitcher/UserStatusSwitcher';

import { formatPhoneNumberForDisplay } from '../../libs/utils';

interface Props {
  callUserOutbound: (user: GetAvailableUsers) => void;
  isOutboundCallModal: boolean;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onTransferCall: (user: GetAvailableUsers) => void;
  search: string;
  userList: GetAvailableUsers[];
}

const ListRow: FC<{
  isOutboundModal: boolean;
  newUser: GetAvailableUsers;
  onCallOutbound: (user: GetAvailableUsers) => void;
  onTransferCall: (user: GetAvailableUsers) => void;
}> = ({ isOutboundModal, newUser, onCallOutbound, onTransferCall }) => {
  const { id, name, twilio_number, in_call, call_status } = newUser;
  return (
    <HStack
      mt={2}
      key={id}
      _hover={{
        background: 'hoverGray',
      }}
      spacing="auto"
      px={3}
    >
      <Text minW="30%">{name}</Text>
      <Text minW="30%"> {twilio_number ? formatPhoneNumberForDisplay(twilio_number) : ''}</Text>
      <Box minW="15%">
        {in_call ? (
          <Text>
            <MdPermPhoneMsg /> ON A CALL
          </Text>
        ) : (
          <Text m={1} textColor={getOptionColor(call_status ?? '')} textTransform="uppercase">
            {call_status}
          </Text>
        )}
      </Box>
      <Box>
        <IconButton
          aria-label="Call/Transfer"
          icon={<MdPhoneForwarded />}
          variant="icon"
          size="sm"
          color="oxfordBlue"
          onClick={() => (isOutboundModal ? onCallOutbound(newUser) : onTransferCall(newUser))}
        />
      </Box>
    </HStack>
  );
};

export const UsersCallList: FC<Props> = ({
  callUserOutbound,
  isOutboundCallModal,
  onSearchChange,
  onTransferCall,
  search,
  userList,
}) => {
  return (
    <Box m={3} pb={4} px={6}>
      <Text fontWeight="bold" fontSize={20}>
        Users
      </Text>

      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={MdSearch} width="3em" height="1.5em" color="primary" />
        </InputLeftElement>
        <Input maxW="50%" placeholder="" value={search} onChange={onSearchChange} />
      </InputGroup>

      <Box w="full" maxH="lg" overflow="auto" mt={3} borderTop="1px" borderColor="lightGray">
        {userList.map((newUser) => {
          if (!newUser.phone_number) {
            return false;
          }
          return (
            <ListRow
              isOutboundModal={isOutboundCallModal}
              onCallOutbound={callUserOutbound}
              onTransferCall={onTransferCall}
              newUser={newUser}
            />
          );
        })}
      </Box>
    </Box>
  );
};
