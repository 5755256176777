import React, { useContext } from 'react';
import { Button, useDisclosure, ButtonProps } from '@chakra-ui/react';
import Modal from '../../shared/Modal';
import { Can } from '../../../libs/Can';
import { DealContext } from '../../../libs/DealContext';
import { useUser } from '../../../hooks/useUser';
import { PermissionEnum } from '../../../constants/permissions';
import { AbilityContext } from '../../../libs/contextLib';

interface ShippingLabelButtonProps {
  size?: ButtonProps['size'];
}

const ShippingLabelButton: React.FC<ShippingLabelButtonProps> = () => {
  const {
    deal: { id },
  } = useContext(DealContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useUser();
  const ability = useContext(AbilityContext);

  return (
    <>
      <Can I={PermissionEnum.ShippingLabelsView}>
        <Button size="sm" aria-label="Shipping Labels" onClick={onOpen}>
          SHIPPING LABELS
        </Button>
      </Can>

      <Modal
        title="Shipping Labels"
        variant="fullContent"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick
        size="6xl"
      >
        <iframe
          id="shippingLabelIframe"
          title="Shipping label Retool Inline Frame"
          style={{
            borderRadius: 10,
            height: 'calc(100vh - 200px)',
            width: '100%',
          }}
          src={`https://leaseendtest.retool.com/embedded/public/d0012a63-1d7c-4f96-b22a-5ef695dad8e1?user_id=${
            user.id
          }&deal_id=${id}&can_create_label=${ability.has(PermissionEnum.ShippingLabelsCreate)}`}
        />
      </Modal>
    </>
  );
};

export default ShippingLabelButton;
