import { gql } from '@apollo/client';

export class Article {
  id?: number;
  title: string;
  headline: string;
  url: string;
  com_visible?: boolean;
  created_at?: string;
  thumbnail_key?: string;
  thumbnail?: string;

  constructor() {
    this.title = '';
    this.headline = '';
    this.url = '';
    this.com_visible = false;
  }
}

export const createArticle = gql`
  mutation createArticle($article: articleInput) {
    createArticle(article: $article) {
      id
    }
  }
`;

export const listArticles = gql`
  query articles {
    articles {
      id
      title
      headline
      url
      com_visible
      created_at
      thumbnail_key
      thumbnail
    }
  }
`;

export const deleteArticle = gql`
  mutation deleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      id
    }
  }
`;

export const updateArticle = gql`
  mutation updateArticle($article: articleInput) {
    updateArticle(article: $article) {
      id
      title
      headline
      url
      com_visible
      created_at
      thumbnail_key
      thumbnail
    }
  }
`;
