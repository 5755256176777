import { useContext } from 'react';

import { Button } from '@chakra-ui/react';
import { MdRestoreFromTrash } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DealStateEnum } from '../../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../../gql/generated/graphql';

import ROUTES from '../../../constants/routes';
import { DealContext } from '../../../libs/DealContext';
import { logger } from '../../../libs/Logger';

const ReviveBootAction = () => {
  const { deal } = useContext(DealContext);
  const [updateDealState] = useDealUpdateStateMutation();
  const history = useHistory();

  const handleClick = () => {
    if (!deal.id) {
      return;
    }

    updateDealState({
      variables: {
        id: deal.id,
      },
    })
      .then(() => {
        toast.success('Success');
        history.push(ROUTES.DASHBOARD);
      })
      .catch((e) => {
        logger.error('ReviveBootAction.tsx', '', null, e);
        toast.error('Could not revert deal state');
      });
  };

  return (
    <Button
      size="lgWithIconLeft"
      leftIcon={<MdRestoreFromTrash size={20} />}
      variant="boot"
      hidden={deal.state !== DealStateEnum.Booted}
      onClick={() => handleClick()}
    >
      REVIVE BOOT
    </Button>
  );
};

export default ReviveBootAction;
