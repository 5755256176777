import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Textarea } from '@chakra-ui/react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';

import { getFullName } from '../../gql/customerGql';
import {
  BootReason,
  BootReasonsEnum,
  DealStateEnum,
  dealUpdateRequestBoot,
} from '../../gql/dealGql';

import { PermissionEnum } from '../../constants/permissions';
import { Can } from '../../libs/Can';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

const MAX_COMMENT_LENGTH = 150;

const RequestBoot = () => {
  const [requestBoot] = useMutation(dealUpdateRequestBoot);
  const { deal, dealRefetch } = useContext(DealContext);
  const [showModal, setShowModal] = useState<boolean>();
  const [reason, setReason] = useState<BootReasonsEnum>();
  const [comment, setComment] = useState<string>('');

  const handleClick = () => {
    const requestBootStatus = deal.request_boot;
    requestBoot({
      variables: {
        id: deal.id,
        request_boot: !requestBootStatus,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        boot_reason: new BootReason(reason!, comment),
      },
    })
      .then(() => {
        dealRefetch();
        toast.success(requestBootStatus ? 'Boot request canceled' : 'Deal boot requested');
        setShowModal(false);
      })
      .catch((e) => {
        logger.error('RequestBoot.tsx', '', null, e);
        toast.error(
          requestBootStatus ? 'Failed to cancel boot request.' : 'Failed to request boot.',
        );
      });
  };

  useEffect(() => {
    setReason(deal?.boot_reason?.reason);
  }, [deal]);

  const bootOptions = Object.values(BootReasonsEnum).map((e) => (
    <option key={e} value={e}>
      {e}
    </option>
  ));

  bootOptions.unshift(
    <option key={-1} value={0}>
      Select Reason
    </option>,
  );

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setReason(e.target.value as BootReasonsEnum);
  };

  const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const isCommentValid = comment.length <= MAX_COMMENT_LENGTH && comment.length > 0;
  const isRequestBootButtonDisabled = !reason || !isCommentValid;

  return (
    <Can I={PermissionEnum.RequestBoot}>
      <Button
        size="lgWithIconLeft"
        leftIcon={<BiTrash />}
        variant="boot"
        onClick={() => setShowModal(true)}
        hidden={deal.state === DealStateEnum.Booted}
      >
        {deal.request_boot ? 'CANCEL BOOT' : 'REQUEST BOOT'}
      </Button>
      <Modal
        show={showModal}
        backdrop="static"
        onHide={() => setShowModal(false)}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {deal.request_boot ? 'Cancel Boot Request?' : `Remove ${getFullName(deal.customer)}?`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deal.request_boot ? (
            'Are you sure?'
          ) : (
            <InputGroup>
              <Form.Control
                as="select"
                placeholder="Reasons"
                name="deal.boot_reason.reason"
                value={reason}
                onChange={handleChange}
                required
              >
                {bootOptions}
              </Form.Control>
              <Textarea
                placeholder="Comment"
                borderRadius={3}
                name="deal.boot_reason.comment"
                mt={4}
                rows={2}
                value={comment}
                onChange={handleChangeComment}
                maxLength={MAX_COMMENT_LENGTH}
                required
              />
            </InputGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            CLOSE
          </Button>
          {deal.request_boot ? (
            <Button variant="warning" onClick={handleClick}>
              CANCEL BOOT REQUEST
            </Button>
          ) : (
            <Button variant="boot" isDisabled={isRequestBootButtonDisabled} onClick={handleClick}>
              REQUEST BOOT
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Can>
  );
};

export default RequestBoot;
