import { gql } from '@apollo/client';

export class Review {
  id?: number;
  customer_name: string;
  review: string;
  com_visible: boolean;
  created_at?: string;
  location?: string;

  constructor() {
    this.customer_name = '';
    this.review = '';
    this.com_visible = false;
    this.location = '';
  }
}

export const createReview = gql`
  mutation createReview($review: reviewInput) {
    createReview(review: $review) {
      id
    }
  }
`;

export const listReviews = gql`
  query reviews {
    reviews {
      id
      customer_name
      review
      com_visible
      created_at
      location
    }
  }
`;

export const deleteReview = gql`
  mutation deleteReview($id: ID!) {
    deleteReview(id: $id) {
      id
    }
  }
`;

export const updateReview = gql`
  mutation updateReview($review: reviewInput) {
    updateReview(review: $review) {
      id
      customer_name
      review
      com_visible
      created_at
      location
    }
  }
`;
