import React, { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import { Table } from 'react-bootstrap';

import { Deal } from '../../../gql/dealGql';
import { Rate } from '../../../gql/generated/graphql';

import { getTermName } from '../constants';
import { VscPackageTypeEnum } from '../types';
// eslint-disable-next-line import/order
import styled from 'styled-components';

import { ColorsEnum } from '../../../libs/colorLib';
import { formatMoney } from '../../../libs/utils';

const StyledTable = styled(Table)`
  border-spacing: 1px;
`;

const StyledCell = styled.td.attrs((props: { active: boolean }) => props)`
  text-align: center;
  padding: 16px !important;
`;

const StyledSelectableCell = styled(StyledCell)`
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
  background: ${({ active }) => (active ? `${ColorsEnum.BLUE} !important` : 'initial')};
  user-select: none;
`;

interface VscPickerProps {
  options: Rate[];
}

const getUniqueNumberValues = (arr: Rate[], key: 'term_miles' | 'term_months') =>
  arr
    .map((rate) => (rate[key] ? +(rate[key] ?? '') : 0))
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a - b);

const VscPicker: React.FC<VscPickerProps> = ({ options }) => {
  const [terms, setTerms] = useState<number[]>([]);
  const [miles, setMiles] = useState<number[]>([]);
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Deal>();

  const updateField = (name: string, value: number | string | undefined) => {
    setFieldValue(name, value);
    setTimeout(() => setFieldTouched(name, true));
  };

  useEffect(() => {
    setTerms(getUniqueNumberValues(options, 'term_months'));
    setMiles(getUniqueNumberValues(options, 'term_miles'));
  }, [options]);

  return options.length ? (
    <StyledTable striped bordered variant="dark">
      <thead>
        <tr>
          <th />
          {terms.map((term) => (
            <th className="text-center" key={term}>
              {term} Months
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {miles.map((mile) => (
          <tr key={mile}>
            <td>{mile / 1000}K Miles</td>
            {terms.map((term) => {
              const rate: Rate | undefined = options.find(
                (o) => +(o.term_miles ?? '') === mile && +(o.term_months ?? '') === term,
              );

              const cost = rate?.deductible?.dealer_cost;
              const sessionId = rate?.session_id ?? undefined;
              const rateId = rate?.deductible?.rate_id ?? undefined;
              const formId = rate?.form_id ?? undefined;
              const parsedCost = cost ? parseInt(cost, 10) : undefined;
              const price = rate?.deductible?.retail_price
                ? parseFloat(rate?.deductible.retail_price)
                : undefined;
              const result = formatMoney(price, { noDecimal: true });
              const termName = getTermName(term, mile);

              return result ? (
                <StyledSelectableCell
                  active={
                    values.financial_info.vsc_price === price &&
                    termName === values.financial_info.vsc_term
                  }
                  key={mile + term}
                  onClick={() => {
                    updateField('financial_info.vsc_price', price);
                    updateField('financial_info.vsc_cost', parsedCost);
                    updateField('financial_info.vsc_type', VscPackageTypeEnum.Major);
                    updateField('financial_info.vsc_term', termName);
                    updateField('financial_info.pen_vsc_session_id', sessionId);
                    updateField('financial_info.pen_vsc_rate_id', rateId);
                    updateField('financial_info.pen_vsc_form_id', formId);
                  }}
                >
                  {result}
                </StyledSelectableCell>
              ) : (
                <StyledCell key={mile + term}>{result}</StyledCell>
              );
            })}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  ) : (
    <h6 className="p-3">No Rates Found</h6>
  );
};

export default VscPicker;
